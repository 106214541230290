import { FC, Fragment, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import StageNameInputField from "./StageNameInputField";
import {
  IGridList,
  IGridListStageDetail,
  IProjectTemplate,
} from "models/interface";
import MessageThread from "../../MessageThread";
import { SectionTypeMessages } from "models/enums/messages";
import ExpandedMessagePopup from "components/sharedComponents/ExpandedMessagePopup/ExpandedMessageDrawer";

const TaskList: FC<{
  sectionDetails: IGridList;
  setStageName: React.Dispatch<React.SetStateAction<string>>;
  stageName: string;
  templateDetails: IProjectTemplate;
}> = ({ sectionDetails, stageName, templateDetails }) => {
  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);
  const [expandedViewForMessageIndex, setExpandedViewForMessageIndex] =
    useState<number | undefined>();
  return (
    <Fragment>
      {sectionDetails.grid_list_details?.stage_details?.map(
        (ele: IGridListStageDetail, index: number) => (
          <Draggable
            key={ele.stage_id}
            draggableId={`${ele.stage_id}`}
            index={index}
            isDragDisabled={true}
          >
            {(provided: any) => {
              return (
                <li
                  key={ele.stage_id}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <StageNameInputField
                    stageDetails={ele}
                    stageName={stageName}
                    sectionDetails={sectionDetails}
                  />
                  {!ele?.isNew && (
                    <Fragment>
                      <MessageThread
                        sectionId={sectionDetails.section_id}
                        taskId={ele.stage_id}
                        sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                        threadName={ele.stage_name}
                        sectionName={sectionDetails.section_name!}
                        taskDetails={ele}
                        templateDetails={templateDetails}
                        setOpenMessageExpandedView={setOpenMessageExpandedView}
                        index={index}
                        setExpandedViewForMessageIndex={
                          setExpandedViewForMessageIndex
                        }
                      />
                      <ExpandedMessagePopup
                        sectionId={sectionDetails.section_id}
                        taskId={ele.stage_id}
                        sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                        threadName={ele.stage_name}
                        sectionName={sectionDetails.section_name!}
                        taskDetails={ele}
                        templateDetails={templateDetails}
                        openMessageExpandedView={openMessageExpandedView}
                        setOpenMessageExpandedView={setOpenMessageExpandedView}
                        isTemplate={true}
                        index={index}
                        expandedViewForMessageIndex={
                          expandedViewForMessageIndex
                        }
                      />
                    </Fragment>
                  )}
                </li>
              );
            }}
          </Draggable>
        )
      )}
    </Fragment>
  );
};

export default TaskList;
