import { useRef, useState } from "react";
import TaskDueDate from "./TaskDueDate";
import TaskAssignee from "./TaskAssignee";
import TaskPriority from "./TaskPriority";
import TaskStatus from "./TaskStatus";
import { IProjectTemplate, ITaskListDetails } from "models/interface";
import MessageThread from "../../Sections/MessageThread";
import { SectionTypeMessages } from "models/enums/messages";
import ExpandedMessagePopup from "components/sharedComponents/ExpandedMessagePopup/ExpandedMessageDrawer";

const Task: React.FC<{
  eachTaskDetail: ITaskListDetails;
  sectionId: string | number;
  templateDetails: IProjectTemplate;
  index?: number;
}> = ({ eachTaskDetail, sectionId, templateDetails, index }) => {
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);
  const [expandedViewForMessageIndex, setExpandedViewForMessageIndex] =
    useState<number | undefined>();

  const renderTaskName = () => {
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          ref={inputRef}
          type="text"
          id={`text-input-${sectionId}-${eachTaskDetail.task_id?.toString()}`}
          className="text-input-box"
          placeholder="Give your task a name"
          value={eachTaskDetail.task_name}
          aria-label="task name"
          maxLength={255}
        />
      </form>
    );
  };

  const getEachTaskJsx = () => {
    if (typeof eachTaskDetail.task_id === "number") {
      return (
        <tr className="not-empty">
          <TaskStatus taskStatusChangeHandler={() => {}} />
          <td className="withMsg">
            {renderTaskName()}
            <MessageThread
              sectionId={sectionId}
              taskId={eachTaskDetail.task_id}
              sectionType={SectionTypeMessages.SIMPLE_LIST_TASK}
              threadName={eachTaskDetail.task_name!}
              sectionName=""
              taskDetails={eachTaskDetail}
              templateDetails={templateDetails}
              setOpenMessageExpandedView={setOpenMessageExpandedView}
              index={index}
              setExpandedViewForMessageIndex={setExpandedViewForMessageIndex}
              isTemplate={true}
            />
            {/* <ExpandedMessagePopup
              sectionId={sectionId}
              taskId={eachTaskDetail.task_id}
              sectionType={SectionTypeMessages.SIMPLE_LIST_TASK}
              threadName={eachTaskDetail.task_name!}
              sectionName=""
              taskDetails={eachTaskDetail}
              templateDetails={templateDetails}
              openMessageExpandedView={openMessageExpandedView}
              setOpenMessageExpandedView={setOpenMessageExpandedView}
              isTemplate={true}
              index={index}
              expandedViewForMessageIndex={expandedViewForMessageIndex}
            /> */}
          </td>
          <TaskDueDate eachTaskDetail={eachTaskDetail} sectionId={sectionId} />
          <TaskAssignee
            assigneeDetails={{
              assignee_id: eachTaskDetail.assignee_id,
              assignee_name: eachTaskDetail.assignee_name,
              assignee_email: eachTaskDetail.assignee_email,
              assignee_dp: eachTaskDetail.assignee_dp,
            }}
            taskAssigneeChangeHandler={() => {}}
            showAssigneeDropdown={showAssigneeDropdown}
            setShowAssigneeDropdown={setShowAssigneeDropdown}
          />
        </tr>
      );
    } else if (typeof eachTaskDetail.task_id === "string") {
      return (
        <tr className="not-empty">
          <TaskStatus taskStatusChangeHandler={() => {}} />
          <td>
            <TaskPriority taskPriorityChangeHandler={() => {}} />
            <form onSubmit={(e) => e.preventDefault()}>
              <input
                autoFocus
                type="text"
                id={`text-input-${sectionId}-${eachTaskDetail.task_id?.toString()}`}
                className="text-input-box"
                placeholder="Give your task a name"
                value={eachTaskDetail.task_name}
                aria-label="task name"
                maxLength={255}
              />
            </form>
          </td>
          <TaskDueDate />
          <TaskAssignee
            taskAssigneeChangeHandler={() => {}}
            showAssigneeDropdown={showAssigneeDropdown}
            setShowAssigneeDropdown={setShowAssigneeDropdown}
          />
          <td></td>
        </tr>
      );
    }
  };

  return <>{getEachTaskJsx()}</>;
};

export default Task;
