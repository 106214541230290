import { FC } from 'react';
import { useModalStore } from 'stores/modal.store';
import { IModalState } from 'models/interface';

const Modal: FC<{}> = () => {
  const { modalState } = useModalStore((state: IModalState) => state);

  return modalState?.shouldShow ? (
    <div
      className={`deleteFolderModal modal ${modalState?.shouldShow ? 'show-modal' : ''
        }`}
    >
      <div className={`modal-content ${modalState.modalContentClassName}`}>
        <div className="modal-container">
          <div className="modal-header">{modalState?.modalHeader}</div>
          <div className="modal-body">
            <div className="content text-center">
              <div className="createCard">
                <div className="text">{modalState?.modalBody}</div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="btnSec">{modalState?.modalFooter}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Modal;
