import { useCallback, useEffect, useState } from "react";
import TeamType from "./TeamType";
import TeamInfo from "./TeamInfo";
import ProfileWrapper from "./ProfileSetup/ProfileWrapper";
import BrowserStorageService from "services/browserStorage.service";
import {
  useDashboardStore,
  useLoaderStore,
  useLocalizationStore,
  useUserStore,
} from "stores";
import {
  ILoaderState,
  IProfilePayload,
  ITeamSetupPayload,
  IUserOnboardingAttributes,
  IUserState,
} from "models/interface";
import { cmsService } from "services";
import { getPageSpecificData } from "utils/commonFunctions";
import { ComponentLoader, useAnalytics } from "components/sharedComponents";
import { useLocation, useNavigate } from "react-router-dom";
import { NavRoutesEnum } from "../../../models/enums/navRoutes";

const UserOnboardingWrapper: React.FC<{
  pageSlug?: string[];
  cmsPageName?: string[];
}> = ({ pageSlug, cmsPageName }) => {
  const { userDetails } = useUserStore((state: IUserState) => state);
  const { newTeamValue } = useDashboardStore((state) => state);
  const location = useLocation();

  useEffect(() => {
    document.getElementById("root")!.className = "genericWrapper";

    return () => {
      document.getElementById("root")!.className = "";
    };
  }, []);

  const [isCreatingNewTeam, setIsCreatingNewTeam] = useState<boolean>(
    location.pathname === NavRoutesEnum.CREATE_NEW_TEAM
  );
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  const [stepNumber, setStepNumber] = useState(
    userDetails?.additional_settings?.step_number
      ? userDetails.additional_settings.step_number
      : 0
  );

  const getDefaultOrgName = () => {
    if (isCreatingNewTeam) {
      return newTeamValue.teamName;
    }

    return userDetails?.organization_info
      ? userDetails?.organization_info[0].organization_name
      : "";
  };

  const getDefaultOrgLogo = () => {
    if (isCreatingNewTeam) {
      return "";
    }

    return userDetails?.organization_info
      ? userDetails?.organization_info[0].organization_logo ?? ""
      : "";
  };

  const getDefaultTeamTypeId = () => {
    if (isCreatingNewTeam) {
      return null;
    }

    return userDetails?.organization_info
      ? userDetails?.organization_info[0].team_type_id
      : null;
  };

  const getDefaultAUthOrgId = () => {
    if (isCreatingNewTeam) {
      return null;
    }

    return userDetails?.organization_info
      ? userDetails?.organization_info[0].auth_org_id
      : null;
  };

  const getDefaultOrgKey = () => {
    if (isCreatingNewTeam) {
      return null;
    }
    
    return userDetails?.organization_info
      ? userDetails?.organization_info[0].org_key
      : null;
  }

  const [oldTeamDetails, setOldTeamDetails] = useState<ITeamSetupPayload>({
    organization_name: getDefaultOrgName(),
    organization_logo: getDefaultOrgLogo(),
    team_type_id: getDefaultTeamTypeId(),
    auth_org_id: getDefaultAUthOrgId(),
    org_key: getDefaultOrgKey(),
    is_creating_team: isCreatingNewTeam,
  });
  const [teamDetails, setTeamDetails] = useState<ITeamSetupPayload>({
    organization_name: getDefaultOrgName(),
    organization_logo: getDefaultOrgLogo(),
    team_type_id: getDefaultTeamTypeId(),
    auth_org_id: getDefaultAUthOrgId(),
    org_key: getDefaultOrgKey(),
    is_creating_team: isCreatingNewTeam,
  });
  const [profileDetails, setProfileDetails] = useState<IProfilePayload>({
    team_type_id: teamDetails.team_type_id,
    user_name: userDetails?.full_name ?? undefined,
    profile_image: userDetails?.profile_picture ?? undefined,
    hobbies: isCreatingNewTeam ? [] : userDetails?.hobbies ?? undefined,
    education_level: isCreatingNewTeam
      ? undefined
      : userDetails?.education_level ?? undefined,
    education_major_id: isCreatingNewTeam
      ? undefined
      : userDetails?.education_major_id ?? undefined,
    job_title: isCreatingNewTeam
      ? undefined
      : userDetails?.job_title ?? undefined,
    job_role_id: isCreatingNewTeam
      ? undefined
      : userDetails?.job_role_id ?? undefined,
    do_later: false,
    is_creating_team: isCreatingNewTeam,
  });
  const browserStorage = BrowserStorageService.getInstance();
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const [pageCmsData, setPageCmsData] =
    useState<IUserOnboardingAttributes | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stepGraphicClassName, setStepGraphicClassName] =
    useState("teamTypeStepImg");
  const navigate = useNavigate();
  const gaContext = useAnalytics();

  const sendAnalyticsEvent = (eventLabel: string, eventName: string) => {
    gaContext.updateProps({
      username: profileDetails.user_name ?? "New User",
      email: userDetails?.email,
      organization: teamDetails.organization_name ?? "N/A",
      eventName: eventName,
      eventCategory: "User Onboarding",
      eventLabel: eventLabel,
    });
    return true;
  };

  const getCmsData = useCallback(
    async (pageSlug: string[]) => {
      setIsLoading(true);
      let tmpPageData: any;
      let pageSpecificData = cmsData.pageSpecificData;
      if (pageSpecificData.length > 0) {
        tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
        if (!tmpPageData) {
          await cmsService.fetchCmsData(
            cmsData,
            setCmsData,
            cmsPageName,
            pageSlug
          );
          tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
          tmpPageData && setPageCmsData(tmpPageData[0].attributes);
          setLoaderState("inactive");
        } else {
          tmpPageData && setPageCmsData(tmpPageData[0].attributes);
          setLoaderState("inactive");
        }
      } else {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
        tmpPageData && setPageCmsData(tmpPageData[0]?.attributes);
        setLoaderState("inactive");
      }
      setIsLoading(false);
    },
    [browserStorage, cmsPageName, setLoaderState]
  );

  useEffect(() => {
    if (pageSlug) {
      setLoaderState("active");
      (async () => {
        await getCmsData(pageSlug);
      })();
      setLoaderState("inactive");
    }
  }, [getCmsData, pageSlug, setLoaderState]);

  useEffect(() => {
    setLoaderState("active");
    if (!isCreatingNewTeam) {
      if (
        userDetails?.additional_settings?.onboarding_complete === true &&
        userDetails.organization_info
      ) {
        navigate(`/org/${userDetails.organization_info[0].org_key}/dashboard`);
      } else {
        sendAnalyticsEvent(
          "Team Type Selection",
          "Team Type Selection Started"
        );
      }
    } else {
      sendAnalyticsEvent("Initiate Create New Team", "User Creating New Team");
    }
    setLoaderState("inactive");
  }, [userDetails]);

  useEffect(() => {
    switch (stepNumber) {
      case 0:
        setStepGraphicClassName("teamTypeStepImg");
        break;
      case 1:
        setStepGraphicClassName("teamInfoStepImg");
        break;
      case 2:
        setStepGraphicClassName("profileInfoStepImg");
        break;
      default:
        setStepGraphicClassName("teamTypeStepImg");
        break;
    }
  }, [stepNumber]);

  const getStepJsx = () => {
    switch (stepNumber) {
      case 0:
        return (
          <TeamType
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            teamDetails={teamDetails}
            setTeamDetails={setTeamDetails}
            oldTeamDetails={oldTeamDetails}
            setOldTeamDetails={setOldTeamDetails}
            pageCmsData={pageCmsData}
            sendAnalyticsEvent={sendAnalyticsEvent}
            isCreatingNewTeam={isCreatingNewTeam}
          />
        );
      case 1:
        return (
          <TeamInfo
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            teamDetails={teamDetails}
            setTeamDetails={setTeamDetails}
            oldTeamDetails={oldTeamDetails}
            setOldTeamDetails={setOldTeamDetails}
            pageCmsData={pageCmsData}
            setProfileDetails={setProfileDetails}
            sendAnalyticsEvent={sendAnalyticsEvent}
            isCreatingNewTeam={isCreatingNewTeam}
            profileDetails={profileDetails}
          />
        );
      case 2:
        return (
          <ProfileWrapper
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            teamDetails={teamDetails}
            pageCmsData={pageCmsData}
            profileDetails={profileDetails}
            setProfileDetails={setProfileDetails}
            sendAnalyticsEvent={sendAnalyticsEvent}
            isCreatingNewTeam={isCreatingNewTeam}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="onboardingGenericContent">
      <div className="setup-info-panel progress-wrapper">
        <ComponentLoader isLoading={isLoading}>
          <div className="customizedTabNavigation" id="progress-bar-container">
            <ul className="tabs">
              <li
                className={`fillCircle ${stepNumber >= 0 ? "active" : ""}`}
              ></li>
              <li
                className={`fillCircle ${stepNumber >= 1 ? "active" : ""}`}
              ></li>
              <li
                className={`fillCircle ${stepNumber >= 2 ? "active" : ""}`}
              ></li>
            </ul>
            {!isLoading && (
              <div className="progressbarContent" id="progress-content-section">
                {getStepJsx()}
              </div>
            )}
          </div>
        </ComponentLoader>
      </div>
      <div className={`onboardingGraphics teamTypeStepImgUpdated `}>
        <span className="logoDefaultWhite"></span>
      </div>
    </div>
  );
};

export default UserOnboardingWrapper;
