import { FC, useEffect, useState } from "react";
import {
  useDashboardStore,
  useLoaderStore,
  useLocalizationStore,
  useUserStore,
} from "../../../stores";
import {
  ILoaderState,
  IOrganization,
  IProfileSettingCmsData,
  IUserState,
} from "../../../models/interface";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getPageSpecificData } from "../../../utils/commonFunctions";
import { NavRoutesEnum } from "../../../models/enums/navRoutes";
import { apiService, cmsService } from "../../../services";
import ProfileSettingWrapper from "./ProfileSettingWrapper";

const ProfileSettingAndPreferencePage: FC<{
  pageSlug?: string[];
  cmsPageName?: string[];
}> = ({ pageSlug, cmsPageName }) => {
  const { userDetails } = useUserStore((state: IUserState) => state);
  const navigate = useNavigate();
  const [orgDetails, setOrgDetails] = useState<IOrganization[]>([]);
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  useDashboardStore((state: any) => state);

  const { getAccessTokenSilently } = useAuth0();
  const [profileSettingLabel, setProfileSettingLabel] =
    useState<IProfileSettingCmsData | null>(null);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  useEffect(() => {
    if (!userDetails?.additional_settings?.onboarding_complete) {
      navigate(`${NavRoutesEnum.USER_ONBOARDING}`);
    }
    apiService.setTokenGenerator(getAccessTokenSilently);
    if (pageSlug) {
      setLoaderState("active");
      (async () => {
        await getCmsData(pageSlug);
      })();

      setLoaderState("inactive");
    }
  }, []);

  const getCmsData = async (pageSlug: string[]) => {
    let tmpPageData: any;
    let profileSetData: any;
    let taskListData: any;
    let pageSpecificDataStore = cmsData.pageSpecificData;
    if (pageSpecificDataStore.length > 0) {
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      if (!tmpPageData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        setLoaderState("inactive");
      } else {
        setLoaderState("inactive");
      }
      profileSetData = getPageSpecificData(cmsData, "profile-setting");
      taskListData = getPageSpecificData(cmsData, "task-list")

      if (!profileSetData || !taskListData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        profileSetData = getPageSpecificData(cmsData, "profile-setting");
        taskListData = getPageSpecificData(cmsData, "task-list");
        profileSetData && taskListData && setProfileSettingLabel({...profileSetData[0].attributes, ...taskListData[0].attributes});
      } else {
        profileSetData && taskListData && setProfileSettingLabel({...profileSetData[0].attributes, ...taskListData[0].attributes});
      }
    } else {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      profileSetData = getPageSpecificData(cmsData, "profile-setting");
      taskListData = getPageSpecificData(cmsData, "task-list");
      profileSetData && taskListData && setProfileSettingLabel({...profileSetData[0].attributes, ...taskListData[0].attributes});
      setLoaderState("inactive");
    }
  };

  useEffect(() => {
    if (userDetails !== null) {
      setOrgDetails(userDetails.organization_info ?? []);
    }
  }, [userDetails]);

  return (
    
      <ProfileSettingWrapper lblCms={profileSettingLabel} />
    
  );
};

export default ProfileSettingAndPreferencePage;
