import { ICmsData, ILocalizationState } from "models/interface";
import { create } from "zustand";

export const useLocalizationStore = create<ILocalizationState>((set) => ({
  //initial states
  cmsData: {
    globalData: [],
    countrySpecificData: [],
    pageSpecificData: [],
  },

  //actions
  setCmsData: (value: ICmsData) => set({ cmsData: value }),
}));
