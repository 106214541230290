import React, { ErrorInfo, ReactNode } from 'react';
import { loggerService } from 'services';

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether there is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    if (!["local", "dev", "usg_local", "usg_dev"].includes(process.env.REACT_APP_ENVIRONMENT as string)) {
      await loggerService.log({
        message: error.message
          ? error.message + " || ErrorBoundary"
          : "Error from error boundary",
        errors: {
          stack: error?.stack,
        },
      });
    }
    console.log({ error, errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children } = this.props;
    // Check if an error is thrown
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="errorBoundaryWrapper">
            {fallback && 
                <React.Fragment>
                    {fallback}
                </React.Fragment>
            }
            {!fallback && 
                <h2>Oops, there is an error!</h2>
            }
            
            {/* <button type="button" onClick={() => this.setState({ hasError: false })}>
                Try again?
            </button> */}
        </div>
      );
    }

    // Return children components in case of no error
    return children;
  }
}

export default ErrorBoundary;