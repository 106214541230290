import { ICmsData, IRequestCms, IResponseCms } from "../../models/interface";
import { setCmsGlobalData, setCmsPageData } from "../../utils/commonFunctions";
import apiService from "../Api.service";
import loggerService from "../Logger.service";
import BrowserStorageService from "services/browserStorage.service";

class CmsService {
  public fetchCmsData = async (
    cmsData: ICmsData,
    setCmsData: any,
    cmsPageName?: string[],
    pageSlug?: string[]
  ) => {
    const browserStorage = BrowserStorageService.getInstance();
    const localizationData = browserStorage.getLocalization();

    const payload: IRequestCms = {
      languageCode: localizationData?.language ??"en",
      countryCode: localizationData?.country ??"IN",
      page: cmsPageName,
      showGlobalData: !cmsPageName,
    };
    try {
      const result = await apiService.post<IResponseCms>(
        "/cms/getCMSData",
        payload
      );
      if (result) {
        if (result.data?.globalData && result.data.countrySpecificData) {
          setCmsGlobalData(
            cmsData,
            setCmsData,
            result.data.globalData,
            result.data.countrySpecificData
          );
        } else if (result.data?.pageSpecificData) {
          setCmsPageData(
            cmsData,
            setCmsData,
            result.data.pageSpecificData,
            pageSlug
          );
          return pageSlug;
        } else if (result.messageId !== 1) {
          await loggerService.log({
            severity: "High",
            message: `Fetching cms data failed`,
            payload: {
              ApiResponse: result.message,
            },
          });
        }
      }
    } catch (error) {
      await loggerService.log({
        severity: "High",
        message: `/cms/getCMSData api endpoint failure`,
        payload: error,
      });
    }
  };
}

const cmsService = new CmsService();

export default cmsService;
