import React, { useState } from 'react'

const AttachmentField: React.FC<{ defaultMeta: Object, isInEditMode: boolean, addEditField: Function }> = ({ defaultMeta, isInEditMode, addEditField }) => {
    const [payload, setPayload] = useState<any>(defaultMeta)

    const handleInputChange = (key: "name" | "description", value: string) => {
        if (payload) {
            const temp = { ...payload }

            temp[key] = value

            setPayload(temp)
        }
    }

    const submitData = () => {
        delete payload.type
        addEditField(payload)
    }

    return (
        <div>
            <div>
                <div>
                    <label>Name</label>
                    <input type={'text'} value={payload?.name} onChange={(e) => handleInputChange('name', e.target.value)} />
                </div>
                <div>
                    <label>Description</label>
                    <input type={'text'} value={payload?.description} onChange={e => handleInputChange('description', e.target.value)} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                    <button className='btnStyle1' onClick={submitData}>{isInEditMode ? 'Update' : 'Create'} Field</button>
                </div>
            </div>

        </div>
    )
}

export default AttachmentField