import { RoutesEnum } from "models/enums/apiRoutes";
import {
  IEducationMajorMasterResponse,
  IIndustryMasterResponse,
  IJobRoleMasterResponse,
  IOrgRolesMasterResponse,
} from "models/interface/master.interface";
import apiService from "services/Api.service";

class MasterService {
  public getIndustries = async () => {
    return apiService.get<IIndustryMasterResponse>(
      RoutesEnum.GET_INDUSTRY_MASTER
    );
  };
  public getOrgRoles = async () => {
    return apiService.get<IOrgRolesMasterResponse>(
      RoutesEnum.GET_ORGANIZATION_ROLE_MASTER
    );
  };
  public getJobRoles = async () => {
    return apiService.get<IJobRoleMasterResponse>(
      RoutesEnum.GET_JOB_ROLE_MASTER
    );
  };
  public getEducationMajors = async () => {
    return apiService.get<IEducationMajorMasterResponse>(
      RoutesEnum.GET_EDUCATION_MAJOR_MASTER
    );
  };

  public getOrgColorPalette = async () => {
    return apiService.get(RoutesEnum.GET_ORGANIZATION_COLOR_PALETTE)
  }
}

const masterService = new MasterService();
export default masterService;
