import {
    IGetStickerPackListApiResponse,
    IGetStickerPackListPayload,
    IStatusDoneAssociationPaylod,
    IUpdateStickerAssociationPayload
} from "models/interface/customization.interface";
import { RoutesEnum } from "../../models/enums/apiRoutes";
import ApiService from "../Api.service";

class CustomizationService {

    public getStickerList = async (getStickerPackListPayload: IGetStickerPackListPayload) => {
        return ApiService.get<IGetStickerPackListApiResponse>(
            RoutesEnum.GET_STICKER_PACK_LIST,
            getStickerPackListPayload
        );
    }

    public updateStickerAssociation = async (updateStickerAssociationPayload: IUpdateStickerAssociationPayload) => {
        return ApiService.post<any>(
            RoutesEnum.UPDATE_STICKER_PACK_ASSOCIATION,
            updateStickerAssociationPayload
        );
    }

    public updateStatusDoneAssociation = async (updateStatusDoneAssociation: IStatusDoneAssociationPaylod) => {
        return ApiService.post<any>(
            RoutesEnum.UPDATE_STATUS_DONE_ASSOCIATION,
            updateStatusDoneAssociation
        );
    }

}

const customizationService = new CustomizationService();

export default customizationService;
