import { useRef } from 'react';
import ReactFlow, { useNodesState } from 'reactflow';

import 'reactflow/dist/style.css';
import ScreenshotContainer from './ScreenshotContainer';
import { useFeedbackStore } from 'stores/feedback.store';

const nodeTypes = {
  containerNode: ScreenshotContainer,
};

const initialNodes: any = [
  {
    id: '1',
    type: 'containerNode',
    dragHandle: '.custom-drag-handle',
    position: { x: 0, y: 0 },
    zoomLevel: 2,
  },
];

const CanvasStructure = () => {
  const { panOnDrag } = useFeedbackStore((state: any) => state);
  const defaultViewport = { x: 0, y: 0, zoom: 0.4 };
  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const reactFlowWrapper = useRef(null);

  return (
    <div className="pncSectionInnerWrraper">
      <div ref={reactFlowWrapper} className="canvasContainer">
        <ReactFlow
          nodes={nodes}
          defaultViewport={defaultViewport}
          onNodesChange={onNodesChange}
          nodesDraggable={false}
          nodeTypes={nodeTypes}
          panOnScroll
          selectionOnDrag
          maxZoom={3}
          minZoom={0.01}
          panOnDrag={panOnDrag}
        ></ReactFlow>
      </div>
    </div>
  );
};

export default CanvasStructure;
