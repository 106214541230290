import { FC } from 'react';
import {
  IProjectCreationAttributes,
  IProjectState,
  IProjectSubmitData,
} from 'models/interface';
import { UseFormRegister } from 'react-hook-form';
import { modeOps } from '../../../models/enums/constants';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useProjectDashboardStore } from 'stores';

const RightSideDrawerProjectNameInputField: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  register: UseFormRegister<IProjectSubmitData>;
  errors: any;
  mode: modeOps;
  onBlur?: () => void;
}> = ({ pageSpecificDataState, register, errors, mode, onBlur = () => {} }) => {
  const { hasPermissions } = useRbac();
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const cmsModeMapping: Record<modeOps, string | undefined> = {
    [modeOps.ADD]: pageSpecificDataState?.lbl_project_name,
    [modeOps.CLONE]: pageSpecificDataState?.lbl_clone_project_name,
    [modeOps.EDIT]: pageSpecificDataState?.lbl_project_name,
  };

  return (
    <div className="inputHolder">
      <label htmlFor="projectName">{cmsModeMapping[mode]}</label>
      <input
        type="text"
        id="projectName"
        className={`${errors.projectName && 'fldError'}`}
        aria-label="project name"
        maxLength={50}
        {...register('projectName', {
          required: true,
        })}
        disabled={
          mode === modeOps.EDIT &&
          !hasPermissions(
            [ERbacPermissions.PROJECT_EDIT],
            projectDetails?.associated_role_id,
          )
        }
        onBlur={onBlur}
      />
      {errors?.projectName && (
        <span className="fldMsg universalErr">
          {errors?.projectName?.message}
        </span>
      )}
    </div>
  );
};

export default RightSideDrawerProjectNameInputField;
