import { useLocation } from "react-router-dom";
import { useAnalytics } from "components/sharedComponents";
import {
  IEmptyProjectDashboardAttributes,
  IProjectState,
} from "models/interface";
import { useProjectDashboardStore, useUserStore } from "stores";
import { getCurrentOrganization } from "utils/commonFunctions";

const EmptyProjectDashboard: React.FC<{
  pageSpecificationData: IEmptyProjectDashboardAttributes;
  getNewEmptySection: Function;
}> = ({ pageSpecificationData, getNewEmptySection }) => {
  const gaContext = useAnalytics();
  const location = useLocation();
  const org_key = location.pathname.split("/")[2];

  const { userDetails } = useUserStore((state: any) => state);

  const projectDetails = useProjectDashboardStore(
    (state: IProjectState) => state.projectDetails
  );
  const setProjectDetails = useProjectDashboardStore(
    (state: IProjectState) => state.setProjectDetails
  );

  const newSectionCreateHandler = (sectionType: number) => {
    switch (sectionType) {
      case 1:
        if (
          !projectDetails?.sections ||
          projectDetails?.sections?.length === 0
        ) {
          setProjectDetails({
            ...projectDetails,
            sections: [{ ...getNewEmptySection() }],
          });
        } else {
          setProjectDetails({
            ...projectDetails,
            sections: [
              ...projectDetails.sections,
              { ...getNewEmptySection() },
            ],
          });
        }
        break;
      case 2:
        if (
          !projectDetails?.sections ||
          projectDetails?.sections?.length === 0
        ) {
          setProjectDetails({
            ...projectDetails,
            sections: [
              {
                ...getNewEmptySection(),
                section_type_id: 2,
              },
            ],
          });
        } else {
          setProjectDetails({
            ...projectDetails,
            sections: [
              ...projectDetails.sections,
              {
                ...getNewEmptySection(),
                section_type_id: 2,
              },
            ],
          });
        }
        break;
      case 3:
        if (
          !projectDetails?.sections ||
          projectDetails?.sections?.length === 0
        ) {
          setProjectDetails({
            ...projectDetails,
            sections: [{ ...getNewEmptySection(), section_type_id: 3 }],
          });
        } else {
          setProjectDetails({
            ...projectDetails,
            sections: [
              ...projectDetails.sections,
              { ...getNewEmptySection(), section_type_id: 3 },
            ],
          });
        }
        break;
    }
  };

  const sendAnalyticsEvent = (sectionType: number) => {
    let eventName = "";

    switch (sectionType) {
      case 1:
        eventName = "simple task list section creation started";
        break;
      case 2:
        eventName = "2d grid list section creation started";
        break;
      case 3:
        eventName = "memo pad section creation started";
        break;
    }

    gaContext.updateProps({
      username: userDetails.full_name,
      email: userDetails.email,
      organization: getCurrentOrganization(
        org_key,
        userDetails.organization_info
      )
        ? getCurrentOrganization(org_key, userDetails.organization_info)
          ?.organization_name
        : "",
      eventName: eventName,
      eventCategory: "Projects",
      eventLabel: "Create Section",
    });

    return true;
  };

  return (
    <div className="CNPScreenDummy">
      <p>{pageSpecificationData.lbl_body_header}</p>
      <div className="row">
        <div className="col-lg-4">

          <div className="createCard taskList" onClick={() => {
            sendAnalyticsEvent(1);
            newSectionCreateHandler(1);
          }}
            onKeyDown={() => { }}>
            <div className="icon" />
            <div className="text">
              <h3>{pageSpecificationData.lbl_card_new_task_list}</h3>
              <p>{pageSpecificationData.desc_card_new_task_list}</p>
            </div>
          </div>

        </div>
        <div className="col-lg-4">

          <div className="createCard gridList" onClick={() => {
            sendAnalyticsEvent(2);
            newSectionCreateHandler(2);
          }}
            onKeyDown={() => { }}>
            <div className="icon" />
            <div className="text">
              <h3>{pageSpecificationData.lbl_card_new_grid_list}</h3>
              <p>{pageSpecificationData.desc_card_new_grid_list}</p>
            </div>
          </div>

        </div>
        <div className="col-lg-4">

          <div className="createCard memoPad" onClick={() => {
            sendAnalyticsEvent(3);
            newSectionCreateHandler(3);
          }}
            onKeyDown={() => { }}>
            <div className="icon" />
            <div className="text">
              <h3>{pageSpecificationData.lbl_card_new_memo_pad}</h3>
              <p>{pageSpecificationData.desc_card_new_memo_pad}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default EmptyProjectDashboard;
