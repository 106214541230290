import { Fragment, useEffect, useRef, useState } from 'react';
import {
  doc,
  getDoc,
  onSnapshot,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { db } from 'utils/firebase';
import { Dropdown, MenuProps, Popconfirm, Popover, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useUserStore } from 'stores';
import { IProjectMember } from 'models/interface';
import { generateUserAvatar, getDateFormat } from 'utils/commonFunctions';
import { SectionTypeMessages } from 'models/enums/messages';
import BrowserStorageService from '../../../../../services/browserStorage.service';

enum MessageThreadOption {
  EDIT_MESSAGE = 'edit_message',
  DELETE_MESSAGE = 'delete_message',
}

const adminUser = {
  full_name: process.env.REACT_APP_CLEVER_SORT_USER_NAME,
  user_id: Number(process.env.REACT_APP_CLEVER_SORT_USER_ID) || -1,
};

const lbl_delete_message_popover_config = {
  body: 'The message will not appear to anyone',
  cancel: 'Cancel',
  header: 'Delete the message',
  confirm: 'Delete',
};

const lbl_message_modification_option = [
  {
    key: 'edit_message',
    label: 'Edit',
    className: 'cmnIcon edit',
  },
  {
    key: 'delete_message',
    label: 'Delete',
    className: 'actionIons cmnIcon deleteBin',
  },
];

const MessageThread: React.FC<{
  sectionType: string;
  sectionId: number | string;
  taskId: number | string;
  threadName: string;
  sectionName: string;
  taskDetails: any;
  templateDetails?: any;
  orgId?: number;
  teamMember?: IProjectMember[];
  index?: number;
  setExpandedViewForMessageIndex?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  setOpenMessageExpandedView?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  sectionType,
  sectionId,
  taskId,
  threadName,
  taskDetails,
  templateDetails,
  teamMember,
  setOpenMessageExpandedView,
  setExpandedViewForMessageIndex,
  index,
}) => {
  const refDiv = useRef<HTMLDivElement>(null);
  const [showMessagesPopup, setShowMessagesPopup] = useState(false);
  const [messages, setMessages] = useState([]);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [messageInfo, setMessageInfo] = useState({
    is_visible: false,
    count: 0,
  });

  const { userDetails } = useUserStore((state: any) => state);
  const browserStorage = BrowserStorageService.getInstance();

  let stageENV =
    process.env.REACT_APP_STAGE === 'prod' ||
    process.env.REACT_APP_STAGE === 'staging'
      ? 'prod'
      : process.env.REACT_APP_STAGE;

  const messageThreadId = `${sectionType}-${stageENV}-CS-${templateDetails.template_id}-${sectionId}-${taskId}`;

  const isAdminMessage = (user_id: number) => {
    return user_id === adminUser.user_id;
  };

  const getUser = (user_id: number) => {
    if (isAdminMessage(user_id)) {
      return adminUser;
    }
  };

  const deleteMessage = async (messageId: string) => {
    let data = await getDoc(doc(db, 'message-thread-admin', messageThreadId));
    let messages = data.data()!.messages;

    let props = messages.find((ele: any) => ele.message_id === messageId);

    props.updated_at = Timestamp.now();
    props.is_delete = true;

    await updateDoc(doc(db, 'message-thread-admin', messageThreadId), {
      messages: messages,
      updated_at: Timestamp.now(),
    });
    setOpenPopover(false);
  };

  const showUnreadIndicator = (messages: any) => {
    if (messages.length > 0) {
      let taggedUsers = messages.map((ele: any) => ele.tagged_user_id).flat();

      let uniqueTaggedUsers = [...new Set(taggedUsers)];

      if (
        sectionType === SectionTypeMessages.GRID_LIST_TASK ||
        sectionType === SectionTypeMessages.GRID_LIST_STAGE
      ) {
        return (
          taskDetails.created_by === userDetails.user_id ||
          uniqueTaggedUsers.includes(userDetails.user_id)
        );
      } else {
        return (
          taskDetails.created_by === userDetails.user_id ||
          taskDetails.assignee_id === userDetails.user_id ||
          uniqueTaggedUsers.includes(userDetails.user_id)
        );
      }
    }
    return false;
  };

  const getUserName = (userId: number) => {
    if (isAdminMessage(userId)) {
      return <span className="userNameElement">{adminUser.full_name}</span>;
    }
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setShowMessagesPopup(false);
        localStorage.removeItem('currentMessageId');
      }
    };
    window.addEventListener('keydown', handleEscKeyPress);

    const unSub = onSnapshot(
      doc(db, 'message-thread-admin', messageThreadId),
      (doc) => {
        if (doc.exists()) {
          setMessages(doc.data().messages);
          setMessageInfo({
            is_visible: showUnreadIndicator(doc.data().messages),
            count: doc.data().messages.length,
          });
        }
      },
    );

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
      unSub();
    };
  }, []);

  useEffect(() => {
    if (messages.length) {
      refDiv.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [messages.length]);

  useEffect(() => {
    if (showMessagesPopup) {
      setMessageInfo({
        ...messageInfo,
        is_visible: false,
      });
    }
  }, [showMessagesPopup]);

  const content = (
    <div className={`messageBox show`} id="msgBoxPopup">
      <div className="msgBoxTop d-flex">
        <div className="msgHeading">
          <Tooltip title={threadName} color={'#2E364C'} placement="right">
            {threadName}
          </Tooltip>
        </div>
        <span
          className="openMessagePopup"
          onClick={() => {
            if (setOpenMessageExpandedView) {
              setShowMessagesPopup(false);
              if (
                index !== undefined &&
                setExpandedViewForMessageIndex !== undefined
              ) {
                setExpandedViewForMessageIndex(index);
              }
              setOpenMessageExpandedView(true);
            }
          }}
        ></span>
        <div
          className="closeMsgBox"
          onClick={() => {
            setShowMessagesPopup(false);
            localStorage.removeItem('currentMessageId');
          }}
          onKeyDown={() => {}}
        >
          <span
            className="material-icons-outlined modal-close"
            id="msgBoxClose"
          >
            close
          </span>
        </div>
      </div>
      <div className="msgBody">
        {messages
          .filter((ele: any) => !ele.is_delete)
          .map((ele: any) => {
            let items: MenuProps['items'] = [];

            if (ele.user_id === userDetails.user_id) {
              items = lbl_message_modification_option?.map((ele: any) => {
                return ele.key === MessageThreadOption.DELETE_MESSAGE
                  ? {
                      key: ele.key,
                      label: (
                        <Popconfirm
                          title={lbl_delete_message_popover_config.header}
                          description={lbl_delete_message_popover_config.body}
                          okText={lbl_delete_message_popover_config.confirm}
                          cancelText={lbl_delete_message_popover_config.cancel}
                          placement="leftTop"
                          icon={
                            <QuestionCircleOutlined style={{ color: 'red' }} />
                          }
                          arrow={false}
                          open={openPopover}
                          onConfirm={() =>
                            deleteMessage(
                              localStorage.getItem('currentMessageId')!,
                            )
                          }
                          onCancel={() => {
                            localStorage.removeItem('currentMessageId');
                            setOpenPopover(false);
                          }}
                          overlayClassName="customAntDeletePopover"
                        >
                          <div
                            className="optionWrap"
                            onClick={() => setOpenPopover(true)}
                            onKeyDown={() => {}}
                          >
                            <span className={ele.className}></span>
                            <span>{ele.label}</span>
                          </div>
                        </Popconfirm>
                      ),
                    }
                  : {
                      key: ele.key,
                      label: (
                        <div className="optionWrap">
                          <span className={ele.className}></span>
                          <span>{ele.label}</span>
                        </div>
                      ),
                    };
              });
            }

            return (
              <div className="masseuse userBlkWrap" key={ele.message_id}>
                {generateUserAvatar(
                  getUser(ele.user_id)! as unknown as IProjectMember,
                ).key === 'string' ? (
                  <div className="userAvtr">
                    {
                      generateUserAvatar(
                        getUser(ele.user_id)! as unknown as IProjectMember,
                      ).value
                    }
                  </div>
                ) : (
                  <div className="userAvtr">
                    <img
                      src={
                        generateUserAvatar(
                          getUser(ele.user_id)! as unknown as IProjectMember,
                        ).value
                      }
                    />
                  </div>
                )}
                <div className="msgArea">
                  <div className="userName">
                    {getUserName(ele.user_id)}
                    {!isAdminMessage(ele.user_id) ? (
                      <span className="msgDueDate">
                        {getDateFormat(ele.created_at.toDate(), {})}
                      </span>
                    ) : null}
                    {ele.is_edited && !isAdminMessage(ele.user_id) ? (
                      <span className="editedMessageMarker">Edited</span>
                    ) : null}
                  </div>
                  {!ele?.is_delete ? (
                    <div
                      className="msgText"
                      dangerouslySetInnerHTML={{ __html: ele.content }}
                    ></div>
                  ) : (
                    <div className="msgTextDeleted">
                      <span className="deletedTxtFormatting">
                        This message was deleted
                      </span>
                    </div>
                  )}
                </div>
                {!isAdminMessage(ele.user_id) ? (
                  <Fragment>
                    {ele.user_id === userDetails.user_id && !ele.is_delete ? (
                      <Dropdown
                        menu={{ items }}
                        placement="bottomLeft"
                        trigger={['click']}
                      >
                        <span
                          className="msgTextMore cmnIcon more"
                          onClick={() =>
                            localStorage.setItem(
                              'currentMessageId',
                              ele.message_id,
                            )
                          }
                          onKeyDown={() => {}}
                        ></span>
                      </Dropdown>
                    ) : null}
                  </Fragment>
                ) : null}
              </div>
            );
          })}
        <div ref={refDiv} />
      </div>
    </div>
  );

  const classNameForMessageThread = (count: number) =>
    count === 0 ? 'emptyMessageThread' : '';

  return (
    <Fragment>
      {!showMessagesPopup ? (
        <div className="messageWrap">
          {messages?.length > 0 && (
            <span
              className={`cmnIcon message ${classNameForMessageThread(
                messages.length,
              )} ${messageInfo.is_visible ? 'unReadMessageIndicator' : ''}`}
              onClick={() => {
                if (teamMember) {
                  browserStorage.setProjectMembers(JSON.stringify(teamMember));
                }
                setTimeout(() => {
                  setShowMessagesPopup(!showMessagesPopup);
                }, 300);
              }}
              onKeyDown={() => {}}
            ></span>
          )}
        </div>
      ) : (
        <Popover
          placement="bottomRight"
          trigger={'click'}
          content={content}
          open={true}
          overlayClassName={'messageThreadClass commentContainer'}
          arrow={false}
        >
          <div className="messageWrap">
            <span
              className={`cmnIcon message`}
              onClick={() => {
                if (teamMember) {
                  browserStorage.setProjectMembers(JSON.stringify(teamMember));
                }
                setTimeout(() => {
                  setShowMessagesPopup(!showMessagesPopup);
                }, 300);
              }}
              onKeyDown={() => {}}
            ></span>
          </div>
        </Popover>
      )}
    </Fragment>
  );
};

export default MessageThread;
