import React from "react";
import color1 from "../../../assets/images/color1.svg";
import color2 from "../../../assets/images/color2.svg";
import color3 from "../../../assets/images/color3.svg";
import color4 from "../../../assets/images/color4.svg";
import color5 from "../../../assets/images/color5.svg";
import color6 from "../../../assets/images/color6.svg";
import color7 from "../../../assets/images/color7.svg";
import color8 from "../../../assets/images/color8.svg";
import color9 from "../../../assets/images/color9.svg";
import color10 from "../../../assets/images/color10.svg";
import color11 from "../../../assets/images/color11.svg";
import color12 from "../../../assets/images/color12.svg";
import color13 from "../../../assets/images/color13.svg";
import color14 from "../../../assets/images/color14.svg";
import color15 from "../../../assets/images/color15.svg";
import color16 from "../../../assets/images/color16.svg";

type TColorPalette = {
    color_id?: number;
    color_name?: string;
    color_rank?: number;
    hex_code?: string;
    rgb_value?: string;
    org_id?: string|number
};

interface IListWithBackgroundColorsProps {
    withPaletteImage?: boolean;
    colors: TColorPalette[]|any;
    callBackFunction: (obj: any) => void;
}

const paletteImages = [
    color1,
    color2,
    color3,
    color4,
    color5,
    color6,
    color7,
    color8,
    color9,
    color10,
    color11,
    color12,
    color13,
    color14,
    color15,
    color16,
];

const ListWithBackgroundColors: React.FC<IListWithBackgroundColorsProps> = ({
    colors,
    callBackFunction,
    withPaletteImage = false,
}) => {
    return (
        <ul className="paletteWrap">
        {colors?.map((color:TColorPalette, index:number) => (
                <li
                key={`${color?.color_id}-${color?.hex_code}-${color?.color_name}`}
                style={{ backgroundColor: color?.hex_code }}
                onClick={() => callBackFunction(color)}
                >
                    {withPaletteImage?<img src={paletteImages[index]} alt="" />:<></>}
                </li>
            ))}
    </ul>
    )
};

export default ListWithBackgroundColors;
