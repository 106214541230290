import {FC, useEffect, useState} from "react";
import { IProfileSettingCmsData} from "../../../../models/interface";
import {Input, message, Select} from "antd";
import {filterSortByRanking} from "../../../../utils/commonFunctions";
import {IEducationMajor} from "../../../../models/interface/master.interface";
import masterService from "../../../../services/Master/master.service";

const SchoolPurposeComponent: FC<{
    lblCms: IProfileSettingCmsData | null,
    defaultEducationLevel?: string | null,
    defaultEducationMajorId?: number | null,
    onChange: (educationLevel: string | null, educationMajorId: number | null) => void
}> = ({lblCms, defaultEducationLevel = null, defaultEducationMajorId = null, onChange}) => {
    const [educationLevel, setEducationLevel] = useState<string | null>(defaultEducationLevel ?? null)
    const [educationMajors, setEducationMajors] = useState<IEducationMajor[]>();

    useEffect(() => {
        onChange(educationLevel, defaultEducationMajorId)
    }, [educationLevel, defaultEducationMajorId]);
    const educationLevelChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        setEducationLevel(e.target.value)
    };

    /**
     * removed select role as currently this option is not available during onboarding
     *
    const handleOnSearch = (value: React.SetStateAction<string>) => {
        setInputValue(value);
    };

    const educationMajorChangeHandler = (education_major_id: number) => {
        setEducationMajorId(education_major_id)
    };
    */

    const fetchEducationMajorsData = async () => {
        try {
            const res = await masterService.getEducationMajors();
            setEducationMajors(res.data.education_majors);
        } catch (error) {
            message.error(
                lblCms?.lbl_error_responses.education_major_data_load_error
            );
        }
    };

    useEffect(() => {
        if (!educationMajors) {
            fetchEducationMajorsData();
        }
        return () => {
            setEducationMajors(undefined);
        };
    }, []);

    return <>
        <div className="row">
            <div className="fldWrap">
                <Input
                    className="teamName"
                    onChange={educationLevelChangeHandler}
                    maxLength={50}
                    value={educationLevel??''}
                />
                <label htmlFor="name">
                    {lblCms?.lbl_profile_education_level}
                </label>
            </div>
        </div>
        {/****
         * removed select role as currently this option is not available during onboarding
         *  {<Select
            placeholder={lblCms?.lbl_education_major_placeholder}
            defaultValue={educationMajorId}
            style={{width: "100%"}}
            onSelect={() => setInputValue("")}
            options={educationMajors}
            showSearch
            onSearch={handleOnSearch}
            filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input?.toLowerCase())
                    ? true
                    : false
            }
            filterSort={(optionA: any, optionB: any) =>
                filterSortByRanking(optionA, optionB, inputValue)
            }
            onChange={educationMajorChangeHandler}
        />} */}
    </>
}

export default SchoolPurposeComponent
