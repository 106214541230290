import { FC, useEffect, useState } from "react";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import {
  IProjectCreationAttributes,
  //   IProjectState,
  IProjectSubmitData,
} from "models/interface";
import {
  //   useDashboardStore,
  //   useProjectDashboardStore,
  useUserStore,
} from "stores";
import ComponentLoader from "../Loaders/ComponentLoader";
// import { modeOps } from "models/enums/constants";

const RightSideDrawerExistingWidgetList: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  register: UseFormRegister<IProjectSubmitData>;
  setValue: UseFormSetValue<IProjectSubmitData>;
  getValues: UseFormGetValues<IProjectSubmitData>;
  isLoading: boolean;
  existingWidgetList: any;
}> = ({ setValue, getValues, existingWidgetList, isLoading, pageSpecificDataState }) => {
  const { userDetails } = useUserStore((state: any) => state);

  return (
    <div className="inputHolder cloneSectionTaskSec">
      <label htmlFor="category">{pageSpecificDataState?.txt_clone_sections_label}</label>
      {isLoading ? (
        <ComponentLoader isLoading={isLoading} />
      ) : (
        <ul className="genericCheckBoxWrp">
          {existingWidgetList &&
            existingWidgetList
              ?.filter(
                (ele: any) =>
                  !ele.is_private ||
                  (ele.is_private &&
                    Number(ele.created_by) === userDetails.user_id)
              )
              .map((ele: any) => (
                <li key={ele.section_id}>
                  <input
                    type="checkbox"
                    id={ele.section_id}
                    name="section"
                    value={ele.section_id}
                    onChange={(e) => {
                      const sectionIds = getValues("sectionIds");
                      setValue(
                        "sectionIds",
                        e.target.checked
                          ? [...sectionIds, ele.section_id]
                          : sectionIds.filter(
                            (id: number) => id !== ele.section_id
                          )
                      );
                    }}
                    defaultChecked={true}
                  />
                  <label htmlFor={ele.section_id}>
                    {ele.section_type_id === 1
                      ? "Task List"
                      : ele.section_type_id === 2
                        ? "Grid List"
                        : "Memopad"}
                    : {ele.section_name}
                  </label>
                  {ele.is_private ? (
                    <span className={`cmnIcon lock`}></span>
                  ) : null}
                </li>
              ))}
        </ul>
      )}
    </div>
  );
};

export default RightSideDrawerExistingWidgetList;
