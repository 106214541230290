import { useEffect, useRef, useState } from "react";
import { Input, InputRef, Popover, Tooltip, message } from "antd";
import {
  IProjectState,
  ITaskListDetails,
  IUserDetails,
  IUserState,
} from "models/interface";
import { useProjectDashboardStore, useUserStore } from "stores";
import { getCurrentOrganization, getUserGravatar } from "utils/commonFunctions";
import OutsideClickHandler from "components/sharedComponents/OutsideClickHandler";
import { TaskListView } from "models/enums/tasklistView";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { projectService } from "services";
import { NotificationEventType } from "models/enums/notification";
import { useLocation } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

const TaskAssignee: React.FC<{
  eachTaskDetail: ITaskListDetails;
  viewType?: TaskListView;
  setTaskListDetails: any;
  taskDetails: any;
  sectionId?: number | string;
  notifyChannel?: (
    userDetails: IUserDetails,
    eventType: string,
    eventValue?: string
  ) => void;
  setEachTaskDetail?: React.Dispatch<React.SetStateAction<ITaskListDetails>>;
  findTaskIdAndUpdateMainTaskList?: (
    eachTaskDetailUpdated: ITaskListDetails
  ) => void;
}> = ({
  eachTaskDetail,
  viewType,
  sectionId,
  notifyChannel,
  setEachTaskDetail,
  findTaskIdAndUpdateMainTaskList,
  setTaskListDetails,
  taskDetails,
}) => {
  const { userDetails } = useUserStore((state: IUserState) => state);
  const { taskListCmsData, gridListCmsData, projectMembers, projectDetails } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { hasPermissions } = useRbac();
  const location = useLocation();
  const org_key = location.pathname.split("/")[2];
  const [showAssigneeDropdown, setShowAssigneeDropdown] = useState(false);
  const [searchMembers, setSearchMemebers] = useState(projectMembers);
  const [searchInput, setSearchInput] = useState("");

  const inputTagRef = useRef<InputRef>(null);

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowAssigneeDropdown(false);
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, []);

  const taskAssigneeChangeHandler = async (user_id: number | null) => {
    const member = projectMembers?.find(
      (eachMember) => eachMember.user_id === user_id
    );
    setShowAssigneeDropdown(false);
    if (typeof eachTaskDetail?.task_id === "string") {
      message.info(taskListCmsData?.error_message_options?.no_task_name, 3);
      return;
    }
    if (setEachTaskDetail) {
      let eachTaskDetailUpdated: any = {
        ...eachTaskDetail,
        assignee_id: user_id,
        assignee_name: member?.full_name,
        assignee_email: member?.email,
        assignee_dp: member?.profile_picture ?? "",
      };
      findTaskIdAndUpdateMainTaskList &&
        findTaskIdAndUpdateMainTaskList(eachTaskDetailUpdated);

      setEachTaskDetail((prev: any) => ({
        ...prev,
        assignee_id: user_id,
        assignee_name: member?.full_name,
        assignee_email: member?.email,
        assignee_dp: member?.profile_picture ?? "",
        assigner_id: +userDetails?.user_id!,
      }));
    }

    if (
      projectDetails?.project_id &&
      typeof sectionId === "number" &&
      userDetails?.organization_info
    ) {
      try {
        const { data, message, messageId } = await projectService.updateTaskV2({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info
          )?.organization_id!,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: [
            {
              ...eachTaskDetail,
              task_id: +eachTaskDetail.task_id,
              assignee_id: user_id,
              assigner_id: +userDetails?.user_id!,
            },
          ],
          updateAssignee: true
        });

        setTaskListDetails((prevState: any) => ({
          ...taskDetails,
          task_list_details: data,
        }));

        if (notifyChannel) {
          notifyChannel(
            userDetails,
            NotificationEventType.UPDATE_SIMPLE_TASK_ROW,
            JSON.stringify({
              ...eachTaskDetail,
              task_id: eachTaskDetail.task_id,
              assignee_id: user_id,
              assignee_email: projectMembers?.find(
                (ele) => ele.user_id === user_id
              )?.email,
              assignee_name: projectMembers?.find(
                (ele) => ele.user_id === user_id
              )?.full_name,
            })
          );
        }
      } catch (error) {
        console.log(error);
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const getAssigneeJsx = () => {
    if (
      eachTaskDetail &&
      eachTaskDetail.assignee_id !== null &&
      eachTaskDetail.assignee_id !== undefined
    ) {
      return (
        <div
          className="userBlkWrap cursorPointer"
          onClick={() => setShowAssigneeDropdown((prev: any) => !prev)}
          onKeyDown={() => {}}
        >
          <Tooltip
            title={
              userDetails?.email === eachTaskDetail?.assignee_email
                ? eachTaskDetail.assignee_name +
                  `\u0020(${taskListCmsData?.task_assignee_custom_text[1]})`
                : eachTaskDetail.assignee_name
            }
            color={"#2E364C"}
            placement="bottom"
          >
            <div
              className={`userAvtr ${
                eachTaskDetail.assignee_dp && "userAvtrImg"
              }`}
            >
              {eachTaskDetail.assignee_dp ? (
                <img src={eachTaskDetail.assignee_dp}></img>
              ) : (
                getUserGravatar(eachTaskDetail.assignee_name ?? "")
              )}
            </div>
          </Tooltip>
          <div className="userName">
            {userDetails?.email === eachTaskDetail?.assignee_email
              ? eachTaskDetail.assignee_name +
                `\u0020(${taskListCmsData?.task_assignee_custom_text[1]})`
              : eachTaskDetail.assignee_name}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="unassignUserBlkWrap userBlkWrap"
          onClick={() => setShowAssigneeDropdown((prev: any) => !prev)}
          onKeyDown={() => {}}
        >
          <Tooltip
            title={taskListCmsData?.task_assignee_custom_text[0]}
            color={"#2E364C"}
            placement="bottom"
          >
            <span className="unassign"></span>
          </Tooltip>
          <div className="userName textMuted">
            {taskListCmsData?.task_assignee_custom_text[0]}
          </div>
        </div>
      );
    }
  };

  const getAssigneeListJsx = () => {
    return projectMembers
      ?.filter(
        (eachMember) => eachMember.user_id !== eachTaskDetail?.assignee_id
      )
      .map((eachMember) => {
        return (
          <li
            key={eachMember.user_id}
            onClick={() => taskAssigneeChangeHandler(eachMember.user_id)}
            onKeyDown={() => {}}
          >
            <div className="userBlkWrap">
              <div
                className={`userAvtr ${
                  eachMember.profile_picture ? "userAvtrImg" : ""
                }`}
              >
                {eachMember.profile_picture ? (
                  <img src={eachMember.profile_picture}></img>
                ) : (
                  getUserGravatar(eachMember.full_name ?? "")
                )}
              </div>
              <div className="userName">
                <p>
                  {userDetails?.email === eachMember.email
                    ? eachMember.full_name +
                      `\u0020(${taskListCmsData?.task_assignee_custom_text[1]})`
                    : eachMember.full_name}
                </p>
                <p>{eachMember.email}</p>
              </div>
            </div>
          </li>
        );
      });
  };

  const content = (
    <div
      className={`assigneeList customFieldTaskList ${
        hasPermissions(
          [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
          projectDetails?.associated_role_id
        )
          ? "show"
          : ""
      }`}
      style={{ position: "unset" }}
    >
      <ul>
        <li>
          <div className="userBlkWrap">
            <Input
              onInput={(e: any) => {
                const value = e.target.value.trim();
                if (!value) setSearchMemebers(projectMembers);
                else {
                  setSearchMemebers(
                    projectMembers?.filter(
                      (eachMember) =>
                        eachMember.full_name
                          ?.toLowerCase()
                          .includes(value.toLowerCase()) ||
                        eachMember.email
                          ?.toLowerCase()
                          .includes(value.toLowerCase())
                    ) ?? []
                  );
                }
                setSearchInput(e.target.value);
              }}
              size="small"
              placeholder={
                gridListCmsData?.lbl_search_project_member ??
                "Search project member"
              }
              prefix={<SearchOutlined />}
              value={searchInput}
              ref={inputTagRef}
            />
          </div>
        </li>
        <li
          className="unassignedTask"
          onClick={() => taskAssigneeChangeHandler(null)}
          onKeyDown={() => {}}
        >
          <div className="userBlkWrap">
            <div className="userPart">
              <div className="userAvtr">
                <span className="cmnIcon unassign"></span>
              </div>
              <div className="userName">
                {" "}
                {taskListCmsData?.task_assignee_custom_text[0]}
              </div>
            </div>
          </div>
        </li>
        {getAssigneeListJsx()}
      </ul>
    </div>
  );

  if (viewType === TaskListView.CARD) {
    return <>{getAssigneeJsx()}</>;
  }

  return (
    <Popover
      content={content}
      placement="top"
      trigger="click"
      open={showAssigneeDropdown}
      overlayClassName="customFieldAssigneePopover"
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setShowAssigneeDropdown(isOpen);
          setSearchInput("");
        }
      }}
    >
      {getAssigneeJsx()}
    </Popover>
  );
};

export default TaskAssignee;
