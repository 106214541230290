import axios from "axios";

class GeolocationService {
  public getUserGeolocationInfo = async () => {
    let request = await axios({
      method: "get",
      url: `${process.env.REACT_APP_GEOLOCATION_URL}?key=${process.env.REACT_APP_GEOLOCATION_KEY}`,
      responseType: "json",
    });
    return request.data;
  };
}

const geolocationService = new GeolocationService();
export default geolocationService;
