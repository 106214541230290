import dayjs from "dayjs";
import { ICalendarViewStore } from "models/interface";
import { create } from "zustand";

export const useCalendarViewStore = create<ICalendarViewStore>((set) => ({
    selectedYear: null,
    selectedWeek: null,
    startDate: null,
    endDate: null,
    currentDate: null,
    calendarWeekOption: 1
,
    setSelectedYear: (value: number | null) => set({selectedYear: value}),
    setSelectedWeek: (value: number | null) => set({selectedWeek: value}),
    setStartAndEndDate: (value:{
        startDate: string | null,
        endDate: string | null
    }) => set({startDate: value?.startDate,
        endDate: value?.endDate
    }),
    setCurrentDate: (value: string | null) => set({currentDate: value}),
    setCurrentDateAndWeek: () => set({
        currentDate: dayjs().format(),
        startDate: dayjs().startOf("week").format(),
        endDate: dayjs().endOf("week").format(),
        selectedWeek: dayjs().week()
    }),
    setCalendarWeekOption: (value: number) => set({calendarWeekOption: value}),
    resetCalendar: () => set({
        selectedWeek: null,
        startDate: null,
        endDate: null,
        currentDate: null
    })
}))