import React, {useState} from "react";
import { IAnalyticsProps } from "models/interface";
import { AnalyticsContext } from "./AnalyticsContext";


export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [props, setProps] = useState<IAnalyticsProps>({
    organization: '',
    username: '',
    email: '',
    eventName: '',
    eventCategory: '',
    eventLabel: '',
    updateProps: (newProps) => {
      setProps((prevProps) => ({ ...prevProps, ...newProps }));
    },
  });

  return (
    <AnalyticsContext.Provider value={props}>
      {children}
    </AnalyticsContext.Provider>
  );
};