import { FC } from "react";

const EmptyBlock: FC<{}> = () => {

    return (
        // <ul className="subTaskRow">
            // <li>
                <div className="blankCard statusCard">
                    <div className="userBlkWrap">
                        <div className="userAvtr"></div>
                    </div>
                    <div className="status">
                        <span className="cmnIcon calendar"></span>
                        <p></p>
                    </div>
                </div>
            // </li>
        // </ul>
    )
}

export default EmptyBlock