import React, { FC, useEffect } from "react";
import TaskCalendarGrid from "./TaskCalendarGrid";
import CalendarControl from "./CalendarControl";
import { useCalendarViewStore } from "stores/calendarView.store";
import { ICalendarViewStore } from "models/interface";
import dayjs from "dayjs";

const TaskCalendarWrapper: FC<{ pageCmsData: any }> = ({ pageCmsData }) => {
    const {
        setSelectedYear,
        setCurrentDate,
        setSelectedWeek,
        setStartAndEndDate,
        resetCalendar,
    } = useCalendarViewStore((state: ICalendarViewStore) => state);
    
    useEffect(() => {
        setSelectedYear(dayjs().year())
        setCurrentDate(dayjs().format());
        setSelectedWeek(dayjs().week());
        setStartAndEndDate({
            startDate: dayjs().startOf("week").format(),
            endDate: dayjs().endOf("week").format(),
        });
        return () => {
            resetCalendar();
        };
    }, []);

    return (
        <>
            <CalendarControl pageCmsData={pageCmsData}/>
            <TaskCalendarGrid />
        </>
    );
};

export default TaskCalendarWrapper;
