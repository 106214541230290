import { FC, useMemo } from 'react';
import { Dropdown, Tooltip } from 'antd';
import dayjs from 'dayjs';
import {
  IGridList,
  IGridListBlockDetail,
  IGridListTaskDetail,
  IPackConfiguration,
  IPackDetail,
  IProjectState,
} from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import { groupGridBlock } from 'utils/commonFunctions';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';

// Images
import BlockIcon from 'assets/e-images/block-icon.svg';
import TodoIcon from 'assets/e-images/todo-icon.svg';
import DoneIcon from 'assets/e-images/done-icon.svg';

export const getStatusImage = (
  status: number,
  packId: number = 1,
  packList: IPackDetail[] | null,
) => {
  if (packList) {
    let pack = packList.filter((ele: IPackDetail) => ele.pack_id === packId);
    if (pack.length > 0) {
      return pack[0].pack_configuration.find(
        (ele: IPackConfiguration) => ele.status_key === status,
      )!;
    } else {
      return { status_value: '', status_label: '' };
    }
  }
  return { status_value: '', status_label: '' };
};

const BlockStatus: FC<{
  blockId: number;
  blockDetails: IGridListBlockDetail;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  updateBlock: (block: IGridListBlockDetail) => Promise<void>;
}> = ({
  blockDetails,
  blockId,
  gridListDetails,
  setGridListDetails,
  updateBlock,
}) => {
  const { hasPermissions } = useRbac();
  const { taskListCmsData, projectDetails, packList, taskStatusMaster } =
    useProjectDashboardStore((state: IProjectState) => state);

  // Memos
  const statusItems = useMemo(() => {
    const defaultItems = [
      {
        label: (
          <div className="customDropDownItem">
            <span className="cmnIcon">
              <img src={TodoIcon} alt="UserIcon" />
            </span>
            {taskListCmsData?.task_status_options[0].status_name}
          </div>
        ),
        key: 1,
      },
      {
        label: (
          <div className="customDropDownItem">
            <span className="cmnIcon">
              <img src={DoneIcon} alt="UserIcon" />
            </span>
            {taskListCmsData?.task_status_options[2].status_name}
          </div>
        ),
        key: 3,
      },
      {
        label: (
          <div className="customDropDownItem">
            <span className="cmnIcon">
              <img src={BlockIcon} alt="UserIcon" />
            </span>
            {taskListCmsData?.task_status_options[3].status_name}
          </div>
        ),
        key: 4,
      },
    ];

    if (blockDetails.block_status_id === 2)
      return defaultItems.filter((item) => item.key !== 1);

    return defaultItems.filter(
      (item) => item.key !== blockDetails.block_status_id,
    );
  }, [blockDetails, gridListDetails]);

  const setStatus = (statusCode: number) => {
    if (gridListDetails) {
      let blockDetails =
        gridListDetails.grid_list_details?.block_details!.flat()!;
      let targetBlock = blockDetails.find(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;
      let targetBlockIndex = blockDetails.findIndex(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;

      let _status_id = statusCode;
      const isOverDue =
        dayjs(targetBlock.due_date).diff(
          dayjs(new Date().setHours(0, 0, 0, 0)),
          'days',
        ) < 0;

      if (taskStatusMaster && _status_id > taskStatusMaster?.length) {
        _status_id = isOverDue ? 2 : 1;
      }

      if (_status_id === 2) {
        _status_id = 3;
      }

      if (statusCode === 1 && isOverDue) _status_id = 2;

      targetBlock.block_status_id = _status_id;
      blockDetails.splice(targetBlockIndex, 1, targetBlock);

      let completedBlockCount = blockDetails.filter(
        (ele: IGridListBlockDetail) =>
          ele.task_id === targetBlock.task_id && ele.block_status_id === 3,
      )!;

      let taskDetails = gridListDetails.grid_list_details?.task_details!;

      if (taskDetails) {
        let targetTask = taskDetails?.find(
          (ele: IGridListTaskDetail) => ele.task_id === targetBlock.task_id,
        )!;
        let targetTaskIndex = taskDetails?.findIndex(
          (ele: IGridListTaskDetail) => ele.task_id === targetBlock.task_id,
        )!;
        targetTask.completed_block_count = completedBlockCount.length;
        taskDetails.splice(targetTaskIndex, 1, targetTask);
      }

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          block_details: groupGridBlock(
            blockDetails,
            gridListDetails.grid_list_details!.task_details!,
          ) as Array<IGridListBlockDetail[]>,
          task_details: taskDetails,
        },
      } as IGridList);

      updateBlock(targetBlock);
    }
  };

  const getStatusNameForTooltip = () => {
    if (gridListDetails) {
      let blockDetails =
        gridListDetails.grid_list_details?.block_details!.flat()!;
      let target = blockDetails?.find(
        (ele: IGridListBlockDetail) => ele.block_id === blockId,
      )!;

      return taskListCmsData?.task_status_options?.find(
        (eachStatus) => eachStatus?.status_id === target?.block_status_id,
      )?.status_name;
    }
  };
  return (
    <Tooltip
      title={getStatusNameForTooltip()}
      color={'#2E364C'}
      placement="left"
    >
      {!projectDetails?.is_archived ? (
        <Dropdown
          menu={{
            items: statusItems,
            onClick: (e) => {
              hasPermissions(
                [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_STATUS_EDIT],
                projectDetails?.associated_role_id,
              ) && setStatus(Number(e.key));
            },
          }}
          placement="bottomLeft"
          trigger={['click']}
        >
          {gridListDetails.view_id == 4 && !gridListDetails.compactView ? (
            <div
              style={{
                backgroundImage:
                  'url(' +
                  `${process.env.REACT_APP_STORAGE_BASE_URL}${
                    getStatusImage(
                      blockDetails.block_status_id,
                      gridListDetails!.pack_id,
                      packList,
                    ).status_value
                  }` +
                  ')',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
              className="blkStatus"
            >
              Status
            </div>
          ) : (
            <div className="blkStatus">Status</div>
          )}
        </Dropdown>
      ) : gridListDetails.view_id == 4 && !gridListDetails.compactView ? (
        <div
          style={{
            backgroundImage:
              'url(' +
              `${process.env.REACT_APP_STORAGE_BASE_URL}${
                getStatusImage(
                  blockDetails.block_status_id,
                  gridListDetails!.pack_id,
                  packList,
                ).status_value
              }` +
              ')',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
          className="blkStatus"
        >
          Status
        </div>
      ) : (
        <div className="blkStatus">Status</div>
      )}
    </Tooltip>
  );
};

export default BlockStatus;
