import { Tooltip } from "antd";
import { useDashboardStore, useProjectDashboardStore } from "stores";
import { IProjectList, IProjectState } from "models/interface";
import { useLocation, useNavigate } from "react-router-dom";
import {
  generateNewListForSelectedProjectInFolderProjectList,
  generateNewListForSelectedProjectInProjectList,
} from "utils/generateNewList";
import { useOrganizationRoutes } from "routes/orgRoutes";

export const FolderProjectCollapsedView: React.FC = () => {
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const navigate = useNavigate();
  const {
    projectList,
    setProjectList,
    folderProjectList,
    setFolderProjectList,
  } = useDashboardStore((state: any) => state);

  useProjectDashboardStore((state: IProjectState) => state);
  const location = useLocation();

  const navigateToProjectDetails = (id: number) => {
    let updatedProjectList = generateNewListForSelectedProjectInProjectList(
      id,
      projectList
    );
    setProjectList(updatedProjectList);
    let updatedProjectFolderList =
      generateNewListForSelectedProjectInFolderProjectList(
        id,
        folderProjectList
      );
    setFolderProjectList(updatedProjectFolderList);
    document.getElementById("rightSideDrawerCloseIcon")?.click();
    navigate(`/org/${location.pathname.split("/")[2]}/project/${id}`);
  };

  return (
    projectList.length > 0 && <ul className="projectList">
      <li className="parentli">
        <ul id="subItemMenu1">
          {projectList.map((ele: IProjectList) => (
            <li
              key={ele.project_id}
              className={
                location.pathname
                  .split("/")
                  .lastIndexOf(ele?.project_id?.toString()) > 0
                  ? "active-item"
                  : ""
              }
            >
              <div className="itemNameBlk">
                <Tooltip
                  title={ele.project_name}
                  color={"#2E364C"}
                  placement="right"
                >
                  <span
                    className="sidePanelIcon layers"
                    onClick={() => navigateToProjectDetails(ele?.project_id)}
                    onKeyDown={() => { }}
                  ></span>
                </Tooltip>
              </div>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
};
