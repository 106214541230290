import { useAuth0 } from "@auth0/auth0-react";
import { Radio, RadioChangeEvent, Space, Typography } from "antd";
import { ITeamSetupPayload, IUserOnboardingAttributes } from "models/interface";
import { useNavigate } from "react-router-dom";
import BrowserStorageService from "services/browserStorage.service";
import { useUserStore } from "stores";

interface ITeamType {
  stepNumber: number;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
  teamDetails: ITeamSetupPayload;
  setTeamDetails: React.Dispatch<React.SetStateAction<ITeamSetupPayload>>;
  oldTeamDetails: ITeamSetupPayload;
  setOldTeamDetails: React.Dispatch<React.SetStateAction<ITeamSetupPayload>>;
  pageCmsData: IUserOnboardingAttributes | null;
  sendAnalyticsEvent: (eventLabel: string, eventName: string) => boolean;
  isCreatingNewTeam: boolean;
}

const TeamType: React.FC<ITeamType> = ({
  stepNumber,
  setStepNumber,
  teamDetails,
  setTeamDetails,
  pageCmsData,
  sendAnalyticsEvent,
  isCreatingNewTeam,
}) => {
  const continueClickHandler = () => {
    sendAnalyticsEvent("Team Type Selection", "Team Type Selection Finished");
    sendAnalyticsEvent("Team Creation/Update", "Team Creation/Update Started");
    setStepNumber((prev) => prev + 1);
  };

  const userDetails = useUserStore((state) => state.userDetails);
  const browserStorage = BrowserStorageService.getInstance();

  const handleTeamTypeSelection = (e: RadioChangeEvent) => {
    setTeamDetails((prev) => ({
      ...prev,
      team_type_id: parseInt(e.target.value),
    }));
  };

  const navigate = useNavigate();
  const { logout } = useAuth0();

  return (
    <div
      className={`section-content ${stepNumber === 0 ? "active" : ""}`}
      id="user-onboard-team-type"
    >
      <div>
        <h1>
          {isCreatingNewTeam
            ? pageCmsData?.lbl_choose_your_team_type
            : pageCmsData?.lbl_team_type_header1}
        </h1>
        <div className="subHeading">
          <h2>{pageCmsData?.lbl_team_type_header2}</h2>
          <p>{pageCmsData?.dsc_team_type}</p>
        </div>
        <div className="radioItemHolder">
          <Radio.Group
            onChange={handleTeamTypeSelection}
            value={teamDetails.team_type_id}
          >
            <Space direction="vertical">
              {pageCmsData?.lbl_radio_team_type?.map((eachTeamType) => (
                <Radio key={eachTeamType.id} value={eachTeamType.value}>
                  {eachTeamType.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>
      </div>
      <div>
        <div
          style={{
            justifyContent: isCreatingNewTeam ? "space-between" : "right",
          }}
          className="btnDiv"
        >
          {isCreatingNewTeam ? (
            <button
              id="btnTeamInfoBack"
              className="backBtn"
              onClick={() => navigate(-1)}
            >
              <span className="sidePanelIcon leftArrow"></span>{" "}
              {pageCmsData?.lbl_back_cta}
            </button>
          ) : null}
          <button
            id="btnTeamTypeContinue"
            className={`${
              teamDetails.team_type_id === null ? "disabledBtn" : "btnStyle3"
            }`}
            onClick={continueClickHandler}
            disabled={teamDetails.team_type_id === null}
          >
            {pageCmsData?.btn_continue}
          </button>
        </div>
        <span className="notUserText">
          Not <b>{userDetails?.email}?</b>{" "}
          <Typography.Link
            onClick={() => {
              localStorage.removeItem("latestPathName");
              localStorage.removeItem("selectedThreadId");
              localStorage.removeItem("currentMessageId");
              localStorage.removeItem("project_members");
              sessionStorage.removeItem("lastLocation");
              browserStorage.clearGlobalData();
              logout({
                logoutParams: { returnTo: window.location.origin },
              });
            }}
            onKeyDown={() => {}}
          >
            Logout
          </Typography.Link>
        </span>
      </div>
    </div>
  );
};
export default TeamType;
