import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { withRbac } from "./rbac/rbac";
import AuthService from "services/Auth0/authService";
const authService = AuthService.getInstance();

const onRedirectingLoader = () => {
  return <></>
}

export const AuthenticationGuard: React.FC<{
  component: React.ComponentType<object>;
  pageSlug?: string[];
  cmsPageName?: string[];
}> = ({ component, pageSlug, cmsPageName }) => {

  const Component = withAuthenticationRequired(withRbac(component), {
    loginOptions: {
      authorizationParams: {
        screen_hint: "login",
      },
    },
    returnTo: window.location.pathname + window.location.search,

    onRedirecting: () =>  onRedirectingLoader(),

    onBeforeAuthentication: async () => {
      authService.clearCurrentOrganization();
    },
  });

  return <Component pageSlug={pageSlug} cmsPageName={cmsPageName}/>;
};
