import { Input, InputRef } from "antd";
import {
  IProfilePayload,
  IUserOnboardingAttributes,
  IUserState,
} from "models/interface";
import React, { useEffect, useRef, useState } from "react";
import TagsInput from "react-tagsinput";
import { useUserStore } from "stores";
import { debounce } from "utils/commonFunctions";

interface IPersonalProfile {
  stepNumber: number;
  pageCmsData: IUserOnboardingAttributes | null;
  profileDetails: IProfilePayload;
  setProfileDetails: React.Dispatch<React.SetStateAction<IProfilePayload>>;
  profileRequiredChecksPassed?: boolean;
  setProfileRequiredChecksPassed: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  isCreatingNewTeam: boolean;
}

const PersonalProfile: React.FC<IPersonalProfile> = ({
  stepNumber,
  pageCmsData,
  profileDetails,
  setProfileDetails,
  profileRequiredChecksPassed,
  setProfileRequiredChecksPassed,
  isCreatingNewTeam,
}) => {
  const [hobbies, setHobbies] = useState<string[]>([]);
  const { userDetails } = useUserStore((state: IUserState) => state);
  const userNameRef = useRef<InputRef | null>(null);
  const hobbiesRef = useRef<any>(null);

  const requiredFieldsCheck = () => {
    if (
      profileDetails.user_name?.trim()?.length === 0 ||
      !profileDetails.user_name
    ) {
      setProfileRequiredChecksPassed(false);
    } else if (
      profileDetails.user_name?.length < 6 ||
      profileDetails.user_name?.length > 30
    ) {
      setProfileRequiredChecksPassed(false);
    } else {
      setProfileRequiredChecksPassed(true);
    }
  };

  const userNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfileDetails((prev) => ({ ...prev, user_name: e.target.value }));
  };

  const hobbyChangeHandler = (tags: string[]) => {
    setHobbies(tags);
    setProfileDetails((prev)=>({...prev, hobbies:tags}))
  };

  const defaultRenderInput = (props: any) => {
    let { onChange, value, addTag, ...other } = props;
    return (
      <React.Fragment>
        <input
          type="text"
          onChange={onChange}
          value={value}
          maxLength={50}
          placeholder={pageCmsData?.lbl_profile_hobbies_placeholder}
          {...other}
        />
        <label className="chipTextAreaLabel">
          {pageCmsData?.lbl_profile_hobbies}
        </label>
      </React.Fragment>
    );
  };

  useEffect(() => {
    profileDetails.user_name && requiredFieldsCheck();
  }, [profileDetails]);

  useEffect(() => {
    if(!profileDetails?.user_name){
      userNameRef.current?.focus();
      return;
    }
      hobbiesRef.current?.focus();
  }, [stepNumber]);

  return (
      <div className="completeProfileFieldsDevClass completeProfileFields">
        <div className="row">
          <div className="fldWrap">
            <Input
              ref={userNameRef}
              className="profileUserName"
              onChange={userNameChangeHandler}
              minLength={6}
              maxLength={30}
              disabled={isCreatingNewTeam}
              value={profileDetails.user_name}
              status={profileRequiredChecksPassed === false ? "error" : ""}
            />
            <label htmlFor="education level">
              {pageCmsData?.lbl_profile_user_full_name}*
            </label>
            {profileRequiredChecksPassed === false ? (
            <span className="fldError fldMsg">
              {pageCmsData?.lbl_error_responses.username_validation_error_msg}
            </span>
          ) : null}
          </div>
        </div>
        <div className="row">
          <div className="textArea-chip">
            <TagsInput
              inputProps={{
                className: "react-tagsinput-input",
                placeholder: `${pageCmsData?.lbl_profile_hobbies_placeholder}`,
              }}
              renderInput={defaultRenderInput}
              value={hobbies}
              onChange={hobbyChangeHandler}
              onlyUnique={true}
              ref={hobbiesRef}
            />
          </div>
        </div>
        <div className="example">
          <p>
            {pageCmsData?.lbl_profile_for_user}
            <span>{userDetails?.email}</span>
          </p>
        </div>
      </div>
  );
};

export default PersonalProfile;
