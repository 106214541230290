import { FC, useState } from "react";
import { Input, Popover } from "antd";
import { projectService } from "services";
import isURL from "validator/lib/isURL";

const { Search } = Input;

const MemopadLink: FC<{
  onChange: (evt: { [key: string]: any }) => void;
  content: { [key: string]: any };
}> = ({ onChange, content }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState<{ [key: string]: any } | null>(
    Object.keys(content.content).length ? content.content : null
  );
  const [defaultLink, setDefaultLink] = useState(
    Object.keys(content.content).length ? content.content.link : ""
  );
  const [searchStatus, setSearchStatus] = useState<
    undefined | "" | "warning" | "error"
  >("");
  const fetchMetaData = async (url: string) => {
    setIsLoading(true);
    if (isURL(url)) {
      const result = await projectService.getWebsiteMeta(url);
      const processResult = { ...result.meta, link: result.link };
      console.log(processResult);
      setMetadata(processResult);
      onChange(processResult);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setSearchStatus("error");
    }
  };

  const navigateToAnotherPage = (url: string) => {
    window.open(url, "_blank")!.focus();
  };

  if (!metadata) {
    return (
      <Search
        autoFocus={true}
        status={searchStatus}
        defaultValue={defaultLink}
        onChange={(e) => {
          setDefaultLink(e.target.value);
          setSearchStatus("");
        }}
        disabled={true}
        placeholder="input search loading default"
        loading={isLoading}
        onBlur={(e) => {
          if (e.target.value.length) {
            return fetchMetaData(e.target.value);
          } else if (Object.keys(content.content).length) {
            setMetadata(content.content);
          }
        }}
        onKeyDown={(e) => {
          if (e.code === "Enter" && defaultLink.length) {
            return fetchMetaData(defaultLink);
          }
        }}
      />
    );
  }

  return (
    <Popover placement="right" content={<></>}>
      <div
        className="linkContainer"
        onClick={() => navigateToAnotherPage(metadata.link)}
        onKeyDown={() => {}}
      >
        <span className="linkUrl">{metadata["link"]}</span>
        <div className="linkDescription">
          <img
            width={100}
            src={metadata["og:image"]}
            alt={metadata["title"]}
            className="linkImage"
          />
          <div className="linkThumbnailLink">
            <span className="linkTitle">{metadata["title"]}</span>
            <p>{metadata["description"]}</p>
          </div>
        </div>
      </div>
    </Popover>
  );
};

export default MemopadLink;
