import { FC } from 'react';
import { IFeedBackBubble } from 'models/interface/pnc.interface';
import { useViewport } from 'reactflow';

const FeedBackBubble: FC<IFeedBackBubble> = ({
  positionCordinate,
  handleClickOnFbMarker,
  feedbackDetailsId,
  isResolved,
  initialNameArray,
  type,
  activeIndex,
}) => {
  const { zoom } = useViewport();

  return (
    <div
      className="commentBubble"
      style={{
        top: positionCordinate?.top,
        left: positionCordinate?.left,
        transform: `scale3d(${type === 'dynamic' ? 1 / zoom : 1},${
          type === 'dynamic' ? 1 / zoom : 1
        },${type === 'dynamic' ? 1 / zoom : 1})`,
      }}
      onClick={(event) =>
        handleClickOnFbMarker && handleClickOnFbMarker(event, feedbackDetailsId)
      }
    >
      <div
        className={`commentBubbleStacked styled commentBubbleForDrawer  ${
          isResolved ? 'resolved' : ''
        } ${feedbackDetailsId === activeIndex && 'selected'}`}
        id={`commentPosition${feedbackDetailsId}`}
      >
        <div
          className={`commentBubbleStackLayer1 ${isResolved ? 'resolved' : ''}`}
        >
          {initialNameArray &&
            initialNameArray?.map((shortName, shortNameIndex: number) => {
              return (
                shortNameIndex < 3 && (
                  <div
                    key={`${shortName?.id + shortNameIndex}`}
                    className={`commentBubbleStackLayer2 ${
                      isResolved ? 'resolved' : ''
                    }`}
                    id={`commentBubbleInner${shortName?.id}`}
                    style={{
                      zIndex: `${initialNameArray.length - shortNameIndex}`,
                    }}
                  >
                    <span>{shortName?.shortName}</span>
                  </div>
                )
              );
            })}
          {initialNameArray.length > 3 && (
            <div className="commentBubbleStackLayer2 lastBubble">
              <span>+{initialNameArray.length - 3}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedBackBubble;
