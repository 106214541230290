import { FC } from "react";
import dayjs from "dayjs";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import isLearYear from "dayjs/plugin/isLeapYear";
import { ICalendarViewStore } from "models/interface";
import { useCalendarViewStore } from "stores/calendarView.store";
import { Select } from "antd";

dayjs.extend(isoWeeksInYear);
dayjs.extend(isLearYear);

const CalendarControl: FC<{ pageCmsData: any }> = ({ pageCmsData }) => {
    const {
        selectedYear,
        currentDate,
        selectedWeek,
        startDate,
        endDate,
        calendarWeekOption,
        setSelectedYear,
        setCurrentDate,
        setSelectedWeek,
        setStartAndEndDate,
        setCurrentDateAndWeek,
        setCalendarWeekOption
    } = useCalendarViewStore((state: ICalendarViewStore) => state);

    const changeToNextWeek = () => {
        let numberOfWeeks = dayjs(`${selectedYear}`).isoWeeksInYear();
        let week = dayjs(startDate).week() + 1;
        let year = selectedYear ? selectedYear : dayjs().year();
        if (week > numberOfWeeks) {
            year += 1;
            setSelectedYear(year);
            setSelectedWeek(1);
            setStartAndEndDate(findStartAndEndDate(year, 1));
            return;
        }
        setSelectedWeek(week);
        setStartAndEndDate(findStartAndEndDate(year, week));
    };

    const changeToPreviousWeek = () => {
        let week = dayjs(startDate).week() - 1;
        let year = selectedYear ? selectedYear : dayjs().year();
        if (week < 1) {
            year -= 1;
            setSelectedYear(year);
            let numberOfWeeks = dayjs(`${year}`).isoWeeksInYear();
            setSelectedWeek(numberOfWeeks);
            setStartAndEndDate(findStartAndEndDate(year, numberOfWeeks));
            return;
        }
        setSelectedWeek(week);
        setStartAndEndDate(findStartAndEndDate(year, week));
    };

    const findDate = (year: number, week: number, type: "start" | "end") => {
        if (type === "start") {
            return dayjs(`${year}`).week(week).startOf("week").format();
        }
        return dayjs(`${year}`).week(week).endOf("week").format();
    };

    const findStartAndEndDate = (year: number, week: number) => {
        return {
            startDate: findDate(year, week, "start"),
            endDate: findDate(year, week, "end"),
        };
    };

    return (
        <div className="schedulerPanelHeader">
            <button
                className="todayButton"
                onClick={() => {
                    setCurrentDateAndWeek();
                }}
            >
                <span className="cmnIcon plannerCalendar"></span>{" "}
                {pageCmsData.lbl_today}
            </button>
            <div className="schedulerPanelNxtPrv">
                <span
                    className="cmnIcon leftArrow"
                    onClick={() => {
                        changeToPreviousWeek();
                    }}
                ></span>
                <span
                    className="cmnIcon rightArrow"
                    onClick={() => {
                        changeToNextWeek();
                    }}
                ></span>
            </div>
            <div className="calendarCardHeaderTitle">
                {`${dayjs(startDate).format("MMM DD")} - ${dayjs(
                    endDate
                ).format("MMM DD, 2024")} (${
                    pageCmsData?.lbl_week
                } ${selectedWeek})`}
            </div>
            <div className="schedulerPanelViewOptions">
                <Select
                    style={{ minWidth: 150 }}
                    value={calendarWeekOption}
                    options={pageCmsData?.lbl_calendar_view_options}
                    onChange={(e: number) => setCalendarWeekOption(e)}
                />
            </div>
        </div>
    );
};

export default CalendarControl;
