export const getAssetConfigObject = () => {
  const assets = {
    REACT_APP_HABIT_TRACKER_IMAGE: `${process.env.REACT_APP_STORAGE_BASE_URL}images/habit-tracker.png`,
    REACT_APP_MARKETING_SITE_IMAGE: `${process.env.REACT_APP_STORAGE_BASE_URL}images/marketingSite.png`,
    REACT_APP_PRODUCT_PLANNER_IMAGE: `${process.env.REACT_APP_STORAGE_BASE_URL}images/product-planner.png`,
    REACT_APP_PROJECT_CHECKLIST_IMAGE: `${process.env.REACT_APP_STORAGE_BASE_URL}images/project-checklist.png`,
    REACT_APP_RUNNING_PLAN_IMAGE: `${process.env.REACT_APP_STORAGE_BASE_URL}images/running-plan.png`,
    REACT_APP_WEEKLY_PLANNER_IMAGE: `${process.env.REACT_APP_STORAGE_BASE_URL}images/weekly-planner.png`,
    REACT_APP_WORKOUT_PLAN_IMAGE: `${process.env.REACT_APP_STORAGE_BASE_URL}images/workout-plan.png`,
    REACT_APP_THUMBNAIL_IMAGE: `${process.env.REACT_APP_STORAGE_BASE_URL}images/thumbnail.svg`,
  };
  return assets;
};
