import { EventContentArg } from "@fullcalendar/core";
import { Tooltip } from "antd";
import { IPlannerAttributes } from "models/interface";
import ExternalCalendarEventTag from "./ExternalCalendarEventTag";

interface ICalendarEventTag {
  arg: EventContentArg;
  options: {
    plannerView?: number;
    showWeekends?: boolean;
  };
  plannerCmsData?: IPlannerAttributes;
}
const CalendarEventTag: React.FC<ICalendarEventTag> = ({
  arg,
  options,
  plannerCmsData,
}) => {
  // console.log(arg)

  const renderCard =()=>{
  if (options.plannerView === 3) {
    return (
      <div className="plannerEventContent">
        <Tooltip
          title={
            arg.event.extendedProps.is_personal
              ? plannerCmsData?.lbl_personal_team
              : arg.event.extendedProps.organization_name
          }
        >
          {arg.event.extendedProps.organization_logo ? (
          <div className="userAvtr"><img src={arg.event.extendedProps.organization_logo} /></div>
        ) : (
          <div className="userAvtr" style={{backgroundColor: arg.event.extendedProps.teamColor}}>
            {arg.event.extendedProps.is_personal
              ? plannerCmsData?.lbl_personal_team.charAt(0).toUpperCase()
              : arg.event.extendedProps.organization_name
                ?.charAt(0)
                .toUpperCase()}
          </div>
        )}
        </Tooltip>
          <Tooltip title={arg.event.title}>
            <div className="plannerEventTitle" style={{ color: "#000" }}>
              {arg.event.title}
            </div>
          </Tooltip>
      </div>
    );
  }
  return (
    <div className="plannerEventContent">
      <span className="leftBar" style={{backgroundColor: arg.event.extendedProps.borderLeftColor}}></span>
      <Tooltip
        title={
          arg.event.extendedProps.is_personal
            ? plannerCmsData?.lbl_personal_team
            : arg.event.extendedProps.organization_name
        }
      >
        {arg.event.extendedProps.organization_logo ? (
          <div className="userAvtr"><img src={arg.event.extendedProps.organization_logo} /></div>
        ) : (
          <div className="userAvtr" style={{backgroundColor: arg.event.extendedProps.teamColor}}>
            {arg.event.extendedProps.is_personal
              ? plannerCmsData?.lbl_personal_team.charAt(0).toUpperCase()
              : arg.event.extendedProps.organization_name
                ?.charAt(0)
                .toUpperCase()}
          </div>
        )}
      </Tooltip>
        <Tooltip title={arg.event.title}>
          <div className="plannerEventTitle" style={{color: arg.event.extendedProps.text_color}}>{arg.event.title}</div>
        </Tooltip>
    </div>
  );}
  return <>{arg.event.extendedProps.hasOwnProperty('etag')?<ExternalCalendarEventTag arg={arg} options={options} plannerCmsData={plannerCmsData}/>: renderCard()}</>
};

export default CalendarEventTag;
