import React from 'react'
import AttachmentField from './AttachmentField/AttachmentField';
import NumberField from './NumberField/NumberField';
import StatusField from './StatusField/StatusField';

const FieldWrapper: React.FC<{ id: string, defaultMeta: Object, isInEditMode: boolean, addEditField: Function }> = ({ id, defaultMeta, isInEditMode, addEditField }) => {

    switch (id) {
        case "1":
            return (<StatusField addEditField={addEditField} isInEditMode={isInEditMode} defaultMeta={defaultMeta} />)
        case "2":
            return (<NumberField addEditField={addEditField} isInEditMode={isInEditMode} defaultMeta={defaultMeta} />)
        case "3":
            return (<AttachmentField addEditField={addEditField} isInEditMode={isInEditMode} defaultMeta={defaultMeta} />)

    }

    return null;
}

export default FieldWrapper