export enum ConstantRowTypeEnum {
  FOLDER = "folder",
  PROJECT = "project",
}

export enum actionTypes {
  ACTION = "action",
  INFO = "info",
}

export enum action {
  DELETE_FOLDER = "delete folder",
}

export enum roles {
  OWNER = "Owner",
  MEMBER = "Member",
  COLLABORATOR = "Collaborator",
}

export enum modeOps {
  ADD = "add",
  EDIT = "edit",
  CLONE = "clone",
}

export enum eventTypes {
  ENTER = "Enter",
  ESCAPE = "Escape",
  BLUR = "Blur",
  REVERT = "Revert",
}

export enum ContextTypes {
  TASKLIST = "TaskList",
  GRIDLIST = "GridList",
}

export const newStageId = 999999;
export const newStageRank = 999999;

export const newTaskId = 999999;
export const newTaskRank = 999999;

export const newCustomFieldColumnWidth = 150;

export const CUSTOM_FIELDS = [
  {
    section: "Essentials",
    fields: [
      {
        icon: "📅",
        type: "date",
        text: "Date"
      },
      {
        icon: "🔢",
        type: "number",
        text: "Number"
      },
      {
        icon: "📝",
        type: "text",
        text: "Text"
      },
      {
        icon: "🔘",
        type: "checkbox",
        text: "Checkbox"
      }
    ]
  },
  {
    section: "Custom",
    fields: [
      {
        icon: "🔢",
        type: "number",
        text: "Number"
      },
      {
        icon: "📝",
        type: "text",
        text: "Text"
      },
      {
        icon: "🔘",
        type: "checkbox",
        text: "Checkbox"
      }
    ]
  }
]