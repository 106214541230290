import {RoutesEnum} from "../../models/enums/apiRoutes";
import {
    IAddEditFolderPayload,
    ICreateFolderPayload,
    ICreateProjectFromTemplatePayload,
    ICreateProjectPayload,
    ICreateProjectResponseDetails,
    IDashboardApiResponse,
    IDeleteFolderPayload,
    IGetFolderListPayload,
    IGetProjectFolderListPayload,
    IGetUserListPayload,
    IUserRoleUpdateDto
} from "../../models/interface";
import ApiService from "../Api.service";

class DashboardService {

    public createFolder = async (createFolderPayload: ICreateFolderPayload) => {
        return ApiService.post<IDashboardApiResponse>(
            RoutesEnum.CREATE_FOLDER,
            createFolderPayload
        );
    }

    public createProject = async (createProjectPayload: ICreateProjectPayload) => {
        return ApiService.post<ICreateProjectResponseDetails>(
            RoutesEnum.CREATE_PROJECT,
            createProjectPayload
        );
    }

    public getFolder = async (folderListPayload: IGetFolderListPayload) => {
        return ApiService.post<IDashboardApiResponse>(
            RoutesEnum.GET_FOLDER_LIST,
            folderListPayload
        );
    }

    public getProjectFolder = async (projectFolderListPayload: IGetProjectFolderListPayload) => {
        return ApiService.post<IDashboardApiResponse>(
            RoutesEnum.GET_PROJECT_FOLDER_LIST,
            projectFolderListPayload
        );
    }

    public addEditFolder = async (addEditFolderPayload: IAddEditFolderPayload) => {
        return ApiService.post<IDashboardApiResponse>(
            RoutesEnum.ADD_EDIT_FOLDER,
            addEditFolderPayload
        );
    }

    public deleteFolder = async (deleteFolderPayload: IDeleteFolderPayload) => {
        return ApiService.post<IDashboardApiResponse>(
            RoutesEnum.DELETE_FOLDER,
            deleteFolderPayload
        )
    }

    public getUserList = async (getUserPayload: IGetUserListPayload) => {
        return ApiService.post<IDashboardApiResponse>(
            RoutesEnum.GET_ASSOCIATED_USER_LIST,
            getUserPayload
        )
    }

    public getRoles = async () => {
        return ApiService.get<IDashboardApiResponse>(RoutesEnum.GET_PROJECT_ROLES)
    }

    public postUpdateUserRole = async (payload: IUserRoleUpdateDto) => {
        return ApiService.post(RoutesEnum.UPDATE_USER_ROLE, payload)
    }

    public createProjectFromTemplate = async (payload: ICreateProjectFromTemplatePayload) => {
        return ApiService.post<ICreateProjectResponseDetails>(RoutesEnum.CREATE_PROJECT_FROM_TEMPLATE, payload)
    }

}

const dashboardService = new DashboardService();

export default dashboardService;
