import { FC, Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

import { ConstantRowTypeEnum } from "models/enums/constants";
import { NavRoutesEnum } from "models/enums/navRoutes";
import {
  IModalState,
  IProjectFolderDetails,
  IProjectList,
} from "models/interface";
import { useDashboardStore, useUserStore, useUserStoryStore } from "stores";
import {
  dashboardService,
  loggerService,
  projectService,
  userStoryGeneratorService,
} from "services";
import { AssociatedProjectInfo } from "./AssociatedProjectInfo";
import {
  generateNewListFromCreateFolder,
  generateNewListForSelectedProjectInProjectList,
  generateNewListForSelectedProjectInFolderProjectList,
  updateExistingList,
} from "utils/generateNewList";
import { DisplayRowName } from "./DisplayRowName";
import { getCurrentOrganization } from "utils/commonFunctions";
import { useModalStore } from "stores/modal.store";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";

interface IFolderProjectExpandedView {
  pageCmsData: any;
  orgDetails: any;
}

export const FolderProjectExpandedView: FC<IFolderProjectExpandedView> = ({
  pageCmsData,
  orgDetails,
}) => {
  const navigate = useNavigate();
  const {
    folderProjectList,
    setFolderProjectList,
    projectList,
    setProjectList,
    selectedFolderId,
    setSelectedFolderId,
    setFolderList,
  } = useDashboardStore((state: any) => state);
  const { modalState, setModalState } = useModalStore(
    (state: IModalState) => state
  );
  const { userDetails } = useUserStore((state: any) => state);
  const { setEpicsAndStoryData } = useUserStoryStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split("/")[2];
  const { hasPermissions } = useRbac();

  // const fetchUserStoryByProject = async (
  //   project_Id: number = 1,
  //   org_Id: number = 5
  // ) => {
  //   try {
  //     const response: any = await userStoryGeneratorService.checkEpicExist({
  //       project_id: project_Id,
  //       organization_id: org_Id,
  //     });
  //     setEpicsAndStoryData([response]);

  //     if (response?.data_existence === true) {
  //       localStorage.setItem('currentPage','1');
  //       navigate(`/org/${org_key}/project/${1}/epic-story-generator`);

  //     } else {
  //       navigate(`/org/${org_key}/project/${1}/user-story-generator`);
  //     }
  //   } catch (error) {
  //     await loggerService.log({
  //       severity: "High",
  //       message: "Failed to Navigate",
  //       payload: error,
  //     });
  //   }
  // };

  const displayIcon = (type: string, state: boolean): string => {
    if (type === ConstantRowTypeEnum.PROJECT) {
      return "layers";
    } else if (state) {
      return "arrow-down";
    } else {
      return "arrow-right";
    }
  };

  const navigateToProjectDetails = (row: IProjectFolderDetails) => {
    let newProjectList;
    let projectIndex = projectList.findIndex(
      (ele: IProjectList) => ele.project_id === row.row_id
    );

    if (projectIndex < 0) {
      let newProjectList = [...projectList];

      let rowNew = {
        is_favorite: row.row_is_favorite,
        project_id: row.row_id,
        project_name: row.row_name,
        project_rank: row.row_rank,
      };

      if (projectList?.length >= 4) {
        newProjectList.splice(0, 1, rowNew);
      } else {
        newProjectList.unshift(rowNew);
      }

      newProjectList = generateNewListForSelectedProjectInProjectList(
        row.row_id,
        newProjectList
      );
      setProjectList(newProjectList);
    } else {
      newProjectList = generateNewListForSelectedProjectInProjectList(
        row.row_id,
        projectList
      );
      setProjectList(newProjectList);
    }

    let updatedProjectFolderList =
      generateNewListForSelectedProjectInFolderProjectList(
        row.row_id,
        folderProjectList
      );
    setFolderProjectList(updatedProjectFolderList);
    document.getElementById("rightSideDrawerCloseIcon")?.click();
    navigate(`/org/${location.pathname.split("/")[2]}/project/${row.row_id}`);
  };

  const navigateToProjectList = (details: IProjectFolderDetails) => {
    setSelectedFolderId(details.row_id);
    document.getElementById("rightSideDrawerCloseIcon")?.click();
    navigate(
      `/org/${location.pathname.split("/")[2]}/${NavRoutesEnum.DASHBOARD_PROJECT_LIST}`
    );
    let newList = updateExistingList(folderProjectList);
    setFolderProjectList(newList);
  };

  const changeFolderName = async (event: any, index: number): Promise<void> => {
    let targetFolder = folderProjectList.filter(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    let folderName = targetFolder[0].row_name;

    if (folderName.trim() !== "") {
      try {
        const response: any = await dashboardService.addEditFolder({
          folderId: targetFolder[0]?.is_new ? 0 : targetFolder[0].row_id,
          folderName: folderName,
          organizationId: getCurrentOrganization(org_key, orgDetails)
            ?.organization_id!,
          userId: userDetails.user_id,
        });

        if (response.messageId === 1) {
          let newFolderList = generateNewListFromCreateFolder(
            response.data.project_folder_list,
            folderProjectList
          );
          setFolderProjectList(newFolderList);
          setFolderList(response.data.folder_list);
        }

        if (response.messageId === -2) {
          let targetFolder = folderProjectList.filter(
            (ele: IProjectFolderDetails) => ele.row_id === index
          );
          let targetFolderIndex = folderProjectList.findIndex(
            (ele: IProjectFolderDetails) => ele.row_id === index
          );
          targetFolder[0].row_is_duplicate = true;
          let newFolderList = [...folderProjectList];
          newFolderList.splice(targetFolderIndex, 1, targetFolder[0]);
          setFolderProjectList(newFolderList);
        }

        if (response.messageId === -1) {
          await loggerService.log({
            severity: "High",
            message: "folder creation failed",
            payload: "DB Error",
          });
        }
      } catch (error) {
        await loggerService.log({
          severity: "High",
          message: "folder creation failed",
          payload: error,
        });
      }
    } else {
      restoreToDefault(index);
    }
  };

  const submitChange = async (event: any, index: number): Promise<void> => {
    let targetFolder = folderProjectList.filter(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );

    if (targetFolder[0].is_edit) {
      let existingFolderName = targetFolder[0].initial_name;
      let newName = targetFolder[0].row_name;

      if (existingFolderName === newName) {
        restoreToDefault(index);
      } else {
        await changeFolderName(event, index);
      }
    } else {
      await changeFolderName(event, index);
    }
  };

  const folderNameChange = (event: any, index: number) => {
    let targetFolder = folderProjectList.filter(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    let targetFolderIndex = folderProjectList.findIndex(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    targetFolder[0].row_name = event.target.value;
    targetFolder[0].is_duplicate = false;
    let newFolderList = [...folderProjectList];
    newFolderList.splice(targetFolderIndex, 1, targetFolder[0]);
    setFolderProjectList(newFolderList);
  };

  const expandCollapse = (index: number) => {
    let targetRow = folderProjectList.filter(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    let targetRowIndex = folderProjectList.findIndex(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    targetRow[0].row_state = !targetRow[0].row_state;
    let newList = [...folderProjectList];
    newList.splice(targetRowIndex, 1, targetRow[0]);
    setFolderProjectList(newList);
  };

  const displayRowName = (rowId: number, rowName: string) => {
    if (rowId === -1) {
      return pageCmsData?.lbl_menu_favorites;
    } else if (rowId === -2) {
      return pageCmsData?.lbl_menu_archived;
    } else {
      return rowName;
    }
  };

  const checkExceedingCondition = (rowType: string, rowName: string) => {
    if (rowType === ConstantRowTypeEnum.FOLDER) {
      return true;
    }

    if (rowType === ConstantRowTypeEnum.PROJECT && rowName?.length <= 25) {
      return true;
    } else {
      return false;
    }
  };

  const clickEventHandler = (row: IProjectFolderDetails) => {
    if (row.row_type === ConstantRowTypeEnum.FOLDER) {
      expandCollapse(row.row_id);
    } else {
      navigateToProjectDetails(row);
    }
  };

  const editFolder = (index: number) => {
    let targetFolder = folderProjectList.filter(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    let targetFolderIndex = folderProjectList.findIndex(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    targetFolder[0].is_edit = true;
    targetFolder[0].initial_name = targetFolder[0].row_name;
    let newFolderList = [...folderProjectList];
    newFolderList.splice(targetFolderIndex, 1, targetFolder[0]);
    setFolderProjectList(newFolderList);
  };

  const restoreToDefault = (index: number) => {
    let targetFolder = folderProjectList.filter(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    let targetFolderIndex = folderProjectList.findIndex(
      (ele: IProjectFolderDetails) =>
        ele.row_id === index && ele.row_type === ConstantRowTypeEnum.FOLDER
    );
    let newFolderList = [...folderProjectList];

    if (targetFolder[0]?.is_new) {
      newFolderList.splice(targetFolderIndex, 1);
      setFolderProjectList(newFolderList);
    } else {
      targetFolder[0].row_name = targetFolder[0].initial_name;
      delete targetFolder[0].is_edit;
      newFolderList.splice(targetFolderIndex, 1, targetFolder[0]);
      setFolderProjectList(newFolderList);
    }
  };

  const generateClassName = (row: IProjectFolderDetails) => {
    if (row.row_type === ConstantRowTypeEnum.PROJECT) {
      if (
        location.pathname.split("/").lastIndexOf(row.row_id?.toString()) > 0
      ) {
        return "independent-project active-item";
      } else {
        return "independent-project";
      }
    } else if (row?.row_id === -1 || row?.row_id === -2) {
      return "favoriteItem";
    }
    return "";
  };

  const deleteFolderHandler = (row: IProjectFolderDetails) => {
    setModalState({
      modalHeader: (
        <Fragment>
          <h3>{pageCmsData.lbl_delete_modal_header}</h3>
          <span
            className="material-icons-outlined modal-close"
            onClick={() => modalClose()}
            onKeyDown={() => { }}
          >
            close
          </span>
        </Fragment>
      ),
      modalBody: (
        <Fragment>
          <div className="icon"></div>
          {row.assosiated_project_info?.length === 0 && (
            <Fragment>
              <p>
                {pageCmsData.lbl_delete_modal_content1}{" "}
                <strong>{row.row_name}</strong>?
              </p>
              <p>{pageCmsData.lbl_delete_modal_content2}</p>
            </Fragment>
          )}
          {row.assosiated_project_info?.length > 0 && (
            <p>{pageCmsData.lbl_delete_modal_content3}</p>
          )}
        </Fragment>
      ),
      modalFooter: (
        <Fragment>
          {row.assosiated_project_info?.length === 0 && (
            <button
              className="noStyle cancel_deleteFolder"
              onClick={() => modalClose()}
            >
              {pageCmsData.cta_delete_modal_cancel}
            </button>
          )}
          {row.assosiated_project_info?.length === 0 && (
            <button
              className="deleteBtnStyle"
              onClick={() =>
                (async () => {
                  await confirmAction(row);
                })()
              }
            >
              {pageCmsData.cta_delete_modal_confirm}
            </button>
          )}
          {row.assosiated_project_info?.length > 0 && (
            <button className="btnStyle1" onClick={() => modalClose()}>
              {pageCmsData.cta_delete_modal_info}
            </button>
          )}
        </Fragment>
      ),
      shouldShow: true,
    });
  };

  const rowClickHandler = (ele: IProjectFolderDetails) => {
    if (ele.row_type === ConstantRowTypeEnum.PROJECT) {
      navigateToProjectDetails(ele);
    } else if (ele.row_id !== -1 && ele.row_id !== -2) {
      editFolder(ele.row_id);
    }
  };

  const toggleFavorite = async (item: IProjectFolderDetails) => {
    if (userDetails) {
      const userId = userDetails?.user_id;
      const organizationId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info
      )?.organization_id!;
      const projectId = item.row_id;

      await projectService.toggleProjectFavorite({
        projectId,
        userId,
        organizationId,
      });
    }
  };

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const confirmAction = async (row: IProjectFolderDetails): Promise<void> => {
    try {
      const response: any = await dashboardService.deleteFolder({
        folderId: row.row_id,
        organizationId: getCurrentOrganization(org_key, orgDetails)
          ?.organization_id!,
        userId: userDetails.user_id,
      });

      let newList = [...folderProjectList];

      newList = newList.filter(
        (ele: IProjectFolderDetails) =>
          (ele.row_type === ConstantRowTypeEnum.FOLDER &&
            ele.row_id !== row.row_id) ||
          ele.row_type === ConstantRowTypeEnum.PROJECT
      );
      setFolderProjectList(newList);
      setFolderList(response.data.folder_list);

      if (selectedFolderId === row.row_id) {
        setSelectedFolderId(0);
      }
      modalClose();
    } catch (err) {
      await loggerService.log({
        severity: "High",
        message: "folder creation failed",
        payload: "DB Error",
      });
      modalClose();
    }
  };
  return (
    <ul>
      {/* <li
        className={`parentli independent-project ${
          location.pathname.split('/').lastIndexOf('user-story-generator') > 0
            ? 'active-item'
            : ''
        }`}
      >
        <div
          className="itemNameBlk haveSubItem "
          onClick={() => fetchUserStoryByProject(1, 5)}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <span className="sidePanelIcon layers"></span>
          <span className="itemName">User Story Generator</span>
          <span className="sidePanelIcon arrow-down"></span>
        </div>
        <div
          className={`itemNameBlk archiveOption ${
            location.pathname.split('/').lastIndexOf('archive-epic') > 0
              ? 'active-item'
              : ''
          }`}
          onClick={() => navigate(`/org/${org_key}/project/${1}/archive-epic`)}
        >
          <span className="sidePanelIcon archiveIcon"></span>
          <span className="itemName">Archived Epics</span>
        </div>
      </li> */}
      {/* <li
        className={`parentli independent-project ${location.pathname.split('/').lastIndexOf('feedback-board') > 0 ? 'active-project' : ''}`}
      >
        <div
          className="itemNameBlk haveSubItem "
          onClick={() =>
            navigate(`/org/${org_key}/project/${1}/pnc/feedback-board`)
          }
        >
          <span className="sidePanelIcon pointNcommentIcon"></span>
          <span className="itemName">Point and Comment</span>
        </div>
      </li> */}
      {folderProjectList
        .filter((ele: IProjectFolderDetails) => {
          if (ele?.row_id === -2) {
            if (ele?.assosiated_project_info?.length > 0) {
              return ele;
            }
          } else {
            return ele;
          }
        })
        .map((ele: IProjectFolderDetails) => (
          <li
            className={`parentli ${generateClassName(ele)}`}
            key={`${ele.row_type}_${ele.row_id}`}
          >
            <div
              className={`itemNameBlk haveSubItem ${
                ele?.is_new ? 'newFolderFld' : ''
              }`}
            >
              {!ele?.is_new && (
                <span
                  className={`sidePanelIcon ${displayIcon(
                    ele.row_type,
                    ele?.row_state,
                  )}`}
                  onClick={() => clickEventHandler(ele)}
                  onKeyDown={() => {}}
                ></span>
              )}
              {ele?.is_new || ele?.is_edit ? (
                <input
                  className={`${ele?.row_is_duplicate && 'duplicateError'}`}
                  maxLength={20}
                  value={ele?.row_name}
                  placeholder={pageCmsData?.lbl_default_folder_name}
                  onChange={(e) => folderNameChange(e, ele.row_id)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      (async () => {
                        await submitChange(e, ele.row_id);
                      })();
                    }
                  }}
                  onBlur={(e) => {
                    (async () => await submitChange(e, ele.row_id))();
                  }}
                  autoFocus
                />
              ) : (
                <DisplayRowName
                  row={ele}
                  displayType={checkExceedingCondition(
                    ele?.row_type,
                    ele?.row_name,
                  )}
                  rowClickHandler={rowClickHandler}
                  rowName={displayRowName(ele?.row_id, ele?.row_name)}
                />
              )}
              {!ele?.is_new &&
                !ele?.is_edit &&
                ele?.row_id !== -1 &&
                ele?.row_id !== -2 &&
                ele.row_type === ConstantRowTypeEnum.FOLDER &&
                hasPermissions([ERbacPermissions.PROJECT_CREATE]) && (
                  <Fragment>
                    <Tooltip
                      title={pageCmsData?.lbl_create_new_project_tooltip}
                      color={'#2E364C'}
                      placement="bottom"
                    >
                      <span
                        className="material-icons-outlined plus-class"
                        onClick={() => navigateToProjectList(ele)}
                        onKeyDown={() => {}}
                      >
                        add
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={pageCmsData?.lbl_delete_folder_tooltip}
                      color={'#2E364C'}
                      placement="bottom"
                    >
                      <span
                        className="material-icons-outlined delete-class"
                        onClick={() => deleteFolderHandler(ele)}
                        onKeyDown={() => {}}
                      >
                        delete_outline
                      </span>
                    </Tooltip>
                  </Fragment>
                )}
              {ele?.row_type === ConstantRowTypeEnum.PROJECT &&
                ele?.row_id !== -2 && (
                  <span
                    onClick={() => toggleFavorite(ele)}
                    onKeyDown={() => {}}
                    className={`sidePanelIcon favStar ${
                      ele?.row_is_favorite ? 'isFavorite' : 'unfavorited'
                    }`}
                  ></span>
                )}
            </div>
            {ele?.row_state && (
              <AssociatedProjectInfo
                associatedProjectInfo={ele?.assosiated_project_info}
                row_id={ele?.row_id}
              />
            )}
          </li>
        ))}
    </ul>
  );
};
