import { FC, Fragment } from "react";

export const PrivacyPolicy: FC<{}> = () => {
  return (
    <Fragment>
      <header className="privacy-terms-header">
        <h1>
          <a href={void 0} className="logoDefault" title="Cleversort"></a>
        </h1>
      </header>

      <main>
        <div className="privacy-terms-page content">
          <div className="container">
            <h2>PRIVACY &amp; COOKIE POLICY</h2>
            <span className="dateEffective">Date effective: Aug 31, 2023</span>
            <p>
              This privacy policy governs your use of the Product “Cleversort”
              that was created by Digital Avenues Pte. Ltd. By visiting this
              Site and requesting information, products or services offered on
              or through this Site, you acknowledge the terms of this Privacy
              Policy as they may change from time to time.
            </p>
            <div className="block">
              <h3>User Provided Information</h3>
              <p>
                In order to provide you with products and services, we may
                collect your information from different sources, including
                information you provide to us on forms, by e-mail, in writing,
                by telephone or through our websites including user id’s and
                passwords; information collected from your transactions with us,
                such as account activity; and your computer’s IP address in
                order to help diagnose problems with our server and to
                administer our site. We may also use the information you
                provided us to contact your from time to time to provide you
                with important information, required notices and marketing
                promotions.
              </p>
            </div>
            <div className="block">
              <h3>Automatically Collected Information</h3>
              <p>
                In addition, we may collect certain information automatically,
                including, but not limited to, the type of device you use, your
                devices unique device ID, the IP address of your device, your
                operating system, the type of Internet browsers you use, and
                information about the way you use the Product. Some of this data
                may be transmitted to external services to help us improve the
                Product and provide content for our service. We will share your
                information with third parties only in the ways that are
                described in this privacy statement.
              </p>
            </div>
            <div className="block">
              <h3>Disclosures</h3>
              <p>
                We may disclose User Provided and Automatically Collected
                Information:
              </p>
              <ul>
                <li>
                  as required by law, such as to comply with a subpoena, or
                  similar legal process;
                </li>
                <li>
                  when we believe in good faith that disclosure is necessary to
                  protect our rights, protect your safety or the safety of
                  others, investigate fraud, or respond to a government request;
                </li>
                <li>
                  with our trusted services providers who work on our behalf, do
                  not have an independent use of the information we disclose to
                  them, and have agreed to adhere to the rules set forth in this
                  privacy statement.
                </li>
              </ul>
              <p>
                If Digital Avenues is involved in a merger, acquisition, or sale
                of all or a portion of its assets, you will be notified via
                email and/or a prominent notice on our Web site of any change in
                ownership or uses of this information, as well as any choices
                you may have regarding this information.
              </p>
            </div>

            <div className="block">
              <h3>Access &amp; Storage</h3>
              <p>
                We will retain User Provided data for as long as you use the
                Product and for a reasonable time thereafter. We will retain
                Automatically Collected information for up to 24 months and
                thereafter may store it in aggregate. If you would like us to
                delete User Provided Data that you have provided via the
                Product, please contact our Data Protection Officer at
                support@cleversort.com and we will respond in a reasonable time.
                Please note that some or all of the User Provided Data may be
                required in order for the Software to function properly.
                <br />
                <br />
                You may help ensure that all your contact information is
                accurate and complete by signing into your account. You may
                access, correct or delete other personal information that we
                hold by contacting our Data Protection Officer at the email
                address provided above.
              </p>
              <p>
                You may also contact our Data Controller/Processor at the
                following address:
              </p>
              <p>30 Cecil Street</p>
              <p>#19-08 Prudential Tower</p>
              <p> Singapore 049712</p>
            </div>
            <div className="block">
              <h3>Security</h3>
              <p>
                We are concerned about safeguarding the confidentiality of your
                information. We use industry standard technical and
                organizational measures to secure the information we store.
                Please be aware that although we endeavor to provide reasonable
                security for information we process and maintain, no security
                system can prevent all potential security breaches.
              </p>
            </div>
            <div className="block">
              <h3>Cookies</h3>
              <p>
                Digital Avenues uses cookies to improve and customize your Use
                of the Software. We also use cookies to identify trends and
                obtain statistics about site traffic and interaction. To opt-out
                of cookies on our Site, you can instruct your browser, by
                changing its options, to stop accepting cookies or to prompt you
                before accepting a cookie from websites you visit. If you do not
                accept cookies, however, you may not be able to use all aspects
                of our Services.
              </p>
            </div>
            <div className="block">
              <h3>Updates</h3>
              <p>
                This Privacy Policy may be updated from time to time for any
                reason. We will notify you of any changes to our Privacy Policy
                by posting the new Privacy Policy here and informing you via
                email or text message if you have not opted out of those contact
                options. You are advised to consult this Privacy Policy
                regularly for any changes, as continued use is deemed approval
                of all changes.
              </p>
            </div>
            <div className="block">
              <h3>Consent</h3>
              <p>
                By using the Product, you are consenting to our processing of
                your information as set forth in this Privacy Policy now and as
                amended by us. “Processing,” means using cookies on a
                computer/hand-held device or using or touching information in
                any way, including, but not limited to, collecting, storing,
                deleting, using, combining and disclosing information.
              </p>
            </div>
            <div className="block">
              <h3>Contact</h3>
              <p>
                If you have any questions regarding privacy while using the
                Product, or have questions about our practices, please contact
                us via email at{" "}
                <a className="supportMail" href="mailto:support@cleversort.com">
                  support@cleversort.com
                </a>.
              </p>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};
