import {IUpdateTeamDetail, IUploadProfileImage} from "../../models/interface";
import apiService from "../Api.service";
import {RoutesEnum} from "../../models/enums/apiRoutes";
import {IGetTeamMembersPayload, ITeamMemberResponse} from "../../models/interface/organization.interface";


class OrganizationService {
    public uploadTeamProfileImage = async (payload: IUploadProfileImage) => {
        return apiService.post<{ data: { url: string } }>(RoutesEnum.SAVE_IMAGE, payload);
    };

    public updateTeamDetail = async (payload: IUpdateTeamDetail) => {
        return apiService.post(RoutesEnum.TEAM_DETAIL_UPDATE, payload);
    };

    public getTeamDetail = async (getTeamMembersPayload: IGetTeamMembersPayload) => {
        return apiService.get<ITeamMemberResponse>(RoutesEnum.TEAM_MEMBER_LIST, getTeamMembersPayload);
    }

    public removeTeamMember = async (payload: {user_id: number; organization_id: number, sender_name: string}) => {
        return apiService.post(`${RoutesEnum.DELETE_TEAM_MEMBER}`, payload)
    }

    public uploadOrgLogo= async (payload: { base64Str: string | ArrayBuffer; extension: string; org_key: string}) => {
        return apiService.post(RoutesEnum.UPLOAD_TEAM_LOGO, payload);
    };

    public updateOrgLogo = async (payload:{org_key:string}) =>{
        return apiService.post<{data:{}, messageId:number; message: string;}>(RoutesEnum.UPDATE_TEAM_LOGO, payload)
    }

    public getMasterOfSettings = async () =>{
        return apiService.get<{data:[], messageId:number; message: string;}>(RoutesEnum.GET_MASTER_OF_SETTING)
    }

    public getOrgOfSettings = async (payload:{org_id: number}) =>{
        return apiService.get<{data:[], messageId:number; message: string;}>(RoutesEnum.GET_ORG_OF_SETTING, payload)
    }

    public updateOrgOfSettings = async (payload: {
        org_id: number; of_settings: {
            mapping_id: number;
            values: null | { mapping_id: number }[]
        }[]
    }) => {
        return apiService.post<{ data: [], messageId: number; message: string; }>(RoutesEnum.UPDATE_ORG_OF_SETTING, payload)
    }
}

const organizationService = new OrganizationService()
export default organizationService
