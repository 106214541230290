import { DatePicker, Popover, Typography, message } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import dayjs, { Dayjs } from 'dayjs';
import { NotificationEventType } from 'models/enums/notification';
import { TaskListView } from 'models/enums/tasklistView';
import {
  IGlobalState,
  IProjectState,
  ITaskListDetails,
  IUserDetails,
  IUserState,
} from 'models/interface';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { projectService } from 'services';
import { useGlobalStore, useProjectDashboardStore, useUserStore } from 'stores';
import { getCurrentOrganization } from 'utils/commonFunctions';
import CCalendar from '../GridListV3/CDatePicker/CCalendar';
import OutsideClickHandler from 'components/sharedComponents/OutsideClickHandler';
import { IEachTaskDetail } from 'components/sharedComponents/StatusChange/StatusChange';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';

const TaskDueDate: React.FC<{
  eachTaskDetail?: ITaskListDetails & IEachTaskDetail;
  viewType?: TaskListView;
  sectionId?: number | string;
  dueDate?: string | null;
  notifyChannel?: (
    userDetails: IUserDetails,
    eventType: string,
    eventValue?: string,
  ) => void;
  setEachTaskDetail?: React.Dispatch<
    React.SetStateAction<ITaskListDetails & IEachTaskDetail>
  >;
  findTaskIdAndUpdateMainTaskList?: (
    eachTaskDetailUpdated: ITaskListDetails,
  ) => void;
  currentSection?:
    | {
        sectionId: number;
        popup: 'assignee' | 'dueDate' | 'message';
      }
    | undefined;
  setCurrentSection?: React.Dispatch<
    React.SetStateAction<
      | {
          sectionId: number;
          popup: 'assignee' | 'dueDate' | 'message';
        }
      | undefined
    >
  >;
}> = ({
  eachTaskDetail,
  viewType,
  sectionId,
  notifyChannel,
  setEachTaskDetail,
  findTaskIdAndUpdateMainTaskList,
  dueDate,
  currentSection,
  setCurrentSection,
}) => {
  const { antLocale } = useGlobalStore((state: IGlobalState) => state);
  const { taskListCmsData, projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { hasPermissions } = useRbac();
  const [selectingDate, setSelectingDate] = useState<Dayjs | null>(
    dayjs(dueDate || dayjs()),
  );

  const [showDatePicker, setShowDatePicker] = useState(false);
  const { userDetails } = useUserStore((state: IUserState) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { fetchNewPermission } = useFetchNewPermission();

  useEffect(() => {
    if (
      setCurrentSection &&
      eachTaskDetail &&
      projectDetails?.is_archived === false
    ) {
      if (showDatePicker === true) {
        setCurrentSection({
          sectionId: Number(eachTaskDetail?.task_id),
          popup: 'dueDate',
        });
      }
    }
  }, [showDatePicker]);

  const openingLogic = () => {
    if (currentSection) {
      return (
        showDatePicker &&
        projectDetails?.is_archived === false &&
        currentSection?.sectionId === Number(eachTaskDetail?.task_id) &&
        currentSection?.popup === 'dueDate'
      );
    } else {
      return showDatePicker;
    }
  };

  //dueDate: Dayjs | null
  const taskDueDateChangeHandler = async (dueDate: Dayjs | null) => {
    setShowDatePicker(false);
    if (eachTaskDetail?.due_date === dayjs(dueDate).format('YYYY-MM-DD')) {
      return;
    }
    if (typeof eachTaskDetail?.task_id === 'string') {
      message.info(taskListCmsData?.error_message_options?.no_task_name, 3);
      return;
    }
    if (
      projectDetails?.project_id &&
      typeof sectionId === 'number' &&
      typeof eachTaskDetail?.task_id === 'number' &&
      userDetails?.organization_info
    ) {
      let payload: any = {
        ...eachTaskDetail,
        task_id: eachTaskDetail?.task_id,
        due_date: dueDate ? dayjs(dueDate).format('YYYY-MM-DD') : '',
        task_status_id: eachTaskDetail?.task_status_id,
        task_status_name: eachTaskDetail?.task_status_name,
      };
      if (
        dayjs(dueDate).isSame(dayjs(), 'day') ||
        dayjs(dueDate).isAfter(dayjs())
      ) {
        payload = {
          ...payload,
          task_status_id:
            eachTaskDetail?.task_status_id === 2
              ? 1
              : eachTaskDetail?.task_status_id,
          task_status_name: taskListCmsData?.task_status_options.find(
            (eachOption) =>
              eachOption.status_id ===
              (eachTaskDetail?.task_status_id === 2
                ? 1
                : eachTaskDetail?.task_status_id),
          )?.status_name,
        };
      } else if (
        dayjs(dueDate).isBefore(dayjs()) &&
        eachTaskDetail?.task_status_id === 1
      ) {
        payload = {
          ...payload,
          task_status_id: 2,
          task_status_name: taskListCmsData?.task_status_options.find(
            (eachOption) => eachOption.status_id === 2,
          )?.status_name,
        };
      } else if (!dueDate && eachTaskDetail.task_status_id === 2) {
        payload = {
          ...payload,
          task_status_id: 1,
          task_status_name: taskListCmsData?.task_status_options.find(
            (eachOption) => eachOption.status_id === 1,
          )?.status_name,
        };
      }
      if (setEachTaskDetail) {
        let eachTaskDetailUpdated = {
          ...eachTaskDetail,
          ...payload,
        };
        findTaskIdAndUpdateMainTaskList &&
          findTaskIdAndUpdateMainTaskList(eachTaskDetailUpdated);
        setEachTaskDetail((prev) => ({ ...prev, ...payload }));
      }
      try {
        await projectService.updateTask({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: [
            {
              task_id: eachTaskDetail?.task_id,
              due_date: dueDate ? dayjs(dueDate).format('YYYY-MM-DD') : '',
              task_status_id: payload?.task_status_id,
            },
          ],
        });
        if (notifyChannel) {
          notifyChannel(
            userDetails,
            NotificationEventType.UPDATE_SIMPLE_TASK_ROW,
            JSON.stringify({ ...payload }),
          );
        }
      } catch (error: any) {
        if (error?.response?.data?.messageId === -4) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const formatDueDate = () => {
    if (!eachTaskDetail?.due_date) {
      return viewType === TaskListView.CARD
        ? taskListCmsData?.due_date_custom_text[5]
        : taskListCmsData?.due_date_custom_text[0];
    }

    const differenceInDays = dayjs(eachTaskDetail?.due_date).diff(
      dayjs(new Date().setHours(0, 0, 0, 0)),
      'days',
    );

    if (differenceInDays === 0) {
      return taskListCmsData?.due_date_custom_text[2];
    } else if (differenceInDays === -1) {
      return taskListCmsData?.due_date_custom_text[1];
    } else if (differenceInDays === 1) {
      return taskListCmsData?.due_date_custom_text[3];
    }
    return dayjs(eachTaskDetail?.due_date).format('MMM DD');
  };

  const getDueDateJsx = () => {
    if (!eachTaskDetail?.due_date) {
      return (
        <div
          className="textMuted"
          onClick={() => {
            if (
              projectDetails?.is_archived === false &&
              hasPermissions(
                [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                projectDetails?.associated_role_id,
              )
            ) {
              if (showDatePicker === true) {
                setShowDatePicker(false);
              } else {
                setShowDatePicker(true);
              }
            }
          }}
          onKeyDown={() => {}}
        >
          {viewType === TaskListView.CARD
            ? taskListCmsData?.due_date_custom_text[5]
            : taskListCmsData?.due_date_custom_text[0]}
        </div>
      );
    }

    const differenceInDays = dayjs(eachTaskDetail?.due_date).diff(
      dayjs(new Date().setHours(0, 0, 0, 0)),
      'days',
    );

    if (differenceInDays === 0) {
      return (
        <div
          onClick={() => {
            if (
              projectDetails?.is_archived === false &&
              hasPermissions(
                [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                projectDetails?.associated_role_id,
              )
            ) {
              if (showDatePicker === true) {
                setShowDatePicker(false);
              } else {
                setShowDatePicker(true);
              }
            }
          }}
          onKeyDown={() => {}}
        >
          {taskListCmsData?.due_date_custom_text[2]}
        </div>
      );
    } else if (differenceInDays === -1) {
      return (
        <div
          onClick={() => {
            if (
              projectDetails?.is_archived === false &&
              hasPermissions(
                [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                projectDetails?.associated_role_id,
              )
            ) {
              if (showDatePicker === true) {
                setShowDatePicker(false);
              } else {
                setShowDatePicker(true);
              }
            }
          }}
          onKeyDown={() => {}}
          className={`${
            differenceInDays < 0 && eachTaskDetail.task_status_id !== 3
              ? 'errorColor'
              : ''
          }`}
        >
          {taskListCmsData?.due_date_custom_text[1]}
        </div>
      );
    } else if (differenceInDays === 1) {
      return (
        <div
          onClick={() => {
            if (
              projectDetails?.is_archived === false &&
              hasPermissions(
                [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                projectDetails?.associated_role_id,
              )
            ) {
              if (showDatePicker === true) {
                setShowDatePicker(false);
              } else {
                setShowDatePicker(true);
              }
            }
          }}
          onKeyDown={() => {}}
        >
          {taskListCmsData?.due_date_custom_text[3]}
        </div>
      );
    }
    return (
      <div
        onClick={() => {
          if (
            projectDetails?.is_archived === false &&
            hasPermissions(
              [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
              projectDetails?.associated_role_id,
            )
          ) {
            if (showDatePicker === true) {
              setShowDatePicker(false);
            } else {
              setShowDatePicker(true);
            }
          }
        }}
        onKeyDown={() => {}}
        className={`${
          differenceInDays < 0 && eachTaskDetail.task_status_id !== 3
            ? 'errorColor'
            : ''
        }`}
      >
        {dayjs(eachTaskDetail?.due_date).format('MMM DD')}
      </div>
    );
  };

  if (viewType === TaskListView.CARD) {
    return (
      <>
        {openingLogic() ? (
          // <OutsideClickHandler onOutsideClick={() => setShowDatePicker(false)}>
          <Popover
            placement="bottom"
            content={
              <CCalendar
                value={selectingDate}
                onChange={(date) => setSelectingDate(date)}
                onCancel={() => setShowDatePicker(false)}
                onOk={() => taskDueDateChangeHandler(selectingDate)}
                onClear={() => taskDueDateChangeHandler(null)}
                open={true}
              />
            }
            trigger="click"
            overlayClassName="gridAssigneeListPopOver"
            open={openingLogic()}
            onOpenChange={(event) => {
              if (event === false) {
                setShowDatePicker(false);
              } else {
                if (
                  hasPermissions(
                    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                    projectDetails?.associated_role_id,
                  )
                ) {
                  if (
                    eachTaskDetail &&
                    eachTaskDetail.assignee_id !== undefined &&
                    projectDetails?.is_archived === false
                  ) {
                    setShowDatePicker(true);
                  } else {
                    setShowDatePicker(false);
                  }
                }
              }
            }}
          >
            {getDueDateJsx()}
          </Popover>
        ) : (
          // </OutsideClickHandler>
          // <DatePicker
          //   className="taskDueDate"
          //   format="MMM DD"
          //   bordered={false}
          //   placeholder={formatDueDate()}
          //   locale={antLocale}
          //   onChange={(date: dayjs.Dayjs | null) =>
          //     taskDueDateChangeHandler(date)
          //   }
          //   open={showDatePicker}
          //   suffixIcon={null}
          //   onClick={() => setShowDatePicker(false)}
          //   renderExtraFooter={() => (
          //     <Typography.Link
          //       className={`text-center taskDueDateClear ${
          //         !eachTaskDetail?.due_date ? "taskDueDateClearDisable" : ""
          //       }`}
          //       onClick={() => taskDueDateChangeHandler(null)}
          //     >
          //       {taskListCmsData?.due_date_custom_text[4]}
          //     </Typography.Link>
          //   )}
          // />
          getDueDateJsx()
        )}
      </>
    );
  }

  return (
    <td>
      {openingLogic() ? (
        // <DatePicker
        //   className="taskDueDate"
        //   format="MMM DD"
        //   bordered={false}
        //   placeholder={formatDueDate()}
        //   locale={antLocale}
        //   onChange={(date: dayjs.Dayjs | null) =>
        //     taskDueDateChangeHandler(date)
        //   }
        //   open={showDatePicker}
        //   suffixIcon={null}
        //   onClick={() => setShowDatePicker(false)}
        //   renderExtraFooter={() => (
        //     <Typography.Link
        //       className={`text-center taskDueDateClear ${
        //         !eachTaskDetail?.due_date ? "taskDueDateClearDisable" : ""
        //       }`}
        //       onClick={() => taskDueDateChangeHandler(null)}
        //     >
        //       {taskListCmsData?.due_date_custom_text[4]}
        //     </Typography.Link>
        //   )}
        // />

        // <OutsideClickHandler onOutsideClick={() => setShowDatePicker(false)}>
        <Popover
          placement="bottom"
          content={
            <CCalendar
              value={selectingDate}
              onChange={(date) => setSelectingDate(date)}
              onCancel={() => setShowDatePicker(false)}
              onOk={() => taskDueDateChangeHandler(selectingDate)}
              onClear={() => taskDueDateChangeHandler(null)}
              open={true}
            />
          }
          trigger="click"
          overlayClassName="gridAssigneeListPopOver"
          open={openingLogic()}
          onOpenChange={(event) => {
            if (event === false) {
              setShowDatePicker(false);
            } else {
              if (
                hasPermissions(
                  [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                  projectDetails?.associated_role_id,
                )
              ) {
                if (
                  eachTaskDetail &&
                  eachTaskDetail.assignee_id !== undefined
                ) {
                  setShowDatePicker(true);
                } else {
                  setShowDatePicker(false);
                }
              }
            }
          }}
        >
          {getDueDateJsx()}
        </Popover>
      ) : (
        // </OutsideClickHandler>
        getDueDateJsx()
      )}
    </td>
  );
};

export default TaskDueDate;
