import { Tooltip } from "antd";
import dayjs from "dayjs";
import { SectionTypeMessages } from "models/enums/messages";
import { IProjectState, ITaskPrioritiesData, IUserState } from "models/interface";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { getThreadName, getUserGravatar } from "utils/commonFunctions";
import classNames from "classnames";
import MessageThread from "../../Project/Sections/MessageThread";
import { useProjectDashboardStore, useUserStore } from "stores";
import StatusChange from "components/sharedComponents/StatusChange/StatusChange";
// import ExpandedMessagePopup from "components/sharedComponents/ExpandedMessagePopup/ExpandedMessageDrawer" //NO SONAR

const EachTask: FC<{
  data: any;
  pageCmsData: any;
  index: number;
  // socket?: any;
  assignedByMe?: boolean;
  setListDetails: any;
  listDetails: any;
}> = ({
  data,
  pageCmsData,
  index,
  /*socket,*/
  listDetails,
  setListDetails,
  assignedByMe = true,
}) => {

    const { userDetails } = useUserStore((state: IUserState) => state);
    const [eachTaskDetail, setEachTaskDetail] = useState(data);

    const { taskListCmsData } = useProjectDashboardStore(
      (state: IProjectState) => state
    )

    const taskName = (item: any) => {
      if (item?.stage_name) {
        return `${item.task_name} | ${item.stage_name}`;
      }
      return item?.task_name;
    };

    const renderTeamAvatar = (item: ITaskPrioritiesData) => {
      if (item.org_logo) {
        return (
          <div className="userAvtr">
            <img src={item.org_logo} alt="" />
          </div>
        );
      }

      return (
        <div style={{ backgroundColor: item.org_color }} className="userAvtr">
          {item.team_name?.split("")[0].toLocaleUpperCase()}
        </div>
      );
    };

    const getDate = (due_date: Date, task_status_id: number) => {
      const differenceInDays = dayjs(due_date).diff(
        dayjs(new Date().setHours(0, 0, 0, 0)),
        "days"
      );
      if (differenceInDays < -1) {
        return (
          <div
            className={classNames({
              errorColor: task_status_id !== 3,
            })}
          >
            {dayjs(due_date).format("MMM DD")}
          </div>
        );
      }
      if (differenceInDays === -1) {
        return (
          <div
            className={classNames({
              errorColor: task_status_id !== 3,
            })}
          >
            {pageCmsData?.due_date?.yesterday}
          </div>
        );
      }
      if (differenceInDays === 0) {
        return <div>{pageCmsData?.due_date?.today}</div>;
      }
      if (differenceInDays === 1) {
        return <div>{pageCmsData?.due_date?.tomorrow}</div>;
      }
      if (differenceInDays > 1) {
        return <div>{dayjs(due_date).format("MMM DD")}</div>;
      }
      return <div className="textMuted">{pageCmsData?.due_date?.none}</div>;
    };

    const renderAvatar = (assign: boolean, item: any) => {
      const name_key = assign ? "assignee_name" : "assigner_name";
      const profilePic_key = assign ? "assignee_dp" : "assigner_dp";
      const email_key = assign ? "assignee_email" : "assigner_email";

      const activeUser = Boolean(data.team_member.filter((e: any) => e?.email === data?.[email_key])?.length)

      if (!data?.[name_key] && !data?.[email_key]) {
        return (
          <div className="unassignUserBlkWrap userBlkWrap">
            <span className="unassign"></span>
            <div className="userName textMuted">{pageCmsData.lbl_unassigned}</div>
          </div>
        );
      }

      if (data?.[email_key] && !activeUser) {
        return <div className="userBlkWrap">
          <Tooltip title={data?.[email_key]} placement="bottom" color={"#2E364C"}>
            <div className="userAvtr userAvtrBlank">
            </div>
          </Tooltip>
          <div className="userName-details userName">
            <div className="userAvtrBlankContent">
              {data?.[email_key]}
            </div>
          </div>
        </div>
      }

      return (
        <div className="userBlkWrap">
          <Tooltip title={data?.[name_key]} placement="bottom" color={"#2E364C"}>
            <div className="userAvtr">
              {data?.[profilePic_key] ? (
                <img src={data?.[profilePic_key]} alt="profile avatar" />
              ) : (
                getUserGravatar(data?.[name_key] ?? data?.[email_key])
              )}
            </div>
          </Tooltip>
          <div className="userName">
            {userDetails?.email === data?.[email_key]
              ? `${data?.[name_key]} (${pageCmsData?.lbl_me})`
              : (data?.[name_key] ?? data?.[email_key])}
          </div>
        </div>
      );
    };

    const findProjectRoleId = (e: any) => {
      let roleId = userDetails?.project_permissions?.find(
        (element: any) => element.project_id == e?.project_id
      );

      return roleId?.role_id;
    };

    return (
      <tr
        className="not-empty"
        key={`${data.org_id}-${data.project_id}-${data.section_id}-${data.task_id}`}
      >
        <td className="statusWrap withImg">
          <StatusChange
            eachTaskDetail={eachTaskDetail}
            setEachTaskDetail={setEachTaskDetail}
            project_role_id={findProjectRoleId(eachTaskDetail)}
            index={index}
            setTaskListDetails={setListDetails}
            taskListDetails={listDetails}
          // socket={socket}
          />
        </td>
        <td className="withMsg">
          <Tooltip
            title={
              taskListCmsData?.task_priority_options?.find(
                (eachStatus) =>
                  eachStatus.priority_id === (eachTaskDetail.task_priority_id ?? 2)
              )?.priority_name
            }
            placement="bottom"
            color={"#2E364C"}
          >
            <span className={`priorityWrap priorityIcon
          ${taskListCmsData?.task_priority_options?.find(
              (eachStatus) =>
                eachStatus.priority_id ===
                (eachTaskDetail.task_priority_id ?? 2)
            )?.className
              }
        `} />
          </Tooltip>
          <div>
            <span className="simpleTaskListTaskName">{taskName(data)}</span>
          </div>
          <MessageThread
            componentId="myTaskListTableMessaging"
            sectionId={data.section_id}
            taskId={data.task_id}
            sectionType={
              data.section_type_id === 1
                ? SectionTypeMessages.SIMPLE_LIST_TASK
                : SectionTypeMessages.GRID_LIST_BLOCK
            }
            threadName={getThreadName(data.task_name, data.stage_name)}
            sectionName={data.section_name}
            taskDetails={data}
            projectId={data.project_id}
            projectName={data.project_name}
            orgId={data.org_id}
            teamMember={data.team_member}
            isTemplate={false}
            isDashBoardOrPlanner={true}
          />
          {/* <ExpandedMessagePopup
                sectionId={data.section_id}
                taskId={data.task_id}
                sectionType={
                  data.section_type_id === 1
                    ? SectionTypeMessages.SIMPLE_LIST_TASK
                    : SectionTypeMessages.GRID_LIST_BLOCK
                }
                threadName={getThreadName(data.task_name, data.stage_name)}
                sectionName={data.section_name}
                taskDetails={data}
                projectId={data.project_id}
                projectName={data.project_name}
                orgId={data.org_id}
                teamMember={data.team_member}
                openMessageExpandedView={openMessageExpandedView}
                index={index}
                setOpenMessageExpandedView={setOpenMessageExpandedView}
                expandedViewForMessageIndex={expandedViewForMessageIndex}
                isTemplate={false}
              /> */}
        </td>
        <td>
          <div className="workspacePriority">
            <Tooltip title={data.team_name} placement="bottom" color={"#2E364C"}>
              <div className="userBlkWrap">{renderTeamAvatar(data)}</div>
            </Tooltip>
            <div className="projectName">
              <Tooltip title={data.project_name} placement="bottom" color={"#2E364C"}>
                <Link to={`/org/${data.org_key}/project/${data.project_id}`}>
                  {data.project_name}
                </Link>
              </Tooltip>
            </div>
          </div>
        </td>
        <td>{getDate(data.due_date, data.task_status_id)} </td>
        <td className="assignWrap">{renderAvatar(assignedByMe, data)}</td>
      </tr>
    );
  };

export default EachTask;
