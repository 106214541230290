import { Dropdown, MenuProps } from 'antd';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import AssignedByMe from './ListView/AssignedByMe';
import AssignedToMe from './ListView/AssignedToMe';
import TaskCalendarWrapper from './CalendarView/TaskCalendarWrapper';

const MyTasks: FC<{ pageCmsData: any }> = ({ pageCmsData }) => {
  const [selectedTab, setSelectedTab] = useState<'today' | 'overdue' | 'all'>(
    'all',
  );
  const [viewAssignedToMe, setViewAssignedToMe] = useState(true);
  const [label, setLabel] = useState<string>(
    pageCmsData?.cta_switch_assign_by_me,
  );

  const [calendarView, setCalendarView] = useState(false);

  const changeTab = (filter: 'today' | 'overdue' | 'all') => {
    setSelectedTab(filter);
  };

  useEffect(() => {
    if (viewAssignedToMe) setLabel(pageCmsData?.cta_switch_assign_by_me);
    else setLabel(pageCmsData?.cta_switch_assign_to_me);
  }, [pageCmsData]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'switch_view') {
      setViewAssignedToMe(!viewAssignedToMe);
      setTimeout(() => {
        if (label === pageCmsData.cta_switch_assign_to_me) {
          setLabel(pageCmsData?.cta_switch_assign_by_me);
        } else setLabel(pageCmsData?.cta_switch_assign_to_me);
      }, 1000);
    }
  };

  const dropdownItems = [
    {
      key: 'switch_view',
      label: (
        <div className="customDropDownItem">
          <span className={'refresh'}></span>
          {label}
        </div>
      ),
    },
    // {
    //   key: 'calendar_view',
    //   label: (
    //     <div
    //       className="customDropDownItem"
    //       onClick={() => {
    //         setCalendarView((prev) => !prev);
    //       }}
    //     >
    //       <span className={'calendarView'}></span>
    //       {calendarView ? 'List View' : 'Calendar View'}
    //     </div>
    //   ),
    // },
  ];

  const renderComponent = () => {
    return viewAssignedToMe ? (
      <AssignedToMe pageCmsData={pageCmsData} filter={selectedTab} />
    ) : (
      <AssignedByMe pageCmsData={pageCmsData} filter={selectedTab} />
    );
  };

  return (
    <div className="taskPrioritiesWrap">
      <div className="flexSubHeading">
        <div className="leftElem">
          <div className="itemNameBlk">
            <span className="section-text-span">
              {viewAssignedToMe
                ? pageCmsData?.lbl_my_tasks
                : pageCmsData?.lbl_tasks_assigned_by_me}
            </span>
            <Dropdown
              menu={{ items: dropdownItems, onClick }}
              trigger={['click']}
              placement="bottomLeft"
            >
              <span className="cmnIcon blueMore"></span>
            </Dropdown>
          </div>
        </div>
        {!calendarView && (
          <div className="rightElem">
            <ul className="actionBtnSec">
              <li>
                <button
                  className={classNames('tablinks actionBtnSecli', {
                    active: selectedTab === 'all',
                  })}
                  onClick={() => changeTab('all')}
                >
                  {pageCmsData?.lbl_all}
                </button>
              </li>
              <li>
                <button
                  className={classNames('tablinks actionBtnSecli', {
                    active: selectedTab === 'today',
                  })}
                  onClick={() => changeTab('today')}
                >
                  {pageCmsData?.lbl_today}
                </button>
              </li>
              <li>
                <button
                  className={classNames('tablinks actionBtnSecli', {
                    active: selectedTab === 'overdue',
                  })}
                  onClick={() => changeTab('overdue')}
                >
                  {pageCmsData?.lbl_overdue}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
      {!calendarView ? (
        renderComponent()
      ) : (
        <TaskCalendarWrapper pageCmsData={pageCmsData} />
      )}
    </div>
  );
};

export default MyTasks;
