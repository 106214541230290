import { FC } from "react";
import { IOrganization, IUserDetails } from "../../../models/interface";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

export const Organization: FC<{
  details: IOrganization;
  userDetails: IUserDetails;
  currentOrganization?: IOrganization | null;
}> = ({ details, userDetails, currentOrganization }) => {
  const navigate = useNavigate();

  function logoDivClicked(e: any) {
    navigate("/org/" + details.org_key + "/dashboard");
  }

  return (
    <Tooltip
      title={details.organization_name}
      color={"#2E364C"}
      placement="right"
    >
      <li
        className={
          currentOrganization?.org_key === details.org_key ? "active" : ""
        }
        onClick={logoDivClicked}
        onKeyDown={() => {}}
        id={details.org_key}
      >
        {details.organization_logo ? (
          <img src={details.organization_logo} alt="" />
        ) : (
          <div style={{ backgroundColor: details.color }} className="noLogo">
            {details.organization_name
              ? details.organization_name?.split("")[0].toLocaleUpperCase()
              : userDetails.full_name.charAt(0)}
          </div>
        )}
      </li>
    </Tooltip>
  );
};
