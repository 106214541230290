import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from "@fullcalendar/core/locales-all";
import BrowserStorageService from "services/browserStorage.service";
import dayjs from "dayjs";
import CalendarEventTag from "./CalendarEventTag";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { IPlannerAttributes, IPlannerEventsQuery } from "models/interface";

interface IFullCalendarComponent {
  calendarRef: React.MutableRefObject<FullCalendar>;
  options: {
    plannerView?: number;
    showWeekends?: boolean;
  };
  plannerEventData: any;
  eventReceiveHandler: (arg: any) => void;
  dropHandler: (arg: any) => void;
  eventDropHandler: (arg: any) => void;
  eventClickHandler: (arg: any) => void;
  plannerCmsData?: IPlannerAttributes;
  setDefaultDate: React.Dispatch<React.SetStateAction<string | null>>;
  setDefaultIsAllDay: React.Dispatch<React.SetStateAction<boolean>>;
  setShowNewPlannerEntryDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showPlannerEntryDrawer: boolean;
  defaultPlannerEventsQuery: IPlannerEventsQuery | null;
  setDefaultPlannerEventsQuery: React.Dispatch<React.SetStateAction<IPlannerEventsQuery | null>>;
  googleEventData?:any;
}

const FullCalendarComponent: React.FC<IFullCalendarComponent> = ({
  calendarRef,
  options,
  plannerEventData,
  eventReceiveHandler,
  dropHandler,
  eventDropHandler,
  eventClickHandler,
  plannerCmsData,
  setDefaultDate,
  setDefaultIsAllDay,
  setShowNewPlannerEntryDrawer,
  showPlannerEntryDrawer,
  defaultPlannerEventsQuery,
  setDefaultPlannerEventsQuery,
  googleEventData

}) => {
  const browserStorage = BrowserStorageService.getInstance();
  const locale = browserStorage.getLocalization().language;
  const [showWeekends, setShowWeekends] = useState(options.showWeekends);
  const [usablePlannedEventData, setUsablePlannedEventData] = useState<any>(null);

  const dayHeaderContent = (args: any) => {
    switch (options.plannerView) {
      case 1:
      case 2:
        return (
          dayjs(args.date).format("ddd").toUpperCase() +
          dayjs(args.date).format(" DD")
        );
      case 3:
        return dayjs(args.date).format("ddd").toUpperCase();
      case 4:
        return dayjs(args.date).format("dddd").toUpperCase();
      default:
        return;
    }
  };

  useEffect(() => {
    setShowWeekends(options.showWeekends);
  }, [options.showWeekends]);

  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    if(draggableEl) {
      new Draggable(draggableEl, {  //NOSONAR
        itemSelector: ".fc-event",
        eventData: function(eventEl) {
          let eventData = JSON.parse(eventEl.getAttribute("data-event") ?? "{}");
          
          return {
            title: eventData.display_name,
            id: eventData.uuid,
            backgroundColor: eventData.backgroundColor,
            borderColor: 'transparent',
            organization_name: eventData.org_name,
            organization_logo: eventData.org_logo,
            org_key: eventData.org_key,
            project_id: eventData.project_id,
            section_id: eventData.section_id,
            task_id: eventData.task_id,
            stage_id: eventData.stage_id,
            block_id: eventData.block_id,
            description: eventData.description,
            is_personal: eventData.is_personal,
            deleted_on: eventData.deleted_on,
            borderLeftColor: eventData.borderLeftColor,
            teamColor: eventData.teamColor,
            text_color: eventData.textColor,
            hoveredBorderColor: eventData.borderColor,
          };
        }
      });
    }
  }, []);
  
  useEffect(() => {
    if (plannerEventData) {
      const tempPlannedEventData: any = [];

      plannerEventData.forEach((elem: any) => {
        let tempElem = { ...elem, hoveredBorderColor: elem.borderColor, borderColor: 'transparent' };
        tempPlannedEventData.push(tempElem);
      });

      setUsablePlannedEventData(tempPlannedEventData);
    }
  }, [plannerEventData, googleEventData]);

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin]}
      droppable={true}
      editable={true}
      initialView="timeGridWeek"
      slotDuration="00:15:00"
      slotLabelInterval="01:00:00"
      slotLabelFormat={{
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: true,
        meridiem: "short",
      }}
      locales={allLocales}
      locale={locale}
      weekends={showWeekends}
      dayHeaderContent={dayHeaderContent}
      scrollTime={
        dayjs().hour() < 8
          ? "08:00:00"
          : dayjs().subtract(2, "hours").format("HH:mm:ss")
      }
      headerToolbar={{
        start: "",
        center: "",
        end: "",
      }}
      contentHeight={window.innerHeight - 160}
      events={[...usablePlannedEventData??[], ...googleEventData??[]]}
      eventClick={eventClickHandler}
      eventContent={(arg) => <CalendarEventTag arg={arg} options={options} plannerCmsData={plannerCmsData} />}
      dayMaxEventRows={true}
      dayMaxEvents={true}
      moreLinkClassNames="customMoreLink"
      eventMaxStack={3}
      drop={dropHandler}
      eventReceive={eventReceiveHandler}
      eventDrop={eventDropHandler}
      dropAccept=".fc-event"
      dateClick={(info) => {
        if (!showPlannerEntryDrawer) {
          const { dateStr, allDay } = info;
          setDefaultDate(dateStr);
          setDefaultIsAllDay(allDay);
          setShowNewPlannerEntryDrawer((prev) => !prev);
        }
      }}
      eventResize={eventDropHandler}
      eventMouseEnter={(e) => {
        e.el.style.borderColor = e.event.extendedProps.hoveredBorderColor;
      }}
      eventMouseLeave={(e) => {
        e.el.style.borderColor = e.event.borderColor;
      }}
      datesSet={(info) => {
        const startDate = dayjs(info.start).subtract(3, "d").format("YYYY-MM-DD"),
          endDate = dayjs(info.end).add(2, "d").format("YYYY-MM-DD");

        if (defaultPlannerEventsQuery?.startDate !== startDate ||
          defaultPlannerEventsQuery?.endDate !== endDate) {
            setDefaultPlannerEventsQuery({startDate, endDate});
          }
      }}
      forceEventDuration={true}
    />
  );
};

export default FullCalendarComponent;
