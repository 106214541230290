import { Tooltip } from "antd";
import { IGridList, ITaskList } from "models/interface";

const TaskStatus: React.FC<{
  eachTaskDetail?: IGridList | ITaskList;
  taskStatusChangeHandler: (statusId: number) => void;
}> = () => {
  return (
    <td className="statusWrap withImg">
      <Tooltip title="To Do" placement="bottom">
        <img
          // onClick={() =>
          //   hasPermissions(
          //     [ERbacPermissions.PROJECT_SECTION_TASK_STATUS_EDIT],
          //     projectDetails?.associated_role_id
          //   ) && taskStatusChangeHandler(eachTaskDetail?.task_status_id ?? 1)
          // }
          src={`https://assets-qa.cleversort.com/sticker_pack/default/sticker-default-todo.svg`}
          alt="Todo"
        />
      </Tooltip>
    </td>
  );
};

export default TaskStatus;
