import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { message } from 'antd';

import {
  ILoaderState,
  IProjectCreationAttributes,
  IProjectFolderDetails,
  IProjectSubmitData,
  IProjectList,
  IOrganizationAssociatedUserDetails,
  ICreateProjectResponseDetails,
  IProjectPermissions,
} from '../../../models/interface';
import {
  useDashboardStore,
  useLoaderStore,
  useUserStore,
} from '../../../stores';
import { dashboardService, loggerService } from '../../../services';
import { useAnalytics } from 'components/sharedComponents';
import {
  generateNewListFromCreateProject,
  generateNewProjectList,
} from 'utils/generateNewList';
import { getCurrentOrganization, removeOverlay } from 'utils/commonFunctions';
import RightSideDrawer from 'components/sharedComponents/RightSideDrawer/RightSideDrawer';
import { modeOps } from 'models/enums/constants';
import { useOrganizationRoutes } from 'routes/orgRoutes';

const CreateProject: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  showSidePanel: boolean;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTemplate: any;
}> = ({
  pageSpecificDataState,
  showSidePanel,
  setShowSidePanel,
  selectedTemplate,
}) => {
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const navigate = useNavigate();
  const gaContext = useAnalytics();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [associatedUser, setAssociatedUser] = useState<any>([]);
  const [isFirstBlur, setIsFirstBlur] = useState(true);
  const [isFirstError, setIsFirstError] = useState(false);

  const defaultValues: Partial<IProjectSubmitData> = {
    projectName: '',
    folderId: 0,
  };

  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    setFocus,
    formState: { errors },
    getValues,
    clearErrors,
  } = useForm<IProjectSubmitData>({
    defaultValues,
  });

  const projectValue = watch('projectName');

  const storeUserDetail = useUserStore((state) => state.userDetails);
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const {
    userDetails,
    projectRolePermissionsList,
    setProjectRolePermissionsList,
  } = useUserStore((state: any) => state);
  const { folderList } = useDashboardStore((state: any) => state);
  const {
    folderProjectList,
    setFolderProjectList,
    setProjectList,
    selectedFolderId,
    setSelectedFolderId,
  } = useDashboardStore((state: any) => state);

  const sendAnalyticsEvent = () => {
    gaContext.updateProps({
      username: userDetails.full_name,
      email: userDetails.email,
      organization: getCurrentOrganization(
        org_key,
        userDetails.organization_info,
      )
        ? getCurrentOrganization(org_key, userDetails.organization_info)
            ?.organization_name
        : '',
      eventName: 'Create Project Finished',
      eventCategory: 'Projects',
      eventLabel: 'Create Project',
    });

    return true;
  };

  const handleBlur = async () => {
    if (isFirstBlur) {
      setIsFirstBlur(false);
      if (projectValue.trim().length > 3 && projectValue.trim().length < 51) {
        clearErrors('projectName');
        setIsDisabled(false);
      } else {
        setError('projectName', {
          message:
            pageSpecificDataState?.lbl_error_message_project_name_validation,
        });
        setIsDisabled(true);
      }
    }
  };

  const onSubmitHandler = async (data: IProjectSubmitData) => {
    sendAnalyticsEvent();

    try {
      setLoaderState('active');
      let payload = {
        projectName: data.projectName.trim(),
        projectDescription: '',
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        folderId: +data.folderId,
        userId: userDetails.user_id,
        associatedUsers: associatedUser
          .filter(
            (ele: IOrganizationAssociatedUserDetails) => ele.should_delete,
          )
          .map((ele: IOrganizationAssociatedUserDetails) => {
            return { userId: ele.user_id, roleId: ele.role_id };
          }),
        newUserAdded: associatedUser
          .filter(
            (ele: IOrganizationAssociatedUserDetails) => ele.should_delete,
          )
          .map((ele: IOrganizationAssociatedUserDetails) => {
            return {
              userId: ele.user_id,
              roleId: ele.role_id,
              email: ele.email,
              projectName: data.projectName.trim(),
              fullName: ele.full_name,
              orgKey: org_key,
              language: ele.additional_settings?.language ?? 'en',
              organizationId: getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!,
              organizationName: getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_name!,
              senderName: storeUserDetail?.full_name!,
            };
          }),
      };

      let response: ICreateProjectResponseDetails = !selectedTemplate
        ? await dashboardService.createProject(payload)
        : await dashboardService.createProjectFromTemplate({
            ...payload,
            templateId: selectedTemplate.template_id,
          });

      if (response.messageId === 1) {
        /** Update latest project permissions dynamically */
        let project_permissions =
          response.data.project_details[0].project_permissions[0];
        if (projectRolePermissionsList) {
          const isCurrentProjectPermissionsAvailable =
            projectRolePermissionsList.find(
              (eachProject: IProjectPermissions) =>
                eachProject.project_id ===
                response.data.project_details[0].project_id,
            );
          if (!isCurrentProjectPermissionsAvailable) {
            setProjectRolePermissionsList([
              ...projectRolePermissionsList,
              project_permissions,
            ]);
          }
        } else {
          setProjectRolePermissionsList(
            response.data.project_details[0].project_permissions,
          );
        }
        /***************************************************/
        let targetIndex: number[] = [];
        let targets = folderProjectList.filter(
          (ele: IProjectFolderDetails) => ele.row_is_duplicate === true,
        );

        folderProjectList.forEach(
          (element: IProjectFolderDetails, index: number) => {
            if (element.row_is_duplicate) targetIndex.push(index);
          },
        );

        let newList: IProjectFolderDetails[] = generateNewListFromCreateProject(
          response.data,
        );
        let newProjectList: IProjectList[] = generateNewProjectList(
          response.data,
        );

        targetIndex.forEach((ele, index) => {
          newList.splice(ele, 0, targets[index]);
        });

        sendAnalyticsEvent();
        setFolderProjectList(newList);
        setProjectList(newProjectList);
        removeOverlay();
        setShowSidePanel(false);
        setSelectedFolderId(0);
        let route = `${organizationBaseRouteUrl}/project/${response.data.project_details[0].project_id}`;
        navigate(route);
      } else if (response.messageId === -2) {
        setError('projectName', {
          message: pageSpecificDataState?.lbl_error_message_project_name_exists,
        });
      }
    } catch (err) {
      message.error(pageSpecificDataState?.lbl_error_message_generic, 3);
      await loggerService.log({
        severity: 'High',
        message: `project creation api failed`,
        payload: { ApiResponse: err },
      });
    } finally {
      setLoaderState('inactive');
    }
  };

  useEffect(() => {
    if (userDetails) {
      setAssociatedUser([
        {
          email: userDetails.email,
          user_id: 1,
          full_name: userDetails.full_name
            ? userDetails.full_name
            : userDetails.email,
          full_name_for_display: userDetails.full_name
            ? `${userDetails.full_name} (${pageSpecificDataState?.lbl_default_member_suffix})`
            : `${userDetails.email} (${pageSpecificDataState?.lbl_default_member_suffix})`,
          profile_picture: userDetails.profile_picture,
          role_id: 3,
          should_delete: false,
          isNew: false,
        },
      ]);
    }
  }, [userDetails, pageSpecificDataState]);

  useEffect(() => {
    setValue('folderId', selectedFolderId);
  }, [folderList, selectedFolderId]);

  useEffect(() => {
    if (showSidePanel) {
      setFocus('projectName');
    }

    return () => {
      setIsFirstBlur(true);
    };
  }, [showSidePanel]);

  useEffect(() => {
    const subscribe = watch((data) => {
      if (isFirstBlur) {
        if (data.projectName!.trim().length > 50) {
          setIsFirstError(true);
          setIsDisabled(true);
          setError('projectName', {
            message:
              pageSpecificDataState?.lbl_error_message_project_name_validation,
          });
        } else if (isFirstError && data.projectName!.trim().length < 4) {
          setIsDisabled(true);
          setError('projectName', {
            message:
              pageSpecificDataState?.lbl_error_message_project_name_validation,
          });
        } else {
          clearErrors('projectName');
          setIsDisabled(false);
        }
        return;
      }
      if (
        data.projectName!.trim().length >= 4 &&
        data.projectName!.trim().length < 51 &&
        !isFirstBlur
      ) {
        clearErrors('projectName');
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
        setError('projectName', {
          message:
            pageSpecificDataState?.lbl_error_message_project_name_validation,
        });
      }
    });

    return () => subscribe.unsubscribe();
  }, [watch, isFirstBlur, isFirstError]);

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <RightSideDrawer
        pageSpecificDataState={pageSpecificDataState}
        showSidePanel={showSidePanel}
        setShowSidePanel={setShowSidePanel}
        register={register}
        errors={errors}
        isDisabled={isDisabled}
        getValues={getValues}
        setIsDisabled={setIsDisabled}
        reset={reset}
        associatedUser={associatedUser}
        setAssociatedUser={setAssociatedUser}
        mode={modeOps.ADD}
        setValue={setValue}
        onBlur={() => handleBlur()}
      />
    </form>
  );
};

export default CreateProject;
