import { FC, useEffect, useState } from "react";
import { useLoaderStore, useLocalizationStore } from "../../../stores";
import { ILoaderState } from "../../../models/interface";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { cmsService } from "services";
import { getPageSpecificData } from "utils/commonFunctions";

const GeneralLoginInvitationError: FC<{}> = () => {
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { logout } = useAuth0();
  const [pageCmsData, setPageCmsData] = useState<any>(null);
  const [searchParam] = useSearchParams();
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  const getCmsData = async () => {
    const cmsPageName = ["page-logins"];
    const pageSlug = ["page-login"];
    let tmpPageData: any;
    setLoaderState("active");
    let pageSpecificDataStore = cmsData.pageSpecificData;
    if (pageSpecificDataStore.length > 0) {
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      if (!tmpPageData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
        tmpPageData && setPageCmsData(tmpPageData[0].attributes);
      } else {
        tmpPageData && setPageCmsData(tmpPageData[0].attributes);
      }
      setLoaderState("inactive");
    } else {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      tmpPageData && setPageCmsData(tmpPageData[0]?.attributes);
      setLoaderState("inactive");
    }
  };

  useEffect(() => {
    document.getElementById("root")!.className =
      "genericWrapper loginWrapperWhole";
    (async () => {
      await getCmsData();
      setTimeout(() => {
        logout();
      }, 3000);
    })();

    return () => {
      document.getElementById("root")!.className = "";
    };
  }, []);

  return (
    <div className="registrationGenericContent">
      <div className="leftImg" />
      <div className="loginBlk">
        <h2>{pageCmsData?.lbl_error_title ?? ""}</h2>
        {searchParam.get("error") === "unknown" ? (
          <p>{pageCmsData?.lbl_error_message ?? ""}</p>
        ) : null}
        {searchParam.get("error") === "wrong-email" ? (
          <p>{pageCmsData?.lbl_signup_with_wrong_email ?? ""}</p>
        ) : null}
        {searchParam.get("error") === "already-accepted" ? (
          <p>{pageCmsData?.lbl_error_already_accepted ?? ""}</p>
        ) : null}
        {searchParam.get("error") === "not-valid" ? (
          <p>{pageCmsData?.lbl_error_invitation_not_valid ?? ""}</p>
        ) : null}
        {searchParam.get("error") === "invitation-expired" ? (
          <p>{pageCmsData?.lbl_error_invitation_expired ?? ""}</p>
        ) : null}
      </div>
    </div>
  );
};

export default GeneralLoginInvitationError;
