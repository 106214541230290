import { IpncCmsStore } from "models/interface";
import { create } from "zustand";

export const usePncCmsDataStore = create<IpncCmsStore>((set) => ({
    //initial states
    projectDetailsPageSpecificData: null,
    projectUpdatePageSpecificData: null,
    pncFeedbackSpecificCmsData: null,
    pncAddEnvSpecificCmsData: null,
    //actions
    setProjectDetailsPageSpecificData: (value) => set({ projectDetailsPageSpecificData: value }),
    setProjectUpdatePageSpecificData: (value) => set({ projectUpdatePageSpecificData: value }),
    setPncFeedbackSpecificCmsData: (value) => set({ pncFeedbackSpecificCmsData: value }),
    setPncAddEnvSpecificCmsData: (value) => set({ pncAddEnvSpecificCmsData: value })
}));
