import React, { ChangeEventHandler, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form';

interface INumberFieldDropdownItems {
    key: string
    label: React.JSX.Element
}

interface INumberFieldData {
    id: string,
    name: string,
    type: string,
    formatTypes?: [string],
    format: string,
    sortable: boolean,
    mapping_id: string,
    description: string,
    preview: string
}

const NumberField: React.FC<{ defaultMeta: Object, isInEditMode: boolean, addEditField: Function }> = ({ defaultMeta, isInEditMode, addEditField }) => {
    const [data, setData] = useState<any>(defaultMeta)
    const [payload, setPayload] = useState<any | null>(null)
    const [format, setFormat] = useState<string>(data.format)

    const formatTypes = useMemo(() => {
        if (payload && payload.formatTypes) {
            return payload.formatTypes.filter((f: any) => f !== null)
        }

        return []
    }, [payload])

    const handleInputChange = (key: "name" | "description", value: string) => {
        if (payload) {
            const temp = { ...payload }

            temp[key] = value

            setPayload(temp)
        }
    }

    const handleTypeChange = (e: any) => {
        const value: string = e.target.value;
        setPayload(data[value])
    }

    const handleFormatChange = (e: any) => {
        const value: string = e.target.value;

        if (payload) {
            const temp = { ...payload }
            const preview = temp.preview;
            
            temp.preview = value + preview.substring(1)
            temp.format = value;

            setPayload(temp)
        }
    }

    const submitData = () => {
        delete payload?.formatTypes
        
        addEditField(payload)
    }

    useEffect(() => {
        if(data["integer"]) setPayload(data["integer"])
        else {
            setPayload(data)
        }
    }, [data])

    return (
        <div>
            {data.subtype ? <div>
                <label>Types</label>
                <select onChange={handleTypeChange}>
                    {data.subtype.map((type: any) => (
                        <option key={type} value={type} >{type}</option>
                    ))}
                </select>
            </div> : null}
            <div>
                <label>Name</label>
                <input type={'text'} value={payload?.name} onChange={(e) => handleInputChange('name', e.target.value)} />
            </div>
            <div>
                <label>Description</label>
                <input type={'text'} value={payload?.description} onChange={e => handleInputChange('description', e.target.value)} />
            </div>
            {formatTypes.length > 0
                ?
                <React.Fragment>
                    <label>Format</label>
                    <select onChange={handleFormatChange}>
                        {
                            formatTypes.map((format: any) => (
                                <option key={format} value={format}>
                                    {format}
                                </option>
                            ))
                        }
                    </select>
                </React.Fragment>
                :
                null
            }
            <div>
                <label>Preview</label>
                <p>{payload?.preview}</p>
            </div>

            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <button className='btnStyle1' onClick={submitData}>{isInEditMode ? 'Update' : 'Create'} Field</button>
            </div>
        </div>
    )
}

export default NumberField