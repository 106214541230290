export enum SectionTypeMessages {
  SIMPLE_LIST_TASK = "simple-list-task",
  GRID_LIST_TASK = "grid-list-task",
  GRID_LIST_STAGE = "grid-list-stage",
  GRID_LIST_BLOCK = "grid-list-block",
  EPIC_AND_STORIES='epic-and-stories'
}

export enum FilterMenuOptionKeys {
  NEW_TO_TOP = "newToTop",
  OLD_TO_TOP = "oldToTop",
  MENTION_ME = "mentionMe",
  HAS_ATTACHMENT = "hasAttachment",
  SELECT_PROJECT = "selectProject",
  TEAM_MEMBER = "teamMember",
}
