import { FC, SelectHTMLAttributes, useEffect, useState } from "react";
import { MenuProps } from "antd";
import {
    ICustomFieldState,
    ILoaderState,
    IProjectState,
} from "models/interface";
import { useLoaderStore, useProjectDashboardStore, useUserStore } from "stores";
import {
    addRemoveOverLay, getCurrentOrganization, removeOverlay,
} from "utils/commonFunctions";
import { useLocation, useParams } from "react-router-dom";
import { customizationService, loggerService, projectService } from "services";
import FieldWrapper from "./FieldWrapper";

enum CustomFieldType {
    STATUS = "1",
    NUMBER = "2",
    FILE = "3",
}

const RightSideDrawerForCustomFieldAddition: FC<{
    showSidePanel: boolean;
    setShowSidePanel: React.Dispatch<React.SetStateAction<ICustomFieldState>>;
    pageSpecificDataState: any,
    fieldMetaData: any,
    getSectionDetails: Function
}> = ({
    showSidePanel,
    setShowSidePanel,
    pageSpecificDataState,
    fieldMetaData,
    getSectionDetails
    //   sectionDetails,
    //   setSectionDetails,
}) => {
        const { customizeSectionUpdateCmsData, projectDetails } =
            useProjectDashboardStore((state: IProjectState) => state);
        const { userDetails } = useUserStore((state: any) => state);
        const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);

        const [fieldID, setFieldID] = useState<string>(CustomFieldType.STATUS)
        const [fieldMap, setFieldMap] = useState<Map<string, Object> | null>(null)
        const [customFieldData, setCustomFieldData] = useState([])
        const [defaultMeta, setDefaultMeta] = useState<Object | null>(null)

        const urlParams = useParams();
        const location = useLocation();

        const project_id = urlParams.projectId;
        const org_key = location.pathname.split("/")[2];

        const closeSidePanel = () => {
            //addRemoveOverLay();
            removeOverlay()
            setShowSidePanel(prev => {
                return {
                    ...prev,
                    show: false
                }
            })
        };

        const handleFieldSelection = (e: any) => {
            const value = e.target.value
            setFieldID(value)

            if (fieldMap) {
                setDefaultMeta(fieldMap.get(value) as Object)
            }
        }

        const getCustomField = async () => {
            try {
                const res = await projectService.getCustomFieldData();
                if (res.data) {
                    let hm = new Map();

                    res.data.forEach((d: any) => {
                        hm.set(d.id + "", d.default_meta)
                    })

                    setFieldMap(hm)
                    setDefaultMeta(hm.get(fieldID))

                    setCustomFieldData(res.data)
                }
            } catch (error) {
                await loggerService.log({
                    severity: "High",
                    message: "Failed to fetch tasklist details",
                    payload: null,
                });
            }
        }

        const addEditField = async (customMeta: any) => {            
            try {
                const payload = {
                    "organizationId": getCurrentOrganization(
                        org_key,
                        userDetails?.organization_info
                      )?.organization_id!,
                    "projectId": project_id,
                    "userId": +pageSpecificDataState.created_by,
                    "sectionId": pageSpecificDataState.section_id,
                    "customItem": customMeta
                }

                const response = await projectService.addEditCustomMeta(payload)

                if (response.messageId === 1) {
                    getSectionDetails()
                    closeSidePanel()
                }
            } catch (error) {
                console.log(error)
            }
        }

        const isInEditMode = fieldMetaData !== null;

        useEffect(() => {
            if (!isInEditMode) {
                getCustomField()
            } else {
                setDefaultMeta(fieldMetaData)
                setFieldID(fieldMetaData.id + "")
            }
        }, [fieldMetaData])

        return showSidePanel ? (
            <div className="customizeStatusUpdateDrawer createNewProjectForm addToggle">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3>{fieldMetaData ? 'Update' : 'Create'} Custom Field</h3>
                        <span
                            className="material-icons-outlined modal-close"
                            onClick={() => closeSidePanel()}
                            onKeyDown={() => { }}
                        >
                            close
                        </span>
                    </div>
                    <div className="modal-body">
                        {!fieldMetaData ? <div className="customStatusUpdateWrapper customizeTaskName">
                            <div className="customStatusUpdate">
                                <select value={fieldID} onChange={handleFieldSelection}>
                                    {customFieldData?.map((eachOption: any) => (
                                        <option key={eachOption.id} value={eachOption.id}>
                                            {eachOption.field_type}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div> : null}
                        <div className="customStatusUpdateWrapper customizeTaskName">
                            <div className="customStatusUpdate">
                                {defaultMeta && <FieldWrapper addEditField={addEditField} isInEditMode={isInEditMode} id={fieldID} defaultMeta={defaultMeta} />}
                            </div>
                        </div>
                    </div>
                    {/* <div className="modal-footer">
                        <div className="btnSec">
                            <button className="noStyle" onClick={() => closeSidePanel()}>
                                {customizeSectionUpdateCmsData?.btn_cancel_cta}
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
        ) : (
            <></>
        );
    };

export default RightSideDrawerForCustomFieldAddition;
