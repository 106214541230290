import BrowserStorage from "models/classes/StorageWrapper";
import { ILocalizationData } from "models/interface";

enum StorageKeys {
  LANGUAGE = "language",
  COUNTRY = "country",
  GLOBAL_DATA = "cs_globalData",
  USER_DETAILS = "userDetails",
  THEME = "cs_theme",
  LAST_VISITED_URL = "latestPathName",
  INVITATION = "invitation_id",
  LAST_URL = "lastVisitedUrl",
  PROJECT_MEMBERS = "project_members",
}

export default class BrowserStorageService extends BrowserStorage<StorageKeys> {
  private static instance?: BrowserStorageService;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new BrowserStorageService();
    }
    return this.instance;
  }

  public getLocalization(): ILocalizationData {
    const language = this.getCookieItem(StorageKeys.LANGUAGE);
    const country = this.getCookieItem(StorageKeys.COUNTRY);
    return {
      language,
      country,
    };
  }

  public setLocalization(value: ILocalizationData) {
    this.setCookieItem(StorageKeys.LANGUAGE, value?.language ?? "en");
    this.setCookieItem(StorageKeys.COUNTRY, value?.country ?? "IN");
  }

  public clearLocalization() {
    this.clearCookieItem(StorageKeys.LANGUAGE);
    this.clearCookieItem(StorageKeys.COUNTRY);
  }

  public getGlobalData() {
    return this.getFromLocalStorage(StorageKeys.GLOBAL_DATA);
  }

  public setGlobalData(value: any) {
    this.saveToLocalStorage(StorageKeys.GLOBAL_DATA, value);
  }

  public clearGlobalData() {
    this.clearItemFromLocalStorage(StorageKeys.GLOBAL_DATA);
  }

  public getUserDetails() {
    return this.getFromLocalStorage(StorageKeys.USER_DETAILS);
  }

  public setUserDetails(value: any) {
    return this.saveToLocalStorage(StorageKeys.USER_DETAILS, value);
  }

  public clearUserDetails() {
    return this.clearItemFromLocalStorage(StorageKeys.USER_DETAILS);
  }

  public getTheme() {
    return this.getFromLocalStorage(StorageKeys.THEME);
  }

  public setTheme(value: any) {
    return this.saveToLocalStorage(StorageKeys.THEME, value);
  }

  public clearTheme() {
    return this.clearItemFromLocalStorage(StorageKeys.THEME);
  }

  public getCountry() {
    return this.getCookieItem(StorageKeys.COUNTRY);
  }

  public setCountry(value: string) {
    return this.setCookieItem(StorageKeys.COUNTRY, value ?? "IN");
  }

  public clearCountry() {
    return this.clearCookieItem(StorageKeys.COUNTRY);
  }

  public getLanguage() {
    return this.getCookieItem(StorageKeys.LANGUAGE);
  }

  public setLanguage(value: string) {
    return this.setCookieItem(StorageKeys.LANGUAGE, value ?? "en");
  }

  public clearLanguage() {
    return this.clearCookieItem(StorageKeys.LANGUAGE);
  }

  public getLastUrl() {
    return this.getFromLocalStorage(StorageKeys.LAST_URL);
  }

  public setLastUrl(value: any) {
    return this.saveToLocalStorage(StorageKeys.LAST_URL, value);
  }

  public clearLastUrl() {
    return this.clearItemFromLocalStorage(StorageKeys.LAST_URL);
  }

  public getProjectMembers() {
    return this.getFromLocalStorage(StorageKeys.PROJECT_MEMBERS);
  }

  public setProjectMembers(value: any) {
    return this.saveToLocalStorage(StorageKeys.PROJECT_MEMBERS, value);
  }

  public clearProjectMembers() {
    return this.clearItemFromLocalStorage(StorageKeys.PROJECT_MEMBERS);
  }

  // public getFilterState() {
  //   return this.getFromLocalStorage(StorageKeys.FILTER_STATE);
  // }

  // public setFilterState(value: any) {
  //   return this.saveToLocalStorage(StorageKeys.FILTER_STATE, value);
  // }

  // public getFilteredProjectState() {
  //   return this.getFromLocalStorage(StorageKeys.FILTERED_PROJECTS);
  // }

  // public setFilteredProjectState(value: any) {
  //   return this.saveToLocalStorage(StorageKeys.FILTERED_PROJECTS, value);
  // }

  // public getFilteredMemberState() {
  //   return this.getFromLocalStorage(StorageKeys.FILTERED_MEMBERS);
  // }

  // public setFilteredMemberState(value: any) {
  //   return this.saveToLocalStorage(StorageKeys.FILTERED_MEMBERS, value);
  // }

  // public getSortingState() {
  //   return this.getFromLocalStorage(StorageKeys.SORTING_STATE);
  // }

  // public setSortingState(value: any) {
  //   return this.saveToLocalStorage(StorageKeys.SORTING_STATE, value);
  // }
}
