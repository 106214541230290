import { FC, Fragment } from "react";
import { Tooltip } from "antd";
import { IGridList, IGridListStageDetail, ITaskList } from "models/interface";

const StageNameInputField: FC<{
  sectionDetails: IGridList | ITaskList;
  stageDetails: IGridListStageDetail;
  stageName: string;
}> = ({
  stageDetails,
}) => {

  const getStageNameFieldJsx = (stageDetails: IGridListStageDetail) => {
    return (
      <Fragment>
        {stageDetails.stage_name.length > 9 ? (
          <Tooltip
            title={stageDetails.stage_name}
            color={"#2E364C"}
            placement="top"
          >
            <span
              className="stageNameSpan"
            >
              {" "}
              {stageDetails.stage_name}{" "}
            </span>
          </Tooltip>
        ) : (
          <span
            className="stageNameSpan"
          >
            {" "}
            {stageDetails.stage_name}{" "}
          </span>
        )}
      </Fragment>
    );
  };

  return getStageNameFieldJsx(stageDetails);
};

export default StageNameInputField;
