import { Tooltip, message } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';
import { IEachTaskDetail } from 'components/sharedComponents/StatusChange/StatusChange';
import { NotificationEventType } from 'models/enums/notification';
import {
  IProjectState,
  ITaskListDetails,
  IUserDetails,
  IUserState,
} from 'models/interface';
import { useLocation } from 'react-router-dom';
import { projectService } from 'services';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { getCurrentOrganization } from 'utils/commonFunctions';

interface ITaskPriorityComponentProps {
  eachTaskDetail: ITaskListDetails & IEachTaskDetail;
  sectionId?: number | string;
  notifyChannel?: (
    userDetails: IUserDetails,
    eventType: string,
    eventValue?: string,
  ) => void;
  setEachTaskDetail?: React.Dispatch<
    React.SetStateAction<ITaskListDetails & IEachTaskDetail>
  >;
  findTaskIdAndUpdateMainTaskList?: (
    eachTaskDetailUpdated: ITaskListDetails,
  ) => void;
}

const TaskPriority: React.FC<ITaskPriorityComponentProps> = ({
  eachTaskDetail,
  sectionId,
  notifyChannel,
  setEachTaskDetail,
  findTaskIdAndUpdateMainTaskList,
}) => {
  const { taskListCmsData, projectDetails, taskPriorityMaster } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { hasPermissions } = useRbac();
  const { userDetails } = useUserStore((state: IUserState) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { fetchNewPermission } = useFetchNewPermission();

  const taskPriorityChangeHandler = async (task_priority_id: number) => {
    if (typeof eachTaskDetail.task_id === 'string') {
      message.info(taskListCmsData?.error_message_options?.no_task_name, 3);
      return;
    }
    if (
      projectDetails?.project_id &&
      typeof sectionId === 'number' &&
      typeof eachTaskDetail.task_id === 'number' &&
      userDetails?.organization_info
    ) {
      let _priority_id = task_priority_id + 1;

      if (taskPriorityMaster && _priority_id > taskPriorityMaster?.length) {
        _priority_id = 2;
      }
      if (setEachTaskDetail) {
        let eachTaskDetailUpdated = {
          ...eachTaskDetail,
          task_priority_id: _priority_id,
          task_priority_name: taskPriorityMaster?.find(
            (eachPriority) => eachPriority.value === _priority_id,
          )?.label,
        };
        findTaskIdAndUpdateMainTaskList &&
          findTaskIdAndUpdateMainTaskList(eachTaskDetailUpdated);
        setEachTaskDetail((prev) => ({
          ...prev,
          task_priority_id: _priority_id,
          task_priority_name: taskPriorityMaster?.find(
            (eachPriority) => eachPriority.value === _priority_id,
          )?.label,
        }));
      }
      try {
        await projectService.updateTask({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: [
            {
              task_id: eachTaskDetail.task_id,
              task_priority_id: _priority_id,
            },
          ],
        });
        if (notifyChannel) {
          notifyChannel(
            userDetails,
            NotificationEventType.UPDATE_SIMPLE_TASK_ROW,
            JSON.stringify({
              ...eachTaskDetail,
              task_id: eachTaskDetail.task_id,
              task_priority_id: _priority_id,
            }),
          );
        }
      } catch (error: any) {
        if (error?.response?.data?.messageId === -4) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  return (
    <Tooltip
      title={
        taskListCmsData?.task_priority_options?.find(
          (eachStatus) =>
            eachStatus.priority_id === (eachTaskDetail.task_priority_id ?? 2),
        )?.priority_name
      }
      placement="bottom"
      color={'#2E364C'}
    >
      <span
        className={`priorityWrap priorityIcon
              ${
                taskListCmsData?.task_priority_options?.find(
                  (eachStatus) =>
                    eachStatus.priority_id ===
                    (eachTaskDetail.task_priority_id ?? 2),
                )?.className
              }
            `}
        onClick={() =>
          hasPermissions(
            [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
            projectDetails?.associated_role_id,
          ) &&
          projectDetails?.is_archived === false &&
          taskPriorityChangeHandler(eachTaskDetail?.task_priority_id ?? 2)
        }
        onKeyDown={() => {}}
      />
    </Tooltip>
  );
};

export default TaskPriority;
