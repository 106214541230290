import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { AnalyticsProvider } from '../contexts/analytics/AnalyticsProvider';
import { AnalyticsEventTracker } from '../components/sharedComponents';
import ReactGA from 'react-ga4';
import { AuthenticationGuard } from 'auth/authentication-guard';
import Auth0Outlet from 'auth/Auth0Outlet';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import { OrganizationRoutes } from './orgRoutes';
import React from 'react';
import GeneralLoginInvitationError from '../components/pages/GeneralLoginInvitationError/GeneralLoginInvitationError';
import LandingPage from 'components/pages/LandingPage/LandingPage';
import UserOnboardingWrapper from 'components/pages/UserOnboarding/UserOnboardingWrapper';
import { AccountActivation } from 'components/pages/AccountActivation/AccountActivation';
import { TermsOfUse } from 'components/pages/Legal/TermsOfUse';
import { PrivacyPolicy } from 'components/pages/Legal/PrivacyPolicy';
import NotFound from 'components/pages/NotFound/NotFound';
import CompactView from 'components/pages/CompactView/CompactView';
import { AuthCallbackPage, ErrorPage, LoginRedirect } from 'components/pages';
import Auth0ProviderWithNavigate from 'auth/authProviderWithNavigate';

const MEASUREMENT_ID: any = process.env.REACT_APP_TRACKING_ID
  ? process.env.REACT_APP_TRACKING_ID
  : '';

(async () => {
  await ReactGA.initialize(MEASUREMENT_ID);
})();

const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route element={<Auth0Outlet />}>
        <Route
          path="/"
          element={
            <AnalyticsProvider>
              <AuthenticationGuard
                component={LandingPage}
                pageSlug={[
                  'page-dashboard-left-panel',
                  'profile-setting',
                  'archive-project',
                ]}
                cmsPageName={[
                  'page-dashboard-left-panels',
                  'profile-settings',
                  'archive-projects',
                ]}
              />
              <AnalyticsEventTracker />
            </AnalyticsProvider>
          }
        >
          <Route
            path={NavRoutesEnum.USER_ONBOARDING}
            element={
              <AnalyticsProvider>
                <AuthenticationGuard
                  component={UserOnboardingWrapper}
                  pageSlug={['user-onboarding']}
                  cmsPageName={['user-onboardings']}
                />
                <AnalyticsEventTracker />
              </AnalyticsProvider>
            }
          />
          <Route
            path={NavRoutesEnum.CREATE_NEW_TEAM}
            element={
              <AnalyticsProvider>
                <AuthenticationGuard
                  component={UserOnboardingWrapper}
                  pageSlug={['user-onboarding']}
                  cmsPageName={['user-onboardings']}
                />
                <AnalyticsEventTracker />
              </AnalyticsProvider>
            }
          />
          {/* Temp route for UI dev */}
          <Route path={'/compact-view'} element={<CompactView />} />
          <Route
            path="org/*"
            element={<AuthenticationGuard component={OrganizationRoutes} />}
          />
        </Route>
        <Route
          path={'/auth/callback'}
          element={
            <AuthCallbackPage
              pageSlug={['auth-callback-page']}
              cmsPageName={['auth-callback-pages']}
            />
          }
        />
      </Route>
      <Route path="">
        <Route
          index
          path={NavRoutesEnum.ACCOUNT_ACTIVATION}
          element={
            <AnalyticsProvider>
              <AccountActivation
                pageSlug={['account-activation-page']}
                cmsPageName={['account-activation-pages']}
              />
              <AnalyticsEventTracker />
            </AnalyticsProvider>
          }
        />
        <Route
          index
          path={NavRoutesEnum.LOGIN_REDIRECT}
          element={
            <AnalyticsProvider>
              <Auth0ProviderWithNavigate>
                <LoginRedirect />
              </Auth0ProviderWithNavigate>
              <AnalyticsEventTracker />
            </AnalyticsProvider>
          }
        />
        <Route
          index
          path={NavRoutesEnum.SIGNUP_SUCCESS}
          element={
            <AnalyticsProvider>
              <AccountActivation
                pageSlug={['account-activation-page']}
                cmsPageName={['account-activation-pages']}
              />
              <AnalyticsEventTracker />
            </AnalyticsProvider>
          }
        />
        <Route
          path="/invitation-error"
          element={<GeneralLoginInvitationError />}
        />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/error" element={<ErrorPage />} />
      </Route>
    </React.Fragment>,
  ),
);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
