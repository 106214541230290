import { FC, useState } from "react";
import { Tooltip } from "antd";
import GridListDetails from "./GridListDetails";
import { IGridList, IProjectTemplate } from "models/interface";

const GridList: FC<{
  sectionDetails: IGridList;
  templateDetails: IProjectTemplate;
}> = ({ sectionDetails, templateDetails }) => {
  const [sectionToggleState, setSectionToggleState] = useState<
    "expanded" | "collapsed"
  >("expanded");

  const handleSectionToggle = () => {
    setSectionToggleState((prev) =>
      prev === "collapsed" ? "expanded" : "collapsed"
    );
  };

  return (
    <div className="templateSection">
      <div className="taskNameHeadingPane">
        <div className="itemNameBlk">
          <Tooltip
            title="Toggle task view"
            color={"#2E364C"}
            placement="bottom"
          >
            <span
              className={`sidePanelIcon ${
                sectionToggleState === "expanded" ? "arrow-down" : "arrow-right"
              }`}
              color={"#2E364C"}
              onClick={handleSectionToggle}
              onKeyDown={() => {}}
            />
          </Tooltip>
          <span className="section-text-span">
            {sectionDetails.section_name}
          </span>
        </div>
      </div>
      <GridListDetails
        sectionDetails={sectionDetails}
        sectionToggleState={sectionToggleState}
        templateDetails={templateDetails}
      />
    </div>
  );
};

export default GridList;
