import { Input } from "antd";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { debounce } from "utils/commonFunctions";
import {
  ILoaderState,
  IOrganization,
  IProfileSettingCmsData,
  IUpdateTeamDetail,
} from "../../../../models/interface";
import organizationService from "../../../../services/OrganizationService/Organization.service";
import BrowserStorageService from "../../../../services/browserStorage.service";
import { useLoaderStore, useUserStore } from "../../../../stores";
import { UploadAvatar } from "../../../sharedComponents";
import PersonalPurposeComponent from "../UseTypes/PersonalPurposeComponent";
import SchoolPurposeComponent from "../UseTypes/SchoolPurposeComponent";
import WorkPurposeComponent from "../UseTypes/WorkPurposeComponent";

const TeamDetailSettings: FC<{
  lblCms: IProfileSettingCmsData | null;
  organizationInfo: IOrganization | null;
}> = ({ lblCms, organizationInfo }) => {
  const browserStorage = BrowserStorageService.getInstance();
  const navigate = useNavigate();
  const { userDetails, setUserDetails } = useUserStore();
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const [orgName, setOrgName] = useState<string>(
    organizationInfo?.organization_name!
  ),
    [orgNameChanged, setOrgNameChanged] = useState(false);
  const [purposeId, setPurposeId] = useState<number>(
    organizationInfo?.team_type_id!
  );
  const [profilePic, setProfilePic] = useState<string | null>(
    organizationInfo?.organization_logo ?? null
  );
  const { org } = useParams();
  const [schoolValue, setSchoolValue] = useState({
    educationLevel: organizationInfo?.education_level,
    educationMajorId: organizationInfo?.education_major_id,
  }),
    [schoolValueChanged, setSchoolValueChanged] = useState(false);

  const [workValue, setWorkValue] = useState({
    jobTitle: organizationInfo?.job_title,
    jobRoleId: organizationInfo?.job_role_id,
  }),
    [workValueChanged, setWorkValueChanged] = useState(false);

  const [personalValue, setPersonalValue] = useState({
    hobbies: organizationInfo?.hobbies,
  }),
    [personalValueChanged, setPersonalValueChanged] = useState(false);

  const [teamRequiredChecksPassed, setTeamRequiredChecksPassed] =
    useState<boolean>();

  const handleSaveButton = async () => {
    setLoaderState("active");
    const formValue: IUpdateTeamDetail = {
      organization_id: organizationInfo?.organization_id!,
      hobbies: personalValue.hobbies!,
      job_title: workValue.jobTitle!,
      job_role_id: workValue.jobRoleId!,
      education_level: schoolValue.educationLevel!,
      education_major_id: schoolValue.educationMajorId!,
      organization_name: orgName!, //NOSONAR
      organization_type_id: purposeId!, //NOSONAR
    };

    switch (purposeId) {
      case 1:
        formValue.hobbies = null;
        formValue.education_level = null;
        formValue.education_major_id = null;
        break;
      case 2:
        formValue.education_level = null;
        formValue.education_major_id = null;
        formValue.job_title = null;
        formValue.job_role_id = null;
        break;
      case 3:
        formValue.job_title = null;
        formValue.job_role_id = null;
        formValue.hobbies = null;
        break;
    }

    await organizationService.updateTeamDetail(formValue);
    const newOrganizationInfo: IOrganization[] =
      userDetails!.organization_info!.map((e: IOrganization) => {
        //NOSONAR
        if (e.organization_id === organizationInfo?.organization_id) {
          return {
            ...e, team_type_id: purposeId, organization_name: orgName,
            job_title: workValue.jobTitle!, job_role_id: workValue.jobRoleId!, hobbies: personalValue.hobbies!,
            education_level: schoolValue.educationLevel!
          };
        }
        return e;
      });

    browserStorage.setUserDetails({
      ...userDetails,
      hobbies: personalValue.hobbies!,
      job_title: workValue.jobTitle!,
      job_role_id: workValue.jobRoleId!,
      education_level: schoolValue.educationLevel!,
      education_major_id: schoolValue.educationMajorId!,
      organization_info: newOrganizationInfo,
    });
    setUserDetails({
      ...userDetails!,
      hobbies: personalValue.hobbies!,
      job_title: workValue.jobTitle!,
      job_role_id: workValue.jobRoleId!,
      education_level: schoolValue.educationLevel!,
      education_major_id: schoolValue.educationMajorId!,
      // @ts-ignore
      organization_info: newOrganizationInfo!, // NOSONAR
    });
  };

  const avatarChangeHandler = async (
    base64data: string | ArrayBuffer | null,
    extension: string
  ) => {
    if (base64data && organizationInfo?.org_key) {
      const newTemp = userDetails?.organization_info?.map((e) => {
        if (e.org_key !== organizationInfo?.org_key) {
          return e
        }
        return { ...e, organization_logo: base64data.toString() }
      })
      const userTemp: any = { ...userDetails, organization_info: newTemp }
      setUserDetails(userTemp)
      await organizationService
        .uploadOrgLogo({
          base64Str: base64data,
          extension,
          org_key: organizationInfo?.org_key
        })
        .then((res) => {
          // @ts-ignore
          setProfilePic(res.data.url);
        });
    } else {
      updateOrganizaitonLogo();
    }
  };

  /**delete Organization logo */
  const updateOrganizaitonLogo = async () => {
    setProfilePic(null);
    const newTemp = userDetails?.organization_info?.map((e) => {
      if (e.org_key !== organizationInfo?.org_key) {
        return e
      }
      return { ...e, organization_logo: null }
    })

    const userTemp: any = { ...userDetails, organization_info: newTemp }
    setUserDetails(userTemp);
    await organizationService.updateOrgLogo({ org_key: organizationInfo?.org_key! })
  }

  const teamNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOrgName(e.target.value);
    setOrgNameChanged(organizationInfo?.organization_name?.trim() !== e.target.value?.trim())
  };

  const handleSchoolValueChange = (
    level: string | null,
    majorId: number | null
  ) => {
    setSchoolValue({
      educationLevel: level,
      educationMajorId: majorId,
    });

    if (level !== organizationInfo?.education_level || majorId !== organizationInfo?.education_major_id) {
      setSchoolValueChanged(true)
      return
    }
    setSchoolValueChanged(false)
  };

  const handlePersonalChange = (hobbies: string[] | null) => {
    let forCheck = hobbies?.length ? hobbies : null
    let initialHobbies = organizationInfo?.hobbies?.length ? organizationInfo.hobbies : null
    setPersonalValue({
      hobbies,
    });
    setPersonalValueChanged(forCheck !== initialHobbies)
  };

  const handleWorkChange = (
    jobTitle: string | undefined,
    jobRoleId: number | undefined
  ) => {
    setWorkValue({
      jobRoleId,
      jobTitle,
    });
    if (jobTitle !== organizationInfo?.job_title || jobRoleId !== organizationInfo?.job_role_id) {
      setWorkValueChanged(true);
      return;
    }
    setWorkValueChanged(false);
  };

  const requiredFieldsCheck = () => {
    if (orgName?.trim().length < 4 || orgName?.trim().length > 12) {
      setTeamRequiredChecksPassed(false);
    } else {
      setTeamRequiredChecksPassed(true);
    }
  };

  useEffect(() => {
    setOrgName(organizationInfo?.organization_name!);
    setPurposeId(organizationInfo?.team_type_id!);
    setProfilePic(organizationInfo?.organization_logo!);
    requiredFieldsCheck();
  }, [organizationInfo]);

  const enableButton = () => {
    return Object.values({ orgNameChanged, schoolValueChanged, workValueChanged, personalValueChanged }).includes(true);
  }

  return (
    <>
      <h4>{lblCms?.lbl_about_your_team}</h4>
      <p>{lblCms?.lbl_about_you_description}</p>
      <div className="editProfile">
        <UploadAvatar
          uploadCtaLbl={lblCms?.lbl_upload_or_update_logo} // profile settings
          onChange={avatarChangeHandler}
          error_msgs={lblCms?.lbl_error_responses}
          oldProfilePicturePublicPath={profilePic}
        />
        <div className="editableFields">
          <div className="fldWrap">
            <Input
              autoFocus={true}
              className="teamName"
              onChange={teamNameChangeHandler}
              onBlur={(e) => setOrgName(e.target.value?.trim())}
              minLength={4}
              maxLength={50}
              value={orgName}
              onKeyUp={debounce(() => requiredFieldsCheck())}
              status={!teamRequiredChecksPassed ? "error" : ""}
            />
            <label htmlFor="email">{lblCms?.lbl_team_name}</label>
          </div>
          <div style={{ display: purposeId === 1 ? "block" : "none" }}>
            <WorkPurposeComponent
              lblCms={lblCms}
              defaultJobId={workValue?.jobRoleId!}
              defaultJobTitle={workValue.jobTitle!}
              onChange={handleWorkChange}
            />
          </div>
          <div style={{ display: purposeId === 2 ? "block" : "none" }}>
            <PersonalPurposeComponent
              lblCms={lblCms}
              defaultHobbies={personalValue?.hobbies}
              onChange={handlePersonalChange}
            />
          </div>
          <div style={{ display: purposeId === 3 ? "block" : "none" }}>
            <SchoolPurposeComponent
              lblCms={lblCms}
              defaultEducationLevel={schoolValue?.educationLevel!}
              defaultEducationMajorId={schoolValue?.educationMajorId}
              onChange={handleSchoolValueChange}
            />
          </div>
          <div className="btnWrapLeft">
            <button
              onClick={handleSaveButton}
              className={classNames("bothStateCmnStyling", {
                disabledBtn: !teamRequiredChecksPassed || !enableButton(),
                btnStyle3: teamRequiredChecksPassed && enableButton(),
              })}
              aria-label="save changes"
              disabled={!teamRequiredChecksPassed || !enableButton()}
            >
              {" "}
              {lblCms?.lbl_save_changes}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetailSettings;
