import { Dropdown, MenuProps, message, Space } from 'antd';
import { FC } from 'react';
import BlockIcon from 'assets/e-images/block-icon.svg';
import TodoIcon from 'assets/e-images/todo-icon.svg';
import DoneIcon from 'assets/e-images/done-icon.svg';
import { ITaskStatusFilter, useFeedbackStore } from 'stores/feedback.store';
import feedbackService from 'services/pncService/feedback.service';
import { useProjectDashboardStore } from 'stores';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';

const FeedbackStatus: FC<{
  feedBackStatusValue: string;
  detailsId: number;
  setFeedbackData: any;
  feedbackData: any;
  feedbacId: number;
}> = ({
  feedBackStatusValue,
  detailsId,
  feedbackData,
  setFeedbackData,
  feedbacId,
}) => {
  const { updateFeedbackStatus, taskStatusFilters } = useFeedbackStore(
    (state: any) => state,
  );
  const { projectDetails } = useProjectDashboardStore();
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();

  const getStatusIcon = (statusId: number) => {
    switch (statusId) {
      case 1:
        return TodoIcon;
      case 3:
        return DoneIcon;
      case 4:
        return BlockIcon;
      default:
        return TodoIcon;
    }
  };

  const statusItems: MenuProps['items'] = taskStatusFilters
    ?.filter(
      (i: ITaskStatusFilter) =>
        [1, 3, 4].includes(i.task_status_id) &&
        i.task_status_id !== Number(feedBackStatusValue),
    )
    ?.map((i: ITaskStatusFilter) => ({
      label: (
        <div className="centerImgText">
          <img src={getStatusIcon(i.task_status_id)} alt=""></img>
          <div>{i.name}</div>
        </div>
      ),
      key: `${i.task_status_id}`,
    }));

  const handleStatusChange: MenuProps['onClick'] = async ({ key }) => {
    try {
      setFeedbackData(() => [
        { ...feedbackData[0], feedback_status_id: Number(key) },
      ]);
      const payload = {
        organizationId: projectDetails?.organization_id,
        projectId: projectDetails?.project_id,
        feedbackStatusId: Number(key),
        detailsId: detailsId,
      };
      const res = await feedbackService.updateStatusFeedBack(payload);
      if (res.messageId === 1) {
        updateFeedbackStatus(Number(key), feedbacId, detailsId);
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_change_fb_status,
        );
        setFeedbackData(() => [
          {
            ...feedbackData[0],
            feedback_status_id: Number(feedBackStatusValue),
          },
        ]);
        console.log(res);
      }
    } catch (error) {
      console.log('Resolve Failed', error);
      setFeedbackData(() => [
        { ...feedbackData[0], feedback_status_id: Number(feedBackStatusValue) },
      ]);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_change_fb_status,
      );
    }
  };

  return (
    <Dropdown
      className="statusDropdown"
      menu={{
        items: statusItems,
        onClick: handleStatusChange,
      }}
      trigger={['click']}
      disabled={feedbackData.length === 0}
    >
      <Space>
        <div className="centerImgText">
          <img
            src={getStatusIcon(
              taskStatusFilters?.filter(
                (i: ITaskStatusFilter) =>
                  i.task_status_id === Number(feedBackStatusValue),
              )[0]?.task_status_id,
            )}
            alt=""
          ></img>
          <div>
            {taskStatusFilters?.filter(
              (i: ITaskStatusFilter) =>
                i.task_status_id === Number(feedBackStatusValue),
            )[0]?.name || 'To Do'}
          </div>
        </div>
      </Space>
    </Dropdown>
  );
};

export default FeedbackStatus;
