import { FC } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import styled from "styled-components";
import { IColumn, ITask } from "../Data";
import Drag from "./Drag";
import Drop from "./Drop";
import CalendarTaskCard from "../CalendarTaskCard";


interface IDDColumn {
  className?: string;
  column: IColumn;
  tasks: ITask[];
  provided?: DraggableProvided;
}

const DDColumn: FC<IDDColumn> = ({ column, tasks, provided }) => {
  return (
    <div className="calendarColumnWrap">
      <div {...provided?.dragHandleProps} className="calendarHeader">{column.title}</div>
      <Drop droppableId={column.id} type="TASK" className="task-drop">
        {tasks.map((task, index) => (
          <Drag draggableId={task.id} index={index} key={task.id}>
            <CalendarTaskCard taskId={task.id}/>
          </Drag>
        ))}
      </Drop>
    </div>
  );
};

export default DDColumn;
