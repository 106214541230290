import React from 'react'

const CompactView = () => {
  return (
    <div className="gridList2DOuterWrap gridCompactView">
      <div className="gridList2DWrap">
        <table>
          <thead>
            <tr>
              <th>
                <div className="gridHeading">
                  <span>Tasks</span>
                  <span className="verticleTxt">Stages</span>
                </div>
              </th>
              <th>
                <ul>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <input type="text" defaultValue="Navigation" />
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Projects</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Task List</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Grid List</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Memopad</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Calendar</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Dashboard</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Settings</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Templates</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Navigation</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridStage">
                      <span className="borderColor">border</span>
                      <span className="blkTxt">Projects</span>
                      <div className="blkMore">More</div>
                    </div>
                  </li>
                </ul>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="gridTask">
                  <span className="borderColor">border</span>
                  <input type="text" defaultValue="Requirements" />
                  <div className="blkMsg haveMsg">
                    Message <em>unread</em>
                  </div>
                  <div className="blkMore">More</div>
                </div>
              </td>
              <td>
                <ul>
                  <li>
                    <div className="gridBlk">
                      <div className="unreadMsgIndicator">unread</div>
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridOverdue">
                    <div className="unreadMsgIndicator">unread</div>
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridBlocked">
                      <div className="unreadMsgIndicator">unread</div>
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridDone">
                      <div className="unreadMsgIndicator">unread</div>
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridNewBlk">
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <div className="gridTask">
                  <span className="borderColor">border</span>
                  <input type="text" defaultValue="Requirements" />
                  <div className="blkMsg haveMsg">
                    Message <em>unread</em>
                  </div>
                  <div className="blkMore">More</div>
                </div>
              </td>
              <td>
                <ul>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridOverdue">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridBlocked">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridDone">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridNewBlk">
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <div className="gridTask">
                  <span className="borderColor">border</span>
                  <input type="text" defaultValue="Requirements" />
                  <div className="blkMsg haveMsg">
                    Message <em>unread</em>
                  </div>
                  <div className="blkMore">More</div>
                </div>
              </td>
              <td>
                <ul>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridOverdue">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridBlocked">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridDone">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk gridNewBlk">
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                  <li>
                    <div className="gridBlk">
                      <div className="blkStatus">Status</div>
                      <div className="gridMore">More</div>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="addTask addPlusSolid">add task</div>
      <div className="addStage addPlusSolid">add task</div>
    </div>
  )
}

export default CompactView