import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { Controlled as ControlledEditor } from 'react-codemirror2';
import copyIcon from '../../../../assets/images/copy-outline-icon.svg';
import copiedIcon from '../../../../assets/e-images/done-icon.svg';
import downArrowGrad from '../../../../assets/images/down-arrow-grad.svg';
import chrome from '../../../../assets/images/chrome.png';
import firefox from '../../../../assets/images/firefox.png';
import edge from '../../../../assets/images/microsoft.png';
import { IEnvDetails, IModalState } from 'models/interface';
import { useProjectDashboardStore } from 'stores';
import feedbackService from 'services/pncService/feedback.service';
import { IEnvFilterData, useFeedbackStore } from 'stores/feedback.store';
import { useModalStore } from 'stores/modal.store';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import { message, Popconfirm, Skeleton, Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import Accordion from '../FeedbackBoard/Accordion';

interface FormData {
  environmentName: string;
  url: string;
}

const EditEnvModal: React.FC<{
  editEnvKey: number;
  setEditEnvKey: Dispatch<SetStateAction<number | null>>;
}> = ({ editEnvKey, setEditEnvKey }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setError,
    clearErrors,
  } = useForm<FormData>({ mode: 'onChange' });
  const { projectDetails } = useProjectDashboardStore();
  const { envFilterData, setEnvFilterData } = useFeedbackStore(
    (state: any) => state,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isAPICalling, setIsAPICalling] = useState(false);
  const [envData, setEnvData] = useState<IEnvDetails | null>(null);
  const [script, setScript] = useState<string>();
  const [spin, setSpin] = useState(false);
  const [generate, setGenerate] = useState(false);
  const [selectedBrowser, setSelectedBrowser] = useState<number>(1);
  const environmentName = watch('environmentName');
  const url = watch('url');
  const isButtonDisabled =
    isLoading ||
    isAPICalling ||
    (!environmentName && !url) ||
    !!errors.environmentName ||
    !!errors.url ||
    !!errors.root ||
    (url === envData?.url && environmentName === envData?.environment_name);
  const [copied, setCopied] = useState(false);
  const { modalState, setModalState } = useModalStore(
    (state: IModalState) => state,
  );
  const { setEnvValue } = useFeedbackStore((state: any) => state);
  const { pncAddEnvSpecificCmsData } = usePncCmsDataStore();

  const fetchEnvDetails = useCallback(async () => {
    let payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      environmentId: editEnvKey,
    };
    try {
      const response = await feedbackService.getEnvById(payload);
      if (response.messageId === 1) {
        setEnvData(response.data[0]);
        setScript(response.data[0]?.script);
        reset();
      } else {
        console.log(response);
        message.error(
          pncAddEnvSpecificCmsData?.toaster_msgs?.error_get_env_by_id,
        );
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncAddEnvSpecificCmsData?.toaster_msgs?.error_get_env_by_id,
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    editEnvKey,
    projectDetails?.organization_id,
    projectDetails?.project_id,
    reset,
  ]);

  const handleRegenarate = async () => {
    setSpin(true);
    let payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      environmentId: editEnvKey,
    };
    try {
      const response = await feedbackService.regenerateScript(payload);
      if (response.messageId === 1) {
        setSpin(false);
        setScript(response.data?.script);
        setGenerate(true);
        setTimeout(() => {
          setGenerate(false);
        }, 2000);
        reset();
      } else {
        setSpin(false);
        message.error(
          pncAddEnvSpecificCmsData?.toaster_msgs?.error_regenerate_script,
        );
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncAddEnvSpecificCmsData?.toaster_msgs?.error_regenerate_script,
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEnvDetails();
  }, [fetchEnvDetails]);

  const onSubmit = async (data: FormData) => {
    setIsAPICalling(true);
    const payload: {
      organizationId: number | string | undefined;
      projectId: number | undefined;
      environmentId: number;
      url: string;
      environmentName: string;
      updatedField: 'name' | 'url' | 'both' | null;
    } = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      environmentId: editEnvKey,
      url: data.url,
      environmentName: data.environmentName,
      updatedField: null,
    };
    if (
      data.environmentName !== envData?.environment_name &&
      data.url === envData?.url
    ) {
      payload.updatedField = 'name';
      console.log(payload);
    } else if (
      data.environmentName === envData?.environment_name &&
      data.url !== envData?.url
    ) {
      payload.updatedField = 'url';
      console.log(payload);
    } else {
      payload.updatedField = 'both';
      console.log(payload);
    }

    try {
      const response = await feedbackService.editEnvDetails(payload);
      if (response.messageId === 1) {
        const updatedFilterData = envFilterData.map((i: IEnvFilterData) => {
          if (i.environment_id === editEnvKey) {
            return {
              ...i,
              environment_name: data.environmentName,
              url: data.url,
            };
          } else return { ...i };
        });
        setEnvFilterData(updatedFilterData);
        setEditEnvKey(null);
        setEnvData(null);
        message.success(
          pncAddEnvSpecificCmsData?.toaster_msgs?.success_edit_env_details,
        );
      } else if (response.messageId === -4) {
        setError('root', {
          type: 'custom',
          message: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_root_unique}`,
        });
      } else {
        console.log(response);
        message.error(
          pncAddEnvSpecificCmsData?.toaster_msgs?.error_edit_env_details,
        );
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncAddEnvSpecificCmsData?.toaster_msgs?.error_edit_env_details,
      );
    } finally {
      setIsAPICalling(false);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(envData?.script || '').then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };

  const handleDeleteModalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const handleDeleteEnvClick = () => {
    setEditEnvKey(null);
    setEnvData(null);
    setModalState({
      modalHeader: (
        <>
          <h3>{`${pncAddEnvSpecificCmsData?.del_env_modal_heading_pre} ${envData?.environment_name} ${pncAddEnvSpecificCmsData?.del_env_modal_heading_post}`}</h3>
          <span
            className="material-icons-outlined modal-close"
            onClick={() => handleDeleteModalClose()}
          >
            close
          </span>
        </>
      ),
      modalBody: (
        <>
          <p>
            <b>{envData?.url}</b>
          </p>
          <p>{`${pncAddEnvSpecificCmsData?.del_env_body_text}`}</p>
        </>
      ),
      modalFooter: (
        <>
          <button
            className="noStyle cancel_deleteFolder"
            type="button"
            onClick={() => handleDeleteModalClose()}
          >
            {pncAddEnvSpecificCmsData?.btn_cta_cancel}
          </button>

          <button
            className="deleteBtnStyle"
            type="button"
            onClick={() =>
              (async () => {
                await handleDeleteEnv();
              })()
            }
          >
            {pncAddEnvSpecificCmsData?.btn_cta_remove_env}
          </button>
        </>
      ),
      shouldShow: true,
    });
  };

  const handleDeleteEnv = async () => {
    handleDeleteModalClose();
    setIsAPICalling(true);
    try {
      const payload = {
        organizationId: projectDetails?.organization_id,
        projectId: projectDetails?.project_id,
        environmentId: editEnvKey,
      };
      const response = await feedbackService.deleteEnv(payload);
      if (response.messageId === 1) {
        const updatedFilterData = envFilterData?.filter(
          (i: IEnvFilterData) => i.environment_id !== editEnvKey,
        );
        setEnvFilterData(updatedFilterData);
        setEnvValue(updatedFilterData[0]?.environment_id);
        handleDeleteModalClose();
        setEditEnvKey(null);
        message.success(
          pncAddEnvSpecificCmsData?.toaster_msgs?.success_del_env,
        );
      } else {
        console.log(response);
        message.error(pncAddEnvSpecificCmsData?.toaster_msgs?.error_del_env);
      }
    } catch (error) {
      console.log(error);
      message.error(pncAddEnvSpecificCmsData?.toaster_msgs?.error_del_env);
    } finally {
      setIsAPICalling(false);
    }
  };

  const handleFormStateChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (errors.root && event.currentTarget.value.trim() !== '') {
      clearErrors('root');
    }
  };
  const getBrowserExtensionName = () => {
    switch (selectedBrowser) {
      case 1:
        return 'ChromeExtension';
      case 2:
        return 'FirefoxExtension';
      case 3:
        return 'EdgeExtension';
      default:
        return null;
    }
  };

  return (
    <div className="deleteFolderModal modal show-modal editEnvModal">
      <div className="modal-content">
        <div className="modal-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-header">
              <h3>{pncAddEnvSpecificCmsData?.edit_env_modal_heading}</h3>
              <span
                className="material-icons-outlined modal-close"
                onClick={() => {
                  setEditEnvKey(null);
                  setEnvData(null);
                }}
              >
                close
              </span>
            </div>
            {isLoading ? (
              <>
                <div className="modal-body">
                  <Skeleton.Input size="large" block={true} active />
                  <br />
                  <br />
                  <Skeleton.Input size="large" block={true} active />
                  <br />
                  <br />
                  <Skeleton active paragraph={{ rows: 5 }} />
                </div>
                <div className="modal-footer">
                  <Skeleton.Button size="large" block={true} active />
                </div>
              </>
            ) : (
              <>
                <div className="modal-body">
                  <div className="loginBlk">
                    <div className="fldWrap">
                      <label>
                        {pncAddEnvSpecificCmsData?.lbl_input_env_name}
                      </label>
                      <input
                        type="text"
                        className={`userEmail noIcon ${
                          errors.environmentName ? 'fldError' : ''
                        }`}
                        placeholder={`${pncAddEnvSpecificCmsData?.lbl_input_env_name}`}
                        maxLength={50}
                        defaultValue={envData?.environment_name}
                        {...register('environmentName', {
                          required: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_name_required}`,
                          validate: (value) =>
                            !!value.trim() ||
                            `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_name_required}`,
                          pattern: {
                            value: /^[A-Za-z0-9@_/-]+$/,
                            message: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_name_validation}`,
                          },
                          onChange: (
                            event: React.FormEvent<HTMLInputElement>,
                          ) => handleFormStateChange(event),
                        })}
                      />
                      {errors.environmentName && (
                        <span className="fldMsg">
                          {errors.environmentName.message}
                        </span>
                      )}
                    </div>
                    <div className="fldWrap fldUrl">
                      <span className="material-icons-outlined">language</span>
                      <label>
                        {pncAddEnvSpecificCmsData?.lbl_input_env_url}
                      </label>
                      <input
                        type="text"
                        className={`userEmail ${errors.url ? 'fldError' : ''}`}
                        placeholder="https://www.cleversort.com"
                        defaultValue={envData?.url}
                        {...register('url', {
                          required: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_url_validation}`,
                          pattern: {
                            value: /^(http|https):\/\/[^ "]+(\.[a-zA-Z]+)$/,
                            message: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_url_validation}`,
                          },
                          onChange: (
                            event: React.FormEvent<HTMLInputElement>,
                          ) => handleFormStateChange(event),
                        })}
                      />
                      {watch('url') && url !== envData?.url && (
                        <span
                          className="material-icons-outlined right cursorPointer"
                          onClick={() => reset({ url: '' })}
                        >
                          cancel
                        </span>
                      )}
                      {errors.url && (
                        <span className="fldMsg">{errors.url.message}</span>
                      )}
                      {errors.root && (
                        <div className="fldError">{errors.root?.message}</div>
                      )}
                    </div>
                    <Accordion
                      title={
                        <h2>
                          {pncAddEnvSpecificCmsData?.script_implement_title}
                        </h2>
                      }
                      defaultOpen={true}
                    >
                      <div>
                        <div className="headerBlk">
                          {/* <h2>{pncAddEnvSpecificCmsData?.gen_code_heading}</h2> */}
                          <b>
                            {pncAddEnvSpecificCmsData?.gen_code_steps?.lbl_step}{' '}
                            - 1{' '}
                          </b>
                          <p>
                            {
                              pncAddEnvSpecificCmsData?.gen_code_steps
                                ?.step_1_pre
                            }{' '}
                            <span className="htmlTag">&lt;body&gt;</span>{' '}
                            {
                              pncAddEnvSpecificCmsData?.gen_code_steps
                                ?.step_1_post
                            }
                          </p>
                        </div>
                        <div className="fldWrap">
                          <div className="codeAccordion">
                            <div className="accordionHead">
                              <h3>HTML</h3>
                              <Popconfirm
                                title={
                                  <span>
                                    {`${pncAddEnvSpecificCmsData?.regenerate_confirm_text_pre}`}
                                    <br /> <b>{`${envData?.url}`}</b>{' '}
                                    {`${pncAddEnvSpecificCmsData?.regenerate_confirm_text_post}`}
                                  </span>
                                }
                                okButtonProps={{ loading: false }}
                                onConfirm={handleRegenarate}
                              >
                                <Tooltip
                                  title={
                                    pncAddEnvSpecificCmsData?.lbl_tooltips
                                      ?.lbl_regen_code
                                  }
                                  color={'#2E364C'}
                                  placement="bottom"
                                >
                                  {generate ? (
                                    <span className="pncRegenerateIcon">
                                      <img src={copiedIcon} alt="" />
                                    </span>
                                  ) : (
                                    <SyncOutlined
                                      spin={spin}
                                      className="pncRegenerateIcon"
                                    />
                                  )}
                                </Tooltip>
                              </Popconfirm>
                              <button
                                type="button"
                                className="copyCode"
                                onClick={handleCopyClick}
                              >
                                {copied ? (
                                  <span>
                                    <img src={copiedIcon} alt="" />
                                  </span>
                                ) : (
                                  <span>
                                    <img src={copyIcon} alt="" />
                                  </span>
                                )}
                              </button>
                            </div>
                            <div className="accordionContent">
                              <ControlledEditor
                                value={script || ''}
                                onBeforeChange={() => {}}
                                options={{
                                  lineNumbers: false,
                                  mode: 'javascript',
                                  theme: 'default',
                                  readOnly: true,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <img src={downArrowGrad} alt="" className="stepArrow" />
                        <div>
                          <div className="headerBlk downloadExtension">
                            <b>
                              {
                                pncAddEnvSpecificCmsData?.gen_code_steps
                                  ?.lbl_step
                              }{' '}
                              - 2{' '}
                            </b>
                            <p>
                              {pncAddEnvSpecificCmsData?.gen_code_steps?.step_2}
                            </p>
                            <ul className="browsersList">
                              <li>
                                <input
                                  type="radio"
                                  id="pncChromeExtension"
                                  name="browserType"
                                  onClick={() => setSelectedBrowser(1)}
                                />
                                <label htmlFor="pncChromeExtension">
                                  <img src={chrome} alt="" />
                                </label>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="pncEdgeExtension"
                                  name="browserType"
                                  onClick={() => setSelectedBrowser(2)}
                                />
                                <label htmlFor="pncEdgeExtension">
                                  <img src={firefox} alt="" />
                                </label>
                              </li>
                              <li>
                                <input
                                  type="radio"
                                  id="pncFirefoxExtension"
                                  name="browserType"
                                  onClick={() => setSelectedBrowser(3)}
                                />
                                <label htmlFor="pncFirefoxExtension">
                                  <img src={edge} alt="" />
                                </label>
                              </li>
                            </ul>
                            <a
                              href={`../../../../../../BrowserExtensions/${getBrowserExtensionName()}.zip`}
                              download
                            >
                              <button type="button" className="btnStyle1">
                                {pncAddEnvSpecificCmsData?.btn_cta_dwnld_ext}
                              </button>
                            </a>
                          </div>
                          <img
                            src={downArrowGrad}
                            alt=""
                            className="stepArrow"
                          />
                          <div className="headerBlk">
                            <b>
                              {
                                pncAddEnvSpecificCmsData?.gen_code_steps
                                  ?.lbl_step
                              }{' '}
                              - 3{' '}
                            </b>
                            <p>
                              {pncAddEnvSpecificCmsData?.gen_code_steps?.step_3}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Accordion>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="btnSec">
                    <button
                      type="button"
                      className="btnStyle5 buttonLoader"
                      disabled={isAPICalling || isLoading}
                      onClick={handleDeleteEnvClick}
                    >
                      {' '}
                      <span className="cmnIcon deleteBin"></span>
                      {pncAddEnvSpecificCmsData?.btn_cta_del_env}
                    </button>
                    <button
                      type="button"
                      className="noStyle buttonLoader"
                      onClick={() => {
                        setEditEnvKey(null);
                        setEnvData(null);
                      }}
                    >
                      {pncAddEnvSpecificCmsData?.btn_cta_cancel}
                    </button>
                    <button
                      type="submit"
                      className="btnStyle1 buttonLoader"
                      disabled={isButtonDisabled}
                    >
                      {pncAddEnvSpecificCmsData?.btn_cta_save_changes}
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditEnvModal;
