import React from "react";
import Task from "./Task";
import { IProjectTemplate, ITaskList } from "models/interface";

enum TaskColumnSortIds {
  TASK_STATUS = "task_status_id",
  TASK_PRIORITY = "task_priority_id",
  TASK_DUE_DATE = "due_date",
  TASK_NAME = "task_name",
  TASK_ASSIGNEE = "assignee_name",
  TASK_UPDATED_ON = "updated_on",
}

enum TaskColumnSortOrder {
  ASC = "asc",
  DESC = "desc",
}

const TaskListDetails: React.FC<{
  taskDetails?: ITaskList;
  sectionToggleState: "collapsed" | "expanded";
  sectionId: string | number;
  templateDetails: IProjectTemplate;
}> = ({ taskDetails, sectionToggleState, sectionId, templateDetails }) => {
  const getTaskTableBodyJsx = () => {
    if (taskDetails?.task_list_details?.length) {
      let _taskDetails = taskDetails.task_list_details;
      if (_taskDetails?.length) {
        return _taskDetails.map((eachTask, index) => (
          <Task
            key={eachTask.task_id}
            eachTaskDetail={eachTask}
            sectionId={sectionId}
            templateDetails={templateDetails}
            index={index}
          />
        ));
      }
    }
  };

  return (
    <div
      className={`taskTable ${
        sectionToggleState === "collapsed" ? "toggle-taskTable" : ""
      }`}
    >
      <table>
        <thead>
          <tr>
            <th>
              <div className="th-div">Status </div>
            </th>
            <th>
              <div className="th-div">
                Task <span className={`cmnIcon arrow-dropdown`} />
              </div>
            </th>
            <th>
              <div className="th-div">Due Date </div>
            </th>
            <th>
              <div className="th-div">Assignee </div>
            </th>

            {/* <th></th> */}
          </tr>
        </thead>
        <tbody>{getTaskTableBodyJsx()}</tbody>
      </table>
    </div>
  );
};

export default TaskListDetails;
