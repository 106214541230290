import React, { useState } from 'react'
import OptionContainer from './OptionContainer/OptionContainer'

const StatusField: React.FC<{ defaultMeta: Object, isInEditMode: boolean, addEditField: Function }> = ({ defaultMeta, isInEditMode, addEditField }) => {
    const [payload, setPayload] = useState<any>(defaultMeta)
    const [options, setOptions] = useState<[Object]>(payload.options.map((op: any) => ({
        ...op,
        id: Math.random().toString()
    })))

    const handleInputChange = (key: "name" | "description", value: string) => {
        if (payload) {
            const temp = { ...payload }

            temp[key] = value

            setPayload(temp)
        }
    }

    const submitData = () => {
        payload.options = options.map((op: any) => ({
            color: op.color,
            value: op.value
        })).filter((op: any) => op?.value?.trim().length > 0)
        
        addEditField(payload)
    }

    return (
        <div>
            <div>
                <label>Name</label>
                <input type={'text'} value={payload?.name} onChange={(e) => handleInputChange('name', e.target.value)} />
            </div>
            <div>
                <label>Description</label>
                <input type={'text'} value={payload?.description} onChange={e => handleInputChange('description', e.target.value)} />
            </div>
            <div>
                <label>Options</label>
                <OptionContainer options={options} setOptions={setOptions} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                <button className='btnStyle1' onClick={submitData}>{isInEditMode ? 'Update' : 'Create'} Field</button>
            </div>
        </div>
    )
}

export default StatusField