import React from "react";
import { Link } from "react-router-dom";

const AccessDenied: React.FC<{ isAccessFromPage?: boolean }> = ({
  isAccessFromPage,
}) => {
  return (
    <div className="errorContent">
      <div className="errorContentInner">
        <span className="error-img"></span>
        <div>
          <h3>Access Denied.</h3>
          {isAccessFromPage && (
            <React.Fragment>
              <p>You do not have the permission to access this page</p>
              <div style={{ textAlign: "center" }}>
                <Link to={"/"}>
                  <button type="button" className="ant-btn ant-btn upload">
                    <i className="da icon-home"></i>
                    <span>Go to dashboard</span>
                  </button>
                </Link>
              </div>
            </React.Fragment>
          )}
          {!isAccessFromPage && (
            <p>You do not have the permission to access this content</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;
