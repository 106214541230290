import { Input, InputRef } from "antd";
import {
  IProfilePayload,
  IUserOnboardingAttributes,
  IUserState,
} from "models/interface";
import { useEffect, useRef } from "react";
import { useUserStore } from "stores";
import { debounce } from "utils/commonFunctions";

interface ISchoolProfile {
  stepNumber: number;
  pageCmsData: IUserOnboardingAttributes | null;
  profileDetails: IProfilePayload;
  setProfileDetails: React.Dispatch<React.SetStateAction<IProfilePayload>>;
  profileRequiredChecksPassed?: boolean;
  setProfileRequiredChecksPassed: React.Dispatch<
    React.SetStateAction<boolean | undefined>
  >;
  isCreatingNewTeam: boolean;
}

const SchoolProfile: React.FC<ISchoolProfile> = ({
  stepNumber,
  pageCmsData,
  profileDetails,
  setProfileDetails,
  profileRequiredChecksPassed,
  setProfileRequiredChecksPassed,
  isCreatingNewTeam,
}) => {
  const { userDetails } = useUserStore((state: IUserState) => state);

  const userNameRef = useRef<InputRef | null>(null);
  const educationRef = useRef<InputRef | null>(null);

  const educationLevelChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setProfileDetails((prev) => ({ ...prev, education_level: e.target.value }));
  };

  const requiredFieldsCheck = () => {
    if (
      profileDetails.user_name?.trim()?.length === 0 ||
      !profileDetails.user_name
    ) {
      setProfileRequiredChecksPassed(false);
    } else if (
      profileDetails.user_name?.length < 6 ||
      profileDetails.user_name?.length > 30
    ) {
      setProfileRequiredChecksPassed(false);
    } else {
      setProfileRequiredChecksPassed(true);
    }
  };

  const userNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProfileDetails((prev) => ({ ...prev, user_name: e.target.value }));
  };

  useEffect(() => {
    profileDetails.user_name && requiredFieldsCheck();
  }, []);

  useEffect(() => {
    if(!profileDetails?.user_name){
      userNameRef.current?.focus();
      return;
    }
      educationRef.current?.focus();
  }, [stepNumber]);

  return (
    <div className="completeProfileFieldsDevClass completeProfileFields">
      <div className="row">
        <div className="fldWrap">
          <Input
            ref={userNameRef}
            className="profileUserName"
            onChange={userNameChangeHandler}
            minLength={6}
            maxLength={30}
            disabled={isCreatingNewTeam}
            value={profileDetails.user_name}
            status={profileRequiredChecksPassed === false ? "error" : ""}
            onKeyUp={debounce(() => requiredFieldsCheck(),100)}
          />
          <label htmlFor="education level">
            {pageCmsData?.lbl_profile_user_full_name}*
          </label>
          {profileRequiredChecksPassed === false ? (
            <span className="fldError fldMsg">
              {pageCmsData?.lbl_error_responses.username_validation_error_msg}
            </span>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="fldWrap">
          <Input
            ref={educationRef}
            className="teamName"
            onChange={educationLevelChangeHandler}
            maxLength={50}
            value={profileDetails.education_level}
          />
          <label htmlFor="name">
            {pageCmsData?.lbl_profile_education_level}
          </label>
        </div>
      </div>
      <div className="example">
        <p>
          {pageCmsData?.lbl_profile_for_user}
          <span>{userDetails?.email}</span>
        </p>
      </div>
    </div>
  );
};

export default SchoolProfile;
