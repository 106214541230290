export interface ITask {
    id: string;
    content: string;
  }
  
  export interface IColumn {
    id: string;
    title: string;
    taskIds: string[];
  }
  
  export interface ITasks {
    [key: string]: ITask;
  }
  
  export interface IColumns {
    [key: string]: IColumn;
  }
  
  export interface IData {
    tasks: ITasks;
    columns: IColumns;
    columnOrder: string[];
  }
  
  export const initialData: IData = {
    tasks: {
      "task-1": { id: "task-1", content: "aaaaaa" },
      "task-2": { id: "task-2", content: "bbbbbb" },
      "task-3": { id: "task-3", content: "cccccc" },
      "task-4": { id: "task-4", content: "dddddd" },
      "task-5": { id: "task-5", content: "dddddd" },
      "task-6": { id: "task-6", content: "dddddd" },
      "task-7": { id: "task-7", content: "dddddd" },
    },
    columns: {
      "column-1": {
        id: "column-1",
        title: "MON",
        taskIds: ["task-2", "task-1", "task-4"],
      },
      "column-2": {
        id: "column-2",
        title: "TUE",
        taskIds: ["task-3"],
      },
      "column-3": {
        id: "column-3",
        title: "WEd",
        taskIds: [],
      },
      "column-4": {
        id: "column-4",
        title: "thu",
        taskIds: [],
      },
      "column-5": {
        id: "column-5",
        title: "fri",
        taskIds: [],
      },
      "column-6": {
        id: "column-6",
        title: "sat",
        taskIds: [],
      },
      "column-7": {
        id: "column-7",
        title: "sun",
        taskIds: [],
      },
    },
    columnOrder: ["column-1", "column-2", "column-3","column-4","column-5","column-6","column-7"]
  };
  