import { FC, Fragment, useCallback, useState } from "react";
import {
  IGridList,
  IGridListStageDetail,
  IProjectState,
} from "models/interface";
import { useProjectDashboardStore } from "stores";
import { Tooltip } from "antd";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { Rbac, useRbac } from "auth/rbac/rbac";
import { SectionTypeMessages } from "models/enums/messages";
// import debounce from "lodash.debounce";
import StageDelete from "./StageDelete";
import ExpandedMessagePopup from "components/sharedComponents/ExpandedMessagePopup/ExpandedMessageDrawer";
import MessageThread from "../../../MessageThread";
import { createPortal } from "react-dom";
import { debounce } from "utils/commonFunctions";

const DURATION = 50;

const StageNameInputField: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  stageDetails: IGridListStageDetail;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  updateStage: (stageName: any, stageId: any) => Promise<void>;
  revertChange: (stageId: number, event: string) => void;
  index?: number;
}> = ({
  stageDetails,
  submitChange,
  updateStage,
  revertChange,
  gridListDetails,
  setGridListDetails,
  index,
}) => {
    const { hasPermissions } = useRbac();
    const { gridListCmsData } = useProjectDashboardStore(
      (state: IProjectState) => state
    );
    const { projectDetails } = useProjectDashboardStore(
      (state: IProjectState) => state
    );

    // State
    const [input, setInput] = useState(stageDetails.stage_name);
    const checkIfRevertRequired = (stageDetails: IGridListStageDetail) =>
      stageDetails.backupName === stageDetails.stage_name ||
      stageDetails.stage_name === "";
    const [openMessageExpandedView, setOpenMessageExpandedView] =
      useState<boolean>(false);
    const [expandedViewForMessageIndex, setExpandedViewForMessageIndex] =
      useState<number | undefined>();
    const onKeyUpEventHandler = async (
      e: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (stageDetails?.isNew) {
        if (e.key === "Enter") {
          if (!localStorage.getItem("isStageAdded")) {
            document
              .getElementById(`${stageDetails.stage_id}_input_field`)
              ?.setAttribute("readonly", "true");
            localStorage.setItem(
              "isStageAdded",
              stageDetails.stage_id.toString()
            );

            await submitChange(stageDetails.stage_name, "Enter");
            document
              .getElementById(`${stageDetails.stage_id}_input_field`)
              ?.removeAttribute("readonly");
            localStorage.removeItem("isStageAdded");
            setInput("");
          }
        }

        if (e.key === "Escape") {
          submitChange("", "Escape");
        }
      } else {
        if (e.key === "Enter") {
          if (checkIfRevertRequired(stageDetails)) {
            revertChange(stageDetails.stage_id, "Enter");
          } else {
            document
              .getElementById(`${stageDetails.stage_id}_input_field`)
              ?.blur();
          }
        }

        if (e.key === "Escape") {
          revertChange(stageDetails.stage_id, "Escape");
        }
      }
    };

    const onChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (gridListDetails) {
        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            stage_details: getUpdateStageDetails(
              gridListDetails,
              stageDetails,
              e.target.value
            )!,
          },
        } as IGridList);
      }
    };

    const debounceUpdate = useCallback(debounce(onChangeEventHandler, DURATION), [
      gridListDetails,
    ]);

    const onBlurEventHandler = async (
      e: React.FocusEvent<HTMLInputElement, Element>
    ) => {
      if (stageDetails?.isNew && stageDetails?.stage_name !== "") {
        submitChange(stageDetails.stage_name, "Blur");
      } else if (checkIfRevertRequired(stageDetails)) {
        revertChange(stageDetails.stage_id, "Blur");
      } else {
        updateStage(stageDetails.stage_name, stageDetails.stage_id);
      }
    };

    const getUpdateStageDetails = (
      gridListDetails: IGridList,
      stageDetails: IGridListStageDetail,
      newName: string
    ) => {
      if (gridListDetails.grid_list_details!.stage_details) {
        let stage = [{ ...stageDetails }];

        if (!stage[0]?.backupName) {
          stage[0].backupName = stage[0].stage_name;
        }

        stage[0].stage_name = newName;

        let existingStage =
          gridListDetails.grid_list_details!.stage_details.filter(
            (ele: IGridListStageDetail) => ele.stage_id !== stage[0].stage_id
          );

        return [...existingStage, ...stage].sort(
          (a, b) => a.stage_rank - b.stage_rank
        );
      }
    };

    const makeFieldEditable = (stageDetails: IGridListStageDetail) => {
      if (
        gridListDetails &&
        hasPermissions(
          [ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_EDIT],
          projectDetails?.associated_role_id
        )
      ) {
        let stage = [{ ...stageDetails }];
        stage[0].isEdit = true;
        let existingStage =
          gridListDetails.grid_list_details!.stage_details!.filter(
            (ele: IGridListStageDetail) => ele.stage_id !== stage[0].stage_id
          );

        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            stage_details: [...existingStage, ...stage].sort(
              (a, b) => a.stage_rank - b.stage_rank
            ),
          },
        } as IGridList);
      }
    };

    const getStageNameFieldJsx = (stageDetails: IGridListStageDetail) => {
      if (stageDetails.isNew) {
        return (
          <div className="gridStage">
            <span className="borderColor">border</span>
            <input
              type="text"
              id={`${stageDetails.stage_id}_input_field`}
              placeholder={gridListCmsData?.lbl_add_stage_placeholder}
              value={input}
              aria-label="sign up / login"
              onChange={(e) => {
                setInput(e.target.value);
                // Debounce update parent state
                debounceUpdate(e);
              }}
              maxLength={50}
              onKeyUp={(e) => onKeyUpEventHandler(e)}
              onBlur={(e) => onBlurEventHandler(e)}
              autoFocus={stageDetails?.isNew}
            />
          </div>
        );
      }

      if (stageDetails.isEdit) {
        return (
          <div className="gridStage">
            <span className="borderColor">border</span>
            <input
              type="text"
              id={`${stageDetails.stage_id}_input_field`}
              placeholder={gridListCmsData?.lbl_add_stage_placeholder}
              value={input}
              aria-label="sign up / login"
              onChange={(e) => {
                setInput(e.target.value);
                // Debounce update parent state
                debounceUpdate(e);
              }}
              maxLength={50}
              onKeyUp={(e) => onKeyUpEventHandler(e)}
              onBlur={(e) => onBlurEventHandler(e)}
              autoFocus={stageDetails?.isEdit}
            />
            {!stageDetails?.isNew && (
              <>
                {/* <MessageThread
                componentId="gridStage"
                sectionId={gridListDetails.section_id}
                taskId={stageDetails.stage_id}
                sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                threadName={stageDetails.stage_name}
                sectionName={gridListDetails.section_name!}
                taskDetails={stageDetails}
                setOpenMessageExpandedView={setOpenMessageExpandedView}
                index={index}
                setExpandedViewForMessageIndex={setExpandedViewForMessageIndex}
              /> */}
                <MessageThread
                  componentId="stageListMessaging"
                  isBlockedView
                  sectionId={gridListDetails.section_id}
                  taskId={stageDetails.stage_id}
                  sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                  threadName={stageDetails.stage_name}
                  sectionName={gridListDetails.section_name!}
                  taskDetails={stageDetails}
                />
                {document.getElementById("expandedMessagePopup") &&
                  createPortal(
                    <ExpandedMessagePopup
                      sectionId={gridListDetails.section_id}
                      taskId={stageDetails.stage_id}
                      sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                      threadName={stageDetails.stage_name}
                      sectionName={gridListDetails.section_name!}
                      taskDetails={stageDetails}
                      openMessageExpandedView={openMessageExpandedView}
                      setOpenMessageExpandedView={setOpenMessageExpandedView}
                      isTemplate={false}
                      index={index}
                      expandedViewForMessageIndex={expandedViewForMessageIndex}
                    />,
                    document.getElementById("expandedMessagePopup")!
                  )}
              </>
            )}
            <div className="blkMore">More</div>
          </div>
        );
      }

      return (
        <>
          {!openMessageExpandedView ? (
            <Tooltip
              title={stageDetails.stage_name}
              color={"#2E364C"}
              placement="top"
            >
              <div
                onClick={() =>
                  projectDetails?.is_archived === false &&
                  makeFieldEditable(stageDetails)
                }
                onKeyDown={() => { }}
                className="gridStage"
              >
                <span className="borderColor">border</span>
                <span className="blkTxt">{stageDetails.stage_name}</span>
                {/* !ele?.isNew */}
                {!stageDetails?.isNew && (
                  <MessageThread
                    componentId="stageListMessaging"
                    isBlockedView
                    sectionId={gridListDetails.section_id}
                    taskId={stageDetails.stage_id}
                    sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                    threadName={stageDetails.stage_name}
                    sectionName={gridListDetails.section_name!}
                    taskDetails={stageDetails}
                  />
                )}
                {!stageDetails?.isNew &&
                  projectDetails?.is_archived === false && (
                    <Fragment>
                      {/* <TaskProgressBar taskDetails={ele} /> */}
                      <Rbac
                        allowedPermissions={[
                          ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_DELETE,
                        ]}
                        project_role_id={projectDetails?.associated_role_id}
                      >
                        <StageDelete
                          stageDetails={stageDetails}
                          gridListDetails={gridListDetails}
                          setGridListDetails={setGridListDetails}
                        />
                      </Rbac>
                    </Fragment>
                  )}
              </div>
            </Tooltip>
          ) : (
            <div
              onClick={() =>
                projectDetails?.is_archived === false &&
                makeFieldEditable(stageDetails)
              }
              onKeyDown={() => { }}
              className="gridStage"
            >
              <span className="borderColor">border</span>
              <span className="blkTxt">{stageDetails.stage_name}</span>
              {/* !ele?.isNew */}
              {!stageDetails?.isNew && (
                <MessageThread
                  componentId="stageListMessaging"
                  isBlockedView
                  sectionId={gridListDetails.section_id}
                  taskId={stageDetails.stage_id}
                  sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                  threadName={stageDetails.stage_name}
                  sectionName={gridListDetails.section_name!}
                  taskDetails={stageDetails}
                />
              )}
              {!stageDetails?.isNew && projectDetails?.is_archived === false && (
                <Fragment>
                  {/* <TaskProgressBar taskDetails={ele} /> */}
                  <Rbac
                    allowedPermissions={[
                      ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_DELETE,
                    ]}
                    project_role_id={projectDetails?.associated_role_id}
                  >
                    <StageDelete
                      stageDetails={stageDetails}
                      gridListDetails={gridListDetails}
                      setGridListDetails={setGridListDetails}
                    />
                  </Rbac>
                </Fragment>
              )}
            </div>
          )}
          {document.getElementById("expandedMessagePopup") &&
            createPortal(
              <ExpandedMessagePopup
                sectionId={gridListDetails.section_id}
                taskId={stageDetails.stage_id}
                sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                threadName={stageDetails.stage_name}
                sectionName={gridListDetails.section_name!}
                taskDetails={stageDetails}
                openMessageExpandedView={openMessageExpandedView}
                setOpenMessageExpandedView={setOpenMessageExpandedView}
                isTemplate={false}
                index={index}
                expandedViewForMessageIndex={expandedViewForMessageIndex}
              />,
              document.getElementById("expandedMessagePopup")!
            )}
        </>
      );
    };

    return getStageNameFieldJsx(stageDetails);
  };

export default StageNameInputField;
