import React, { FC } from "react";

const CalendarTaskCard: FC<{taskId: any}> = ({taskId}) => {
  return (
    <div className="taskEventCard" style={{borderLeft:"2px solid #2e364c"}}>
      <div className="taskStatusName">
        <div className="statusIcon">
          <img
            src="https://assets-qa.cleversort.com/sticker_pack/default/sticker-default-overdue.svg"
            alt="Overdue"
          />
        </div>
        <div className="taskAndAssignee">
          <div className="taskName commonTaskAndAssigner">{taskId}</div>
          <div className="assignerName">
            by <span>Ramann Raghav</span>
          </div>
        </div>
      </div>
      <span className="cmnIcon more"></span>
      <div className="workspacePriority">
        <div className="leftElem">
        <div className="userAvtr">
          {/* <img src="https://assets-qa.cleversort.com/organization_logo/oxn-25/1710488107126.jpeg" /> */}
          R
        </div>
        <div className="plannerProjectNameInnerWrapper">
          <a className="projectName" href="">
            New qwerty 22
          </a>
        </div>
        </div>
        <div className="rightElem">
          <span className="cmnIcon deleteBin"></span>
        </div>
      </div>
    </div>
  );
};

export default CalendarTaskCard;
