import { Fragment, useState } from "react";
import TaskListDetails from "./TaskListDetails";
import { Tooltip } from "antd";
import { IProjectTemplate, ITaskList } from "models/interface";

interface ITaskListComponent {
  sectionId: string | number;
  sectionDetails: ITaskList;
  templateDetails: IProjectTemplate;
}

const TaskList: React.FC<ITaskListComponent> = ({
  sectionId,
  sectionDetails,
  templateDetails,
}) => {
  const [sectionToggleState, setSectionToggleState] = useState<
    "expanded" | "collapsed"
  >("expanded");

  const handleSectionToggle = () => {
    setSectionToggleState((prev) =>
      prev === "collapsed" ? "expanded" : "collapsed"
    );
  };

  return (
    <div className="templateSection">
      <div className="taskNameHeadingPane">
        <div className="itemNameBlk">
          <Tooltip
            title="Toggle task view"
            color={"#2E364C"}
            placement="bottom"
          >
            <span
              className={`sidePanelIcon ${
                sectionToggleState === "expanded" ? "arrow-down" : "arrow-right"
              }`}
              color={"#2E364C"}
              onClick={handleSectionToggle}
              onKeyDown={() => {}}
            />
          </Tooltip>

          <span className="section-text-span">
            {sectionDetails.section_name}
          </span>
        </div>
      </div>
      <TaskListDetails
        taskDetails={sectionDetails}
        sectionToggleState={sectionToggleState}
        sectionId={sectionDetails.section_id}
        templateDetails={templateDetails}
      />
    </div>
  );
};

export default TaskList;
