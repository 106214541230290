import { RoutesEnum } from "models/enums/apiRoutes";
import {
  IInvitationApiResponseCommon,
  IInviteUsersResponse,
  IProcessInvitationDto,
  IUserInvitationDto,
} from "models/interface";
import ApiService from "services/Api.service";

class InvitationService {
  public inviteUsers = async (payload: IUserInvitationDto) => {
    return ApiService.post<IInviteUsersResponse>(
      RoutesEnum.INVITE_USERS,
      payload
    );
  };

  public processInvitation = async (payload: IProcessInvitationDto) => {
    return ApiService.post<IInvitationApiResponseCommon>(
      RoutesEnum.PROCESS_INVITATION,
      payload
    );
  };
}

const invitationService = new InvitationService();

export default invitationService;
