import BrowserStorageService from "services/browserStorage.service";
import "../../assets/css/custom-dev.css";
import "../../assets/css/custom-active.css";
import "../../assets/css/2d-grid.css";
import "../../assets/css/custom.css";
import "../../assets/css/custom-field.css";
import "../../assets/css/2d-grid-antd.css";
import React, { Suspense, useEffect, useState } from "react";

const DefaultTheme = React.lazy(() => import("./Default"));
const FrenchTheme = React.lazy(() => import("./France"));
const JapaneseTheme = React.lazy(() => import("./Japan"));
const SingaporeTheme = React.lazy(() => import("./Singapore"));

const ThemeProvider: React.FC<{ children: any }> = ({ children }) => {
  const [themeComponent, setThemeComponent] = useState(<DefaultTheme />);
  const browserStorage = BrowserStorageService.getInstance();

  //load country-specific css
  const countryCode = browserStorage.getCountry();
  useEffect(() => {
    switch (countryCode) {
      case "JP":
        setThemeComponent(<JapaneseTheme />);
        break;
      case "SG":
        setThemeComponent(<SingaporeTheme />);
        break;
      case "FR":
        setThemeComponent(<FrenchTheme />);
        break;
      default:
        break;
    }
  }, [countryCode]);

  return (
    <>
      <Suspense fallback={null}><DefaultTheme />{themeComponent}</Suspense>
      {children}
    </>
  );
};

export default ThemeProvider;
