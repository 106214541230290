import { useEffect } from "react";
import { Tooltip } from "antd";
import { TaskListView } from "models/enums/tasklistView";

interface IAssigneeDetails {
  assignee_id?: number | null;
  assignee_name?: string;
  assignee_email?: string;
  assignee_dp?: string;
}

const TaskAssignee: React.FC<{
  assigneeDetails?: IAssigneeDetails;
  taskAssigneeChangeHandler: (user_id: number | null) => void;
  showAssigneeDropdown: boolean;
  setShowAssigneeDropdown: Function;
  viewType?: TaskListView;
}> = ({
  setShowAssigneeDropdown,
  viewType,
}) => {
  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowAssigneeDropdown(false);
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    return () => {
      window.removeEventListener("keydown", handleEscKeyPress);
    };
  }, []);

  const getAssigneeJsx = () => {
    return (
      <div
        className="unassignUserBlkWrap userBlkWrap"
        onClick={() => setShowAssigneeDropdown((prev: any) => !prev)}
        onKeyDown={() => {}}
      >
        <Tooltip title="Unassigned" color={"#2E364C"} placement="bottom">
          <span className="unassign"></span>
        </Tooltip>
        <div className="userName textMuted">Unassigned</div>
      </div>
    );
  };

  if (viewType === TaskListView.CARD) {
    return <>{getAssigneeJsx()}</>;
  }

  return <td className="assignWrap">{getAssigneeJsx()}</td>;
};

export default TaskAssignee;
