import React, { FC, ReactNode } from "react";
import { Draggable, DraggableProps, DraggableProvided } from "react-beautiful-dnd";

interface IDrag extends Omit<DraggableProps, "children"> {
  className?: string;
  children: ReactNode;
  dragAll?: boolean;
}

const Drag: FC<IDrag> = ({ className = "", children, dragAll = true, ...props }) => {
  if (!React.isValidElement(children)) return <div />;
  return (
    <Draggable {...props}>
      {(provided: DraggableProvided) => {
        const dragHandleProps = dragAll ? provided.dragHandleProps : {};
        return (
          <div
            className={className}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...dragHandleProps}
          >
            {React.cloneElement(children as React.ReactElement<any>, { provided })}
          </div>
        );
      }}
    </Draggable>
  );
};

export default Drag;
