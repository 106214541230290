import axios from "axios";
import { RoutesEnum } from "../../models/enums/apiRoutes";
import {
  IResponseUserDetails,
  IResponseUserLogin,
  IRequestAccountActivation,
  IResponseCommon,
  IResponseUserSocialSignup,
  IAuditPayload,
  IUserProfileUpdate,
  IUploadProfileImage,
  IUserDetailsQueryParams,
  IUserAuthDetails,
  IInvitationStatus,
} from "../../models/interface";
import apiService from "../Api.service";
import {
  INotificationEvent,
} from "../../models/interface/organization.interface";

class UserService {
  public accountActivation = async (payload: IRequestAccountActivation) => {
    const url =
      process.env.REACT_APP_API_URL + RoutesEnum.USER_ACCOUNT_ACTIVATION;
    let request = await axios.post(url, payload);
    return request.data;
  };

  public accountActivationResendEmail = async (
    payload: IRequestAccountActivation
  ) => {
    return apiService.post<IResponseCommon>(
      RoutesEnum.USER_ACCOUNT_ACTIVATION,
      payload
    );
  };

  public userStatus = async (payload: IAuditPayload) => {
    return apiService.post<IResponseUserLogin>(RoutesEnum.USER_STATUS, payload);
  };

  public userDetails = async (
    userDetailsQueryParams: IUserDetailsQueryParams
  ) => {
    return apiService.get<IResponseUserDetails>(
      RoutesEnum.USER_DETAILS,
      userDetailsQueryParams
    );
  };

  public userSocialSignup = async () => {
    return apiService.get<IResponseUserSocialSignup>(
      RoutesEnum.USER_SOCIAL_SIGNUP
    );
  };

  public updateUserProfile = async (payload: IUserProfileUpdate) => {
    return apiService.post(RoutesEnum.USER_PROFILE_UPDATE, payload);
  };

  public uploadUserProfileImage = async (payload: IUploadProfileImage) => {
    return apiService.post<{ data: { url: string } }>(
      RoutesEnum.USER_UPLOAD_IMAGE_PROFILE,
      payload
    );
  };

  public updateUserPassword = async (payload: { password: string }) => {
    return apiService.post<{ messageId: number }>(
      RoutesEnum.USER_UPDATE_PASSWORD,
      payload
    );
  };

  public getUserNotificationPreference = async (payload: {
    organization_id: number;
    user_id: number;
  }) => {
    return apiService.get<{ data: INotificationEvent[] }>(
      RoutesEnum.USER_NOTIFICATION,
      payload
    );
  };

  public updateUserNotificationPreference = async (payload: {
    organization_id: number;
    user_id: number;
    event_id: number;
    is_enable: boolean;
  }) => {
    return apiService.post<{ messageId: number }>(
      RoutesEnum.USER_NOTIFICATION,
      payload
    );
  };

  public getAuthUserDetails = async (payload: { auth_id: string }) => {
    return apiService.get<{ data: IUserAuthDetails }>(
      RoutesEnum.GET_AUTH_USER_DETAILS,
      payload
    );
  };

  public updateUserOrgColor = async (payload: {
    user_id: number;
    organization_id: number;
    org_color: string;
    role_id: number;
  }) => {
    return apiService.post<{ data: {}; messageId: number; message: string }>(
      RoutesEnum.UPDATE_USER_ORG_COLOR,
      payload
    );
  };

  public updateUserProfilePic = async () => {
    return apiService.post<{ data: {}; messageId: number; message: string }>(
      RoutesEnum.UPDATE_USER_PROFILE_PIC
    );
  };
  
  public getInvitationStatus = async (payload: { invitation_id: number }) => {
    return apiService.get<{ data: IInvitationStatus }>(
      RoutesEnum.GET_INVITATION_STATUS,
      payload
    );
  };

    public addUserCalendar = async (payload:{email:string; calendar_color?:string; is_linked?: boolean; access_token: string; refresh_token: string}) =>{
        return apiService.post<{data:{}, messageId:number; message: string;}>(RoutesEnum.ADD_USER_CALENDAR,payload)
    }

    public updateUserCalendarAssoc = async (payload:{email:string; calendar_color?:string; is_linked?: boolean}) =>{
        return apiService.post<{data:{}, messageId:number; message: string;}>(RoutesEnum.UPDATE_USER_CALENDAR_ASSOC,payload)
    }
}

const userService = new UserService();
export default userService;
