import { ITaskListDetails } from "models/interface";

const TaskDueDate: React.FC<{
  eachTaskDetail?: ITaskListDetails;
  sectionId?: number | string;
}> = () => {
  return <td>None</td>;
};

export default TaskDueDate;
