import { FC, Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import { db } from 'utils/firebase';
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import BrowserStorageService from 'services/browserStorage.service';
import { loggerService, messagingService } from 'services';
import dayjs from 'dayjs';
import {
  useMessagingStore,
  useAllThreadsMessageDetailsStore,
  useAllThreadUnreadIndicatorStore,
} from 'stores';
import { SectionTypeMessages } from 'models/enums/messages';

export const DefaultItemViewForProd: FC<{
  pageCmsData: any;
  navExpanded: boolean;
}> = ({ pageCmsData, navExpanded }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const browserStorage = BrowserStorageService.getInstance();
  const selectedThreadId = localStorage.getItem('selectedThreadId') ?? '';
  const { unReadMessageDetails, setUnReadMessageDetails } = useMessagingStore(
    (state: any) => state,
  );
  const { setAllThreadMessageDetails } = useAllThreadsMessageDetailsStore(
    (state: any) => state,
  );
  const messageThreadCollectionID =
    process.env.REACT_APP_MESSAGE_THREAD_ID || 'message-thread';

  const { setAllThreadUnreadIndicator, setUserMessageList } =
    useAllThreadUnreadIndicatorStore((state: any) => state);

  useEffect(() => {
    const q = query(collection(db, messageThreadCollectionID));

    const unSub = onSnapshot(q, () => {
      (async () => {
        await getMessagesByUser('desc');
      })();
    });

    return () => {
      unSub();
    };
  }, [selectedThreadId]);

  const firestoreOptimizedQuery = async (order: 'desc' | 'asc') => {
    try {
      let userDetails = browserStorage.getUserDetails();
      const collectionRef = collection(db, messageThreadCollectionID);
      const res = getDocs(
        query(
          collectionRef,
          where('all_members_id', 'array-contains', userDetails?.user_id),
          orderBy('updated_at', order),
        ),
      );
      return res;
    } catch (err) {
      console.log('FIREBASE ERROR -> ', err);
    }
  };

  const getMessageThreadId = async () => messagingService.getMessagesByUser();

  const getMessagesByUser = async (order: 'desc' | 'asc') => {
    try {
      let messageDetails: any[] = [];
      const msgDetails: any[] = [];

      const responseDb = await getMessageThreadId();
      const newData = await firestoreOptimizedQuery(order);

      if (responseDb.messageId === 1 && responseDb.data.threadIds.length > 0) {
        setAllThreadUnreadIndicator(responseDb.data.allMessageCountDetails);
        setAllThreadMessageDetails(responseDb.data.messageCountDetails);
        let threadsNotToShow = responseDb.data.threadsNotToShow || [];
        newData &&
          newData.forEach((doc) => {
            let projectArchiveState = false;
            if (
              responseDb.data.projectArchiveStatus !== undefined &&
              responseDb.data.projectArchiveStatus[doc.id.split('-')[5]] !==
                undefined
            ) {
              projectArchiveState =
                responseDb.data.projectArchiveStatus[doc.id.split('-')[5]];
            }

            if (
              shouldAddInList(
                doc,
                responseDb.data.threadIds,
                threadsNotToShow,
                projectArchiveState,
              )
            ) {
              let obj;
              let target = responseDb.data.messageCountDetails.find(
                (ele) => ele.message_thread_id === doc.id,
              );

              if (!target) {
                obj = {
                  id: doc.id,
                  count: doc.data().messages.length,
                };
              } else {
                obj = {
                  id: doc.id,
                  count: doc.data().messages.length - target.read_message_count,
                };
              }

              if (obj.count > 0) {
                messageDetails.push(obj);
              }

              const mdObj = {
                threadId: doc.id,
                details: doc.data(),
                readMessageCount: getReadMessageCount(
                  doc.data().messages,
                  responseDb.data.messageCountDetails,
                  doc.id,
                ),
                is_pinned: isMessagePinned(
                  responseDb.data.messageCountDetails,
                  doc.id,
                ),
              };

              msgDetails.push(mdObj);
            }
          });

        messageDetails = messageDetails.filter(
          (ele) => ele.id !== selectedThreadId,
        );

        if (messageDetails.length > 0) {
          setUnReadMessageDetails({
            shouldVisible: true,
            count: messageDetails.reduce(function (acc, curr) {
              return acc + curr.count;
            }, 0),
          });
        } else {
          setUnReadMessageDetails({
            shouldVisible: false,
            count: 0,
          });
        }
      }
      setUserMessageList(msgDetails);
    } catch (error) {
      await loggerService.log({
        severity: 'High',
        errors: error,
        message: 'Failed to get messages',
      });
    }
  };

  const isMessagePinned = (messages: any[], threadId: string) => {
    let item = messages.filter((ele) => ele.message_thread_id === threadId);

    return item.length > 0 ? item[0].is_pinned : false;
  };

  const getReadMessageCount = (
    firebaseMessage: any[],
    messages: any[],
    threadId: string,
  ) => {
    if (selectedThreadId === '' || selectedThreadId !== threadId) {
      let item = messages.filter((ele) => ele.message_thread_id === threadId);
      return item.length > 0 ? item[0].read_message_count : 0;
    }

    return firebaseMessage.length;
  };

  const checkIfMentionedInThread = (messages: any[]) => {
    let taggedUserList: number[] = [];
    let userDetails = browserStorage.getUserDetails();

    for (let iterator of messages) {
      taggedUserList = [...taggedUserList, ...iterator.tagged_user_id];
    }

    return taggedUserList.includes(userDetails?.user_id);
  };

  const checkDifferenceInDays = (date: string) => {
    const date1 = dayjs(date);
    const date2 = dayjs();
    return date2.diff(date1, 'day', true);
  };

  const shouldAddInList = (
    document: QueryDocumentSnapshot<DocumentData, DocumentData>,
    threadIds: string[],
    threadsNotToShow: string[],
    archivedStatus: boolean = false,
  ) => {
    let userDetails = browserStorage.getUserDetails();
    let threadType = document.id
      .split('-')
      .slice(0, 3)
      .join('-') as SectionTypeMessages;
    let userDataFromThread = document
      .data()
      .member_id.find(
        (itm: any) => Number(itm.user_id) === Number(userDetails.user_id),
      );
    let env = document.id.split('-')[3];
    let stage = process.env.REACT_APP_STAGE || '';
    const whetherNeedsToBeIgnored = threadsNotToShow.includes(document.id);
    const presentInThreadList = threadIds.includes(document.id);

    if (
      !userDataFromThread ||
      Boolean(userDataFromThread.removed) ||
      archivedStatus ||
      env !== stage ||
      !presentInThreadList ||
      whetherNeedsToBeIgnored
    ) {
      return false;
    }

    const isThreadTypeGridList = [
      SectionTypeMessages.GRID_LIST_STAGE,
      SectionTypeMessages.GRID_LIST_TASK,
    ].includes(threadType);
    const isDone = document.data().is_done;
    const isUserMember = document
      .data()
      .member_id.some((ele: any) => ele.user_id === userDetails.user_id);
    const isRecent =
      checkDifferenceInDays(document.data().updated_at.toDate()) < 60;

    if (isThreadTypeGridList) {
      return !isDone && isRecent;
    } else {
      return isUserMember && !isDone && isRecent;
    }
  };
  return (
    <ul className="leftMainNav">
      <li
        className={
          location.pathname.split('/')?.lastIndexOf('dashboard') > 0
            ? 'navDashboard active'
            : 'navDashboard'
        }
        title={pageCmsData?.lbl_menu_dashboard}
        onClick={() => {
          document.body.classList.remove('body-overlay');
          navigate(
            `/org/${location.pathname.split('/')[2]}/${
              NavRoutesEnum.DASHBOARD
            }`,
          );
        }}
        onKeyDown={() => {}}
      >
        {pageCmsData?.lbl_menu_dashboard}
      </li>
      <li
        className={
          location.pathname.split('/')?.lastIndexOf('planner') > 0
            ? 'navPlanner active'
            : 'navPlanner'
        }
        title={pageCmsData?.lbl_menu_planner}
        onClick={() => {
          document.body.classList.remove('body-overlay');
          navigate(
            `/org/${location.pathname.split('/')[2]}/${NavRoutesEnum.PLANNER}`,
          );
        }}
        onKeyDown={() => {}}
      >
        {pageCmsData?.lbl_menu_planner}
      </li>
      <li
        className={
          location.pathname.split('/')?.lastIndexOf('messages') > 0
            ? 'navMessage active'
            : 'navMessage'
        }
        title={pageCmsData?.lbl_menu_message}
        onClick={() => {
          document.body.classList.remove('body-overlay');
          navigate(
            `/org/${location.pathname.split('/')[2]}/${NavRoutesEnum.MESSAGES}`,
          );
        }}
        onKeyDown={() => {}}
      >
        <span
          className={`${
            unReadMessageDetails.shouldVisible ? 'unReadMessageIndicator' : ''
          }`}
        ></span>
        {pageCmsData?.lbl_menu_message}
        {unReadMessageDetails.shouldVisible ? (
          <Fragment>{`(${unReadMessageDetails.count})`}</Fragment>
        ) : null}
      </li>
    </ul>
  );
};
