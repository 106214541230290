import { FC, Fragment, useState } from "react";
import EmptyBlock from "./EmptyBlock";
import { IGridListBlockDetail, IGridList } from "models/interface";

const Block: FC<{ sectionDetails: IGridList }> = ({ sectionDetails }) => {

  const getClassNameByFilter = (
    sectionDetails: IGridList,
    blockDetails: IGridListBlockDetail
  ) => {
    if (sectionDetails.show_own_tasks && blockDetails.assignee_id !== null) {
      return true;
    }

    if (
      sectionDetails.hide_completed_tasks &&
      blockDetails.block_status_id === 3
    ) {
      return true;
    }

    if (
      sectionDetails.show_own_tasks === true &&
      sectionDetails.hide_completed_tasks === true &&
      blockDetails.assignee_id !== null &&
      blockDetails.block_status_id === 3
    ) {
      return true;
    }

    return false;
  };

  const isItemToDelete = (blockDetails: IGridListBlockDetail) => {
    if (sectionDetails.grid_list_details?.deleteItemDetails?.type === "task") {
      return (
        sectionDetails.grid_list_details?.deleteItemDetails?.id ===
        blockDetails.task_id
      );
    }

    if (sectionDetails.grid_list_details?.deleteItemDetails?.type === "stage") {
      return (
        sectionDetails.grid_list_details?.deleteItemDetails?.id ===
        blockDetails.stage_id
      );
    }

    return false;
  };

  return (
    <Fragment>
      {sectionDetails.grid_list_details?.block_details?.map((eleOuter: any) => {
        return (
          <ul key={`${eleOuter.task_id}-${eleOuter.stage_id}-${eleOuter.block_id}`} className="subTaskRow">
            {eleOuter.grid_list_details}
            {eleOuter?.map((ele: IGridListBlockDetail) => (
              <li
                className={`${
                  ele.block_id === 0 ? "toggle-z-index" : ""
                } ${ele.block_status_id === 3 ? "doneTaskCard" : ""} ${
                  getClassNameByFilter(sectionDetails, ele)
                    ? "nonHighlightedTaskCard"
                    : ""
                } ${isItemToDelete(ele) ? "deleteBlockHighlight" : ""}`}
                key={ele.block_id}
              >
                <EmptyBlock />
              </li>
            ))}
          </ul>
        );
      })}
    </Fragment>
  );
};

export default Block;
