import React from 'react';
import { IProjectState, IProjectSections } from 'models/interface';
import TaskList from './TaskList/TaskList';
import { useProjectDashboardStore } from 'stores';
import GridList2 from './GridListV4/GridList';
import MemoPad from './MemoPad/MemoPad';

const SectionsWrapper: React.FC<{
  // socket: any;
  refDiv: React.RefObject<HTMLDivElement>;
  setCurrentId?: (val: string) => void;
  currentId?: string;
  sectionRef?: React.RefObject<HTMLDivElement>;
}> = ({ /*socket,*/ refDiv, setCurrentId, currentId, sectionRef }) => {
  let { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const getSectionsJsxByType = (eachSection: IProjectSections) => {
    switch (eachSection.section_type_id) {
      case 1:
        return (
          <TaskList
            key={eachSection.section_id}
            sectionDetails={eachSection}
            // socket={socket}
            projectColor={projectDetails?.project_color}
            refDiv={refDiv}
            currentId={currentId}
            setCurrentId={setCurrentId}
          />
        );
      case 2:
        return (
          <GridList2
            ref={sectionRef}
            key={eachSection.section_id}
            sectionDetails={eachSection}
            // socket={socket}
            projectColor={projectDetails?.project_color}
            refDiv={refDiv}
            currentId={currentId}
            setCurrentId={setCurrentId}
          />
        );
      case 3:
        return (
          <MemoPad
            key={eachSection.section_id}
            currentProjectDetails={eachSection}
            sectionId={eachSection.section_id}
            // socket={socket}
            projectColor={projectDetails?.project_color}
            refDiv={refDiv}
            currentId={currentId}
            setCurrentId={setCurrentId}
          />
        );
    }
  };
  return (
    <React.Fragment>
      {projectDetails?.sections?.map((eachSection) =>
        getSectionsJsxByType(eachSection),
      )}
    </React.Fragment>
  );
};

export default SectionsWrapper;
