import React from 'react';
import Routes from './routes/Routes';
import { Modal } from 'components/sharedComponents';
import BrowserStorageService from 'services/browserStorage.service';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/fr';
import 'dayjs/plugin/weekOfYear';
import { IGlobalState } from 'models/interface';
import { useGlobalStore } from 'stores';
import enUS from 'antd/es/calendar/locale/en_US';
import frFR from 'antd/es/calendar/locale/fr_FR';
import jaJP from 'antd/es/calendar/locale/ja_JP';
import { ConfigProvider } from 'antd';
import Hotjar from '@hotjar/browser';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import { usePnCScript } from 'components/sharedComponents/hooks';

declare global {
  interface Window {
    pnc_key: string;
  }
}

const App: React.FC<{}> = () => {
  const browserStorage = BrowserStorageService.getInstance();
  const language = browserStorage.getLocalization().language;
  const { setAntLocale } = useGlobalStore((state: IGlobalState) => state);
  usePnCScript();
  localStorage.removeItem('selectedThreadId');
  localStorage.removeItem('project_members');
  
  React.useEffect(() => {
    const hotjarId: any = process.env.REACT_APP_HOTJAR_SITE_ID;
    const hotjarSnippet: any = process.env.REACT_APP_HOTJAR_SNIPPET_VERSION;
    Hotjar.init(hotjarId, hotjarSnippet);

    //set locales
    if (!language || language === 'en') {
      dayjs.locale('en');
      setAntLocale(enUS);
    } else if (language === 'fr') {
      dayjs.locale(language);
      setAntLocale(frFR);
    } else if (language === 'ja') {
      dayjs.locale(language);
      setAntLocale(jaJP);
    }
  }, [language]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter, sans-serif',
        },
      }}
    >
      <Routes />
      <Modal />
    </ConfigProvider>
  );
};

export default App;
