import { FC } from "react";

export const LogoWrapper: FC<{children: any;  className: string}> = ({ children, className }) => {
  return (
    <div className="registrationGenericContent">
      <div className="leftImg" />
      {children}
    </div>
  );
};
