import {create} from 'zustand'
import {ITaskMyWorkToday, ITaskPrioritiesData} from "../models/interface";

export const dailyInfoStore = create((set) => ({
        todayTaskPriorities: null,
        updateTodayTaskPriorities: (value: ITaskPrioritiesData[] | null) => set({todayTaskPriorities: value}),
        overdueTaskPriorities: null,
        updateOverdueTaskPriorities: (value: ITaskPrioritiesData[] | null) => set({overdueTaskPriorities: value}),
        upcomingTaskPriorities: null,
        updateUpcomingTaskPriorities: (value: ITaskPrioritiesData[] | null) => set({upcomingTaskPriorities: value}),
        todayTaskIveAssigned: null,
        updateTodayTaskIveAssigned: (value: ITaskPrioritiesData[] | null) => set({todayTaskIveAssigned: value}),
        overdueTaskIveAssigned: null,
        updateOverdueTaskIveAssigned: (value: ITaskPrioritiesData[] | null) => set({overdueTaskIveAssigned: value}),
        upcomingTaskIveAssigned: null,
        updateUpcomingTaskIveAssigned: (value: ITaskPrioritiesData[] | null) => set({upcomingTaskIveAssigned: value}),
        myWorkToday: null,
        updateMyWorkToday: (value: ITaskMyWorkToday[] | null) => set({myWorkToday: value}),
        allTaksAssignedToMe: null,
        updateAllTaskAssignedToMe: (value: ITaskPrioritiesData[] | null) => set({ allTaksAssignedToMe: value }),
        allTaksAssignedByMe: null,
        updateAllTaskAssignedByMe: (value: ITaskPrioritiesData[] | null) => set({ allTaksAssignedByMe: value }),
        resetValue: () => set({
            todayTaskPriorities: null,
            overdueTaskPriorities: null,
            upcomingTaskPriorities: null,
            todayTaskIveAssigned: null,
            overdueTaskIveAssigned: null,
            upcomingTaskIveAssigned: null,
            allTaksAssignedToMe: null,
            allTaksAssignedByMe: null
        })
    })
)