import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserStore } from '../../../stores/user.store';
import {
  addOverlay,
  getCurrentOrganization,
  getPageSpecificData,
  removeOverlay,
} from '../../../utils/commonFunctions';
import {
  IApiResponse,
  IFolderData,
  ILoaderState,
  IPageSpecificData,
  IProjectCreationAttributes,
} from '../../../models/interface';
import {
  useDashboardStore,
  useLoaderStore,
  useLocalizationStore,
  useProjectDashboardStore,
} from '../../../stores';
import {
  dashboardService,
  loggerService,
  projectService,
} from '../../../services';
import CreateProject from './CreateProject';
import cmsService from '../../../services/CmsService/cms.service';
import { ComponentLoader, useAnalytics } from '../../sharedComponents';
import BrowserStorageService from 'services/browserStorage.service';
import { getAssetConfigObject } from 'config/assetsConfig';
import cryptoRandomString from 'crypto-random-string';
import TemplateDetailWrapper from './Project/TemplateDetailWrapper';

const DashboardProjectListPage: FC<{
  pageSlug?: string[];
  cmsPageName?: string[];
}> = ({ pageSlug, cmsPageName }) => {
  const { userDetails } = useUserStore((state: any) => state);
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  const {
    folderList,
    setFolderList,
    folderProjectList,
    userList,
    setUserList,
  } = useDashboardStore((state: any) => state);
  const {
    selectedTemplate,
    setSelectedTemplate,
    showSidePanel,
    setShowSidePanel,
  } = useProjectDashboardStore((state: any) => state);
  const [loading, setLoading] = useState(false);
  const [loadingIndustry, setLoadingIndustry] = useState(false);
  const [
    projectListPageSpecificDataState,
    setProjectListPageSpecificDataState,
  ] = useState<IProjectCreationAttributes | null>(null);
  const [pageSpecificDataState, setPageSpecificDataState] =
    useState<IProjectCreationAttributes | null>(null);
  const [industries, setIndustries] = useState<IPageSpecificData[]>([]);
  const [originalPublishedTemplates, setOriginalPublishedTemplates] = useState(
    [],
  );
  const [publishedTemplates, setPublishedTemplates] = useState([]);
  const [searchText, setSearchText] = useState<string>('');
  const gaContext = useAnalytics();
  const browserStorage = BrowserStorageService.getInstance();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const [selectedIndustry, setSelectedIndustry] = useState<number | null>(null);

  const sendAnalyticsEvent = () => {
    gaContext.updateProps({
      username: userDetails.full_name,
      email: userDetails.email,
      organization: getCurrentOrganization(
        org_key,
        userDetails.organization_info,
      )
        ? getCurrentOrganization(org_key, userDetails.organization_info)
            ?.organization_name
        : '',
      eventName: 'Create Project Started',
      eventCategory: 'Projects',
      eventLabel: 'Create Project',
    });

    return true;
  };

  useEffect(() => {
    (async () => {
      setLoadingIndustry(true);
      const localizationData = browserStorage.getLocalization();
      const response = await projectService.getIndustriesPublishedTemplates({
        language_alias: localizationData.language || 'en',
        country_alias: localizationData.country || 'US',
        org_key,
      });
      const responsePublishedTemplates =
        await projectService.getPublishedTemplate({
          language_alias: localizationData.language || 'en',
          country_alias: localizationData.country || 'US',
          org_key,
        });
      const responseRecentTemplates = await projectService.getRecentTemplates({
        organization_id: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        user_id: userDetails?.user_id,
      });
      if (
        response.messageId === 1 &&
        responsePublishedTemplates.messageId === 1 &&
        responseRecentTemplates.messageId === 1
      ) {
        setIndustries(response?.data || []);
        setOriginalPublishedTemplates(responsePublishedTemplates?.data || []);
        setPublishedTemplates(responsePublishedTemplates?.data || []);
        setLoadingIndustry(false);
      }
    })();

    return () => {
      removeOverlay();
    };
  }, []);

  useEffect(() => {
    setLoaderState('active');
  }, []);

  useEffect(() => {
    if (folderProjectList.length > 0) {
      if (folderList?.length === 0 && userList?.length === 0) {
        (async () => {
          setLoaderState('active');
          await Promise.allSettled([fetchFolderList(), fetchUserList()]);
          setLoaderState('inactive');
        })();
      } else {
        if (folderList?.length === 0) {
          setLoaderState('active');
          (async () => {
            await fetchFolderList();
          })();
          setLoaderState('inactive');
        }

        if (userList?.length === 0) {
          setLoaderState('active');
          (async () => {
            await fetchUserList();
          })();
          setLoaderState('inactive');
        }
        setLoaderState('inactive');
      }
    }
  }, [folderProjectList]);

  useEffect(() => {
    if (showSidePanel === true) {
      addOverlay();
      sendAnalyticsEvent();
    } else {
      removeOverlay();
    }
  }, [showSidePanel]);

  //
  useEffect(() => {
    setLoaderState('active');
    if (!projectListPageSpecificDataState || !pageSpecificDataState) {
      if (pageSlug) {
        (async () => {
          let projectPageListSpecificData: any;
          let createProjectPageSpecificData: any;
          let pageSpecificData: any;
          let pageSpecificDataStore = cmsData.pageSpecificData;

          if (pageSpecificDataStore.length > 0) {
            projectPageListSpecificData = getPageSpecificData(
              cmsData,
              pageSlug[1],
            );
            createProjectPageSpecificData = getPageSpecificData(
              cmsData,
              pageSlug[2],
            );

            await setPageCmsData(
              projectPageListSpecificData,
              createProjectPageSpecificData,
            );
          } else {
            setLoaderState('active');
            await cmsService.fetchCmsData(
              cmsData,
              setCmsData,
              cmsPageName,
              pageSlug,
            );
            pageSpecificData = getPageSpecificData(cmsData, pageSlug[1]);
            setProjectListPageSpecificDataState(pageSpecificData[0].attributes);
            pageSpecificData = getPageSpecificData(cmsData, pageSlug[2]);
            setPageSpecificDataState(pageSpecificData[0].attributes);
            setLoaderState('inactive');
          }
        })();
        setLoaderState('inactive');
      }
    }
  }, [pageSpecificDataState, projectListPageSpecificDataState]);

  const fetchFolderList = async () => {
    try {
      let payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        userId: userDetails?.user_id,
      };

      let response: IApiResponse = await dashboardService.getFolder(payload);
      let folders: IFolderData[] = response.data.folders as IFolderData[];

      if (folders) {
        setFolderList(folders);
      }
    } catch (err) {
      await loggerService.log({
        severity: 'High',
        message: `Folder list get action failed`,
        payload: { ApiResponse: err },
      });
    }
  };

  const fetchUserList = async () => {
    try {
      let payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
      };

      let response: any = await dashboardService.getUserList(payload);
      let list: any[] = response.data.users;

      if (list) {
        setUserList(list);
      }
    } catch (err) {
      await loggerService.log({
        severity: 'High',
        message: `User list get action failed`,
        payload: { ApiResponse: err },
      });
    }
  };

  const setPageCmsData = async (
    projectPageListSpecificData: any,
    createProjectPageSpecificData: any,
  ) => {
    let pageSpecificData: any;

    if (pageSlug) {
      if (!projectPageListSpecificData && !createProjectPageSpecificData) {
        setLoaderState('active');
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug,
        );
        pageSpecificData = getPageSpecificData(cmsData, pageSlug[1]);
        setProjectListPageSpecificDataState(pageSpecificData[0].attributes);
        pageSpecificData = getPageSpecificData(cmsData, pageSlug[2]);
        setPageSpecificDataState(pageSpecificData[0].attributes);
        setLoaderState('inactive');
      } else {
        if (!projectPageListSpecificData) {
          setLoaderState('active');
          await cmsService.fetchCmsData(
            cmsData,
            setCmsData,
            cmsPageName,
            pageSlug,
          );
          pageSpecificData = getPageSpecificData(cmsData, pageSlug[1]);
          setProjectListPageSpecificDataState(pageSpecificData[0].attributes);
          setLoaderState('inactive');
        } else {
          setProjectListPageSpecificDataState(
            projectPageListSpecificData[0].attributes,
          );
        }

        if (!createProjectPageSpecificData) {
          setLoaderState('active');
          await cmsService.fetchCmsData(
            cmsData,
            setCmsData,
            cmsPageName,
            pageSlug,
          );
          pageSpecificData = getPageSpecificData(cmsData, pageSlug[2]);
          setPageSpecificDataState(pageSpecificData[0].attributes);
          setLoaderState('inactive');
        } else {
          setPageSpecificDataState(createProjectPageSpecificData[0].attributes);
        }
      }
    }
  };
  const handleClickIndustry = async (industryId: number) => {
    try {
      setLoading(true);
      setSelectedIndustry(industryId);
      const localizationData = browserStorage.getLocalization();
      const response = await projectService.getPublishedTemplate({
        industry_id: industryId,
        language_alias: localizationData.language || 'en',
        country_alias: localizationData.country || 'US',
        org_key,
      });
      if (response.messageId === 1) {
        setOriginalPublishedTemplates(response.data);
        setPublishedTemplates(response.data);
        setLoading(false);
      }
    } catch (error) {
      await loggerService.log({
        severity: 'High',
        message: `Get templates by industry failed`,
        payload: { ApiResponse: error },
      });
    }
  };

  const handleClearSelectedIndustry = async () => {
    try {
      setLoading(true);
      setSelectedIndustry(null);
      const localizationData = browserStorage.getLocalization();
      const response = await projectService.getPublishedTemplate({
        language_alias: localizationData.language || 'en',
        country_alias: localizationData.country || 'US',
        org_key,
      });
      if (response.messageId === 1) {
        setOriginalPublishedTemplates(response.data);
        setPublishedTemplates(response.data);
        setLoading(false);
      }
    } catch (error) {
      await loggerService.log({
        severity: 'High',
        message: `Get templates by industry failed`,
        payload: { ApiResponse: error },
      });
    }
  };

  const convertTemplateDescription = (description: string) => {
    const div = document.createElement('div');
    div.innerHTML = description;
    const text = div.textContent || div.innerText || '';
    return text;
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    const searchTerm = e.target.value.toLowerCase().trim();
    const filteredPublishedTemplates = originalPublishedTemplates.filter(
      (template: any) => {
        const templateName = template.template_name.toLowerCase();
        const templateDescription = template.template_description.toLowerCase();
        const organization_functions = template.organization_functions
          ? template.organization_functions
              .map((data: any) => data.organization_function)
              .join(',')
              .toLowerCase()
          : '';
        const tags = template.tags ? template.tags.join(',').toLowerCase() : '';

        return (
          templateName.includes(searchTerm) ||
          templateDescription.includes(searchTerm) ||
          tags.includes(searchTerm) ||
          organization_functions.includes(searchTerm)
        );
      },
    );
    setPublishedTemplates(
      e.target.value ? filteredPublishedTemplates : originalPublishedTemplates,
    );
  };

  return (
    <div className="mainContent">
      <div className="contentHeadingPanel">
        {!selectedTemplate ? (
          <h2>
            {userDetails &&
              projectListPageSpecificDataState &&
              `${projectListPageSpecificDataState?.txt_page_header} ${
                getCurrentOrganization(org_key, userDetails?.organization_info)
                  ?.organization_name
                  ? getCurrentOrganization(
                      org_key,
                      userDetails?.organization_info,
                    )?.organization_name
                  : userDetails.full_name
              }`}
          </h2>
        ) : (
          <>
            <a
              href="void(e)"
              className="backBtn"
              onClick={(e) => {
                e.preventDefault();
                setSelectedTemplate(null);
              }}
            >
              <span className="material-icons-outlined">arrow_back_ios</span>
              {projectListPageSpecificDataState?.btn_back}
            </a>
            <div className="contentHeadingRightPart">
              <button
                className=" btnStyle1"
                type="button"
                data-toggle="dropdown"
                onClick={() => {
                  setShowSidePanel(true);
                }}
              >
                {projectListPageSpecificDataState?.btn_use_template}
              </button>
            </div>
          </>
        )}
      </div>

      <ComponentLoader isLoading={loadingIndustry}>
        <div className="templateDetailsWrapContainer inner-container mainContentRightSec">
          <div className="projectTemplateWrapper">
            {selectedTemplate ? (
              <TemplateDetailWrapper
                projectListPageSpecificDataState={
                  projectListPageSpecificDataState
                }
                setShowSidePanel={setShowSidePanel}
                industries={industries}
                selectedIndustry={selectedIndustry}
                handleClickIndustry={handleClickIndustry}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
              />
            ) : (
              <>
                <div className="projectTemplateBanner">
                  <h2>{projectListPageSpecificDataState?.txt_banner_title}</h2>
                  <p>
                    {projectListPageSpecificDataState?.txt_banner_description}
                  </p>
                </div>
                <div className="templateListingWrap">
                  <div className="leftPanel">
                    <div
                      className="viewAllBtn"
                      onClick={handleClearSelectedIndustry}
                      onKeyDown={() => {}}
                    >
                      {selectedIndustry && (
                        <button>
                          {projectListPageSpecificDataState?.txt_view_all}
                        </button>
                      )}
                    </div>
                    <ul className="projectTemplate">
                      {(industries || []).map((ele: any) => (
                        <li
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClickIndustry(ele.attributes.id_industry);
                          }}
                          onKeyDown={() => {}}
                          key={cryptoRandomString({ length: 5 })}
                          className={
                            selectedIndustry === ele.attributes.id_industry
                              ? 'active-item'
                              : ''
                          }
                        >
                          <a href="void(e)">{ele.attributes.industry_name}</a>
                        </li>
                      ))}
                    </ul>

                    <div className="createOwn">
                      <div
                        className="itemNameBlk addFolder"
                        onClick={() => {
                          setShowSidePanel(true);
                        }}
                        onKeyDown={() => {}}
                      >
                        <span className="addPlusSolid"></span>
                        <span className="itemName">
                          {projectListPageSpecificDataState?.btn_cta}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="rightPanel">
                    <div className="searchFieldWrap">
                      <div className="searchFld">
                        <input
                          type="search"
                          value={searchText}
                          placeholder={
                            projectListPageSpecificDataState?.plc_search_template
                          }
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <ComponentLoader isLoading={loading}>
                      <ul className="templateCardList">
                        {(publishedTemplates || []).map((template: any) => (
                          <li
                            key={cryptoRandomString({ length: 5 })}
                            onClick={() => setSelectedTemplate(template)}
                            onKeyDown={() => {}}
                          >
                            <div className="thumbnail professionalService">
                              <img
                                src={
                                  template.template_thumbnails?.length > 0
                                    ? `${
                                        process.env
                                          .REACT_APP_STORAGE_BASE_URL_FOR_TEMPLATE
                                      }${
                                        template.template_thumbnails?.find(
                                          (item: any) => item.is_default,
                                        )?.thumbnail_url
                                      }`
                                    : getAssetConfigObject()
                                        .REACT_APP_THUMBNAIL_IMAGE
                                }
                                alt="thumbnail"
                              />
                            </div>
                            <div className="card-detail">
                              <h4>{template.template_name}</h4>
                              <p>
                                {convertTemplateDescription(
                                  template.template_description,
                                )}
                              </p>
                              {template?.organization_functions?.length > 0 && (
                                <p className="templateCategory">
                                  {template.organization_functions
                                    .map(
                                      (orgFunc: any) =>
                                        orgFunc.organization_function,
                                    )
                                    .join(', ')}
                                </p>
                              )}
                            </div>
                            <span className="material-icons-outlined navigateNext">
                              navigate_next
                            </span>
                          </li>
                        ))}
                      </ul>
                    </ComponentLoader>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </ComponentLoader>
      {showSidePanel ? (
        <CreateProject
          pageSpecificDataState={pageSpecificDataState}
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          selectedTemplate={selectedTemplate}
        />
      ) : null}
    </div>
  );
};

export default DashboardProjectListPage;
