import { FC } from "react";
import { IProjectState } from "models/interface";
import { useProjectDashboardStore } from "stores";
import {
  IPackConfiguration,
  IPackDetail,
} from "models/interface/customization.interface";

const StickerPackList: FC<{
  packId: number;
  setPackId: React.Dispatch<React.SetStateAction<number>>;
}> = ({ packId, setPackId }) => {
  const { packList } = useProjectDashboardStore(
    (state: IProjectState) => state
  );

  return (
    <form>
      <div className="statusUpdateVariationRadioWrapper">
        {packList?.map((ele: IPackDetail) => {
          return (
            <div className="radioWrapper" key={ele.pack_id}>
              <input
                type="radio"
                id={`radio_pack_selection_${ele.pack_id}`}
                name="status-sticker"
                checked={packId === ele.pack_id}
                value={ele.pack_id}
                onChange={() => setPackId(ele.pack_id)}
              />
              <label
                onClick={() => {
                  setPackId(ele.pack_id);
                }}
                onKeyDown={() => {}}
                htmlFor={`radio_pack_selection_${ele.pack_id}`}
              >
                <div className="labelWrap stickerCollectionWrap">
                  {[...ele.pack_configuration]
                    .sort(
                      (a: IPackConfiguration, b: IPackConfiguration) =>
                        a.sort_value - b.sort_value
                    )
                    .map((ele: IPackConfiguration) => (
                      <img
                        src={`${process.env.REACT_APP_STORAGE_BASE_URL}${ele.status_value}`}
                        alt={ele.status_label}
                        key={ele.status_key}
                      />
                    ))}
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </form>
  );
};

export default StickerPackList;
