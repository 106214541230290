import { FC, Fragment } from "react";

export const TermsOfUse: FC<{}> = () => {
  return (
    <Fragment>
      <header className="privacy-terms-header">
        <h1>
          <a href={void 0} className="logoDefault" title="Cleversort"></a>
        </h1>
      </header>

      <main>
        <div className="privacy-terms-page content">
          <div className="container">
            <h2>TERMS OF USE</h2>
            <span className="dateEffective">Date effective: Aug 31, 2023</span>
            <p>
              Please read this entire agreement carefully. These are the Terms
              governing your use of Digital Avenues services, the website, and
              the app (collectively, “Site”). This Site is owned and operated by
              Digital Avenues Pte. Ltd (“Digital Avenues”). Digital Avenues
              reserves all rights in respect of this Site, including any rights
              not explicitly granted in these Terms of Use.
              <br />
              <br />
              This Agreement sets forth the Terms of Use governing your use of
              this Site and your access to and use of the information, content
              and services offered on this Site. By accessing or using this Site
              or any of the services, you accept and agree to be legally bound
              by this Agreement, as it may be amended or supplemented from time
              to time, and agree to all operating rules and/or policies of
              Digital Avenues and the services that may be published by Digital
              Avenues on this Site. All these rules and policies are
              incorporated into this Agreement by this reference.
              <br />
              <br />
              The services are intended for use only by individuals and legal
              entities that can form legally binding contracts under applicable
              law. By using any of the services, you expressly represent that
              you are an individual with the capacity under applicable law to
              enter into a legally binding contract. Digital Avenues reserves
              the right, at any time and from time to time, to update, revise,
              supplement and to otherwise modify this Agreement and to impose
              new or additional rules, policies, terms or conditions on your use
              of the services. Such updates, revisions, supplements,
              modifications, and additional rules and policies shall be
              effective immediately and incorporated into this Agreement upon
              notice thereof, which may be given by any reasonable means
              including by posting to this Site or the websites of Digital
              Avenues related or affiliated entities. Your continued use of this
              Site following such notice shall be deemed to indicate your
              acceptance of any and all such additional terms. All additional
              terms are hereby incorporated into this Agreement by this
              reference.
              <br />
              <br />
              Digital Avenues reserves the right, at any time and from time to
              time, to modify or discontinue, temporarily or permanently, any or
              all of the services, with or without notice and in its sole
              discretion. This includes the right to modify, discontinue or
              remove any content, postings, links, pages, services, or other
              materials at any time and for any reason. You agree that neither
              Digital Avenues, its partners nor affiliates shall be liable to
              you for any modification, general suspension or discontinuance of
              any services. Digital Avenues may, in its sole discretion, refuse
              or restrict anyone from access to any or all of the services at
              any time.
            </p>
            <div className="block">
              <h3>YOUR OBLIGATIONS</h3>
              <p className="subHeading">Registration Information</p>
              <p>
                You must register in order to use our Site. You agree to: (i)
                provide current, complete, not misleading, true and accurate
                information as required to complete the registration form and at
                other points as may be required in the course of your using the
                services; and (ii) maintain and update this information as
                required to keep it current, complete, and accurate. You agree
                not to use a false or misleading name or a name that you are not
                authorized to use. If Digital Avenues has reasonable grounds to
                suspect that such information is untrue, inaccurate, not current
                or incomplete, Digital Avenues has the right to suspend or
                terminate your account, refuse any and all current or future use
                of the services, and pursue any appropriate legal remedies.
                Furthermore, you grant Digital Avenues the right to use your
                registration and other data and information for the purposes
                described in this Agreement and in furtherance of your use of
                the services.
                <br />
                <br />
                As a registered user, you will receive or establish one or more
                passwords and accounts. Maintaining the confidentiality and
                security of your password and account is solely your
                responsibility. Do not divulge your password to anyone else, and
                do not use anyone else's password or account. You are entirely
                responsible for all activities that occur on or through your
                account(s), and you agree to notify us immediately about any
                unauthorized use of accounts or any breach of security. You
                agree that Digital Avenues will not be responsible for any
                losses incurred in connection with any misuse of passwords, and
                you further agree that we will have no responsibility whatsoever
                for your failure to comply with these provisions. If Digital
                Avenues has reasonable grounds to suspect that the security of
                your password has been compromised, Digital Avenues has the
                right to suspend or terminate your account, refuse any and all
                current or future use of the services, and pursue any
                appropriate legal remedies. Please refer to our Privacy Policy
                for additional information.
              </p>
              <p className="subHeading">Payment Obligations</p>
              <p>
                The Service is provided based on the Subscription chosen by you.
                <br />
                <br />
                Payment for the subscription fees will be due annually or in
                accordance with the details set out in your individual Plan.
                Digital Avenues will continue invoicing you in accordance with
                the Plan until it is duly terminated in accordance with the
                termination rules set forth in each Plan.
                <br />
                <br />
                You are responsible for all payments inclusive of all taxes and
                duties.
                <br />
                <br />
                You may terminate your Subscription to the Plan in accordance
                with the terms and conditions set forth therein.
              </p>
              <p className="subHeading">Usage</p>
              <p>
                You must only use the Site for lawful purposes, in accordance
                with these Terms of Use and any notice sent by Digital Avenues
                or condition posted on the Site.
              </p>
            </div>
            <div className="block">
              <h3>COPYRIGHT AND CONTENT</h3>
              <p>
                The Site and all content and other materials, including, without
                limitation, the Digital Avenues logo, and all designs, text,
                graphics, pictures, selection, coordination, 'look and feel',
                information, data, software, sound files, other files and the
                selection and arrangement thereof, unless otherwise specified,
                are the proprietary property of Digital Avenues or its licensors
                or users and are protected by trade dress, copyright, and
                trademark laws, and various other intellectual property rights
                and unfair competition laws.
                <br />
                <br />
                You are granted a personal, limited, non-sublicensable license
                to access and use our Site and electronically copy, (except
                where prohibited without a license) and print to hard copy
                portions of our Site materials for your informational,
                non-commercial and personal use only. Such license is subject to
                these Terms of Use and does not include: (i) any resale or
                commercial use of our Site or the Site materials therein; (ii)
                the collection and use of any services listings, pictures or
                descriptions for commercial purposes; (iii) the distribution,
                public performance or public display of any Site materials, (iv)
                modifying or otherwise making any derivative uses of our Site
                and the Site materials, or any portion thereof; (v) use of any
                automated means to access, monitor or interact with any portion
                of our Site, including through data mining, robots, spiders,
                scraping, or similar data gathering or extraction methods; (vi)
                downloading (other than the page caching) of any portion of our
                Site, the Site materials or any information contained therein,
                except as expressly permitted on our Site; (vii) cause to appear
                any pop-up, pop-under, exit windows, expanding buttons, banners,
                advertisement, or anything else which minimizes, covers, or
                frames or inhibits the full display of our Site; (viii) use of
                our web Sites in any way which interferes with the normal
                operation of our Sites; or (ix) any use of our Site or the Site
                materials other than for its intended purpose.
                <br />
                <br />
                Any use of our Site or the Site materials other than as
                specifically authorized herein, without the prior written
                permission of Digital Avenues, is strictly prohibited and will
                terminate the license granted herein. Such unauthorized use may
                also violate applicable laws, including without limitation
                copyright and trademark laws and applicable communications
                regulations and statutes. Unless explicitly stated herein,
                nothing in these Terms of Use shall be construed as conferring
                any license to intellectual property rights, whether by
                estoppel, implication, or otherwise. This license is revocable
                at any time.
              </p>
              <p className="subHeading">
                Copyright (Network Service Provider) Regulations
              </p>
              <p>
                In accordance with Regulation 5 of the Copyright (Network
                Service Provider) Regulations (Cap. 63, Rg. 7), the designated
                representative for the Site is:
              </p>
              <strong className="userName">Vineet Kothari</strong>
              <p>Digital Avenues Pte. Ltd.</p>
              <p>30 Cecil Street, #19-08 Prudential Tower, Singapore 049712</p>
              <p>+65 9336 4271</p>
            </div>
            <div className="block">
              <h3>THIRD PARTY CONTENT</h3>
              <p>
                When using the Site, you may be exposed to Content generated by
                other Users on the Site (“Third Party Content”) that may be
                inaccurate, indecent, or offensive. Digital Avenues is not
                responsible for the accuracy, usefulness, or Intellectual
                Property Rights related to Third Party Content. You hereby waive
                any right or remedy whether in law or equity you may have
                against Digital Avenues with respect to Third Party Content.
                <br />
                <br />
                Digital Avenues may provide links to web pages and content of
                third parties ('Third Party Content') as a service to those
                interested in this information. Digital Avenues does not monitor
                or have any control over any Third Party Content or third party
                Sites. Digital Avenues does not endorse any Third Party Content
                and can make no guarantee as to its accuracy or completeness.
                Digital Avenues does not represent or warrant the accuracy of
                any information contained therein, and undertakes no
                responsibility to update or review any Third Party Content.
                Users use these links and Third Party Content contained therein
                at their own risk.
                <br />
                <br />
                Digital Avenues may run advertisements and promotions from third
                parties on our Site. Your business dealings or correspondence
                with, or participation in promotions of, advertisers other than
                Digital Avenues, and any terms, conditions, warranties, or
                representations associated with such dealings, are solely
                between you and such third party. Digital Avenues is not
                responsible or liable for any loss or damage of any sort
                incurred as the result of any such dealings or as the result of
                the presence of such non- Digital Avenues advertisers on our
                Site.
              </p>
            </div>

            <div className="block">
              <h3>ACCURACY</h3>
              <p>
                Digital Avenues makes every attempt to ensure that the
                information on our Site is as accurate and complete as possible
                and from time to time the Site may contain errors and may not be
                complete or current. Digital Avenues therefore reserves the
                right to correct any errors or omissions and to change or update
                information at any time without prior notice.
              </p>
            </div>
            <div className="block">
              <h3>AVAILABILITY OF WEBSITE/SERVICES</h3>
              <p>
                Subject to the terms and conditions of this Agreement and our
                other policies and procedures, we shall use commercially
                reasonable efforts to attempt to provide this Site and the
                Services thereon a twenty-four (24) hours a day, seven (7) days
                a week basis. You acknowledge and agree that from time to time
                this Site may be inaccessible or inoperable for any reason
                including, but not limited to, equipment malfunctions; periodic
                maintenance, repairs or replacements that we undertake from time
                to time; or causes beyond our reasonable control or that are not
                reasonably foreseeable including, but not limited to,
                interruption or failure of telecommunication or digital
                transmission links, hostile network attacks, network congestion
                or other failures. You acknowledge and agree that we have no
                control over the availability of this Site or the Service on a
                continuous or uninterrupted basis, and that we assume no
                liability to you or any other party with regard thereto.
              </p>
            </div>
            <div className="block">
              <h3>USER CONTENT</h3>
              <p>
                Digital Avenues may permit you to post, upload, transmit through
                or otherwise provide through our Site messages, text,
                illustrations, files, images, graphics, photos, comments,
                sounds, music, videos, information and/or other content. You
                agree not to submit any content that infringes any copyright,
                trademark, patent, trade secret, moral right, or other
                intellectual property or proprietary right. You are solely
                liable for any damage resulting from your infringement or from
                any other harm resulting from your content. Furthermore, you
                represent and warrant that your content will reflect opinions
                genuinely held by you, and comply with all applicable laws; will
                be truthful, non-misleading and non-deceptive; will not contain
                any material which is tortious, defamatory, obscene, offensive,
                hateful or inflammatory; will not promote sexually explicit
                material or violence, or promote discrimination based on race,
                sex, religion, nationality, disability, sexual orientation or
                age; does not breach any legal duty you owe to a third party;
                will not be threatening, abuse or invade another's privacy, or
                provided with an intent to harass, upset or embarrass any other
                person; will not be provided with an intent to impersonate any
                person, to misrepresent your identity or affiliation with any
                person, or to falsely give the impression that your content
                comes from someone else; or will not advocate, promote or assist
                any unlawful act. Digital Avenues reserves the right but not the
                obligation to monitor, edit or remove any content for violation
                of the letter or spirit of these terms, or for any other reason.
                <br />
                <br />
                By submitting content to Digital Avenues, you automatically
                grant, or warrant to us a worldwide, royalty-free, perpetual,
                irrevocable, non-exclusive, fully sublicensable and transferable
                right and license to use, reproduce, distribute, create
                derivative works based upon, publicly display/perform, transmit
                and publish the content (in whole or in part) as we, in our sole
                discretion, deem appropriate in connection with our business and
                operations. Notwithstanding the foregoing, you waive any and all
                claims you may now or later have in any jurisdiction to any
                rights with respect to the content.
                <br />
                <br />
                Digital Avenues may establish general practices and limits with
                respect to use of the Site, including storage capacity,
                postings, or other uploaded Content that will be retained by the
                Site, the maximum disk space that will be allotted on Digital
                Avenues’s servers on your behalf, and the maximum number of
                times (and the maximum duration for which) you may access the
                Site in a given period of time.
                <br />
                <br />
                Digital Avenues has no responsibility or liability for the
                deletion or failure to store any messages and other
                communications or other Content maintained or transmitted by the
                Site. You further acknowledge that Digital Avenues may change
                these general practices and limits at any time, in its sole
                discretion without notice.
              </p>
            </div>
            <div className="block">
              <h3>ADDITIONAL APP TERMS</h3>
              <p>
                If you download the App from any App Store then, without
                derogating from the warranty disclaimers and limitation of
                liability as set forth in these Terms, you acknowledge and agree
                that: (i) these Terms are applied between Digital Avenues and
                you only, and not with the App Store, and Digital Avenues
                licensors, and not the App Store, are solely responsible for the
                App and the content thereof; (ii) your use of the App is also
                subject to the Usage Rules established by the App Store,
                including those set forth in the applicable Terms of Service,
                effective as of the date that you enter into these Terms; (iii)
                the Subscription granted herein is limited to a non-transferable
                right to use the App; (iv) Digital Avenues is solely responsible
                for providing any maintenance and support services with respect
                to the App, as specified in these Terms, or as required under
                applicable law; and (v) Digital Avenues is solely responsible
                for any product warranties, whether express or implied by law,
                to the extent not effectively disclaimed.
              </p>
            </div>
            <div className="block">
              <h3>WARRANTIES</h3>
              <p>
                Except as expressly provided to the contrary in writing by
                Digital Avenues, this Site, the content contained therein and
                the products and services provided on or in connection therewith
                are provided on an “as-is” basis without warranties of any kind,
                either express or implied. Digital Avenues disclaims all other
                warranties of any kind, either express or implied. Digital
                Avenues disclaims all other warranties, express or implied,
                including, without limitation, implied warranties of
                merchantability, fitness for a particular purpose, title, and
                non-infringement as to the information, content, and materials
                in our Site. Digital Avenues does not represent or warrant that
                materials in our Site or the services are accurate, complete,
                reliable, current, or error-free. Digital Avenues does not
                represent or warrant that our Site or its servers are free of
                viruses or other harmful components.
              </p>
            </div>
            <div className="block">
              <h3>LIMITATION OF LIABILITY</h3>
              <p>
                In no event shall Digital Avenues, its directors, members,
                employees or agents be liable for any direct, special, indirect
                or consequential damages, or any other damages of any kind,
                including but not limited to, loss of use, loss or profits, or
                loss of data, whether in an action in contract, tort (including
                but not limited to negligence), or otherwise arising out of or
                in any way connected with the use of our Site, the products and
                services, or the content contained in or accessed through our
                Site, including without limitation any damages caused by our
                resulting from reliance by the user on any information obtained
                from Digital Avenues, or that results from mistakes, omissions,
                interruptions, deletion of files or email, errors, defects,
                viruses, delays in operation or transmission or any failure of
                performance, whether or not resulting from acts of god,
                communications, failure, theft, destruction or unauthorized
                access to Digital Avenues’s records, programs or services, or
                accidents or harm arising from your possession or use of the
                Services.
              </p>
            </div>
            <div className="block">
              <h3>APPLICABLE LAW AND VENUE</h3>
              <p>
                These Terms of Use and your use of this Site will be governed by
                and construed in accordance with the laws of the Republic of
                Singapore without resort to its conflict of law provisions. You
                agree that any action at law or in equity arising out of or
                relating to these Terms of Use shall be filed only in the courts
                located in the Republic of Singapore, and you hereby irrevocably
                and unconditionally consent and submit to the exclusive
                jurisdiction of such courts over any suit, action or proceeding
                arising out of your use of this Site, any purchase from this
                Site, or these Terms of Use.
              </p>
            </div>
            <div className="block">
              <h3>SEVERABILITY</h3>
              <p>
                If any provision of these Terms of Use shall be deemed unlawful,
                void or for any reason unenforceable, then that provision shall
                be deemed severable from these Terms of Use and shall not affect
                the validity and enforceability of any remaining provisions.
              </p>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};
