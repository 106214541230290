import React, { FC, Fragment, useEffect, useState } from 'react';
import zoomInShortcut from '../../../assets/images/zoom-in-shortcut.svg';
import zoomOutShortcut from '../../../assets/images/zoom-out-shortcut.svg';
import zoom100Shortcut from '../../../assets/images/zoom-100-shortcut.svg';
import zoomFitShortcut from '../../../assets/images/zoom-fit-shortcut.svg';
import listViewIcon from '../../../assets/images/listViewIcon.svg';
import funnelIcon from '../../../assets/images/funnelIcon.svg';
import checkIcon from '../../../assets/images/checkIcon.svg';
import addIcon from '../../../assets/images/addIcon.svg';
import {
  Button,
  Dropdown,
  MenuProps,
  message,
  notification,
  Space,
  Tooltip,
} from 'antd';
import {
  IApiResponse,
  IFolderData,
  IProjectState,
} from '../../../models/interface';
import { dashboardService, loggerService } from '../../../services';
import {
  useDashboardStore,
  useProjectDashboardStore,
  useUserStore,
} from '../../../stores';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  generateNewListForSelectedProjectInFolderProjectList,
  generateNewListFromCreateFolder,
} from 'utils/generateNewList';
import { projectService } from '../../../services/';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import { useOrganizationRoutes } from 'routes/orgRoutes';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import {
  getMessageThreadByProject,
  updateProjectName,
} from 'utils/firebaseCommonFunctions';
import DisplayProjectMember from 'components/pages/DashboardPage/Project/Sections/DisplayProjectMember/DisplayProjectMember';
import ProjectSettingsCta from 'components/pages/DashboardPage/Project/ProjectSettingsCta';
import {
  addOverlay,
  getCurrentOrganization,
  getPageSpecificData,
  removeOverlay,
} from '../../../utils/commonFunctions';
import UpdateProject from 'components/pages/DashboardPage/UpdateProject';
import CloneProject from 'components/pages/DashboardPage/CloneProject';
import { IEnvFilterData, useFeedbackStore } from 'stores/feedback.store';
import { io } from 'socket.io-client';
import EditEnvModal from './AddProject/EditEnvModal';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
const socket = io(process.env.REACT_APP_SOCKET_URL!, {
  withCredentials: true,
});

const PncHeader: FC<{
  cmsData: any;
}> = ({ cmsData }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [filterActiveKey, setFilterActiveKey] = useState<string>('2');
  const { hasPermissions } = useRbac();
  const {
    projectDetails,
    setProjectDetails,
    fetchProjectMembers,
    projectMembers,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);
  const [currentProjectName, setCurrentProjectName] = useState<string>('');
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const [isLoadingProjectDetails, setIsLoadingProjectDetails] = useState(true);
  const urlParams = useParams();
  const { folderList, setFolderList, setFolderProjectList, folderProjectList } =
    useDashboardStore((state: any) => state);
  const {
    envValue,
    setEnvValue,
    envFilterData,
    handleSetPanOnDrag,
    panOnDrag,
    updateZoomLevel,
    zoomLevelPercentage,
    filterKey,
    updateFilterValue,
    updateFitviewFlag,
  } = useFeedbackStore((state: any) => state);
  const navigate = useNavigate();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const [showSettingDropDown, setShowSettingDropDown] =
    useState<boolean>(false);
  const [showProjectMemberDropdown, setShowProjectMemberDropdown] =
    useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [sidePanelMode, setSidePanelMode] = useState('update');
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(100);
  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
  const [msg, contextMsg] = message.useMessage({ maxCount: 1 });
  const [envDropdownOpen, setEnvDropdownOpen] = useState(false);
  const [editEnvKey, setEditEnvKey] = useState<number | null>(null);
  const [, setQuery] = useSearchParams();
  const {
    pncFeedbackSpecificCmsData,
    projectDetailsPageSpecificData,
    projectUpdatePageSpecificData,
    pncAddEnvSpecificCmsData,
  } = usePncCmsDataStore();

  useEffect(() => {
    (async () => {
      if (urlParams.projectId) {
        await getProjectDetails(urlParams.projectId);
        if (folderList?.length === 0) {
          await fetchFolderList();
        }
      }
    })();
  }, [urlParams]);

  useEffect(() => {
    if (showSidePanel === true) {
      addOverlay();
    } else {
      removeOverlay();
    }
  }, [showSidePanel]);

  useEffect(() => {
    if (isEdit) {
      setCurrentProjectName(projectDetails?.project_name!);
    }
  }, [isEdit]);
  useEffect(() => {
    setZoomLevel(Math.round(zoomLevelPercentage * 100));
  }, [zoomLevelPercentage]);

  useEffect(() => {
    socket.on('receiveProjectArchive', (projectArchivePayload: any) => {
      actionOnNotification(projectArchivePayload, true);
    });
    return () => {
      socket.off('receiveProjectArchive');
      localStorage.removeItem('currentMessageId');
    };
  }, []);

  useEffect(() => {
    if (filterKey === 0) {
      setFilterActiveKey('2');
    } else {
      setFilterActiveKey('3');
    }
  }, [filterKey]);

  const actionOnNotification = (payload: any, isArchived: boolean = false) => {
    let parsedPayload = JSON.parse(payload);
    if (
      parsedPayload.currentOrganizationId ===
        getCurrentOrganization(org_key, userDetails?.organization_info)
          ?.organization_id &&
      parsedPayload.currentUserId !== userDetails.user_id &&
      parsedPayload.currentProjectId === +location.pathname.split('/')[4]
    ) {
      if (isArchived === true) {
        addOverlay();
      }
      openNotification(
        parsedPayload.currentUserName,
        isArchived,
        parsedPayload?.currentProjectName,
      );
    }
  };

  const openNotification = (
    name: string,
    isArchived: boolean = false,
    projectName: string = '',
  ) => {
    if (isArchived === true) {
      let tmpPageData = getPageSpecificData(cmsData, 'archive-project')!;
      let archiveProjectCMSData: any = tmpPageData[0]?.attributes;
      const notificationDescription = `<strong>${projectName}</strong> ${archiveProjectCMSData?.lbl_archive_notification_config.message}`;
      const btn = (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={async () => {
              window.location.reload();
              navigate(
                `/org/${location.pathname.split('/')[2]}/${
                  NavRoutesEnum.DASHBOARD
                }`,
              );
              api.destroy();
            }}
          >
            {archiveProjectCMSData?.lbl_archive_notification_config.action}
          </Button>
        </Space>
      );
      api.info({
        message: archiveProjectCMSData?.lbl_archive_notification_config.title,
        description: (
          <div dangerouslySetInnerHTML={{ __html: notificationDescription }} />
        ),
        btn,
        closeIcon: <></>,
        placement: 'bottomLeft',
        duration: 0,
        onClose: () => {
          removeOverlay();
        },
      });
    } else {
      let tmpPageData = getPageSpecificData(cmsData, 'task-list')!;
      let taskListCmsData: any = tmpPageData[0]?.attributes;
      const btn = (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={async () => {
              //await getProjectDetails(urlParams.projectId!);
              window.location.reload();
              api.destroy();
            }}
          >
            {taskListCmsData?.lbl_notification_config.action}
          </Button>
        </Space>
      );
      api.info({
        message: taskListCmsData?.lbl_notification_config.title,
        description: `${name} ${taskListCmsData?.lbl_notification_config.message}`,
        btn,
        closeIcon: <></>,
        placement: 'bottomLeft',
        duration: 0,
      });
    }
  };

  const fetchFolderList = async () => {
    try {
      let payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        userId: userDetails.user_id,
      };

      let response: IApiResponse = await dashboardService.getFolder(payload);
      let folders: IFolderData[] = response.data.folders as IFolderData[];

      if (folders) {
        setFolderList(folders);
      }
    } catch (err) {
      await loggerService.log({
        severity: 'High',
        message: `Folder list get action failed`,
        payload: { ApiResponse: err },
      });
    }
  };

  const getProjectDetails = async (project_id: string) => {
    setIsLoadingProjectDetails(true);
    try {
      const response = await projectService.getProjectDetails({
        project_id: project_id,
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
      });
      if (response.messageId === 1) {
        setProjectDetails(response.data);
        fetchProjectMembers({
          project_id: urlParams.projectId ?? '',
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
        });
        setIsLoadingProjectDetails(false);
      } else {
        navigate(`${organizationBaseRouteUrl}/${NavRoutesEnum.DASHBOARD}`);
      }
    } catch (error) {
      setIsLoadingProjectDetails(false);
      navigate(`${organizationBaseRouteUrl}/${NavRoutesEnum.DASHBOARD}`);
      await loggerService.log({
        severity: 'High',
        message: 'Failed to fetch project details',
        payload: { project_id: project_id },
      });
    }
  };

  const changeProjectName = async () => {
    if (currentProjectName.trim().length > 2) {
      try {
        if (
          currentProjectName.trim() !== projectDetails?.project_name?.trim()
        ) {
          setIsLoadingProjectDetails(true);

          const response = await projectService.updateProjectName({
            projectId: +urlParams.projectId!,
            organizationId: getCurrentOrganization(
              org_key,
              userDetails?.organization_info,
            )?.organization_id!,
            userId: userDetails.user_id,
            projectName: currentProjectName.trim(),
          });

          if (response.messageId === 1) {
            await getProjectDetails(urlParams.projectId!);
            setIsEdit(false);
            setCurrentProjectName('');
            let updatedProjectFolderListV1 = generateNewListFromCreateFolder(
              response.data.project_folder_list,
              folderProjectList,
            );
            let updatedProjectFolderListV2 =
              generateNewListForSelectedProjectInFolderProjectList(
                +urlParams.projectId!,
                updatedProjectFolderListV1,
              );
            setFolderProjectList(updatedProjectFolderListV2);

            const responseFireStore = await getMessageThreadByProject(
              +getCurrentOrganization(org_key, userDetails?.organization_info)
                ?.organization_id!,
              projectDetails?.project_id!,
            );

            let messageThreadId: string[] = [];

            responseFireStore.forEach((doc: any) => {
              messageThreadId.push(doc.id);
            });

            for (let id of messageThreadId) {
              await updateProjectName(id, currentProjectName.trim());
            }
          } else {
            setIsLoadingProjectDetails(false);
            document
              .getElementById('inlineProjectEditInputField')
              ?.classList.add('duplicateProjectNameError');
          }
        } else {
          setIsEdit(false);
          setCurrentProjectName('');
        }
      } catch (ex: any) {
        setIsLoadingProjectDetails(false);
        await loggerService.log({
          severity: 'High',
          message: 'Failed to update project name',
          payload: { project_id: +urlParams.projectId! },
        });
      }
    }
  };

  const toggleFavorite = async () => {
    if (userDetails) {
      const userId = userDetails?.user_id;
      const organizationId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!;
      const projectId = projectDetails?.project_id!;

      await projectService.toggleProjectFavorite({
        projectId,
        userId,
        organizationId,
      });
    }
  };

  const handleFilterChange: MenuProps['onClick'] = ({ key }) => {
    if (key === '2') {
      updateFilterValue(0);
    } else if (key === '3') {
      updateFilterValue(1);
    }
  };

  const handleZoomChange: MenuProps['onClick'] = ({ key }) => {
    if (key === '4') {
      updateZoomLevel(0.5);
    } else if (key === '5') {
      updateZoomLevel(1);
    } else if (key === '6') {
      updateZoomLevel(2);
    } else if (key === '1') {
      if (zoomLevelPercentage < 1.5) updateZoomLevel(zoomLevelPercentage * 2);
      else updateZoomLevel(3);
    } else if (key === '2') {
      if (zoomLevelPercentage > 1 / 100)
        updateZoomLevel(zoomLevelPercentage / 2);
      else updateZoomLevel(0.01);
    } else {
      updateFitviewFlag(1);
    }
  };

  const handleEnvChange: MenuProps['onClick'] = ({ key }) => {
    handelQuery(key);
  };
  const handelQuery = (key: any) => {
    setQuery({ env: key });
    setEnvValue(parseInt(key));
  };

  const handleEditEnvClick = async (e: any, envId: number) => {
    e.stopPropagation();
    setEditEnvKey(envId);
    setEnvDropdownOpen(false);
  };

  const handleZoomInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (value) {
      if (value < 1) {
        setZoomLevel(1);
        updateZoomLevel(0.01);
      } else if (value > 300) {
        setZoomLevel(300);
        updateZoomLevel(6);
      } else {
        setZoomLevel(value);
        updateZoomLevel(value / 100);
      }
    }
  };

  const getIconForZoomDropdown = (key: string) => {
    switch (key) {
      case '1':
        return zoomInShortcut;
      case '2':
        return zoomOutShortcut;
      case '3':
        return zoomFitShortcut;
      case '5':
        return zoom100Shortcut;
      default:
        return '';
    }
  };

  let FilterDropDownitems: MenuProps['items'] =
    pncFeedbackSpecificCmsData?.feedback_filter_dropdown_items
      ?.filter((eachItem) => eachItem.key !== filterActiveKey)
      .map((eachItem) => {
        return {
          key: eachItem.key,
          label: (
            <div className="filterDropdownListItem">
              <img
                src={eachItem.key === '1' ? listViewIcon : funnelIcon}
                alt=""
              />
              <span>{eachItem.label}</span>
            </div>
          ),
        };
      });

  let ZoomDropDownitems: MenuProps['items'] =
    pncFeedbackSpecificCmsData?.zoom_dropdown_items?.map((eachItem) => {
      return {
        key: eachItem.key,
        label: (
          <div className="zoomListItem">
            {eachItem.label}
            <img src={getIconForZoomDropdown(eachItem.key)} alt="" />
          </div>
        ),
      };
    });

  let EnvDropDownitems: MenuProps['items'] = envFilterData.map(
    (i: IEnvFilterData) => ({
      key: i.environment_id,
      label: (
        <div className="envDropdownListItem">
          <span className="flexCenter">
            <span className="iconSpace">
              {envValue === Number(i.environment_id) && (
                <img src={checkIcon} alt="" />
              )}
            </span>
            <Tooltip title={i.environment_name} color="var(--cs-primary-a)">
              <span>{i.environment_name}</span>
            </Tooltip>
          </span>
          <Tooltip
            title={pncAddEnvSpecificCmsData?.lbl_tooltips?.lbl_edit_env}
            color="var(--cs-primary-a)"
          >
            <span
              className="cmnIcon edit"
              onClick={(e) => handleEditEnvClick(e, i.environment_id)}
            ></span>
          </Tooltip>
        </div>
      ),
      disabled: editEnvKey,
    }),
  );

  return (
    <>
      {contextHolder}
      {contextMsg}
      <div className="contentHeadingPanel pncHeader">
        <div className="CNPScreen">
          <div className="itemNameBlk">
            <span className="sidePanelIcon layers"></span>
            {isEdit &&
            hasPermissions(
              [ERbacPermissions.PROJECT_EDIT],
              projectDetails?.associated_role_id,
            ) &&
            projectDetails?.is_archived === false ? (
              <input
                id="inlineProjectEditInputField"
                className="inlineProjectEditInputFieldClass"
                maxLength={50}
                value={currentProjectName}
                autoFocus
                onChange={(e) => setCurrentProjectName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    (async () => {
                      await changeProjectName();
                    })();
                  }
                }}
                onBlur={() => {
                  (async () => await changeProjectName())();
                }}
              />
            ) : (
              <Fragment>
                {projectDetails?.project_name &&
                projectDetails.project_name.length < 40 ? (
                  <span
                    className="itemName"
                    onClick={() => setIsEdit(true)}
                    onKeyDown={() => {}}
                  >
                    {projectDetails?.project_name}
                  </span>
                ) : (
                  <Tooltip
                    title={projectDetails?.project_name}
                    color={'#2E364C'}
                    placement="bottom"
                  >
                    <span
                      className="itemName"
                      onClick={() => setIsEdit(true)}
                      onKeyDown={() => {}}
                    >
                      {projectDetails?.project_name}
                    </span>
                  </Tooltip>
                )}
                <span
                  onClick={() => {
                    if (projectDetails?.is_archived === false) {
                      toggleFavorite();
                    }
                  }}
                  onKeyDown={() => {}}
                  className={`cmnIcon ${
                    projectDetails?.is_archived === true
                      ? 'archiveIcon'
                      : projectDetails?.is_favorite
                      ? 'isFavorite'
                      : 'star'
                  }`}
                ></span>
                <Dropdown
                  menu={{
                    items: FilterDropDownitems,
                    onClick: handleFilterChange,
                  }}
                  placement="bottomLeft"
                  trigger={['click']}
                  overlayClassName={'overrideAntDropdown'}
                >
                  <span className="cmnIcon blueMore" />
                </Dropdown>
                <Tooltip
                  title={
                    !panOnDrag
                      ? pncAddEnvSpecificCmsData?.lbl_tooltips
                          ?.lbl_disable_fb_mode
                      : pncAddEnvSpecificCmsData?.lbl_tooltips
                          ?.lbl_enable_fb_mode
                  }
                  placement="right"
                  color="#2E364C"
                >
                  <button
                    type="button"
                    disabled={!location.pathname.includes('feedback-board')}
                    className={
                      !panOnDrag ? 'btnStyle1 pcBtn active' : 'btnStyle1 pcBtn'
                    }
                    onClick={() => handleSetPanOnDrag(!panOnDrag)}
                  >
                    <span className="commentBubbleWhiteIcon"></span>
                  </button>
                </Tooltip>
              </Fragment>
            )}
          </div>
          <div className="contentHeadingRightPart">
            {projectDetailsPageSpecificData && (
              <>
                {projectMembers && (
                  <DisplayProjectMember
                    setShowDropdown={setShowDropdown}
                    setShowSettingDropDown={setShowSettingDropDown}
                    pageSpecificationData={projectDetailsPageSpecificData}
                    projectMembers={projectMembers}
                    showProjectMemberDropdown={showProjectMemberDropdown}
                    setShowProjectMemberDropdown={setShowProjectMemberDropdown}
                  />
                )}
              </>
            )}
            <div className="posRelative ddLeftSpace">
              <Dropdown
                menu={{ items: ZoomDropDownitems, onClick: handleZoomChange }}
                placement="bottomLeft"
                trigger={['click']}
                disabled={!location.pathname.includes('feedback-board')}
                dropdownRender={(menu) => (
                  <div className="overrideAntDropdown customAntDropDown">
                    <Space className="dropDownSearchBox">
                      <input
                        type="number"
                        max={300}
                        min={1}
                        value={zoomLevel}
                        onChange={handleZoomInput}
                      />
                    </Space>
                    {React.cloneElement(menu as React.ReactElement)}
                  </div>
                )}
              >
                <button
                  className="hdrDropdownToggle btnStyle1 autoWidthDd"
                  type="button"
                  data-toggle="dropdown"
                >
                  {`${zoomLevel}%`}
                  <span className="material-icons-outlined">expand_more</span>
                </button>
              </Dropdown>
            </div>
            <div className="posRelative ddLeftSpace">
              <Dropdown
                menu={{ items: EnvDropDownitems, onClick: handleEnvChange }}
                placement="bottomLeft"
                trigger={['click']}
                overlayClassName={'overrideAntDropdown envDropdown'}
                disabled={!location.pathname.includes('feedback-board')}
                open={envDropdownOpen}
                onOpenChange={() => setEnvDropdownOpen(!envDropdownOpen)}
                dropdownRender={(menu) => (
                  <>
                    {React.cloneElement(menu as React.ReactElement)}
                    <div
                      className="addEnvironmentButton"
                      onClick={() =>
                        navigate(
                          `/org/${
                            urlParams?.org
                          }/project/${1}/pnc/add-environment`,
                        )
                      }
                    >
                      <span className="iconSpace">
                        <img src={addIcon} alt="" />
                      </span>
                      {pncFeedbackSpecificCmsData?.btn_cta_add_env ||
                        'Add Environment'}
                    </div>
                  </>
                )}
              >
                <button
                  className="hdrDropdownToggle btnStyle1 autoWidthDd pncEnvDropdown"
                  type="button"
                  data-toggle="dropdown"
                >
                  <span>
                    {envFilterData.filter(
                      (i: IEnvFilterData) =>
                        Number(i.environment_id) === envValue,
                    )[0]?.environment_name ||
                      `${
                        pncFeedbackSpecificCmsData?.btn_cta_add_env ||
                        'Add Environment'
                      }`}
                  </span>
                  <span className="material-icons-outlined">expand_more</span>
                </button>
              </Dropdown>
            </div>
            {projectDetailsPageSpecificData && (
              <ProjectSettingsCta
                pageSpecificationData={projectDetailsPageSpecificData}
                showSettingDropDown={showSettingDropDown}
                setShowSettingDropDown={setShowSettingDropDown}
                setShowDropdown={setShowDropdown}
                setShowSidePanel={setShowSidePanel}
                setShowProjectMemberDropdown={setShowProjectMemberDropdown}
                setSidePanelMode={setSidePanelMode}
                setIsLoadingProjectDetails={setIsLoadingProjectDetails}
                getProjectDetails={async () =>
                  await getProjectDetails(urlParams.projectId!)
                }
                socket={socket}
                msg={msg}
                messageData={getPageSpecificData(cmsData, 'archive-project')}
              />
            )}
          </div>
        </div>
      </div>
      {sidePanelMode === 'update' ? (
        <UpdateProject
          pageSpecificDataState={projectUpdatePageSpecificData}
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          getProjectDetails={async () =>
            await getProjectDetails(urlParams.projectId!)
          }
        />
      ) : null}
      {sidePanelMode === 'clone' ? (
        <CloneProject
          pageSpecificDataState={projectUpdatePageSpecificData}
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          getProjectDetails={async () =>
            await getProjectDetails(urlParams.projectId!)
          }
        />
      ) : null}
      {editEnvKey && (
        <EditEnvModal editEnvKey={editEnvKey} setEditEnvKey={setEditEnvKey} />
      )}
    </>
  );
};

export default PncHeader;
