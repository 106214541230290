import { useAuth0 } from "@auth0/auth0-react";
import { ErrorPage } from "components/pages";
import ErrorBoundary from "components/sharedComponents/Error/ErrorBoundary";
import { useSearchParams } from "react-router-dom";
import AuthService from "services/Auth0/authService";
const authService = AuthService.getInstance();

const Auth0Check: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [searchParams] = useSearchParams();

  if (searchParams.get("error") && searchParams.get("error_description")) {
    return (
      <ErrorPage
        isFromLogin={true}
        error={searchParams.get("error")}
        errorDescription={searchParams.get("error_description")}
      />
    );
  }

  if (!isLoading && !isAuthenticated) {
    if (authService.getCurrentOrganization()) {
      authService.clearCurrentOrganization();
    }
  }

  authService.setAccessTokenSilentlyFunction(getAccessTokenSilently);

  return (
    <ErrorBoundary fallback={<ErrorPage error={""} errorDescription={""} />}>
      {children}
    </ErrorBoundary>
  );
};

export default Auth0Check;
