import React, { useMemo, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const getContrastColor = (bgColor: string): string => {
    // Function to calculate contrast color based on background color
    const getLuminance = (color: string) => {
        // Function to calculate luminance
        color = color.substring(1); // remove #
        let rgb = parseInt(color, 16); // convert to decimal
        let r = (rgb >> 16) & 0xff; // extract red
        let g = (rgb >> 8) & 0xff; // extract green
        let b = (rgb >> 0) & 0xff; // extract blue
        r /= 255; g /= 255; b /= 255; // normalize to 0-1 range
        r = (r <= 0.03928) ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
        g = (g <= 0.03928) ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
        b = (b <= 0.03928) ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
        return 0.2126 * r + 0.7152 * g + 0.0722 * b; // calculate luminance
    };

    const luminance = getLuminance(bgColor);
    return luminance > 0.5 ? '#000000' : '#ffffff'; // return black or white based on background luminance
};

const OptionContainer: React.FC<{ options: any, setOptions: React.Dispatch<React.SetStateAction<any>> }> = ({ options, setOptions }) => {
    const itemsMap = useMemo(() => {
        const map: any = {}

        options.forEach((option: any, index: number) => {
            map[option.id] = {
                ...option,
                index
            }
        })

        return map;
    }, [options])

    const handleInputChange = (e: any, id: number, type: 'color' | 'text') => {
        const value = e.target.value;

        const option = itemsMap[id];
        let tempOptions = [...options]

        if (type === 'text') {
            option.value = value;
        } else if (type === 'color') {
            option.color = value
        }

        tempOptions[option.index] = option;

        setOptions(tempOptions)

    }

    const addMoreOptions = () => {
        setOptions([...options, { value: "", color: "#ffffff", id: Math.random().toString() }])
    }

    const deleteItem = (id: number | string) => {
        setOptions(options.filter((option: any) => option.id !== id))
    }

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedOptions = [...options];
        const [removed] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, removed);

        setOptions(reorderedOptions);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} >
            <div>
                <button className='btnStyle1' onClick={addMoreOptions}>Add</button>
            </div>
            <Droppable droppableId='droppable_zone'>
                {(provided) =>
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {
                            options.map((item: any, index: number) => (
                                <Draggable draggableId={item.id} key={item.id} index={index} isDragDisabled={options.length < 2}>
                                    {
                                        (provided) =>
                                            <div
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                ref={provided.innerRef}
                                            >
                                                <div style={{ display: 'flex', alignItems: 'center', padding: 10, marginTop: 10 }}>
                                                    {options.length > 1 ? <div><strong><h1>:::</h1></strong></div> : null}
                                                    <div style={{ marginLeft: 10 }}>
                                                        <input type={'color'} value={item.color} style={{ width: 40, height: 40, padding: 4 }} onChange={e => handleInputChange(e, item.id, 'color')} />
                                                    </div>
                                                    <div style={{ marginLeft: 10 }}>
                                                        <input autoFocus type={'text'} defaultValue={item.value} onChange={e => handleInputChange(e, item.id, 'text')} />
                                                    </div>
                                                    {
                                                        options.length > 1 ? <div style={{ marginLeft: 10 }}>
                                                            <button className="btnStyle1" onClick={() => deleteItem(item.id)} >Delete</button>
                                                        </div> : null
                                                    }
                                                    <div style={{ marginLeft: 10, backgroundColor: item.color, borderRadius: 5, padding: 5, display: 'flex' }} >
                                                        <label style={{ color: getContrastColor(item.color) }}>{item.value}</label>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </Draggable>
                            ))
                        }
                        {provided.placeholder}
                    </div>
                }
            </Droppable>
        </DragDropContext>
    )
}

export default OptionContainer