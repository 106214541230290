import React, { useCallback, useState } from 'react';
import { Modal, Button, message, Upload, Slider, Typography } from 'antd';
import Cropper from 'react-easy-crop';
import { RcFile } from 'rc-upload/lib/interface';
import {
  IAvatarOrLogoLabel,
  IUserOnboardingAttributesErrorResponses,
} from 'models/interface';

const UploadAvatar: React.FC<{
  onChange?: Function;
  oldProfilePicturePublicPath?: string | null;
  uploadCtaLbl?: IAvatarOrLogoLabel;
  error_msgs?: IUserOnboardingAttributesErrorResponses;
  readOnly?: boolean;
  editTitle?: string;
}> = ({
  onChange,
  oldProfilePicturePublicPath,
  uploadCtaLbl,
  error_msgs,
  readOnly,
  editTitle,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [oriImageBlob, setOriImageBlob] = useState<any>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [imageFileName, setImageFileName] = useState<string | null>(null);
  const [croppedImageString, setCroppedImageString] = useState<
    string | ArrayBuffer | null
  >(null);

  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  const onCropComplete = useCallback(
    async (_croppedArea: any, _croppedAreaPixels: any) => {
      setCroppedAreaPixels(_croppedAreaPixels);
    },
    [],
  );

  const getCroppedImg = async (imageSrc: string, _crop: any) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    /* setting canvas width & height allows us to resize from the original image resolution */
    canvas.width = 200;
    canvas.height = 200;

    ctx.drawImage(
      image,
      _crop.x,
      _crop.y,
      _crop.width,
      _crop.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const beforeUpload = async (file: RcFile) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(file.name.toLocaleLowerCase())) {
      message.error(error_msgs?.file_extension_error);
      return false;
    }
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize < 2) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', (event: any) => {
        const _loadedImageUrl = event.target.result;
        const image = document.createElement('img');
        image.src = _loadedImageUrl;
        image.addEventListener('load', () => {
          const { width, height } = image;
          if (width >= 200 && height >= 200) {
            // set image width and height to your state here
            setOriImageBlob(_loadedImageUrl);
            setShowEditModal(true);
            setImageFileName(file.name);
          } else {
            message.error(error_msgs?.file_dimensions_error);
          }
        });
      });
    } else {
      message.error(error_msgs?.file_size_error);
    }
    return false;
  };

  const deleteHandler = () => {
    setCroppedImageString(null);
    if (onChange) {
      onChange(null, null);
    }
  };

  return (
    <React.Fragment>
      <div className={`uploadLogoLeft defaultUserLogo`}>
        {!croppedImageString && !oldProfilePicturePublicPath && (
          <div className="userBlkWrap">
            <div className="userAvtr">
              <span className="cmnIcon unassign"></span>
            </div>
          </div>
        )}
        {(oldProfilePicturePublicPath || croppedImageString) && (
          <div className="userBlkWrap">
            <div className="userAvtr">
              <img
                src={
                  croppedImageString
                    ? croppedImageString.toString()
                    : oldProfilePicturePublicPath?.toString()
                }
                alt=""
              />
              {!readOnly ? (
                <div
                  className="deleteProfileImg"
                  onClick={deleteHandler}
                  onKeyDown={() => {}}
                >
                  <span className="deleteBin cmnIcon"></span>
                </div>
              ) : null}
            </div>
          </div>
        )}
        <div className="uploadFileInputDiv">
          <Upload
            beforeUpload={beforeUpload}
            accept={'.png,.PNG,.jpg,.JPG,.jpeg,.JPEG'}
            showUploadList={false}
          >
            {!readOnly ? (
              <Typography.Link className="browseFilesSpan">
                {!croppedImageString && !oldProfilePicturePublicPath
                  ? uploadCtaLbl?.upload
                  : uploadCtaLbl?.update}
              </Typography.Link>
            ) : null}
          </Upload>
        </div>
      </div>

      {showEditModal && (
        <Modal
          title={editTitle}
          className="imageCropperModalWrap"
          width={500}
          closeIcon={<i className="cmnIcon closeIcon2"></i>}
          open={true}
          okButtonProps={{ className: 'btnOk' }}
          okText={uploadCtaLbl?.btn_ok_cta}
          cancelButtonProps={{ className: 'noStyle btnCancel' }}
          cancelText={uploadCtaLbl?.btn_cancel_cta}
          onOk={async () => {
            const croppedImage: any = await getCroppedImg(
              oriImageBlob,
              croppedAreaPixels,
            );

            const reader = new FileReader();
            reader.readAsDataURL(croppedImage);
            reader.onloadend = function () {
              const base64data = reader.result;
              const extension = imageFileName?.split('.').pop();
              setZoom(1);
              setCrop({ x: 0, y: 0 });
              setOriImageBlob(null);
              if (onChange) {
                onChange(base64data, extension);
              }
              setCroppedImageString(base64data);
              setShowEditModal(false);
            };
          }}
          onCancel={() => {
            setZoom(1);
            setCrop({ x: 0, y: 0 });
            setOriImageBlob(null);
            setShowEditModal(false);
          }}
        >
          <div style={{ position: 'relative', width: '100%', height: '50vh' }}>
            {oriImageBlob && (
              <Cropper
                image={oriImageBlob}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>
          <div className="zoomSliderWrap">
            <Button
              className="zoomOutBtnWrap icon-image"
              onClick={() => setZoom(1)}
            >
              <i className="cmnIcon zoomOutBtn"></i>
            </Button>
            <Slider
              className="zoomSlider"
              tooltip={{ open: false }}
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(newValue: number) => setZoom(newValue)}
            />
            <Button
              className="zoomInBtnWrap icon-image"
              onClick={() => setZoom(3)}
            >
              <i className="cmnIcon zoomInBtn"></i>
            </Button>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default UploadAvatar;
