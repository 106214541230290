import { FC, useEffect, useState } from "react";
import { Skeleton } from "antd";
import { dailyInfoStore } from "../../../../../stores/dailyInfo.store";
import { loggerService, projectService } from "../../../../../services";
import {
    useLoaderStore,
    useTaskStatusStore,
    useUserStore,
} from "../../../../../stores";
import {
    ILoaderState,
    ITaskStatus,
    IUserState,
} from "../../../../../models/interface";
import MyTaskListTable from "./MyTaskListTable";
/**
 * FOR REAL TIME NOTIFICATION
 * import io from "socket.io-client"; 

const socket = io(process.env.REACT_APP_SOCKET_URL!, {
    withCredentials: true,
}); */

const AssignedByMe: FC<{
    pageCmsData: any;
    filter: "today" | "overdue" | "all";
}> = ({ pageCmsData, filter }) => {
    const [isFetching, setIsFetching] = useState(true);

    const { userDetails } = useUserStore((state: IUserState) => state);
    const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
    /**
     * FOR REAL TIME NOTIFICATION
     * const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
     */
    const {
        todayTaskIveAssigned,
        updateTodayTaskIveAssigned,
        overdueTaskIveAssigned,
        updateOverdueTaskIveAssigned,
        allTaksAssignedByMe,
        updateAllTaskAssignedByMe,
        resetValue,
    } = dailyInfoStore((state: any) => state);

    const { taskStatusCmsData } = useTaskStatusStore(
        (state: ITaskStatus) => state
    );

    let permissableOrganization: any[] = [];
    userDetails?.organization_info?.map((element) =>
        permissableOrganization.push(element.organization_id)
    );

    const getTaskIveAssigned = async () => {
        let result = null;
        setIsFetching(true);
        if (filter === "all") {
            result = await projectService.getAllTask({
                type: "assigned by me",
            });
        } else {
            result = await projectService.getTaskByTime({
                type: "assigned by me",
                time: filter,
            });
        }

        try {
            switch (filter) {
                case "overdue":
                    updateOverdueTaskIveAssigned(result.data.data);
                    break;
                case "all":
                    updateAllTaskAssignedByMe(result.data.data);
                    break;
                case "today":
                    updateTodayTaskIveAssigned(result.data.data);
                    break;
            }
            setIsFetching(false);
        } catch (e) {
            await loggerService.log({
                severity: "High",
                message: "Failed to fetch task priorities",
                payload: { user_id: +userDetails?.user_id!, time: filter },
            });
        }
    };

    /**
    * FOR REAL TIME NOTIFICATION
    *  const actionOnNotification = (payload: any) => {
        let parsedPayload = JSON.parse(payload);
        if (
            parsedPayload.currentUserId !== userDetails?.user_id &&
            JSON.parse(parsedPayload.eventValue)?.assigner_id ===
                userDetails?.user_id &&
            permissableOrganization?.includes(
                parsedPayload?.currentOrganizationId
            )
        ) {
            openNotification(parsedPayload.currentUserName);
        }
    };
    
    const openNotification = (name: string) => {
        const btn = (
            <Space>
                <Button
                    type="primary"
                    size="small"
                    onClick={async () => {
                        await getTaskIveAssigned();
                        api.destroy();
                    }}
                >
                    {taskStatusCmsData?.lbl_notification_config.action}
                </Button>
            </Space>
        );
        api.info({
            message: taskStatusCmsData?.lbl_notification_config.title,
            description: `${name} ${taskStatusCmsData?.lbl_notification_config.message}`,
            btn,
            closeIcon: <></>,
            placement: "bottomLeft",
            duration: 0,
        });
    }; */

    useEffect(() => {
        getTaskIveAssigned();

        return () => {
            resetValue();
        };
    }, [filter]);

    useEffect(() => {
        if (taskStatusCmsData) {
            setLoaderState("active");
            /**
            * FOR REAL TIME NOTIFICATION
            * socket.on(
               "receiveSimpleTaskUpdate",
               (simpleTaskUpdatePayload: any) => {
                   actionOnNotification(simpleTaskUpdatePayload);
               }
           );*/
        }

        return () => {
            // socket.off("receiveSimpleTaskUpdate"); //NO SONAR
            localStorage.removeItem("currentMessageId");
        };
    }, [taskStatusCmsData]);

    return (
        <>
            {isFetching ? (
                <Skeleton active loading={isFetching} />
            ) : (
                <>
                    {/**
                     * FOR REAL TIME NOTIFICATION
                     * {contextHolder} */}
                    <MyTaskListTable
                        id="due"
                        filter={filter}
                        data={todayTaskIveAssigned}
                        pageCmsData={pageCmsData}
                        // socket={socket}
                    />
                    <MyTaskListTable
                        id="overdue"
                        filter={filter}
                        data={overdueTaskIveAssigned}
                        pageCmsData={pageCmsData}
                        // socket={socket}
                    />
                    <MyTaskListTable
                        id="all"
                        filter={filter}
                        data={allTaksAssignedByMe}
                        pageCmsData={pageCmsData}
                        // socket={socket}
                    />
                </>
            )}
        </>
    );
};

export default AssignedByMe;
