import { IAccordionProps } from 'models/interface/pnc.interface';
import React, { useState } from 'react';

const Accordion: React.FC<IAccordionProps> = ({
  title,
  children,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title ${isOpen ? '' : 'open'}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? '' : 'collapsed'}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
