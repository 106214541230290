import {
  useEffect,
  useRef,
  useState,
} from "react";
import { Tooltip } from "antd";
import { ComponentLoader } from "components/sharedComponents";
import TiptapEditor from "./TiptapEditor";
import cryptoRandomString from "crypto-random-string";

const MemoPad: React.FC<{
  sectionId: string | number;
  pageSpecificationData: any;
  sectionDetails: any;
}> = ({ sectionId, sectionDetails }) => {
  const [isEditingSectionName, setIsEditingSectionName] = useState(false);
  const [sectionToggleState, setSectionToggleState] = useState<
    "expanded" | "collapsed"
  >("expanded");
  const memopadContainerRef = useRef<HTMLDivElement>(null);
  const [tempMemoPadValue, setTempMemoPadValue] = useState<any[]>([]);

  const handleSectionToggle = () => {
    setSectionToggleState((prev) =>
      prev === "collapsed" ? "expanded" : "collapsed"
    );
  };

  useEffect(() => {
    if (!isEditingSectionName) {
      const checkIsDataDoesHaveId = sectionDetails?.memopad_data ? sectionDetails?.memopad_data?.map((e: any) => ({...e, docId: e.docId ?? cryptoRandomString({length: 10, type: 'alphanumeric'})})) : []
      setTempMemoPadValue(checkIsDataDoesHaveId);
    }
  }, [sectionDetails]);

  const updateContentBlock = () => {}

  const moveUpContentBlock = () => {}
  const moveDownContentBlock = () => {}

  const removeContentBlock = () => {}

  const showContent = () => {
    if (sectionId && typeof sectionId === "string") {
      return false;
    }
    return !isEditingSectionName && sectionToggleState === "expanded";
  };

  return (
    <ComponentLoader isLoading={false}>
      <div className="templateSection">
        <div className="firstTopic">
          <div className="taskNameHeadingPane">
            <div className="itemNameBlk">
              <Tooltip
                title="Toggle memopad view"
                color={"#2E364C"}
                placement="bottom"
              >
                <span
                  className={`sidePanelIcon ${
                    sectionToggleState === "expanded"
                      ? "arrow-down"
                      : "arrow-right"
                  }`}
                  color={"#2E364C"}
                  onClick={handleSectionToggle}
                  onKeyDown={() => {}}
                />
              </Tooltip>

              <span
                className="section-text-span"
                onClick={() => {
                  setIsEditingSectionName(true);
                }}
                onKeyDown={() => {}}
              >
                {sectionDetails.section_name}
              </span>
            </div>
          </div>
        </div>
        {showContent() ? (
          <div ref={memopadContainerRef} className="memoPadTipTap">
            {tempMemoPadValue.map((e, i) => (
              <TiptapEditor
                key={e.docId}
                content={e}
                index={i}
                maxIndex={tempMemoPadValue.length - 1}
                onMoveUp={moveUpContentBlock}
                onMoveDown={moveDownContentBlock}
                onRemove={removeContentBlock}
                onUpdate={updateContentBlock}
                templateId={null}
              />
            ))}
          </div>
        ) : null}
      </div>
    </ComponentLoader>
  );
};

export default MemoPad;
