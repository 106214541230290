import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import cryptoRandomString from "crypto-random-string";
import { generateUserAvatar } from "utils/commonFunctions";
import { userInfo } from "os";

interface IMentionUserInfo {
    email: string;
    role_id: number;
    user_id: number;
    inactive: boolean;
    full_name: string | null;
    should_delete: boolean
    profile_picture: string | null;
}

export const MentionList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState<string>("");
  const [filteredMentionList, setFilteredMentionList] = useState<any[]>([]);

  const removeLoggedInUserFromTheMentionList = () => {
    const mentionList = props.items,
      info = props.info;

    const loggedInUser: string | null =
        localStorage.getItem("userDetails") !== null
          ? localStorage.getItem("userDetails")
          : "",
      loggedInUserDetails = JSON.parse(loggedInUser!);

    if (mentionList && mentionList.length > 0) {
      const modifiedItemList: string[] = [];
      mentionList.forEach((itm: string) => {
        const newItm = JSON.parse("{" + itm.split("?{")[1]);
        if (
          newItm.user_id !== loggedInUserDetails.value.user_id &&
          !modifiedItemList.find(
            (el) =>
              JSON.parse("{" + el.split("?{")[1]).user_id === newItm.user_id
          ) &&
          (!newItm.project_ids ||
            (info.projectId && newItm.project_ids.includes(info.projectId)))
        ) {
          modifiedItemList.push(itm);
        }
      });

      setSelectedIndex(modifiedItemList[0]);
      setFilteredMentionList(modifiedItemList);
    }
  };

  const selectItem = (userInfo: string) => {
    if (userInfo) {
      const userName = JSON.parse("{" + userInfo.split("?{")[1]).inactive ? JSON.parse("{" + userInfo.split("?{")[1]).email : userInfo.split("?{")[0];

      setSelectedIndex(userInfo);

      props.command({
        id: JSON.parse("{" + userInfo.split("?{")[1]).user_id,
        label: userName,
      });
    }
  };

  // Logic to handle selection from key press (Commented on #CS23-6435 @29-02-24 Sprint-24)
  /** const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: (event: any) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  })); */

  const getMentionListUserGravatarClassName = (user: IMentionUserInfo) => {
    if (user.inactive) {
      return "userAvtrBlank";
    }
    return generateUserAvatar(user)?.key === "url" ? "userAvtrImg" : "";
  };

  const getMentionListUserGravatarJsx = (user: IMentionUserInfo) => {
    if (user.inactive) {
      return null;
    }
    return generateUserAvatar(user)?.key === "string" ? (
      generateUserAvatar(user)?.value
    ) : (
      <img src={generateUserAvatar(user)?.value} alt="user displayW" />
    );
  };

  useEffect(() => {
    if (props.items) removeLoggedInUserFromTheMentionList();
  }, [props]);

  return (
    <div className="items mentionedPersonInfo">
      {filteredMentionList.length ? (
        filteredMentionList.map((item: string) => {
          return (
            <button
              className={`item ${item === selectedIndex ? "is-selected" : ""}`}
              key={`item_${cryptoRandomString({ length: 5 })}`}
              onClick={() => selectItem(item)}
            >
              <div className="userBlkWrap">
                <span
                  className={`userAvtr ${getMentionListUserGravatarClassName(JSON.parse("{" + item.split("?{")[1]))}`}
                >
                  {getMentionListUserGravatarJsx(JSON.parse("{" + item.split("?{")[1]))}
                </span>
              </div>
              <div className="mentionedPersonNameEmail">
                {JSON.parse("{" + item.split("?{")[1])?.inactive ? null : item.split("?{")[0]}
                <span className="mentionEmail">{`(${
                  JSON.parse("{" + item.split("?{")[1]).email
                })`}</span>
              </div>
            </button>
          );
        })
      ) : (
        <div className="noMemberToMention">No result</div>
      )}
    </div>
  );
});
