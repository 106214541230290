import { IProjectState, ITaskList } from 'models/interface';
import TaskListCard from './TaskListCard';
import AddTask from '../../AddTask';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { useProjectDashboardStore, useUserStore } from 'stores';
import cryptoRandomString from 'crypto-random-string';
import { useEffect, useState } from 'react';

interface ITaskListCardView {
  taskDetails?: ITaskList;
  sectionToggleState: 'collapsed' | 'expanded';
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
  // socket: any;
  currentSection?:
    | {
        sectionId: number;
        popup: 'assignee' | 'dueDate' | 'message';
      }
    | undefined;
  setCurrentSection?: React.Dispatch<
    React.SetStateAction<
      | {
          sectionId: number;
          popup: 'assignee' | 'dueDate' | 'message';
        }
      | undefined
    >
  >;
  setIsTaskEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaskListCardView: React.FC<ITaskListCardView> = ({
  taskDetails,
  setTaskListDetails,
  sectionToggleState,
  // socket,
  currentSection,
  setCurrentSection,
  setIsTaskEmpty,
}) => {
  const [filterData, setFilterData] = useState<any>(taskDetails);
  const { taskListCmsData, projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { userDetails } = useUserStore((state: any) => state);
  const [latestNamedTask, setLatestNamedTask] = useState<{
    id: string;
    name: string;
  }>();

  const getTaskCardsJsx = () => {
    if (taskDetails?.task_list_details?.length) {
      let _taskDetails = taskDetails.task_list_details;
      if (taskDetails?.hide_completed_tasks) {
        _taskDetails = _taskDetails.filter(
          (eachTask) =>
            eachTask.task_status_id !== 3 ||
            typeof eachTask.task_id === 'string',
        );
      }
      if (taskDetails?.show_own_tasks) {
        _taskDetails = _taskDetails.filter(
          (eachTask) =>
            eachTask.assignee_id === userDetails?.user_id ||
            typeof eachTask.task_id === 'string',
        );
      }
      if (_taskDetails.length) {
        return _taskDetails.map((eachTask: any, index: number) => (
          <TaskListCard
            key={eachTask.task_id}
            eachTaskDetail={eachTask}
            sectionId={taskDetails?.section_id}
            setTaskListDetails={setTaskListDetails}
            taskDetails={taskDetails}
            statusDoneId={taskDetails?.status_done_id}
            packId={taskDetails?.pack_id}
            // socket={socket}
            index={index}
            sectionName={taskDetails?.section_name!}
            setListDetails={setFilterData}
            listDetails={filterData}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            latestNamedTask={latestNamedTask}
            setLatestNamedTask={setLatestNamedTask}
            setIsTaskEmpty={setIsTaskEmpty}
          />
        ));
      } else {
        let max =
          _taskDetails.length > 0
            ? _taskDetails?.reduce((prev: any, current: any) =>
                prev?.task_rank > current?.task_rank ? prev : current,
              )?.task_rank
            : 0;
        const newTask = {
          task_id: cryptoRandomString({ length: 5 }),
          task_rank: max + 1,
          task_name: '',
          due_date: '',
          assignee_dp: '',
          assignee_id: null,
          assignee_name: '',
          assignee_email: '',
          task_status_id: 1,
          task_priority_id: 2,
          task_status_name: taskListCmsData?.task_status_options.find(
            (eachOption) => eachOption.status_id === 1,
          )?.status_name,
          task_priority_name: taskListCmsData?.task_priority_options.find(
            (eachOption) => eachOption.priority_id === 2,
          )?.priority_name,
          created_by: userDetails.user_id,
        };

        return _taskDetails.map((eachTask: any, index: number) => (
          <TaskListCard
            key={eachTask.task_id}
            eachTaskDetail={newTask}
            sectionId={taskDetails?.section_id}
            setTaskListDetails={setTaskListDetails}
            taskDetails={taskDetails}
            statusDoneId={taskDetails?.status_done_id}
            packId={taskDetails?.pack_id}
            // socket={socket}
            index={index}
            sectionName={taskDetails?.section_name!}
            setListDetails={setFilterData}
            listDetails={filterData}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            latestNamedTask={latestNamedTask}
            setLatestNamedTask={setLatestNamedTask}
            setIsTaskEmpty={setIsTaskEmpty}
          />
        ));
      }
    }
  };

  return sectionToggleState !== 'collapsed' ? (
    <>
      <ul className="simpleTaskListCardView taskTable">{getTaskCardsJsx()}</ul>
      <Rbac
        allowedPermissions={[ERbacPermissions.PROJECT_SECTION_TASK_CREATE]}
        project_role_id={projectDetails?.associated_role_id}
      >
        {projectDetails?.is_archived === false && (
          <AddTask
            taskDetails={taskDetails}
            setTaskListDetails={setTaskListDetails}
          />
        )}
      </Rbac>
    </>
  ) : (
    <></>
  );
};

export default TaskListCardView;
