import { FC, Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";
import { IGridList, IProjectState } from "models/interface";
import TaskNameInputField from "./TaskNameInputField";
import BlockWrapper from "../Block/BlockWrapper";
import { useProjectDashboardStore } from "stores";

const TaskList: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  updateTask: (taskName: any, taskId: any) => Promise<void>;
  revertChange: (taskId: number, event: string) => void;
  onCustomFieldCellChange: (value: string, cell: any) => void;
}> = ({
  gridListDetails,
  setGridListDetails,
  submitChange,
  updateTask,
  revertChange,
  onCustomFieldCellChange
}) => {
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state
  );

  return (
    <Fragment>
      {gridListDetails?.grid_list_details?.task_details?.map((ele, index) => (
        <Draggable
          key={ele.task_id}
          draggableId={`${ele.task_id}`}
          index={index}
          isDragDisabled={
            gridListDetails.grid_list_details?.task_details?.length === 1 ||
            projectDetails?.is_archived
          }
        >
          {(provided: any) => {
            return (
              <>
                {/* TASK */}
                <tr
                  key={ele.task_id}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className={"taskInnerWrap " + (projectDetails?.is_archived ? "notDraggable" : "")}
                >
                  <td>
                    <TaskNameInputField
                      taskDetails={ele}
                      submitChange={submitChange}
                      updateTask={updateTask}
                      gridListDetails={gridListDetails}
                      setGridListDetails={setGridListDetails}
                      revertChange={revertChange}
                      index={index}
                    />
                  </td>
                  {/* BLOCK mapping */}
                  <BlockWrapper
                    taskIndex={index}
                    gridListDetails={gridListDetails}
                    setGridListDetails={setGridListDetails}
                    onCustomFieldCellChange={onCustomFieldCellChange}
                  />
                </tr>
                {/* BLOCK */}
              </>
            );
          }}
        </Draggable>
      ))}
    </Fragment>
  );
};

export default TaskList;
