import { useEffect, useState } from 'react';
import { useUserStore } from '../../../../stores';
import { getCurrentOrganization } from '../../../../utils/commonFunctions';
import {
  IOrganization,
  IProfileSettingCmsData,
  IProjectsList,
} from '../../../../models/interface';
import { projectService } from '../../../../services/';
import { useLocation, useNavigate } from 'react-router-dom';
import { message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';

const TeamProjects = ({
  lblCms,
  organizationInfo,
}: {
  lblCms: IProfileSettingCmsData | null;
  organizationInfo: IOrganization | null;
}) => {
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const { userDetails } = useUserStore((state: any) => state);
  const [isLoadingProjectList, setIsLoadingProjectList] = useState(true);
  const [projectList, setProjectList] = useState<
    IProjectsList[] | null | undefined
  >([]);
  const [searchText, setSearchText] = useState('');
  const [bProjectList, setBProjectList] = useState<IProjectsList[] | null>([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [isDescending, setIsDescending] = useState<boolean>(false);
  const navigate = useNavigate();

  const getProjectDetails = async () => {
    setIsLoadingProjectList(true);
    try {
      const response = await projectService.getOrgAllProjectlist({
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        isDescending: isDescending,
      });
      if (response?.messageId) {
        setProjectList(response.data?.project_list);
        setBProjectList(response.data?.project_list);
        setIsLoadingProjectList(false);
      }
    } catch (err) {
      setIsLoadingProjectList(false);
    }
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  useEffect(() => {
    getProjectDetails();
  }, [isDescending]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase().trim();
    const filteredData = bProjectList?.filter((record) => {
      const project_name = record.project_name.toLowerCase();
      return project_name.includes(searchTerm);
    });
    setSearchText(e.target.value);
    setProjectList(e.target.value ? filteredData : bProjectList);
  };

  const updateAdminProjectRole = async (
    user_id: number,
    value: number,
    project_id: number,
    created_by: number,
  ) => {
    if (user_id !== created_by) {
      const response = await projectService.updateProjectUserRole({
        org_key: org_key,
        project_id: project_id,
        role_id: value,
      });

      if (response?.messageId === 1) {
        message.info(lblCms?.lbl_role_updated);
        getProjectDetails();
      } else {
        message.error(lblCms?.lbl_error);
      }
    }
  };

  const renderProjecName = (element: IProjectsList) => {
    if (element.is_archived) {
      return (
        <div className="projectName archivedProjectName">
          {element.associated_role_id === 3 ? (
            <a
              onClick={() => {
                navigate(`/org/${org_key}/project/${element.project_id}`);
              }}
              href={`${process.env.REACT_APP_PLATFORM_URL}/org/${org_key}/project/${element.project_id}`}
            >
              {element.project_name}
            </a>
          ) : (
            <a
              style={{
                textDecoration: 'none',
                color: 'inherit',
                cursor: 'initial',
              }}
            >
              {element.project_name}
            </a>
          )}
          <span className="cmnIcon archiveIcon"></span>
        </div>
      );
    }

    return (
      <div className="projectName">
        {element.associated_role_id! ? (
          <a
            onClick={() => {
              navigate(`/org/${org_key}/project/${element.project_id}`);
            }}
            href={`${process.env.REACT_APP_PLATFORM_URL}/org/${org_key}/project/${element.project_id}`}
          >
            {element.project_name}
          </a>
        ) : (
          <a
            style={{
              textDecoration: 'none',
              color: 'inherit',
              cursor: 'initial',
            }}
          >
            {element.project_name}
          </a>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="workspace-member-heading">
        <h4>
          {lblCms?.lbl_projects_in} {organizationInfo?.organization_name}{' '}
        </h4>
        <input
          className="input-search-project"
          type="search"
          placeholder={lblCms?.lbl_search_project}
          onChange={onSearch}
          value={searchText}
        />
      </div>
      <div className="memberInviteTable projectsTable">
        <table aria-describedby="Projects Table">
          <thead>
            <tr>
              <th
                onClick={() => {
                  setIsDescending(!isDescending);
                }}
              >
                <div className="th-div">
                  {lblCms?.lbl_project_name}
                  <span
                    style={{
                      transform: isDescending
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                    }}
                    className="cmnIcon arrow-dropdown"
                  ></span>
                </div>
              </th>
              <th>
                <div className="th-div">
                  {lblCms?.lbl_project_role} <span className="cmnIcon"></span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoadingProjectList ? (
              projectList?.map((e, i) => (
                <tr key={e.project_id}>
                  <td> {renderProjecName(e)} </td>
                  <td>
                    <select
                      name="role"
                      defaultValue={e.associated_role_id!}
                      disabled={e.user_id === Number(e.created_by)}
                      onChange={(evt) =>
                        updateAdminProjectRole(
                          e.user_id,
                          +evt.target.value,
                          e.project_id,
                          Number(e.created_by),
                        )
                      }
                      className={classNames({
                        disabledElement: e.user_id === Number(e.created_by),
                      })}
                    >
                      {lblCms?.project_role.map((el, i) => (
                        <option key={el.role_name} value={el.role_id}>
                          {el.role_name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  style={{ textAlign: 'center', padding: '20px' }}
                  colSpan={4}
                >
                  <Spin indicator={antIcon} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TeamProjects;
