import { FC, Fragment } from "react";
import { Tooltip } from "antd";
import {
  IEmptyProjectDashboardAttributes,
  IProjectMember,
} from "models/interface";
import BrowserStorageService from "services/browserStorage.service";

const UserDetails: FC<{
  projectMember: IProjectMember;
  pageSpecificationData: IEmptyProjectDashboardAttributes;
}> = ({ projectMember, pageSpecificationData }) => {
  const browserStorage = BrowserStorageService.getInstance();

  const userDetails = browserStorage.getUserDetails();

  const checkIfMemberLoggerIn = (projectMember: IProjectMember) =>
    projectMember.user_id === userDetails.user_id;

  const getUserName = (projectMember: IProjectMember) => {
    if (checkIfMemberLoggerIn(projectMember)) {
      if (projectMember.full_name) {
        return `${projectMember.full_name} (${pageSpecificationData.lbl_logged_in_member_suffix})`;
      } else {
        return `(${pageSpecificationData.lbl_logged_in_member_suffix})`;
      }
    } else if (projectMember.full_name) {
      return projectMember.full_name;
    } else {
      return null;
    }
  };

  const getUserNameClassName = (projectMember: IProjectMember) => {
    return projectMember.inactive ? "userAvtrBlankContent" : "userEmail";
  };

  return (
    <Fragment>
      {projectMember.inactive ? (
        <></>
      ) : (
        <div className="userName">{getUserName(projectMember)}</div>
      )}
      <Tooltip title={projectMember.email} placement="right" color={"#2E364C"}>
        <div className={`${getUserNameClassName(projectMember)}`}>
          {projectMember.email}
        </div>
      </Tooltip>
    </Fragment>
  );
};

export default UserDetails;
