class NotificationService {
  // public sendSectionUpdateNotification = (socket: any, payload: any) => {
  //   socket.emit("sectionChannel", JSON.stringify(payload));
  // };

  // public sendSimpleTaskUpdateNotification = (socket: any, payload: any) => {
  //   socket.emit("simpleTaskUpdateChannel", JSON.stringify(payload));
  // };

  // public sendSimpleTaskDeleteNotification = (socket: any, payload: any) => {
  //   socket.emit("simpleTaskDeleteChannel", JSON.stringify(payload));
  // };

  public sendArchiveProjectNotification = (socket: any, payload: any) => {
    socket.emit("projectSettingsChannel", JSON.stringify(payload));
  };
}

const notificationService = new NotificationService();
export default notificationService;
