import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { IProfileSettingCmsData } from "../../../models/interface";
import { useNavigate } from "react-router-dom";
import { useDashboardStore, useUserStore } from "../../../stores";
import {
  debounce,
  removeOverlay,
} from "../../../utils/commonFunctions";
import ListNewMember from "./ListNewMember";
import isEmail from "validator/lib/isEmail";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";

const NewTeamDrawer: FC<{
  handleCloseDrawer: () => void;
  settingLblCms: IProfileSettingCmsData;
  openDrawer: boolean;
}> = ({ handleCloseDrawer, settingLblCms, openDrawer }) => {
  const storeUserDetail = useUserStore((state) => state.userDetails);

  const { setNewTeamValue } = useDashboardStore((state) => state);
  const inputNameRef = useRef<HTMLInputElement>(null);
  const [teamName, setTeamName] = useState<string>("");
  const [listNewMember, setListNewMember] = useState<
    { email: string; roleId: number }[]
  >([
    {
      email: "",
      roleId: 2,
    },
  ]);

  const navigate = useNavigate();

  const addNewMember = () => {
    const tempMember = [...listNewMember];
    tempMember.push({
      email: "",
      roleId: 2,
    });
    setListNewMember(tempMember);
  };
  const removeNewMember = (index: number) => {
    const tempMember = [...listNewMember];
    tempMember.splice(index, 1);
    setListNewMember(tempMember);
  };

  const updateMemberRole = (index: number, roleId: number) => {
    const tempMember = [...listNewMember];
    tempMember[index].roleId = roleId;
    setListNewMember(tempMember);
  };

  const updateMemberEmail = (
    index: number,
    evt: ChangeEvent<HTMLInputElement>
  ) => {
    const tempMember = [...listNewMember];
    tempMember[index].email = evt.target.value;
    setListNewMember(tempMember);
  };

  const createNewTeam = () => {
    //addRemoveOverLay();
    removeOverlay();
    setNewTeamValue({
      teamName,
      teamMember: listNewMember.filter((e) => e.email !== ""),
    });
    document.getElementById("rightSideDrawerCloseIcon")?.click();
    navigate("/user/create-new-team");
  };

  const addMemberCheck = () => {
    if (isEmail(listNewMember[listNewMember.length - 1].email)) {
      addNewMember();
    } else {
      listNewMember.length > 1 && removeNewMember(listNewMember.length - 1);
    }
  };

  const enableAddButton = () => {
    if (listNewMember.length === 1 && !listNewMember[0].email?.length) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (inputNameRef.current && openDrawer) {
      inputNameRef.current.focus();
    }
  }, [inputNameRef, openDrawer]);

  const validateInvitation = () => {
    let teamEmailsArray: any[] = [];
    listNewMember.map((element) => {
      teamEmailsArray.push(element.email);
    });

    if (teamName?.trim()?.length < 4 || teamName?.trim()?.length > 50) {
      return true;
    }

    if (
      listNewMember
        .map((e) => (e.email === "" ? true : isEmail(e.email)))
        .includes(false)
    ) {
      return true;
    }

    if (
      listNewMember.filter((e) => e.email === storeUserDetail?.email).length
    ) {
      return true;
    }

    if (
      teamEmailsArray
        .map((e, i) => {
          return teamEmailsArray.indexOf(e) < i;
        })
        .includes(true)
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    validateInvitation();
    return () => {
      removeOverlay();
    };
  }, []);

  return (
    // <OutsideClickHandler
    //   onOutsideClick={() => {
    //     handleCloseDrawer();
    //   }}
    // >
    <div className="modal-content">
      <div className="modal-header">
        <h3>{settingLblCms?.lbl_create_a_new_team}</h3>
        <span
          id="rightSideDrawerCloseIcon"
          className="material-icons-outlined modal-close"
          onClick={handleCloseDrawer}
          onKeyDown={() => {}}
        >
          close
        </span>
      </div>
      <div className="modal-body">
        <div
          className="projectName inputHolder"
          onClick={() => inputNameRef.current!.focus()}
          onKeyDown={() => {}}
        >
          <div className="teamInputLabel">
            <label>{settingLblCms?.lbl_team_name}</label>
            <Tooltip
              title={`${settingLblCms?.lbl_team_name_help_tooltip_msg}`}
              placement="right"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <input
            autoFocus={true}
            ref={inputNameRef}
            type="text"
            minLength={4}
            maxLength={50}
            defaultValue={teamName}
            onChange={(evt) => setTeamName(evt.target.value)}
            onBlur={(evt) => setTeamName(evt.target.value?.trim())}
            onKeyUp={debounce(() => validateInvitation())}
            className={`${
              !teamName?.trim() ||
              (teamName?.trim()?.length >= 4 && teamName?.trim()?.length <= 50)
                ? ""
                : "fldError"
            }`}
          />
        </div>
        <div id="onClickInput"></div>

        <ul className="memberRoleSection">
          <label>{settingLblCms?.lbl_add_team_member}</label>
          <ListNewMember
            listNewMemberData={listNewMember}
            updateMemberEmail={updateMemberEmail}
            removeNewMember={removeNewMember}
            updateMemberRole={updateMemberRole}
            settingLblCms={settingLblCms}
          />
        </ul>
        {listNewMember.length < 10 ? (
          <div
            className={classNames("addMember", {
              disabledElement: !enableAddButton(),
            })}
          >
            <span
              onClick={addMemberCheck}
              className="addPlusSolid"
              onKeyDown={() => {}}
            />
            <span
              onClick={addMemberCheck}
              className="itemName"
              onKeyDown={() => {}}
            >
              {settingLblCms?.lbl_add_more_member}
            </span>
            <span className="infoText">
              ({settingLblCms?.lbl_maximum_email})
            </span>
          </div>
        ) : null}
      </div>
      <div className="modal-footer">
        <div className="btnSec">
          <button
            disabled={validateInvitation()}
            className={validateInvitation() ? "disabledBtn" : "btnStyle1"}
            onClick={createNewTeam}
          >
            {settingLblCms?.lbl_create_new_team}
          </button>
        </div>
      </div>
    </div>
    //</OutsideClickHandler>
  );
};
export default NewTeamDrawer;
