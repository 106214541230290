import { Input, InputRef, Typography, message } from "antd";
import { ComponentLoader, UploadAvatar } from "components/sharedComponents";
import ImageFor from "models/enums/imageFor";
import {
  IImageUrlParams,
  IProfilePayload,
  ITeamSetupPayload,
  IUserOnboardingAttributes,
  IUserState,
} from "models/interface";
import { useEffect, useRef, useState } from "react";
import { userOnboardingService } from "services";
import { checkArrayEquality, debounce } from "utils/commonFunctions";
import { useUserStore } from "../../../stores";
import BrowserStorageService from "services/browserStorage.service";
import { useAuth0 } from "@auth0/auth0-react";

interface ITeamInfo {
  stepNumber: number;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
  teamDetails: ITeamSetupPayload;
  setTeamDetails: React.Dispatch<React.SetStateAction<ITeamSetupPayload>>;
  oldTeamDetails: ITeamSetupPayload;
  setOldTeamDetails: React.Dispatch<React.SetStateAction<ITeamSetupPayload>>;
  pageCmsData: IUserOnboardingAttributes | null;
  setProfileDetails: React.Dispatch<React.SetStateAction<IProfilePayload>>;
  sendAnalyticsEvent: (eventLabel: string, eventName: string) => boolean;
  isCreatingNewTeam: boolean;
  profileDetails: IProfilePayload;
}

const TeamInfo: React.FC<ITeamInfo> = ({
  stepNumber,
  setStepNumber,
  teamDetails,
  setTeamDetails,
  oldTeamDetails,
  setOldTeamDetails,
  pageCmsData,
  setProfileDetails,
  sendAnalyticsEvent,
  isCreatingNewTeam,
  profileDetails,
}) => {
  const [imagePayload, setImagePayload] = useState<IImageUrlParams>({
    base64Str: "",
    extension: "",
    image_for: ImageFor.ORGANIZATION,
  });

  const [oldImagePayload, setOldImagePayload] = useState<IImageUrlParams>({
    base64Str: "",
    extension: "",
    image_for: ImageFor.ORGANIZATION,
  });
  const { userDetails } = useUserStore((state: IUserState) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [teamRequiredChecksPassed, setTeamRequiredChecksPassed] =
    useState<boolean>();
  const teamNameRef = useRef<InputRef | null>(null);
  const backClickHandler = () => {
    sendAnalyticsEvent("Team Type Selection", "Team Type Selection Started");
    setStepNumber((prev) => prev - 1);
  };
  const browserStorage = BrowserStorageService.getInstance();
  const { logout } = useAuth0();

  const continueClickHandler = async () => {
    if (
      checkArrayEquality(
        Object.values(oldImagePayload),
        Object.values(imagePayload)
      ) &&
      checkArrayEquality(
        Object.values(oldTeamDetails),
        Object.values(teamDetails)
      )
    ) {
      sendAnalyticsEvent(
        "Team Creation/Update",
        "Team Creation/Update Skipped"
      );
      setStepNumber((prev) => prev + 1);
    } else {
      createTeamHandler();
    }
  };

  const createTeamHandler = () => {
    if (isCreatingNewTeam) {
      setStepNumber((prev) => prev + 1);
      setProfileDetails((prev) => ({
        ...prev,
        team_type_id: teamDetails.team_type_id,
      }));
      return;
    }
    setupOrganization();
  };

  const setupOrganization = async () => {
    const _teamDetails = teamDetails;
    try {
      setIsLoading(true);
      if (imagePayload.base64Str?.length && imagePayload.extension?.length) {
        const { data } = await userOnboardingService.saveOrganizationLogo(
          imagePayload
        );
        _teamDetails.organization_logo = data.url;
        setTeamDetails(_teamDetails);
        setOldImagePayload(imagePayload);
      }
      let teamSetupResponse: any;
      teamSetupResponse = await userOnboardingService.setupTeam({
        ..._teamDetails,
        organization_name: teamDetails.organization_name?.trim(),
      });
      if (teamSetupResponse.messageId === 1) {
        sendAnalyticsEvent(
          "Team Creation/Update",
          "Team Creation/Update Finished"
        );

        setStepNumber(teamSetupResponse.data.step_number);
        setOldTeamDetails((prev) => ({
          ...prev,
          auth_org_id: teamSetupResponse.data.auth_org_id,
          org_key: teamSetupResponse.data.org_key,
        }));
        setTeamDetails((prev) => ({
          ...prev,
          auth_org_id: teamSetupResponse.data.auth_org_id,
          org_key: teamSetupResponse.data.org_key,
        }));
        setProfileDetails((prev) => ({
          ...prev,
          team_type_id: teamDetails.team_type_id,
        }));
        sendAnalyticsEvent("Profile Creation", "Profile Creation Started");
      } else {
        message.warning(pageCmsData?.lbl_error_responses?.team_setup_error, 3);
      }
    } catch (error) {
      message.error(pageCmsData?.lbl_error_responses.team_setup_error);
      sendAnalyticsEvent("Team Creation/Update", "Team Creation/Update Error");
    } finally {
      setIsLoading(false);
    }
  };

  const avatarChangeHandler = (base64data: string, extension: string) => {
    if (base64data) {
      setImagePayload((prev) => ({
        ...prev!,
        base64Str: base64data,
        extension: extension,
      }));
      setTeamDetails((prev) => ({
        ...prev,
        organization_logo: base64data.toString(),
        orgImageBase64: base64data.toString(),
        orgImageExtension: extension,
      }));
    } else {
      setImagePayload({
        base64Str: "",
        extension: "",
        image_for: ImageFor.ORGANIZATION,
      });
      setOldImagePayload({
        base64Str: "",
        extension: "",
        image_for: ImageFor.ORGANIZATION,
      });
      setTeamDetails((prev) => ({ ...prev, organization_logo: "" }));
    }
  };

  const teamNameChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamDetails((prev) => ({ ...prev, organization_name: e.target.value }));
  };

  const requiredFieldsCheck = () => {
    if (teamDetails.organization_name?.trim().length < 4) {
      setTeamRequiredChecksPassed(false);
    } else {
      setTeamRequiredChecksPassed(true);
    }
  };

  useEffect(() => {
    (oldTeamDetails.organization_name || teamDetails.organization_name) &&
      requiredFieldsCheck();
  }, [oldTeamDetails]);

  useEffect(() => {
    teamNameRef.current?.focus();
  }, [stepNumber]);

  return (
    <ComponentLoader isLoading={isLoading}>
      <div
        className={`section-content workspace ${
          stepNumber === 1 ? "active" : ""
        }`}
        id="user-onboard-team-info"
      >
        <div>
          <h1>{pageCmsData?.lbl_team_info_header1}</h1>
          <div className="subHeading">
            <h2>{pageCmsData?.lbl_team_info_header2}</h2>
          </div>
          <div className="fldWrap">
            <Input
              ref={teamNameRef}
              autoFocus={true}
              className="teamName"
              onChange={teamNameChangeHandler}
              minLength={4}
              maxLength={50}
              value={teamDetails.organization_name}
              onKeyUp={debounce(() => requiredFieldsCheck())}
              status={teamRequiredChecksPassed === false ? "error" : ""}
            />
            <label htmlFor="email">
              {pageCmsData?.lbl_team_info_team_name}*
            </label>
            {teamRequiredChecksPassed === false && (
              <span className="fldError fldMsg">
                {pageCmsData?.lbl_error_responses.team_name_required_error}
              </span>
            )}
          </div>
          <div className="uploadLogoDiv">
            <UploadAvatar
              uploadCtaLbl={
                pageCmsData?.lbl_team_info_upload_or_update_logo_cta
              } // user onboarding
              oldProfilePicturePublicPath={teamDetails.organization_logo}
              onChange={avatarChangeHandler}
              error_msgs={pageCmsData?.lbl_error_responses}
            />
            <div className="uploadLogoInfo">
              <h2>{pageCmsData?.lbl_team_info_upload_logo_header}</h2>
              <p>{pageCmsData?.dsc_team_info_upload_logo}</p>
            </div>
          </div>
        </div>
        <div>
          <div className="btnDiv">
            <button
              id="btnTeamInfoBack"
              className="backBtn"
              onClick={backClickHandler}
            >
              <span className="sidePanelIcon leftArrow"></span>{" "}
              {pageCmsData?.lbl_back_cta}
            </button>
            <button
              id="btnTeamInfoContinue"
              className={`${
                teamRequiredChecksPassed === true ? "btnStyle3" : "disabledBtn"
              }`}
              onClick={continueClickHandler}
              disabled={!teamRequiredChecksPassed}
            >
              {pageCmsData?.btn_continue}
            </button>
          </div>
          <span className="notUserText">
            Not <b>{userDetails?.email}?</b>{" "}
            <Typography.Link
              onClick={() => {
                localStorage.removeItem("latestPathName");
                localStorage.removeItem("selectedThreadId");
                localStorage.removeItem("currentMessageId");
                localStorage.removeItem("project_members");
                sessionStorage.removeItem("lastLocation");
                browserStorage.clearGlobalData();
                logout({
                  logoutParams: { returnTo: window.location.origin },
                });
              }}
              onKeyDown={() => {}}
            >
              Logout
            </Typography.Link>
          </span>
        </div>
      </div>
    </ComponentLoader>
  );
};

export default TeamInfo;
