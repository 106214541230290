import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { UploadAvatar } from '../../sharedComponents';
import { Input, message } from 'antd';
import {
  ILoaderState,
  IProfileSettingCmsData,
} from '../../../models/interface';

import { useLoaderStore, useUserStore } from '../../../stores';
import { userService } from '../../../services';
import BrowserStorageService from '../../../services/browserStorage.service';
import classNames from 'classnames';
import { useAuth0 } from '@auth0/auth0-react';
import {
  getMessageThreadByMemberObject,
  updateMemberName,
} from 'utils/firebaseCommonFunctions';
import { debounce } from 'utils/commonFunctions';

type NameValidationType = 0 | 1 | 2 | 3;
// 0: No error, 1: required error, 2: min length error, 4: max length error
type NameErrorMessageType = {
  message: string;
  key: NameValidationType;
  value: 'required' | 'min' | 'max';
};

const baseUsernameErrorMessages: NameErrorMessageType[] = [
  { message: 'Name Required', key: 1, value: 'required' },
  { message: 'Minimum 6 characters required', key: 2, value: 'min' },
  { message: 'Maximum 30 characters allowed', key: 3, value: 'max' },
];

const ProfileSetting: FC<{
  isActive: boolean;
  lblCms: IProfileSettingCmsData | null;
}> = ({ isActive, lblCms }) => {
  const { userDetails, setUserDetails } = useUserStore();
  const { logout } = useAuth0();
  const [fullName, setFullName] = useState(userDetails?.full_name ?? '');
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConf, setPasswordConf] = useState('');
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [profilePic, setProfilePic] = useState<string | null>(
    userDetails?.profile_picture ?? null,
  );
  const passwordRef = useRef<HTMLInputElement>(null);
  const cfPasswordRef = useRef<HTMLInputElement>(null);
  const btnSaveRef = useRef<HTMLButtonElement>(null);
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const browserStorage = BrowserStorageService.getInstance();
  const [nameValidatorErrorType, setNameValidatorErrorType] =
    useState<NameValidationType>(0);
  const [usernameErrorMessages, setUsernameErrorMessages] = useState<
    NameErrorMessageType[]
  >(baseUsernameErrorMessages);

  const avatarChangeHandler = async (
    base64data: string | ArrayBuffer,
    extension: string,
  ) => {
    if (base64data) {
      const userTemp: any = {
        ...userDetails,
        profile_picture: base64data.toString(),
      };
      setUserDetails(userTemp);
      await userService
        .uploadUserProfileImage({
          base64Str: base64data,
          extension,
        })
        .then((res) => {
          setProfilePic(res?.data?.url);
          setTimeout(() => {
            handleSaveButton(res?.data?.url);
          }, 500);
        });
    } else {
      updateProfilePic();
      setTimeout(() => {
        handleSaveButton(null);
      }, 500);
    }
  };

  /**delete pic */
  const updateProfilePic = async () => {
    setProfilePic(null);
    const userTemp: any = { ...userDetails, profile_picture: null };
    setUserDetails(userTemp);
    await userService.updateUserProfilePic();
  };

  const handleFullNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setFullName(evt.target.value);
  };

  const nameValidationLogic = () => {
    if (fullName?.trim()?.length === 0 || !fullName) {
      return 1;
    }

    if (fullName?.trim()?.length < 6) {
      return 2;
    }

    if (fullName?.trim()?.length > 30) {
      return 3;
    }

    return 0;
  };

  const requiredFieldsCheck = () => {
    setNameValidatorErrorType(nameValidationLogic());
  };

  const handleSaveButton = async (imageUrl: string | null) => {
    if (nameValidatorErrorType) {
      return;
    }

    if (fullName?.trim() !== userDetails?.full_name) {
      await userService.updateUserProfile({
        full_name: fullName?.trim(),
        profile_pic: imageUrl,
        additional_information: userDetails?.additional_settings,
      });
      const tempUserDetail = userDetails!;
      let targetMemberObj = {
        user_id: +userDetails!.user_id,
        user_name: userDetails!.full_name,
      };
      tempUserDetail.profile_picture = imageUrl;
      tempUserDetail.full_name = fullName?.trim();
      setUserDetails(tempUserDetail);
      browserStorage.setUserDetails({
        ...userDetails,
        full_name: fullName?.trim(),
        profile_picture: imageUrl,
      });
      message.info(lblCms?.lbl_profile_updated_message);
      const responseFireStore = await getMessageThreadByMemberObject(
        targetMemberObj,
      );
      const messageThreadId: string[] = [];

      responseFireStore.forEach((doc: any) => {
        messageThreadId.push(doc.id);
      });

      setFullName(fullName?.trim());

      for (let id of messageThreadId) {
        await updateMemberName(id, {
          user_id: +tempUserDetail.user_id,
          user_name: tempUserDetail.full_name.trim(),
        });
      }
    }
  };

  const saveNewPassword = async () => {
    setLoaderState('active');
    setIsPasswordValid(false);
    setPassword('');
    setPasswordConf('');
    const response = await userService.updateUserPassword({ password });
    if (response.messageId === 1) {
      message.info(lblCms?.lbl_password_updated, 3);
      setTimeout(() => {
        logout({
          logoutParams: { returnTo: window.location.origin },
        });
        window.location.reload();
      }, 2000);
    }
    setLoaderState('inactive');
  };

  useEffect(() => {
    if (password === passwordConf) {
      if (
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,12}$/.test(
          password,
        )
      ) {
        setIsPasswordValid(true);
        return;
      }
    }
    return setIsPasswordValid(false);
  }, [password, passwordConf]);

  useEffect(() => {
    requiredFieldsCheck();
  }, []);
  useEffect(() => {
    const handleTabKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        if (document.activeElement === passwordRef.current) {
          cfPasswordRef?.current?.focus();
        } else if (
          document.activeElement === cfPasswordRef.current &&
          !isPasswordValid
        ) {
          passwordRef?.current?.focus();
        } else if (
          document.activeElement === cfPasswordRef.current &&
          isPasswordValid
        ) {
          btnSaveRef?.current?.focus();
        } else if (document.activeElement === btnSaveRef.current) {
          passwordRef?.current?.focus();
        }
      }
      if (event.key === 'Enter') {
        event.preventDefault();
        if (document.activeElement === btnSaveRef.current && isPasswordValid) {
          saveNewPassword();
        }
      }
    };
    window.addEventListener('keydown', handleTabKeyPress);
    return () => {
      window.removeEventListener('keydown', handleTabKeyPress);
    };
  }, [isPasswordValid]);

  useEffect(() => {
    if (lblCms) {
      const temp = usernameErrorMessages.map((el: NameErrorMessageType) => {
        if (lblCms?.lbl_username_error_message[el.value]) {
          el.message = lblCms?.lbl_username_error_message[el.value];
        }
        return el;
      });

      setUsernameErrorMessages([...temp]);
    }
  }, [lblCms]);

  return (
    <div
      id="secondTab"
      className="tabContent"
      style={{ display: isActive ? 'block' : 'none' }}
    >
      <h4>{lblCms?.lbl_about_you}</h4>
      <p>{lblCms?.lbl_about_you_description}</p>
      <div className="editProfile">
        <UploadAvatar
          uploadCtaLbl={lblCms?.lbl_upload_or_update_avatar} // Profile settings
          onChange={avatarChangeHandler}
          error_msgs={lblCms?.lbl_error_responses}
          oldProfilePicturePublicPath={profilePic}
          editTitle={lblCms?.lbl_edit_image}
        />
        <div className="editableFields">
          <div className="fldWrap">
            <Input
              autoFocus={true}
              onChange={handleFullNameChange}
              onBlur={() => handleSaveButton(profilePic)}
              minLength={6}
              maxLength={30}
              value={fullName}
              onKeyUp={debounce(() => requiredFieldsCheck())}
              status={!Boolean(nameValidatorErrorType) ? '' : 'error'}
            />
            <label htmlFor="name">{lblCms?.lbl_your_name}</label>
          </div>
          {Boolean(nameValidatorErrorType) && (
            <div className="profileNameError">
              {
                usernameErrorMessages?.find(
                  (el: NameErrorMessageType) =>
                    el.key === nameValidatorErrorType,
                )?.message
              }
            </div>
          )}
        </div>
      </div>
      <div className="emailPassChange">
        <div className="leftSec">
          <h4>{lblCms?.lbl_email_address}</h4>
          <p>
            {lblCms?.lbl_your_email_is} <strong>{userDetails?.email}</strong>
          </p>
        </div>
        <div className="loginBlk leftSec">
          <h4>{lblCms?.lbl_password}</h4>
          <div className="fldWrap">
            <input
              ref={passwordRef}
              type={showPassword ? 'text' : 'password'}
              maxLength={12}
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
            <span
              onClick={() => setShowPassword(!showPassword)}
              onKeyDown={() => {}}
              className="material-icons-outlined rightIcon"
            >
              {showPassword ? 'visibility' : 'visibility_off'}
            </span>
            <label htmlFor="password">{lblCms?.lbl_new_password}*</label>
          </div>
          <div className="pwStrength">
            <em className={classNames({ active: /[A-Z]/.test(password) })}>
              ABC
            </em>
            <em className={classNames({ active: /[a-z]/.test(password) })}>
              abc
            </em>
            <em className={classNames({ active: /\d/.test(password) })}>123</em>
            <em
              className={classNames({
                active: /[@_!#$%^&*()<>?\/|}{~:]/.test(password),
              })}
            >
              !@#
            </em>
            <em
              className={classNames({
                active: password.length >= 6 && password.length <= 12,
              })}
            >
              6-12 *
            </em>
          </div>
          <div className="fldWrap">
            <input
              ref={cfPasswordRef}
              type={showPasswordConf ? 'text' : 'password'}
              value={passwordConf}
              onChange={(evt) => setPasswordConf(evt.target.value)}
            />
            <span
              onClick={() => setShowPasswordConf(!showPasswordConf)}
              onKeyDown={() => {}}
              className="material-icons-outlined rightIcon"
            >
              {showPasswordConf ? 'visibility' : 'visibility_off'}
            </span>
            <label htmlFor="password">
              {lblCms?.lbl_confirm_new_password}*
            </label>
          </div>
          {passwordConf.length && password !== passwordConf ? (
            <div className="passwordMismatch">
              {lblCms?.lbl_validate_password_match}
            </div>
          ) : (
            ''
          )}
          <div className="btnWrapLeft">
            <button
              ref={btnSaveRef}
              onClick={saveNewPassword}
              disabled={!isPasswordValid}
              className={classNames({
                disabledBtn: !isPasswordValid,
                btnStyle3: isPasswordValid,
              })}
              aria-label="save changes"
            >
              {lblCms?.lbl_change_password}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetting;
