import { FC } from "react";
import EmptyBlock from "./EmptyBlock";
import Block from "./Block";
import { IGridList } from "models/interface";

const BlockWrapper: FC<{
  sectionDetails: IGridList;
}> = ({ sectionDetails }) => {
  const checkTaskStageList = (sectionDetails: IGridList) => {
    if (!sectionDetails.grid_list_details) {
      return false;
    } else {
      if (
        sectionDetails?.grid_list_details?.task_details?.length === 0 &&
        sectionDetails?.grid_list_details?.stage_details?.length === 0
      ) {
        return false;
      }
      return true;
    }
  };

  return checkTaskStageList(sectionDetails) ? (
    <Block sectionDetails={sectionDetails} />
  ) : (
    <EmptyBlock />
  );
};

export default BlockWrapper;
