import {
  IFirstProjectModalAttributes,
  ILoaderState,
  IProjectState,
  IUserState,
} from "models/interface";
import { useEffect, useState } from "react";
import { useOrganizationRoutes } from "routes/orgRoutes";
import { cmsService, projectService } from "services";
import BrowserStorageService from "services/browserStorage.service";
import {
  useLoaderStore,
  useLocalizationStore,
  useProjectDashboardStore,
  useUserStore,
} from "stores";
import { addOverlay, getPageSpecificData, removeOverlay } from "utils/commonFunctions";
import { NavRoutesEnum } from "models/enums/navRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";

interface IFirstProjectModal {
  setShowFirstProjectModal: React.Dispatch<React.SetStateAction<boolean>>;
  pageSlug?: string[];
  cmsPageName?: string[];
}

const FirstProjectModal: React.FC<IFirstProjectModal> = ({
  setShowFirstProjectModal,
  cmsPageName,
  pageSlug,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userDetails } = useUserStore((state: IUserState) => state);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);
  const closeHandler = () => {
    setShowFirstProjectModal(false);
    removeOverlay();
  };
  const { setSelectedTemplate, setShowSidePanel } = useProjectDashboardStore(
    (state: IProjectState) => state
  );
  const org = useOrganizationRoutes();
  const browserStorage = BrowserStorageService.getInstance();
  const [firstModalCmsData, setFirstModalCmsData] =
    useState<IFirstProjectModalAttributes>();
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const [recommendedTemplates, setRecommendedTemplates] = useState([]);

  const getCmsData = async (pageSlug: string[]) => {
    let tmpPageData: any;
    let pageSpecificData = cmsData.pageSpecificData;
    if (pageSpecificData.length > 0) {
      tmpPageData = getPageSpecificData(cmsData, pageSlug[2]);
      if (!tmpPageData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        tmpPageData = getPageSpecificData(cmsData, pageSlug[2]);
        tmpPageData && setFirstModalCmsData(tmpPageData[0].attributes);
        setLoaderState("inactive");
      } else {
        tmpPageData && setFirstModalCmsData(tmpPageData[0].attributes);
        setLoaderState("inactive");
      }
    } else {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      tmpPageData = getPageSpecificData(cmsData, pageSlug[2]);
      tmpPageData && setFirstModalCmsData(tmpPageData[0].attributes);
      setLoaderState("inactive");
    }
  };

  const renderTemplateName = (name: string) => {
    return <Tooltip title={name}>{name}</Tooltip>;
  };

  const getTemplateSuggestions = () => {
    return recommendedTemplates.map((eachTemplate: any) => (
      <div className="template" key={eachTemplate.template_name}>
        <div className="img-wrap">
          <img
            src={`${process.env.REACT_APP_STORAGE_BASE_URL_FOR_TEMPLATE}${eachTemplate.template_thumbnails[0].thumbnail_url}`}
            alt=""
          />
        </div>
        <div className="contentWrap">
          <h3>{renderTemplateName(eachTemplate.template_name)}</h3>
          <button
            className="btnStyle4"
            onClick={() => {
              setSelectedTemplate(eachTemplate);
              const org_key = location.pathname.split("/")[2];
              navigate("/org/" + org_key + "/project/list");
              setShowFirstProjectModal(false);
              setTimeout(() => setShowSidePanel(true), 2000);
            }}
          >
            {firstModalCmsData?.lbl_btn_use_template}
          </button>
        </div>
      </div>
    ));
  };

  const hasRecommendedTemplates = () => {
    return recommendedTemplates.length > 0;
  };

  useEffect(() => {
    if (pageSlug) {
      setLoaderState("active");
      (async () => {
        await getCmsData(pageSlug);
      })();
      setLoaderState("inactive");
      addOverlay();
    }

    return () => {
      removeOverlay()
    }
  }, []);

  useEffect(() => {
    (async () => {
      const localizationData = browserStorage.getLocalization();
      const org_key = location.pathname.split("/")[2];
      const team_type_id = userDetails?.organization_info?.find(
        (eachOrg) => eachOrg.org_key === org_key
      )?.team_type_id;
      const responsePublishedTemplates =
        await projectService.getPublishedTemplate({
          language_alias: localizationData.language || "en",
          country_alias: localizationData.country || "US",
          org_key,
          work_type: team_type_id,
        });
      if (responsePublishedTemplates.messageId === 1) {
        let recommendedTemplateList: any;
        if (responsePublishedTemplates.data?.length > 3) {
          recommendedTemplateList = responsePublishedTemplates.data
            .sort((a: any, b: any) => {
              return (
                new Date(b.created_on).getTime() -
                new Date(a.created_on).getTime()
              );
            })
            .slice(0, 3);
        } else if (responsePublishedTemplates.data?.length === 0) {
          recommendedTemplateList = [];
        } else {
          recommendedTemplateList = responsePublishedTemplates.data.sort(
            (a: any, b: any) => a - b
          );
        }
        setRecommendedTemplates(recommendedTemplateList || []);
        
      }
    })();
  }, []);

  getTemplateSuggestions();

  return ( 
    <div
      className={`newUserDefault ${
        !hasRecommendedTemplates() ? "noTemplateNewUserDefault" : ""
      }`}
      id="newUserDefaultID"
    >
      <div className="modalContainer">
        <span
          className="cmnIcon closeIcon2"
          id="newUserDefaultClose"
          onClick={closeHandler}
          onKeyDown={() => {}}
        ></span>
        <div className="textContent text-center">
          <h2>{firstModalCmsData?.lbl_modal_header}</h2>
          {hasRecommendedTemplates() ? (
            <p>{firstModalCmsData?.lbl_modal_desc}</p>
          ) : null}
        </div>
        {hasRecommendedTemplates() ? (
          <>
            <div className="templateWrapper">{getTemplateSuggestions()}</div>
            <div className="orSeparator">
              <span>OR</span>
            </div>
          </>
        ) : null}

        <div className="optionCreateCustom">
          <button
            className="btnStyle4"
            onClick={() => {
              removeOverlay();
              setTimeout(() => setShowSidePanel(true), 2000);
              navigate(
                `${org.organizationBaseRouteUrl}/${NavRoutesEnum.DASHBOARD_PROJECT_LIST}`
              );
            }}
          >
            {firstModalCmsData?.lbl_btn_create_custom_project}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FirstProjectModal;
