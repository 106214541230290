import { ConstantRowTypeEnum } from "models/enums/constants";
import { IAssosiatedProjectInfo, IProjectFolderDetails, IProjectList } from "models/interface";

export const generateNewListFromCreateProject = (data: any) => {

    let newList: IProjectFolderDetails[] = [];
    let tempList: IProjectFolderDetails[] = [];
    let index: number = data.project_details[0].project_id;

    if (index) {
        tempList = generateNewListForSelectedProjectInFolderProjectList(index, data.project_folder_list)
    }

    tempList.forEach((ele: IProjectFolderDetails) => {
        if (ele.row_type === ConstantRowTypeEnum.PROJECT) {
            newList.push(ele)
        } else {
            if (ele.assosiated_project_info.length > 0) {
                let item = ele.assosiated_project_info.filter((ele) => ele.project_id === data.project_details[0].project_id)
                if (item.length > 0) {
                    newList.push({
                        ...ele,
                        row_state: true
                    })
                } else {
                    newList.push(ele)
                }
            } else {
                newList.push(ele)
            }
        }
    });

    return newList;
}

export const generateNewListFromCreateFolder = (data: any, folderProjectList: IProjectFolderDetails[]) => {

    let newFolderList: IProjectFolderDetails[] = data;
    let targetIndex: number[] = [];
    let targetIndexForExpandedFolder: number[] = [];

    let targets = folderProjectList.filter((ele: IProjectFolderDetails) => ele.is_duplicate === true);

    let index: number | undefined = getSelectedProjectedId(folderProjectList);

    if (index) {
        newFolderList = generateNewListForSelectedProjectInFolderProjectList(index, data)
    }

    folderProjectList.forEach((element: IProjectFolderDetails, index: number) => {
        if (element.is_duplicate) { targetIndex.push(index) }
        if (element.row_state && element.row_type === ConstantRowTypeEnum.FOLDER) { targetIndexForExpandedFolder.push(element.row_id) }
    });

    targetIndex.forEach((ele, index) => {
        newFolderList.splice(ele, 0, targets[index])
    })

    return newFolderList.map((ele) => {
        if (targetIndexForExpandedFolder.includes(ele.row_id)) {
            return { ...ele, row_state: true }
        } else {
            return ele
        }
    })
}

export const generateNewListForSelectedProjectInProjectList = (id: number, projectList: IProjectList[]) => {

    let updatedProjectList = [...projectList]

    return updatedProjectList.map((ele: IProjectList) => {
        if (ele.project_id === id) {
            return { ...ele, is_selected: true }
        } else {
            return { ...ele, is_selected: false }
        }
    })
}

export const generateNewListForSelectedProjectInFolderProjectList = (id: number, folderProjectList: IProjectFolderDetails[]) => {
    let updatedFolderProjectList = [...folderProjectList]

    return updatedFolderProjectList.map((ele: IProjectFolderDetails) => {
        if (ele.row_type === ConstantRowTypeEnum.PROJECT) {
            if (ele.row_id === id) {
                return { ...ele, is_selected: true }
            } else {
                return { ...ele, is_selected: false }
            }
        } else {
            let updatedAssosiatedProjectList = ele.assosiated_project_info.map((ele: IAssosiatedProjectInfo) => {
                if (ele.project_id === id) {
                    return { ...ele, is_selected: true }
                } else {
                    return { ...ele, is_selected: false }
                }
            })
            return { ...ele, assosiated_project_info: updatedAssosiatedProjectList }
        }
    })
}

export const generateNewProjectList = (data: any) => {

    let newList: IProjectList[] = [];
    let target = data.project_details[0]
    let newProjectList = [...data.project_list];

    let id = newProjectList.findIndex((ele) => ele.project_id === target.project_id);

    if (id < 0) {

        newList = newProjectList.map((ele: IProjectList) => {
            return { ...ele, is_selected: false }
        })

        target.is_selected = true

        newList.unshift(target)
    } else {
        newList = newProjectList.map((ele: IProjectList) => {
            if (ele?.project_id === target.project_id) {
                return { ...ele, is_selected: true }
            } else {
                return { ...ele, is_selected: false }
            }
        })
    }

    return newList

}

const getSelectedProjectedId = (folderProjectList: IProjectFolderDetails[]) => {

    let index: number | undefined;

    folderProjectList.forEach((ele) => {
        if (ele.row_type === ConstantRowTypeEnum.PROJECT && ele?.is_selected === true) {
            index = ele?.row_id;
        } else {
            if (ele.row_type === ConstantRowTypeEnum.FOLDER && !ele?.is_new) {
                for (const eleIn of ele.assosiated_project_info) {
                    if (eleIn?.is_selected) {
                        index = eleIn?.project_id
                    }
                }
            }
        }
    });

    return index

}

export const updateExistingList = (folderProjectList: IProjectFolderDetails[]) => {
    return folderProjectList.map((ele: IProjectFolderDetails) => {
        if (ele.row_type === ConstantRowTypeEnum.PROJECT) {
            return { ...ele, is_selected: false }
        } else {
            let assosiatedProjectInfo = ele.assosiated_project_info.map((eleIn: IAssosiatedProjectInfo) => {
                return { ...eleIn, is_selected: false }
            });
            return { ...ele, assosiated_project_info: assosiatedProjectInfo }
        }
    })
}