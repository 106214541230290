import Drop from './DragDropComponent/Drop';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useState } from 'react';
import styled from 'styled-components';
import { initialData } from './Data';
import DDColumn from './DragDropComponent/Column';
import { addTask, deleteTask, onChange } from './DragDropComponent/utils';

const CSS = styled.div``;

const TaskCalendarGrid = () => {
  const [data, setData] = useState(initialData);

  const onDragEnd = (res: DropResult) => {
    const { source, destination, draggableId } = res;
    if (!destination) return;
    if (onChange(source, destination)) return;
    if (res.type === 'TASK') {
      let newData = deleteTask(data, source);
      newData = addTask(newData, destination, draggableId);
      setData(newData);
    }
  };

  return (
    <div className="calendarGrid">
      <DragDropContext onDragEnd={onDragEnd}>
        <Drop
          className="column-content"
          droppableId="all-columns"
          type="COLUMN"
          direction="horizontal"
        >
          {data.columnOrder.map((columnId, index) => {
            const column = data.columns[columnId];
            const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);
            return <DDColumn column={column} tasks={tasks} key={columnId} />;
          })}
        </Drop>
      </DragDropContext>
    </div>
  );
};

export default TaskCalendarGrid;
