import { Select, Tooltip } from 'antd';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { IProjectState } from 'models/interface';
import React, { useEffect, useState } from 'react';
import { useProjectDashboardStore } from 'stores';
import { getContrastColor } from 'utils/commonFunctions';

interface IPriorityDropdownProps {
  options: any[];
  value: string;
  onChange: (value: any) => void;
  onSelect: (value: any) => void;
  disabled?: boolean;
}

const PriorityDropdown = (props: IPriorityDropdownProps) => {
  const { options, value, onChange, onSelect, disabled = false } = props;

  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const [isEdit, setIsEdit] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectRef = React.useRef<any>(null);

  const currentValue = options.find((i) => i.value === value) ?? {};
  const color = currentValue.color ?? '';
  const { hasPermissions } = useRbac();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        !isDropdownOpen
      ) {
        setIsEdit(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectRef, isDropdownOpen]);
  return (
    <>
      {isEdit || !value ? (
        hasPermissions(
          [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
          projectDetails?.associated_role_id,
        ) ? (
          <div id="selectWrap" ref={selectRef}>
            <Select
              style={{
                width: '100%',
              }}
              disabled={disabled}
              options={options}
              popupClassName="customSelect"
              value={value}
              onChange={(e) => {
                onChange(e);
                setIsEdit(false);
              }}
              onSelect={onSelect}
              labelRender={(option) => {
                return (
                  <span style={{ background: option.value }}>
                    {option.label}
                  </span>
                );
              }}
              virtual={false}
              optionRender={(option, { index }) => {
                const color = options[index].color;
                return (
                  <Tooltip
                    title={option.value}
                    placement={'left'}
                    color={'#2E364C'}
                  >
                    <div
                      className="customOption"
                      key={index}
                      style={{
                        background: color,
                        color: getContrastColor(color),
                      }}
                      title=""
                    >
                      {option.value}
                    </div>
                  </Tooltip>
                );
              }}
              onDropdownVisibleChange={(open) => {
                setIsDropdownOpen(open);
                if (!open) setIsEdit(false);
              }}
            />
          </div>
        ) : null
      ) : (
        <div
          //   onClick={() => handleToggleEdit(i.id, record.task_id, i.mapping_id)}
          onClick={() => {
            hasPermissions(
              [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
              projectDetails?.associated_role_id,
            ) &&
              !disabled &&
              setIsEdit(true);
          }}
          className="statusCellValue"
          style={{
            background: color,
            color: getContrastColor(color),
          }}
        >
          <Tooltip title={value} placement="left" color={'#2E364C'}>
            {currentValue.value}
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default PriorityDropdown;
