import { FC, useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  IProjectCreationAttributes,
  IProjectSections,
  IProjectTemplate,
} from "models/interface";
import TaskList from "./TemplateSections/TaskList/TaskList";
import GridList from "./TemplateSections/GridList/GridList";
import MemoPad from "./TemplateSections/MemoPad/MemoPad";
import marketingBannerCategory from "../../../../assets/images/marketing-site-template-banner.png";

const TemplateDetailWrapper: FC<{
  projectListPageSpecificDataState: IProjectCreationAttributes | null;
  setShowSidePanel: (showSidePanel: boolean) => void;
  industries: any[];
  selectedIndustry: number | null;
  handleClickIndustry: (industryId: number) => void;
  selectedTemplate: any;
  setSelectedTemplate: (template: any) => void;
}> = ({
  projectListPageSpecificDataState,
  setShowSidePanel,
  industries,
  selectedIndustry,
  handleClickIndustry,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    divRef.current?.scrollIntoView({
      block:'end',
      behavior: 'instant'
    })
  }, [])
  const getSectionsJsxByType = (
    eachSection: IProjectSections,
    templateDetails: IProjectTemplate
  ) => {
    switch (eachSection.section_type_id) {
      case 1:
        return (
          <TaskList
            key={eachSection.section_id}
            sectionId={eachSection.section_id}
            sectionDetails={eachSection}
            templateDetails={templateDetails}
            // socket={null}
          />
        );
      case 2:
        return (
          <GridList
            key={eachSection.section_id}
            sectionDetails={eachSection}
            templateDetails={templateDetails}
            // socket={null}
          />
        );
      case 3:
        return (
          <MemoPad
            key={eachSection.section_id}
            sectionId={eachSection.section_id}
            pageSpecificationData={eachSection}
            sectionDetails={eachSection}
            // socket={null}
          />
        );
    }
  };

  return (
    <>
      <div className="projectTemplateDetailsBanner" ref={divRef}>
        <img src={selectedTemplate.banner
          ? `${process.env.REACT_APP_STORAGE_BASE_URL_FOR_TEMPLATE}${selectedTemplate.banner}`
          : marketingBannerCategory} alt="" />
        <div className="textElement">
          <h2>{selectedTemplate.banner_title}</h2>
          <p>{selectedTemplate.banner_description}</p>
        </div>
      </div>
      <div
        className="aboutTemplate"
        dangerouslySetInnerHTML={{
          __html: selectedTemplate.template_description,
        }}
      />
      {selectedTemplate?.tags?.length > 0 && (
        <div className="recommendation">
          <strong>{projectListPageSpecificDataState?.txt_recommend}</strong>
          <div className="tags">
            {selectedTemplate.tags.map((tag: any) => (
              <span key={tag} className="customTag">{tag}</span>
            ))}
          </div>
        </div>
      )}
      <h3 className="templateDetailsTemplateName">
        {selectedTemplate.template_name}
      </h3>
        {selectedTemplate.sections?.map((section: IProjectSections) =>
          getSectionsJsxByType(section, selectedTemplate)
        )}
    </>
  );
};

export default TemplateDetailWrapper;
