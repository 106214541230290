import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';

import { IAssosiatedProjectInfo, IProjectList } from 'models/interface';
import { useDashboardStore, useUserStore } from 'stores';
import {
  generateNewListForSelectedProjectInFolderProjectList,
  generateNewListForSelectedProjectInProjectList,
} from 'utils/generateNewList';
import { getCurrentOrganization } from 'utils/commonFunctions';
import { projectService } from 'services';

interface IAssociatedProjectInfoComponent {
  associatedProjectInfo: IAssosiatedProjectInfo[];
  row_id?: number;
}

export const AssociatedProjectInfo: FC<IAssociatedProjectInfoComponent> = ({
  associatedProjectInfo,
  row_id,
}) => {
  const navigate = useNavigate();
  const {
    projectList,
    setProjectList,
    folderProjectList,
    setFolderProjectList,
  } = useDashboardStore((state: any) => state);
  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const navigateToProjectDetails = (row: IAssosiatedProjectInfo) => {
    let newProjectList;
    let projectIndex = projectList.findIndex(
      (ele: IProjectList) => ele.project_id === row.project_id,
    );
    if (projectIndex < 0) {
      let newProjectList = [...projectList];

      let rowNew = {
        is_favorite: row.is_favorite,
        project_id: row.project_id,
        project_name: row.project_name,
        project_rank: row.project_rank,
      };

      if (projectList.length >= 4) {
        newProjectList.splice(0, 1, rowNew);
      } else {
        newProjectList.unshift(rowNew);
      }

      newProjectList = generateNewListForSelectedProjectInProjectList(
        row.project_id,
        newProjectList,
      );
      setProjectList(newProjectList);
    } else {
      newProjectList = generateNewListForSelectedProjectInProjectList(
        row.project_id,
        projectList,
      );
      setProjectList(newProjectList);
    }

    let updatedProjectFolderList =
      generateNewListForSelectedProjectInFolderProjectList(
        row.project_id,
        folderProjectList,
      );
    setFolderProjectList(updatedProjectFolderList);
    document.getElementById('rightSideDrawerCloseIcon')?.click();
    let route = `/org/${org_key}/project/${row.project_id}`;
    navigate(route);
  };

  const displayProjectName = (projectName: string) => projectName.length <= 25;

  const toggleFavorite = async (item: IAssosiatedProjectInfo) => {
    if (userDetails) {
      const userId = userDetails?.user_id;
      const organizationId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!;
      const projectId = item.project_id;

      await projectService.toggleProjectFavorite({
        projectId,
        userId,
        organizationId,
      });
    }
  };

  return (
    <ul>
      {associatedProjectInfo.map((ele: IAssosiatedProjectInfo) => (
        <li
          key={ele?.project_id}
          className={
            location.pathname
              .split('/')
              .lastIndexOf(ele?.project_id?.toString()) > 0
              ? 'active-item'
              : ''
          }
        >
          <div className="itemNameBlk">
            <span
              className="sidePanelIcon layers"
              onClick={() => navigateToProjectDetails(ele)}
              onKeyDown={() => {}}
            ></span>

            {displayProjectName(ele.project_name) ? (
              <span
                className="itemName"
                onClick={() => navigateToProjectDetails(ele)}
                onKeyDown={() => {}}
              >
                {ele.project_name}
              </span>
            ) : (
              <Tooltip
                title={ele.project_name}
                color={'#2E364C'}
                placement="bottom"
              >
                <span
                  className="itemName"
                  onClick={() => navigateToProjectDetails(ele)}
                  onKeyDown={() => {}}
                >
                  {ele.project_name}
                </span>
              </Tooltip>
            )}

            <span
              onClick={() => toggleFavorite(ele)}
              onKeyDown={() => {}}
              className={`sidePanelIcon favStar ${
                ele?.is_favorite ? 'isFavorite' : 'unfavorited'
              }`}
            ></span>
          </div>
        </li>
      ))}
    </ul>
  );
};
