import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  apiService,
  cmsService,
  dashboardService,
  loggerService,
} from '../../../services';
import {
  getCurrentOrganization,
  getPageSpecificData,
  removeOverlay,
} from '../../../utils/commonFunctions';
import BrowserStorageService from 'services/browserStorage.service';
import {
  IDashboardApiResponse,
  ILoaderState,
  IProjectFolderDetails,
  IProjectList,
  IProjectState,
  ITaskStatus,
  IUserState,
} from 'models/interface';
import {
  useDashboardStore,
  useLoaderStore,
  useLocalizationStore,
  useProjectDashboardStore,
  useTaskStatusStore,
  useUserStore,
} from 'stores';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import FirstProjectModal from './FirstProjectModal';
import { Rbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import MyTasks from './NewDashboard/MyTasks';
import { useUpdateMessagingCMS } from 'components/sharedComponents/hooks';
import OutsideClickHandler from 'components/sharedComponents/OutsideClickHandler';

const DashboardPage: FC<{ pageSlug?: string[]; cmsPageName?: string[] }> = ({
  pageSlug,
  cmsPageName,
}) => {
  const browserStorage = BrowserStorageService.getInstance();

  const navigate = useNavigate(),
    location = useLocation();

  const org_key = location.pathname.split('/')[2];

  const { getAccessTokenSilently } = useAuth0();

  const [pageCmsData, setPageCmsData] = useState<any>(null),
    [showFirstProjectModal, setShowFirstProjectModal] = useState(false),
    [isGlobalStateUpdated, setIsGlobalStateUpdated] = useState<boolean>(false);

  const { setMessagingCmsData, setTaskListCmsData } = useProjectDashboardStore(
      (state: IProjectState) => state,
    ),
    { userDetails } = useUserStore((state: IUserState) => state),
    { setLoaderState } = useLoaderStore((state: ILoaderState) => state),
    { folderProjectList, setFolderProjectList, setProjectList } =
      useDashboardStore((state: any) => state),
    { cmsData, setCmsData } = useLocalizationStore((state: any) => state);
  useUpdateMessagingCMS(isGlobalStateUpdated);

  const currentOrganization =
    userDetails?.organization_info &&
    getCurrentOrganization(org_key, userDetails?.organization_info);

  const { setTaskStatusCmsData, fetchTaskStatusMaster } = useTaskStatusStore(
    (state: ITaskStatus) => state,
  );

  useEffect(() => {
    if (!userDetails?.additional_settings?.onboarding_complete) {
      navigate(`${NavRoutesEnum.USER_ONBOARDING}`);
    }
    apiService.setTokenGenerator(getAccessTokenSilently);

    if (pageSlug) {
      setLoaderState('active');
      (async () => {
        await getCmsData(pageSlug);
        setIsGlobalStateUpdated(true);
        if (folderProjectList.length === 0) {
          await fetchFolderProjectList();
        }
      })();
      fetchTaskStatusMaster();
      setLoaderState('inactive');
    }
  }, []);

  const getCmsData = async (pageSlug: string[]) => {
    let tmpPageData: any;
    let messagingCmsData: any;
    let taskStatusCmsData: any;
    let taskListCmsData: any;
    let pageSpecificData = cmsData.pageSpecificData;
    if (pageSpecificData.length > 0) {
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      messagingCmsData = getPageSpecificData(cmsData, pageSlug[3]);
      taskListCmsData = getPageSpecificData(cmsData, pageSlug[5]);
      if (!tmpPageData || !messagingCmsData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug,
        );
        tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
        tmpPageData && setPageCmsData(tmpPageData[0].attributes);
        messagingCmsData = getPageSpecificData(cmsData, pageSlug[3]);
        messagingCmsData &&
          setMessagingCmsData(messagingCmsData[0]?.attributes);
        taskStatusCmsData = getPageSpecificData(cmsData, pageSlug[4]);
        taskStatusCmsData &&
          setTaskStatusCmsData(taskStatusCmsData[0]?.attributes);
        taskListCmsData = getPageSpecificData(cmsData, pageSlug[5]);
        taskListCmsData && setTaskListCmsData(taskListCmsData[0]?.attributes);
        setLoaderState('inactive');
      } else {
        tmpPageData && setPageCmsData(tmpPageData[0].attributes);
        messagingCmsData &&
          setMessagingCmsData(messagingCmsData[0]?.attributes);
        taskListCmsData && setTaskListCmsData(taskListCmsData[0]?.attributes);
        setLoaderState('inactive');
      }
    } else {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      tmpPageData && setPageCmsData(tmpPageData[0]?.attributes);
      messagingCmsData = getPageSpecificData(cmsData, pageSlug[3]);
      messagingCmsData && setMessagingCmsData(messagingCmsData[0]?.attributes);
      taskStatusCmsData = getPageSpecificData(cmsData, pageSlug[4]);
      setTaskStatusCmsData(taskStatusCmsData[0]?.attributes);
      setLoaderState('inactive');
    }
  };

  const fetchFolderProjectList = async () => {
    try {
      let userDetails = browserStorage.getUserDetails();
      let payload = {
        organizationId: currentOrganization
          ? currentOrganization.organization_id
          : getCurrentOrganization(org_key, userDetails?.organization_info)
              ?.organization_id!,
        userId: userDetails.user_id,
      };

      let response: IDashboardApiResponse =
        await dashboardService.getProjectFolder(payload);

      let list: IProjectFolderDetails[] = response.data.project_folder_list;
      let projectList: IProjectList[] = response.data.project_list;

      if (list) {
        setFolderProjectList(list);
      }

      if (projectList) {
        setProjectList(projectList);
      }
      if (list.length === 2 && list[0].row_id === -1 && list[1].row_id === -2) {
        setShowFirstProjectModal(true);
      }
    } catch (err) {
      await loggerService.log({
        severity: 'High',
        message: `Folder list get action failed`,
        payload: { ApiResponse: err },
      });
    }
  };

  useEffect(() => {}, [folderProjectList]);

  return (
    <>
      <div className="mainContent">
        <div className="contentHeadingPanel">
          <h2>{pageCmsData?.lbl_menu_dashboard}</h2>
        </div>
        <div className="dashboardContentWrap createNewSecMainContent">
          <MyTasks pageCmsData={pageCmsData} />
        </div>
      </div>
      <Rbac allowedPermissions={[ERbacPermissions.PROJECT_CREATE]}>
        {showFirstProjectModal && (
          <OutsideClickHandler
            onOutsideClick={() => {
              setShowFirstProjectModal(false);
              removeOverlay();
            }}
          >
            <FirstProjectModal
              setShowFirstProjectModal={setShowFirstProjectModal}
              pageSlug={pageSlug}
              cmsPageName={cmsPageName}
            />
          </OutsideClickHandler>
        )}
      </Rbac>
    </>
  );
};

export default DashboardPage;
