import {
  IOrganization,
  IOrganizationAssociatedUserDetails,
  IProjectMember,
  IUserDetails,
  IInvitationData,
  IGridList,
  ITaskList,
  IProjectSections,
  IMemopad,
} from 'models/interface';
import {
  ICmsData,
  ICountryCmsData,
  IGlobalCmsData,
  IPageSpecificData,
  IPlannerDateTimeTemplate,
} from '../models/interface/cms.interface';
import { AES, enc } from 'crypto-js';
import BrowserStorageService from 'services/browserStorage.service';
import * as CryptoJS from 'crypto-js';
import { TaskListView } from 'models/enums/tasklistView';
import dayjs from 'dayjs';
import { ITimestampTags } from 'models/interface/pnc.interface';

export const removeStyle = (name: string) => {
  const link = document.getElementById(name);
  if (link) {
    link.remove();
  }
};

export const setStyle = (stylesheet: string, idName: string) => {
  if (stylesheet && stylesheet !== 'default') {
    const link = document.createElement('link');
    link.href = `src/assets/css/${stylesheet}`;
    link.rel = 'stylesheet';
    link.id = idName;
    document.head.appendChild(link);
  }
};

export const getPageSpecificData = (
  pageSpecificData: ICmsData,
  pageSlug: string,
) => {
  const cmsLocalData: ICmsData = pageSpecificData;
  if (cmsLocalData?.pageSpecificData?.length) {
    return cmsLocalData?.pageSpecificData?.find(
      (eachPage: IPageSpecificData[]) => {
        return eachPage[0]?.pageSlug === pageSlug;
      },
    );
  }
};

export const setCmsGlobalData = (
  cmsData: ICmsData,
  setCmsData: any,
  globalData: IGlobalCmsData[],
  countryData: ICountryCmsData[],
) => {
  const browserStorage = BrowserStorageService.getInstance();
  let tmpCmsLocalData: any = browserStorage.getLocalization();

  if (
    (tmpCmsLocalData?.country === '' || tmpCmsLocalData?.language === '') &&
    globalData &&
    countryData
  ) {
    tmpCmsLocalData = { globalData, countrySpecificData: countryData };
  } else if (tmpCmsLocalData && globalData && countryData) {
    tmpCmsLocalData.globalData = [...globalData];
    tmpCmsLocalData.countrySpecificData = [...countryData];
  }
  setCmsData(tmpCmsLocalData);
};

export const setCmsPageData = (
  cmsData: ICmsData,
  setCmsData: any,
  pageSpecificData?: IPageSpecificData[][] | null,
  pageSlug: string[] | null = null,
) => {
  let tmpCmsLocalData: ICmsData | null = cmsData;
  tmpCmsLocalData.pageSpecificData = tmpCmsLocalData.pageSpecificData!.filter(
    (eachPage: IPageSpecificData[]) =>
      !pageSlug?.includes(eachPage[0].pageSlug),
  );
  tmpCmsLocalData.pageSpecificData = [
    ...tmpCmsLocalData.pageSpecificData,
    ...pageSpecificData!,
  ];
  setCmsData(tmpCmsLocalData);
};

export const clearPageSpecificData = (setCmsData: any) => {
  setCmsData({ globalData: [], countrySpecificData: [], pageSpecificData: [] });
};

export const decryptData = (value: string) => {
  return AES.decrypt(
    value,
    process.env.REACT_APP_DATA_ENCRYPTION_KEY ?? '',
  ).toString(enc.Utf8);
};

export const generateUserAvatar = (
  userDetails:
    | IUserDetails
    | IOrganizationAssociatedUserDetails
    | IProjectMember,
) => {
  if (userDetails) {
    if (!userDetails.profile_picture) {
      let nameSplit;

      if (userDetails['full_name']) {
        nameSplit = userDetails['full_name']?.trim().split(' ');
      } else if (userDetails['email']) {
        nameSplit = userDetails['email']?.trim().split(' ');
      }

      if (nameSplit) {
        if (nameSplit.length === 1) {
          return { key: 'string', value: `${nameSplit[0].charAt(0)}` };
        } else {
          return {
            key: 'string',
            value: `${nameSplit[0].charAt(0)}${nameSplit[
              nameSplit.length - 1
            ].charAt(0)}`,
          };
        }
      } else {
        return {
          key: 'string',
          value: `${userDetails?.email?.charAt(0)?.toLocaleUpperCase()}`,
        };
      }
    } else {
      return { key: 'url', value: userDetails?.profile_picture };
    }
  } else {
    return {
      key: 'string',
      value: '',
    };
  }
};

export const getUserGravatar = (name: string) => {
  let _name = name?.trim()?.split(' ');
  if (_name?.length === 1) {
    return _name[0]?.charAt(0)?.toUpperCase();
  } else if (_name?.length > 1) {
    return (
      _name[0]?.charAt(0)?.toUpperCase() +
      _name[_name?.length - 1]?.charAt(0)?.toUpperCase()
    );
  } else {
    return name;
  }
};

export const addRemoveOverLay = () => {
  document.body.classList.toggle('body-overlay');
};

export const addOverlay = () => {
  document.body.classList.add('body-overlay');
};

export const removeOverlay = () => {
  document.body.classList.remove('body-overlay');
};

export const getDefaultOrganization = (
  organizationDetails: IOrganization[],
): IOrganization | undefined => {
  let defaultOrganization = organizationDetails.find(
    (ele) => ele.is_default_organization,
  );
  if (!defaultOrganization && organizationDetails?.length) {
    defaultOrganization = organizationDetails[0];
  }
  return defaultOrganization;
};

export const checkTaskList = (gridList: IGridList) => {
  if (!gridList) {
    return false;
  } else {
    if (gridList?.grid_list_details?.task_details === null) {
      return false;
    }
    return true;
  }
};

export const groupBy = (arr: any[], key: string) => {
  let groupedObject = arr.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});

  return Object.values(groupedObject);
};

export const filterSortByRanking = (
  optionA: any,
  optionB: any,
  inputValue: string,
) => {
  const a = optionA?.label?.toLowerCase();
  const b = optionB?.label?.toLowerCase();
  const i = inputValue?.toLowerCase();
  const aSortOrder = optionA?.value;
  const bSortOrder = optionB?.value;
  // prioritize exact code match
  if (inputValue && a === i) {
    return -1;
  }
  if (inputValue && b === i) {
    return 1;
  }
  // prioritize earlier matches
  if (
    inputValue &&
    a?.indexOf(i) !== -1 &&
    b?.indexOf(i) !== -1 &&
    a?.indexOf(i) < b?.indexOf(i)
  ) {
    return -1;
  }
  if (
    inputValue &&
    a?.indexOf(i) !== -1 &&
    b?.indexOf(i) !== -1 &&
    a?.indexOf(i) > b?.indexOf(i)
  ) {
    return 1;
  }
  if (inputValue && a?.indexOf(i) !== -1 && b?.indexOf(i) === -1) {
    return -1;
  }
  if (inputValue && a?.indexOf(i) === -1 && b?.indexOf(i) !== -1) {
    return 1;
  }
  if (!inputValue) {
    return a > b;
  }
  // prioritize sortOrder if exists
  if (aSortOrder && bSortOrder && aSortOrder < bSortOrder) {
    return -1;
  }
  if (aSortOrder && bSortOrder && aSortOrder > bSortOrder) {
    return 1;
  }
  if (aSortOrder && bSortOrder && aSortOrder === bSortOrder) {
    return 0;
  }

  return a.localeCompare(b);
};

export const checkArrayEquality = (array1: any, array2: any) => {
  return (
    array1.length === array2.length &&
    array1.every(function (element: any, index: string | number) {
      return element === array2[index];
    })
  );
};

export const getCurrentOrganization = (
  org_key: string,
  organizationDetails: IOrganization[],
): IOrganization | undefined => {
  let currentOrganization = organizationDetails?.find(
    (ele) => ele?.org_key === org_key,
  );
  return currentOrganization ?? organizationDetails.at(0);
};

export const groupGridBlock = (block: any[], task: any[] | null) => {
  let blocks: any[] = [];
  if (task) {
    const taskIds = task
      .sort((a, b) => a.task_rank - b.task_rank)
      .map((item) => item.task_id);

    taskIds.forEach((ele) => {
      let newBlock = block.filter((item) => item.task_id === ele);
      blocks.push(newBlock);
    });
  } else {
    if (block !== undefined && block !== null) {
      let newBlock = block.filter((item) => item.task_id === 0);
      blocks.push(newBlock);
    }
  }

  return blocks;
};

export const checkTaskView = (section: ITaskList) => {
  if (!section.task_list_view) {
    return TaskListView.CARD;
  } else if (section.task_list_view === 'card') {
    return TaskListView.CARD;
  } else {
    return TaskListView.LIST;
  }
};

export const getDateFormat = (data: any, cmsData: any) => {
  const timeStamp = dayjs(data);
  const diffH = dayjs().diff(timeStamp, 'h');
  const diffD = dayjs().diff(timeStamp, 'd');

  if (diffH < 24) {
    return timeStamp.format('hh:mm A');
  } else if (diffD === 1) {
    return cmsData
      ? `${diffD} ${cmsData?.lbl_time_difference_new.singular}`
      : `${diffD} day ago`;
  } else if (diffD > 1 && diffD < 7) {
    return cmsData
      ? `${diffD} ${cmsData?.lbl_time_difference_new.plural}`
      : `${diffD} days ago`;
  } else {
    return timeStamp.format('MM/DD/YYYY');
  }
};

export const getOrganizationalDetails = (threadId: string) => {
  const browserStorage = BrowserStorageService.getInstance();
  let userDetails = browserStorage.getUserDetails();

  let organizationId = threadId.split('-')[4];

  return userDetails?.organization_info.find(
    (ele: any) => ele.organization_id === +organizationId,
  );
};

export const isRichTextContent = (text: string) => {
  if (text.substring(0, 20).indexOf('<img') > -1) {
    return true;
  }

  if (text.substring(0, 20).indexOf('ul') > -1) {
    return true;
  }

  let index = text.indexOf('</p>');
  let updatedText = text.substring(0, index);

  if (updatedText.indexOf('tiptapExtensionElement') > -1) {
    return true;
  }

  return false;
};

export const getTextForRichText = (data: any) => {
  let imagesSize = 0;
  let otherFileSize = 0;
  let allImageExtensions: string[] = [
    'jpg',
    'png',
    'gif',
    'jfif',
    'ico',
    'svg',
    'webp',
  ];
  data.attachments.forEach((itm: any) => {
    let extension = itm?.name?.split('.').pop();
    if (allImageExtensions.includes(extension)) {
      imagesSize++;
    } else {
      otherFileSize++;
    }
  });

  // if (data.content.startsWith("<img")) {
  //   return "image";
  // }

  // if (data.content.indexOf("tiptapExtensionElement") > -1) {
  //   return "file";
  // }

  if (imagesSize === 1 && otherFileSize === 0) {
    return 'image';
  }

  if (imagesSize === 0 && otherFileSize === 1) {
    return 'file';
  }

  if (imagesSize > 1 && otherFileSize === 0) {
    return 'multiImage';
  }

  if (imagesSize === 0 && otherFileSize > 1) {
    return 'multiFile';
  }
  if (imagesSize !== 0 && otherFileSize !== 0) {
    return 'multiFileImage';
  }

  return 'richText';
};

export const modifyMessageString = (messageContent: string) => {
  if (messageContent) {
    let str = reformatMessageText(messageContent);

    const regexStart = /^<p class="tiptapExtensionParagraph"><\/p>/,
      regexEnd = /<p class="tiptapExtensionParagraph"><\/p>$/;

    while (regexStart.test(str)) {
      str = str.replace(regexStart, '');
    }

    while (regexEnd.test(str)) {
      str = str.replace(regexEnd, '');
    }

    str = str.replaceAll(
      /<p class="tiptapExtensionParagraph"><\/p>/g,
      '<p class="tiptapExtensionParagraph"><br class="ProseMirror-trailingBreak"></p>',
    );
    return str;
  } else {
    return '';
  }
};

export const truncateLongText: (
  maxLength: number,
  text: string,
  suffix?: string,
) => string = (maxLength, text, suffix = '...') =>
  text.length > maxLength ? text.slice(0, maxLength) + suffix : text;

export const formatNumDecimal = (num: number | string, decimals = 2) => {
  if (Number.isNaN(num)) {
    return 0;
  }
  const parsedNum = parseFloat(String(num));

  if (parsedNum < 1) {
    return parseFloat(parsedNum.toFixed(decimals)); // Return the original number for values less than 0
  }

  if (Number(num) < 1000) {
    const number = parseFloat(Number(num).toFixed(decimals));
    return number;
  } else {
    const number = parseFloat(Number(num).toFixed(decimals));
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};
export function getInitialName(fullName: string): string {
  if (!fullName) return '';
  const words = fullName.split(' ');
  let initials = '';
  // Take the first letter of the words
  for (let i = 0; i < Math.min(2, words.length); i++) {
    initials += words[i][0].toUpperCase();
  }
  return initials;
}
export function convertNamesToInitials(
  fullNames: Array<{ id: number; fullName: string; reportBy: number }>,
): Array<{ id: number; shortName: string; reportBy: number }> {
  return fullNames.map((name) => ({
    id: name.id,
    shortName: getInitialName(name.fullName),
    reportBy: name.reportBy,
  }));
}

export function getTimeFromNow(
  dateTimeString: string,
  cmsData: ITimestampTags,
): string {
  const currentTime: Date = new Date();
  const pastTime: Date = new Date(dateTimeString);

  const timezoneOffset: number = currentTime.getTimezoneOffset();
  pastTime.setMinutes(pastTime.getMinutes() - timezoneOffset);

  const timeDifference: number = currentTime.getTime() - pastTime.getTime();

  const minutes: number = Math.floor(timeDifference / 60000);
  const hours: number = Math.floor(timeDifference / 3600000);
  const days: number = Math.floor(hours / 24);
  if (minutes < 1) {
    return `0 ${cmsData?.min_timestamp_sin}`;
  }
  if (minutes < 60) {
    return `${minutes} ${
      minutes === 1 ? cmsData?.min_timestamp_sin : cmsData?.min_timestamp_plu
    }`;
  } else if (hours < 24) {
    return `${hours} ${
      hours === 1 ? cmsData?.hour_timestamp_sin : cmsData?.hour_timestamp_plu
    }`;
  } else {
    return `${days} ${
      days === 1 ? cmsData?.day_timestamp_sin : cmsData?.day_timestamp_plu
    }`;
  }
}
export function sortRepliesByReportedOn(replies: any) {
  if (!replies) return;
  // Sorting the array based on the reported_on property
  replies.sort((a: any, b: any) => {
    const timeA: number = new Date(a.reported_on).getTime();
    const timeB: number = new Date(b.reported_on).getTime();
    return timeA - timeB; // Sort from oldest to newest
  });

  return replies;
}
export function removeElementByIdSuffix(suffix: string | number) {
  if (!suffix) return;
  const elementId = `commentPosition${suffix}`;
  const elementToRemove = document.getElementById(elementId);
  if (elementToRemove) {
    elementToRemove.remove();
    console.log(`Element with id ${elementId} removed from the DOM.`);
  } else {
    console.log(`Element with id ${elementId} not found.`);
  }
}
export function collectFullNames(feedback: any) {
  const fullNames = new Map();

  if (feedback?.full_name) {
    fullNames.set(feedback.reported_by, {
      id: fullNames.size + 1,
      fullName: feedback.full_name,
      reportBy: feedback.reported_by,
    });
  }

  if (Array.isArray(feedback?.feedback_reply_data)) {
    feedback.feedback_reply_data.forEach((item: any) => {
      if (item.full_name && !fullNames.has(item.full_name)) {
        fullNames.set(item.reported_by, {
          id: fullNames.size + 1,
          fullName: item.full_name,
          reportBy: item.reported_by,
        });
      }
    });
  }
  if (Array.isArray(feedback?.reply_data)) {
    feedback.reply_data.forEach((item: any) => {
      if (item.full_name && !fullNames.has(item.full_name)) {
        fullNames.set(item.reported_by, {
          id: fullNames.size + 1,
          fullName: item.full_name,
          reportBy: item.reported_by,
        });
      }
    });
  }
  return Array.from(fullNames.values());
}
export const debounce = (func: Function, timeout = 300) => {
  let timer: any;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

export const getThreadName = (
  taskName: string | null,
  stageName: string | null,
) => {
  if (stageName && taskName) {
    return `${taskName} | ${stageName}`;
  }

  return `${taskName ?? ''}${stageName ?? ''}`;
};

export const createTimeIntervalArray = (
  cms: IPlannerDateTimeTemplate | undefined,
) => {
  const times: {
    id: number;
    label: string;
    value: string;
  }[] = [];

  for (let hour = 0; hour < 24; hour++) {
    const period = hour >= 12 ? cms?.pmLabel ?? 'PM' : cms?.amLabel ?? 'AM';
    let adjustedHour = hour % 12;

    if (hour === 0) {
      adjustedHour = 12;
    } else if (hour === 12) {
      adjustedHour = 12;
    }

    for (let minute = 0; minute < 60; minute += 15) {
      times.push({
        id: Number(
          `${hour.toString().padStart(2, '0')}${minute
            .toString()
            .padStart(2, '0')}`,
        ),
        value: `${hour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')}`,
        label: `${adjustedHour.toString().padStart(2, '0')}:${minute
          .toString()
          .padStart(2, '0')} ${period}`,
      });
    }
  }

  return times;
};

export const formatMessageItems = (message: string) => {
  if (/\s{2,}/.test(message)) {
    return message.replace(/\s{2,}/g, (match, index) => {
      if (match.length % 2 === 0) {
        return ' &nbsp;'.repeat(match.length / 2);
      }
      if (index === match.length - 1) {
        return ' ';
      }
      return ' &nbsp;'.repeat(match.length / 2);
    });
  }

  return message;
};

export const reformatMessageText = (message: string) => {
  if (/&nbsp;/.test(message)) {
    return message.replace(/&nbsp;/g, ' ');
  }

  return message;
};

export const findCollapseStateFromStorage = (
  sectionDetails: IProjectSections | IMemopad | ITaskList | IGridList,
  userId: string,
  projectId?: string,
  organizationId?: string,
  gridListView?: { key: string },
) => {
  if (sectionDetails) {
    let sectionType =
      sectionDetails.section_type_id === 1
        ? 'tasklist'
        : sectionDetails.section_type_id === 2
        ? 'gridlist'
        : 'memopad';

    let getCurrentState = localStorage.getItem('userProjectBoardSettings');
    if (getCurrentState !== null) {
      let obj = JSON.parse(getCurrentState);

      let userSettingsIndex = obj.findIndex(
        (itm: any) => itm.userId === userId,
      );
      if (userSettingsIndex !== -1) {
        if (gridListView !== undefined) {
          let sectionArrayList =
            obj[userSettingsIndex]['settings'][sectionType];
          let findSection = sectionArrayList.findIndex(
            (itm: any) =>
              itm.sectionId === sectionDetails.section_id &&
              itm.projectId === projectId?.toString() &&
              itm.organizationId === organizationId?.toString(),
          );
          if (findSection !== -1) {
            if (sectionArrayList[findSection][gridListView.key] === undefined) {
              return '[]';
            } else {
              return sectionArrayList[findSection][gridListView.key];
            }
          } else {
            return '[]';
          }
        } else {
          let sectionArrayList =
            obj[userSettingsIndex]['settings'][sectionType];
          let findSection = sectionArrayList.findIndex(
            (itm: any) =>
              itm.sectionId === sectionDetails.section_id &&
              itm.projectId === projectId?.toString() &&
              itm.organizationId === organizationId?.toString(),
          );
          if (findSection !== -1) {
            return sectionArrayList[findSection];
          }
        }
      }
    }
  }
};

export const storeSectionListStatusInLocalStorage = (
  sectionDetails: IProjectSections | IMemopad | ITaskList | IGridList,
  userId: string,
  projectId?: string,
  organizationId?: string,
  sectionCollapseStatus?: boolean,
  taskListView?: 'list' | 'card',
  gridListView?: { key: string; value: string },
) => {
  if (sectionDetails) {
    let sectionType =
      sectionDetails.section_type_id === 1
        ? 'tasklist'
        : sectionDetails.section_type_id === 2
        ? 'gridlist'
        : 'memopad';
    let getCurrentState = localStorage.getItem('userProjectBoardSettings');
    if (getCurrentState !== null) {
      let obj = JSON.parse(getCurrentState);
      let findUserSettingsIndex = obj.findIndex(
        (itm: any) => itm.userId === userId,
      );
      let userSettings;
      if (findUserSettingsIndex !== -1) {
        userSettings = obj[findUserSettingsIndex];
      } else {
        userSettings = {
          userId: userId,
          settings: {
            tasklist: [],
            gridlist: [],
            memopad: [],
          },
        };
      }

      if (sectionCollapseStatus !== undefined) {
        let settingsList = userSettings.settings[sectionType];

        let findSection = settingsList.findIndex(
          (itm: any) =>
            itm.sectionId === sectionDetails.section_id &&
            itm.projectId === projectId?.toString() &&
            itm.organizationId === organizationId?.toString(),
        );
        if (findSection !== -1) {
          settingsList[findSection]['collapsed'] = sectionCollapseStatus;
        } else {
          settingsList.push({
            sectionId: sectionDetails.section_id,
            projectId: projectId?.toString(),
            organizationId: organizationId?.toString(),
            collapsed: sectionCollapseStatus,
          });
        }

        userSettings['settings'][sectionType] = settingsList;

        if (findUserSettingsIndex !== -1) {
          obj[findUserSettingsIndex] = userSettings;
        } else {
          obj.push(userSettings);
        }

        let newArray = [...obj];

        localStorage.setItem(
          'userProjectBoardSettings',
          JSON.stringify(newArray),
        );
      }
      if (taskListView !== undefined) {
        let settingsList = userSettings.settings[sectionType];

        let findSection = settingsList.findIndex(
          (itm: any) =>
            itm.sectionId === sectionDetails.section_id &&
            itm.projectId === projectId?.toString() &&
            itm.organizationId === organizationId?.toString(),
        );
        if (findSection !== -1) {
          settingsList[findSection]['view'] = taskListView;
        } else {
          settingsList.push({
            sectionId: sectionDetails.section_id,
            projectId: projectId?.toString(),
            organizationId: organizationId?.toString(),
            view: taskListView,
          });
        }

        userSettings['settings'][sectionType] = settingsList;

        if (findUserSettingsIndex !== -1) {
          obj[findUserSettingsIndex] = userSettings;
        } else {
          obj.push(userSettings);
        }

        let newArray = [...obj];

        localStorage.setItem(
          'userProjectBoardSettings',
          JSON.stringify(newArray),
        );
      }
      if (gridListView !== undefined) {
        let settingsList = userSettings.settings[sectionType];

        let findSection = settingsList.findIndex(
          (itm: any) =>
            itm.sectionId === sectionDetails.section_id &&
            itm.projectId === projectId?.toString() &&
            itm.organizationId === organizationId?.toString(),
        );
        if (findSection !== -1) {
          settingsList[findSection][gridListView.key] = gridListView.value;
        } else {
          settingsList.push({
            sectionId: sectionDetails.section_id,
            projectId: projectId?.toString(),
            organizationId: organizationId?.toString(),
            [gridListView.key]: gridListView.value,
          });
        }

        userSettings['settings'][sectionType] = settingsList;

        if (findUserSettingsIndex !== -1) {
          obj[findUserSettingsIndex] = userSettings;
        } else {
          obj.push(userSettings);
        }

        let newArray = [...obj];

        localStorage.setItem(
          'userProjectBoardSettings',
          JSON.stringify(newArray),
        );
      }
    } else {
      localStorage.setItem('userProjectBoardSettings', JSON.stringify([]));
    }
  }
};

export const decryptUsingAES256 = (
  decString: string,
): IInvitationData | null => {
  try {
    const key = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_AES_ENCRYPTION_KEY!,
    );
    const iv = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_AES_ENCRYPTION_KEY!,
    );
    const decrypted = CryptoJS.AES.decrypt(
      decString.replaceAll(/\s/gm, '+').trim(),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      },
    );

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    return null;
  }
};

export const encryptUsingAES256 = (data: any) => {
  const key = CryptoJS.enc.Utf8.parse(
    process.env.REACT_APP_AES_ENCRYPTION_KEY!,
  );
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_ENCRYPTION_KEY!);
  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(JSON.stringify(data)),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return encrypted;
};

export const getContrastColor = (bgColor: string): string => {
  // Function to calculate contrast color based on background color
  const getLuminance = (color: string) => {
    // Function to calculate luminance
    color = color.substring(1); // remove #
    let rgb = parseInt(color, 16); // convert to decimal
    let r = (rgb >> 16) & 0xff; // extract red
    let g = (rgb >> 8) & 0xff; // extract green
    let b = (rgb >> 0) & 0xff; // extract blue
    r /= 255;
    g /= 255;
    b /= 255; // normalize to 0-1 range
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // calculate luminance
  };

  const luminance = getLuminance(bgColor);
  return luminance > 0.5 ? '#000000' : '#ffffff'; // return black or white based on background luminance
};

export function addOrRemoveNameToBubble(
  containerId: string,
  nameId: number,
  text: string,
  isResolve: boolean,
  isAdd: boolean,
) {
  const parent = document.getElementById(`commentPosition${containerId}`);
  if (!parent) {
    console.error('No element found with ID:', `commentPosition${containerId}`);
    return;
  }

  const commentWrap = parent.querySelector('.commentBubbleInnerWrap');
  if (!commentWrap) {
    console.error(
      'No comment wrap found inside:',
      `commentPosition${containerId}`,
    );
    return;
  }

  if (isAdd) {
    const newCommentBubble = document.createElement('div');
    newCommentBubble.className = `commentBubbleInner ${
      isResolve ? 'resolved' : ''
    }`;
    newCommentBubble.id = `commentBubbleInner${nameId}`;

    const newSpan = document.createElement('span');
    newSpan.textContent = text;
    newCommentBubble.appendChild(newSpan);

    commentWrap.appendChild(newCommentBubble);
  } else {
    const existingCommentBubble = document.getElementById(
      `commentBubbleInner${nameId}`,
    );
    if (existingCommentBubble) {
      commentWrap.removeChild(existingCommentBubble);
    } else {
      console.error('No element found with ID:', `commentBubbleInner${nameId}`);
    }
  }
}

export function changeBackgroundStyles(
  containerId: number | string,
  wrapColor: string,
) {
  const parent = document.getElementById(`commentPosition${containerId}`);
  if (!parent) {
    console.error('No element found with ID:', `commentPosition${containerId}`);
    return;
  }
  parent.style.background = wrapColor;
  const commentWrap = parent.querySelector('.commentBubbleStackLayer1');
  if (commentWrap && commentWrap instanceof HTMLElement) {
    commentWrap.style.background = wrapColor;
  } else {
    console.error(
      'No comment wrap found inside:',
      `commentPosition${containerId}`,
    );
    return;
  }

  const commentBubbles = parent.querySelectorAll('.commentBubbleStackLayer2');
  commentBubbles.forEach((bubble) => {
    if (bubble instanceof HTMLElement) {
      bubble.style.background = wrapColor;
    }
  });
  const lastCommentBubbles = parent.querySelectorAll(
    '.commentBubbleStackLayer2.lastBubble',
  );
  lastCommentBubbles.forEach((bubble) => {
    if (bubble instanceof HTMLElement) {
      bubble.style.background = 'transparent';
    }
  });
}

export function formatDateTime(dateTimeString: string) {
  const date = new Date(dateTimeString);

  const optionsDate: any = { year: 'numeric', month: 'long', day: '2-digit' };
  const dateFormatted = date.toLocaleDateString('en-US', optionsDate);

  const optionsTime: any = { hour: '2-digit', minute: '2-digit', hour12: true };
  const timeFormatted = date.toLocaleTimeString('en-US', optionsTime);

  const formattedDateTime = `${dateFormatted} ${timeFormatted}`;

  return formattedDateTime;
}

export const getCurrentTimeAsTimestamp = () => {
  let date = new Date();
  let year = date.getFullYear();
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let day = ('0' + date.getDate()).slice(-2);
  let hours = ('0' + date.getHours()).slice(-2);
  let minutes = ('0' + date.getMinutes()).slice(-2);
  let seconds = ('0' + date.getSeconds()).slice(-2);
  let milliseconds = ('00' + date.getMilliseconds()).slice(-3);
  let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  return formattedDate;
};

export function checkContainReply(
  fullNames: any[],
  myReportBy: number,
): boolean {
  const containsMyReportBy = fullNames.some(
    (obj) => obj.reportBy === myReportBy,
  );
  if (!containsMyReportBy) {
    return false;
  }
  const otherReportBys = fullNames.filter((obj) => obj.reportBy !== myReportBy);
  return otherReportBys.length === 0;
}

export function isEmptyString(str: any): boolean {
  if (!str || typeof str !== 'string') return true;
  if (typeof str === 'string') {
    return String(str).trim().length === 0;
  }
  return true;
}

export function getBlockStatus(date: string, originalStatus: number) {
  if ([3, 4].includes(originalStatus)) return originalStatus;
  return dayjs().isBefore(date, 'day') || dayjs().isSame(date, 'day') ? 1 : 2;
}
