import React, {FC, useEffect, useState} from "react";
import { IProfileSettingCmsData} from "../../../../models/interface";
import TagsInput from "react-tagsinput";

const PersonalPurposeComponent: FC<{
    lblCms: IProfileSettingCmsData | null,
    defaultHobbies: string[] | null | undefined,
    onChange: (hobbies: string[]) => void
}> = ({lblCms, defaultHobbies, onChange}) => {
    const [hobbies, setHobbies] = useState<string[]>(defaultHobbies ?? []);
    const hobbyChangeHandler = (tags: string[]) => {
        setHobbies(tags);
    };
    useEffect(() => {
        onChange(hobbies)
    }, [hobbies]);


    const defaultRenderInput = (props: any) => {
        let {onChange, value, addTag, ...other} = props;
        return (
            <React.Fragment>
                <input type="text" onChange={onChange} value={value} maxLength={50} {...other} />
                <label className="chipTextAreaLabel">
                    {lblCms?.lbl_profile_hobbies}
                </label>
            </React.Fragment>
        );
    };

    return <>
        <div className="row">
            <div className="textArea-chip">
                <TagsInput
                    inputProps={{
                        className: "react-tagsinput-input",
                        placeholder: `${lblCms?.lbl_profile_hobbies_placeholder}`,
                    }}
                    renderInput={defaultRenderInput}
                    value={hobbies}
                    onChange={hobbyChangeHandler}
                    onlyUnique={true}
                />
            </div>
        </div>
    </>
}

export default PersonalPurposeComponent
