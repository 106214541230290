import {
  FC,
  Fragment,
  memo,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Skeleton, Tooltip } from 'antd';
import SectionFilters from '../SectionFilters';
import {
  IGridList,
  IGridListBlockDetail,
  IGridListResponseData,
  IMemopad,
  IProjectData,
  IProjectSections,
  IProjectState,
  ITaskList,
} from 'models/interface';
import { useProjectDashboardStore, useUserStore } from 'stores';
import BrowserStorageService from 'services/browserStorage.service';
import { loggerService, notificationService, projectService } from 'services';
import GridListDetails from './GridListDetails';
import {
  RightSideDrawerForCustomizeStatusUpdate,
  useAnalytics,
} from 'components/sharedComponents';
import {
  findCollapseStateFromStorage,
  getCurrentOrganization,
  groupGridBlock,
  storeSectionListStatusInLocalStorage,
} from 'utils/commonFunctions';
import MeatBallMenu from '../MeatBallMenu';
import {
  NotificationEventType,
  SectionType,
  SectionTypeForViewPort,
} from 'models/enums/notification';
import {
  useFetchNewPermission,
  useIsInViewport,
} from 'components/sharedComponents/hooks';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';

export const GRID_VIEW_TYPES = [
  {
    id: 1,
    className: '',
  },
  {
    id: 2,
    className: 'gridDateView',
  },
  {
    id: 3,
    className: '',
  },
  {
    id: 4,
    className: 'gridStatusView',
  },
];

// Re-use functions
export const handleGridViewStore = (
  sectionId: number,
  view_id: number,
  action: 'set' | 'get',
  key: 'grid_view' | 'compact_view' = 'grid_view',
  sectionDetails: IProjectSections | IMemopad | ITaskList | IGridList,
  userDetails: any,
  projectDetails: any,
  org_key: any,
) => {
  try {
    let projectId =
      projectDetails && projectDetails.project_id
        ? projectDetails.project_id.toString()
        : '';
    let orgId = getCurrentOrganization(org_key, userDetails?.organization_info)
      ?.organization_id!;
    let dt = findCollapseStateFromStorage(
      sectionDetails,
      userDetails.user_id.toString(),
      projectId,
      orgId,
      { key: key },
    );

    const storeData: (string | null)[] = JSON.parse(dt);

    if (action === 'set') {
      const data = `${sectionId}-${view_id}`;
      const isExist = storeData.find((i) => i?.includes(`${sectionId}-`));
      const updateData = isExist
        ? storeData.map((i) => (i?.includes(`${sectionId}-`) ? data : i))
        : [...storeData, data];

      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId,
        orgId,
        undefined,
        undefined,
        { key: key, value: JSON.stringify(updateData) },
      );

      return data;
    }
    return Number(
      storeData.find((i) => i?.includes(`${sectionId}-`))?.split('-')?.[1] ??
        (key === 'compact_view' ? 0 : 1),
    );
  } catch (err) {
    console.log('ERROR FROM HANDLE -> ', err);
  }
};

const GridList: FC<{
  sectionDetails: IProjectSections;
  socket?: any;
  projectColor?: string;
  refDiv: React.RefObject<HTMLDivElement>;
  setCurrentId?: (val: string) => void;
  currentId?: string;
}> = forwardRef(
  (
    {
      sectionDetails,
      socket,
      projectColor = '#dbe8ff',
      refDiv,
      currentId,
      setCurrentId,
    },
    ref,
  ) => {
    const gaContext = useAnalytics();
    const location = useLocation();
    const org_key = location.pathname.split('/')[2];
    const inputRef = useRef<any>(null);
    const {
      projectDetails,
      taskListCmsData,
      customFields,
      setProjectDetails,
      setCustomFields,
      customfieldCmsData,
    } = useProjectDashboardStore((state: IProjectState) => state);
    const [isEditingSectionName, setIsEditingSectionName] =
      useState<boolean>(false);
    const { hasPermissions } = useRbac();
    const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
    const [sectionName, setSectionName] = useState<string>('');
    const [gridListDetails, setGridListDetails] = useState<IGridList>({
      ...sectionDetails,
      pack_id: 1,
      status_done_id: 1,
    });
    const { userDetails } = useUserStore((state: any) => state);
    const [isLoadingTaskListDetails, setIsLoadingTaskListDetails] =
      useState(true);
    const browserStorage = BrowserStorageService.getInstance();
    const [sectionToggleState, setSectionToggleState] = useState<
      'expanded' | 'collapsed'
    >('expanded');

    const gridListSection = useRef(null);
    const isGridListSectionInViewport = useIsInViewport(gridListSection);

    const { fetchNewPermission } = useFetchNewPermission();

    useImperativeHandle(ref, () => ({
      setIsEditing: (value: boolean) => {
        setIsEditingSectionName(value);
      },
    }));
    const initializeProjectSettings = () => {
      let projectId =
        projectDetails && projectDetails.project_id
          ? projectDetails.project_id.toString()
          : '';
      let orgId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!;

      let collapseState = findCollapseStateFromStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId.toString(),
        orgId.toString(),
      );
      storeSectionListStatusInLocalStorage(
        sectionDetails,
        userDetails.user_id.toString(),
      );
      setSectionToggleState((prev) =>
        collapseState && collapseState.collapsed ? 'collapsed' : 'expanded',
      );
    };
    useEffect(() => {
      initializeProjectSettings();
      (async () => {
        setIsLoadingTaskListDetails(true);
        if (typeof sectionDetails.section_id === 'number') {
          await getSectionDetails();
        } else {
          setGridListDetails({
            ...gridListDetails,
            grid_list_details: {
              task_details: null,
              stage_details: null,
              block_details: null,
            },
          });
          setTimeout(() => {
            setIsLoadingTaskListDetails(false);
          }, 500);
        }
      })();

      getCustomFields();
    }, []);

    useEffect(() => {
      if (typeof sectionDetails.section_id === 'string') {
        setIsEditingSectionName(true);
      } else {
        setIsEditingSectionName(false);
      }
    }, [sectionDetails.section_id]);

    useEffect(() => {
      if (isEditingSectionName) {
        setSectionName(gridListDetails?.section_name ?? '');
      }
    }, [isEditingSectionName]);

    useEffect(() => {
      if (!isEditingSectionName) {
        setSectionName(gridListDetails?.section_name ?? '');
      }
    }, [gridListDetails?.section_name]);

    const handleSectionToggle = () => {
      let projectId =
        projectDetails && projectDetails.project_id
          ? projectDetails.project_id.toString()
          : '';
      let orgId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!;

      let collapseState = findCollapseStateFromStorage(
        sectionDetails,
        userDetails.user_id.toString(),
        projectId,
        orgId,
      );
      setSectionToggleState((prev) =>
        collapseState && collapseState.collapsed ? 'expanded' : 'collapsed',
      );
      if (collapseState && collapseState.collapsed) {
        storeSectionListStatusInLocalStorage(
          sectionDetails,
          userDetails.user_id.toString(),
          projectId,
          orgId,
          false,
          undefined,
          undefined,
        );
      } else {
        storeSectionListStatusInLocalStorage(
          sectionDetails,
          userDetails.user_id.toString(),
          projectId,
          orgId,
          true,
          undefined,
          undefined,
        );
      }
    };

    useEffect(() => {
      if (
        sectionDetails.is_edit_mode &&
        String(currentId) === String(sectionDetails.section_id)
      ) {
        setTimeout(() => {
          setIsEditingSectionName(true);
        }, 1500);
      }
    }, [sectionDetails, currentId]);

    const getSectionDetails = async () => {
      if (projectDetails) {
        try {
          const response: IGridListResponseData =
            await projectService.getGridlistDetails({
              project_id: projectDetails.project_id!.toString(),
              section_id: sectionDetails.section_id.toString(),
              organization_id: getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!,
            });
          if (response.messageId === 1) {
            let eachSection = response.data;

            if (
              eachSection?.grid_list_details?.block_details !== null &&
              eachSection?.grid_list_details!.block_details!.length > 0
            ) {
              let updatedSection = {
                ...eachSection,
                view_id: handleGridViewStore(
                  Number(eachSection.section_id ?? 0),
                  0,
                  'get',
                  undefined,
                  sectionDetails,
                  userDetails,
                  projectDetails,
                  org_key,
                ),
                grid_list_details: {
                  ...eachSection.grid_list_details,
                },
              };
              updatedSection.grid_list_details.block_details = updatedSection
                .grid_list_details?.block_details
                ? (groupGridBlock(
                    updatedSection.grid_list_details?.block_details,
                    updatedSection.grid_list_details.task_details!,
                  ) as Array<IGridListBlockDetail[]>)
                : null;

              eachSection = updatedSection as IGridList;
              // @ts-ignore
              const isCompactView = handleGridViewStore(
                Number(eachSection.section_id ?? 0),
                0,
                'get',
                'compact_view',
                sectionDetails,
                userDetails,
                projectDetails,
                org_key,
              );
              eachSection.compactView = Boolean(isCompactView);
            }

            setIsLoadingTaskListDetails(false);
            setGridListDetails(eachSection);
          }
        } catch (err) {
          setIsLoadingTaskListDetails(false);
          await loggerService.log({
            severity: 'High',
            message: 'Failed to fetch grid list details',
            payload: {
              project_id: projectDetails.project_id,
              section_id: sectionDetails.section_id,
              organization_id: getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id,
            },
          });
        }
      }
    };

    const createSection = async (
      projectDetails: IProjectData,
      oldSection: IProjectSections,
    ) => {
      const userDetails = browserStorage.getUserDetails();

      try {
        const response = await projectService.createNewSection({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id!,
          section_type_id: 2,
          section_name: sectionName,
          section_rank: oldSection.section_rank,
          userId: userDetails?.user_id,
        });

        if (response.data.data) {
          sendAnalyticsEvent();
          setProjectDetails({
            ...projectDetails,
            sections: projectDetails?.sections?.map(
              (eachSection: IProjectSections) => {
                const _section = eachSection;
                if (_section.section_id === sectionDetails.section_id) {
                  _section.section_id = response.data.data;
                }
                return _section;
              },
            ),
          });

          // notificationService.sendSectionUpdateNotification(socket, {
          //   eventType: NotificationEventType.ADD_NEW_SECTION,
          //   eventValue: "",
          //   sectionType: SectionType.GRID_LIST,
          //   currentOrganizationId: getCurrentOrganization(
          //     org_key,
          //     userDetails?.organization_info
          //   )?.organization_id,
          //   currentProjectId: projectDetails?.project_id,
          //   currentSectionId: "",
          //   currentUserId: userDetails.user_id,
          //   currentUserName: userDetails.full_name
          //     ? userDetails.full_name
          //     : userDetails.email,
          // });
        }
      } catch (error: any) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
      }
    };

    const updateSection = async (
      projectDetails: IProjectData,
      oldSection: IProjectSections,
    ) => {
      const userDetails = browserStorage.getUserDetails();

      try {
        const result = await projectService.updateSection({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id!,
          userId: userDetails?.user_id,
          sections: [
            {
              section_id: +oldSection.section_id,
              section_name: sectionName,
            },
          ],
        });

        if (result.messageId === 1) {
          setProjectDetails({
            ...projectDetails,
            sections: projectDetails?.sections?.map((eachSection) => {
              const _section = eachSection;
              delete _section.is_edit_mode;
              return _section;
            }),
          });

          setGridListDetails({
            ...gridListDetails,
            section_name: sectionName,
          });

          // notificationService.sendSectionUpdateNotification(socket, {
          //   eventType: NotificationEventType.UPDATE_SECTION_NAME,
          //   eventValue: sectionName,
          //   sectionType: SectionType.GRID_LIST,
          //   currentOrganizationId: getCurrentOrganization(
          //     org_key,
          //     userDetails?.organization_info
          //   )?.organization_id,
          //   currentProjectId: projectDetails.project_id,
          //   currentSectionId: sectionDetails.section_id,
          //   currentUserId: userDetails.user_id,
          //   currentUserName: userDetails.full_name
          //     ? userDetails.full_name
          //     : userDetails.email,
          // });
        }
      } catch (error: any) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
      }
    };

    const sectionNameOnBlurHandler = async (
      _event:
        | React.FocusEvent<HTMLInputElement, Element>
        | React.KeyboardEvent<HTMLInputElement>,
    ) => {
      setIsEditingSectionName(false);
      setSectionName((prev) => prev?.trim());
      if (projectDetails?.project_id && projectDetails?.sections) {
        const oldSection = projectDetails.sections.find(
          (eachSection) => eachSection.section_id === sectionDetails.section_id,
        );
        if (typeof oldSection?.section_id === 'string' && sectionName === '') {
          setProjectDetails({
            ...projectDetails,
            sections: projectDetails?.sections?.filter(
              (eachSection) =>
                eachSection.section_id !== sectionDetails.section_id,
            ),
          });
        } else if (
          typeof oldSection?.section_id === 'string' &&
          sectionName !== ''
        ) {
          await createSection(projectDetails, oldSection);
        } else if (
          typeof oldSection?.section_id === 'number' &&
          sectionName?.trim() !== '' &&
          gridListDetails?.section_name !== sectionName
        ) {
          await updateSection(projectDetails, oldSection);
        }
      }
    };

    const sectionNameChangeHandler = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const value = event?.currentTarget?.value;
      setSectionName(value);
    };

    const saveOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        sectionNameOnBlurHandler(event);
      }
    };

    const sendAnalyticsEvent = () => {
      gaContext.updateProps({
        username: userDetails.full_name,
        email: userDetails.email,
        organization: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )
          ? getCurrentOrganization(org_key, userDetails.organization_info)
              ?.organization_name
          : '',
        eventName: '2d grid list section creation finished',
        eventCategory: 'Projects',
        eventLabel: 'Create Section',
      });

      return true;
    };

    const getCustomFields = async () => {
      if (customFields?.length) return;

      try {
        const { data, messageId } = await projectService.getCustomFieldData();
        const list = data?.category_list?.length ? data.category_list : data;
        if (messageId === 1) setCustomFields(list);
      } catch (error) {
        await loggerService.log({
          severity: 'High',
          message: 'Failed to fetch custom fields',
          payload: null,
        });
      }
    };
    const checkAutoFocus = () => {
      if (!currentId) return true;
      return String(currentId) === String(sectionDetails.section_id);
    };
    return isGridListSectionInViewport ? (
      <Fragment>
        <div
          ref={gridListSection}
          id={`${SectionTypeForViewPort.GRID_LIST}-${sectionDetails.section_id}`}
        >
          <div className="createNewSecMainContent">
            {isLoadingTaskListDetails ? (
              <Skeleton active loading={isLoadingTaskListDetails} />
            ) : (
              <div className="taskNameHeadingPane">
                <div className="itemNameBlk">
                  <div
                    className="nameOuterBlk"
                    style={{ backgroundColor: projectColor }}
                  >
                    <Tooltip
                      title={taskListCmsData?.lbl_section_view_toggle_tooltip}
                      color={'#2E364C'}
                      placement="bottom"
                    >
                      <span
                        className={`sidePanelIcon ${
                          sectionToggleState === 'expanded'
                            ? 'arrow-down'
                            : 'arrow-right'
                        }`}
                        color={'#2E364C'}
                        onClick={handleSectionToggle}
                        onKeyDown={() => {}}
                      />
                    </Tooltip>
                    {isEditingSectionName &&
                    projectDetails?.is_archived === false ? (
                      <Rbac
                        allowedPermissions={[
                          ERbacPermissions.PROJECT_SECTION_EDIT,
                        ]}
                        project_role_id={projectDetails?.associated_role_id}
                      >
                        <div className="auto-grow-input">
                          <input
                            className="section-text-input"
                            placeholder={
                              taskListCmsData?.lbl_new_section_default_name
                            }
                            onBlur={sectionNameOnBlurHandler}
                            onChange={sectionNameChangeHandler}
                            onKeyDown={saveOnEnter}
                            value={sectionName}
                            maxLength={50}
                            autoFocus={checkAutoFocus()}
                            ref={(r) => (inputRef.current = r)}
                          />
                          <span className="auto-grow-input-hidden">
                            {sectionName}
                          </span>
                        </div>
                      </Rbac>
                    ) : (
                      <span
                        className="section-text-span itemName"
                        onClick={() => {
                          if (projectDetails?.is_archived === false) {
                            hasPermissions(
                              [ERbacPermissions.PROJECT_SECTION_EDIT],
                              projectDetails?.associated_role_id,
                            ) && setIsEditingSectionName(true);
                            setCurrentId?.('');
                          }
                        }}
                        onKeyDown={() => {}}
                      >
                        {sectionName?.trim() === ''
                          ? gridListDetails?.section_name
                          : sectionName}
                      </span>
                    )}
                    {!isEditingSectionName && (
                      <span
                        className={`cmnIcon ${
                          gridListDetails?.is_private ? 'lock' : 'unlock'
                        }`}
                      ></span>
                    )}
                  </div>
                  {!isEditingSectionName && (
                    <Fragment>
                      {gridListDetails && (
                        <MeatBallMenu
                          sectionType="grid-list"
                          sectionDetails={gridListDetails}
                          setSectionDetails={setGridListDetails}
                          setShowSidePanel={setShowSidePanel}
                          // socket={socket}
                          sectionList={projectDetails?.sections!}
                          getSectionDetails={getSectionDetails}
                          refDiv={refDiv}
                          currentId={currentId}
                          setCurrentId={setCurrentId}
                        />
                      )}
                    </Fragment>
                  )}
                </div>
                {sectionToggleState === 'expanded' && gridListDetails ? (
                  <SectionFilters
                    sectionDetails={gridListDetails}
                    setSectionDetails={setGridListDetails}
                    getSectionDetails={getSectionDetails}
                  />
                ) : null}
              </div>
            )}
          </div>
          <GridListDetails
            sectionDetails={sectionDetails}
            sectionToggleState={sectionToggleState}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            getSectionDetails={getSectionDetails}
            customfieldCmsData={customfieldCmsData}
          />
          {/* <DateGridList /> */}
        </div>
        {gridListDetails && (
          <RightSideDrawerForCustomizeStatusUpdate
            showSidePanel={showSidePanel}
            setShowSidePanel={setShowSidePanel}
            sectionDetails={gridListDetails}
            setSectionDetails={setGridListDetails}
          />
        )}
        <div id="expandedMessagePopup"></div>
      </Fragment>
    ) : (
      <div
        className="createNewSecMainContent"
        ref={gridListSection}
        id={`${SectionTypeForViewPort.GRID_LIST}-${sectionDetails.section_id}`}
        style={{ minHeight: '150px' }}
      ></div>
    );
  },
);

export default memo(GridList);
