import { FC, useState } from 'react';
import { message, Modal } from 'antd';

import VideoPlayer from './VideoPlayer';
import FeedbackMessageBox from './FeedbackMessageBox';
import {
  IFeedbackDetailsData,
  IResponseGetFeedbackbyId,
} from 'models/interface/pnc.interface';
import feedbackService from 'services/pncService/feedback.service';
import { useProjectDashboardStore } from 'stores/project.store';
import { useFeedbackStore } from 'stores/feedback.store';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';

const CustomVideoContainer: FC<{
  src: string;
  feedbackDetailsId: number;
  feedbackId: number;
  onClick: () => void;
}> = ({ src, feedbackDetailsId, feedbackId, onClick }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { projectDetails } = useProjectDashboardStore();
  const [feedbackData, setFeedbackData] = useState<IFeedbackDetailsData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setIsRightDrawerOpen } = useFeedbackStore((state: any) => state);
  const { pncFeedbackSpecificCmsData } = usePncCmsDataStore();

  const getFeedbackData = async (feedbackDetailsId: number) => {
    const payload = {
      organizationId: projectDetails?.organization_id,
      projectId: projectDetails?.project_id,
      detailsId: feedbackDetailsId,
    };
    try {
      const response: IResponseGetFeedbackbyId =
        await feedbackService.getFeedbacksById(payload);
      if (response?.messageId === 1) {
        setFeedbackData(response?.data?.feedback_data);
      } else {
        message.error(
          pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
        );
        console.log(response?.message);
        setIsRightDrawerOpen(false);
        handleCancel();
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncFeedbackSpecificCmsData?.toaster_msgs?.error_get_fb_details_by_id,
      );
      setIsRightDrawerOpen(false);
      handleCancel();
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleModelOpen = () => {
    setModalOpen(true);
    getFeedbackData(feedbackDetailsId);
  };

  return (
    <>
      <div className="videoScreenshot">
        <button
          className="playBtn"
          onClick={() => {
            handleModelOpen();
            onClick();
          }}
        ></button>
        <video>
          <source src={src} type="video/webm"></source>
          <track kind="captions" />
        </video>
      </div>
      {modalOpen && (
        <Modal
          title={null}
          closable={false}
          centered
          open={modalOpen}
          footer={null}
          onCancel={handleCancel}
          className="videoFeedbackPopup"
          width={900}
        >
          <VideoPlayer src={src} />
          <FeedbackMessageBox
            feedbackDetailsId={feedbackDetailsId}
            handleCancel={handleCancel}
            feedbackId={feedbackId}
            type={'video'}
            feedbackData={feedbackData}
            setFeedbackData={setFeedbackData}
            isLoading={isLoading}
          />
        </Modal>
      )}
    </>
  );
};

export default CustomVideoContainer;
