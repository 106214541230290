import { create } from "zustand";
import {
  IDashboardStore,
  IFoldersData,
  IOrganizationAssociatedUser,
  IOrganizationAssociatedUserDetails,
  IProjectFolderDetails,
  IProjectList,
} from "../models/interface";

export const useDashboardStore = create<IDashboardStore>((set) => ({
  //initial states
  folderList: [],
  projectList: [],
  folderProjectList: [],
  userList: [],
  selectedFolderId: 0,
  newTeamValue: {
    teamName: "",
    teamMember: [],
  },

  //actions
  setFolderList: (value: IFoldersData[] | null) => set({ folderList: value }),
  setProjectList: (value: IProjectList[] | null) => set({ projectList: value }),
  setFolderProjectList: (value: IProjectFolderDetails[] | null) =>
    set({ folderProjectList: value }),
  setUserList: (value: IOrganizationAssociatedUserDetails[] | null) => {
    let sortedUserList: IOrganizationAssociatedUserDetails[] | null = null;
    if (value && value.length > 0) {
      const activeUserList = value.filter((eachUser) => !eachUser.inactive);
      const inactiveUserList = value.filter((eachUser) => eachUser.inactive);
      let activeSortedUserList: any = [],
        inactiveSortedUserList: any = [];
      if (activeUserList.length) {
        activeSortedUserList = activeUserList.sort((a, b) =>
          a.full_name!.localeCompare(b.full_name!)
        );
      }
      if (inactiveUserList.length) {
        inactiveSortedUserList = inactiveUserList.sort((a, b) =>
          a.email!.localeCompare(b.email!)
        );
      }
      sortedUserList = [...activeSortedUserList, ...inactiveSortedUserList];
    }
    set({ userList: sortedUserList });
  },
  setSelectedFolderId: (value: number) => set({ selectedFolderId: value }),
  setNewTeamValue: (value: {
    teamName: string;
    teamMember: { email: string; roleId: number }[];
  }) => set({ newTeamValue: value }),
}));
