import { useEffect, FC, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IAccountActivationPageAttributes,
  ILoaderState,
} from "../../../models/interface";
import { cmsService, loggerService, userService } from "../../../services";
import { useLoaderStore, useLocalizationStore } from "../../../stores";
import { getPageSpecificData } from "../../../utils/commonFunctions";
import { ThemeSelector } from "../../sharedComponents/ThemeSelector/ThemeSelector";
import { LogoWrapper } from "../../sharedComponents/LogoWrapper/LogoWrapper";

interface IActivationDetails {
  authId: string;
  validity: number;
  localization?: string;
}

export const AccountActivation: FC<{
  pageSlug?: string[];
  cmsPageName?: string[];
}> = ({ pageSlug, cmsPageName }) => {
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  const [queryParameters] = useSearchParams();
  const [activationDetails, setActivationDetails] =
    useState<IActivationDetails | null>(null);
  const [activationState, setActivationState] = useState<number>(0);
  const navigate = useNavigate();
  const [pageCmsData, setPageCmsData] =
    useState<IAccountActivationPageAttributes | null>(null);

  useEffect(() => {
    document.getElementById("root")!.className =
      "genericWrapper loginWrapperWhole";
    setLoaderState("active");
    try {
      let activationDetails = queryParameters.get("activation")!;
      setActivationDetails(
        JSON.parse(window.atob(activationDetails.toString()))
      );
    } catch (error) {
      setLoaderState("inactive");
    }
    let tmpPageData;

    (async () => {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      tmpPageData = getPageSpecificData(cmsData, pageSlug![0]);
      tmpPageData &&
        setPageCmsData(
          tmpPageData[0]?.attributes as IAccountActivationPageAttributes
        );
    })();

    (async () => {
      await cmsService.fetchCmsData(
        cmsData,
        setCmsData,
        cmsPageName,
        pageSlug
      );
      tmpPageData = getPageSpecificData(cmsData, pageSlug![0]);
      tmpPageData && setPageCmsData(tmpPageData[0]?.attributes as IAccountActivationPageAttributes);
    })();

    return () => {
      document.getElementById("root")!.className = "";
    };
  }, []);

  useEffect(() => {
    const currentDate = +new Date();
    if (activationDetails && currentDate < activationDetails.validity) {
      (async () => {
        try {
          const result = await userService.accountActivation({
            auth_id: activationDetails.authId,
          });

          setLoaderState("inactive");
          setActivationState(result?.messageId ?? 0);
          if (![-2, 1, 2].includes(result.messageId)) {
            loggerService.log({
              severity: "High",
              message: `Account activation failed`,
              payload: {
                ApiResponse: result.message,
              },
            });
          }
        } catch (err) {
          setLoaderState("inactive");
          loggerService.log({
            severity: "High",
            message: `api endpoint failure`,
            payload: err,
          });
        }
      })();
    } else if (activationDetails) {
      setActivationState(2);
      setLoaderState("inactive");
    }
  }, [activationDetails]);

  const handleClick = async () => {
    if (activationState <= 1) {
      navigate(
        `/?localization=${queryParameters.get(
          "localization"
        )}&country=${queryParameters.get("country")}`
      );
    } else {
      try {
        const result = await userService.accountActivationResendEmail({
          auth_id: activationDetails?.authId ?? "",
        });

        setLoaderState("inactive");
        setActivationState(result?.messageId ?? 0);
        if (result.messageId !== 1) {
          loggerService.log({
            severity: "High",
            message: `Resend activation link failed`,
            payload: {
              ApiResponse: result.message,
            },
          });
        }
      } catch (err) {
        setLoaderState("inactive");
        loggerService.log({
          severity: "High",
          message: `api endpoint failure`,
          payload: err,
        });
      }
    }
  };

  const getHeader = (state: number) => {
    if (state === 1) {
      return pageCmsData?.lbl_activation_successful_title;
    } else if (state === 2) {
      return pageCmsData?.lbl_expired_title;
    } else if (state === -2) {
      return pageCmsData?.lbl_activated_title;
    } else {
      return pageCmsData?.lbl_resend_success_title;
    }
  };

  const getMessage = (state: number) => {
    if (state === 1) {
      return pageCmsData?.lbl_activation_successful_message;
    } else if (state === 2) {
      return pageCmsData?.lbl_expired_message;
    } else if (state === -2) {
      return pageCmsData?.lbl_activated_message;
    } else {
      return pageCmsData?.lbl_resend_success_message;
    }
  };

  const onLanguageChange = () => {
    if (pageSlug) {
      let pageSpecificData = cmsData.pageSpecificData;
      const tmpPageData = getPageSpecificData(pageSpecificData, pageSlug[0]);
      tmpPageData &&
        setPageCmsData(
          tmpPageData[0].attributes as IAccountActivationPageAttributes
        );
    }
  };

  return (
    <LogoWrapper className="signupPage">
      <div className="signUpBlk loginBlk">
        <h1>
          <a
            href={`${process.env.REACT_APP_PLATFORM_URL}`}
            className="logoDefault"
            aria-label="logo"
          />
        </h1>
        <ThemeSelector
          pageSlug={pageSlug}
          onLanguageChange={onLanguageChange}
          cmsPageName={cmsPageName}
          languageArg={queryParameters.get("localization")!}
          countryArg={queryParameters.get("country")!}
        />
        <h2>{getHeader(activationState)}</h2>
        <p>{getMessage(activationState)}</p>

        {[-2, 1, 2].includes(activationState) && (
          <button className="btnStyle1 btnInline" onClick={() => handleClick()}>
            {activationState > 1
              ? pageCmsData?.btn_resend
              : pageCmsData?.btn_login}
          </button>
        )}
      </div>
    </LogoWrapper>
  );
};
