import { Spin } from "antd";
import gradientLoader from "../../../assets/images/gradient-loader.svg";
const ComponentLoader: React.FC<{ isLoading: boolean; children?: any }> = (
  props
) => {
  return (
    <Spin
      className="spinGradientLoader"
      spinning={props.isLoading}
      indicator={
        // <div className="gradientLoader">
        //   <img src={gradientLoader} alt="" />
        // </div>

        <div className="customLoader"></div>
      }
    >
      {props.children}
    </Spin>
  );
};

export default ComponentLoader;
