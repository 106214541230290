import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Button, Space, Tooltip, notification, message } from 'antd';
import io from 'socket.io-client';
import {
  addOverlay,
  getCurrentOrganization,
  getPageSpecificData,
  removeOverlay,
} from '../../../../utils/commonFunctions';
import {
  IApiResponse,
  IEmptyProjectDashboardAttributes,
  IFolderData,
  ILoaderState,
  IProjectState,
  ITaskStatus,
} from '../../../../models/interface';
import {
  cmsService,
  customizationService,
  dashboardService,
  loggerService,
} from '../../../../services';
import EmptyProjectDashboard from './EmptyProjectDashboard';
import {
  useDashboardStore,
  useLoaderStore,
  useLocalizationStore,
  useProjectDashboardStore,
  useTaskStatusStore,
  useUserStore,
} from '../../../../stores';
import NewSectionCta from './Sections/NewSectionCta';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SectionsWrapper from './Sections/SectionsWrapper';
import cryptoRandomString from 'crypto-random-string';
import BrowserStorageService from 'services/browserStorage.service';
import { ComponentLoader } from 'components/sharedComponents';
import UpdateProject from '../UpdateProject';
import {
  generateNewListForSelectedProjectInFolderProjectList,
  generateNewListFromCreateFolder,
} from 'utils/generateNewList';
import { projectService } from '../../../../services/';
import { NavRoutesEnum } from 'models/enums/navRoutes';
import { useOrganizationRoutes } from 'routes/orgRoutes';
import {
  IGetStickerPackListApiResponse,
  IPackDetail,
} from 'models/interface/customization.interface';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import CloneProject from '../CloneProject';
import {
  getMessageThreadByProject,
  updateProjectName,
} from 'utils/firebaseCommonFunctions';
import DisplayProjectMember from './Sections/DisplayProjectMember/DisplayProjectMember';
import ProjectSettingsCta from './ProjectSettingsCta';
import ProjectMeatballMenu from './ProjectMeatballMenu';
import classNames from 'classnames';
import organizationService from 'services/OrganizationService/Organization.service';
const socket = io(process.env.REACT_APP_SOCKET_URL!, {
  withCredentials: true,
});

type TProjectPalette = {
  color_id: number;
  color_name: string;
  color_rank: number;
  hex_code: string;
  rgb_value: string;
};

const ProjectDashboardWrapper: FC<{
  pageSlug?: string[];
  cmsPageName?: string[];
}> = ({ pageSlug, cmsPageName }) => {
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const {
    projectDetails,
    setProjectDetails,
    setTaskListCmsData,
    setMemopadCmsData,
    fetchTaskStatusMaster,
    fetchTaskPriorityMaster,
    isLoadingMasterData,
    fetchProjectMembers,
    setGridListCmsData,
    setCustomizeSectionUpdateCmsData,
    setMessagingCmsData,
    projectMembers,
    setPackList,
    taskListCmsData,
    setCustomfieldCmsData,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);
  const { setTaskStatusCmsData } = useTaskStatusStore(
    (state: ITaskStatus) => state,
  );
  const {
    folderList,
    setFolderList,
    setUserList,
    setFolderProjectList,
    folderProjectList,
  } = useDashboardStore((state: any) => state);
  const urlParams = useParams();
  const browserStorage = BrowserStorageService.getInstance();
  const [isLoadingProjectDetails, setIsLoadingProjectDetails] = useState(true);
  const { setRefreshDashboardFolders } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const [projectDetailsPageSpecificData, setProjectDetailsPageSpecificData] =
    useState<IEmptyProjectDashboardAttributes | null>(null);
  const [projectUpdatePageSpecificData, setProjectUpdatePageSpecificData] =
    useState<any>(null);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentProjectName, setCurrentProjectName] = useState<string>('');
  const { hasPermissions } = useRbac();
  const [sidePanelMode, setSidePanelMode] = useState('update');
  const [showSettingDropDown, setShowSettingDropDown] =
    useState<boolean>(false);
  const [showProjectMemberDropdown, setShowProjectMemberDropdown] =
    useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const navigate = useNavigate();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const refDiv = useRef<HTMLDivElement>(null);

  const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
  const [msg, contextMsg] = message.useMessage({ maxCount: 1 });
  const [currentId, setCurrentId] = useState('');
  const sectionRef = useRef<any>(null);
  const [duplicateProjectName, setDuplicateProjectName] =
    useState<boolean>(false);
  const setIsSectionEditing = () => {
    sectionRef.current?.setIsEditing(false);
  };
  const getNewEmptySection = () => {
    return {
      section_id: cryptoRandomString({ length: 10 }),
      section_type_id: 1,
      created_by: userDetails.user_id,
      section_rank: 1,
    };
  };

  const returnProjectIcon = () => {
    if (projectDetails?.is_archived === true) {
      return 'archiveIcon';
    }
    if (projectDetails?.is_favorite) {
      return 'isFavorite';
    }
    return 'star';
  };

  const checkWhetherUserPresentInMemberList = () => {
    if (
      projectMembers !== undefined &&
      projectMembers !== null &&
      projectMembers.length > 0
    ) {
      if (!projectMembers.find((itm) => itm.user_id === userDetails.user_id)) {
        setRefreshDashboardFolders(true);
        navigate(`/org/${org_key}/dashboard`);
      }
    }
  };

  const checkUserAllowance = (response: any) => {
    let members = browserStorage.getProjectMembers();
    let membersArray = members ? JSON.parse(members) : [];
    if (
      response &&
      membersArray.find(
        (itm: any) => itm.user_id === userDetails.user_id && itm.role_id !== 3,
      ) &&
      response.is_archived === true
    ) {
      sessionStorage.setItem(
        'lastLocation',
        `/org/${location.pathname.split('/')[2]}/${NavRoutesEnum.DASHBOARD}`,
      );
      window.location.replace(
        `/org/${location.pathname.split('/')[2]}/${NavRoutesEnum.DASHBOARD}`,
      );
    } else {
      setProjectDetails(response);
    }
  };

  const getProjectDetails = async (project_id: string) => {
    setIsLoadingProjectDetails(true);
    setProjectDetails(null);
    try {
      const response = await projectService.getProjectDetails({
        project_id: project_id,
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
      });
      if (response.messageId === 1) {
        await fetchProjectMembers({
          project_id: urlParams.projectId ?? '',
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
        });
        checkUserAllowance(response.data);
        setIsLoadingProjectDetails(false);
      } else {
        navigate(`${organizationBaseRouteUrl}/${NavRoutesEnum.DASHBOARD}`);
      }
    } catch (error) {
      setIsLoadingProjectDetails(false);
      navigate(`${organizationBaseRouteUrl}/${NavRoutesEnum.DASHBOARD}`);
      await loggerService.log({
        severity: 'High',
        message: 'Failed to fetch project details',
        payload: { project_id: project_id },
      });
    }
  };

  const getPageSpecificCmsData = async (pageSlugInfo: string[]) => {
    let projectCmsData: any = getPageSpecificData(cmsData, pageSlugInfo[1]);
    let newTaskListCmsData: any = getPageSpecificData(cmsData, pageSlugInfo[2]);
    let projectUpdateCmsData: any = getPageSpecificData(
      cmsData,
      pageSlugInfo[3],
    );
    let memopadCmsData: any = getPageSpecificData(cmsData, pageSlugInfo[4]);
    let gridListCmsData: any = getPageSpecificData(cmsData, pageSlugInfo[5]);
    let customfieldCmsData: any = getPageSpecificData(
      cmsData,
      pageSlugInfo[11],
    );
    let customizeSectionUpdateCmsData: any = getPageSpecificData(
      cmsData,
      pageSlugInfo[7],
    );
    let taskStatusCmsData: any;

    let messagingCmsData: any = getPageSpecificData(cmsData, pageSlugInfo[8]);
    if (
      !browserStorage.getGlobalData() ||
      !projectCmsData ||
      !newTaskListCmsData ||
      !memopadCmsData ||
      !projectUpdateCmsData ||
      !gridListCmsData ||
      !customizeSectionUpdateCmsData ||
      !messagingCmsData ||
      !customfieldCmsData
    ) {
      setLoaderState('active');
      await cmsService.fetchCmsData(
        cmsData,
        setCmsData,
        cmsPageName,
        pageSlugInfo,
      );
      projectCmsData = getPageSpecificData(cmsData, pageSlugInfo[1]);
      newTaskListCmsData = getPageSpecificData(cmsData, pageSlugInfo[2]);
      projectUpdateCmsData = getPageSpecificData(cmsData, pageSlugInfo[3]);
      memopadCmsData = getPageSpecificData(cmsData, pageSlugInfo[4]);
      gridListCmsData = getPageSpecificData(cmsData, pageSlugInfo[5]);
      customfieldCmsData = getPageSpecificData(cmsData, pageSlugInfo[11]);
      customizeSectionUpdateCmsData = getPageSpecificData(
        cmsData,
        pageSlugInfo[7],
      );
      messagingCmsData = getPageSpecificData(cmsData, pageSlugInfo[8]);
    }
    projectCmsData &&
      setProjectDetailsPageSpecificData(projectCmsData[0]?.attributes);
    newTaskListCmsData && setTaskListCmsData(newTaskListCmsData[0]?.attributes);
    projectUpdateCmsData &&
      setProjectUpdatePageSpecificData(projectUpdateCmsData[0]?.attributes);
    memopadCmsData && setMemopadCmsData(memopadCmsData[0]?.attributes);
    gridListCmsData && setGridListCmsData(gridListCmsData[0]?.attributes);
    customizeSectionUpdateCmsData &&
      setCustomizeSectionUpdateCmsData(
        customizeSectionUpdateCmsData[0]?.attributes,
      );
    messagingCmsData && setMessagingCmsData(messagingCmsData[0]?.attributes);
    customfieldCmsData &&
      setCustomfieldCmsData(customfieldCmsData[0]?.attributes);
    taskStatusCmsData = getPageSpecificData(cmsData, pageSlugInfo[9]);
    setTaskStatusCmsData(taskStatusCmsData[0]?.attributes);
    setLoaderState('inactive');
  };

  useEffect(() => {
    setLoaderState('active');
    fetchTaskStatusMaster();
    fetchTaskPriorityMaster();
    fetchStickerPackList();

    /**
     * for Real time notification
     * socket.on("receiveSectionUpdate", (sectionUpdatePayload: any) => {
      actionOnNotification(sectionUpdatePayload);
    });

    socket.on("receiveSimpleTaskDelete", (simpleTaskDeletePayload: any) => {
      actionOnNotification(simpleTaskDeletePayload);
    });

    socket.on("receiveSimpleTaskUpdate", (simpleTaskUpdatePayload: any) => {
      actionOnNotification(simpleTaskUpdatePayload);
    });*/

    // socket.on('receiveProjectArchive', (projectArchivePayload: any) => {
    //   actionOnNotification(projectArchivePayload, true);
    // });

    return () => {
      /**
       * For real time notification
       * socket.off("receiveSectionUpdate");
      socket.off("receiveSimpleTaskUpdate");
      socket.off("receiveSimpleTaskUpdate");*/
      //socket.off('receiveProjectArchive');
      localStorage.removeItem('currentMessageId');
      removeOverlay();
    };
  }, []);

  useEffect(() => {
    checkWhetherUserPresentInMemberList();
  }, [projectMembers]);

  useEffect(() => {
    (async () => {
      if (urlParams.projectId) {
        await getProjectDetails(urlParams.projectId);
        await fetchTeamMembers();
        if (folderList?.length === 0) {
          await fetchFolderList();
        }
      }
    })();
  }, [urlParams]);

  useEffect(() => {
    (async () => {
      if (
        !projectDetailsPageSpecificData &&
        !projectUpdatePageSpecificData &&
        pageSlug
      ) {
        await getPageSpecificCmsData(pageSlug);
      }
    })();
  }, [projectDetailsPageSpecificData, projectUpdatePageSpecificData]);

  useEffect(() => {
    if (showSidePanel === true) {
      addOverlay();
    } else {
      removeOverlay();
    }
  }, [showSidePanel]);

  // useEffect(() => {
  //   if (isEdit) {
  //     setCurrentProjectName(projectDetails?.project_name!);
  //   }
  // }, [isEdit]);

  useEffect(() => {
    if (projectDetails?.project_name) {
      setCurrentProjectName(projectDetails.project_name);
    }
  }, [projectDetails?.project_name, isEdit]);

  const fetchFolderList = async () => {
    try {
      let payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        userId: userDetails.user_id,
      };

      let response: IApiResponse = await dashboardService.getFolder(payload);
      let folders: IFolderData[] = response.data.folders as IFolderData[];

      if (folders) {
        setFolderList(folders);
      }
    } catch (err) {
      await loggerService.log({
        severity: 'High',
        message: `Folder list get action failed`,
        payload: { ApiResponse: err },
      });
    }
  };

  const fetchUserList = async () => {
    try {
      let payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
      };

      let response: any = await dashboardService.getUserList(payload);
      let list: any[] = response.data.users;

      if (list) {
        setUserList(list);
      }
    } catch (err) {
      await loggerService.log({
        severity: 'High',
        message: `User list get action failed`,
        payload: { ApiResponse: err },
      });
    }
  };
  const fetchTeamMembers = async () => {
    try {
      const res = await organizationService.getTeamDetail({
        org_key,
        is_desc: 'false',
      });
      let list: any[] = [
        ...res.data.accepted_members,
        ...res.data.pending_members.map((m: any) => ({ ...m, inactive: true })),
      ];

      if (list) {
        setUserList(list);
      }
    } catch (err) {
      await loggerService.log({
        severity: 'High',
        message: `Team members get action failed`,
        payload: { ApiResponse: err },
      });
    }
  };

  const fetchStickerPackList = async () => {
    try {
      const localizationData = browserStorage.getLocalization();

      let payload = {
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        languageCode: localizationData?.language ?? 'en',
      };

      let response: IGetStickerPackListApiResponse =
        await customizationService.getStickerList(payload);
      let packDetails: IPackDetail[] = response.data.pack_details;

      if (packDetails) {
        setPackList(packDetails);
      }
    } catch (error) {
      await loggerService.log({
        severity: 'High',
        message: `Sticker pack list get action failed`,
        payload: { ApiResponse: error },
      });
    }
  };

  const changeProjectName = async () => {
    if (currentProjectName.trim().length > 3) {
      try {
        if (
          currentProjectName.trim() !== projectDetails?.project_name?.trim()
        ) {
          setIsLoadingProjectDetails(true);

          const response = await projectService.updateProjectName({
            projectId: +urlParams.projectId!,
            organizationId: getCurrentOrganization(
              org_key,
              userDetails?.organization_info,
            )?.organization_id!,
            userId: userDetails.user_id,
            projectName: currentProjectName.trim(),
          });

          if (response.messageId === 1) {
            setDuplicateProjectName(false);
            await getProjectDetails(urlParams.projectId!);
            setIsEdit(false);
            setCurrentProjectName('');
            let updatedProjectFolderListV1 = generateNewListFromCreateFolder(
              response.data.project_folder_list,
              folderProjectList,
            );
            let updatedProjectFolderListV2 =
              generateNewListForSelectedProjectInFolderProjectList(
                +urlParams.projectId!,
                updatedProjectFolderListV1,
              );
            setFolderProjectList(updatedProjectFolderListV2);

            const responseFireStore = await getMessageThreadByProject(
              +getCurrentOrganization(org_key, userDetails?.organization_info)
                ?.organization_id!,
              projectDetails?.project_id!,
            );

            let messageThreadId: string[] = [];

            responseFireStore.forEach((doc: any) => {
              messageThreadId.push(doc.id);
            });

            for (let id of messageThreadId) {
              await updateProjectName(id, currentProjectName.trim());
            }
          } else {
            setIsLoadingProjectDetails(false);
            setDuplicateProjectName(true);
            // document
            //   .getElementById('inlineProjectEditInputField')
            //   ?.classList.add('duplicateProjectNameError');
          }
        } else {
          setIsEdit(false);
          setCurrentProjectName('');
        }
      } catch (ex: any) {
        setIsLoadingProjectDetails(false);
        await loggerService.log({
          severity: 'High',
          message: 'Failed to update project name',
          payload: { project_id: +urlParams.projectId! },
        });
      }
    }
  };

  const toggleFavorite = async () => {
    if (userDetails) {
      const userId = userDetails?.user_id;
      const organizationId = getCurrentOrganization(
        org_key,
        userDetails?.organization_info,
      )?.organization_id!;
      const projectId = projectDetails?.project_id!;

      await projectService.toggleProjectFavorite({
        projectId,
        userId,
        organizationId,
      });
    }
  };

  const updateProjectColor = async (selectedColor: TProjectPalette) => {
    const oldColor = projectDetails?.project_color;
    setProjectDetails({
      ...projectDetails,
      project_color: selectedColor?.hex_code,
    });
    try {
      if (oldColor !== selectedColor.hex_code) {
        const result = await projectService.updateProjectColor({
          organization_id: projectDetails?.organization_id,
          user_id: userDetails?.user_id,
          project_id: projectDetails?.project_id,
          project_color: selectedColor?.hex_code,
        });
        if (result.messageId !== 1) {
          msg.error({
            content: taskListCmsData?.color_menu.error_msg,
          });

          setProjectDetails({ ...projectDetails, project_color: oldColor });
        }
      }
    } catch (error) {
      console.error(error);
      msg.error({
        content: taskListCmsData?.color_menu.error_msg,
      });
      setProjectDetails({ ...projectDetails, project_color: oldColor });
    }
  };

  const renderProjectErrorText = () => {
    if (duplicateProjectName) {
      return (
        <span className="projectNameErrorText">
          {projectUpdatePageSpecificData?.lbl_error_message_project_name_exists}
        </span>
      );
    }
    if (currentProjectName?.trim()?.length < 4) {
      return (
        <span className="projectNameErrorText">
          {
            projectUpdatePageSpecificData?.lbl_error_message_project_name_validation
          }
        </span>
      );
    }
    return null;
  };

  return (
    <Fragment>
      {contextHolder}
      {contextMsg}

      <div className="mainContent">
        <ComponentLoader
          isLoading={isLoadingProjectDetails || isLoadingMasterData}
        >
          <div className="contentHeadingPanel">
            <div className="CNPScreen">
              <div className="itemNameBlk">
                <span className="sidePanelIcon layers"></span>
                {isEdit &&
                  hasPermissions(
                    [ERbacPermissions.PROJECT_EDIT],
                    projectDetails?.associated_role_id,
                  ) &&
                  !projectDetails?.is_archived ? (
                  <div>
                    <input
                      id="inlineProjectEditInputField"
                      className={classNames(
                        'inlineProjectEditInputFieldClass',
                        {
                          projectNameError:
                            currentProjectName.trim()?.length < 4 ||
                            duplicateProjectName,
                        },
                      )}
                      maxLength={50}
                      value={currentProjectName}
                      autoFocus
                      onChange={(e) => {
                        setCurrentProjectName(e.target.value);
                        if (duplicateProjectName) {
                          setDuplicateProjectName(false);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          (async () => {
                            await changeProjectName();
                          })();
                        }
                      }}
                      onBlur={() => {
                        (async () => await changeProjectName())();
                      }}
                    />
                    {renderProjectErrorText()}
                  </div>
                ) : (
                  <Fragment>
                    {projectDetails?.project_name &&
                      projectDetails.project_name.length < 40 ? (
                      <span
                        className="itemName"
                        onClick={() => setIsEdit(true)}
                        onKeyDown={() => { }}
                      >
                        {projectDetails?.project_name}
                      </span>
                    ) : (
                      <Tooltip
                        title={projectDetails?.project_name}
                        color={'#2E364C'}
                        placement="bottom"
                      >
                        <span
                          className="itemName"
                          onClick={() => setIsEdit(true)}
                          onKeyDown={() => { }}
                        >
                          {projectDetails?.project_name}
                        </span>
                      </Tooltip>
                    )}
                    <span
                      onClick={() => {
                        if (projectDetails?.is_archived === false) {
                          toggleFavorite();
                        }
                      }}
                      onKeyDown={() => { }}
                      className={`cmnIcon ${returnProjectIcon()}`}
                    ></span>
                    {projectDetails?.is_archived === false && (
                      <ProjectMeatballMenu
                        updateProjectColor={updateProjectColor}
                      />
                    )}
                  </Fragment>
                )}
              </div>
              {projectDetailsPageSpecificData && (
                <div className="contentHeadingRightPart">
                  {projectMembers && (
                    <DisplayProjectMember
                      setShowDropdown={setShowDropdown}
                      setShowSettingDropDown={setShowSettingDropDown}
                      pageSpecificationData={projectDetailsPageSpecificData}
                      projectMembers={projectMembers}
                      showProjectMemberDropdown={showProjectMemberDropdown}
                      setShowProjectMemberDropdown={
                        setShowProjectMemberDropdown
                      }
                    />
                  )}
                  <Rbac
                    allowedPermissions={[
                      ERbacPermissions.PROJECT_SECTION_CREATE,
                    ]}
                    project_role_id={projectDetails?.associated_role_id}
                  >
                    <NewSectionCta
                      pageSpecificationData={projectDetailsPageSpecificData}
                      getNewEmptySection={getNewEmptySection}
                      setShowSidePanel={setShowSidePanel}
                      setSidePanelMode={setSidePanelMode}
                      showDropdown={showDropdown}
                      setShowDropdown={setShowDropdown}
                      setShowProjectMemberDropdown={
                        setShowProjectMemberDropdown
                      }
                      setShowSettingDropDown={setShowSettingDropDown}
                      refDiv={refDiv}
                      setCurrentId={setCurrentId}
                      setIsSectionEditing={setIsSectionEditing}
                    />
                  </Rbac>
                  <ProjectSettingsCta
                    pageSpecificationData={projectDetailsPageSpecificData}
                    showSettingDropDown={showSettingDropDown}
                    setShowSettingDropDown={setShowSettingDropDown}
                    setShowDropdown={setShowDropdown}
                    setShowSidePanel={setShowSidePanel}
                    setShowProjectMemberDropdown={setShowProjectMemberDropdown}
                    setSidePanelMode={setSidePanelMode}
                    setIsLoadingProjectDetails={setIsLoadingProjectDetails}
                    getProjectDetails={async () =>
                      await getProjectDetails(urlParams.projectId!)
                    }
                    socket={socket}
                    msg={msg}
                    messageData={getPageSpecificData(
                      cmsData,
                      'archive-project',
                    )}
                  />
                </div>
              )}
            </div>
          </div>
          {projectDetailsPageSpecificData &&
            (!projectDetails?.sections ||
              projectDetails?.sections?.length === 0) &&
            !isLoadingProjectDetails && (
              <Rbac
                allowedPermissions={[ERbacPermissions.PROJECT_SECTION_CREATE]}
                project_role_id={projectDetails?.associated_role_id}
              >
                <EmptyProjectDashboard
                  pageSpecificationData={projectDetailsPageSpecificData}
                  getNewEmptySection={getNewEmptySection}
                />
              </Rbac>
            )}
          {projectDetails?.sections?.length && (
            <Rbac
              allowedPermissions={[
                ERbacPermissions.PROJECT_VIEW_ALL,
                ERbacPermissions.PROJECT_VIEW_ASSIGNED,
                ERbacPermissions.PROJECT_SECTION_VIEW,
              ]}
              project_role_id={projectDetails?.associated_role_id}
            >
              <div
                className="test"
                ref={refDiv}
                style={{ position: 'sticky' }}
              ></div>
              <SectionsWrapper
                /*socket={socket}*/ refDiv={refDiv}
                setCurrentId={setCurrentId}
                currentId={currentId}
                sectionRef={sectionRef}
              />
            </Rbac>
          )}
          {sidePanelMode === 'update' ? (
            <UpdateProject
              pageSpecificDataState={projectUpdatePageSpecificData}
              showSidePanel={showSidePanel}
              setShowSidePanel={setShowSidePanel}
              getProjectDetails={async () =>
                await getProjectDetails(urlParams.projectId!)
              }
            />
          ) : null}
          {sidePanelMode === 'clone' ? (
            <CloneProject
              pageSpecificDataState={projectUpdatePageSpecificData}
              showSidePanel={showSidePanel}
              setShowSidePanel={setShowSidePanel}
              getProjectDetails={async () =>
                await getProjectDetails(urlParams.projectId!)
              }
            />
          ) : null}
        </ComponentLoader>
      </div>
    </Fragment>
  );
};

export default ProjectDashboardWrapper;
