import { EventContentArg } from "@fullcalendar/core";
import { Tooltip } from "antd";
import { IPlannerAttributes } from "models/interface";

interface ICalendarEventTag {
  arg: EventContentArg;
  options: {
    plannerView?: number;
    showWeekends?: boolean;
  };
  plannerCmsData?: IPlannerAttributes;
}
const ExternalCalendarEventTag: React.FC<ICalendarEventTag> = ({
  arg,
  options,
  plannerCmsData,
}) => {
  // console.log(arg)
 return (
      <div className="plannerEventContent">
        <Tooltip
          title={
            arg.event.extendedProps.is_personal
              ? plannerCmsData?.lbl_personal_team
              : arg.event.extendedProps.organization_name
          }
        >
         <span className="cmnIcon googleIcon"></span>
        </Tooltip>
          <Tooltip title={arg.event.title}>
            <div className="plannerEventTitle" style={{ color: "#000" }}>
              {arg.event.title}
            </div>
          </Tooltip>
      </div>
    );
};

export default ExternalCalendarEventTag;
