import { FC } from 'react';
import { IProjectCreationAttributes } from 'models/interface';
import { AnalyticsEventTracker } from 'components/sharedComponents';
import { modeOps } from 'models/enums/constants';

const RightSideDrawerCTA: FC<{
  pageSpecificDataState?: IProjectCreationAttributes | null;
  closeRightPanel: any;
  isDisabled: boolean;
  mode: string;
}> = ({ pageSpecificDataState, closeRightPanel, isDisabled, mode }) => {
  return (
    <div className="btnPart">
      <div className="cancelCreate">
        <button
          type="button"
          className="noStyle"
          onClick={() => closeRightPanel()}
        >
          {pageSpecificDataState?.btn_cancel_cta}
        </button>
        <button type="submit" className="btnStyle1" disabled={isDisabled}>
          {mode !== modeOps.CLONE
            ? pageSpecificDataState?.btn_create_project_cta
            : pageSpecificDataState?.btn_clone_new_project_cta}
        </button>
        <AnalyticsEventTracker />
      </div>
    </div>
  );
};

export default RightSideDrawerCTA;
