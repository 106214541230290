import { create } from "zustand";
import { IOrganization, IProjectPermissions, IUserDetails, IUserState } from "../models/interface";

export const useUserStore = create<IUserState>((set) => ({
  //initial states
  userDetails: null,
  organizations: null,
  projectRolePermissionsList: null,
  currentOrgKey: null,
  permissions: null,

  //actions
  setUserDetails: (
    value: IUserDetails | null
  ) =>
    set({
      userDetails: value
    }),
  setOganizations: (value: IOrganization[] | null) =>
    set({ organizations: value }),
  setCurrentOrgKey: (value: string | null) => set({ currentOrgKey: value }),
  setProjectRolePermissionsList: (value: IProjectPermissions[] | null) => set({projectRolePermissionsList: value}),
  setPermissions: (value: string[] | null) => set({permissions: value})
}));
