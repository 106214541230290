import { FormEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import feedbackService from 'services/pncService/feedback.service';
import GenerateCode from './GenerateCode';
import { ComponentLoader } from 'components/sharedComponents';
import { useProjectDashboardStore } from 'stores';
import { useFeedbackStore } from 'stores/feedback.store';
import { usePncCmsDataStore } from 'stores/pncCmsData.store';
import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

interface FormData {
  environmentName: string;
  url: string;
}

const AddEnvironmentPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormData>({ mode: 'onChange' });
  const [script, setScript] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [generateCode, setGenerateCode] = useState<string>('');
  const [envId, setEnvId] = useState<number>(0);
  const [isEnvExits, setIsEnvExits] = useState(false);
  const environmentName = watch('environmentName');
  const navigate = useNavigate();
  const { org, projectId } = useParams();
  const url = watch('url');
  const isButtonDisabled =
    isLoading ||
    !environmentName ||
    !url ||
    !!errors.environmentName ||
    !!errors.url ||
    isEnvExits;
  const { projectDetails } = useProjectDashboardStore();
  const { envFilterData, setEnvFilterData } = useFeedbackStore(
    (state: any) => state,
  );
  const { pncAddEnvSpecificCmsData } = usePncCmsDataStore();

  const onSubmit = async (data: FormData) => {
    try {
      setIsLoading(true);
      const payload = {
        organizationId: projectDetails?.organization_id,
        projectId: projectDetails?.project_id,
        ...data,
      };
      const response: any = await feedbackService.createEnvironment(payload);
      if (response?.messageId === 1) {
        reset();
        setEnvFilterData([
          ...envFilterData,
          {
            environment_id: parseInt(response.data?.env_id),
            environment_name: data.environmentName,
            url: data.url,
          },
        ]);
        setGenerateCode(response.data?.script);
        setEnvId(parseInt(response.data?.env_id));
        setScript(true);
        message.success(
          pncAddEnvSpecificCmsData?.toaster_msgs?.success_create_new_env,
        );
      } else if (response?.messageId === -4) {
        setIsEnvExits(true);
      } else {
        message.error(
          pncAddEnvSpecificCmsData?.toaster_msgs?.error_create_new_env,
        );
        console.log(response);
      }
    } catch (error) {
      console.log(error);
      message.error(
        pncAddEnvSpecificCmsData?.toaster_msgs?.error_create_new_env,
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event: FormEvent<HTMLInputElement>) => {
    if (isEnvExits && event.currentTarget.value.trim() !== '') {
      setIsEnvExits(false);
    }
  };

  return (
    <div
      className={`pncEnvScreenWrap ${
        generateCode !== '' ? 'generateCode' : ''
      }`}
    >
      <div className="loginBlk">
        <ComponentLoader isLoading={isLoading}>
          <div className="headerBlk">
            <div className="logoBlk">
              <h1 className="gradientText">Point and Comment</h1>
            </div>
          </div>
          {script ? (
            <GenerateCode script={generateCode} envId={envId} />
          ) : (
            <>
              <div className="headerBlk">
                <h2>{pncAddEnvSpecificCmsData?.add_env_heading}</h2>
                <p>{pncAddEnvSpecificCmsData?.add_env_subheading}</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="fldWrap">
                  <input
                    type="text"
                    className={`userEmail noIcon ${
                      errors.environmentName ? 'fldError' : ''
                    }`}
                    placeholder={`${pncAddEnvSpecificCmsData?.lbl_input_env_name_placeholder}`}
                    maxLength={50}
                    {...register('environmentName', {
                      required: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_name_required}`,
                      validate: (value) =>
                        !!value.trim() ||
                        `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_name_required}`,
                      pattern: {
                        value: /^[A-Za-z0-9@_/-]+$/,
                        message: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_name_validation}`,
                      },
                      onChange: (e: FormEvent<HTMLInputElement>) =>
                        handleInputChange(e),
                    })}
                  />
                  <label>{pncAddEnvSpecificCmsData?.lbl_input_env_name}</label>
                  {errors.environmentName && (
                    <span className="fldMsg">
                      {errors.environmentName.message}
                    </span>
                  )}
                </div>
                <div className="fldWrap fldUrl">
                  <input
                    type="text"
                    className={`userEmail ${errors.url ? 'fldError' : ''}`}
                    placeholder="https://www.cleversort.com"
                    {...register('url', {
                      required: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_url_validation}`,
                      pattern: {
                        value: /^(http|https):\/\/[^ "]+(\.[a-zA-Z]+)$/,
                        message: `${pncAddEnvSpecificCmsData?.error_env_input_fields?.env_url_validation}`,
                      },
                      onChange: (e: FormEvent<HTMLInputElement>) =>
                        handleInputChange(e),
                    })}
                  />
                  <span className="material-icons-outlined">language</span>
                  {watch('url') && (
                    <span
                      className="material-icons-outlined right cursorPointer"
                      onClick={() => reset({ url: '' })}
                    >
                      cancel
                    </span>
                  )}
                  <label>{pncAddEnvSpecificCmsData?.lbl_input_env_url}</label>
                  {errors.url && (
                    <span className="fldMsg">{errors.url.message}</span>
                  )}

                  {isEnvExits && (
                    <div className="fldError">
                      {
                        pncAddEnvSpecificCmsData?.error_env_input_fields
                          ?.env_root_unique
                      }
                    </div>
                  )}
                </div>
                <div className="pncAddEnvbtnSec">
                  <button
                    type="button"
                    onClick={() =>
                      navigate(
                        envFilterData?.length === 0
                          ? `/org/${org}/dashboard`
                          : `/org/${org}/project/${projectId}/pnc/feedback-board`,
                      )
                    }
                    className="noStyle"
                  >
                    {pncAddEnvSpecificCmsData?.btn_cta_cancel}
                  </button>
                  <button
                    type="submit"
                    className="btnStyle1 buttonLoader"
                    disabled={isButtonDisabled}
                  >
                    {pncAddEnvSpecificCmsData?.btn_cta_generate_code}
                  </button>
                </div>
              </form>
            </>
          )}
        </ComponentLoader>
      </div>
    </div>
  );
};

export default AddEnvironmentPage;
