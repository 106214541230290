import { FC } from "react";
import { useLoaderStore } from "../../../stores";
import { ILoaderState } from "../../../models/interface";
import gradientLoader from "../../../assets/images/gradient-loader.svg";
const FullPageLoader: FC<{}> = () => {
  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  return (
    // <div className={`gradientLoader ${loaderState}`}>
    //   <img src={gradientLoader} alt="" />
    // </div>
    <div className="mainContent">
      <div className={`customLoader ${loaderState}`}></div>
    </div>
  );
};

export default FullPageLoader;
