import { FC, Fragment } from "react";
import { Tooltip } from "antd";
import { IGridList, ITaskList, ITaskListDetails } from "models/interface";

const TaskNameInputField: FC<{
  sectionDetails: IGridList | ITaskList;
  taskDetails: ITaskListDetails;
}> = ({
  taskDetails,
  sectionDetails,
}) => { 

  const getTaskNameFieldJsx = (taskDetails: ITaskListDetails) => {

    return (

      <Fragment>
        {taskDetails?.task_name?.length && taskDetails.task_name.length > 30 ? (
          <Tooltip
            title={taskDetails.task_name}
            color={"#2E364C"}
            placement="top"
          >
            <span
              className="taskNameSpan"
            >
              {" "}
              {taskDetails.task_name}{" "}
            </span>
          </Tooltip>
        ) : (
          <span
            className="taskNameSpan"
          >
            {" "}
            {taskDetails.task_name}{" "}
          </span>
        )}
      </Fragment>
    );
  };

  return getTaskNameFieldJsx(taskDetails);
};

export default TaskNameInputField;
