import { Input, message, Tooltip } from "antd";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { IProjectState, ITaskList, ITaskListDetails } from "models/interface";
import { clone } from "ramda";
import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { projectService } from "services";
import BrowserStorageService from "services/browserStorage.service";
import { useProjectDashboardStore } from "stores";
import { getCurrentOrganization, isEmptyString } from "utils/commonFunctions";

interface CustomNumberFieldProps {
  value: number;
  disabled?: boolean;
  configuration?: any;
  taskDetail: ITaskListDetails;
  sectionId: number | string;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;

}
const getUnit = (configuration: any, direction: number) => {
  const unit = configuration?.number_format
    ?.find((u: any) => u.id === configuration?.selectedFormat)?.description ?? '';
  return (configuration.direction === direction && !!unit) ? unit : '';
};
const formatNegativeString = (numStr: string): string => {
  const dashCount = (numStr.match(/-/g) || []).length;// Count the number of dashes in the string
  const cleanedStr = numStr.replace(/-/g, ''); // Remove all dashes from the string
  const isNegative = dashCount % 2 === 1; // Determine if the number should be negative or positive based on the dash count
  return isNegative ? `-${cleanedStr}` : cleanedStr; // Add a dash at the beginning if it should be negative
};
const getNumber = (value: string | number) => (String(value)?.replace(/[^0-9.-]/g, ''));
const formatNumber = (number: string, precision: number = 0, grouping: boolean = true): string => {
  const parts = number.toString().split('.');
  const integerPart = parts[0];
  const decimalPart = precision ? '.' + String(parts?.[1] ?? '').padEnd(precision, '0').substring(0, precision) : '';
  const formattedIntegerPart = grouping ? integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : integerPart;
  return formattedIntegerPart + decimalPart;
}
const isValidNumber = (value: any): boolean => {
  return value !== null && value !== undefined && value !== '-' && !isNaN(Number(value));
};
const CustomNumberField: React.FC<CustomNumberFieldProps> = (props) => {
  let { value: initialValue, disabled = false, configuration, sectionId, setTaskListDetails, taskDetail } = props;
  const [value, setValue] = useState<string>(
    isValidNumber(initialValue)
      ? `${initialValue}`
      : '');
  const [editing, setEditing] = useState(false);

  const {
    projectDetails,
    taskListCmsData,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const { hasPermissions } = useRbac();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const browserStorage = BrowserStorageService.getInstance();
  const userDetails = browserStorage.getUserDetails();
  const negativeConfig =
    configuration?.negative_number_format
      ?.find((ele: any) => ele.id === configuration?.selectedNegativeNumberFormat) || null
  const isNegative = useMemo(() => Number(getNumber(value)) < 0, [value])
  const numberAlignment = configuration?.alignments?.find((a: any) => a?.id === configuration?.alignment)?.value || 'right';

  const formattedValue = () => {
    const prefix = getUnit(configuration, 1)
    const suffix = getUnit(configuration, 2)
    const valueText = String(value)?.replace(/[^0-9.]/g, '')
    if (!valueText) return ''

    let formattedNumber = `${formatNumber(valueText, configuration?.precision)}`;

    if (negativeConfig?.signed && isNegative) {
      formattedNumber = `-${formattedNumber}`
    }
    if (negativeConfig?.brackets && isNegative) {
      formattedNumber = `(${formattedNumber})`
    }
    formattedNumber = `${prefix ? `${prefix} ` : ''}${formattedNumber}${suffix ? ` ${suffix}` : ''}`
    return !value ? undefined : formattedNumber;
  }

  const updateExistingTask = async () => {
    setEditing(false)
    const getValueFromDetail = taskDetail?.custom_data?.find((ele: any) => ele?.mapping_id === configuration.mapping_id);
    const initialNumber = isValidNumber(getValueFromDetail?.value)
      ? getNumber(getValueFromDetail?.value)
      : null;
    const currentNumber = value && isValidNumber(getNumber(value))
      ? getNumber(value)?.replace(/^0+(?=\d)/, '')
      : null;
    if (initialNumber === currentNumber) {
      setValue(isValidNumber(initialValue)
        ? `${initialValue}`
        : '');
      return;
    };
    if ((!isValidNumber(initialNumber) && !isValidNumber(currentNumber))) {
      setValue('');
      return;
    }
    let tempValue = value === '-' ? '' : value;
    if (projectDetails?.project_id && typeof sectionId === 'number') {
      setValue(tempValue?.replace(/^0+(?=\d)/, ''))
      try {
        const currentTask = clone(taskDetail)
        currentTask.custom_data = currentTask.custom_data?.length ? currentTask.custom_data : [];

        const findIndex = currentTask?.custom_data?.findIndex((ele: any) => ele.mapping_id === configuration?.mapping_id);
        const updatedData = {
          value: currentNumber,
          editing: false,
          mapping_id: configuration?.mapping_id
        }

        if (findIndex < 0) {
          currentTask.custom_data = [
            ...currentTask.custom_data,
            updatedData
          ]
        } else {
          currentTask.custom_data[findIndex] = updatedData
        }

        const { data, messageId } = await projectService.updateTaskV2({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: [currentTask],
        });
        if (messageId === 1 && data?.length) {
          setTaskListDetails((prev) => ({
            ...prev,
            task_list_details: data,
          }));
        }
      } catch (error) {
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const enableEditing = () => {
    setEditing(true);
    setValue(formatNumber(value, configuration.precision, false))
  }

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    let content = inputValue;
    content = formatNegativeString(content);
    let regex = /[^0-9.-]/g
    const allowedNegative = !!negativeConfig?.brackets || !!negativeConfig?.signed
    const allowedDecimal = configuration?.precision && configuration?.precision > 0
    if (!allowedDecimal && allowedNegative) {
      regex = /[^0-9-]/g
    }
    if (!allowedDecimal && !allowedNegative) {
      regex = /[^0-9]/g
    }
    if (allowedDecimal && !allowedNegative) {
      regex = /[^0-9.]/g
    }
    if (inputValue.startsWith('.') && configuration?.precision > 0) {
      content = '0' + inputValue;
    } else if (inputValue) {
      content = content?.replace(regex, '')
      const pattern = /^([^.]*)\.([^.]*)\.(.*)$/;
      content = content?.replace(pattern, '$1.$2$3');// Replace the second dot with nothing
      const dotIndex = content.indexOf('.');
      const addition = (configuration.precision || 0) + 1
      if (dotIndex !== -1 && dotIndex + addition < content.length) {
        content = content.substring(0, dotIndex + addition);
      }
    }
    setValue(content)
  }

  const allowedToEdit = hasPermissions(
    [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
    projectDetails?.associated_role_id,
  )

  const renderInput = allowedToEdit ? (
    <Input
      autoFocus={editing}
      value={value}
      onBlur={updateExistingTask}
      onFocus={() => setEditing(true)}
      disabled={disabled}
      onChange={onValueChange}
      onPressEnter={updateExistingTask}
      style={{
        boxShadow: 'none',
        textAlign: numberAlignment
      }} />
  ) : null;
  return !editing && !isEmptyString(value) ? (
    <div
      role="button" className="taskNameBlk"
      style={{ cursor: disabled ? 'not-allowed' : 'pointer', textAlign: numberAlignment }}
      onClick={
        () => { (allowedToEdit && !disabled) && enableEditing() }
      }>
      <Tooltip color="#2E364C" title={formattedValue()} destroyTooltipOnHide placement="left">
        <span
          className="simpleTaskListTaskName"
          style={{ color: isNegative ? negativeConfig?.colour : undefined }} >{formattedValue()}</span>
      </Tooltip>
    </div >
  )
    : renderInput;
};

export default CustomNumberField;
