import { Button, Upload, UploadProps } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { PaperClipOutlined, UploadOutlined } from "@ant-design/icons";
import { useProjectDashboardStore } from "../../../../../../stores";
import { IProjectState } from "../../../../../../models/interface";

const FileUploader: FC<{
  organizationId: number;
  projectId: number;
  content: { [key: string]: any };
  onChange: (evt: { [key: string]: any }) => void;
}> = ({ organizationId, projectId, content, onChange }) => {
  const { memopadCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showUploader, setShowUploader] = useState<boolean>(
    !Object.keys(content.content).length
  );
  const [fileValue, setFileValue] = useState<{ [key: string]: any }>({
    size: content.content.size ?? 0,
    title: content.content.title ?? "",
    url: content.content.url ?? "",
  });
  useEffect(() => {
    if (
      fileInputRef.current &&
      !Object.keys(content.content).length &&
      content?.is_new
    ) {
      fileInputRef.current.click();
    }
  }, []);

  const humanFileSize = (size: number) => {
    let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return `${+(size / Math.pow(1024, i)).toFixed(2) * 1} ${
      ["B", "kB", "MB", "GB", "TB"][i]
    }`;
  };

  const props: UploadProps = {
    name: "file",
    accept:
      "image/*,.pdf,.svg,.csv,doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx, .xls, video/*",
    maxCount: 1,
    action: `${process.env.REACT_APP_API_URL}project/upload-assets/${organizationId}/${projectId}`,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        setShowUploader(false);

        setFileValue({
          size: info.file.size,
          title: info.file.name,
          url: info.file.response.file.url,
        });
        onChange({
          size: info.file.size,
          title: info.file.name,
          url: info.file.response.file.url,
        });
      } else if (info.file.status === "error") {
        setShowUploader(false);
      }
    },
  };

  if (showUploader) {
    return (
      <div>
        <Upload {...props}>
          <Button ref={fileInputRef} icon={<UploadOutlined />}>
            {memopadCmsData?.lbl_tool_ui_file_upload}
          </Button>
        </Upload>
      </div>
    );
  }

  return (
    <div
      onClick={() => window!.open(fileValue.url, "_blank")!.focus()}
      onKeyDown={() => {}}
      className="memopadFileUploader"
    >
      <PaperClipOutlined />
      <span>
        {fileValue.title} ({humanFileSize(fileValue.size)})
      </span>
    </div>
  );
};

export default FileUploader;
