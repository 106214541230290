import {
  ICountry,
  ILanguage,
  ITheme,
} from "../../../models/interface/localization.interface";

const CustomDropdown: React.FC<{
  id: string;
  label: string;
  options: ICountry[] | ILanguage[] | ITheme[] | undefined;
  selectedValue: string | number;
  onChange: (value: string | number) => void;
  showDropdowns?: boolean;
  icon: string;
}> = ({ label, options, selectedValue, onChange, showDropdowns, icon }) => {
  return (
    <div style={{ visibility: showDropdowns ? "visible" : "hidden" }}>
      <span className={icon} />
      <select value={selectedValue} onChange={(e) => onChange(e.target.value)}>
        {options?.map((eachOption: ICountry | ILanguage | ITheme) => (
          <option key={eachOption.value} value={eachOption.value}>
            {eachOption.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomDropdown;
