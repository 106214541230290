import { type TableProps } from "antd";
import ReactDragListView from "react-drag-listview";
import RowDragTable from "./RowDragTable";
import { checkIsCustomField } from "../../services/grid.handler";

interface CTableProps extends TableProps<any> {
  onColumnOrderChange?: (
    columns: any,
    fromIndex: number,
    toIndex: number
  ) => void;
  onDataSourceChange?: (from: string, to: string) => Promise<void>;
  onClickAdd?: () => void;
}

const TableView = (props: CTableProps) => {
  const columns: any = [
    {
      key: "sort",
      width: 16,
      fixed: true,
    },
    ...(props?.columns?.map((col: any) => {
      const isCFClassName = checkIsCustomField(col.key)
        ? "custom-field-column"
        : "";

      return {
        ...col,
        title: (
          <span className={"dragHandler column-block " + isCFClassName}>
            {col.title ?? ""}
          </span>
        ),
      };
    }) || []),
  ];

  return (
    <>
      <ReactDragListView.DragColumn
        // {...dragProps}
        nodeSelector="th"
        handleSelector=".dragHandler"
        ignoreSelector="react-resizable-handle"
        onDragEnd={(fromIndex, toIndex) => {
          if (props?.expandable) {
            fromIndex -= 1;
            toIndex -= 1;
          }

          const currentFrom = columns[fromIndex];
          const currentTo = columns[toIndex];
          if (
            !currentFrom ||
            !currentTo ||
            currentFrom.fixed ||
            currentTo.fixed
          )
            return;

          // const newColumns = [...(props?.columns ?? [])];
          const newColumns = columns;
          newColumns.splice(toIndex, 0, newColumns.splice(fromIndex, 1)[0]);

          props?.onColumnOrderChange?.(
            newColumns.slice(1),
            fromIndex - 1,
            toIndex - 1
          );
        }}
      >
        <RowDragTable
          {...props}
          columns={columns}
          bordered
          // components={{
          //   header: {
          //     cell: ResizableTitle,
          //   },
          // }}
          // dataSource={props.dataSource}
        />
      </ReactDragListView.DragColumn>
    </>
  );
};

export default TableView;
