import { Slider } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import rewindIcon from 'assets/images/rewind-icon.svg';
import pauseIcon from 'assets/images/pause-play-icon.svg';
import fastforwardIcon from 'assets/images/fastforward-icon.svg';
import playIcon from 'assets/images/iconPlay.svg';
import fullscreenIcon from 'assets/images/fullscreen-icon.svg';

const VideoPlayer: FC<{ src: string; showControls?: boolean }> = ({
  src,
  showControls = true,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  //useEffects

  useEffect(() => {
    let videoRefValue = null;
    const intervalId = setInterval(() => {
      if (videoRef.current && !videoRef.current.paused) {
        setCurrentTime(videoRef.current.currentTime);
      }
    }, 1);

    if (videoRef?.current) {
      videoRefValue = videoRef.current;
    }

    return () => {
      clearInterval(intervalId);
      if (videoRefValue) {
        videoRefValue.currentTime = 0;
        videoRefValue.pause();
      }
      setIsPlaying(false);
      setCurrentTime(0);
    };
  }, []);

  // Video handlers

  const handlePlayPauseClick = () => {
    if (isPlaying) {
      videoRef?.current?.pause();
    } else {
      videoRef?.current?.play();
    }
    setIsPlaying(!isPlaying);
    if (videoRef?.current) {
      setDuration(videoRef.current.duration);
    }
  };

  const rewind = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime -= 10;
      setCurrentTime(currentTime - 10);
    }
  };

  const fastForward = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime += 10;
      setCurrentTime(currentTime + 10);
    }
  };

  const handleTimeChange = (value: number) => {
    if (videoRef.current) {
      videoRef.current.currentTime = value;
      setCurrentTime(value);
    }
  };

  const handleFullScreen = () => {
    if (videoRef?.current) {
      videoRef?.current.requestFullscreen();
    }
  };

  function formatTime(seconds: number | undefined): string {
    if (!seconds || isNaN(seconds) || seconds === Infinity) {
      return '00:00';
    } else {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);

      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');

      if (hours > 0) {
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
      } else {
        return `${formattedMinutes}:${formattedSeconds}`;
      }
    }
  }

  return (
    <div className={showControls ? 'videoPopup' : ''}>
      {showControls && (
        <>
          <div className="videoContoller">
            <button onClick={rewind} className="rewindIcon">
              <img src={rewindIcon} alt="" />
            </button>
            <button onClick={handlePlayPauseClick}>
              <img src={isPlaying ? pauseIcon : playIcon} alt="" />
            </button>
            <button onClick={fastForward} className="fastforwardIcon">
              <img src={fastforwardIcon} alt="" />
            </button>
          </div>
          <div className="videoTimeline">
            <div className="timelineSlider">
              <Slider
                max={videoRef?.current?.duration}
                onChange={handleTimeChange}
                value={currentTime}
                step={0.001}
                tooltip={{ formatter: (value) => formatTime(value) }}
              />
            </div>
            <div className="videoTimer">
              <button className="fullscreenIcon" onClick={handleFullScreen}>
                <img src={fullscreenIcon} alt="" />
              </button>
              {duration && <span>{formatTime(currentTime)}</span>}
            </div>
          </div>
        </>
      )}

      <video
        ref={videoRef}
        onTimeUpdate={(e) => setCurrentTime(e.currentTarget.currentTime)}
        onEnded={() => setIsPlaying(false)}
        onLoadedMetadata={() => setDuration(videoRef?.current?.duration || 0)}
      >
        <source src={src} type="video/webm"></source>
        <track kind="captions" />
      </video>
    </div>
  );
};

export default VideoPlayer;
