import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Forbidden } from 'components/sharedComponents/Error/Forbidden';
import { useGlobalStore, useUserStore } from 'stores';
import { userService } from 'services';
import BrowserStorageService from 'services/browserStorage.service';
import PageLoader from 'components/sharedComponents/Loaders/PageLoader';
import localizationService from '../../services/Localization/localization.service';
import { IGlobalState, IUserDetailsQueryParams } from '../../models/interface';
import { useLocation, useSearchParams } from 'react-router-dom';
import { decryptUsingAES256 } from '../../utils/commonFunctions';
import invitationService from 'services/Invitation/invitation.service';

const useRbac = () => {
  const { user } = useAuth0();
  const {
    setUserDetails,
    setOganizations,
    userDetails,
    organizations,
    projectRolePermissionsList,
    permissions,
    currentOrgKey,
    setCurrentOrgKey,
    setPermissions,
    setProjectRolePermissionsList,
  } = useUserStore();
  const auth0UserDetails = user;
  const [isLoadingRbac, setIsLoadingRbac] = useState(true);
  const dataFetchedRef = useRef(false);
  const browserStorage = BrowserStorageService.getInstance();
  const { setLocalization } = useGlobalStore((state: IGlobalState) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/').includes('org')
    ? location.pathname.split('/')[2]
    : null;

  const [queryParameters] = useSearchParams();

  const getUserDetails = useCallback(async () => {
    const data = decryptUsingAES256(
      queryParameters.get('invitation_identifier')!,
    );
    let invitationSuccess: boolean = false;
    const userDetailPayload: IUserDetailsQueryParams = {
      org_key: org_key,
    };
    let userResponseData = await userService.userDetails(userDetailPayload);
    const result = await localizationService.getLocalizationData();
    setLocalization(result.data.data);
    if (data) {
      const processInvitationPayload = {
        invitation_id: data.invitation_id,
        user_id: parseInt(userResponseData.data.user_info[0].user_id),
        auth_id: userResponseData.data.user_info[0].auth_id,
      };
      const res = await invitationService.processInvitation(
        processInvitationPayload,
      );
      if (res.messageId !== 1) {
        window.location.href = `${process.env.REACT_APP_PLATFORM_URL!}/error`;
      } else {
        userResponseData = await userService.userDetails(userDetailPayload);
        invitationSuccess = true;
      }
    }
    try {
      if (userResponseData.messageId === -1) {
        const socialSignupResponse = await userService.userSocialSignup();
        if (
          socialSignupResponse.messageId === 1 &&
          socialSignupResponse.data.is_organization_completed
        ) {
          const userResponseData = await userService.userDetails({
            org_key: org_key ?? null,
          });
          setUserDetails(userResponseData.data.user_info[0]);

          if (!org_key) {
            let defaultExists =
              userResponseData.data.user_info[0].organization_info?.find(
                (eachOrg) => eachOrg.is_default_organization,
              );

            if (defaultExists) {
              setPermissions(defaultExists?.permissions);
            } else {
              setPermissions([]);
            }
          } else {
            setPermissions(
              userResponseData.data.user_info[0].permissions ?? [],
            );
          }
          setProjectRolePermissionsList(
            userResponseData.data.user_info[0].project_permissions,
          );
          setOganizations(
            userResponseData?.data.user_info[0].organization_info,
          );
          browserStorage.setUserDetails(userResponseData.data.user_info[0]);
        }
      } else {
        if (!org_key) {
          let defaultExists =
            userResponseData.data.user_info[0].organization_info?.find(
              (eachOrg) => eachOrg.is_default_organization,
            );

          if (defaultExists) {
            setPermissions(defaultExists?.permissions);
          } else {
            let permissionsInfo = userResponseData.data.user_info[0]
              .organization_info
              ? userResponseData.data.user_info[0].organization_info[0]
                  .permissions
              : [];

            setPermissions(permissionsInfo);
          }
        } else {
          setPermissions(userResponseData.data.user_info[0].permissions ?? []);
        }
        setProjectRolePermissionsList(
          userResponseData.data.user_info[0].project_permissions,
        );
        setUserDetails(userResponseData.data.user_info[0]);
        setOganizations(userResponseData?.data.user_info[0].organization_info);
        browserStorage.setUserDetails(userResponseData.data.user_info[0]);

        if (userResponseData.data.user_info[0]?.additional_settings?.language) {
          browserStorage.setLanguage(
            userResponseData.data.user_info[0].additional_settings?.language,
          );
        }
        if (org_key && invitationSuccess === true) {
          sessionStorage.setItem('lastLocation', `/org/${org_key}/dashboard`);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [setUserDetails]);

  useEffect(() => {
    if (!userDetails) {
      if (!dataFetchedRef.current) {
        dataFetchedRef.current = true;
        getUserDetails();
      }
    } else {
      setIsLoadingRbac(false);
    }
  }, [userDetails, getUserDetails]);

  const getProjectPermissionsByRoleId = (project_role_id?: number) => {
    if (project_role_id) {
      return (
        projectRolePermissionsList?.find(
          (each) => each.role_id === project_role_id,
        )?.permissions ?? []
      );
    }
    return [];
  };

  const getAllPermissions = (project_role_id?: number) => {
    let tempPermissions = permissions?.length ? [...permissions] : [];
    return Array.from(
      new Set(
        tempPermissions?.concat(getProjectPermissionsByRoleId(project_role_id)),
      ),
    );
  };

  const hasPermissions = (
    allowedPermissions: string[],
    project_role_id?: number,
  ) => {
    return getAllPermissions(project_role_id)?.some((value) =>
      allowedPermissions?.includes(value),
    );
  };

  return {
    isLoadingRbac,
    userDetails,
    organizations,
    hasPermissions,
    auth0UserDetails,
    currentOrgKey,
    setCurrentOrgKey,
  };
};

const withRbac = (
  WrappedComponent: React.ComponentType,
  params?: {
    allowedPermissions?: string[];
    isAccessFromPage?: boolean;
    project_role_id?: number;
  },
) => {
  return (props: any) => {
    const { isLoadingRbac, userDetails, hasPermissions, auth0UserDetails } =
      useRbac();
    return (
      <React.Fragment>
        {!isLoadingRbac && (
          <React.Fragment>
            {params?.allowedPermissions && (
              <React.Fragment>
                {hasPermissions(params?.allowedPermissions) && (
                  <WrappedComponent
                    {...props}
                    userDetails={userDetails}
                    auth0UserDetails={auth0UserDetails}
                    hasPermissions={hasPermissions}
                    isLoadingRbac={isLoadingRbac}
                  />
                )}
                {!hasPermissions(params.allowedPermissions) && (
                  <Forbidden isAccessFromPage={params.isAccessFromPage} />
                )}
              </React.Fragment>
            )}

            {!params?.allowedPermissions && (
              <WrappedComponent
                {...props}
                userDetails={userDetails}
                auth0UserDetails={auth0UserDetails}
                hasPermissions={hasPermissions}
                isLoadingRbac={isLoadingRbac}
              />
            )}
          </React.Fragment>
        )}
        {isLoadingRbac && <PageLoader loaderState={isLoadingRbac} />}
      </React.Fragment>
    );
  };
};

const Rbac: React.FC<{
  children: React.ReactNode;
  allowedPermissions?: string[];
  allowedRoles?: string[];
  project_role_id?: number;
}> = ({ children, allowedPermissions, allowedRoles, project_role_id }) => {
  const { isLoadingRbac, hasPermissions } = useRbac();

  const checkRoleAndPermission = () => {
    if (isLoadingRbac) {
      return false;
    } else if (
      allowedPermissions &&
      allowedRoles &&
      hasPermissions(allowedPermissions, project_role_id)
    ) {
      return true;
    } else if (
      allowedPermissions &&
      !allowedRoles &&
      hasPermissions(allowedPermissions, project_role_id)
    ) {
      return true;
    } else if (allowedRoles && !allowedPermissions) {
      return true;
    } else {
      return false;
    }
  };

  if (checkRoleAndPermission()) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return null;
  }
};

export { useRbac, withRbac, Rbac };
