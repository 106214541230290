import axios from "axios";
import { RoutesEnum } from "../../models/enums/apiRoutes";

class LocalizationService {
  public getLocalizationData = async () => {
    let request = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}${RoutesEnum.GET_LOCALIZATION_DATA}`,
      responseType: "json",
    });
    return request;
  };
}

const localizationService = new LocalizationService();
export default localizationService;
