import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from 'components/themes/ThemeProvider';
import { MantineProvider } from '@mantine/core';
import '../src/assets/css/user-story.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <MantineProvider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </MantineProvider>
);

reportWebVitals();
