interface ILocalStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
  clear(): void;
}

export default abstract class BrowserStorage<T extends string> {
  private readonly localStorage: ILocalStorage;

  public constructor(getStorage = (): ILocalStorage => window.localStorage) {
    this.localStorage = getStorage();
  }

  protected getFromLocalStorage(key: T): any {
    const itemStr = this.localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);

    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      this.localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }

  protected saveToLocalStorage(key: T, value: any): void {
    const item = {
      value: value,
    };
    this.localStorage.setItem(key, JSON.stringify(item));
  }

  protected saveToLocalStorageWithExpiry(
    key: string,
    value: any,
    ttl: number = 86400000
  ): void {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    this.localStorage.setItem(key, JSON.stringify(item));
  }

  protected clearItemFromLocalStorage(key: T): void {
    this.localStorage.removeItem(key);
  }

  protected clearItemsFromLocalStorage(keys: T[]): void {
    keys.forEach((key) => this.clearItemFromLocalStorage(key));
  }

  protected clearLocalStorage(): void {
    this.localStorage.clear();
  }

  protected getCookieItem(key: T): any {
    let name = key + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(const element of ca) {
      let c = element;
      while (c.startsWith(' ')) {
        c = c.substring(1);
      }
      if (c.startsWith(name)) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  protected setCookieItem(key: T, value: any, expiryInDays: number = 3): void {
    const d = new Date();
    d.setTime(d.getTime() + (expiryInDays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    let domain = process.env.REACT_APP_STAGE === 'local' || process.env.REACT_APP_STAGE === 'usg_local' ? "localhost" : "cleversort.com";
    document.cookie = key + "=" + value + ";" + expires + ";path=/; domain=" + domain;
  }

  protected checkCookieItem(key: T): boolean {
    let value = this.getCookieItem(key);
    return value !== "";
  }

  protected clearCookieItem(key: T): void {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=cleversort.com`;
  }

  protected clearCookieItems(keys: T[]): void {
    keys.forEach((key) => this.clearCookieItem(key));
  }
}
