import { FC } from "react";
import { IGridList, IProjectState } from "models/interface";
import { useProjectDashboardStore } from "stores";
import { Tooltip } from "antd";
import { newStageId, newStageRank } from "models/enums/constants";

export const checkStageList = (gridListDetails: IGridList) => {
  if (!gridListDetails) {
    return false;
  } else {
    if (gridListDetails?.grid_list_details?.stage_details === null) {
      return false;
    }
    return true;
  }
};

const AddStageCTA: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
}> = ({ gridListDetails, setGridListDetails }) => {
  const { gridListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state
  );

  const gridDetails = gridListDetails?.grid_list_details;
  const isHaveBlocks = gridDetails?.stage_details && gridDetails?.task_details;

  const addNewStage = () => {
    if (gridListDetails) {
      const check = checkStageList(gridListDetails);
      if (!check) {
        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            stage_details: gridListDetails.grid_list_details!.stage_details
              ? [
                  ...gridListDetails.grid_list_details!.stage_details,
                  {
                    stage_id: newStageId,
                    stage_name: "",
                    stage_rank: newStageRank,
                    section_id: +gridListDetails.section_id,
                    isNew: true,
                    created_by: "",
                  },
                ]
              : [
                  {
                    stage_id: newStageId,
                    stage_name: "",
                    stage_rank: newStageRank,
                    section_id: +gridListDetails.section_id,
                    isNew: true,
                    created_by: "",
                  },
                ],
          },
        } as IGridList);
        return;
      }

      let lastElement =
        gridListDetails.grid_list_details?.stage_details?.slice(-1)!;

      if (!lastElement) return;

      if (!lastElement[0].isNew && !lastElement[0].isEdit) {
        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            stage_details: gridListDetails.grid_list_details!.stage_details
              ? [
                  ...gridListDetails.grid_list_details!.stage_details,
                  {
                    stage_id: newStageId,
                    stage_name: "",
                    stage_rank: newStageRank,
                    section_id: +gridListDetails.section_id,
                    isNew: true,
                    created_by: "",
                  },
                ]
              : [
                  {
                    stage_id: newStageId,
                    stage_name: "",
                    stage_rank: newStageRank,
                    section_id: +gridListDetails.section_id,
                    isNew: true,
                    created_by: "",
                  },
                ],
          },
        } as IGridList);
      }
    }
  };

  return (
    <Tooltip
      title={isHaveBlocks ? gridListCmsData?.lbl_add_stage_tooltip : ''}
      color={"#2E364C"}
      placement="right"
    >
      <div onClick={() => addNewStage()} className={"addStage addPlusSolid " + (!gridDetails?.stage_details ? 'noStageAvailable' : '')}>
        Add new stage
      </div>
    </Tooltip>
  );
};

export default AddStageCTA;
