import { Dropdown, Tooltip, message } from 'antd';
import {
  IGridList,
  IProjectState,
  IProjectUserMetaPayload,
  ITaskList,
  ITaskListDetails,
} from 'models/interface';
import { useProjectDashboardStore, useUserStore } from 'stores';
import {
  generateUserAvatar,
  getCurrentOrganization,
  getDefaultOrganization,
} from 'utils/commonFunctions';
import { TaskListView } from 'models/enums/tasklistView';
import { customizationService, loggerService, projectService } from 'services';
import { useLocation } from 'react-router-dom';

// Images
import UserIcon from 'assets/e-images/user-profile.svg';
import InitalIcon from 'assets/e-images/inital-icon.svg';
import DateIcon from 'assets/e-images/date-icon.svg';
import StickerIcon from 'assets/e-images/sticker-icon.svg';
import { handleGridViewStore } from './GridListV3/GridList';

const SectionFilters: React.FC<{
  sectionDetails: IGridList | ITaskList;
  setSectionDetails: React.Dispatch<
    React.SetStateAction<IGridList | ITaskList>
  >;
  getSectionDetails: () => Promise<void>;
}> = ({ sectionDetails, setSectionDetails, getSectionDetails }) => {
  const { userDetails } = useUserStore((state: any) => state);
  const {
    customizeSectionUpdateCmsData,
    taskListCmsData,
    projectDetails,
    gridListCmsData,
    setProjectDetails,
  } = useProjectDashboardStore((state: IProjectState) => state);

  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const selectingView = handleGridViewStore(
    Number(sectionDetails.section_id ?? 0),
    0,
    'get',
    sectionDetails,
    userDetails,
    projectDetails,
    org_key,
    undefined,
  );
  // @ts-ignore
  const gridListDetails = sectionDetails?.grid_list_details;
  const isHaveBlocks =
    gridListDetails?.stage_details && gridListDetails?.task_details;

  const updateMetadata = async (
    updateProjectUserMetaPayload?: IProjectUserMetaPayload,
  ) => {
    if (updateProjectUserMetaPayload) {
      await projectService.updateProjectUserMetadata(
        updateProjectUserMetaPayload,
      );
      const _project_metadata =
        updateProjectUserMetaPayload?.project_metadata?.map((each) => ({
          ...each,
          section_id: parseInt(each.section_id as string),
        }));
      setProjectDetails({
        ...projectDetails,
        project_metadata: _project_metadata,
      });
      getSectionDetails();
    }
  };

  const filterTaskAndReturn = () => {
    let _taskDetails = sectionDetails?.task_list_details;
    if (_taskDetails !== undefined) {
      if (sectionDetails?.hide_completed_tasks) {
        _taskDetails = _taskDetails.filter(
          (eachTask) =>
            eachTask.task_status_id !== 3 ||
            typeof eachTask.task_id === 'string',
        );
      }
      if (sectionDetails?.show_own_tasks) {
        _taskDetails = _taskDetails.filter(
          (eachTask) =>
            eachTask.assignee_id === userDetails?.user_id ||
            typeof eachTask.task_id === 'string',
        );
      }
    }
    return _taskDetails;
  };

  const getProjectMetadata = (
    hide_completed_tasks: boolean,
    show_own_tasks: boolean,
  ) => {
    if (
      projectDetails?.project_metadata?.length &&
      projectDetails?.project_metadata?.length > 0
    ) {
      if (
        projectDetails.project_metadata
          .map((eachMeta) => eachMeta.section_id)
          .includes(sectionDetails.section_id)
      ) {
        return projectDetails?.project_metadata?.map((eachMeta) => {
          if (eachMeta.section_id === sectionDetails.section_id) {
            return {
              section_id: eachMeta.section_id,
              hide_completed_tasks: hide_completed_tasks,
              show_own_tasks: show_own_tasks,
              task_list_view: sectionDetails.task_list_view as TaskListView,
            };
          }
          return eachMeta;
        });
      } else {
        return [
          ...projectDetails.project_metadata,
          {
            section_id: sectionDetails.section_id,
            hide_completed_tasks: hide_completed_tasks,
            show_own_tasks: show_own_tasks,
            task_list_view: sectionDetails.task_list_view as TaskListView,
          },
        ];
      }
    } else {
      return [
        {
          section_id: sectionDetails.section_id,
          hide_completed_tasks: hide_completed_tasks,
          show_own_tasks: show_own_tasks,
          task_list_view: sectionDetails.task_list_view as TaskListView,
        },
      ];
    }
  };

  const ownTaskFilterClickHandler = () => {
    if (sectionDetails) {
      const updateProjectUserMetaPayload = {
        project_id: projectDetails?.project_id,
        project_metadata: getProjectMetadata(
          sectionDetails.hide_completed_tasks ?? false,
          !sectionDetails.show_own_tasks,
        ),
        org_key: org_key,
      };
      updateMetadata(updateProjectUserMetaPayload);
    }
  };

  const checkDoneTask = () => {
    if (sectionDetails) {
      if (sectionDetails.section_type_id === 2) {
        if (!isHaveBlocks) return true;
        return false;
      }

      let doneTask = sectionDetails?.task_list_details?.filter(
        (ele: ITaskListDetails) => ele.task_status_id === 3,
      );
      let filteredList = filterTaskAndReturn();
      if (
        filteredList &&
        filteredList?.length === 0 &&
        sectionDetails.task_list_details &&
        sectionDetails?.task_list_details?.length > 0
      ) {
        return false;
      } else {
        return doneTask?.length && doneTask?.length > 0 ? false : true;
      }
    } else {
      return true;
    }
  };

  const checkOwnTask = () => {
    if (sectionDetails) {
      if (sectionDetails.section_type_id === 2) {
        if (!isHaveBlocks) return true;
        return false;
      }

      let ownTask = sectionDetails?.task_list_details?.filter(
        (ele: ITaskListDetails) => ele.assignee_id === userDetails.user_id,
      );
      let filteredList = filterTaskAndReturn();
      if (
        filteredList &&
        filteredList.length === 0 &&
        sectionDetails.task_list_details &&
        sectionDetails?.task_list_details?.length > 0
      ) {
        return false;
      } else {
        return (
          ownTask?.length === 0 ||
          ownTask?.length === sectionDetails?.task_list_details?.length
        );
      }
    } else {
      return false;
    }
  };

  const checkDoneState = () => {
    return sectionDetails?.hide_completed_tasks ? 'buttonSelected' : '';
  };

  const updateStickerPackAssociation = async (pack_id: number) => {
    // setLoaderState("active");
    try {
      let payload = {
        organizationId: getDefaultOrganization(userDetails.organization_info)
          ?.organization_id!,
        userId: userDetails.user_id,
        packId: pack_id,
        projectId: projectDetails?.project_id,
        sectionId: sectionDetails.section_id,
      };

      await customizationService.updateStickerAssociation(payload);
      // addRemoveOverLay();
      // setShowSidePanel(false);
      // setLoaderState("inactive");
    } catch (error) {
      message.error(
        customizeSectionUpdateCmsData?.lbl_generic_error_message,
        3,
      );
      await loggerService.log({
        severity: 'High',
        message: `Sticker pack list get action failed`,
        payload: { ApiResponse: error },
      });
    } finally {
      // setLoaderState("inactive");
    }
  };

  const onToggleGridView = async (view_id: number) => {
    const org_key = location.pathname.split('/')[2];
    setSectionDetails({ ...sectionDetails, view_id });
    handleGridViewStore(
      Number(sectionDetails.section_id ?? 0),
      view_id,
      'set',
      sectionDetails,
      userDetails,
      projectDetails,
      org_key,
      undefined,
    );
  };

  const doneTaskFilterClickHandler = () => {
    if (sectionDetails) {
      const updateProjectUserMetaPayload = {
        project_id: projectDetails?.project_id,
        project_metadata: getProjectMetadata(
          !sectionDetails.hide_completed_tasks,
          sectionDetails.show_own_tasks ?? false,
        ),
        org_key: org_key,
      };
      updateMetadata(updateProjectUserMetaPayload);
    }
  };

  return (
    <ul className="actionBtnSec">
      {/* <li>
        <Tooltip
          title={
            sectionDetails?.show_own_tasks
              ? taskListCmsData?.lbl_filter_done_task_tooltips[0].tooltip_text
              : taskListCmsData?.lbl_filter_own_tasks_tooltip
          }
          color={"#2E364C"}
        >
          <button
            aria-label="user image"
            className={`ownTaskPopover ${
              sectionDetails?.show_own_tasks ? "buttonSelected" : ""
            }`}
            onClick={ownTaskFilterClickHandler}
            disabled={checkOwnTask()}
          >
            {generateUserAvatar(userDetails).key === "string" ? (
              <span className="userAvtr">
                {generateUserAvatar(userDetails).value}
              </span>
            ) : (
              <img
                className="userAvtr"
                src={generateUserAvatar(userDetails).value}
              ></img>
            )}
          </button>
        </Tooltip>
      </li> */}
      <li>
        <Tooltip
          title={
            sectionDetails?.hide_completed_tasks
              ? taskListCmsData?.lbl_filter_done_task_tooltips[0].tooltip_text
              : taskListCmsData?.lbl_filter_done_task_tooltips[1].tooltip_text
          }
          color={'#2E364C'}
          placement="topLeft"
        >
          <button
            aria-label="check"
            disabled={checkDoneTask()}
            className={checkDoneState()}
            onClick={doneTaskFilterClickHandler}
          >
            <span
              className={`cmnIcon ${
                sectionDetails?.hide_completed_tasks ? 'hideDoneTask' : 'check'
              }`}
            ></span>
          </button>
        </Tooltip>
      </li>
      {/* @ts-ignore */}
      {sectionDetails.section_type_id === 2 && !sectionDetails?.compactView && (
        <Tooltip
          title={gridListCmsData?.cta_toggle_view ?? 'Toggle View'}
          color={'#2E364C'}
          placement="left"
        >
          <li>
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <div className="customDropDownItem">
                        <span className="cmnIcon">
                          <img src={UserIcon} alt="UserIcon" />
                        </span>
                        {gridListCmsData?.lbl_menu_toggle_view_show_profile ??
                          'Show Profile'}
                      </div>
                    ),
                    key: 1,
                  },
                  {
                    label: (
                      <div className="customDropDownItem">
                        <span className="cmnIcon">
                          <img src={InitalIcon} alt="UserIcon" />
                        </span>
                        {gridListCmsData?.lbl_menu_toggle_view_show_initials ??
                          'Show Initials'}
                      </div>
                    ),
                    key: 3,
                  },
                  {
                    label: (
                      <div className="customDropDownItem">
                        <span className="cmnIcon">
                          <img src={DateIcon} alt="UserIcon" />
                        </span>
                        {gridListCmsData?.lbl_menu_toggle_view_show_date ??
                          'Show Date'}
                      </div>
                    ),
                    key: 2,
                  },
                  {
                    label: (
                      <div className="customDropDownItem">
                        <span className="cmnIcon">
                          <img src={StickerIcon} alt="UserIcon" />
                        </span>
                        {gridListCmsData?.lbl_menu_toggle_view_show_sticker ??
                          'Show Stickers'}
                      </div>
                    ),
                    key: 4,
                  },
                ],
                selectable: true,
                defaultSelectedKeys: [selectingView?.toString() ?? '1'],
                onClick: (item) => {
                  onToggleGridView(Number(item.key));
                },
              }}
              placement="bottomLeft"
              trigger={['click']}
            >
              <button className="actionBtnSecli">
                <span className="cmnIcon toggleView"></span>
              </button>
            </Dropdown>
          </li>
        </Tooltip>
      )}
    </ul>
  );
};

export default SectionFilters;
