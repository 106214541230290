import React, { useEffect, useState } from "react";
import { useLoaderStore, useLocalizationStore } from "../../../stores";
import {
  ILoaderState,
  ICmsData,
  ILocalizationData,
  IGlobalState,
} from "../../../models/interface";
import {
  clearPageSpecificData,
  getPageSpecificData,
  removeStyle,
  setStyle,
} from "../../../utils/commonFunctions";
import { useGlobalStore } from "../../../stores/global.store";
import { loggerService } from "../../../services";
import localizationService from "../../../services/Localization/localization.service";
import { ILocalizationMasterData } from "../../../models/interface/localization.interface";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import cmsService from "../../../services/CmsService/cms.service";
import BrowserStorageService from "services/browserStorage.service";

export const ThemeSelector: React.FC<{
  pageSlug?: string[];
  onLanguageChange?: Function;
  cmsPageName?: string[];
  languageArg?: string;
  countryArg?: string;
}> = ({ pageSlug, onLanguageChange, cmsPageName, languageArg, countryArg }) => {
  // global states
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { setLocalization } = useGlobalStore((state: IGlobalState) => state);
  const [localizationData, setLocalizationData] =
    useState<ILocalizationMasterData | null>(null);
  const browserStorage = BrowserStorageService.getInstance();

  const getLocalizationData = async () => {
    const result = await localizationService.getLocalizationData();
    setLocalizationData(result.data.data);
    setLocalization(result.data.data);
  };

  const { language, setLanguage, country, setCountry, theme, setTheme } =
    useGlobalStore((state: IGlobalState) => state);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  // life cycles
  useEffect(() => {
    getLocalizationData();
    updateCmsContent();
    changeCountryTheme();
  }, []);

  // updating language
  useEffect(() => {
    if (language) {
      const tmpLocalizationData: ILocalizationData = {
        language: languageArg ?? language,
        country: countryArg ?? country,
      };

      if (languageArg) {
        setLanguage(languageArg);
      }

      if (countryArg) {
        setCountry(countryArg);
      }

      browserStorage.setLocalization(tmpLocalizationData);
      clearPageSpecificData(setCmsData);
      updateCmsContent();
    }
  }, [language]);

  const changeThemeWithCountry = async () => {
    try {
      await cmsService.fetchCmsData(cmsData, setCmsData);
      setLoaderState("inactive");
      changeCountryTheme();
    } catch (error) {
      setLoaderState("inactive");
      loggerService.log({
        severity: "Medium",
        message: "Unable to change theme with country change",
        payload: error,
      });
    }
  };

  // changing country specific theme
  useEffect(() => {
    if (country) {
      const tmpLocalizationData: ILocalizationData = {
        language: languageArg ?? language,
        country: countryArg ?? country,
      };

      if (languageArg) {
        setLanguage(languageArg);
      }

      if (countryArg) {
        setCountry(countryArg);
      }

      browserStorage.setLocalization(tmpLocalizationData);
      setLoaderState("active");
      changeThemeWithCountry();
    }
  }, [country]);

  // toggling dark theme
  useEffect(() => {
    if (theme === 2) {
      setStyle("custom-dark.css", "dark-style");
      !browserStorage.getTheme() && browserStorage.setTheme("dark");
    } else {
      browserStorage.getTheme() && browserStorage.clearTheme();
      removeStyle("dark-style");
    }
  }, [theme]);

  // update cms content
  const updateCmsContent = async () => {
    const cmsLocalData = browserStorage.getGlobalData();
    if (!cmsLocalData) {
      setLoaderState("active");
      await cmsService.fetchCmsData(cmsData, setCmsData);
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      if (onLanguageChange) onLanguageChange();
      setLoaderState("inactive");
      setLoaderState("inactive");
    } else if (checkLocalCmsData()) {
      setLoaderState("active");
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      if (onLanguageChange) onLanguageChange();
      setLoaderState("inactive");
      setLoaderState("inactive");
    } else {
      setLoaderState("inactive");
      if (onLanguageChange) onLanguageChange();
    }
  };

  const changeCountryTheme = () => {
    const cmsLocalData: ICmsData = browserStorage.getGlobalData();
    if (
      cmsLocalData?.countrySpecificData?.length &&
      cmsLocalData?.countrySpecificData[0].attributes.stylesheet !==
        "custom.css"
    ) {
      removeStyle("country-specific-style");
      setStyle(
        cmsLocalData?.countrySpecificData[0].attributes.stylesheet,
        "country-specific-style"
      );
    } else if (
      cmsLocalData?.countrySpecificData &&
      cmsLocalData?.countrySpecificData[0].attributes.stylesheet ===
        "custom.css"
    ) {
      removeStyle("country-specific-style");
    }
  };

  const checkLocalCmsData = () => {
    return (
      pageSlug?.length &&
      pageSlug.some((eachSlug: string) => !getPageSpecificData(cmsData, eachSlug))
    );
  };

  return (
    <div className="demoPanel" style={{ zIndex: 1000 }}>
      <CustomDropdown
        id="languageDropdown"
        options={localizationData?.languages}
        label={"Select Language"}
        selectedValue={language}
        onChange={(value) => typeof value === "string" && setLanguage(value)}
        showDropdowns={true}
        icon={"language"}
      />
    </div>
  );
};
