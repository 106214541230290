import { AppState, Auth0Provider } from '@auth0/auth0-react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Auth0Check from './Auth0Check';
import AuthService from 'services/Auth0/authService';
import { MainWrapper } from 'components/layout';
import ReactGA from 'react-ga4';

const authService = AuthService.getInstance();

const Auth0Outlet = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.location.origin;
  let location = useLocation();
  ReactGA.send({
    hitType: 'pageview',
    page: location.pathname,
    title: location.pathname,
  });
  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  const pageRedirectUri: any = () => {
    if (searchParams.get('redirect')) {
      return btoa(`${location.pathname}${location.search}`);
    }
    return null;
  };

  const authorizationParams: any = {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: redirectUri,
    invitation_identifier: searchParams.get('invitation_identifier'),
    localization: searchParams.get('localization') ?? 'en',
    country: searchParams.get('country') ?? 'IN',
    ticket: searchParams.get('ticket') ?? null,
    action: searchParams.get('action') ?? null,
    page_redirect_uri: pageRedirectUri(),
  };

  return (
    <MainWrapper>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={authorizationParams}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <Auth0Check>
          <Outlet />
        </Auth0Check>
      </Auth0Provider>
    </MainWrapper>
  );
};

export default Auth0Outlet;
