import {
  IEpicAndStoryDataType,
  IUploadedFileData,
} from "models/interface/userStoryGenerator.interface";
import { create } from "zustand";

interface UserStoryStoreType {
  epicsAndStoryData: IEpicAndStoryDataType[];
  uploadedFileHistory: IUploadedFileData[];
  archivedEpics: IEpicAndStoryDataType[];
  setEpicsAndStoryData: (data: any) => void;
  setUploadedFileHistory: (data: any) => void;
  setArchivedEpics: (data: any) => void;
}
export const useUserStoryStore = create<UserStoryStoreType>((set) => ({
  epicsAndStoryData: [],
  uploadedFileHistory: [],
  archivedEpics: [],
  setEpicsAndStoryData: (data: any) => set({ epicsAndStoryData: data }),
  setUploadedFileHistory: (data: any) => set({ uploadedFileHistory: data }),
  setArchivedEpics: (data: any) => set({ archivedEpics: data }),
}));
