import {FC, useEffect, useState} from "react";
import masterService from "../../../../services/Master/master.service";
import {Input, message, Select} from "antd";
import {IJobRole} from "../../../../models/interface/master.interface";
import { IProfileSettingCmsData} from "../../../../models/interface";
import {filterSortByRanking} from "../../../../utils/commonFunctions";

const WorkPurposeComponent:  FC<{
    lblCms: IProfileSettingCmsData | null,
    defaultJobId?: number,
    defaultJobTitle?: string,
    onChange: (jobTitle: string, jobId: number) => void
}> = ({lblCms, defaultJobId = 1, defaultJobTitle= "", onChange}) => {
    const [jobRoles, setJobRoles] = useState<IJobRole[]>();
    const [inputValue, setInputValue] = useState("");
    const [jobTitle, setJobTitle] = useState(defaultJobTitle)
    const [jobId, setJobId] = useState(defaultJobId)

    useEffect(() => {
        if (!jobRoles) {
            fetchJobRolesData();
        }
        return () => {
            setJobRoles(undefined);
        };
    }, []);

    useEffect(() => {
        onChange(jobTitle, jobId)
    }, [jobTitle, jobId]);
    const fetchJobRolesData = async () => {
        try {
            const res = await masterService.getJobRoles();
            setJobRoles(res.data.job_roles);
        } catch (error) {
            message.error(
                lblCms?.lbl_error_responses.job_role_data_load_error
            );
        }
    };
    /**
     * removed select role as currently this option is not available during onboarding
     * 
     const workRoleChangeHandler = (job_role_id: number) => {
        setJobId(job_role_id);
    };
     */
   

    const workTitleChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setJobTitle(e.target.value);
    };
    return <>
        <div className="fldWrap">
            <Input
                className="profileWorkTitle"
                onChange={workTitleChangeHandler}
                maxLength={100}
                value={jobTitle}
            />
            <label htmlFor="name">{lblCms?.lbl_profile_job_title}</label>
        </div>
        {/****
         * removed select role as currently this option is not available during onboarding
         *  {<Select
            placeholder={lblCms?.lbl_job_role_place_holder}
            defaultValue={jobId}
            style={{ width: "100%" }}
            onSelect={() => setInputValue("")}
            options={jobRoles}
            showSearch
            onSearch={(value) => setInputValue(value)}
            filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input?.toLowerCase())
                    ? true
                    : false
            }
            filterSort={(optionA: any, optionB: any) =>
                filterSortByRanking(optionA, optionB, inputValue)
            }
            onChange={workRoleChangeHandler}
            size="large"
        />} */}
    </>
}

export default WorkPurposeComponent
