import { FC, useEffect, useRef } from "react";
import { PaperClipOutlined } from "@ant-design/icons";

const FileUploader: FC<{
  content: { [key: string]: any };
}> = ({ content }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (
      fileInputRef.current &&
      !Object.keys(content.content).length &&
      content?.is_new
    ) {
      fileInputRef.current.click();
    }
  }, []);

  const humanFileSize = (size: number) => {
    let i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return `${+(size / Math.pow(1024, i)).toFixed(2) * 1} ${
      ["B", "kB", "MB", "GB", "TB"][i]
    }`;
  };

  return (
    <div className="memopadFileUploader">
      <PaperClipOutlined />
      <span>
        {content.content.title ?? ""} (
        {humanFileSize(content.content.size ?? 0)})
      </span>
    </div>
  );
};

export default FileUploader;
