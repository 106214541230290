import React, { FC, Fragment, useEffect, useState } from 'react';
import { cmsService, loggerService, userStoryGeneratorService } from 'services';
import { Skeleton, Tooltip, Upload, UploadProps, message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import onlyLogo from '../../../assets/images/onlyLogo.svg';
import Editor from 'components/sharedComponents/Editor/Editor';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocalizationStore, useUserStore, useUserStoryStore } from 'stores';
import {
  getCurrentOrganization,
  getPageSpecificData,
} from 'utils/commonFunctions';
import { IModalState, IUserStoryGeneratorLabel } from 'models/interface';
import { useModalStore } from 'stores/modal.store';

const { Dragger } = Upload;

interface IUserStoryGeneratorProps {
  pageSlug?: string[];
  cmsPageName?: string[];
}

const UserStoryGenerator: FC<IUserStoryGeneratorProps> = ({
  pageSlug,
  cmsPageName,
}) => {
  const [fileUpload, setFileUpload] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<boolean>(false);
  const [fileItem, setFileItem] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [editorContent, setEditorContent] = useState<string | null>('');
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isNotified, setIsNotified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disableUploadFile, setDisableUploadFile] = useState<boolean>(false);

  const [userStoryGeneratorLabel, setUserStoryGeneratorLabel] =
    useState<IUserStoryGeneratorLabel | null>(null);

  const { setModalState, modalState } = useModalStore(
    (state: IModalState) => state,
  );
  const [_pageCmsData, setPageCmsData] = useState<any | null>(null);
  const [_profileSettingCmsData, setProfileSettingCmsData] = useState<
    any | null
  >(null);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  const { epicsAndStoryData, setEpicsAndStoryData } = useUserStoryStore(
    (state: any) => state,
  );

  const navigate = useNavigate();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const [isDeleteModalActive, setIsDeleteModalActive] =
    useState<boolean>(false);

  const urlParams = useParams();

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
    setIsDeleteModalActive(false);
  };

  useEffect(() => {
    if (isDeleteModalActive) {
      setModalState({
        modalHeader: (
          <Fragment>
            <h3>
              {
                userStoryGeneratorLabel?.lbl_file_delete
                  .lbl_file_delete_modal_header
              }
            </h3>
            <span
              className="material-icons-outlined modal-close"
              onClick={() => modalClose()}
              onKeyDown={() => {}}
            >
              close
            </span>
          </Fragment>
        ),
        modalBody: (
          <React.Fragment>
            <div className="icon"></div>
            <p>
              {
                userStoryGeneratorLabel?.lbl_file_delete
                  .lbl_file_delete_modal_description
              }
            </p>
          </React.Fragment>
        ),
        modalFooter: (
          <Fragment>
            <button
              className="noStyle cancel_deleteFolder"
              onClick={() => modalClose()}
            >
              {
                userStoryGeneratorLabel?.lbl_file_delete
                  .lbl_file_delete_modal_footer1
              }
            </button>

            <button className="deleteBtnStyle" onClick={handleDeleteFile}>
              {
                userStoryGeneratorLabel?.lbl_file_delete
                  .lbl_file_delete_modal_footer2
              }
            </button>
          </Fragment>
        ),
        shouldShow: true,
      });
    }
  }, [isDeleteModalActive]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response: any =
          await userStoryGeneratorService.GetEpicAndStoriesList({
            page_data: {
              project_id: Number(urlParams.projectId) || 1,
              organization_id: 5,
              is_archived: false,
            },
          });
        setEpicsAndStoryData([response]);
      } catch (error) {
        await loggerService.log({
          severity: 'High',
          message: 'Failed to Fetch Epics and Stories',
          payload: error,
        });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [setEpicsAndStoryData]);

  const searchParams = new URLSearchParams(location.search);
  const isUserStoryExist = searchParams.get('redirected') === 'true';
  let isEpicAvailable = epicsAndStoryData[0]?.data !== null;
  let charcterCount = !isUserStoryExist && !isEpicAvailable ? 300 : 100;
  let plainText = editorContent?.replace(/<[^>]*>/g, '') ?? '';
  let uploadFileFlag = isDisable ? 1 : 0;
  useEffect(() => {
    if (plainText.length > 0) {
      setDisableUploadFile(true);
    } else {
      setDisableUploadFile(false);
    }
  }, [editorContent, plainText]);

  useEffect(() => {
    if (plainText.trim().length >= charcterCount || fileItem) {
      setFileUpload(true);
    } else {
      setFileUpload(false);
    }
  }, [editorContent, fileItem, plainText, charcterCount]);

  useEffect(() => {
    if (
      plainText.length >= 1 &&
      plainText.length < charcterCount &&
      !fileItem
    ) {
      setIsNotified(true);
    } else {
      setIsNotified(false);
    }
  }, [editorContent, fileItem, plainText, charcterCount]);

  useEffect(() => {
    if (fileItem) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [fileItem]);

  useEffect(() => {
    if (pageSlug) {
      (async () => {
        setIsLoading(true);
        let userStoryGeneratorData: any;
        let tempPageData: any;
        let profileSettingData: any;
        let pageSpecificData = cmsData.pageSpecificData;
        if (pageSpecificData.length > 0) {
          tempPageData = getPageSpecificData(cmsData, pageSlug[0]);
          await getTempPageDataSet(tempPageData, pageSlug);
          profileSettingData = getPageSpecificData(cmsData, pageSlug[1]);
          await getProfileCmsDataSet(profileSettingData, pageSlug);
          userStoryGeneratorData = getPageSpecificData(cmsData, pageSlug[2]);
          await userStoryGeneratorDataSet(userStoryGeneratorData, pageSlug);
          setIsLoading(false);
        } else {
          setIsLoading(true);

          await cmsService.fetchCmsData(
            cmsData,
            setCmsData,
            cmsPageName,
            pageSlug,
          );

          tempPageData = getPageSpecificData(cmsData, pageSlug[0]);
          tempPageData && setPageCmsData(tempPageData[0]?.attributes);
          profileSettingData = getPageSpecificData(cmsData, pageSlug[1]);
          profileSettingData &&
            setProfileSettingCmsData(profileSettingData[0]?.attributes);
          userStoryGeneratorData = getPageSpecificData(cmsData, pageSlug[2]);
          userStoryGeneratorData &&
            setUserStoryGeneratorLabel(userStoryGeneratorData[0]?.attributes);
          setIsLoading(false);
        }
      })();
    }
  }, [location]);

  const userStoryGeneratorDataSet = async (
    userStoryGeneratorSetData: any,
    pageSlug: string[],
  ) => {
    if (!userStoryGeneratorSetData) {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      userStoryGeneratorSetData = getPageSpecificData(cmsData, 'user-story');
      userStoryGeneratorSetData &&
        setUserStoryGeneratorLabel(userStoryGeneratorSetData[0].attributes);
    } else {
      userStoryGeneratorSetData &&
        setUserStoryGeneratorLabel(userStoryGeneratorSetData[0].attributes);
    }
  };

  const getTempPageDataSet = async (
    tempPageSetData: any,
    pageSlug: string[],
  ) => {
    if (!tempPageSetData) {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      tempPageSetData = getPageSpecificData(
        cmsData,
        'page-dashboard-left-panel',
      );
      tempPageSetData && setPageCmsData(tempPageSetData[0].attributes);
    } else {
      tempPageSetData && setPageCmsData(tempPageSetData[0].attributes);
    }
  };

  const getProfileCmsDataSet = async (
    prfoileSettingSetData: any,
    pageSlug: string[],
  ) => {
    if (!prfoileSettingSetData) {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      prfoileSettingSetData = getPageSpecificData(cmsData, 'profile-setting');
      prfoileSettingSetData &&
        setProfileSettingCmsData(prfoileSettingSetData[0].attributes);
    } else {
      prfoileSettingSetData &&
        setProfileSettingCmsData(prfoileSettingSetData[0].attributes);
    }
  };

  const onSubmitFilehandler = () => {
    if (imgUrl && fileItem) {
      handleUploadUserFile(fileItem);
      setFileItem(null);
      setImgUrl(false);
      setFileUpload(false);
      setEditorContent('');
    }

    if (!fileItem && editorContent) {
      const textFile = textToFileConvertor(plainText);
      handleUploadUserFile(textFile);
      setEditorContent('');
    }
  };

  const textToFileConvertor = (text: string): File => {
    const blob = new Blob([text], {
      type: 'text/plain',
    });
    const textFile = new File([blob], 'file.txt', { type: 'text/plain' });

    return textFile;
  };

  const formatFileSize = (fileSizeInBytes: number): string => {
    if (fileSizeInBytes < 1024) {
      return `${fileSizeInBytes.toFixed(0)} B`;
    } else if (fileSizeInBytes < 1024 * 1024) {
      return `${(fileSizeInBytes / 1024).toFixed(0)} KB`;
    } else {
      return `${(fileSizeInBytes / (1024 * 1024)).toFixed(0)} MB`;
    }
  };

  const fileSize = formatFileSize(fileItem?.size ?? 0);

  const handleUploadUserFile = async (file: File) => {
    let projectId = urlParams?.projectId;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('project_id', projectId ?? '1');
    formData.append('created_by', '4');
    formData.append('organization_id', '5');
    formData.append('is_uploaded_file', uploadFileFlag.toString());

    try {
      setIsLoading(true);
      const response = await userStoryGeneratorService.UploadFile(formData);
      if (response && response.status === 201) {
        localStorage.setItem('currentPage', '1');
        navigate(`/org/${org_key}/project/${1}/epic-story-generator`);
      }
    } catch (error) {
      await loggerService.log({
        severity: 'High',
        message: 'Failed to Upload File',
        payload: error,
      });
      setIsLoading(false);
    }
  };

  const handleDeleteFile = () => {
    setFileItem(null);
    setImgUrl(false);
    setFileUpload(false);
    modalClose();
  };

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    customRequest: ({ file }: { file: any }) => {
      setImgUrl(true);
      message.success(
        `${truncateFileName(file.name)} ${userStoryGeneratorLabel?.lbl_file_upload_msg.lbl_upl_file_success_msg}`,
      );
    },

    onChange(info) {
      const { status } = info.file;

      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }

      if (status === 'done') {
        setIsUploading(false);
      } else if (info.file.status === 'error') {
        message.error(
          `${truncateFileName(info.file.name)} ${userStoryGeneratorLabel?.lbl_file_upload_msg.lbl_upl_file_failed_msg}`,
        );
      }
    },

    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const beforeUpload = async (file: File) => {
    const isSizeAccepted = file.size / 1024 / 1024 < 5; // 5MB
    if (!isSizeAccepted) {
      message.error(
        `${userStoryGeneratorLabel?.lbl_file_upload_msg.lbl_upl_file_size_msg}
      `,
      );
      return Upload.LIST_IGNORE;
    }

    const allowedFileTypes = [
      'text/plain',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const isAllowedFileType = allowedFileTypes.includes(file.type);

    if (!isAllowedFileType) {
      message.error(
        userStoryGeneratorLabel?.lbl_file_upload_msg.lbl_upl_file_type_err_msg,
      );
      return Upload.LIST_IGNORE;
    }

    setFileItem(file);
    setFileUpload(true);
    return true;
  };

  const truncateFileName = (fileName: string) => {
    if (fileName?.length > 30) {
      const newTruncatedFileName = fileName.substring(0, 30);
      return newTruncatedFileName + '...';
    }
    return fileName;
  };

  return (
    <div className="mainContent">
      <div className="usgContent">
        <Skeleton loading={isLoading} active>
          <div className="headingUSG">
            {userStoryGeneratorLabel?.lbl_user_story_title && (
              <h1>
                <img src={onlyLogo} alt="CleverSort" />
              </h1>
            )}

            <h2>
              {userStoryGeneratorLabel?.lbl_user_story_title}
              <span className="gradientText">
                {userStoryGeneratorLabel?.lbl_user_story_title_2}
              </span>
            </h2>
          </div>

          <div className="customDraggerAndEditor">
            <p>{userStoryGeneratorLabel?.lbl_user_story_subtitle}</p>
            {imgUrl ? (
              <div className="fileUploadedNameWrapper">
                <Tooltip
                  title={fileItem?.name}
                  placement="left"
                  color={'#2E364C'}
                >
                  <div className="uploadedFileName">
                    <div className="uploadedFileIcon">
                      <span
                        className={`${
                          fileItem?.type === 'text/plain'
                            ? 'txtFileIcon'
                            : 'docFileIcon'
                        }`}
                      ></span>
                      <div className="fileNameSize">
                        {truncateFileName(fileItem?.name!)}
                        <p className="fileSize">{fileSize}</p>
                      </div>
                    </div>
                  </div>
                </Tooltip>

                <span
                  className="cmnIcon deleteBin"
                  onClick={() => setIsDeleteModalActive(true)}
                  onKeyDown={() => {}}
                />
              </div>
            ) : (
              userStoryGeneratorLabel?.lbl_upload_file?.lbl_uploaded_file && (
                <Dragger
                  showUploadList={false}
                  {...props}
                  className={`customAntDragger ${
                    disableUploadFile ? 'disabledElement' : ''
                  }`}
                  beforeUpload={beforeUpload}
                  maxCount={1}
                  disabled={disableUploadFile}
                >
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>

                  <b className="ant-upload-text">
                    <span>
                      {
                        userStoryGeneratorLabel?.lbl_upload_file
                          ?.lbl_uploaded_file
                      }
                    </span>
                    {userStoryGeneratorLabel?.lbl_upload_file?.lbl_drag_drop}
                  </b>
                  <p className="ant-upload-hint">
                    {userStoryGeneratorLabel?.lbl_upload_file.lbl_all_files}
                  </p>
                  <p className="ant-upload-hint">
                    {userStoryGeneratorLabel?.lbl_upload_file?.lbl_size}
                  </p>
                  <p className="ant-upload-list-text"></p>
                </Dragger>
              )
            )}
            {userStoryGeneratorLabel?.lbl_user_story_subtitle && (
              <div
                className={`userStoryGenerator-wrapper ${
                  isDisable ? 'disabledElement ' : ' '
                } `}
              >
                <Editor
                  extensionPosition="top"
                  editorClassName={`userStoryGenerator${
                    isDisable ? '-disabled' : ''
                  } `}
                  allowedExtensions={{
                    showBoldOutlined: true,
                    showItalicOutlined: true,
                    showUnorderedListOutlined: true,
                    showUndoRedo: true,
                  }}
                  editorContent={editorContent}
                  setEditorData={setEditorContent}
                  disable={isDisable}
                  autoFocus={true}
                  toolTipPlacement="top"
                />
              </div>
            )}
            {isNotified && (
              <span className="msgError">
                {userStoryGeneratorLabel?.lbl_character_count_msg
                  .lbl_character_count_msg_1 +
                  ' ' +
                  charcterCount +
                  ' ' +
                  userStoryGeneratorLabel?.lbl_character_count_msg
                    .lbl_character_count_msg_2}
              </span>
            )}{' '}
            <div className="btnSec">
              {epicsAndStoryData[0]?.data !== null && (
                <button
                  onClick={() =>
                    navigate(
                      `/org/${org_key}/project/${1}/epic-story-generator?page=1`,
                    )
                  }
                  className="noStyle cancel_deleteFolder"
                >
                  {userStoryGeneratorLabel?.lbl_cancel_btn}
                </button>
              )}
              {userStoryGeneratorLabel?.lbl_user_story_identify_epic_story && (
                <button
                  onClick={onSubmitFilehandler}
                  disabled={!fileUpload || isUploading}
                  className="btnStyle1"
                >
                  {userStoryGeneratorLabel?.lbl_user_story_identify_epic_story}
                </button>
              )}
            </div>
          </div>
        </Skeleton>
      </div>
    </div>
  );
};

export default UserStoryGenerator;
