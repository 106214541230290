import { FC, Fragment } from "react";
import { Tooltip } from "antd";
import { IProjectMember } from "models/interface";
import { generateUserAvatar } from "utils/commonFunctions";

const UserAvatar: FC<{
  projectMember: IProjectMember;
  showProjectMemberDropdown: boolean;
}> = ({ projectMember, showProjectMemberDropdown }) => {
  const getAvatarBody = () => {
    return (
      <Fragment>
        {generateUserAvatar(projectMember).key === "string" ? (
          <div
            className={`userAvtr ${
              projectMember.inactive ? "userAvtrBlank" : ""
            }`}
          >
            {projectMember.inactive ? "" : generateUserAvatar(projectMember).value}
          </div>
        ) : (
          <div
            className={`userAvtr ${
              projectMember.inactive ? "userAvtrBlank" : ""
            }`}
          >
            {projectMember.inactive ? <></> : <img src={generateUserAvatar(projectMember).value} />}
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {showProjectMemberDropdown ? (
        getAvatarBody()
      ) : (
        <Tooltip
          title={projectMember.inactive ? projectMember.email : projectMember.full_name}
          placement="bottom"
          color={"#2E364C"}
        >
          {getAvatarBody()}
        </Tooltip>
      )}
    </Fragment>
  );
};

export default UserAvatar;
