import { FC, useState } from 'react';
import { Input, Popover, message } from 'antd';
import { projectService } from '../../../../../../services';
import { useProjectDashboardStore } from '../../../../../../stores';
import { IProjectState } from '../../../../../../models/interface';

const { Search } = Input;

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

const MemopadLink: FC<{
  onChange: (evt: { [key: string]: any }) => void;
  content: { [key: string]: any };
  disableEditor?: boolean;
  focusLink?: boolean;
  setFocusLink?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ onChange, content, disableEditor, focusLink, setFocusLink }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [metadata, setMetadata] = useState<{ [key: string]: any } | null>(
    Object.keys(content.content).length ? content.content : null,
  );
  const [defaultLink, setDefaultLink] = useState(
    Object.keys(content.content).length ? content.content.link : '',
  );
  const [searchStatus, setSearchStatus] = useState<
    undefined | '' | 'warning' | 'error'
  >('');
  const { memopadCmsData, messagingCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const fetchMetaData = async (url: string) => {
    setIsLoading(true);
    if (isValidUrl(url)) {
      const result = await projectService.getWebsiteMeta(url);
      const processResult = { ...result.meta, link: result.link };
      console.log(processResult);
      setMetadata(processResult);
      onChange(processResult);
      setIsLoading(false);
      setFocusLink && setFocusLink(false);
    } else {
      setIsLoading(false);
      setSearchStatus('error');
      message.info(messagingCmsData.lbl_invalid_link, 2);
    }
  };

  const navigateToAnotherPage = (url: string) => {
    window.open(url, '_blank')!.focus();
  };

  if (!metadata) {
    return (
      <Search
        autoFocus={focusLink}
        status={searchStatus}
        defaultValue={defaultLink}
        onChange={(e) => {
          setDefaultLink(e.target.value);
          setSearchStatus('');
        }}
        disabled={isLoading || Boolean(disableEditor) === true}
        placeholder="input search loading default"
        loading={isLoading}
        onBlur={(e) => {
          if (e.target.value.length) {
            return fetchMetaData(e.target.value);
          } else if (Object.keys(content.content).length) {
            setMetadata(content.content);
            setFocusLink && setFocusLink(false);
          }
        }}
        onKeyDown={(e) => {
          if (e.code === 'Enter' && defaultLink.length) {
            return fetchMetaData(defaultLink);
          }
          setFocusLink && setFocusLink(false);
        }}
      />
    );
  }
  if (Boolean(disableEditor) === false) {
    return (
      <Popover
        placement="right"
        content={
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setMetadata(null);
              setFocusLink && setFocusLink(true);
            }}
            onKeyDown={() => {}}
          >
            {memopadCmsData?.memopad_tooltip.change_link}
          </span>
        }
      >
        <div
          className="linkContainer"
          onClick={() => navigateToAnotherPage(metadata.link)}
          onKeyDown={() => {}}
        >
          <span className="linkUrl">{metadata['link']}</span>
          <div className="linkDescription">
            <img
              width={100}
              src={metadata['og:image']}
              alt={metadata['title']}
              className="linkImage"
            />
            <div className="linkThumbnailLink">
              <span className="linkTitle">{metadata['title']}</span>
              <p>{metadata['description']}</p>
            </div>
          </div>
        </div>
      </Popover>
    );
  }
  return (
    <div
      className="linkContainer"
      role="button"
      tabIndex={0}
      onClick={() => navigateToAnotherPage(metadata.link)}
      onKeyDown={() => {}}
    >
      <span className="linkUrl">{metadata['link']}</span>
      <div className="linkDescription">
        <img
          width={100}
          src={metadata['og:image']}
          alt={metadata['title']}
          className="linkImage"
        />
        <div className="linkThumbnailLink">
          <span className="linkTitle">{metadata['title']}</span>
          <p>{metadata['description']}</p>
        </div>
      </div>
    </div>
  );
};

export default MemopadLink;
