import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';

import { MentionList } from './MentionList';
import BrowserStorageService from 'services/browserStorage.service';

const suggestion = (info: any, mentionPopupParent: any) => {
  let reactRenderer: any;
  let popup: any;
  let currentParent = mentionPopupParent;

  const createPopup = (props: any) => {
    if (!props.clientRect) {
      return;
    }

    reactRenderer = new ReactRenderer(MentionList, {
      props: { ...props, info },
      editor: props.editor,
    });

    popup = tippy('.commentContainer', {
      getReferenceClientRect: props.clientRect,
      appendTo: () => {
        return currentParent;
      },
      content: reactRenderer.element,
      showOnCreate: true,
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
    });
  };

  const destroyPopup = () => {
    if (popup) {
      popup[0].destroy();
      popup = null;
    }
    if (reactRenderer) {
      reactRenderer.destroy();
      reactRenderer = null;
    }
  };

  const updateParent = (newParent: any) => {
    if (currentParent !== newParent) {
      currentParent = newParent;
      if (popup) {
        const props = popup[0].props;
        destroyPopup();
        createPopup(props);
      }
    }
  };

  return {
    items: ({ query }: { query: string }) => {
      // You can query an API here or filter an array of users
      const browserStorage = BrowserStorageService.getInstance();
      let data = JSON.parse(browserStorage.getProjectMembers());
      return [...data]
        .map((ele) => `${ele.full_name}?${JSON.stringify(ele)}`)
        .filter((item) =>
          item.split('?')[0].toLowerCase().startsWith(query.toLowerCase()),
        );
    },

    render: () => {
      return {
        onStart: (props: any) => {
          createPopup(props);
        },

        onUpdate(props: any) {
          reactRenderer.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: any) {
          if (props.event.key === 'Escape') {
            popup[0].hide();

            return true;
          }

          return reactRenderer.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          reactRenderer.destroy();
        },
      };
    },
    updateParent: updateParent,
  };
};

export default suggestion;
