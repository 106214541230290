import BrowserStorage from 'models/classes/StorageWrapper';

enum Locals {
  ORGANIZATION_KEY = 'org_id',
}

export default class AuthService extends BrowserStorage<Locals> {
  private static instance?: AuthService;
  private getAccessTokenSilentlyFunction?: () => Promise<string>;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new AuthService();
    }
    return this.instance;
  }

  public getCurrentOrganization() {
    return this.getFromLocalStorage(Locals.ORGANIZATION_KEY);
  }

  public setCurrentOrganization(organizationId: string) {
    this.saveToLocalStorage(Locals.ORGANIZATION_KEY, organizationId);
  }

  public clearCurrentOrganization() {
    this.clearItemFromLocalStorage(Locals.ORGANIZATION_KEY);
  }

  public setAccessTokenSilentlyFunction(func: any) {
    this.getAccessTokenSilentlyFunction = func;
  }

  public async getAccessToken() {
    try {
      if (this.getAccessTokenSilentlyFunction) {
        return await this.getAccessTokenSilentlyFunction();
      }
    } catch (error) {}
    return null;
  }
}
