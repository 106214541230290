import React from 'react'
import { IProfileSettingCmsData } from "models/interface"; 
import TeamColor from './Customize/TeamColor';


interface ICustomizeProps {
  lblCms: IProfileSettingCmsData | null;
  isActive: boolean;
}

const Customize: React.FC<ICustomizeProps> = ({ lblCms, isActive }) => {
  
  return (
   <TeamColor isActive={isActive} lblCms={lblCms} />
  );
};

export default Customize;