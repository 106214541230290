import { FC, Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  IGridList,
  IGridListStageDetail,
  IProjectState,
} from "models/interface";
import StageNameInputField from "./StageNameInputField";
import { useProjectDashboardStore } from "stores";
import { checkIsCustomField } from "../../services/grid.handler";

const StageList: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  submitChange: (stageName: string, eventType: string) => Promise<void>;
  updateStage: (stageName: any, stageId: any) => Promise<void>;
  revertChange: (stageId: number, event: string) => void;
  openMessageExpandedView?: boolean;
  setOpenMessageExpandedView?: React.Dispatch<React.SetStateAction<boolean>>;
  expandedViewForMessageIndex?: number | undefined;
  setExpandedViewForMessageIndex?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}> = ({
  gridListDetails,
  setGridListDetails,
  submitChange,
  updateStage,
  revertChange,
  openMessageExpandedView,
  setOpenMessageExpandedView,
  expandedViewForMessageIndex,
  setExpandedViewForMessageIndex,
}) => {
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state
  );

  return (
    <Fragment>
      {gridListDetails?.grid_list_details?.stage_details?.map(
        (ele: IGridListStageDetail, index: number) => {
          if (checkIsCustomField(ele.stage_id)) return null;
          return (
            <Draggable
              key={ele.stage_id}
              draggableId={`${ele.stage_id}`}
              index={index}
              isDragDisabled={
                gridListDetails.grid_list_details?.stage_details?.length ===
                  1 || projectDetails?.is_archived
              }
            >
              {(provided: any) => {
                return (
                  <li
                    key={ele.stage_id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={
                      ele?.isEdit && gridListDetails?.compactView
                        ? "addEditStage "
                        : projectDetails?.is_archived
                        ? "notDraggable"
                        : ""
                    }
                  >
                    <StageNameInputField
                      stageDetails={ele}
                      submitChange={submitChange}
                      updateStage={updateStage}
                      gridListDetails={gridListDetails}
                      setGridListDetails={setGridListDetails}
                      revertChange={revertChange}
                      index={index}
                      openMessageExpandedView={openMessageExpandedView}
                      setOpenMessageExpandedView={setOpenMessageExpandedView}
                      expandedViewForMessageIndex={expandedViewForMessageIndex}
                      setExpandedViewForMessageIndex={
                        setExpandedViewForMessageIndex
                      }
                    />
                  </li>
                );
              }}
            </Draggable>
          );
        }
      )}
    </Fragment>
  );
};

export default StageList;
