import { RoutesEnum } from "../models/enums/apiRoutes";
import apiService from "./Api.service";

export interface ILoggerData {
  type?: "Error";
  message: string;
  function_name?: string;
  severity?: "High" | "Medium" | "Low";
  payload?: any;
  errors?: any;
  status_code?: number;
}

class LoggerService {
  public log = async (loggerData: ILoggerData) => {
    let msg = "Error from Frontend to test the use case";

    if (loggerData.payload instanceof Error) {
      msg = loggerData.payload.message;
    }
    let logData: any = {
      type: loggerData.type ? loggerData.type : "Error",
      message: loggerData.message ? loggerData.message : msg,
      stage: process.env.REACT_APP_STAGE,
      function_name: loggerData.function_name ? loggerData.function_name : "",
      db_query: "",
      errors: loggerData.errors ? loggerData.errors : "",
      user_agent: window.navigator.userAgent,
      severity: loggerData.severity ? loggerData.severity : "Low",
      payload: loggerData.payload ? loggerData.payload : "",
      origin: "FRONTEND",
    };

    return apiService.post(RoutesEnum.LOG, logData);
  };
}

const loggerService = new LoggerService();
export default loggerService;
