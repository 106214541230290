import { FC } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import TaskListWrapper from "./Task/TaskListWrapper";
import StageListWrapper from "./Stage/StageListWrapper";
import BlockWrapper from "./Block/BlockWrapper";
import { IGridList, IProjectTemplate } from "models/interface";

const GridListDetails: FC<{
  sectionDetails: IGridList;
  sectionToggleState: "collapsed" | "expanded";
  templateDetails: IProjectTemplate;
}> = ({ sectionDetails, sectionToggleState, templateDetails }) => {

  const checkStageList = (sectionDetails: IGridList) => {
    if (!sectionDetails.grid_list_details) {
      return false;
    } else {
      if (sectionDetails?.grid_list_details?.stage_details === null) {
        return false;
      }
      return true;
    }
  };

  return (
    <div
      className={`gridWrapperWhole ${
        sectionToggleState === "collapsed" ? "toggle-taskTable" : ""
      }`}
    >
      <div
        className={`gridWrapper ${
          !checkStageList(sectionDetails) ? "emptyStateGrid" : ""
        }`}
      >
        <div className="stagesWrapper">
          <div className="stageHeader">
            <p className="taskheading">TASKS</p>
            <p className="stageheading">STAGES</p>
          </div>

          <DragDropContext onDragEnd={() => {}}>
            <Droppable droppableId="droppableTask" direction="vertical">
              {(provided: any) => {
                return (
                  <ul
                    className="taskInnerWrap"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <TaskListWrapper sectionDetails={sectionDetails} templateDetails={templateDetails} />
                    {provided.placeholder}
                  </ul>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>

        <div className="taskOuterWrap">
          <DragDropContext onDragEnd={() => {}}>
            <Droppable droppableId="droppableStage" direction="horizontal">
              {(provided: any) => {
                return (
                  <ul
                    className="stageItemList"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <StageListWrapper
                      sectionDetails={sectionDetails}
                      checkStageList={checkStageList}
                      templateDetails={templateDetails}
                    />
                  </ul>
                );
              }}
            </Droppable>
          </DragDropContext>
          <div className="subtaskWrapperWhole">
            <BlockWrapper sectionDetails={sectionDetails} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridListDetails;
