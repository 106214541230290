import { message, Popconfirm } from 'antd';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import SharedAssigneeDropDown from 'components/sharedComponents/SharedAssigneeDropDown/SharedAssigneeDropDown';
import StatusChange from 'components/sharedComponents/StatusChange/StatusChange';
import cryptoRandomString from 'crypto-random-string';
import { ContextTypes } from 'models/enums/constants';
import { SectionTypeMessages } from 'models/enums/messages';
import { TaskListView } from 'models/enums/tasklistView';
import {
  IProjectState,
  ITaskList,
  ITaskListDetails,
  IUpdatedTasks,
} from 'models/interface';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { projectService } from 'services';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { getCurrentOrganization, isEmptyString } from 'utils/commonFunctions';
import {
  markTaskDeleteInMessageThread,
  renameTaskInMessageThread,
} from 'utils/firebaseCommonFunctions';
import MessageThread from '../../MessageThread';
import TaskDueDate from '../TaskDueDate';
import TaskPriority from '../TaskPriority';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';

interface ITaskListCard {
  eachTaskDetail: ITaskListDetails;
  sectionId?: number | string;
  setTaskListDetails: React.Dispatch<React.SetStateAction<ITaskList>>;
  statusDoneId?: number;
  packId?: number;
  // socket: any;
  sectionName: string;
  taskDetails?: ITaskList;
  openMessageExpandedView?: boolean;
  setOpenMessageExpandedView?: React.Dispatch<React.SetStateAction<boolean>>;
  index?: number;
  setListDetails?: any;
  listDetails?: any;
  currentSection?:
    | {
        sectionId: number;
        popup: 'assignee' | 'dueDate' | 'message';
      }
    | undefined;
  setCurrentSection?: React.Dispatch<
    React.SetStateAction<
      | {
          sectionId: number;
          popup: 'assignee' | 'dueDate' | 'message';
        }
      | undefined
    >
  >;
  latestNamedTask?:
    | {
        id: string;
        name: string;
      }
    | undefined;
  setLatestNamedTask?: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          name: string;
        }
      | undefined
    >
  >;
  setIsTaskEmpty?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaskListCard: React.FC<ITaskListCard> = ({
  eachTaskDetail,
  sectionId,
  setTaskListDetails,
  statusDoneId,
  packId,
  // socket,
  sectionName,
  taskDetails,
  index,
  setListDetails,
  listDetails,
  currentSection,
  setCurrentSection,
  latestNamedTask,
  setLatestNamedTask,
  setIsTaskEmpty,
}) => {
  const {
    taskListCmsData,
    projectDetails,
    customizeSectionUpdateCmsData: customizeCmsData,
    packList,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const { userDetails } = useUserStore((state: any) => state);

  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);
  const [expandedViewForMessageIndex, setExpandedViewForMessageIndex] =
    useState<number | undefined>();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const currentOrganization =
    userDetails?.organization_info &&
    getCurrentOrganization(org_key, userDetails?.organization_info);
  const [taskName, setTaskName] = useState(eachTaskDetail.task_name);
  const [isEditMode, setIsEditMode] = useState(false);
  const { hasPermissions } = useRbac();
  const [taskDetail, setTaskDetail] = useState(eachTaskDetail);

  const { fetchNewPermission } = useFetchNewPermission();

  const findTaskIdAndUpdateMainTaskList = (
    eachTaskDetailUpdated: ITaskListDetails,
  ) => {
    let allCurrentTask: any[] = [];
    if (
      taskDetails !== null &&
      taskDetails !== undefined &&
      taskDetails.task_list_details !== undefined &&
      taskDetails.task_list_details.length > 0
    ) {
      allCurrentTask = [...taskDetails.task_list_details];
      allCurrentTask.forEach((task, index) => {
        if (task.task_id === eachTaskDetailUpdated?.task_id) {
          allCurrentTask[index] = { ...eachTaskDetailUpdated };
        }
      });
      setTaskListDetails((prevState) => ({
        ...prevState,
        task_list_details: allCurrentTask,
      }));
    }
  };

  useEffect(() => {
    setTaskDetail(eachTaskDetail);
  }, [eachTaskDetail]);

  const saveNewTask = async (taskId: string) => {
    if (projectDetails?.project_id && typeof sectionId === 'number') {
      try {
        let tskName = taskName;
        const response = await projectService.createNewTask({
          organizationId: currentOrganization.organization_id,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          task_name: tskName?.trim() ?? '',
          task_status_id: 1,
          task_priority_id: 2,
        });
        if (response.data.data) {
          setTaskListDetails((prev) => {
            let newArr = prev.task_list_details?.map((each) => {
              if (each.task_id === taskId) {
                return {
                  ...each,
                  task_id: response.data.data,
                  task_name: tskName?.trim(),
                };
              }
              return each;
            });

            return { ...prev, task_list_details: newArr };
          });
          // notifyChannel(
          //   userDetails,
          //   NotificationEventType.ADD_SIMPLE_TASK_ROW,
          //   JSON.stringify({
          //     taskId: response.data.data,
          //     taskName: tskName?.trim(),
          //   })
          // );
        }
        setTaskName('');
        setLatestNamedTask && setLatestNamedTask(undefined);
        setIsTaskEmpty?.(false);
      } catch (e: any) {
        if (e?.response?.data?.messageId === -4) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        setLatestNamedTask && setLatestNamedTask(undefined);
        removeBlankNewTask();
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const updateExistingTask = async (taskData: IUpdatedTasks[]) => {
    if (projectDetails?.project_id && typeof sectionId === 'number') {
      try {
        await projectService.updateTask({
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          project_id: projectDetails.project_id,
          section_id: sectionId,
          section_details: taskData,
        });
        setTaskListDetails((prev) => ({
          ...prev,
          task_list_details: prev.task_list_details?.map((eachTask) => {
            if (eachTask.task_id === taskDetail.task_id) {
              return { ...eachTask, ...taskData[0] };
            }
            return eachTask;
          }),
        }));
      } catch (error: any) {
        if (error?.response?.data?.messageId === -4) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        message.error(taskListCmsData?.error_message_options?.msg, 3);
      }
    }
  };

  const taskNameChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.target?.value;
    setTaskName(value);
  };

  const removeBlankNewTask = () => {
    setTaskListDetails((prev) => {
      const _temp_first_task = prev?.task_list_details?.slice(0, 1);
      const _task_list_details = prev.task_list_details?.filter(
        (eachTask, index) => {
          if (typeof eachTask.task_id === 'number') {
            return eachTask;
          } else {
            if (
              prev.task_list_details &&
              index + 1 < prev?.task_list_details?.length
            ) {
              if (
                prev.task_list_details[index + 1].task_id ===
                latestNamedTask?.id
              ) {
                return eachTask;
              }
            }
          }
        },
      );
      return {
        ...prev,
        task_list_details: _task_list_details?.length
          ? _task_list_details
          : _temp_first_task,
      };
    });
    latestNamedTask && setTaskName(latestNamedTask?.name);
    setLatestNamedTask && setLatestNamedTask(undefined);
  };

  const addNewTaskOnEnter = (
    taskId: string | number,
    event?: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event?.key === 'Enter') {
      if (!isEmptyString(taskName)) {
        setTaskName((prev) => prev?.trim());
      }
      const task_id = cryptoRandomString({ length: 5 });
      if (!isEmptyString(taskName) && taskName !== undefined) {
        setLatestNamedTask &&
          setLatestNamedTask({ id: task_id, name: taskName });
      }
      setTaskListDetails((prev) => {
        const _task_list_details = prev?.task_list_details;
        if (_task_list_details) {
          let max = _task_list_details?.reduce(function (prev, current) {
            return prev?.task_rank > current?.task_rank ? prev : current;
          }).task_rank;
          let newTask = {
            task_id: task_id,
            task_rank: max + 1,
            task_name: '',
            due_date: '',
            assignee_dp: '',
            assignee_id: null,
            assignee_name: '',
            assignee_email: '',
            assigner_id: null,
            task_status_id: 1,
            task_priority_id: 2,
            task_status_name: taskListCmsData?.task_status_options.find(
              (eachOption) => eachOption.status_id === 1,
            )?.status_name,
            task_priority_name: taskListCmsData?.task_priority_options.find(
              (eachOption) => eachOption.priority_id === 2,
            )?.priority_name,
            created_by: userDetails?.user_id,
          };
          _task_list_details?.push(newTask);
        } else {
          event.currentTarget.blur();
        }
        return { ...prev, task_list_details: _task_list_details };
      });
    }
  };

  const taskNameOnBlurHandler = (
    taskId: string | number,
    event?: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (!isEmptyString(taskName)) {
      setTaskName((prev) => prev?.trim());
    }
    const oldTaskName = taskDetail.task_name;
    if (typeof taskId === 'string' && isEmptyString(taskName)) {
      removeBlankNewTask();
    } else if (
      typeof taskId === 'number' &&
      isEmptyString(taskName) &&
      oldTaskName
    ) {
      setTaskName(oldTaskName);
    } else if (
      typeof taskId === 'string' &&
      !isEmptyString(taskName) &&
      event?.target.value
    ) {
      saveNewTask(taskId);
    } else if (
      typeof taskId === 'number' &&
      oldTaskName &&
      oldTaskName !== taskName
    ) {
      // notifyChannel(
      //   userDetails,
      //   NotificationEventType.UPDATE_SIMPLE_TASK_ROW,
      //   JSON.stringify({
      //     ...taskDetail,
      //     task_id: taskId,
      //     task_name: taskName,
      //   })
      // );
      updateExistingTask([{ task_id: taskId, task_name: taskName }]);
    }
    setIsEditMode(false);
    if (taskName) {
      renameTaskInMessageThread(
        `${SectionTypeMessages.SIMPLE_LIST_TASK}-${
          process.env.REACT_APP_STAGE
        }-${getCurrentOrganization(org_key, userDetails?.organization_info)
          ?.organization_id!}-${projectDetails?.project_id}-${sectionId}-${
          eachTaskDetail.task_id
        }`,
        taskName,
      );
    }
  };

  const deleteTaskHandler = () => {
    if (typeof taskDetail.task_id === 'number') {
      // notifyDeleteChannel(
      //   userDetails,
      //   NotificationEventType.DELETE_SIMPLE_TASK_ROW
      // );
      setTaskListDetails((prev) => {
        let _task_list_details = prev.task_list_details;
        if (_task_list_details && _task_list_details.length === 1) {
          const newTask = {
            task_id: cryptoRandomString({ length: 5 }),
            task_rank: 1,
            task_name: '',
            due_date: '',
            assignee_dp: '',
            assignee_id: null,
            assignee_name: '',
            assignee_email: '',
            assigner_id: null,
            task_status_id: 1,
            task_priority_id: 2,
            task_status_name: taskListCmsData?.task_status_options.find(
              (eachOption) => eachOption.status_id === 1,
            )?.status_name,
            task_priority_name: taskListCmsData?.task_priority_options.find(
              (eachOption) => eachOption.priority_id === 2,
            )?.priority_name,
            created_by: userDetails?.user_id,
          };
          _task_list_details?.push(newTask);
          setIsTaskEmpty?.(true);
        }
        return {
          ...prev,
          task_list_details: prev.task_list_details?.filter(
            (eachTask) => eachTask.task_id !== eachTaskDetail.task_id,
          ),
        };
      });
      updateExistingTask([
        {
          ...eachTaskDetail,
          task_id: +eachTaskDetail.task_id,
          is_deleted: true,
        },
      ]);

      markTaskDeleteInMessageThread(
        `${SectionTypeMessages.SIMPLE_LIST_TASK}-${
          process.env.REACT_APP_STAGE
        }-${getCurrentOrganization(org_key, userDetails?.organization_info)
          ?.organization_id!}-${projectDetails?.project_id}-${sectionId}-${
          eachTaskDetail.task_id
        }`,
      );
    }
  };

  const renderTaskName = () => {
    if (!isEditMode && typeof taskDetail.task_id !== 'string') {
      if (taskDetail.task_status_id === 3) {
        const selectedStatus = customizeCmsData?.done_task_list.find(
          (e) => e.status_done_view_id === statusDoneId,
        );
        if (selectedStatus) {
          return (
            <div
              className={selectedStatus.classname}
              onClick={() =>
                hasPermissions(
                  [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                  projectDetails?.associated_role_id,
                ) && setIsEditMode(true)
              }
              onKeyDown={() => {}}
            >
              {selectedStatus.status_done_view_id === 3 ? (
                <del className="simpleTaskListTaskName">
                  {taskName?.slice(0, 255)}
                </del>
              ) : (
                <span className="simpleTaskListTaskName">
                  {taskName?.slice(0, 255)}
                </span>
              )}
            </div>
          );
        }
      }

      return (
        <div
          onClick={() =>
            hasPermissions(
              [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
              projectDetails?.associated_role_id,
            ) && setIsEditMode(true)
          }
          onKeyDown={() => {}}
        >
          <span className="simpleTaskListTaskName">
            {taskName?.slice(0, 255)}
          </span>
        </div>
      );
    } else {
      if (projectDetails?.is_archived === false) {
        return (
          <form onSubmit={(e) => e.preventDefault()}>
            <input
              autoFocus
              type="text"
              id={`text-input-${sectionId}-${taskDetail.task_id?.toString()}`}
              className="text-input-box"
              placeholder={taskListCmsData?.lbl_task_name_placeholder}
              value={taskName}
              aria-label="task name"
              onChange={taskNameChangeHandler}
              onBlur={(e) => taskNameOnBlurHandler(taskDetail.task_id, e)}
              onKeyDown={(e) => addNewTaskOnEnter(taskDetail.task_id, e)}
              maxLength={255}
              readOnly={
                hasPermissions(
                  [ERbacPermissions.PROJECT_SECTION_TASK_EDIT],
                  projectDetails?.associated_role_id,
                ) === true
                  ? false
                  : true
              }
            />
          </form>
        );
      } else {
        return (
          <span className="simpleTaskListTaskName">
            {taskName?.slice(0, 255)}
          </span>
        );
      }
    }
  };

  // const notifyChannel = (
  //   userDetails: IUserDetails,
  //   eventType: string,
  //   eventValue: string = ""
  // ) => {
  //   notificationService.sendSimpleTaskUpdateNotification(socket, {
  //     eventType: eventType,
  //     eventValue: eventValue,
  //     sectionType: SectionType.SIMPLE_TASK_LIST,
  //     currentOrganizationId: getCurrentOrganization(
  //       org_key,
  //       userDetails?.organization_info!
  //     )?.organization_id,
  //     currentProjectId: projectDetails?.project_id,
  //     currentSectionId: sectionId,
  //     currentUserId: userDetails.user_id,
  //     currentUserName: userDetails.full_name
  //       ? userDetails.full_name
  //       : userDetails.email,
  //   });
  // };

  // const notifyDeleteChannel = (
  //   userDetails: IUserDetails,
  //   eventType: string,
  //   eventValue: string = ""
  // ) => {
  //   notificationService.sendSimpleTaskDeleteNotification(socket, {
  //     eventType: eventType,
  //     eventValue: eventValue,
  //     sectionType: SectionType.SIMPLE_TASK_LIST,
  //     currentOrganizationId: getCurrentOrganization(
  //       org_key,
  //       userDetails?.organization_info!
  //     )?.organization_id,
  //     currentProjectId: projectDetails?.project_id,
  //     currentSectionId: sectionId,
  //     currentUserId: userDetails.user_id,
  //     currentUserName: userDetails.full_name
  //       ? userDetails.full_name
  //       : userDetails.email,
  //   });
  // };

  const findProjectRoleId = (e: any) => {
    let roleId = userDetails?.project_permissions?.find(
      (element: any) => element.project_id == e?.project_id,
    );

    return roleId?.role_id;
  };

  return (
    <li
      className={`${
        taskDetail.task_status_id === 3 ? 'doneLeftProgressWrap' : ''
      }`}
    >
      {/* <TaskStatus
        eachTaskDetail={taskDetail}
        sectionId={sectionId}
        packId={packId}
        notifyChannel={notifyChannel}
        viewType={TaskListView.CARD}
        setEachTaskDetail={setTaskDetail}
        findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
      /> */}
      <StatusChange
        eachTaskDetail={{
          ...eachTaskDetail,
          project_id: projectDetails?.project_id,
          section_type_id: 1,
          org_id: currentOrganization?.organization_id,
          org_key: currentOrganization?.org_key,
          section_id: sectionId,
        }}
        setEachTaskDetail={setTaskDetail}
        project_role_id={findProjectRoleId({
          ...eachTaskDetail,
          project_id: projectDetails?.project_id,
          section_type_id: 1,
          org_id: currentOrganization?.organization_id,
          org_key: currentOrganization?.org_key,
        })}
        index={index!}
        // socket={socket}
        findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
        taskListDetails={listDetails}
        packId={packId}
        packList={packList}
      />
      <div className="taskName">
        <div className="taskNameMessageWrapper">{renderTaskName()}</div>
        <div className="taskRelatedData">
          <div className="assignWrap assigneeListWithoutTable">
            {/* <TaskAssignee
              eachTaskDetail={taskDetail}
              sectionId={sectionId}
              notifyChannel={notifyChannel}
              setEachTaskDetail={setTaskDetail}
              viewType={TaskListView.CARD}
              findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
            /> */}
            <SharedAssigneeDropDown
              eachTaskDetail={taskDetail}
              sectionId={sectionId}
              // notifyChannel={notifyChannel}
              setEachTaskDetail={setTaskDetail}
              viewType={TaskListView.CARD}
              findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
              contextType={ContextTypes.TASKLIST}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
          </div>
          <div className="priorityInfo">
            <TaskPriority
              eachTaskDetail={taskDetail}
              sectionId={sectionId}
              // notifyChannel={notifyChannel}
              setEachTaskDetail={setTaskDetail}
              findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
            />

            <TaskDueDate
              eachTaskDetail={taskDetail}
              sectionId={sectionId}
              // notifyChannel={notifyChannel}
              viewType={TaskListView.CARD}
              setEachTaskDetail={setTaskDetail}
              findTaskIdAndUpdateMainTaskList={findTaskIdAndUpdateMainTaskList}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
          </div>
          {!isEditMode && typeof eachTaskDetail.task_id !== 'string' && (
            <>
              <MessageThread
                componentId="taskListCardMessaging"
                sectionId={sectionId!}
                taskId={taskDetail.task_id}
                sectionType={SectionTypeMessages.SIMPLE_LIST_TASK}
                threadName={taskDetail.task_name!}
                sectionName={sectionName}
                taskDetails={taskDetail}
                setOpenMessageExpandedView={setOpenMessageExpandedView}
                index={index}
                setExpandedViewForMessageIndex={setExpandedViewForMessageIndex}
                currentSection={currentSection}
                setCurrentSection={setCurrentSection}
              />
            </>
          )}
        </div>
      </div>
      {!isEditMode &&
      typeof eachTaskDetail.task_id !== 'string' &&
      projectDetails?.is_archived === false ? (
        <Rbac
          allowedPermissions={[ERbacPermissions.PROJECT_SECTION_TASK_DELETE]}
          project_role_id={projectDetails?.associated_role_id}
        >
          <Popconfirm
            title={taskListCmsData?.lbl_task_delete_message}
            onConfirm={deleteTaskHandler}
            okText="Delete"
            okButtonProps={{
              style: { backgroundColor: '#ba1a1a' },
            }}
          >
            <span
              className="actionIons cmnIcon deleteBin"
              onKeyDown={() => {}}
            ></span>
          </Popconfirm>
        </Rbac>
      ) : (
        <></>
      )}
    </li>
  );
};

export default TaskListCard;
