import {
  IImageUrlParams,
  IProfilePayload,
  ITeamSetupPayload,
  IUserOnboardingAttributes,
  IUserState,
} from 'models/interface';
import PersonalProfile from './PersonalProfile';
import SchoolProfile from './SchoolProfile';
import WorkProfile from './WorkProfile';
import { useState } from 'react';
import { ComponentLoader, UploadAvatar } from 'components/sharedComponents';
import ImageFor from 'models/enums/imageFor';
import { userOnboardingService } from 'services';
import { Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import BrowserStorageService from '../../../../services/browserStorage.service';
import { useDashboardStore, useUserStore } from 'stores';
import { useAuth0 } from '@auth0/auth0-react';
import * as R from 'ramda';
import invitationService from 'services/Invitation/invitation.service';

interface IProfileWrapper {
  stepNumber: number;
  setStepNumber: React.Dispatch<React.SetStateAction<number>>;
  teamDetails: ITeamSetupPayload;
  pageCmsData: IUserOnboardingAttributes | null;
  profileDetails: IProfilePayload;
  setProfileDetails: React.Dispatch<React.SetStateAction<IProfilePayload>>;
  sendAnalyticsEvent: (eventLabel: string, eventName: string) => boolean;
  isCreatingNewTeam: boolean;
}

const ProfileWrapper: React.FC<IProfileWrapper> = ({
  stepNumber,
  setStepNumber,
  teamDetails,
  pageCmsData,
  profileDetails,
  setProfileDetails,
  sendAnalyticsEvent,
  isCreatingNewTeam,
}) => {
  const { newTeamValue } = useDashboardStore((state) => state);

  const [imagePayload, setImagePayload] = useState<IImageUrlParams>({
    base64Str: '',
    extension: '',
    image_for: ImageFor.PROFILE,
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [profileRequiredChecksPassed, setProfileRequiredChecksPassed] =
    useState<boolean>();

  const backClickHandler = () => {
    sendAnalyticsEvent('Team Creation/Update', 'Team Creation/Update Started');
    setStepNumber((prev) => prev - 1);
  };

  const browserStorage = BrowserStorageService.getInstance();
  const { userDetails } = useUserStore((state: IUserState) => state);
  const { logout } = useAuth0();

  const getProfileComponentByTeamType = () => {
    switch (teamDetails.team_type_id) {
      case 1:
        return (
          <WorkProfile
            stepNumber={stepNumber}
            pageCmsData={pageCmsData}
            profileDetails={profileDetails}
            setProfileDetails={setProfileDetails}
            profileRequiredChecksPassed={profileRequiredChecksPassed}
            setProfileRequiredChecksPassed={setProfileRequiredChecksPassed}
            isCreatingNewTeam={isCreatingNewTeam}
          />
        );
      case 2:
        return (
          <PersonalProfile
            stepNumber={stepNumber}
            pageCmsData={pageCmsData}
            profileDetails={profileDetails}
            setProfileDetails={setProfileDetails}
            profileRequiredChecksPassed={profileRequiredChecksPassed}
            setProfileRequiredChecksPassed={setProfileRequiredChecksPassed}
            isCreatingNewTeam={isCreatingNewTeam}
          />
        );
      case 3:
        return (
          <SchoolProfile
            stepNumber={stepNumber}
            pageCmsData={pageCmsData}
            profileDetails={profileDetails}
            setProfileDetails={setProfileDetails}
            profileRequiredChecksPassed={profileRequiredChecksPassed}
            setProfileRequiredChecksPassed={setProfileRequiredChecksPassed}
            isCreatingNewTeam={isCreatingNewTeam}
          />
        );
      default:
        return <></>;
    }
  };

  const avatarChangeHandler = (base64data: string, extension: string) => {
    if (base64data) {
      setImagePayload((prev) => ({
        ...prev,
        base64Str: base64data,
        extension: extension,
      }));
      setProfileDetails((prev) => ({
        ...prev,
        profile_image: base64data.toString(),
      }));
    } else {
      setImagePayload((prev) => ({
        ...prev,
        base64Str: '',
        extension: '',
      }));
      setProfileDetails((prev) => ({ ...prev, profile_image: '' }));
    }
  };

  const profileSetupHandler = async (orgKey?: string) => {
    if (profileDetails.team_type_id) {
      try {
        const _profileDetails = profileDetails;
        _profileDetails.localization = browserStorage.getLanguage();

        if (imagePayload.base64Str?.length && imagePayload.extension?.length) {
          const { data } = await userOnboardingService.saveProfileImage(
            imagePayload,
          );
          _profileDetails.profile_image = data.url;
          setProfileDetails(_profileDetails);
        }
        if (isCreatingNewTeam) {
          _profileDetails.is_existing = true;
        }
        const profileSetupResponse = await userOnboardingService.setupProfile({
          ..._profileDetails,
          org_key: orgKey ?? teamDetails?.org_key,
        });
        if (profileSetupResponse.messageId === 1) {
          sendAnalyticsEvent('Profile Creation', 'Profile Creation Finished');
          setIsLoading(false);
          if (isCreatingNewTeam) {
            const newUrl = `/org/${orgKey ?? teamDetails.org_key}/dashboard`;
            navigate(newUrl);
          }
          window.location.reload();
        }
        setIsLoading(false);
      } catch (error) {
        message.error(pageCmsData?.lbl_error_responses.profile_setup_error);
        sendAnalyticsEvent('Profile Creation', 'Profile Creation Error');
        setIsLoading(false);
      }
    }
  };

  const completeOnboardingProcess = () => {
    setIsLoading(true);
    if (isCreatingNewTeam) {
      setupOrganizaiton();
      return;
    }
    profileSetupHandler();
  };

  const setupOrganizaiton = async () => {
    try {
      const _teamDetails = teamDetails;
      if (
        teamDetails.orgImageBase64?.length &&
        teamDetails.orgImageExtension?.length
      ) {
        const { data } = await userOnboardingService.saveOrganizationLogo({
          base64Str: teamDetails?.orgImageBase64,
          extension: teamDetails?.orgImageExtension,
          image_for: ImageFor.ORGANIZATION,
        });
        _teamDetails.organization_logo = data.url;
      }
      let teamSetupResponse: any;
      teamSetupResponse = await userOnboardingService.setupTeam({
        ..._teamDetails,
        organization_name: teamDetails.organization_name?.trim(),
      });
      if (teamSetupResponse.messageId === 1) {
        sendAnalyticsEvent(
          'Team Creation/Update',
          'Team Creation/Update Finished',
        );
        sendAnalyticsEvent('Profile Creation', 'Profile Creation Started');
        const cleanData = R.uniqBy(
          R.prop('email'),
          newTeamValue.teamMember,
        ).filter((e) => e.email !== userDetails?.email!);
        const invitationData = cleanData.map((each) => ({
          email: each.email,
          role_id: each.roleId,
        }));
        if (invitationData?.length > 0) {
          await invitationService.inviteUsers({
            sender_name: userDetails?.full_name!,
            invitation_data: invitationData,
            organization_name: newTeamValue.teamName?.trim() as string,
            org_key: teamSetupResponse.data.org_key,
            sender_id: +userDetails?.user_id!,
            language: userDetails?.additional_settings?.language ?? 'en',
          });
        }
        profileSetupHandler(teamSetupResponse?.data?.org_key);
      }
    } catch (error) {
      message.error(pageCmsData?.lbl_error_responses.team_setup_error);
      sendAnalyticsEvent('Team Creation/Update', 'Team Creation/Update Error');
    }
  };

  return (
    <ComponentLoader isLoading={isLoading}>
      <div
        className={`section-content workspace completeProfileFieldTab ${
          stepNumber === 2 ? 'active' : ''
        }`}
        id="user-onboard-profile"
      >
        <div>
          <h1>{pageCmsData?.lbl_profile_header1}</h1>
          <div className="subHeading">
            <h2>{pageCmsData?.lbl_profile_header2}</h2>
            <p>{pageCmsData?.dsc_profile}</p>
          </div>
          <div className="completeProfileFieldsWrap uploadLogoDiv">
            <UploadAvatar
              uploadCtaLbl={pageCmsData?.lbl_profile_upload_or_update_photo_cta} // user onboarding
              oldProfilePicturePublicPath={profileDetails.profile_image}
              onChange={avatarChangeHandler}
              error_msgs={pageCmsData?.lbl_error_responses}
            />
            {getProfileComponentByTeamType()}
          </div>
        </div>
        <div>
          <div className="btnDiv">
            <button
              id="step02back"
              className="backBtn"
              onClick={backClickHandler}
            >
              <span className="sidePanelIcon leftArrow"></span>{' '}
              {pageCmsData?.lbl_back_cta}
            </button>
            <div className="rightPart">
              <button
                id="step03"
                className={`${
                  profileRequiredChecksPassed ? 'btnStyle3' : 'disabledBtn'
                }`}
                onClick={completeOnboardingProcess}
                disabled={!profileRequiredChecksPassed}
              >
                {pageCmsData?.btn_continue}
              </button>
            </div>
          </div>
          <span className="notUserText">
            Not <b>{userDetails?.email}?</b>{' '}
            <Typography.Link
              onClick={() => {
                localStorage.removeItem('latestPathName');
                localStorage.removeItem('selectedThreadId');
                localStorage.removeItem('currentMessageId');
                localStorage.removeItem('project_members');
                browserStorage.clearGlobalData();
                logout({
                  logoutParams: { returnTo: window.location.origin },
                });
              }}
              onKeyDown={() => {}}
            >
              Logout
            </Typography.Link>
          </span>
        </div>
      </div>
    </ComponentLoader>
  );
};

export default ProfileWrapper;
