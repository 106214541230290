import { useState } from "react";
import { useLoaderStore, useUserStore } from "../../../stores";
import classNames from "classnames";
import {
  ILoaderState,
  IOrganization,
  IProfileSettingCmsData,
} from "../../../models/interface";
import ProfileSetting from "./ProfileSetting";
import LanguageAndRegion from "./LanguageAndRegion";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TeamSettings from "./TeamSettings";
import BrowserStorageService from "services/browserStorage.service";
import Customize from "./Customize";

const ProfileSettingWrapper = ({
  lblCms,
}: {
  lblCms: IProfileSettingCmsData | null;
}) => {
  const activateTab = () => {
    switch (searchParams.get("section")) {
      case "team-settings":
        return 5;
      case "language":
        return 2;
      case "customize":
        return 3;
      default:
        return 1;
    }
  };

  const [searchParams] = useSearchParams();
  const { org } = useParams();
  const storeUserDetail = useUserStore((state) => state.userDetails);
  const [tabActive, setTabActive] = useState(activateTab());
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const navigate = useNavigate();
  const browserStorage = BrowserStorageService.getInstance();

  const [selectedOrganization, setSelectedOrganization] =
    useState<IOrganization>(
      storeUserDetail?.organization_info!.find((e) => e.org_key === org)!
    );

  const teamNameChange = (evt: string) => {
    setSelectedOrganization({
      ...selectedOrganization,
      organization_name: evt,
    });
  };

  return (
    <div className="mainContent">
      <div id="profileSettingsModal" className="profileSettingsPage">
        <div
          className="modal-header"
          onClick={() => {
            let lastUrl = browserStorage.getLastUrl();
            browserStorage.clearLastUrl();
            navigate(lastUrl);
          }}
          onKeyDown={() => {}}
        >
          <div className="content">
            <h2>{lblCms?.lbl_section_name}</h2>
          </div>
        </div>
        <div className="profileCustomNavTab" id="profileSettingsID">
          <div className="tab">
            <div className="workspaceTab">
              <h4>{lblCms?.lbl_teams_you_belong_to}</h4>
              {storeUserDetail?.organization_info?.map((e: IOrganization) => (
                <button
                  key={e.auth_org_id}
                  onClick={() => {
                    setLoaderState("active");
                    navigate(
                      `/org/${e.org_key}/users/settings?section=team-settings`,
                      { state: "dashboard" }
                    );
                  }}
                  className={classNames("tablinks", {
                    active: e.org_key === org && tabActive === 5,
                  })}
                >
                  {e.organization_name}
                </button>
              ))}
            </div>
            <div className="generalSettings">
              <h4>{lblCms?.lbl_general_setting}</h4>
              <button
                onClick={() => {
                  setTabActive(1);
                  navigate(`/org/${org}/users/settings`);
                }}
                className={classNames("tablinks", {
                  active: tabActive === 1,
                })}
              >
                {lblCms?.lbl_your_profile}
              </button>
              <button
                onClick={() => {
                  setTabActive(2);
                  navigate(`/org/${org}/users/settings?section=language`);
                }}
                className={classNames("tablinks", {
                  active: tabActive === 2,
                })}
              >
                {lblCms?.lbl_language_and_region}
              </button>
              <button
                onClick={() => {
                  setTabActive(3);
                  navigate(`/org/${org}/users/settings?section=customize`);
                }}
                className={classNames("tablinks", {
                  active: tabActive === 3,
                })}
              >
                {lblCms?.lbl_costumize}
              </button>
              {/*<button onClick={() => setTabActive(4)} className={classNames("tablinks", {active: tabActive === 4})}>{lblCms?.lbl_themes}</button>*/}
              {/*<button className="tablinks">{lblCms?.lbl_member}</button>*/}
              {/*<button className="tablinks">{lblCms?.lbl_notifications}</button>*/}
              {/*<button className="tablinks">{lblCms?.lbl_connected_app}</button>*/}
            </div>
            <ul className="externalLinks">
              <li>
                <a target="_blank" href="/privacy-policy">
                  {lblCms?.lbl_privacy_policy}{" "}
                  <span className="cmnIcon linkToOtherPage"></span>
                </a>
              </li>
              <li>
                <a target="_blank" href="/terms-of-use">
                  {lblCms?.lbl_terms_and_condition}{" "}
                  <span className="cmnIcon linkToOtherPage"></span>
                </a>
              </li>
            </ul>
          </div>
          <ProfileSetting isActive={tabActive === 1} lblCms={lblCms} />
          <LanguageAndRegion isActive={tabActive === 2} lblCms={lblCms} />
          <Customize isActive={tabActive === 3} lblCms={lblCms} />
          <div
            id="fourthTab"
            className="tabContent"
            style={{ display: tabActive === 4 ? "block" : "none" }}
          >
            <div className="toggleThemePart">
              <h4>Theme</h4>
              <p>
                Change the appearance of Cleversort across all of your
                workspaces.
              </p>
              <input type="checkbox" id="toggle-mode-cb" />
              <div className="mode-wrapper">
                <label className="toggle-mode" htmlFor="toggle-mode-cb">
                  <span className="toggle-border">
                    <span className="toggle-indicator"></span>
                  </span>
                </label>
                <div className="mode mode__light">
                  <p>Light Mode</p>
                </div>
                <div className="mode mode__dark">
                  <p>Dark Mode</p>
                </div>
              </div>
              <div className="themeVariation">
                <ul>
                  <li>
                    <div className="themeRadioHolder">
                      <input type="radio" id="midnight" name="themeRadio" />
                      <label htmlFor="midnight">
                        <div className="themeImg">
                          <img src="../images/marketingSite.svg" alt="" />
                        </div>
                        <div className="themeText">
                          <h5>Midnight</h5>
                        </div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="themeRadioHolder">
                      <input type="radio" id="Forest" name="themeRadio" />
                      <label htmlFor="Forest">
                        <div className="themeImg">
                          <img src="images/marketingSite.svg" alt="" />
                        </div>
                        <div className="themeText">
                          <h5>Forest</h5>
                        </div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="themeRadioHolder">
                      <input type="radio" id="Moonstone" name="themeRadio" />
                      <label htmlFor="Moonstone">
                        <div className="themeImg">
                          <img src="images/marketingSite.svg" alt="" />
                        </div>
                        <div className="themeText">
                          <h5>Moonstone</h5>
                        </div>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="themeRadioHolder">
                      <input type="radio" id="sunglow" name="themeRadio" />
                      <label htmlFor="sunglow">
                        <div className="themeImg">
                          <img src="images/marketingSite.svg" alt="" />
                        </div>
                        <div className="themeText">
                          <h5>Moonglow</h5>
                        </div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="sound">
                <h4>Sound</h4>
                <p>
                  Change the notification sound of Cleversort across all of your
                  workspaces.
                </p>
                <div className="sound-dropdown">
                  <select name="language-dropdown">
                    <option value="Chime" selected>
                      Chime
                    </option>
                    <option value="Other">Other</option>
                    <option value="Other2">Other2</option>
                  </select>
                </div>
                <input type="checkbox" id="toggle-mode-cb2" />
                <div className="sound-switcher mode-wrapper">
                  <label className="toggle-mode" htmlFor="toggle-mode-cb2">
                    <span className="toggle-border">
                      <span className="toggle-indicator"></span>
                    </span>
                  </label>
                  <div className="mode mode__light">
                    <p>Sound Off</p>
                  </div>
                  <div className="mode mode__dark">
                    <p>Sound On</p>
                  </div>
                </div>
              </div>
              <div className="customStatusUpdateWrapper">
                <div className="customStatusUpdate">
                  <h4>Custom status updates</h4>
                  <p>
                    Control how your “Done” tasks are displayed across all of
                    your workspaces.
                  </p>
                </div>
                <div className="statusUpdateVariationRadioWrapper">
                  <div className="radioWrapper">
                    <input
                      type="radio"
                      name="statusUpdateVariationRadio"
                      id="default"
                    />
                    <label htmlFor="default">
                      <div className="labelWrap">
                        <p>Default</p>
                        <img src="./images/defaultRadio.svg" alt="" />
                      </div>
                    </label>
                  </div>
                  <div className="radioWrapper">
                    <input
                      type="radio"
                      name="statusUpdateVariationRadio"
                      id="singleStroke"
                    />
                    <label htmlFor="singleStroke">
                      <div className="labelWrap">
                        <p>Single strike-out</p>
                        <img src="./images/singleStrokeRadio.svg" alt="" />
                      </div>
                    </label>
                  </div>
                  <div className="radioWrapper">
                    <input
                      type="radio"
                      name="statusUpdateVariationRadio"
                      id="doubleStroke"
                    />
                    <label htmlFor="doubleStroke">
                      <div className="labelWrap">
                        <p>Double strike-out</p>
                        <img src="./images/doubleStrokeRadio.svg" alt="" />
                      </div>
                    </label>
                  </div>
                  <div className="radioWrapper">
                    <input
                      type="radio"
                      name="statusUpdateVariationRadio"
                      id="fullScribbleStroke"
                    />
                    <label htmlFor="fullScribbleStroke">
                      <div className="labelWrap">
                        <p>Full scribble</p>
                        <img src="./images/fullScribbleRadio.svg" alt="" />
                      </div>
                    </label>
                  </div>
                  <div className="radioWrapper">
                    <input
                      type="radio"
                      name="statusUpdateVariationRadio"
                      id="greenHighlight"
                    />
                    <label htmlFor="greenHighlight">
                      <div className="labelWrap">
                        <p>Green highlight</p>
                        <img src="./images/highlightedRadio.svg" alt="" />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TeamSettings
            isActive={tabActive === 5}
            lblCms={lblCms}
            selectedOrganization={selectedOrganization}
            teamNameChange={teamNameChange}
          />
        </div>
      </div>
      <div
        className="createNewProjectForm formUpgradeDrawer"
        id="createNewProjectFormUpgradePlan"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h3>Upgrade Subscription</h3>
            <span
              className="material-icons-outlined modal-close"
              id="planUpgradeClose"
            >
              close
            </span>
          </div>
          <div className="modal-body">
            <div className="projectName">
              <h3>Select Plan</h3>
              <div className="selectPlanRadioWrapper">
                <div className="themeRadioHolder">
                  <input type="radio" id="annualProPlan" name="themeRadio" />
                  <label htmlFor="annualProPlan">
                    <div className="annualProPlan radioContent">
                      <p>
                        <strong>Annual Pro Plan</strong> billed at $60.00
                        USD/per year
                        <span>$5.00/per month</span> <strong>$7.00</strong>
                      </p>
                      <div className="perks">
                        <h4>Included in your subscription</h4>
                        <ul>
                          <li>Unlimited checklists</li>
                          <li>Unlimited users</li>
                          <li>Unlimited collaborators</li>
                          <li>Priority support</li>
                        </ul>
                      </div>
                    </div>
                    <div className="themeText">
                      <h5>Save 28% OFF</h5>
                    </div>
                  </label>
                </div>
                <div className="themeRadioHolder">
                  <input type="radio" id="monthlyProPlan" name="themeRadio" />
                  <label htmlFor="monthlyProPlan">
                    <div className="monthlyProPlan radioContent">
                      <p>
                        <strong>Monthly Pro Plan</strong>
                      </p>
                      <p>
                        <strong>$7/per month</strong>
                      </p>
                    </div>
                  </label>
                </div>
              </div>
              <div className="addPaymentMethod">
                <h3>Add Payment Method</h3>
                <div className="inputHolder creditCardDetails">
                  <label htmlFor="CARD NUMBER">CARD NUMBER</label>
                  <div className="inputWrap">
                    <input
                      type="text"
                      placeholder="Credit Card"
                      aria-label="credit Card Details"
                    />
                    <div className="rightSidePlaceholder">
                      <span>MM/YY CVC</span>
                    </div>
                  </div>
                </div>
                <div className="inputHolder promoCode">
                  <label htmlFor="PROMO CODE">PROMO CODE</label>
                  <div className="inputWrap">
                    <input type="text" aria-label="promo code" />
                    <button className="applyBtn btnStyle1">Apply</button>
                  </div>
                </div>
              </div>
              <div className="totalCost">
                <h3>Total Cost</h3>
                <div className="planDetailWrap">
                  <div className="planName">1 Annual Pro Plan</div>
                  <div className="planPricing">
                    <strong>$60</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="btnSec">
              <button id="cancel_create" className="noStyle">
                Cancel
              </button>
              <button className="btnStyle1">Upgrade &amp; Pay</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettingWrapper;
