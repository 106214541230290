import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { MenuOutlined } from '@ant-design/icons';

interface CRowDragTable extends TableProps<any> {
  onColumnOrderChange?: (columns: any) => void;
  onDataSourceChange?: (fromId: string, toId: string) => Promise<void>;
  columns: ColumnsType<any>;
  dataSource: any;
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 1 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
const RowDragTable = (props: CRowDragTable) => {
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (props?.dataSource?.length === 0) return;
    if (active.id !== over?.id) {
      let fromTaskId: any = active.id ? active.id : '';
      let toTaskId: any = over?.id ? over.id : '';

      props?.onDataSourceChange?.(`${fromTaskId}`, `${toTaskId}`);
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={
          props?.dataSource
            ?.filter((item: any) => item !== undefined)
            ?.map((i: any) => i.key) ?? []
        }
        strategy={verticalListSortingStrategy}
      >
        <Table
          {...props}
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey="key"
        />
      </SortableContext>
    </DndContext>
  );
};

export default RowDragTable;
