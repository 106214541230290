import {
  IAddTaggedMemberThread,
  IGetFileBlob,
  IGetMessagesApiResponse,
  IMessageCountPayload,
  IMessageTogglePin,
  IMessagesApiResponse,
} from 'models/interface';
import { RoutesEnum } from '../../models/enums/apiRoutes';
import ApiService from '../Api.service';

class MessagingService {
  public getMessagesByUser = async () => {
    return ApiService.get<IGetMessagesApiResponse>(
      RoutesEnum.GET_MESSAGES_BY_USER,
    );
  };

  public saveReadMessageCountByUser = async (payload: IMessageCountPayload) => {
    return ApiService.post<IMessagesApiResponse>(
      RoutesEnum.SAVE_MESSAGE_COUNT_BY_USER,
      payload,
    );
  };

  public pinnedOrUnpinnedMessages = async (payload: IMessageTogglePin) => {
    return ApiService.post<IMessagesApiResponse>(
      RoutesEnum.CHANGE_PINNED_STATUS,
      payload,
    );
  };

  public getFileBlob = async (payload: IGetFileBlob) => {
    return ApiService.post<IMessagesApiResponse>(
      RoutesEnum.GET_FILE_BLOB,
      payload,
      { responseType: 'arraybuffer' },
    );
  };

  public addTaggedMemberThread = async (payload: IAddTaggedMemberThread) => {
    return ApiService.post<IMessagesApiResponse>(
      RoutesEnum.ADD_TAGGED_MEMBERS_THREAD,
      payload,
    );
  };
}

const messagingService = new MessagingService();

export default messagingService;
