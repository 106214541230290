import { ChangeEvent, FC, useState } from 'react';
import {
  IGlobalState,
  ILoaderState,
  IProfileSettingCmsData,
} from '../../../models/interface';
import { useGlobalStore, useLoaderStore, useUserStore } from '../../../stores';
import { userService } from '../../../services';
import BrowserStorageService from '../../../services/browserStorage.service';
import { useParams } from 'react-router-dom';

const LanguageAndRegion: FC<{
  isActive: boolean;
  lblCms: IProfileSettingCmsData | null;
}> = ({ isActive, lblCms }) => {
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { localization } = useGlobalStore((state: IGlobalState) => state);
  const { userDetails, setUserDetails } = useUserStore();
  const { org } = useParams();
  const browserStorage = BrowserStorageService.getInstance();
  const [formValue, setFormValue] = useState(
    userDetails?.additional_settings?.language ?? 'en',
  );
  const handleChangeLanguageValue = async (
    evt: ChangeEvent<HTMLSelectElement>,
  ) => {
    setLoaderState('active');
    await userService.updateUserProfile({
      full_name: userDetails?.full_name!,
      additional_information: {
        ...userDetails?.additional_settings,
        language: evt.target.value,
      },
    });
    const tempUserDetail = userDetails!; // NOSONAR
    tempUserDetail.additional_settings = {
      ...userDetails?.additional_settings,
      language: evt.target.value,
    };

    setUserDetails(tempUserDetail);
    browserStorage.setUserDetails({
      ...userDetails,
      additional_settings: {
        ...userDetails?.additional_settings,
        language: evt.target.value,
      },
    });
    setFormValue(evt.target.value);
    browserStorage.clearGlobalData();

    window.location.replace(`/org/${org}/users/settings?section=language`);
  };

  return (
    <div
      id="thirdTab"
      className="tabContent"
      style={{ display: isActive ? 'block' : 'none' }}
    >
      <div className="languagePart">
        <h4>{lblCms?.lbl_language}</h4>
        <p>{lblCms?.lbl_langauge_description}</p>
        <div className="language-dropdown">
          <select name="language-dropdown" onChange={handleChangeLanguageValue}>
            {localization?.languages!.map((e) => (
              <option
                selected={formValue === e.value}
                key={e.value}
                value={e.value}
              >
                {e.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/*disable for now will be used in future */}
      {/*<div className="time-zone">*/}
      {/*    <h4>Time Zone</h4>*/}
      {/*    <p>Cleversort uses your time zone to send app notifications and email*/}
      {/*        notifications.</p>*/}
      {/*    <div className="checkboxHolder timeZoneCheck">*/}
      {/*        <input type="checkbox" id="timeZone" aria-label="time zone check"/>*/}
      {/*        <label htmlFor="timeZone">Set time zone automatically</label>*/}
      {/*    </div>*/}
      {/*    <div className="timezone-dropdown">*/}
      {/*        <select name="timezone-dropdown">*/}
      {/*            <option value="UTC Pacific Time (US and Canada)" selected>UTC*/}
      {/*                Pacific*/}
      {/*                Time (US and*/}
      {/*                Canada)*/}
      {/*            </option>*/}
      {/*            <option value="UTC Pacific Time (US and Canada)">UTC Pacific Time*/}
      {/*                (US*/}
      {/*                and Canada)*/}
      {/*            </option>*/}
      {/*            <option value="UTC Pacific Time (US and Canada)">UTC Pacific Time*/}
      {/*                (US*/}
      {/*                and Canada)*/}
      {/*            </option>*/}
      {/*        </select>*/}
      {/*    </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default LanguageAndRegion;
