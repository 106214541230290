export enum NotificationEventType {
  ADD_NEW_SECTION = "add_new_section",
  UPDATE_SECTION_NAME = "update_section_name",
  CLONE_SECTION = "clone_section",
  CHANGE_SECTION_PRIVACY = "change_section_privacy",
  REORDER_SECTION = "reorder_section",
  DELETE_SECTION = "delete_section",
  ADD_SIMPLE_TASK_ROW = "add_simple_task_row",
  UPDATE_SIMPLE_TASK_ROW = "update_simple_task_row",
  DELETE_SIMPLE_TASK_ROW = "delete_simple_task_row",
}

export enum SectionType {
  SIMPLE_TASK_LIST = "task_list",
  GRID_LIST = "grid_list",
  MEMOPAD = "memo_pad",
}

export enum SectionTypeForViewPort {
  SIMPLE_TASK_LIST = "simple-task-list",
  GRID_LIST = "grid-list",
  MEMOPAD = "memo-pad",
}
