import { FC, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { Dropdown, message } from "antd";
import {
  IGridList,
  IGridListBlockDetail,
  IGridListTaskDetail,
  IModalState,
  IProjectState,
} from "models/interface";
import { useProjectDashboardStore, useUserStore } from "stores";
import { useModalStore } from "stores/modal.store";
import { loggerService, projectService } from "services";
import { getCurrentOrganization, groupGridBlock } from "utils/commonFunctions";
import { markTaskDeleteInMessageThread } from "utils/firebaseCommonFunctions";
import { SectionTypeMessages } from "models/enums/messages";

const TaskDelete: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  taskDetails: IGridListTaskDetail;
}> = ({ gridListDetails, setGridListDetails, taskDetails }) => {
  const { gridListCmsData, projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state
  );
  const { modalState, setModalState } = useModalStore(
    (state: IModalState) => state
  );
  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split("/")[2];

  const deleteTaskHandler = () => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          deleteItemDetails: {
            type: "task",
            id: taskDetails.task_id,
          },
        },
      } as IGridList);

      setModalState({
        modalHeader: (
          <Fragment>
            <h3>{gridListCmsData?.lbl_delete_task_modal_header}</h3>
            <span
              className="material-icons-outlined modal-close"
              onClick={() => modalCloseHandler()}
              onKeyDown={() => {}}
            >
              close
            </span>
          </Fragment>
        ),
        modalBody: (
          <Fragment>
            <div className="icon"></div>
            <p>
              {gridListCmsData?.lbl_delete_task_modal_content1}{" "}
              <strong>{taskDetails.task_name}</strong>?
            </p>
            <p>{gridListCmsData?.lbl_delete_task_modal_content2}</p>
          </Fragment>
        ),
        modalFooter: (
          <Fragment>
            <button
              className="noStyle cancel_deleteFolder"
              onClick={() => modalCloseHandler()}
            >
              {gridListCmsData?.cta_delete_modal_cancel}
            </button>

            <button
              className="deleteBtnStyle"
              onClick={() =>
                (async () => {
                  await confirmAction();
                })()
              }
            >
              {gridListCmsData?.cta_delete_task_modal_confirm}
            </button>
          </Fragment>
        ),
        shouldShow: true,
      });
    }
  };

  const modalCloseHandler = () => {
    let section = {
      ...gridListDetails,
    };
    delete section.grid_list_details!.deleteItemDetails;

    setGridListDetails(section);

    setModalState({ ...modalState, shouldShow: false });
  };

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const confirmAction = async (): Promise<void> => {
    try {
      let isLastItem: boolean = false;

      if (gridListDetails.grid_list_details?.task_details!.length === 1) {
        isLastItem = true;
      }

      const response = await projectService.deleteGridTask({
        organizationId: +getCurrentOrganization(
          org_key,
          userDetails.organization_info
        )?.organization_id!,
        userId: userDetails.user_id,
        projectId: projectDetails?.project_id!,
        sectionId: +gridListDetails.section_id,
        taskId: taskDetails.task_id,
        isLastItem,
      });

      if (response.messageId === 1) {
        let blockDetails =
          gridListDetails.grid_list_details?.block_details!.flat();
        let newTaskDetails = gridListDetails.grid_list_details?.task_details!;
        let updatedBlockList: IGridListBlockDetail[] = [];

        // ALL BLOCKS WILL BE DELETE BY TASK (CS23-6820)
        updatedBlockList = blockDetails!.filter(
          (ele: IGridListBlockDetail) => ele.task_id !== taskDetails.task_id
        )!;

        // ALL BLOCKS WILL BE DELETE BY TASK (CS23-6820)
        // if (isLastItem) {
        //   updatedBlockList = blockDetails!.map((ele: IGridListBlockDetail) => {
        //     return { ...ele, task_id: 0 };
        //   })!;

        //   if (
        //     updatedBlockList.length === 1 &&
        //     updatedBlockList[0].stage_id === 0 &&
        //     updatedBlockList[0].task_id === 0
        //   ) {
        //     updatedBlockList = [];
        //   }
        // } else {
        //   updatedBlockList = blockDetails!.filter(
        //     (ele: IGridListBlockDetail) => ele.task_id !== taskDetails.task_id
        //   )!;
        // }

        let updatedTaskList = newTaskDetails
          .filter(
            (ele: IGridListTaskDetail) => ele.task_id !== taskDetails.task_id
          )
          .filter((ele: IGridListTaskDetail) => !ele.isNew)!;

        let targetBlockList = blockDetails!.filter(
          (ele: IGridListBlockDetail) => ele.task_id === taskDetails.task_id
        )!;

        let newTaskList = updatedTaskList.length > 0 ? updatedTaskList : null;

        delete gridListDetails.grid_list_details!.deleteItemDetails;

        setGridListDetails({
          ...gridListDetails,
          grid_list_details: {
            ...gridListDetails.grid_list_details,
            task_details: newTaskList,
            block_details:
              updatedBlockList.length > 0
                ? (groupGridBlock(updatedBlockList, newTaskList) as Array<
                    IGridListBlockDetail[]
                  >)
                : null,
          },
        } as IGridList);

        modalClose();

        await markTaskDeleteInMessageThread(
          `${SectionTypeMessages.GRID_LIST_TASK}-${
            process.env.REACT_APP_STAGE
          }-${getCurrentOrganization(org_key, userDetails?.organization_info)
            ?.organization_id!}-${
            projectDetails?.project_id
          }-${+gridListDetails.section_id}-${taskDetails.task_id}`
        );

        for (let block of targetBlockList) {
          await markTaskDeleteInMessageThread(
            `${SectionTypeMessages.GRID_LIST_BLOCK}-${
              process.env.REACT_APP_STAGE
            }-${getCurrentOrganization(org_key, userDetails?.organization_info)
              ?.organization_id!}-${
              projectDetails?.project_id
            }-${+gridListDetails.section_id}-${block.block_id}`
          );
        }
      } else {
        modalClose();
        message.error(gridListCmsData?.lbl_generic_error_message, 3);
      }
    } catch (err) {
      message.error(gridListCmsData?.lbl_generic_error_message, 3);
      await loggerService.log({
        severity: "High",
        message: "task delete failed",
        payload: "DB Error",
      });
      modalClose();
    }
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "delete",
            label: (
              <div className="customDropDownItem">
                <span className="deleteBin cmnIcon"></span>
                {gridListCmsData?.lbl_delete_task_modal_header}
              </div>
            ),
          },
        ],
        onClick: () => {
          deleteTaskHandler();
        },
      }}
      placement="bottomLeft"
      trigger={["click"]}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="blkMore"
      >
        More
      </div>
    </Dropdown>
  );
};

export default TaskDelete;
