import { useLocation } from 'react-router-dom';
import {
  IEmptyProjectDashboardAttributes,
  IProjectState,
} from '../../../../../models/interface';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { useAnalytics } from 'components/sharedComponents';
import { getCurrentOrganization } from 'utils/commonFunctions';
import OutsideClickHandler from 'components/sharedComponents/OutsideClickHandler';

const NewSectionCta: React.FC<{
  pageSpecificationData: IEmptyProjectDashboardAttributes;
  getNewEmptySection: Function;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  setSidePanelMode: React.Dispatch<React.SetStateAction<string>>;
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSettingDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  setShowProjectMemberDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  refDiv?: React.RefObject<HTMLDivElement>;
  setCurrentId?: (val: string) => void;
  setIsSectionEditing?: () => void;
}> = ({
  pageSpecificationData,
  getNewEmptySection,
  showDropdown,
  setShowDropdown,
  setShowSettingDropDown,
  setShowProjectMemberDropdown,
  refDiv,
  setCurrentId,
  setIsSectionEditing,
}) => {
  const gaContext = useAnalytics();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const projectDetails = useProjectDashboardStore(
    (state: IProjectState) => state.projectDetails,
  );
  const setProjectDetails = useProjectDashboardStore(
    (state: IProjectState) => state.setProjectDetails,
  );

  const { userDetails } = useUserStore((state: any) => state);

  const dropdownClickHandler = () => {
    setShowDropdown((prev) => !prev);
    setShowSettingDropDown(false);
    setShowProjectMemberDropdown(false);
  };

  const newSectionCtaClickHandler = (selectedOption: string) => {
    let updatedSection;
    switch (selectedOption) {
      case 'task_list':
        if (
          !projectDetails?.sections ||
          projectDetails?.sections?.length === 0
        ) {
          const newSection = getNewEmptySection();
          setProjectDetails({
            ...projectDetails,
            sections: [{ ...newSection }],
          });
          setCurrentId?.(newSection.section_id);
        } else {
          updatedSection = [...projectDetails.sections].map((ele) => {
            return { ...ele, section_rank: ele.section_rank + 1 };
          });
          const newSection = getNewEmptySection();
          setProjectDetails({
            ...projectDetails,
            sections: [newSection, ...updatedSection],
          });
          setCurrentId?.(newSection.section_id);
        }

        break;
      case 'grid_list':
        if (
          !projectDetails?.sections ||
          projectDetails?.sections?.length === 0
        ) {
          const newSection = {
            ...getNewEmptySection(),
            section_type_id: 2,
            grid_list_details: {
              task_details: null,
              stage_details: null,
              block_details: null,
            },
          };
          setProjectDetails({
            ...projectDetails,
            sections: [
              {
                ...newSection,
              },
            ],
          });
          setCurrentId?.(newSection.section_id);
        } else {
          updatedSection = [...projectDetails.sections].map((ele) => {
            return { ...ele, section_rank: ele.section_rank + 1 };
          });
          const newSection = {
            ...getNewEmptySection(),
            section_type_id: 2,
            grid_list_details: {
              task_details: null,
              stage_details: null,
              block_details: null,
            },
          };
          setCurrentId?.(newSection.section_id);
          setProjectDetails({
            ...projectDetails,
            sections: [
              {
                ...newSection,
              },
              ...updatedSection,
            ],
          });
        }
        break;
      case 'memo_pad':
        if (
          !projectDetails?.sections ||
          projectDetails?.sections?.length === 0
        ) {
          const newSection = getNewEmptySection();
          setProjectDetails({
            ...projectDetails,
            sections: [{ ...newSection, section_type_id: 3 }],
          });
          setCurrentId?.(newSection.section_id);
        } else {
          const newSection = getNewEmptySection();
          updatedSection = [...projectDetails.sections].map((ele) => {
            return { ...ele, section_rank: ele.section_rank + 1 };
          });
          setProjectDetails({
            ...projectDetails,
            sections: [
              { ...newSection, section_type_id: 3 },
              ...updatedSection,
            ],
          });
          setCurrentId?.(newSection.section_id);
        }
        break;
      // message.info("This feature is under development")
    }
    sendAnalyticsEvent(selectedOption);
    setShowDropdown(false);
    refDiv?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
    setIsSectionEditing?.();
  };

  const sendAnalyticsEvent = (selectedOption: string) => {
    let eventName = '';

    switch (selectedOption) {
      case 'task_list':
        eventName = 'simple task list section creation started';
        break;
      case 'grid_list':
        eventName = '2d grid list section creation started';
        break;
      case 'memo_pad':
        eventName = 'memo pad section creation started';
        break;
    }

    gaContext.updateProps({
      username: userDetails.full_name,
      email: userDetails.email,
      organization: getCurrentOrganization(
        org_key,
        userDetails.organization_info,
      )
        ? getCurrentOrganization(org_key, userDetails.organization_info)
            ?.organization_name
        : '',
      eventName: eventName,
      eventCategory: 'Projects',
      eventLabel: 'Create Section',
    });

    return true;
  };

  const determineClass = () => {
    if (projectDetails?.is_archived === true) {
      return 'disabledOption';
    } else {
      return '';
    }
  };

  if (projectDetails?.is_archived === false) {
    return (
      <>
        <button
          className="hdrDropdownToggle btnStyle1"
          type="button"
          data-toggle="dropdown"
          onClick={dropdownClickHandler}
        >
          {pageSpecificationData?.btn_new_section}
          <span className="material-icons-outlined">expand_more</span>
        </button>
        {showDropdown ? (
          <OutsideClickHandler
            onOutsideClick={() => {
              dropdownClickHandler();
            }}
          >
            <ul
              className={`genericPopup newSectionMenu ${
                showDropdown ? 'show' : ''
              } ${determineClass()}`}
            >
              {pageSpecificationData.btn_new_section_dropdown_options?.options.map(
                (eachOption) => (
                  <li
                    key={eachOption.label}
                    onClick={() => {
                      if (projectDetails?.is_archived === false) {
                        newSectionCtaClickHandler(eachOption.value);
                      } else {
                        setShowDropdown(false);
                      }
                    }}
                    onKeyDown={() => {}}
                  >
                    <span className={eachOption.className}></span>{' '}
                    {eachOption.label}
                  </li>
                ),
              )}
            </ul>
          </OutsideClickHandler>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default NewSectionCta;
