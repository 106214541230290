import { RightOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, Popover } from 'antd';
import React, { useState } from 'react';
import ColumnEditForm from './ColumnSettings';

interface IAction {
  customMeta: any;
  updateCustomMeta: (customMeta: any) => void;
  onDelete?: (customMeta: any) => void;
  onDeleteOptions?: (deletedOptions: any) => void;
  onClose?: () => void;
  onChange?: (changedValue: any, allValues: any) => void;
}

const ActionDropdown: React.FC<IAction> = ({
  customMeta,
  updateCustomMeta,
  onDelete,
  onChange,
  ...restProps
}) => {
  const props = {
    customMeta,
    onDelete,
  };
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleOptionClick = (e: any) => {
    setSelectedOption(e.key);
  };

  const renderComponent = () => {
    switch (selectedOption) {
      case 'settings':
        return (
          <ColumnEditForm
            {...props}
            {...restProps}
            updateCustomMeta={(customMeta) => {
              updateCustomMeta(customMeta);
              setSelectedOption('');
            }}
            onChange={(changedValue: any, allValues: any) => {
              onChange?.(changedValue, allValues);
            }}
          />
        );
      case 'delete':
        return <>Delete</>;
      default:
        return null;
    }
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {!selectedOption ? (
        <Dropdown
          menu={{
            items: [
              {
                key: 'settings',
                label: (
                  <div className="antdSettingsDropdown">
                    <SettingOutlined /> <span>Settings</span>
                    <span className="ddArrow">
                      <RightOutlined />
                    </span>
                  </div>
                ),
              },
            ],
            onClick: handleOptionClick,
          }}
          placement="bottomLeft"
          trigger={['click']}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="blkMore"
          >
            More
          </div>
        </Dropdown>
      ) : (
        <Popover
          open
          trigger="click"
          onOpenChange={(e) => {
            if (!e) {
              onChange && onChange({ width: customMeta.width }, null);
              setSelectedOption('');
            }
          }}
          content={renderComponent()}
        ></Popover>
      )}
    </div>
  );
};

export default ActionDropdown;
