import { Tooltip } from "antd";
import { IGridList, ITaskList } from "models/interface";

const TaskPriority: React.FC<{
  eachTaskDetail?: ITaskList | IGridList;
  taskPriorityChangeHandler: (statusId: number) => void;
}> = ({ eachTaskDetail, taskPriorityChangeHandler }) => {
  return (
    // <td className="priorityWrap">
    //   <div className="content">
    <Tooltip title="To do" placement="bottom">
      <span
        className={`priorityWrap cmnIcon high-priority priorityIcon`}
        // onClick={() =>
        //   hasPermissions([ERbacPermissions.PROJECT_SECTION_TASK_EDIT], projectDetails?.associated_role_id) && taskPriorityChangeHandler(eachTaskDetail?.task_priority_id ?? 2)
        // }
      />
    </Tooltip>
    //   </div>
    // </td>
  );
};

export default TaskPriority;
