import { FC } from 'react';
import {
  ICreateStageResponse,
  IGridList,
  IGridListBlockDetail,
  IGridListStageDetail,
  IGridListWrapper,
  IProjectState,
  IUpdateStageResponse,
  IUpdateStageResponseData,
} from 'models/interface';

import { useLocation } from 'react-router-dom';
import StageList from './StageList';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { projectService } from 'services';
import { getCurrentOrganization } from 'utils/commonFunctions';
import { eventTypes, newStageId, newStageRank } from 'models/enums/constants';
import {
  renameTaskInMessageThread,
  renameBlockInMessageThread,
} from 'utils/firebaseCommonFunctions';
import { SectionTypeMessages } from 'models/enums/messages';
import { checkStageList } from './AddStageCTA';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';

const StageListWrapper: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
}> = ({ gridListDetails, setGridListDetails }) => {
  const { projectDetails, gridListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { fetchNewPermission } = useFetchNewPermission();

  const submitChange = async (stageName: string, eventType: string) => {
    if (projectDetails?.project_id) {
      if (stageName.trim() === '') {
        removeEmptyStage();
      } else {
        try {
          const response: ICreateStageResponse =
            await projectService.createNewGridStage({
              userId: userDetails?.user_id,
              projectId: projectDetails?.project_id,
              organizationId: getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!,
              sectionId: gridListDetails.section_id,
              stageName: stageName.trim(),
            });

          if (response.messageId === 1) {
            updateProjectState(response.data, eventType);
          } else if (response.messageId === -4) {
            fetchNewPermission(
              org_key,
              gridListCmsData?.lbl_error_message_permission_denied,
            );
          } else {
            handleFailedOperation();
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const updateStage = async (stageName: string, stageId: number) => {
    if (projectDetails?.project_id) {
      try {
        const response: IUpdateStageResponse =
          await projectService.updateGridStage({
            organizationId: getCurrentOrganization(
              org_key,
              userDetails?.organization_info,
            )?.organization_id!,
            userId: userDetails?.user_id,
            projectId: projectDetails?.project_id,
            sectionId: gridListDetails.section_id,
            stageId: stageId,
            stageName: stageName.trim(),
          });

        if (response.messageId === 1) {
          updateProjectStateForStageUpdate(response.data);
          renameTaskInMessageThread(
            `${SectionTypeMessages.GRID_LIST_STAGE}-${
              process.env.REACT_APP_STAGE
            }-${getCurrentOrganization(org_key, userDetails?.organization_info)
              ?.organization_id!}-${projectDetails?.project_id}-${
              gridListDetails.section_id
            }-${stageId}`,
            stageName.trim(),
          );

          let blockDetails =
            gridListDetails.grid_list_details?.block_details!.flat()!;

          let targetBlockList = blockDetails!.filter(
            (ele: IGridListBlockDetail) => ele.stage_id === stageId,
          )!;

          for (let block of targetBlockList) {
            await renameBlockInMessageThread(
              `${SectionTypeMessages.GRID_LIST_BLOCK}-${
                process.env.REACT_APP_STAGE
              }-${getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!}-${
                projectDetails?.project_id
              }-${+gridListDetails.section_id}-${block.block_id}`,
              stageName.trim(),
              'stage',
            );
          }
        } else {
          revertChange(stageId, 'Blur');
        }
      } catch (error) {
        revertChange(stageId, 'Blur');
      }
    }
  };

  const removeEmptyStage = () => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: gridListDetails.grid_list_details?.stage_details
            ? filterOutStage(gridListDetails.grid_list_details?.stage_details)
            : null,
        },
      } as IGridList);
    }
  };

  const updateProjectState = (data: IGridListWrapper, eventType: string) => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: data.grid_list_details.task_details,
          block_details: data.grid_list_details.block_details,
          stage_details:
            eventType === eventTypes.ENTER
              ? [
                  ...data.grid_list_details.stage_details!,
                  {
                    stage_id: newStageId,
                    stage_name: '',
                    stage_rank: newStageRank,
                    section_id: +gridListDetails.section_id,
                    isNew: true,
                    created_by: '',
                  },
                ]
              : data.grid_list_details.stage_details,
        },
      });
    }
  };

  const updateProjectStateForStageUpdate = (data: IUpdateStageResponseData) => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: getUpdateStageDetails(
            gridListDetails,
            data.stage_details,
          )!,
        },
      } as IGridList);
    }
  };

  const handleFailedOperation = () => {
    if (checkStageList(gridListDetails)) {
      removeEmptyStage();
    }
  };

  const revertChange = (stageId: number, event: string) => {
    if (gridListDetails) {
      let target = gridListDetails.grid_list_details!.stage_details?.filter(
        (ele) => ele.stage_id === stageId,
      )!;
      let others = gridListDetails.grid_list_details!.stage_details?.filter(
        (ele) => ele.stage_id !== stageId,
      )!;

      target[0].stage_name = target[0].backupName!;
      if (event === eventTypes.BLUR) delete target[0].backupName;

      let newStageList = [...others, ...target]
        .sort((a, b) => a.stage_rank - b.stage_rank)
        .filter((ele) => ele.stage_id !== newStageId);

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: newStageList.length > 0 ? newStageList : null,
        },
      } as IGridList);
    }
  };

  const getUpdateStageDetails = (
    gridListDetails: IGridList,
    stageDetails: IGridListStageDetail[],
  ) => {
    if (gridListDetails.grid_list_details!.stage_details) {
      let stage = [...stageDetails];
      let existingStage =
        gridListDetails.grid_list_details!.stage_details.filter(
          (ele: IGridListStageDetail) => ele.stage_id !== stage[0].stage_id,
        );
      return [...existingStage, ...stage].sort(
        (a, b) => a.stage_rank - b.stage_rank,
      );
    }
  };

  const filterOutStage = (stageDetails: IGridListStageDetail[]) => {
    let filteredList = stageDetails.filter((ele: any) => !ele.isNew);

    return filteredList.length > 0 ? filteredList : null;
  };

  return (
    <StageList
      gridListDetails={gridListDetails}
      setGridListDetails={setGridListDetails}
      submitChange={async (stageName, eventType) =>
        await submitChange(stageName, eventType)
      }
      updateStage={async (stageName, stageId) =>
        await updateStage(stageName, stageId)
      }
      revertChange={revertChange}
    />
  );
};

export default StageListWrapper;
