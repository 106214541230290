import { arrayMove } from "@dnd-kit/sortable";
import { IGridList, IGridListBlockDetail } from "models/interface";

interface GridService {
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
}

export const checkIsCustomField = (stageId: number | string) => {
  return Boolean(typeof stageId === "string" && stageId.includes("_"));
};

export const mapCFtoCompactView = (details: IGridList) => {
  const newDetails = { ...details };
  const blockDetails = details.grid_list_details?.block_details ?? [];
  const taskDetails = details.grid_list_details?.task_details ?? [];
  const stageDetails = details.grid_list_details?.stage_details ?? [];

  // Remove Stage CustomField
  const newStageDetails = stageDetails.filter((i) => !checkIsCustomField(i.stage_id));

  if (newDetails?.grid_list_details) {
    newDetails.grid_list_details.stage_details = newStageDetails;
    newDetails.grid_list_details.stage_details = newStageDetails;
  }

  return newDetails;
}

const useGridHandler = (props: GridService) => {
  const { gridListDetails, setGridListDetails } = props;
  const details = gridListDetails.grid_list_details;

  const validateReOrderItems = (fromIndex: number, toIndex: number) => {
    const stages = details?.stage_details ?? [];
    const fromStage = stages[fromIndex - 1];
    const toStage = stages[toIndex - 1];
    const isFromCustomField = checkIsCustomField(fromStage.stage_id);
    const isToCustomField = checkIsCustomField(toStage.stage_id);
    const isBothCustomField = isFromCustomField && isToCustomField;
    const isBothNormalStage = !isFromCustomField && !isToCustomField;
    const isInvalidReOrder = isFromCustomField !== isToCustomField;

    return { isBothCustomField, isBothNormalStage, isInvalidReOrder };
  };

  const updateStageDragEnd = (
    fromIndex: number,
    toIndex: number,
    setColumns: React.Dispatch<React.SetStateAction<any>>
  ): { fromId?: number; toId?: number; newCols: any } => {
    const stages = details?.stage_details ?? [];
    const fromStage = stages[fromIndex - 1];
    const toStage = stages[toIndex - 1];
    let newCols: any = [];

    setColumns((prev: any) => {
      const reOrderList = arrayMove(prev, fromIndex, toIndex);
      newCols = reOrderList;
      return reOrderList;
    });
    return { fromId: fromStage.stage_id, toId: toStage.stage_id, newCols };
  };

  const updateTaskDragEnd = (
    fromId: string,
    toId: string,
    setDataSource: React.Dispatch<React.SetStateAction<any>>
  ) => {
    let newDataSources: any = [];
    setDataSource((prev: any) => {
      if (!prev) return [];

      const activeIndex = prev.findIndex((i: any) => i.key == fromId);
      const overIndex = prev.findIndex((i: any) => i.key == toId);
      const reOrderList = arrayMove(prev, activeIndex, overIndex);
      newDataSources = reOrderList;
      return reOrderList;
    });

    return newDataSources;
  };

  const updateCFCell = (
    value: string,
    blockId: string | number,
    taskId: number
  ) => {
    let newDetails = { ...gridListDetails };
    setGridListDetails((prev: IGridList) => {
      if (!prev.custom_meta || !taskId) return prev;
      const currentBlockLevel = prev.grid_list_details?.task_details?.findIndex(
        (i) => i.task_id === taskId
      );

      if (currentBlockLevel === -1 || currentBlockLevel === undefined)
        return prev;

      const currentBlocks: any =
        prev.grid_list_details?.block_details?.[currentBlockLevel];

      if (!currentBlocks) return prev;

      const newBlocks = currentBlocks?.map((i: IGridListBlockDetail) => {
        if (i.block_id === blockId) {
          return { ...i, custom_field_block_value: value };
        }
        return i;
      });

      if (prev.grid_list_details && prev.grid_list_details.block_details) {
        prev.grid_list_details.block_details[currentBlockLevel] = newBlocks;
      }

      newDetails = { ...prev };
      return newDetails;
    });

    return newDetails;
  };

  const getCFBlockDataByTask = (taskId: number) => {
    return (
      details?.block_details
        ?.flat()
        ?.filter((i) => i.task_id === taskId)
        ?.map((i) => {
          return {
            mapping_id: i.stage_id,
            value: i.custom_field_block_value,
          };
        })
        ?.filter((i) => i.value) ?? []
    );
  };

  return {
    updateStageDragEnd,
    updateTaskDragEnd,
    validateReOrderItems,
    updateCFCell,
    getCFBlockDataByTask,
  };
};

export default useGridHandler;
