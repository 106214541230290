import { useProjectDashboardStore } from "../../../stores";
import { IProjectState, DoneTaskList } from "../../../models/interface";
import { useState } from "react";

const CustomDoneStatus = ({
  onChange,
  value,
}: {
  onChange: (evt: number) => void;
  value: number | undefined;
}) => {
  const { customizeSectionUpdateCmsData: customizeCmsData } =
    useProjectDashboardStore((state: IProjectState) => state);
  const [doneValue, setDoneValue] = useState(value);
  const handleValueChange = (val: number) => {
    if (val !== doneValue) {
      setDoneValue(val);
      onChange(val);
    }
  };
  return (
    <form>
      <div className="statusUpdateVariationRadioWrapper">
        {customizeCmsData?.done_task_list.map((e: DoneTaskList) => (
          <div className="radioWrapper" key={e.status_done_view_id}>
            <input
              type="radio"
              value={e.status_done_view_id}
              checked={doneValue === e.status_done_view_id}
              name="statusUpdateVariationRadio"
              id={e.status_name}
              onChange={() => handleValueChange(e.status_done_view_id)}
            />
            <label
              htmlFor={e.status_name}
              onClick={() => handleValueChange(e.status_done_view_id)}
              onKeyDown={() => {}}
            >
              <div className="labelWrap">
                <p>{e.status_name}</p>
                <div className={e.classname}>
                    <span>{customizeCmsData?.lbl_task_name}</span>
                </div>
              </div>
            </label>
          </div>
        ))}
      </div>
    </form>
  );
};

export default CustomDoneStatus;
