import { useEffect } from "react";
import { useProjectDashboardStore, useTaskStatusStore } from "stores";
import { IProjectState, IRequestCms, IResponseCms, ITaskStatus } from "models/interface";
import { apiService, loggerService } from "services";
import BrowserStorageService from "services/browserStorage.service";

export const useUpdateMessagingCMS = (isMounted: boolean) => {
  const cmsPageName = ["messagings", "task-statuses", "task-lists"];
  const { messagingCmsData, setMessagingCmsData, taskListCmsData, setTaskListCmsData } = useProjectDashboardStore((state: IProjectState) => state),
  { taskStatusCmsData, setTaskStatusCmsData } = useTaskStatusStore((state:ITaskStatus)=>state)

  // get and update state of messaging cms data
  const updateMessagingCmsData = async () => {
    try {
      const browserStorage = BrowserStorageService.getInstance(),
        localizationData = browserStorage.getLocalization(),
        payload: IRequestCms = {
          languageCode: localizationData?.language ?? "en",
          countryCode: localizationData?.country ?? "IN",
          page: cmsPageName,
          showGlobalData: false,
        },
        cmsDataResp: any = await apiService.post<IResponseCms>(
          "/cms/getCMSData",
          payload
        );

      if (cmsDataResp.data?.pageSpecificData) {
        const cmsMessagingData = cmsDataResp.data?.pageSpecificData[0];
        const cmsTaskStatusData = cmsDataResp.data?.pageSpecificData[1];
        const cmsTaskListData = cmsDataResp.data?.pageSpecificData[2];
        cmsMessagingData && setMessagingCmsData(cmsMessagingData[0]?.attributes);
        cmsTaskStatusData && setTaskStatusCmsData(cmsTaskStatusData[0]?.attributes);
        cmsTaskListData && setTaskListCmsData(cmsTaskListData[0]?.attributes);
      } else {
        await loggerService.log({
          severity: "High",
          message: `Fetching cms data failed (For ${cmsPageName[0]})`,
          payload: {
            ApiResponse: cmsDataResp.message,
          },
        });
      }
    } catch (error) {
      await loggerService.log({
        severity: "High",
        message: `/cms/getCMSData api endpoint failure (For ${cmsPageName[0]})`,
        payload: error,
      });
    }
  }

  useEffect(() => {
    if ((!messagingCmsData||!taskStatusCmsData ||!taskListCmsData) && isMounted) {
      updateMessagingCmsData();
    }
  }, [isMounted]);
}
