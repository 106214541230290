import { FC, useEffect, useState } from "react";
import { ITaskPrioritiesData } from "models/interface";
import noMyTaskImage from "../../../../../assets/images/no-task-my.svg";
import EachTask from "./EachTask";

enum SortOrder {
  ASC = "asc",
  DESC = "desc",
}

enum SortColumn {
  TASK_STATUS = "task_status_id",
  TASK_PRIORITY = "task_priority_id",
  TASK_DUE_DATE = "due_date",
  TASK_NAME = "task_name",
  TASK_ASSIGNER = "assigner_name",
  PROJECT_NAME = "project_name",
  TASK_ASSIGNEE = "assignee_name",
}

const MyTaskListTable: FC<{
  id: "due" | "overdue" | "all";
  filter: "today" | "overdue" | "all";
  data: any;
  pageCmsData: any;
  //   socket?: any;
  assignedByMe?: boolean;
}> = ({ id, filter, data, pageCmsData, /*socket,*/ assignedByMe = true }) => {
  const block = (id == "due" && filter == "today") || id === filter;
  const [filterData, setFilterData] = useState<any>(data);
  const [sortOption, setSortOption] = useState({
    sortColumn: SortColumn.TASK_DUE_DATE,
    sortOrder: SortOrder.ASC,
  });

  useEffect(() => {
    if (filter !== "today") {
      setSortOption({
        sortColumn: SortColumn.TASK_DUE_DATE,
        sortOrder: SortOrder.ASC,
      });
      sortTasks(SortColumn.TASK_DUE_DATE);
      return;
    }
    setSortOption({
      sortColumn: SortColumn.TASK_STATUS,
      sortOrder: SortOrder.ASC,
    });
    sortTasks(SortColumn.TASK_STATUS);
  }, [data, filterData]);

  const getSortIconClass = (sortColumn: SortColumn, sortOrder: SortOrder) => {
    if (sortOption.sortColumn === sortColumn) {
      if (sortOption.sortOrder === SortOrder.DESC) {
        return "sort-arrow-up";
      } else {
        return "sort-arrow-down";
      }
    } else {
      return "double-sort-arrow";
    }
  };

  const sortTasks = (sortColumn: SortColumn) => {
    setSortOption((prev) => ({
      sortColumn: sortColumn,
      sortOrder:
        prev.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
    }));
    const task = data;
    task?.sort((a: any, b: any) => {
      // equal items sort equally
      if (
        a[sortColumn]?.toString()?.toLowerCase() ===
        b[sortColumn]?.toString()?.toLowerCase()
      ) {
        return 0;
      }

      // nulls sort after anything else
      if (a[sortColumn] === null) {
        return 1;
      }
      if (b[sortColumn] === null) {
        return -1;
      }

      // otherwise, if we're ascending, lowest sorts first
      if (sortOption.sortOrder === SortOrder.ASC) {
        return a[sortColumn]?.toString()?.toLowerCase() <
          b[sortColumn]?.toString()?.toLowerCase()
          ? -1
          : 1;
      }

      // if descending, highest sorts first
      return a[sortColumn]?.toString()?.toLowerCase() <
        b[sortColumn]?.toString()?.toLowerCase()
        ? 1
        : -1;
    });

    setFilterData(task);
  };

  const assignByOrAssignTo = () => {
    if (assignedByMe) {
      return (
        <th onClick={() => sortTasks(SortColumn.TASK_ASSIGNEE)}>
          <div className="th-div">
            {pageCmsData?.lbl_assigned_to}
            <span
              className={`cmnIcon ${getSortIconClass(
                SortColumn.TASK_ASSIGNEE,
                SortOrder.DESC
              )}`}
            ></span>
          </div>
        </th>
      );
    }
    return (
      <th onClick={() => sortTasks(SortColumn.TASK_ASSIGNER)}>
        <div className="th-div">
          {pageCmsData?.lbl_assigned_by}
          <span
            className={`cmnIcon ${getSortIconClass(
              SortColumn.TASK_ASSIGNER,
              SortOrder.DESC
            )}`}
          ></span>
        </div>
      </th>
    );
  };

  const dueDate = () => {
    return filter === "today" ? (
      <th>
        <div className="th-div">
          {pageCmsData?.lbl_due_date} <span></span>
        </div>
      </th>
    ) : (
      <th>
        <div
          className="th-div"
          onClick={() => sortTasks(SortColumn.TASK_DUE_DATE)}
          onKeyDown={() => {}}
        >
          {pageCmsData?.lbl_due_date}{" "}
          <span
            className={`cmnIcon ${getSortIconClass(
              SortColumn.TASK_DUE_DATE,
              SortOrder.DESC
            )}`}
          ></span>
        </div>
      </th>
    );
  };

  const statusHeader = () => {
    return filter !== "overdue" ? (
      <th>
        <div
          className="th-div"
          onClick={() => sortTasks(SortColumn.TASK_STATUS)}
          onKeyDown={() => {}}
        >
          {pageCmsData?.lbl_status}{" "}
          <span
            className={`cmnIcon ${getSortIconClass(
              SortColumn.TASK_STATUS,
              SortOrder.DESC
            )}`}
          ></span>
        </div>
      </th>
    ) : (
      <th>
        <div className="th-div">{pageCmsData?.lbl_status} </div>
      </th>
    );
  };

  const renderBody = (data: ITaskPrioritiesData[] | null) => {
    if (!data) {
      return (
        <div className="emptyTaskPriority">
          <img src={noMyTaskImage} alt="task I've assigned" />
        </div>
      );
    }
    const renderHead = () => {
      return (
        <thead>
          <tr>
            {statusHeader()}
            <th>
              <div
                className="th-div"
                onClick={() => sortTasks(SortColumn.TASK_NAME)}
                onKeyDown={() => {}}
              >
                {pageCmsData?.lbl_task}{" "}
                <span
                  className={`cmnIcon ${getSortIconClass(
                    SortColumn.TASK_NAME,
                    SortOrder.DESC
                  )}`}
                ></span>
              </div>
            </th>
            <th>
              <div
                className="th-div"
                onClick={() => sortTasks(SortColumn.PROJECT_NAME)}
                onKeyDown={() => {}}
              >
                {pageCmsData?.lbl_project}
                <span
                  className={`cmnIcon ${getSortIconClass(
                    SortColumn.PROJECT_NAME,
                    SortOrder.DESC
                  )}`}
                ></span>
              </div>
            </th>
            {dueDate()}
            {assignByOrAssignTo()}
          </tr>
        </thead>
      );
    };

    const renderTableBody = () => {
      return filterData?.map((e: any, index: number) => (
        <EachTask
          key={`${e.org_id}-${e.project_id}-${e.section_id}-${e.task_id}`}
          data={e}
          pageCmsData={pageCmsData}
          assignedByMe={assignedByMe}
          index={index}
          setListDetails={setFilterData}
          listDetails={filterData}
          //   socket={socket}
        />
      ));
    };

    return (
      <div className="taskTable ">
        <table>
          {renderHead()}
          <tbody>{renderTableBody()}</tbody>
        </table>
      </div>
    );
  };

  return (
    <div id={id} className="subTabNavigation tabContent">
      <div className="tab-main-box">
        <div
          className="tab-box"
          id="tab-1a"
          style={{ display: block ? "block" : "none" }}
        >
          {renderBody(data)}
        </div>
        <div className="tab-box" id="tab-2a"></div>
      </div>
    </div>
  );
};

export default MyTaskListTable;
