import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import BrowserStorageService from "./browserStorage.service";
import AuthService from "./Auth0/authService";
const authService = AuthService.getInstance();

class ApiService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL as string,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer authtoken`,
      },
    });
  }

  private tokenGenerator() {}

  private async handleError<T>(
    config: AxiosRequestConfig,
    error: AxiosError
  ): Promise<T> {
    throw error;
  }

  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const browserStorage = BrowserStorageService.getInstance();
      const token = await this.getToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        // localization: browserStorage.getLocalization()?.language ?? "en",
        localization: "en",
        // country: browserStorage.getLocalization()?.country ?? "IN",
        country: "IN",
        user: "",
      };

      if (browserStorage.getUserDetails()) {
        headers.user = browserStorage.getUserDetails()?.user;
      }

      if (browserStorage.getLanguage()){
        headers.localization = browserStorage.getLanguage()
      }

      const response: AxiosResponse<T> = await this.axiosInstance.request<T>({
        ...config,
        headers,
      });
      return response.data;
    } catch (error) {
      return this.handleError(config, error as AxiosError);
    }
  }


  public async get<T>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: "get",
      params,
    });
  }

  public async post<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: "post",
      data,
    });
  }

  public async patch<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: "patch",
      data,
    });
  }

  public async put<T>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    return this.request<T>({
      ...config,
      url,
      method: "put",
      data,
    });
  }


  public setTokenGenerator(tokenGenerator: any) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  private getToken() {
    return authService.getAccessToken();
  }
}

const apiService = new ApiService();
export default apiService;
