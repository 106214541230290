import { useAuth0 } from "@auth0/auth0-react";
import { errorTypes } from "models/enums/errorTypes";
import { IErrorPageAttributes, ILoaderState } from "models/interface";
import { useEffect, useState } from "react";
import { cmsService } from "services";
import { useLoaderStore, useLocalizationStore } from "stores";
import { getPageSpecificData } from "utils/commonFunctions";

interface IErrorPageData {
  isFromLogin?: boolean;
  error?: string | null;
  errorDescription?: string | null;
}

const ErrorPage: React.FC<IErrorPageData> = ({
  isFromLogin,
  error,
  errorDescription,
}) => {
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { logout } = useAuth0();
  const cmsPageName = ["generic-error-pages"];
  const pageSlug = ["generic-error-page"];
  const [errorPageCmsData, setErrorPageCmsData] =
    useState<IErrorPageAttributes | null>(null);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);

  const getCmsData = async (pageSlug: string[]) => {
    let tmpPageData: any;
    let errorPageData: any;
    let pageSpecificDataStore = cmsData.pageSpecificData;
    if (pageSpecificDataStore.length > 0) {
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      if (!tmpPageData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        setLoaderState("inactive");
      } else {
        setLoaderState("inactive");
      }
      errorPageData = getPageSpecificData(cmsData, "generic-error-page");
      if (!errorPageData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        errorPageData = getPageSpecificData(cmsData, "generic-error-page");
        errorPageData && setErrorPageCmsData(errorPageData[0].attributes);
      } else {
        errorPageData && setErrorPageCmsData(errorPageData[0].attributes);
      }
    } else {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      errorPageData = getPageSpecificData(cmsData, "generic-error-page");
      errorPageData && setErrorPageCmsData(errorPageData[0].attributes);
      setLoaderState("inactive");
    }
  };

  useEffect(() => {
    if (pageSlug) {
      setLoaderState("active");
      (async () => {
        await getCmsData(pageSlug);
      })();

      setLoaderState("inactive");
    }
  }, []);

  const buttonClickHandler = () => {
    if (isFromLogin) {
      logout({
        logoutParams: { returnTo: window.location.origin },
      });
    }
    sessionStorage.removeItem("lastLocation");
    window.location.href = "/";
  };

  const getSubheadingContent = () => {
    switch (errorDescription) {
      case errorTypes.EMAIL_NOT_VERIFIED:
        return errorPageCmsData?.lbl_email_not_verified_error_desc;
      case errorTypes.UNAUTHORIZED:
        return errorPageCmsData?.lbl_unauthorized_error_desc;
      default:
        return errorPageCmsData?.lbl_sub_heading;
    }
  };

  const getTextDescription = () => {
    switch (errorDescription) {
      case errorTypes.EMAIL_NOT_VERIFIED:
      case errorTypes.UNAUTHORIZED:
        return errorPageCmsData?.lbl_email_not_verified_error_txt_desc;
      default:
        return errorPageCmsData?.txt_description;
    }
  };

  return (
    <div className="errorScreenWrap">
      <h1></h1>
      <h2>{errorPageCmsData?.lbl_main_heading}</h2>
      <h3>{getSubheadingContent()}</h3>
      <span className={errorPageCmsData?.cn_error_illustration}></span>
      <p>{getTextDescription()}</p>
      <button className="btnStyle1" onClick={buttonClickHandler}>
        {isFromLogin ? "Login" : errorPageCmsData?.btn_goto_dashboard}
      </button>
    </div>
  );
};

export default ErrorPage;
