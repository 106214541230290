import { FC } from "react";
import gradientLoader from "../../../assets/images/gradient-loader.svg";
const PageLoader: FC<{ loaderState?: boolean }> = ({ loaderState }) => {
  return (
    // <div className={`gradientLoader ${loaderState}`}>
    //   <img src={gradientLoader} alt="" />
    // </div>
    <div className="mainContentPageLoader">
      <div className={`customLoader ${loaderState}`}></div>
    </div>
  );
};

export default PageLoader;
