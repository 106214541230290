import { useEffect } from "react";
import GA4React from "ga-4-react";
import { useAnalytics } from "../UseAnalytics/useAnalytics";

const MEASSUREMENT_ID:any = process.env.REACT_APP_TRACKING_ID
const ga4react = new GA4React(MEASSUREMENT_ID,{},[],5000);

(async () => {
  await ga4react.initialize();
})();

const AnalyticsEventTracker = () => {
  
  const gaContext = useAnalytics();


  useEffect(() => {

    if(gaContext.eventName !== '') {

      ga4react.gtag('event', gaContext.eventName, {
        'Username': gaContext.username,
        'Email': gaContext.email,
        'Organization Name': gaContext.organization,
        'Category': gaContext.eventCategory,
        'Label': gaContext.eventLabel
      });

      // reset the context
      gaContext.updateProps({
        username: '',
        email: '',
        organization: '',
        eventName: '',
        eventCategory: '',
        eventLabel: ''
      });
    }
  },[gaContext])

  return(
    <></>
  )
}
export default AnalyticsEventTracker;
    