import axios from 'axios';
import apiService from 'services/Api.service';
import {
  IUpdateEpicPayload,
  IDeleteEpicPayload,
  IDeleteUserStoryPayload,
  IUpdateStorySummaryPayload,
  IUpdateStoryTitlePayload,
  IAddOrEditEpicResponse,
  IEpicAndStoryDataType,
  IDeleteEpicResponse,
  IEditUserStoryorUserStoryTitle,
  IUploadedHistoryResponse,
  IDeleteUserStory,
  ICreateStoryResponse,
  IGetUserStoryData,
  IEditAcceptanceCriteriaPayload,
  IEditAcceptanceCriteriaResponse,
  IGACAndTestCasePayload,
  IHistoryRequirement,
  IGetUserStoryDataPayload,
  IGenerateAcceptanceCriteriaResponse,
  IGenTestCaseResponse,
  IUploadFileResponse,
  IEditTestCasePayload,
  IEditTestCaseResponse,
  IDeleteTestCasePayload,
  IDeleteTestCaseResponse,
  IGenSignUrlPayload,
  IGenSignUrlResponse,
  IgetUSGorEpicPage,
  IExportEpic,
} from 'models/interface/userStoryGenerator.interface';
import { RoutesEnum } from 'models/enums/apiRoutes';

const BASE_URL = process.env.REACT_APP_CLEVER_STORY_API_URL;

class UserStoryGeneratorService {
  public GetEpicAndStoriesList = async (
    payload: IGetUserStoryDataPayload,
    pageLimit?: number,
  ) => {
    let url = BASE_URL + RoutesEnum.GET_USERSTORY_DATA;
    let response = await apiService.post<IGetUserStoryData>(
      `${url}?page=${pageLimit}`,
      payload,
    );
    return response;
  };

  public UploadFile = async (payload: FormData) => {
    let url = BASE_URL + RoutesEnum.GEN_USER_STORY;
    let request = await axios.post<IUploadFileResponse>(url, payload);
    return request;
  };

  public CreateEpicName = async (payload: any) => {
    let url = BASE_URL + RoutesEnum.ADD_OR_EDIT_EPICS;
    let response = await apiService.post<IAddOrEditEpicResponse>(url, payload);
    return response;
  };

  public CreateStory = async (payload: any) => {
    let url = BASE_URL + RoutesEnum.ADD_OR_EDIT_STORY;
    let response = await apiService.post<ICreateStoryResponse>(url, payload);
    return response;
  };

  public GetEpicAndStories = async () => {
    let url =
      BASE_URL +
      RoutesEnum.GET_EPIC_USERSTORY +
      `?project_id=${10}` +
      `&organization_id=${5}`;
    return apiService.get<IEpicAndStoryDataType>(url);
  };

  public UpdateEpicName = async (payload: IUpdateEpicPayload) => {
    let url = BASE_URL + RoutesEnum.ADD_OR_EDIT_EPICS;
    return apiService.post<IAddOrEditEpicResponse>(url, payload);
  };

  public UpdateStorySummary = async (payload: IUpdateStorySummaryPayload) => {
    let url = BASE_URL + RoutesEnum.ADD_OR_EDIT_STORY;
    return apiService.post<IEditUserStoryorUserStoryTitle>(url, payload);
  };

  public UpdateStoryTitle = async (payload: IUpdateStoryTitlePayload) => {
    let url = BASE_URL + RoutesEnum.ADD_OR_EDIT_STORY;
    return apiService.post<IEditUserStoryorUserStoryTitle>(url, payload);
  };

  public DeleteEpic = async (payload: IDeleteEpicPayload) => {
    const { project_id, epic_id, organization_id } = payload;
    let url =
      BASE_URL +
      RoutesEnum.DELETE_EPIC +
      `?project_id=${project_id}&epic_id=${epic_id}&organization_id=${organization_id}`;
    let request = await axios.delete<IDeleteEpicResponse>(url);

    return request;
  };

  public DeleteStory = async (payload: IDeleteUserStoryPayload) => {
    const { epic_id, story_id, organization_id } = payload;
    let url =
      BASE_URL +
      RoutesEnum.DELETE_STORY +
      `?story_id=${story_id}&epic_id=${epic_id}&organization_id=${organization_id}`;
    let request = await axios.delete<IDeleteUserStory>(url);
    return request;
  };

  public getAcceptanceCriteria = async (payload: IGACAndTestCasePayload) => {
    let url = BASE_URL + RoutesEnum.GEN_ACCEPTANCE_CRITERIA;
    return await apiService.post<IGenerateAcceptanceCriteriaResponse>(
      url,
      payload,
    );
  };

  public editAcceptanceCriteria = async (
    payload: IEditAcceptanceCriteriaPayload,
  ) => {
    let url = BASE_URL + RoutesEnum.EDIT_ACCEPTANCE_CRITERIA;
    return await apiService.put<IEditAcceptanceCriteriaResponse>(url, payload);
  };

  public getTestCase = async (payload: IGACAndTestCasePayload) => {
    let url = BASE_URL + RoutesEnum.GEN_TEST_CASES;
    return await apiService.post<IGenTestCaseResponse>(url, payload);
  };

  public editTestCase = async (payload: IEditTestCasePayload) => {
    let url = BASE_URL + RoutesEnum.EDIT_TEST_CASE;
    return await apiService.put<IEditTestCaseResponse>(url, payload);
  };

  public deleteTestCase = async (payload: IDeleteTestCasePayload) => {
    let { test_case_id, organization_id } = payload;
    let url =
      BASE_URL +
      RoutesEnum.DELETE_TEST_CASE +
      `?test_case_id=${test_case_id}&organization_id=${organization_id}`;
    let request = await axios.delete<IDeleteTestCaseResponse>(url);
    return request;
  };

  public getUploadedHistory = async (payload: IHistoryRequirement) => {
    const { project_id, organization_id } = payload;
    let url =
      BASE_URL +
      RoutesEnum.HISTORY_OF_REQUIREMENT_FILES +
      `?project_id=${project_id}&organization_id=${organization_id}`;
    return await apiService.get<IUploadedHistoryResponse>(url);
  };

  public generateSignURl = async (payload: IGenSignUrlPayload) => {
    let url = BASE_URL + RoutesEnum.DOWNLOAD_REQUIREMENT_FILE;
    return await apiService.post<IGenSignUrlResponse>(url, payload);
  };

  public checkEpicExist = async (payload: IgetUSGorEpicPage) => {
    let url = BASE_URL + RoutesEnum.NAVIGATION_TO_SINGLE_LINK;
    return await apiService.post(url, payload);
  };

  public exportEpic = async (payload: IExportEpic) => {
    return await apiService.post(RoutesEnum.EXPORT_EPIC, payload);
  };
}

const userStoryGeneratorService = new UserStoryGeneratorService();
export default userStoryGeneratorService;
