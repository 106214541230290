import {FC, useEffect, useState} from "react";
import {IOrganization, IProfileSettingCmsData} from "../../../../models/interface";
import {userService} from "../../../../services";
import {useUserStore} from "../../../../stores";
import {INotificationEvent} from "../../../../models/interface/organization.interface";
import {message} from "antd";


const TeamNotification: FC<{
    lblCms: IProfileSettingCmsData | null,
    organizationInfo: IOrganization | null,
}> = ({lblCms, organizationInfo}) => {
    const {userDetails} = useUserStore()
    const [allNotification, setAllNotification] = useState<INotificationEvent[]>([])
    useEffect(() => {
        (async () => userService.getUserNotificationPreference({
                organization_id: +organizationInfo?.organization_id!,
                user_id: +userDetails?.user_id!
            })
                .then(res => {
                    setAllNotification(res.data)
                })
        )()
    }, [])

    const handleEventChange = async (value: boolean, event_id: number) => {
        userService.updateUserNotificationPreference({
            organization_id: +organizationInfo?.organization_id!,
            user_id: +userDetails?.user_id!,
            event_id,
            is_enable: value
        })
            .then(() => {
                message.info(lblCms?.lbl_notification_updated)
            })
    }


    return <>
        <div className="notificationTab">
            <h4>{lblCms?.lbl_notifications}</h4>
            <p>{lblCms?.lbl_notification_detail_information}</p>
        </div>
        <div className="projectActivityTable">
            <table aria-describedby="projectActivity">
                <thead>
                <tr>
                    <th>{lblCms?.lbl_project_activity}</th>
                    <th>{lblCms?.lbl_email}</th>
                    {/*<th>DESKTOP</th>*/}
                    {/*<th>SLACK</th>*/}
                </tr>
                </thead>
                <tbody>
                {allNotification.map((e) => <tr key={e.event_id}>
                        <td>{lblCms?.notification_list[e.event_id].event_name}</td>
                        <td><input type="checkbox" defaultChecked={e.is_enable}
                                   onChange={(evt) => handleEventChange(evt.target.checked, e.event_id)}/></td>
                        {/*<td><input type="checkbox"*/}
                        {/*           aria-label="project activity"/></td>*/}
                        {/*<td><input type="checkbox"*/}
                        {/*           aria-label="project activity"/></td>*/}
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </>
}

export default TeamNotification
