import { useAuth0 } from "@auth0/auth0-react";
import { RoutesEnum } from "models/enums/apiRoutes";
import {
  IAuthCallbackAttributes,
  IInvitationStatus,
  IUserAuthDetails,
} from "models/interface";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { apiService, cmsService, userService } from "services";
import invitationService from "services/Invitation/invitation.service";
import { useLocalizationStore } from "stores";
import { decryptUsingAES256, getPageSpecificData } from "utils/commonFunctions";

const enum ErrorCodes {
  EXPIRED = -1,
  INVALID = -2,
  MISMATCH = -3,
}

const AuthCallbackPage: React.FC<{
  pageSlug?: string[];
  cmsPageName?: string[];
}> = ({ cmsPageName, pageSlug }) => {
  const { logout } = useAuth0();
  const [pageCmsData, setPageCmsData] =
    useState<IAuthCallbackAttributes | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<ErrorCodes>();
  const [invitationStatusData, setInvitationStatusData] =
    useState<IInvitationStatus | null>(null);
  const [userAuthDetails, setUserAuthDetails] =
    useState<IUserAuthDetails | null>(null);
  const [queryParameters] = useSearchParams();
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);
  const [isLoading, setIsLoading] = useState(true);

  const getCmsData = async () => {
    let tmpPageData: any;
    let pageSpecificData = cmsData.pageSpecificData;
    if (pageSpecificData?.length > 0) {
      tmpPageData = getPageSpecificData(cmsData, pageSlug![0]);
      if (!tmpPageData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        tmpPageData = getPageSpecificData(cmsData, pageSlug![0]);
        tmpPageData && setPageCmsData(tmpPageData[0].attributes);
      } else {
        tmpPageData && setPageCmsData(tmpPageData[0].attributes);
      }
    } else {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      tmpPageData = getPageSpecificData(cmsData, pageSlug![0]);
      tmpPageData && setPageCmsData(tmpPageData[0]?.attributes);
    }
  };

  const handleClick = () => {
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  const getInvitationStatus = async () => {
    const data = decryptUsingAES256(
      queryParameters.get("invitation_identifier")!
    );
    const res = await userService.getInvitationStatus({
      invitation_id: data?.invitation_id!,
    });
    setInvitationStatusData(res.data);
  };

  const getUserAuthDetails = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.location.hash}`,
      localization: "en",
      country: "IN",
      user: "",
    };
    const res: any = await apiService.get(
      RoutesEnum.GET_AUTH_USER_DETAILS,
      {},
      { headers }
    );
    setUserAuthDetails(res.data);
    return res.data;
  };

  const processInvitation = async () => {
    const data = decryptUsingAES256(
      queryParameters.get("invitation_identifier")!
    );
    if (data && userAuthDetails) {
      const processInvitationPayload = {
        invitation_id: data.invitation_id,
        auth_id: userAuthDetails.auth_id,
        user_id: data.recipient_id,
        full_name: userAuthDetails.full_name,
        profile_picture: userAuthDetails.profile_picture,
      };
      const res = await invitationService.processInvitation(
        processInvitationPayload
      );
      if (res.messageId === 1) {
        window.location.href = process.env.REACT_APP_PLATFORM_URL!;
      } else {
        window.location.href = `${process.env.REACT_APP_PLATFORM_URL!}/error`;
      }
    } else {
      const user = await getUserAuthDetails();
      if (data) {
        const processInvitationPayload = {
          invitation_id: data.invitation_id,
          auth_id: user.auth_id,
          user_id: data.recipient_id,
          full_name: user.full_name,
          profile_picture: user.profile_picture,
        };
        const res = await invitationService.processInvitation(
          processInvitationPayload
        );
        if (res.messageId === 1) {
          window.location.href = process.env.REACT_APP_PLATFORM_URL!;
        } else {
          window.location.href = `${process.env.REACT_APP_PLATFORM_URL!}/error`;
        }
      }
    }
  };

  const getErrorDescriptionJsx = () => {
    switch (errorCode) {
      case ErrorCodes.EXPIRED:
        return pageCmsData?.lbl_invitation_expired ?? "";
      case ErrorCodes.INVALID:
        return pageCmsData?.lbl_invitation_invalid ?? "";
      case ErrorCodes.MISMATCH:
        return pageCmsData?.lbl_invitation_email_mismatch ?? "";
    }
  };


  useEffect(() => {
    setIsLoading(true);
    getCmsData();
    getInvitationStatus();
    getUserAuthDetails();
    if (
      queryParameters.get("page_redirect_uri") !== undefined &&
      queryParameters.get("page_redirect_uri") !== "null"
    ) {
      window.location.href = atob(
        decodeURIComponent(queryParameters.get("page_redirect_uri") ?? "")
      );
      return;
    }
    if (queryParameters.get("invitation_identifier") === "null") {
      window.location.href = process.env.REACT_APP_PLATFORM_URL!;
    }
    return () => {
      document.getElementById("root")!.className = "";
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const data = decryptUsingAES256(
      queryParameters.get("invitation_identifier")!
    );

    if (queryParameters.get("invitation_identifier") !== "null") {
      if (Date.now() > data?.exp!) {
        setErrorCode(ErrorCodes.EXPIRED);
        setIsLoading(false);
      } else if (
        invitationStatusData?.is_existing_user === false &&
        (invitationStatusData?.deleted_on || invitationStatusData?.accepted_on)
      ) {
        setErrorCode(ErrorCodes.INVALID);
        setIsLoading(false);
      } else if (data?.recipient_email !== userAuthDetails?.email) {
        setErrorCode(ErrorCodes.MISMATCH);
        setIsLoading(false);
      } else if (data?.recipient_email === userAuthDetails?.email) {
        processInvitation();
      }
    }
  }, [invitationStatusData, userAuthDetails]);

  useEffect(() => {
    document.getElementById("root")!.className = !isLoading
      ? "genericWrapper loginWrapperWhole"
      : "";
  }, [isLoading]);

  return !isLoading ? (
    <div className="loginRedirectPage registrationGenericContent">
      <div className="leftImg" />
      <div className="loginBlk">
        <h2>{pageCmsData?.lbl_invitation_error_header ?? ""}</h2>
        <p>{getErrorDescriptionJsx()}</p>
        {showError && (
          <button className="btnStyle1 btnInline" onClick={handleClick}>
            {pageCmsData?.btn_login ?? "Login"}
          </button>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AuthCallbackPage;
