import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { message } from 'antd';

import { RightSideDrawer, useAnalytics } from 'components/sharedComponents';
import {
  ICreateProjectResponseDetails,
  ILoaderState,
  IOrganizationAssociatedUserDetails,
  IProjectData,
  IProjectFolderDetails,
  IProjectList,
  IProjectMember,
  IProjectPermissions,
  IProjectState,
  IProjectSubmitData,
} from 'models/interface';
import {
  useDashboardStore,
  useLoaderStore,
  useProjectDashboardStore,
  useUserStore,
} from 'stores';
import { getCurrentOrganization, removeOverlay } from 'utils/commonFunctions';
import { projectService, loggerService } from 'services';
import {
  generateNewListFromCreateProject,
  generateNewProjectList,
} from 'utils/generateNewList';
import { modeOps } from 'models/enums/constants';
import { useOrganizationRoutes } from 'routes/orgRoutes';

const CloneProject: FC<{
  pageSpecificDataState?: any;
  showSidePanel: boolean;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  getProjectDetails: () => Promise<void>;
}> = ({
  pageSpecificDataState,
  showSidePanel,
  setShowSidePanel,
  getProjectDetails,
}) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [associatedUser, setAssociatedUser] = useState<any>([]);

  const storeUserDetail = useUserStore((state) => state.userDetails);
  const { projectDetails, projectMembers, initiateProjectMembers } =
    useProjectDashboardStore((state: IProjectState) => state);
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);

  const gaContext = useAnalytics();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const navigate = useNavigate();

  const {
    userDetails,
    projectRolePermissionsList,
    setProjectRolePermissionsList,
  } = useUserStore((state: any) => state);
  const { folderProjectList, setFolderProjectList, setProjectList } =
    useDashboardStore((state: any) => state);
  const [sectionData, setSectionData] = useState<any[]>([]);
  const [clonedSectionsLoading, setClonedSectionsLoading] = useState(true);

  const {
    register,
    watch,
    setError,
    setValue,
    reset,
    setFocus,
    formState: { errors },
    getValues,
    clearErrors,
  } = useForm<IProjectSubmitData>();

  useEffect(() => {
    if (projectDetails) {
      setValue('projectName', `Copy of ${projectDetails?.project_name!}`);
      setValue('folderId', projectDetails.folder_id as number);
    }
    (async function getData() {
      if (!showSidePanel) return;
      setClonedSectionsLoading(true);
      const response = await projectService.getSectionsData({
        organization_id: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id!,
        project_id: projectDetails?.project_id,
        sections: projectDetails?.sections?.map((section) => ({
          section_id: section.section_id,
          section_type_id: section.section_type_id,
        })),
      });
      if (response.messageId === 1) {
        const sortedData = response?.data?.map((section: any) => {
          if (section?.section_type_id !== 1) {
            return section;
          } else {
            const sectionTemp = { ...section };
            sectionTemp.task_list_details =
              sectionTemp?.task_list_details?.sort(
                (a: any, b: any) => a?.task_rank - b?.task_rank,
              ) || [];
            return sectionTemp;
          }
        });
        setSectionData(sortedData || []);
        setValue(
          'sectionIds',
          response.data.map((section: any) => section.section_id),
        );
      }
      setClonedSectionsLoading(false);
    })();
  }, [projectDetails, showSidePanel]);

  useEffect(() => {
    if (projectMembers) {
      let users = generateUserList(projectMembers);
      setAssociatedUser(users);
    }
  }, [projectMembers, showSidePanel]);

  useEffect(() => {
    if (showSidePanel) {
      setIsDisabled(true);
      setFocus('projectName');
    }
  }, [showSidePanel]);

  useEffect(() => {
    let newProjectDetails = {
      projectName: getValues('projectName'),
      folderId: +getValues('folderId'),
      sectionIds: getValues('sectionIds'),
    };

    if (
      newProjectDetails.projectName?.trim().length < 4 ||
      newProjectDetails.projectName?.trim().length > 50
    ) {
      setIsDisabled(true);
      setError('projectName', {
        message:
          pageSpecificDataState?.lbl_error_message_project_name_validation,
      });
    } else {
      clearErrors('projectName');
      setIsDisabled(
        !shouldCallBackend(
          newProjectDetails,
          associatedUser,
          projectDetails,
          projectMembers,
        ),
      );
    }
  }, [
    watch('projectName'),
    watch('folderId'),
    watch('sectionIds'),
    associatedUser,
    projectDetails,
    projectMembers,
  ]);

  const sendAnalyticsEvent = () => {
    gaContext.updateProps({
      username: userDetails.full_name,
      email: userDetails.email,
      organization: getCurrentOrganization(
        org_key,
        userDetails.organization_info,
      )
        ? getCurrentOrganization(org_key, userDetails.organization_info)
            ?.organization_name
        : '',
      eventName: 'Clone Project Finished',
      eventCategory: 'Projects',
      eventLabel: 'Clone Project',
    });

    return true;
  };

  const shouldCallBackend = (
    newProjectDetails: IProjectSubmitData,
    newProjectMember: IOrganizationAssociatedUserDetails[],
    oldProjectDetails: IProjectData | null,
    oldProjectMember: IProjectMember[] | null,
  ) => {
    let newMember = newProjectMember
      .filter((ele: IOrganizationAssociatedUserDetails) => ele.should_delete)
      .map((ele: IOrganizationAssociatedUserDetails) => {
        return { userId: ele.user_id, roleId: ele.role_id };
      })
      .sort((a, b) => a.userId - b.userId);

    let oldMember = oldProjectMember
      ?.filter((ele: IProjectMember) => ele?.should_delete)
      .map((ele: IProjectMember) => {
        return { userId: ele.user_id, roleId: ele.role_id };
      })
      .sort((a, b) => a.userId - b.userId);

    if (
      newProjectDetails.projectName?.trim() ===
        oldProjectDetails?.project_name?.trim() &&
      newProjectDetails.folderId == oldProjectDetails?.folder_id &&
      JSON.stringify(oldMember) === JSON.stringify(newMember)
    ) {
      return false;
    }

    return true;
  };

  const onSubmitHandler = async (data: IProjectSubmitData) => {
    sendAnalyticsEvent();
    if (
      shouldCallBackend(data, associatedUser, projectDetails, projectMembers)
    ) {
      try {
        setLoaderState('active');
        const oldMember = initiateProjectMembers?.map((e) => e.email) ?? [];
        const selectedSectionIds = getValues('sectionIds');
        let payload = {
          clonedProjectId: projectDetails?.project_id,
          projectName: data.projectName.trim(),
          projectDescription: '',
          organizationId: getCurrentOrganization(
            org_key,
            userDetails?.organization_info,
          )?.organization_id!,
          folderId: +data.folderId,
          userId: userDetails.user_id,
          sections: sectionData.filter((item) =>
            selectedSectionIds?.includes(item?.section_id),
          ),
          associatedUsers: associatedUser
            .filter(
              (ele: IOrganizationAssociatedUserDetails) => ele.should_delete,
            )
            .map((ele: IOrganizationAssociatedUserDetails) => {
              return { userId: ele.user_id, roleId: ele.role_id };
            }),
          newUserAdded: associatedUser
            .filter(
              (ele: IOrganizationAssociatedUserDetails) => ele.should_delete,
            )
            .map((ele: IOrganizationAssociatedUserDetails) => {
              return {
                userId: ele.user_id,
                roleId: ele.role_id,
                email: ele.email,
                projectName: data.projectName.trim(),
                fullName: ele.full_name,
                orgKey: org_key,
                language: ele.additional_settings?.language ?? 'en',
                organizationId: getCurrentOrganization(
                  org_key,
                  userDetails?.organization_info,
                )?.organization_id!,
                organizationName: getCurrentOrganization(
                  org_key,
                  userDetails?.organization_info,
                )?.organization_name!,
                senderName: storeUserDetail?.full_name!,
              };
            }),
        };
        /*
           .filter(
              (ele: IOrganizationAssociatedUserDetails) =>
                !oldMember.includes(ele.email),
            )
        */
        let response: ICreateProjectResponseDetails =
          await projectService.cloneProject(payload);

        if (response.messageId === 1) {
          /** Update latest project permissions dynamically */
          let project_permissions =
            response.data.project_details[0].project_permissions[0];
          if (projectRolePermissionsList) {
            const isCurrentProjectPermissionsAvailable =
              projectRolePermissionsList.find(
                (eachProject: IProjectPermissions) =>
                  eachProject.project_id ===
                  response.data.project_details[0].project_id,
              );
            if (!isCurrentProjectPermissionsAvailable) {
              setProjectRolePermissionsList([
                ...projectRolePermissionsList,
                project_permissions,
              ]);
            }
          } else {
            setProjectRolePermissionsList(
              response.data.project_details[0].project_permissions,
            );
          }
          /***************************************************/
          let targetIndex: number[] = [];
          let targets = folderProjectList.filter(
            (ele: IProjectFolderDetails) => ele.row_is_duplicate === true,
          );

          folderProjectList.forEach(
            (element: IProjectFolderDetails, index: number) => {
              if (element.row_is_duplicate) targetIndex.push(index);
            },
          );

          let newList: IProjectFolderDetails[] =
            generateNewListFromCreateProject(response.data);
          let newProjectList: IProjectList[] = generateNewProjectList(
            response.data,
          );

          targetIndex.forEach((ele, index) => {
            newList.splice(ele, 0, targets[index]);
          });

          sendAnalyticsEvent();
          setFolderProjectList(newList);
          setProjectList(newProjectList);
          removeOverlay();
          let route = `${organizationBaseRouteUrl}/project/${response.data.project_details[0].project_id}`;
          navigate(route);
        } else if (response.messageId === -2) {
          setError('projectName', {
            message:
              pageSpecificDataState?.lbl_error_message_project_name_exists,
          });
        }
      } catch (err) {
        message.error(pageSpecificDataState?.lbl_error_message_generic, 3);
        await loggerService.log({
          severity: 'High',
          message: `project creation api failed`,
          payload: { ApiResponse: err },
        });
      } finally {
        setLoaderState('inactive');
      }
    } else {
      removeOverlay();
      setShowSidePanel(false);
    }
  };

  const generateUserList = (projectMembers: IProjectMember[]) => {
    let tempList = [...projectMembers];

    const owner = tempList.find(
      (item) => item.user_id === userDetails?.user_id,
    );
    const other = tempList.filter(
      (item) => item.user_id !== userDetails?.user_id,
    );

    return [
      {
        ...owner,
        role_id: 3,
        should_delete: false,
        full_name_for_display: `${owner?.full_name} (${pageSpecificDataState?.lbl_default_member_suffix})`,
      },
      ...other
        .sort((a: any, b: any) => (a?.full_name > b?.full_name ? 1 : -1))
        .map((ele) => {
          return {
            ...ele,
            full_name_for_display: ele.full_name ? ele.full_name : ele.email,
            role_id: ele.role_id === 3 ? 4 : ele.role_id,
            should_delete: true,
          };
        }),
    ];
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        //handleSubmit(onSubmitHandler)
        let payload = {
          projectName: getValues('projectName'),
          folderId: getValues('folderId'),
          sectionIds: getValues('sectionIds'),
        };
        onSubmitHandler(payload);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <RightSideDrawer
        pageSpecificDataState={pageSpecificDataState}
        showSidePanel={showSidePanel}
        setShowSidePanel={setShowSidePanel}
        register={register}
        errors={errors}
        isDisabled={isDisabled}
        getValues={getValues}
        setIsDisabled={setIsDisabled}
        reset={reset}
        associatedUser={associatedUser}
        setAssociatedUser={setAssociatedUser}
        mode={modeOps.CLONE}
        setValue={setValue}
        existingWidgetList={sectionData}
        clonedSectionsLoading={clonedSectionsLoading}
      />
    </form>
  );
};

export default CloneProject;
