export enum ERbacPermissions {
    // Organization
    ORGANIZATION_MEMBER_MANAGE = "org:members:manage",
    ORGANIZATION_MANAGE = "org:edit",
    ORGANIZATION_BILLING_MANAGE = "org:billing:manage",
    ORGANIZATION_DELETE = "org:delete",

    // Project
    PROJECT_CREATE = "project:create",
    PROJECT_VIEW_ALL = "project:view:all",
    PROJECT_VIEW_ASSIGNED = "project:view:assigned",
    PROJECT_EDIT = "project:edit",
    PROJECT_DELETE = "project:delete",
    PROJECT_CLONE = "project:clone",
    PROJECT_MEMBER_ADD = "project:member:add",
    PROJECT_MEMBER_REMOVE = "project:member:remove",

    // Project - Section
    PROJECT_SECTION_VIEW = "project:section:view",
    PROJECT_SECTION_CREATE = "project:section:create",
    PROJECT_SECTION_EDIT = "project:section:edit",
    PROJECT_SECTION_DELETE = "project:section:delete",

    // Project - Section - Task
    PROJECT_SECTION_TASK_VIEW = "project:section:task:view",
    PROJECT_SECTION_TASK_CREATE = "project:section:task:create",
    PROJECT_SECTION_TASK_EDIT = "project:section:task:edit",
    PROJECT_SECTION_TASK_STATUS_EDIT = "project:section:task:status:edit",
    PROJECT_SECTION_TASK_DELETE = "project:section:task:delete",
    PROJECT_SECTION_TASK_CUSTOM_FIELD_ADD = "project:section:task:customfield:add",
    PROJECT_SECTION_TASK_CUSTOM_FIELD_SETTING_VIEW = "project:section:task:customfield:setting:view",

    //Project - Section - Grid List
    PROJECT_SECTION_GRIDLIST_TASK_ADD = "project:section:gridlist:task:add",
    PROJECT_SECTION_GRIDLIST_STAGE_ADD = "project:section:gridlist:stage:add",
    PROJECT_SECTION_GRIDLIST_TASK_EDIT = "project:section:gridlist:task:edit",
    PROJECT_SECTION_GRIDLIST_STAGE_EDIT = "project:section:gridlist:stage:edit",
    PROJECT_SECTION_GRIDLIST_BLOCK_EDIT = "project:section:gridlist:block:edit",
    PROJECT_SECTION_GRIDLIST_BLOCK_STATUS_EDIT = "project:section:gridlist:block:status:edit",
    PROJECT_SECTION_GRIDLIST_TASK_DELETE = "project:section:gridlist:task:delete",
    PROJECT_SECTION_GRIDLIST_STAGE_DELETE = "project:section:gridlist:stage:delete",
    PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_FIELD_ADD = "project:section:gridlist:customfield:add",
    PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_SETTING_VIEW = "project:section:gridlist:customfield:setting:view",
}