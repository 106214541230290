import { create } from 'zustand';
import { projectService } from 'services';
import { ITaskStatus } from 'models/interface';

export const useTaskStatusStore = create<ITaskStatus>((set) => ({
    //initial states
    taskStatusCmsData: null,
    taskStatusMaster: null,
    isLoadingMasterData: false,
  
    //actions
    setTaskStatusCmsData: (value: any) => set({taskStatusCmsData: value}),
    fetchTaskStatusMaster: async () => {
      set({ isLoadingMasterData: true });
      const response = await projectService.getStatusMaster();
      set({ taskStatusMaster: response.data });
      set({ isLoadingMasterData: false });
  }
}));