import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button } from "antd";
import React, { useRef, useState } from "react";

interface UploadFieldProps {
  disabled?: boolean;
}

const allowedFileTypes: string[] = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const UploadField: React.FC<UploadFieldProps> = (props) => {
  const { disabled } = props;
  const [fieldData, setFieldData] = useState<any>("");
  const [file, setFile] = useState<any | null>(null);
  const [fileUrl, setFileUrl] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFileUpload = (e: any) => {
    const uploadedFile = e.target.files[0];

    if (allowedFileTypes.includes(uploadedFile.type)) {
      setFile(uploadedFile);
      const fileUrl = URL.createObjectURL(uploadedFile);
      setFileUrl(fileUrl);
    }
  };
  return (
    <div className="upload-cell">
      {!file ? (
        <>
          <label style={{ cursor: "pointer" }} htmlFor="file_upload">
            <Button disabled={disabled} onClick={() => inputRef.current?.click()} icon={<UploadOutlined />} size="small"></Button>
          </label>
          <input
            onChange={handleFileUpload}
            style={{ display: "none" }}
            type={"file"}
            id="file_upload"
            placeholder="Upload file here"
            ref={inputRef}
            disabled={disabled}
          />
        </>
      ) : (
        <>
          <a
            style={{ textWrap: "wrap" }}
            target={"_blank"}
            download={file.name}
            href={fileUrl}
          >
            <Button icon={<EyeOutlined />} size="small"></Button>
          </a>
          <Button disabled={disabled} icon={<EditOutlined />} size="small"></Button>
          <Button disabled={disabled} icon={<DeleteOutlined />} size="small"></Button>
        </>
      )}
    </div>
  );
};

export default UploadField;
