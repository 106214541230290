import { IGridList } from "models/interface";
import { FC } from "react";
import Block from "./Block";

const BlockWrapper: FC<{
  gridListDetails: IGridList;
  taskIndex: number;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  onCustomFieldCellChange: (value: string, cell: any) => void;
}> = ({ gridListDetails, taskIndex, setGridListDetails, onCustomFieldCellChange }) => {
  const checkTaskStageList = (gridList: IGridList) => {
    return (
      gridList?.grid_list_details?.task_details?.length &&
      gridList?.grid_list_details?.stage_details?.length
    );
  };

  return (
    checkTaskStageList(gridListDetails) && (
      <Block
        taskIndex={taskIndex}
        gridListDetails={gridListDetails}
        setGridListDetails={setGridListDetails}
        onCustomFieldCellChange={onCustomFieldCellChange}
      />
    )
  );
};

export default BlockWrapper;
