import { message, Spin, Tooltip } from 'antd';
import { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import {
  IModalState,
  IOrganization,
  IProfileSettingCmsData,
  OrgRole,
} from '../../../../models/interface';
import { ITeamMember } from '../../../../models/interface/organization.interface';
import DashboardService from '../../../../services/DashboardService/dashboard.service';
import { useGlobalStore, useUserStore } from '../../../../stores';

import { useAnalytics } from '../../../sharedComponents';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import organizationService from '../../../../services/OrganizationService/Organization.service';
import { useModalStore } from '../../../../stores/modal.store';
import { loggerService } from 'services';
import TagsInput from 'react-tagsinput';
import isEmail from 'validator/es/lib/isEmail';
import invitationService from 'services/Invitation/invitation.service';
import React from 'react';
import { markMemberAsRemovedFromOrg } from 'utils/firebaseCommonFunctions';

const OrganizationMember = ({
  lblCms,
  organizationInfo,
}: {
  lblCms: IProfileSettingCmsData | null;
  organizationInfo: IOrganization | null;
}) => {
  const { setModalState, modalState } = useModalStore(
    (state: IModalState) => state,
  );
  const storeUserDetail = useUserStore((state) => state.userDetails);
  const language = useGlobalStore((state) => state.language);
  const gaContext = useAnalytics();
  const [inviteEmail, setInviteEmail] = useState<string[]>([]);
  const [roleSelected, setRoleSelected] = useState<number>(2);
  const [memberData, setMemberData] = useState<ITeamMember[]>([]);
  const [listedEmail, setListedEmail] = useState<Object>({});
  const [fetchingTeamMember, setFetchingTeamMember] = useState<boolean>(true);
  const [isDescending, setIsDescending] = useState<boolean>(false);
  const [isHaveAnotherAdmin, setIsHaveAnotherAdmin] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const fetchTeamMember = async () => {
    try {
      setFetchingTeamMember(true);
      if (organizationInfo?.org_key) {
        const res = await organizationService.getTeamDetail({
          org_key: organizationInfo?.org_key,
          is_desc: isDescending ? 'true' : 'false',
        });
        setMemberData([
          ...res.data.accepted_members,
          ...res.data.pending_members,
        ]);
        setIsHaveAnotherAdmin(
          res.data.accepted_members.some(
            (member: ITeamMember) => member.role_id === 1,
          ),
        );
        setListedEmail(
          [...res.data.accepted_members, ...res.data.pending_members].reduce(
            (a, b) => ({ ...a, [b.email!]: true }),
            {},
          ),
        );
      }
      setFetchingTeamMember(false);
    } catch (error) {
      setFetchingTeamMember(false);
      await loggerService.log({
        severity: 'High',
        message: `Failed to fetch team members`,
        payload: { ApiResponse: error },
      });
    }
  };

  const handleEmailAddition = (emails: string[]) => {
    const multipleEmails = emails.filter((eachEmail) =>
      eachEmail?.trim()?.includes(' '),
    );
    let singleEmails = emails.filter(
      (eachEmail) =>
        !eachEmail?.trim()?.includes(' ') && eachEmail?.trim()?.length !== 0,
    );
    let splitEmails: string[] = [];
    if (multipleEmails?.length > 0) {
      splitEmails = multipleEmails
        .map((eachEmail) => {
          return eachEmail
            ?.toLocaleLowerCase()
            ?.replace(/\n/g, ' ')
            ?.split(' ');
        })[0]
        .filter((eachEmail) => eachEmail?.trim()?.length !== 0);
    }
    const processedEmails = [
      ...singleEmails.map((eachEmail) =>
        eachEmail?.toLocaleLowerCase()?.trim(),
      ),
      ...splitEmails.map((eachEmail) => eachEmail?.toLocaleLowerCase()?.trim()),
    ];
    const uniqueSet = new Set(processedEmails);
    processedEmails.splice(0, processedEmails.length, ...uniqueSet);
    const invalidEmails = processedEmails.find((eachEmail) => {
      return !isEmail(eachEmail);
    });
    if (invalidEmails) {
      messageApi.open({
        type: 'warning',
        content: lblCms?.lbl_error_responses.invalid_email_error,
        duration: 3,
      });
      return;
    }
    if ((processedEmails?.length ?? 0) > 10) {
      messageApi.open({
        type: 'warning',
        content: lblCms?.lbl_maximum_email,
        duration: 3,
      });
      return;
    }
    if ((processedEmails.at(-1) ?? 'xxxxx') in listedEmail) {
      messageApi.open({
        type: 'warning',
        content: lblCms?.lbl_duplicate_user_add,
        duration: 3,
      });
      return;
    }
    setInviteEmail(processedEmails);
  };

  const sendInvitation = async () => {
    setFetchingTeamMember(true);
    const sendInviteKey = 'sendInvite';
    messageApi.open({
      key: sendInviteKey,
      type: 'loading',
      content: lblCms?.lbl_invitation_sent,
      duration: 0,
    });
    gaContext.updateProps({
      username: storeUserDetail?.full_name!,
      email: storeUserDetail?.email!,
      organization: organizationInfo?.organization_name!,
      eventName: 'Invite another user to join team',
      eventCategory: 'Organization',
      eventLabel: 'Invite User',
    });
    const invitationData = inviteEmail.map((eachEmail) => ({
      email: eachEmail,
      role_id: roleSelected,
    }));
    setInviteEmail([]);
    const response = await invitationService.inviteUsers({
      sender_name: storeUserDetail?.full_name!,
      invitation_data: invitationData,
      organization_name: organizationInfo?.organization_name!,
      org_key: organizationInfo?.org_key ?? '',
      sender_id: +storeUserDetail?.user_id!,
      language: language,
    });
    fetchTeamMember();
    if (response.messageId === 1) {
      messageApi.open({
        key: sendInviteKey,
        type: 'success',
        content: lblCms?.lbl_invitation_sent,
        duration: 3,
      });
      return;
    }
    messageApi.open({
      key: sendInviteKey,
      type: 'error',
      content: lblCms?.lbl_invitation_sent,
      duration: 3,
    });
  };

  const resendInvitation = async (email: string, role: number) => {
    const resendInviteKey = 'resendInvite';
    messageApi.open({
      key: resendInviteKey,
      type: 'loading',
      content: lblCms?.lbl_invitation_sent,
      duration: 0,
    });
    await invitationService.inviteUsers({
      sender_name: storeUserDetail?.full_name!,
      invitation_data: [{ email: email, role_id: role }],
      organization_name: organizationInfo?.organization_name!,
      org_key: organizationInfo?.org_key ?? '',
      sender_id: +storeUserDetail?.user_id!,
      language: language,
    });
    messageApi.open({
      key: resendInviteKey,
      type: 'success',
      content: lblCms?.lbl_invitation_sent,
      duration: 3,
    });
  };

  const updateUserRole = async (email: string, role: number, index: number) => {
    const tempListMember = memberData;
    tempListMember[index].role_id = role;
    setMemberData(tempListMember);
    const haveAnotherAdmin =
      tempListMember.filter((e) => e.role_id === 1 && e.accepted_on).length > 1;
    setIsHaveAnotherAdmin(haveAnotherAdmin);
    await DashboardService.postUpdateUserRole({
      email: email,
      organization_id: +organizationInfo?.organization_id!,
      role_id: role,
    });
    messageApi.open({
      type: 'info',
      content: lblCms?.lbl_role_updated,
      duration: 3,
    });
    gaContext.updateProps({
      username: storeUserDetail?.full_name!,
      email: storeUserDetail?.email!,
      organization: organizationInfo?.organization_name!,
      eventName: 'Change member role',
      eventCategory: 'Organization',
      eventLabel: 'Update Role',
    });
    fetchTeamMember();
  };

  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
  };

  const removeTeamMember = async (userId: number, username: string) => {
    setModalState({
      modalHeader: (
        <Fragment>
          <h3> {lblCms?.lbl_remove_team_member} </h3>
          <span
            className="material-icons-outlined modal-close"
            onClick={() => modalClose()}
            onKeyDown={() => {}}
          >
            close
          </span>
        </Fragment>
      ),
      modalBody: (
        <React.Fragment>
          <div className="icon"></div>
          <p>
            {lblCms?.lbl_member_delete_warning_1}&nbsp;
            <b>{username}</b>? <br />
            {lblCms?.lbl_member_delete_warning_2}
          </p>
        </React.Fragment>
      ),
      modalFooter: (
        <Fragment>
          <button
            className="noStyle cancel_deleteFolder"
            onClick={() => modalClose()}
          >
            {lblCms?.cta_delete_modal_cancel}
          </button>

          <button
            className="deleteBtnStyle"
            onClick={() =>
              (async () => {
                setFetchingTeamMember(true);
                modalClose();
                await organizationService.removeTeamMember({
                  user_id: userId,
                  organization_id: +organizationInfo?.organization_id!,
                  sender_name: storeUserDetail?.full_name as string,
                });
                gaContext.updateProps({
                  username: storeUserDetail?.full_name!,
                  email: storeUserDetail?.email!,
                  organization: organizationInfo?.organization_name!,
                  eventName: 'Remove member from organization',
                  eventCategory: 'Organization',
                  eventLabel: 'Remove Member',
                });
                messageApi.open({
                  type: 'info',
                  content: lblCms?.lbl_user_removed,
                  duration: 3,
                });
                if (organizationInfo && organizationInfo.organization_id) {
                  markMemberAsRemovedFromOrg(
                    Number(organizationInfo.organization_id),
                    Number(userId),
                  );
                }
                fetchTeamMember();
              })()
            }
          >
            {lblCms?.lbl_delete_member}
          </button>
        </Fragment>
      ),
      shouldShow: true,
    });
  };

  const generateUserAvatar = (userDetails: ITeamMember) => {
    if (!userDetails?.profile_picture) {
      let nameSplit = userDetails?.full_name?.trim()?.split(' ');

      if (nameSplit?.length) {
        if (nameSplit?.length === 1) {
          return { key: 'string', value: `${nameSplit[0]?.charAt(0)}` };
        } else {
          return {
            key: 'string',
            value: `${nameSplit[0]?.charAt(0)}${nameSplit[
              nameSplit?.length - 1
            ]?.charAt(0)}`,
          };
        }
      } else {
        return {
          key: 'string',
          value: `${userDetails?.email?.charAt(0).toLocaleUpperCase()}`,
        };
      }
    } else {
      return { key: 'url', value: userDetails?.profile_picture };
    }
  };

  const renderName = (e: ITeamMember) => {
    if (e.invitation_id === null || (e.invitation_id && e.accepted_on)) {
      return (
        <div className="memberRoleAction">
          <div className="userBlkWrap">
            <div
              className={`userAvtr ${
                generateUserAvatar(e)?.key === 'url' ? 'userAvtrImg' : ''
              }`}
            >
              {generateUserAvatar(e)?.key === 'string' ? (
                generateUserAvatar(e)?.value
              ) : (
                <img alt="avatar" src={generateUserAvatar(e)?.value}></img>
              )}
            </div>
            <div className="userName-details">
              <div className="userName">
                {e.full_name}
                {storeUserDetail?.email === e.email
                  ? lblCms?.lbl_you_information!
                  : ''}
              </div>
              <div className="userEmail">{e.email}</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="memberRoleAction">
        <div className="userBlkWrap resendInviteTableData">
          <div className="userAvtr userAvtrBlank"></div>
          <div className="userName-details">
            <div className="userAvtrBlankContent">{e.email}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderRoleChanger = (item: ITeamMember, i: number) => {
    return (
      <div className="memberRole">
        <select
          name="role"
          defaultValue={item.role_id!}
          disabled={item.is_owner || item.email === storeUserDetail?.email}
          onChange={(evt) => updateUserRole(item.email!, +evt.target.value, i)}
          className={
            item.is_owner || item.email === storeUserDetail?.email
              ? 'disabledElement'
              : ''
          }
        >
          {lblCms?.org_role.map((e: OrgRole) => (
            <option key={e.value} value={e.value}>
              {e.label}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderDeleteIcon = (e: ITeamMember) => {
    if (e.email === storeUserDetail?.email) {
      return null;
    }

    if (e.invitation_id === null && e.accepted_on === null && e.role_id === 1) {
      return null;
    }
    return (
      <span
        onClick={() => removeTeamMember(e.user_id!, e.full_name ?? e.email!)}
        className="cmnIcon deleteBin"
        onKeyDown={() => {}}
      ></span>
    );
  };

  const defaultRenderInput = (props: any) => {
    let { onChange, value, addTag, ...other } = props;
    return <input type="text" onChange={onChange} value={value} {...other} />;
  };

  useEffect(() => {
    const haveAnotherAdmin =
      memberData.filter((e) => e.role_id === 1 && e.accepted_on).length >= 1;
    setIsHaveAnotherAdmin(haveAnotherAdmin);
  }, [memberData]);

  useEffect(() => {
    fetchTeamMember();
  }, [organizationInfo, isDescending]);

  return (
    <>
      {contextHolder}
      <div className="workspace-member-heading">
        <h4>
          {organizationInfo?.organization_name} {lblCms?.lbl_member}
        </h4>
      </div>
      <div className="inviteMember">
        <h4>
          {lblCms?.lbl_invite}
          <Tooltip
            title={`${lblCms?.lbl_invite_help_tooltip_msg}`}
            placement="top"
          >
            <InfoCircleOutlined />
          </Tooltip>
        </h4>
        <div className="mergedFld">
          <div className="textArea-chip">
            <TagsInput
              inputProps={{
                className: 'react-tagsinput-input',
                placeholder: `${lblCms?.lbl_invite_placeholder}`,
              }}
              className={`${inviteEmail?.length ? 'react-tagsinput' : ''}`}
              renderInput={defaultRenderInput}
              value={inviteEmail}
              onChange={handleEmailAddition}
              onlyUnique={true}
            />
          </div>
          <select
            name="role"
            value={roleSelected}
            onChange={(evt) => setRoleSelected(+evt.target.value)}
            style={{
              backgroundColor: 'transparent',
              display: inviteEmail.length ? 'block' : 'none',
            }}
          >
            {lblCms?.org_role.map((e) => (
              <option key={e.value} value={e.value}>
                {e.label}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={sendInvitation}
          disabled={!inviteEmail.length}
          className={classNames({
            btnStyle3: inviteEmail.length,
            disabledBtn: !inviteEmail.length,
          })}
        >
          {lblCms?.lbl_invite}
        </button>
      </div>
      <div className="inviteMemberInfoTxt">{lblCms?.lbl_maximum_email}</div>

      {/*This line used for showing organization member*/}
      <div className="memberInviteTable tab1Table">
        <table aria-describedby="MemberInviteTable">
          <thead>
            <tr>
              <th onClick={() => setIsDescending((prev) => !prev)}>
                <div className="th-div">
                  {lblCms?.lbl_name}{' '}
                  <span
                    className={`cmnIcon ${
                      isDescending ? 'sort-arrow-down' : 'sort-arrow-up'
                    }`}
                  />
                </div>
              </th>
              <th></th>

              <th>
                <div className="th-div">{lblCms?.lbl_workspace_role}</div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!fetchingTeamMember && lblCms !== null ? (
              memberData?.map((eachMember, i) => (
                <tr key={eachMember.user_id}>
                  <td>{renderName(eachMember)}</td>

                  <td>
                    {eachMember.accepted_on === null &&
                    eachMember.invitation_id ? (
                      <button
                        onClick={() =>
                          resendInvitation(
                            eachMember.email!,
                            eachMember.role_id!,
                          )
                        }
                        className="RIbutton"
                      >
                        {lblCms?.lbl_resend_invite}
                      </button>
                    ) : null}
                  </td>
                  <td>{renderRoleChanger(eachMember, i)}</td>
                  <td>{renderDeleteIcon(eachMember)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  style={{ textAlign: 'center', padding: '20px' }}
                  colSpan={4}
                >
                  <Spin indicator={antIcon} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default OrganizationMember;
