import { FC, Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'components/sharedComponents';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { getCurrentOrganization } from 'utils/commonFunctions';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import {
  IEmptyProjectDashboardAttributes,
  IProjectState,
} from 'models/interface';
import { ConfigProvider, Typography } from 'antd';
import { notificationService, projectService } from 'services';
import OutsideClickHandler from 'components/sharedComponents/OutsideClickHandler';

const ProjectSettingsCta: FC<{
  pageSpecificationData: IEmptyProjectDashboardAttributes;
  showSettingDropDown: boolean;
  setShowSettingDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSidePanel: React.Dispatch<React.SetStateAction<boolean>>;
  setShowProjectMemberDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  setSidePanelMode: React.Dispatch<React.SetStateAction<string>>;
  setIsLoadingProjectDetails?: React.Dispatch<React.SetStateAction<any>>;
  getProjectDetails?: () => void;
  socket?: any;
  msg?: any;
  messageData?: any;
}> = ({
  pageSpecificationData,
  showSettingDropDown,
  setShowSettingDropDown,
  setShowDropdown,
  setShowSidePanel,
  setShowProjectMemberDropdown,
  setSidePanelMode,
  setIsLoadingProjectDetails,
  getProjectDetails,
  socket,
  msg,
  messageData,
}) => {
  const gaContext = useAnalytics();
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const [archived, setArchived] = useState<boolean>();
  const { userDetails } = useUserStore((state: any) => state);
  const { projectDetails, setRefreshDashboardFolders, projectMembers } =
    useProjectDashboardStore((state: IProjectState) => state);
  useEffect(() => {
    if (projectDetails !== undefined) {
      setArchived(projectDetails?.is_archived);
    }
  }, [projectDetails]);
  const { hasPermissions } = useRbac();

  const isOwner = () => {
    if (projectMembers && projectMembers.length > 0 && userDetails) {
      let userRole = projectMembers.find(
        (itm: any) => Number(itm.user_id) === Number(userDetails.user_id),
      );
      if (userRole && Number(userRole.role_id) === 3) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const determineClass = (eachOption: any) => {
    if (
      eachOption.value !== 'archive_project' &&
      eachOption.value !== 'restore_project' &&
      projectDetails?.is_archived === true
    ) {
      return 'disabledOption';
    } else {
      return '';
    }
  };

  const sendAnalyticsEvent = () => {
    gaContext.updateProps({
      username: userDetails.full_name,
      email: userDetails.email,
      organization: getCurrentOrganization(
        org_key,
        userDetails.organization_info,
      )
        ? getCurrentOrganization(org_key, userDetails.organization_info)
            ?.organization_name
        : '',
      eventName: 'Update Project Started',
      eventCategory: 'Projects',
      eventLabel: 'Update Project',
    });

    return true;
  };

  const archiveOrRestoreProjectCall = async () => {
    let archiveProjectCMSData: any = messageData[0]?.attributes;
    try {
      await projectService.archiveOrRestoreProject({
        organization_id: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id,
        project_id: projectDetails?.project_id,
      });
      setArchived((prevState) => !prevState);
      setRefreshDashboardFolders(true);
      getProjectDetails && getProjectDetails();
      setIsLoadingProjectDetails && setIsLoadingProjectDetails(false);

      msg.open({
        key: 'archiveProject',
        type: 'success',
        content: !projectDetails?.is_archived
          ? archiveProjectCMSData?.lbl_archive_success_message
          : archiveProjectCMSData?.lbl_restore_success_message,
        duration: 2,
      });

      notificationService.sendArchiveProjectNotification(socket, {
        currentOrganizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info,
        )?.organization_id,
        currentProjectId: projectDetails?.project_id,
        currentProjectName: projectDetails?.project_name,
        currentUserId: userDetails.user_id,
        currentUserName: userDetails.full_name
          ? userDetails.full_name
          : userDetails.email,
        isArchived: !projectDetails?.is_archived,
      });
    } catch (err) {
      setIsLoadingProjectDetails && setIsLoadingProjectDetails(false);
    }
  };

  return (
    <Fragment>
      <Rbac
        allowedPermissions={[
          ERbacPermissions.PROJECT_EDIT,
          ERbacPermissions.PROJECT_CLONE,
          ERbacPermissions.PROJECT_DELETE,
          ERbacPermissions.PROJECT_MEMBER_ADD,
          ERbacPermissions.PROJECT_MEMBER_REMOVE,
        ]}
        project_role_id={projectDetails?.associated_role_id}
      >
        <button
          type="button"
          name="settings"
          className="btnStyle2"
          onClick={() => {
            setShowSettingDropDown(!showSettingDropDown);
            setShowDropdown(false);
            setShowProjectMemberDropdown(false);
          }}
        >
          <span className="cmnIcon settings"></span>
        </button>
      </Rbac>
      {showSettingDropDown ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowSettingDropDown(!showSettingDropDown);
            setShowDropdown(false);
            setShowProjectMemberDropdown(false);
          }}
        >
          <ul
            className={`genericPopup projectSettingsMenu ${
              showSettingDropDown ? 'show' : ''
            }`}
          >
            {pageSpecificationData.btn_gear_icon_dropdown_options
              ?.filter((eachOption) => {
                if (
                  !eachOption.permissions ||
                  hasPermissions(
                    eachOption.permissions,
                    projectDetails?.associated_role_id,
                  )
                ) {
                  if (
                    (eachOption.value === 'archive_project' ||
                      eachOption.value === 'restore_project') &&
                    isOwner() === true
                  ) {
                    if (archived === false) {
                      return eachOption.value === 'archive_project';
                    } else {
                      return eachOption.value === 'restore_project';
                    }
                  }
                  if (
                    projectDetails?.is_archived === false &&
                    eachOption.value !== 'archive_project' &&
                    eachOption.value !== 'restore_project'
                  ) {
                    return eachOption;
                  }
                }
              })
              ?.map((ele: any) => (
                <li
                  key={ele.label}
                  id={ele.ParentElementId}
                  onClick={() => {
                    if (ele.value === 'edit_project_details') {
                      setShowSettingDropDown(!showSettingDropDown);
                      projectDetails?.is_archived === false &&
                        sendAnalyticsEvent();
                      projectDetails?.is_archived === false &&
                        setSidePanelMode('update');
                      projectDetails?.is_archived === false &&
                        setShowSidePanel(true);
                    } else if (ele.value === 'clone_project') {
                      setShowSettingDropDown(!showSettingDropDown);
                      projectDetails?.is_archived === false &&
                        setSidePanelMode('clone');
                      projectDetails?.is_archived === false &&
                        setShowSidePanel(true);
                    } else {
                      archiveOrRestoreProjectCall();
                      setShowSettingDropDown(!showSettingDropDown);
                    }
                  }}
                  onKeyDown={() => {}}
                  className={determineClass(ele)}
                >
                  <ConfigProvider
                    theme={{
                      token: {
                        colorLink: 'var(--cs-primary-a)',
                      },
                    }}
                  >
                    <Typography.Link>
                      <span className={ele.className}></span>
                      {ele.label}
                    </Typography.Link>
                  </ConfigProvider>
                </li>
              ))}
          </ul>
        </OutsideClickHandler>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default ProjectSettingsCta;
