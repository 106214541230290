import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ICreateTaskResponse,
  ICreateTaskResponseData,
  IGridList,
  IGridListBlockDetail,
  IGridListTaskDetail,
  IProjectState,
  IUpdateTaskResponse,
  IUpdateTaskResponseData,
} from 'models/interface';
import EmptyTask from './EmptyTask';
import { checkTaskList, getCurrentOrganization } from 'utils/commonFunctions';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { projectService } from 'services';
import TaskList from './TaskList';
import { eventTypes } from 'models/enums/constants';
import {
  renameBlockInMessageThread,
  renameTaskInMessageThread,
} from 'utils/firebaseCommonFunctions';
import { SectionTypeMessages } from 'models/enums/messages';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';

const TaskListWrapper: FC<{
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  onCustomFieldCellChange: (value: string, cell: any) => void;
}> = ({ gridListDetails, setGridListDetails, onCustomFieldCellChange }) => {
  const { projectDetails, gridListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );

  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { fetchNewPermission } = useFetchNewPermission();

  const submitChange = async (taskName: string, eventType: string) => {
    if (projectDetails?.project_id) {
      if (taskName.trim() === '') {
        removeEmptyTask();
      } else {
        try {
          const response: ICreateTaskResponse =
            await projectService.createNewGridTask({
              userId: userDetails?.user_id,
              projectId: projectDetails?.project_id,
              organizationId: getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!,
              sectionId: gridListDetails.section_id,
              taskName: taskName.trim(),
            });

          if (response.messageId === 1) {
            generateGridListDetails(gridListDetails, eventType, response.data);
          } else if (response.messageId === -4) {
            fetchNewPermission(
              org_key,
              gridListCmsData?.lbl_error_message_permission_denied,
            );
          } else {
            handleFailedOperation();
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const updateTask = async (taskName: string, taskId: number) => {
    if (projectDetails?.project_id) {
      try {
        const response: IUpdateTaskResponse =
          await projectService.updateGridTask({
            organizationId: getCurrentOrganization(
              org_key,
              userDetails?.organization_info,
            )?.organization_id!,
            userId: userDetails?.user_id,
            projectId: projectDetails?.project_id,
            sectionId: gridListDetails.section_id,
            taskId: taskId,
            taskName: taskName.trim(),
          });

        if (response.messageId === 1) {
          updateProjectStateForTaskUpdate(response.data);
          renameTaskInMessageThread(
            `${SectionTypeMessages.GRID_LIST_TASK}-${
              process.env.REACT_APP_STAGE
            }-${getCurrentOrganization(org_key, userDetails?.organization_info)
              ?.organization_id!}-${projectDetails?.project_id}-${
              gridListDetails.section_id
            }-${taskId}`,
            taskName.trim(),
          );

          let blockDetails =
            gridListDetails.grid_list_details?.block_details!.flat()!;

          let targetBlockList = blockDetails!.filter(
            (ele: IGridListBlockDetail) => ele.task_id === taskId,
          )!;

          for (let block of targetBlockList) {
            await renameBlockInMessageThread(
              `${SectionTypeMessages.GRID_LIST_BLOCK}-${
                process.env.REACT_APP_STAGE
              }-${getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!}-${
                projectDetails?.project_id
              }-${+gridListDetails.section_id}-${block.block_id}`,
              taskName.trim(),
              'task',
            );
          }
        } else {
          revertChange(taskId, 'Blur');
        }
      } catch (error) {
        revertChange(taskId, 'Blur');
      }
    }
  };

  const generateGridListDetails = (
    gridListDetails: IGridList,
    eventType: string,
    data: ICreateTaskResponseData,
  ) => {
    if (
      gridListDetails.grid_list_details!.task_details &&
      !gridListDetails.grid_list_details!.task_details[0].isNew
    ) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details:
            gridListDetails.grid_list_details!.task_details !== null
              ? taskListBasedOnEvent(gridListDetails, eventType, data)
              : data.new_task_details,
          block_details:
            gridListDetails.grid_list_details!.block_details !== null
              ? ([
                  ...gridListDetails.grid_list_details!.block_details!,
                  ...data.new_block_details,
                ] as Array<IGridListBlockDetail[]>)
              : data.new_block_details,
          stage_details: gridListDetails.grid_list_details?.stage_details
            ? gridListDetails.grid_list_details?.stage_details
            : null,
        },
      });
    } else {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          task_details: taskListBasedOnEvent(gridListDetails, eventType, data),
          block_details: gridListDetails.grid_list_details?.block_details
            ? [
                getUpdatedBlockDetails(
                  gridListDetails.grid_list_details
                    ?.block_details as IGridListBlockDetail[][],
                  data.new_task_details[0].task_id,
                ),
              ]
            : data.new_block_details,
          stage_details: gridListDetails.grid_list_details?.stage_details
            ? gridListDetails.grid_list_details?.stage_details
            : null,
        },
      });
    }
  };

  const updateProjectStateForTaskUpdate = (data: IUpdateTaskResponseData) => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: getUpdateTaskDetails(
            gridListDetails,
            data.task_details,
          )!,
        },
      } as IGridList);
    }
  };

  const taskListBasedOnEvent = (
    gridListDetails: IGridList,
    eventType: string,
    data: ICreateTaskResponseData,
  ) => {
    let taskList: any[] = [];

    if (gridListDetails.grid_list_details!.task_details) {
      if (eventType === eventTypes.ENTER) {
        taskList = [
          ...gridListDetails.grid_list_details!.task_details,
          ...data.new_task_details,
        ].filter((ele) => !ele.isNew);
        taskList.push({
          task_id: 99,
          task_name: '',
          task_rank: 99,
          section_id: 99,
          total_block_count: 0,
          completed_block_count: 0,
          isNew: true,
        });
      } else {
        taskList = [
          ...gridListDetails.grid_list_details!.task_details,
          ...data.new_task_details,
        ].filter((ele) => !ele.isNew);
      }
    } else {
      if (eventType === eventTypes.ENTER) {
        taskList = [...data.new_task_details];
        taskList.push({
          task_id: 99,
          task_name: '',
          task_rank: 99,
          section_id: 99,
          total_block_count: 0,
          completed_block_count: 0,
          isNew: true,
        });
      } else {
        taskList = [...data.new_task_details];
      }
    }

    return taskList;
  };

  const removeEmptyTask = () => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: gridListDetails.grid_list_details!.task_details
            ? filterOutTask(gridListDetails.grid_list_details!.task_details)
            : null,
        },
      } as IGridList);
    }
  };

  const filterOutTask = (taskDetails: IGridListTaskDetail[]) => {
    let filteredList = taskDetails.filter((ele: any) => !ele.isNew);

    return filteredList.length > 0 ? filteredList : null;
  };

  const handleFailedOperation = () => {
    if (checkTaskList(gridListDetails)) {
      removeEmptyTask();
    }
  };

  const revertChange = (taskId: number, event: string) => {
    if (gridListDetails) {
      let target = gridListDetails.grid_list_details!.task_details?.filter(
        (ele) => ele.task_id === taskId,
      )!;
      let others = gridListDetails.grid_list_details!.task_details?.filter(
        (ele) => ele.task_id !== taskId,
      )!;

      target[0].task_name = target[0].backupName!;
      if (event === eventTypes.BLUR) delete target[0].backupName;

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: [...others, ...target].sort(
            (a, b) => a.task_rank - b.task_rank,
          ),
        },
      } as IGridList);
    }
  };

  const getUpdateTaskDetails = (
    section: IGridList,
    taskDetails: IGridListTaskDetail[],
  ) => {
    if (section.grid_list_details!.task_details) {
      let task = [...taskDetails];
      let existingTask = section.grid_list_details!.task_details.filter(
        (ele: IGridListTaskDetail) => ele.task_id !== task[0].task_id,
      );
      return [...existingTask, ...task].sort(
        (a, b) => a.task_rank - b.task_rank,
      );
    }
  };

  const getUpdatedBlockDetails = (
    blockDetails: IGridListBlockDetail[][],
    taskId: number,
  ) => {
    return blockDetails[0].map((ele: IGridListBlockDetail) => {
      return {
        ...ele,
        task_id: taskId,
      };
    });
  };

  return (
    <TaskList
      gridListDetails={gridListDetails}
      setGridListDetails={setGridListDetails}
      submitChange={async (stageName, eventType) =>
        await submitChange(stageName, eventType)
      }
      updateTask={async (taskName, taskId) =>
        await updateTask(taskName, taskId)
      }
      revertChange={revertChange}
      onCustomFieldCellChange={onCustomFieldCellChange}
    />
  );
};

export default TaskListWrapper;
