import { AppEnv } from "models/enums/appEnv";
import { useEffect } from "react";

export const usePnCScript = () => {
  useEffect(() => {
    const pnc_script = document.createElement("script");
    if (
      process.env.REACT_APP_STAGE === AppEnv.DEV ||
      process.env.REACT_APP_STAGE === AppEnv.LOCAL
    ) {
      pnc_script.src =
        process.env.REACT_APP_PNC_SCRIPT_SRC ??
        "https://cdn.dev-pnc-script.cleversort.com/releases/1.0/index.js"
      window.pnc_key = process.env.REACT_APP_PNC_SCRIPT_ENV_KEY ??
        "zALeoRLzu+ezn7an40M2f5xk4LNhhVv+XD7UUo76Puv3kWXAaSraBE1ZiYTY1kka4IWeQgHC6GpmfYdZt8xnzBlD4hR8U2IAcw1eyhjiV4TZ1xDyKhxyQ28q/OVigQbF"
      pnc_script.async = true;
      document.body.appendChild(pnc_script);
    }

    return () => {
      document.body.removeChild(pnc_script);
    };
  }, []);
};
