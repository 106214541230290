import React, { useEffect, useState } from "react";
import {
    IOrganization,
    IProfileSettingCmsData,
    IUserState,
} from "models/interface";
import { useUserStore } from "stores";
import ListWithBackgroundColors from "components/sharedComponents/ListWithBackgroundColors/ListWithBackgroundColors";
import { Dropdown, MenuProps, Tooltip } from "antd";
import masterService from "services/Master/master.service";
import { userService } from "services";

type TCustomizeProps = {
    lblCms: IProfileSettingCmsData | null;
    isActive: boolean;
};

type TColorPalette = {
    color_id?: number;
    color_name?: string;
    color_rank?: number;
    hex_code?: string;
    rgb_value?: string;
    org_id?: string | number;
};


type TTeamColorPalette = {
    colors?: {
        color_id?: number;
        hex_code?: string;
    }[];
    selectedColor?: string;
    org?: IOrganization;
    updateFunction: (
        previousColor: string,
        selectedColor: TColorPalette,
        org: IOrganization
    ) => void;
};


const TeamColor: React.FC<TCustomizeProps> = ({ lblCms, isActive }) => {

    const { userDetails, setUserDetails } = useUserStore(
        (state: IUserState) => state
    );
    const [orgColorPalette, setOrgColorPalette] = useState<TColorPalette[]>([]);

    const getOrgColorPaltte = async () => {
        try {
            const result: any = await masterService.getOrgColorPalette();
            const color = result.data ?? [];
            setOrgColorPalette(color);
        } catch (error) {
            console.error(error);
            setOrgColorPalette([]);
        }
    };

    const updateOrgColor = async (
        previousColor: string,
        selectedColor: TColorPalette,
        org:IOrganization
    ) => {
        try {
            const newColor: IOrganization[] | null =
                userDetails?.organization_info?.map((info) => {
                    if (info.organization_id === org.organization_id) {
                        return {
                            ...info,
                            org_color: selectedColor.hex_code!,
                            color: selectedColor.hex_code!,
                        };
                    }
                    return info;
                }) ?? [];

            setUserDetails({ ...userDetails!, organization_info: newColor });

            if (previousColor !== selectedColor.hex_code) {
                await userService.updateUserOrgColor({
                    organization_id: Number(org.organization_id),
                    user_id: Number(userDetails?.user_id),
                    org_color: selectedColor.hex_code!,
                    role_id: Number(org.role_id)
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getOrgColorPaltte();
    }, []);

    const renderAvtr = (image: string | null, name: string, color: string) => {
        if (image) {
            return (
                <div className="userAvtr">
                    <img src={image} />
                </div>
            );
        }
        return (
            <div className="userAvtr" style={{ backgroundColor: color }}>
                {name?.split("")[0]}
            </div>
        );
    };

    const renderRow = () => {
        return userDetails?.organization_info?.map((info) => (
            <tr key={`${info.organization_id}-${info.org_key}`}>
                <td>
                    <div className="projectName">
                        <div className="teamNameWrap">
                            {renderAvtr(
                                info.organization_logo,
                                info.organization_name,
                                info.color
                            )}
                            <Tooltip title={info.organization_name}>
                            <div className="teamName">
                                {info.organization_name}
                            </div>
                            </Tooltip>
                        </div>
                    </div>
                </td>
                <td>
                    <TeamColorPalette
                        colors={orgColorPalette}
                        selectedColor={info.color}
                        org={info}
                        updateFunction={updateOrgColor}
                    />
                </td>
            </tr>
        ));
    };

    return (
        <div
            id="fifthTab"
            className="tabContent"
            style={{ display: isActive ? "block" : "none" }}
        >
            <div className="workspace-member-heading">
                <h4>{lblCms?.heading_your_teams}</h4>
            </div>
            <div className="memberInviteTable projectsTable teamColorTable">
                <table aria-describedby="Projects Table">
                    <thead>
                        <tr>
                            <th>
                                <div className="th-div">
                                    {lblCms?.lbl_team_name}
                                </div>
                            </th>
                            <th>
                                <div className="th-div">{lblCms?.lbl_team_color}</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>{renderRow()}</tbody>
                </table>
            </div>
        </div>
    );
};

export default TeamColor;


const TeamColorPalette: React.FC<TTeamColorPalette> = ({
    colors,
    selectedColor,
    org,
    updateFunction,
}) => {
    const items: MenuProps["items"] = [
        {
            key: "1",
            className: "teamColorSubmenu",
            label: (
                
                    <ListWithBackgroundColors
                        colors={colors}
                        items={[]}
                        callBackFunction={(e: any) => {
                            updateFunction(selectedColor!, e, org!);
                        }}
                    />
                
            ),
        },
    ];

    return (
        <Dropdown
            menu={{ items }}
            trigger={["click"]}
            overlayClassName="TeamColorPaletteMenu"
            placement="bottomLeft"
        >
            <a
                onClick={(e) => e.preventDefault()}
                className="TeamColorPaletteWrap"
            >
                <span
                    className="currentTeamColor"
                    style={{ backgroundColor: selectedColor }}
                ></span>
                <span className="cmnIcon downArrow" />
            </a>
        </Dropdown>
    );
};
