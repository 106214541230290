import { FC, Fragment, MutableRefObject, useCallback, useState } from 'react';
import {
  ICreateStageResponse,
  IGridList,
  IGridListBlockDetail,
  IGridListStageDetail,
  IGridListWrapper,
  IProjectState,
  IUpdateStageResponse,
  IUpdateStageResponseData,
} from 'models/interface';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { Tooltip } from 'antd';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { Rbac, useRbac } from 'auth/rbac/rbac';
import { SectionTypeMessages } from 'models/enums/messages';
// import debounce from 'lodash.debounce';
import StageDelete from './StageDelete';
import ExpandedMessagePopup from 'components/sharedComponents/ExpandedMessagePopup/ExpandedMessageDrawer';
import MessageThread from '../../MessageThread';
import { createPortal } from 'react-dom';
import { eventTypes, newStageId, newStageRank } from 'models/enums/constants';
import { projectService } from 'services';
import { useLocation } from 'react-router-dom';
import { debounce, getCurrentOrganization } from 'utils/commonFunctions';
import { checkStageList } from './AddStageCTA';
import {
  renameBlockInMessageThread,
  renameTaskInMessageThread,
} from 'utils/firebaseCommonFunctions';
import ActionDropdown from '../ColumnAction/ActionDropdown';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';

const DURATION = 50;

interface StageNameInputFieldProps {
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  stageDetails: IGridListStageDetail;
  index?: number;
  customMeta: any;
  updateCustomMeta: (customMeta: any) => void;
  deleteCustomField: () => void;
  deleteOptionsRef?: MutableRefObject<any>;
  width?: number;
}

const StageNameInputField: FC<StageNameInputFieldProps> = (props) => {
  const {
    stageDetails,
    gridListDetails,
    setGridListDetails,
    index,
    customMeta,
    updateCustomMeta,
    deleteCustomField,
    deleteOptionsRef,
    width
  } = props;
  const { hasPermissions } = useRbac();
  const { gridListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const org_key = location.pathname.split('/')[2];

  const { fetchNewPermission } = useFetchNewPermission();

  const revertChange = (stageId: number, event: string) => {
    if (gridListDetails) {
      let target = gridListDetails.grid_list_details!.stage_details?.filter(
        (ele) => ele.stage_id === stageId,
      )!;
      let others = gridListDetails.grid_list_details!.stage_details?.filter(
        (ele) => ele.stage_id !== stageId,
      )!;

      target[0].stage_name = target[0].backupName!;
      if (event === eventTypes.BLUR) delete target[0].backupName;

      let newStageList = [...others, ...target]
        .sort((a, b) => a.stage_rank - b.stage_rank)
        .filter((ele) => ele.stage_id !== newStageId);

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: newStageList.length > 0 ? newStageList : null,
        },
      } as IGridList);
    }
  };

  // State
  const [input, setInput] = useState(stageDetails.stage_name);
  const checkIfRevertRequired = (stageDetails: IGridListStageDetail) =>
    stageDetails.backupName === stageDetails.stage_name ||
    stageDetails.stage_name === '';
  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);
  const [expandedViewForMessageIndex, setExpandedViewForMessageIndex] =
    useState<number | undefined>();
  const onKeyUpEventHandler = async (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (stageDetails?.isNew) {
      if (e.key === 'Enter') {
        if (!localStorage.getItem('isStageAdded')) {
          document
            .getElementById(`${stageDetails.stage_id}_input_field`)
            ?.setAttribute('readonly', 'true');
          localStorage.setItem(
            'isStageAdded',
            stageDetails.stage_id.toString(),
          );

          await submitChange(stageDetails.stage_name, 'Enter');
          document
            .getElementById(`${stageDetails.stage_id}_input_field`)
            ?.removeAttribute('readonly');
          localStorage.removeItem('isStageAdded');
          setInput('');
        }
      }

      if (e.key === 'Escape') {
        submitChange('', 'Escape');
      }
    } else {
      if (e.key === 'Enter') {
        if (checkIfRevertRequired(stageDetails)) {
          revertChange(stageDetails.stage_id, 'Enter');
        } else {
          document
            .getElementById(`${stageDetails.stage_id}_input_field`)
            ?.blur();
        }
      }

      if (e.key === 'Escape') {
        revertChange(stageDetails.stage_id, 'Escape');
      }
    }
  };

  const onChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: getUpdateStageDetails(
            gridListDetails,
            stageDetails,
            e.target.value,
          )!,
        },
      } as IGridList);
    }
  };

  const debounceUpdate = useCallback(debounce(onChangeEventHandler, DURATION), [
    gridListDetails,
  ]);

  const handleFailedOperation = () => {
    if (checkStageList(gridListDetails)) {
      removeEmptyStage();
    }
  };

  const updateProjectState = (data: IGridListWrapper, eventType: string) => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          task_details: data.grid_list_details.task_details,
          block_details: data.grid_list_details.block_details,
          stage_details:
            eventType === eventTypes.ENTER
              ? [
                ...data.grid_list_details.stage_details!,
                {
                  stage_id: newStageId,
                  stage_name: '',
                  stage_rank: newStageRank,
                  section_id: +gridListDetails.section_id,
                  isNew: true,
                  created_by: '',
                },
              ]
              : data.grid_list_details.stage_details,
        },
      });
    }
  };

  const submitChange = async (stageName: string, eventType: string) => {
    if (projectDetails?.project_id) {
      if (stageName?.trim() === '' || !stageName) {
        removeEmptyStage();
      } else {
        try {
          const response: ICreateStageResponse =
            await projectService.createNewGridStage({
              userId: userDetails?.user_id,
              projectId: projectDetails?.project_id,
              organizationId: getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!,
              sectionId: gridListDetails.section_id,
              stageName: stageName.trim(),
            });

          if (response.messageId === 1) {
            updateProjectState(response.data, eventType);
          } else if (response.messageId === -4) {
            fetchNewPermission(
              org_key,
              gridListCmsData?.lbl_error_message_permission_denied,
            );
          } else {
            handleFailedOperation();
          }
        } catch (error) { }
      }
    }
  };
  const updateProjectStateForStageUpdate = (data: IUpdateStageResponseData) => {
    if (gridListDetails) {
      // This only updates the `stage_name` in the grid list
      const newStages = gridListDetails.grid_list_details?.stage_details?.map(
        (i) =>
          i.stage_id === data.stage_details[0].stage_id
            ? { ...i, stage_name: data.stage_details[0].stage_name }
            : i,
      );

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: newStages,
        },
      } as IGridList);
    }
  };

  const updateStage = async (stageName: string, stageId: number) => {
    if (projectDetails?.project_id) {
      try {
        const response: IUpdateStageResponse =
          await projectService.updateGridStage({
            organizationId: getCurrentOrganization(
              org_key,
              userDetails?.organization_info,
            )?.organization_id!,
            userId: userDetails?.user_id,
            projectId: projectDetails?.project_id,
            sectionId: gridListDetails.section_id,
            stageId: stageId,
            stageName: stageName.trim(),
          });

        if (response.messageId === -4) {
          const error = new Error(response?.message);
          (error as any).messageId = response?.messageId;
          throw error;
        }

        if (response.messageId === 1) {
          updateProjectStateForStageUpdate(response.data);
          renameTaskInMessageThread(
            `${SectionTypeMessages.GRID_LIST_STAGE}-${process.env.REACT_APP_STAGE
            }-${getCurrentOrganization(org_key, userDetails?.organization_info)
              ?.organization_id!}-${projectDetails?.project_id}-${gridListDetails.section_id
            }-${stageId}`,
            stageName.trim(),
          );

          let blockDetails =
            gridListDetails.grid_list_details?.block_details!.flat()!;

          let targetBlockList = blockDetails!.filter(
            (ele: IGridListBlockDetail) => ele.stage_id === stageId,
          )!;

          for (let block of targetBlockList) {
            await renameBlockInMessageThread(
              `${SectionTypeMessages.GRID_LIST_BLOCK}-${process.env.REACT_APP_STAGE
              }-${getCurrentOrganization(
                org_key,
                userDetails?.organization_info,
              )?.organization_id!}-${projectDetails?.project_id
              }-${+gridListDetails.section_id}-${block.block_id}`,
              stageName.trim(),
              'stage',
            );
          }
        } else {
          revertChange(stageId, 'Blur');
        }
      } catch (error: any) {
        if (error?.messageId === -4) {
          fetchNewPermission(
            org_key,
            gridListCmsData?.lbl_error_message_permission_denied,
          );
          return;
        }
        revertChange(stageId, 'Blur');
      }
    }
  };

  const filterOutStage = (stageDetails: IGridListStageDetail[]) => {
    let filteredList = stageDetails.filter((ele: any) => !ele.isNew);

    return filteredList.length > 0 ? filteredList : null;
  };

  const removeEmptyStage = () => {
    if (gridListDetails) {
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: gridListDetails.grid_list_details?.stage_details
            ? filterOutStage(gridListDetails.grid_list_details?.stage_details)
            : null,
        },
      } as IGridList);
    }
  };

  const onBlurEventHandler = async (
    e: React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    if (stageDetails?.isNew && stageDetails?.stage_name !== '') {
      submitChange(stageDetails.stage_name, 'Blur');
    } else if (checkIfRevertRequired(stageDetails)) {
      revertChange(stageDetails.stage_id, 'Blur');
    } else {
      updateStage(stageDetails.stage_name, stageDetails.stage_id);
      // Change isEdit = false
      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: gridListDetails.grid_list_details!.stage_details!.map(
            (ele) => {
              ele.isEdit = false;
              return ele;
            },
          ),
        },
      } as IGridList);
    }
  };

  const getUpdateStageDetails = (
    gridListDetails: IGridList,
    stageDetails: IGridListStageDetail,
    newName?: string,
  ) => {
    if (gridListDetails.grid_list_details!.stage_details) {
      let stage = [{ ...stageDetails }];

      if (!stage[0]?.backupName) {
        stage[0].backupName = stage[0].stage_name;
      }

      stage[0].stage_name = newName ?? '';

      let existingStage =
        gridListDetails.grid_list_details!.stage_details.filter(
          (ele: IGridListStageDetail) => ele.stage_id !== stage[0].stage_id,
        );

      return [...existingStage, ...stage].sort(
        (a, b) => a.stage_rank - b.stage_rank,
      );
    }
  };

  const makeFieldEditable = (stageDetails: IGridListStageDetail) => {
    if (
      gridListDetails &&
      hasPermissions(
        [ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_EDIT],
        projectDetails?.associated_role_id,
      )
    ) {
      let stage = [{ ...stageDetails }];
      stage[0].isEdit = true;
      let existingStage =
        gridListDetails.grid_list_details!.stage_details!.filter(
          (ele: IGridListStageDetail) => ele.stage_id !== stage[0].stage_id,
        );

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          stage_details: [...existingStage, ...stage].sort(
            (a, b) => a.stage_rank - b.stage_rank,
          ),
        },
      } as IGridList);
    }
  };
  const handleColumnValuesChange = (changedValues: any, allValues: any) => {
    if (!changedValues?.width) return;
    const newWidth = changedValues?.width ?? allValues?.width;
    let newGridDetails: any = {};
    let customMetaPayload = {};
    setGridListDetails((prev: any) => {
      const newCustomMeta = prev.custom_meta.map((j: any) => {
        if (j?.mapping_id === stageDetails.stage_id) {
          j.tempWidth = newWidth;
          customMetaPayload = j;
        }
        return j;
      });

      newGridDetails = {
        ...prev,
        custom_meta: newCustomMeta,
      };
      return newGridDetails;
    });
  };
  const getStageNameFieldJsx = (stageDetails: IGridListStageDetail) => {
    if (stageDetails.isNew) {
      return (
        <div className="gridStage">
          <span className="borderColor">border</span>
          <input
            type="text"
            id={`${stageDetails.stage_id}_input_field`}
            placeholder={gridListCmsData?.lbl_add_stage_placeholder}
            value={input}
            aria-label="sign up / login"
            onChange={(e) => {
              setInput(e.target.value);
              // Debounce update parent state
              debounceUpdate(e);
            }}
            maxLength={50}
            onKeyUp={(e) => onKeyUpEventHandler(e)}
            onBlur={(e) => onBlurEventHandler(e)}
            autoFocus={stageDetails?.isNew}
          />
        </div>
      );
    }

    if (stageDetails.isEdit) {
      return (
        <>
          <div className="gridStage">
            <span className="borderColor">border</span>
            <input
              type="text"
              id={`${stageDetails.stage_id}_input_field`}
              placeholder={gridListCmsData?.lbl_add_stage_placeholder}
              value={input}
              aria-label="sign up / login"
              onChange={(e) => {
                setInput(e.target.value);
                // Debounce update parent state
                debounceUpdate(e);
              }}
              maxLength={50}
              onKeyUp={(e) => onKeyUpEventHandler(e)}
              onBlur={(e) => onBlurEventHandler(e)}
              autoFocus={stageDetails?.isEdit}
            />
            {!stageDetails?.isNew && (
              <>
                {/* <MessageThread
                componentId="gridStage"
                sectionId={gridListDetails.section_id}
                taskId={stageDetails.stage_id}
                sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                threadName={stageDetails.stage_name}
                sectionName={gridListDetails.section_name!}
                taskDetails={stageDetails}
                setOpenMessageExpandedView={setOpenMessageExpandedView}
                index={index}
                setExpandedViewForMessageIndex={setExpandedViewForMessageIndex}
              /> */}
                <MessageThread
                  componentId="stageListMessaging"
                  isBlockedView
                  sectionId={gridListDetails.section_id}
                  taskId={stageDetails.stage_id}
                  sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                  threadName={stageDetails.stage_name}
                  sectionName={gridListDetails.section_name!}
                  taskDetails={stageDetails}
                />
                {document.getElementById('expandedMessagePopup') &&
                  createPortal(
                    <ExpandedMessagePopup
                      sectionId={gridListDetails.section_id}
                      taskId={stageDetails.stage_id}
                      sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                      threadName={stageDetails.stage_name}
                      sectionName={gridListDetails.section_name!}
                      taskDetails={stageDetails}
                      openMessageExpandedView={openMessageExpandedView}
                      setOpenMessageExpandedView={setOpenMessageExpandedView}
                      isTemplate={false}
                      index={index}
                      expandedViewForMessageIndex={expandedViewForMessageIndex}
                    />,
                    document.getElementById('expandedMessagePopup')!,
                  )}
              </>
            )}
          </div>
          <div className="blkMore">More</div>
        </>
      );
    }

    const isCustomField = `${stageDetails.stage_id}`.includes('_');
    return (
      <>
        {!openMessageExpandedView ? (
          <>
            <Tooltip
              title={
                customMeta?.description
                  ? customMeta?.description
                  : stageDetails.stage_name
              }
              color={'#2E364C'}
              placement="top"
            >
              <div
                onClick={() =>
                  projectDetails?.is_archived === false &&
                  !isCustomField &&
                  makeFieldEditable(stageDetails)
                }
                onKeyDown={() => { }}
                className="gridStage"
                style={{ width: width ?? '100%' }}
              >
                <span className="borderColor">border</span>
                <span className="blkTxt">{stageDetails.stage_name}</span>
                {/* !ele?.isNew */}
                {!stageDetails?.isNew && (
                  <MessageThread
                    componentId="stageListMessaging"
                    isBlockedView
                    sectionId={gridListDetails.section_id}
                    taskId={stageDetails.stage_id}
                    sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                    threadName={stageDetails.stage_name}
                    sectionName={gridListDetails.section_name!}
                    taskDetails={stageDetails}
                  />
                )}
              </div>
            </Tooltip>
            {!stageDetails?.isNew && projectDetails?.is_archived === false && (
              <Fragment>
                {/* <TaskProgressBar taskDetails={ele} /> */}
                {isCustomField ? (
                  <Rbac
                    allowedPermissions={[
                      ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_CUSTOM_SETTING_VIEW,
                      ERbacPermissions.PROJECT_SECTION_TASK_CUSTOM_FIELD_SETTING_VIEW,
                    ]}
                    project_role_id={projectDetails?.associated_role_id}
                  >
                    <ActionDropdown
                      customMeta={customMeta}
                      updateCustomMeta={updateCustomMeta}
                      onDeleteOptions={(deleteOptions) => {
                        let tempDelRef = deleteOptionsRef?.current ?? [];
                        const { color, name } = deleteOptions[0];
                        let deletedField = {
                          value: name,
                          mapping_id: stageDetails?.stage_id,
                        };

                        tempDelRef.push(deletedField);
                        if (deleteOptionsRef) {
                          deleteOptionsRef.current = tempDelRef;
                        }
                      }}
                      onClose={() => {
                        if (deleteOptionsRef) {
                          deleteOptionsRef.current = null;
                        }
                      }}
                      onDelete={deleteCustomField}
                      onChange={handleColumnValuesChange}
                    />
                  </Rbac>
                ) : (
                  <Rbac
                    allowedPermissions={[
                      ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_DELETE,
                    ]}
                    project_role_id={projectDetails?.associated_role_id}
                  >
                    <StageDelete
                      stageDetails={stageDetails}
                      gridListDetails={gridListDetails}
                      setGridListDetails={setGridListDetails}
                    />
                  </Rbac>
                )}
              </Fragment>
            )}
          </>
        ) : (
          <div
            onClick={() =>
              projectDetails?.is_archived === false &&
              isCustomField &&
              makeFieldEditable(stageDetails)
            }
            onKeyDown={() => { }}
            className="gridStage"
          >
            <span className="borderColor">border</span>
            <span className="blkTxt">{stageDetails.stage_name}</span>
            {/* !ele?.isNew */}
            {!stageDetails?.isNew && (
              <MessageThread
                componentId="stageListMessaging"
                isBlockedView
                sectionId={gridListDetails.section_id}
                taskId={stageDetails.stage_id}
                sectionType={SectionTypeMessages.GRID_LIST_STAGE}
                threadName={stageDetails.stage_name}
                sectionName={gridListDetails.section_name!}
                taskDetails={stageDetails}
              />
            )}
            {!stageDetails?.isNew && projectDetails?.is_archived === false && (
              <Fragment>
                {/* <TaskProgressBar taskDetails={ele} /> */}
                {isCustomField ? (
                  <ActionDropdown
                    customMeta={customMeta}
                    updateCustomMeta={updateCustomMeta}
                    onDeleteOptions={(deleteOptions) => {
                      let tempDelRef = deleteOptionsRef?.current ?? [];
                      const { color, name } = deleteOptions[0];
                      let deletedField = {
                        value: name,
                        mapping_id: stageDetails?.stage_id,
                      };

                      tempDelRef.push(deletedField);
                      if (deleteOptionsRef) {
                        deleteOptionsRef.current = tempDelRef;
                      }
                    }}
                    onClose={() => {
                      if (deleteOptionsRef) {
                        deleteOptionsRef.current = null;
                      }
                    }}
                    onChange={handleColumnValuesChange}
                  />
                ) : (
                  <Rbac
                    allowedPermissions={[
                      ERbacPermissions.PROJECT_SECTION_GRIDLIST_TASK_DELETE,
                    ]}
                    project_role_id={projectDetails?.associated_role_id}
                  >
                    <StageDelete
                      stageDetails={stageDetails}
                      gridListDetails={gridListDetails}
                      setGridListDetails={setGridListDetails}
                    />
                  </Rbac>
                )}
              </Fragment>
            )}
          </div>
        )}
      </>
    );
  };

  return getStageNameFieldJsx(stageDetails);
};

export default StageNameInputField;
