import { create } from 'zustand';
import { ILoaderState } from '../models/interface';

export const useLoaderStore = create<ILoaderState>((set) => ({
    //initial states
    loaderState: 'inactive',
    message: null,

    //actions
    setLoaderState: (value: 'active' | 'inactive') => set({ loaderState: value }),
    setMessage: (value: string | null) => set({ message: value }),
}))