import { FC, useState } from "react";

import StageList from "./StageList";
import { IGridList, IProjectTemplate, ITaskList } from "models/interface";

const StageListWrapper: FC<{
  sectionDetails: IGridList | ITaskList;
  checkStageList: (sectionDetails: IGridList | ITaskList) => boolean;
  templateDetails: IProjectTemplate;
}> = ({ sectionDetails, checkStageList, templateDetails }) => {

  const [stageName, setStageName] = useState<string>("");

  const getStageListBodyJsx = () => {
    return checkStageList(sectionDetails) ? (
      <StageList
        sectionDetails={sectionDetails}
        stageName={stageName}
        setStageName={setStageName}
        templateDetails={templateDetails}
      />
    ) : null;
  };

  return getStageListBodyJsx();
};

export default StageListWrapper;
