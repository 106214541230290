import { FC, useEffect, useState } from "react";
import {
  IEmptyProjectDashboardAttributes,
  IProjectMember,
  IRoleList,
} from "models/interface";
import UserAvatar from "./UserAvatar";
import { generateUserAvatar } from "utils/commonFunctions";
import UserDetails from "./UserDetails";
import { Tag } from "antd";
import OutsideClickHandler from "components/sharedComponents/OutsideClickHandler";

const DisplayProjectMember: FC<{
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  pageSpecificationData: IEmptyProjectDashboardAttributes;
  projectMembers: IProjectMember[];
  setShowProjectMemberDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSettingDropDown: React.Dispatch<React.SetStateAction<boolean>>;
  showProjectMemberDropdown: boolean;
}> = ({
  pageSpecificationData,
  projectMembers,
  setShowProjectMemberDropdown,
  showProjectMemberDropdown,
  setShowSettingDropDown,
  setShowDropdown,
}) => {
  const getRoleName = (roleId: number | undefined, roleList: IRoleList[]) => {
    if (roleList.length > 0 && roleId !== undefined) {
      return roleList.find((ele) => ele.role_id === roleId)?.role_name;
    } else {
      return "";
    }
  };

  const getProjectMemberGravatarClassName = (ele: IProjectMember) => {
    if (!ele.inactive) {
      return generateUserAvatar(ele).key === "url" ? "userAvtrImg" : "";
    }
    return "userAvtrBlank";
  };

  const getProjectMemberGravatarJsx = (ele: IProjectMember) => {
    if (ele.inactive) {
      return null;
    }
    return generateUserAvatar(ele).key === "string" ? (
      generateUserAvatar(ele).value
    ) : (
      <img src={generateUserAvatar(ele).value} alt="gravatar" />
    );
  };

  return (
    <div className="memberPanelDropdownArea">
      <div
        className="membersPanel"
        onClick={() => {
          setShowProjectMemberDropdown(!showProjectMemberDropdown);
          setShowSettingDropDown(false);
          setShowDropdown(false);
        }}
        onKeyDown={() => {}}
      >
        {[...projectMembers.slice(0, 4)].map((ele: IProjectMember) => (
          <UserAvatar
            key={ele?.user_id}
            projectMember={ele}
            showProjectMemberDropdown={showProjectMemberDropdown}
          />
        ))}
        {projectMembers.length > 4 && (
          <div className="lastItem">+{projectMembers.length - 4}</div>
        )}
      </div>
      {showProjectMemberDropdown ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowProjectMemberDropdown(!showProjectMemberDropdown);
            setShowSettingDropDown(false);
            setShowDropdown(false);
          }}
        >
          <div
            className={`membersList ${showProjectMemberDropdown ? "show" : ""}`}
          >
            <div className="memberInviteTable">
              <div className="tableHeading">
                <h5>
                  {pageSpecificationData?.lbl_project_member_dropdown_header}
                </h5>
              </div>
              <div className="memberInviteTableHolder">
                <table aria-describedby="MemberInviteTable">
                  <thead>
                    <th></th>
                    <th></th>
                    <th></th>
                  </thead>
                  <tbody>
                    {projectMembers.map((ele: IProjectMember) => (
                      <tr key={ele.user_id}>
                        <td>
                          <div className="memberRoleAction">
                            <div className="userBlkWrap">
                              <div
                                className={`userAvtr ${getProjectMemberGravatarClassName(
                                  ele
                                )}`}
                              >
                                {getProjectMemberGravatarJsx(ele)}
                              </div>
                              <div className="userName-details">
                                <UserDetails
                                  projectMember={ele}
                                  pageSpecificationData={pageSpecificationData}
                                />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          {ele.inactive ? (
                            <Tag color="#ba1a1a">
                              {pageSpecificationData.lbl_inactive_members}
                            </Tag>
                          ) : null}
                        </td>
                        <td>
                          <div className="memberRole">
                            <p>
                              {getRoleName(
                                ele.role_id,
                                pageSpecificationData.project_member_role_list
                              )}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DisplayProjectMember;
