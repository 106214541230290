import { FC } from "react";

import {
  ICustomFieldState
} from "models/interface";
import RightSideDrawerForCustomFieldAddition from "components/sharedComponents/RightSideDrawerForCustomFieldAddition/RightSideDrawerForCustomFieldAddition";

const CreateCustomField: FC<{
  pageSpecificDataState?: any | null;
  showSidePanel: boolean;
  setShowSidePanel: React.Dispatch<React.SetStateAction<ICustomFieldState>>;
  selectedTemplate: any;
  fieldMetaData: any;
  getSectionDetails: Function
}> = ({
  pageSpecificDataState,
  showSidePanel,
  setShowSidePanel,
  selectedTemplate,
  fieldMetaData,
  getSectionDetails
}) => {


    return (
      <RightSideDrawerForCustomFieldAddition
        pageSpecificDataState={pageSpecificDataState}
        showSidePanel={showSidePanel}
        setShowSidePanel={setShowSidePanel}
        fieldMetaData={fieldMetaData}
        getSectionDetails={getSectionDetails}
      />
    );
  };

export default CreateCustomField;
