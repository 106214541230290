import { FC } from "react";
import TaskList from "./TaskList";
import { IGridList, IProjectTemplate } from "models/interface";

const TaskListWrapper: FC<{
  sectionDetails: IGridList;
  templateDetails: IProjectTemplate;
}> = ({ sectionDetails, templateDetails }) => {

  const getTaskListBodyJsx = () => {
    return (
      <TaskList
        sectionDetails={sectionDetails}
        templateDetails={templateDetails}
      />
    )
  };

  return getTaskListBodyJsx();
};

export default TaskListWrapper;
