import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { db, storage } from 'utils/firebase';
import { Dropdown, Menu, MenuProps, Popconfirm, Popover, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import cryptoRandomString from 'crypto-random-string';
import {
  useMessagingStore,
  useProjectDashboardStore,
  useUserStore,
  useAllThreadsMessageDetailsStore,
  useAllThreadUnreadIndicatorStore,
} from 'stores';
import {
  IAddTaggedMemberThread,
  IProjectMember,
  IProjectState,
} from 'models/interface';
import {
  addOverlay,
  formatMessageItems,
  generateUserAvatar,
  getCurrentOrganization,
  getDateFormat,
  modifyMessageString,
  removeOverlay,
} from 'utils/commonFunctions';
import Editor from 'components/sharedComponents/Editor/Editor';
import { deleteObject, ref } from '@firebase/storage';
import { messagingService, projectService } from 'services';
import { SectionTypeMessages } from 'models/enums/messages';
import BrowserStorageService from '../../../../../services/browserStorage.service';
import { TooltipPlacement } from 'antd/es/tooltip';
import dayjs from 'dayjs';
import { getMessageThreadCount } from 'utils/firebaseCommonFunctions';
import removedUser from '../../../../../assets/images/removed-user.png';
const { SubMenu } = Menu;

enum MessageThreadOption {
  EDIT_MESSAGE = 'edit_message',
  DELETE_MESSAGE = 'delete_message',
}

const adminUser = {
  full_name: process.env.REACT_APP_CLEVER_SORT_USER_NAME,
  user_id: Number(process.env.REACT_APP_CLEVER_SORT_USER_ID) || -1,
};

interface IMessageInfo {
  is_visible: boolean;
  count: number;
}
interface MessageThreadPayload {
  count: number;
  messageThreadId: string;
  shouldUpdate: boolean;
  isUserStoryThread?: boolean;
}

interface ICreateMessageThreadPayLoad {
  created_at: Timestamp;
  updated_at: Timestamp;
  project_id: number | undefined;
  section_id: string | number;
  organization_id: string | undefined;
  member_id: any;
  all_members_id: any;
  task_name: string;
  project_name: string | undefined;
  is_done: boolean;
  is_deleted: boolean;
  messages: ReturnType<typeof arrayUnion>;
  is_usg_thread: boolean;
  epic?: string;
  storyTitle?: string;
}

const MessageThread: React.FC<{
  componentId?: string;
  sectionType: string;
  sectionId: number | string;
  taskId: number | string;
  blockId?: number;
  threadName: string;
  sectionName: string;
  taskDetails: any;
  projectId?: number;
  projectName?: string;
  orgId?: number;
  teamMember?: IProjectMember[];
  placement?: TooltipPlacement | undefined;
  isBlockedView?: boolean;
  compactView?: boolean;
  isTemplate?: boolean;
  templateDetails?: any;
  currentSection?:
    | {
        sectionId: number;
        popup: 'assignee' | 'dueDate' | 'message';
      }
    | undefined;
  setCurrentSection?: React.Dispatch<
    React.SetStateAction<
      | {
          sectionId: number;
          popup: 'assignee' | 'dueDate' | 'message';
        }
      | undefined
    >
  >;
  isDashBoardOrPlanner?: boolean;
  isEditorEnabled?: boolean;
  isUserStoryThread?: boolean;
  epicName?: string;
}> = ({
  componentId,
  sectionType,
  sectionId,
  taskId,
  blockId,
  threadName,
  sectionName,
  taskDetails,
  projectId,
  projectName,
  orgId,
  teamMember,
  placement = 'bottomRight',
  isBlockedView = false,
  compactView = false,
  isTemplate = false,
  templateDetails,
  currentSection,
  setCurrentSection,
  isDashBoardOrPlanner = false,
  isUserStoryThread = false,
  isEditorEnabled = true,
  epicName,
}) => {
  const refDiv = useRef<HTMLDivElement>(null),
    messagePopupRef = useRef<HTMLDivElement>(null),
    messageBodyRef = useRef<HTMLDivElement>(null);

  const [showMessagesPopup, setShowMessagesPopup] = useState(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [messageId, setMessageId] = useState<string>('');
  const [messageContent, setMessageContent] = useState<string>('');
  const [messages, setMessages] = useState<any>([]);
  const [messageThreadMembers, setMessageThreadMembers] = useState([]);
  const [openPopover, setOpenPopover] = useState<{
    [key: string]: boolean;
  } | null>(null);
  const [messageInfo, setMessageInfo] = useState<IMessageInfo>({
    is_visible: false,
    count: 0,
  });
  const [isExpandedView, setIsExpandedView] = useState<boolean>();

  const [uniqueId, setUniqueId] = useState<string>(''),
    [
      ifClickedOnTextFieldAfterSelectDelete,
      setIfClickedOnTextFieldAfterSelectDelete,
    ] = useState<boolean>(false);

  const { projectMembers, projectDetails, messagingCmsData } =
      useProjectDashboardStore((state: IProjectState) => state),
    { unReadMessageDetails, setUnReadMessageDetails } = useMessagingStore(
      (state: any) => state,
    ),
    { allThreadsMessageDetails, setAllThreadMessageDetails } =
      useAllThreadsMessageDetailsStore((state: any) => state),
    {
      allThreadUnreadIndicator,
      updateAllThreadUnreadIndicator,
      userMessageList,
    } = useAllThreadUnreadIndicatorStore((state: any) => state);

  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const browserStorage = BrowserStorageService.getInstance();
  const org_key = location.pathname.split('/')[2];
  const currentOrganization = getCurrentOrganization(
    org_key,
    userDetails?.organization_info,
  );
  const expandedViewClass = isExpandedView ? 'addToggle' : '';

  const messageThreadCollectionID =
    process.env.REACT_APP_MESSAGE_THREAD_ID || 'message-thread';

  const exClassName = isExpandedView
    ? `createNewProjectForm messageThreadExpandedDrawer ${expandedViewClass}`
    : '';

  let stageENV = process.env.REACT_APP_STAGE;

  let messageThreadId: string;
  if (isTemplate) {
    messageThreadId = `${sectionType}-${stageENV}-CS-${templateDetails?.template_id}-${sectionId}-${taskId}`;
  } else {
    const environment = stageENV === 'local' ? 'dev' : stageENV;
    const organizationId = orgId ?? currentOrganization?.organization_id;
    const projectID = projectId ?? projectDetails?.project_id;
    const blockOrTaskId = blockId ?? taskId;

    messageThreadId = `${sectionType}-${environment}-${organizationId}-${projectID}-${sectionId}-${blockOrTaskId}`;
  }
  const isCompactView = compactView;

  const [isMessageCountExist, setIsMessageCountExist] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const count = await getMessageThreadCount(messageThreadId);
      if (count > 0) {
        setIsMessageCountExist(true);
      } else {
        setIsMessageCountExist(false);
      }
    })();
  }, [isMessageCountExist]);

  const getTaggedUserId = (data: any[]) => data;

  const saveReadMessageCountByUser = async (
    count: number,
    messageThreadId: string,
    shouldUpdate: boolean = false,
  ) => {
    let payload: MessageThreadPayload = {
      count: count,
      messageThreadId: messageThreadId,
      shouldUpdate: shouldUpdate,
    };
    if (isUserStoryThread) {
      payload.isUserStoryThread = true;
    }

    await messagingService.saveReadMessageCountByUser(payload);
  };

  const sendMessage = async (
    data: string,
    parsedData: any,
    attachmentUrls: any[],
  ) => {
    const docRef = doc(db, messageThreadCollectionID, messageThreadId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      await saveReadMessageCountByUser(
        docSnap.data().messages.length + 1,
        messageThreadId,
        true,
      );
    } else {
      await saveReadMessageCountByUser(1, messageThreadId, true);
    }

    if (messageId === undefined || messageId === '') {
      if (!docSnap.exists()) {
        let createMessageThreadPayload: ICreateMessageThreadPayLoad = {
          created_at: Timestamp.now(),
          updated_at: Timestamp.now(),
          project_id: projectId ?? projectDetails?.project_id,
          section_id: sectionId,
          organization_id: currentOrganization?.organization_id,
          member_id: generateMemberDetails(),
          all_members_id: generateMemberIds(),
          task_name: isUserStoryThread ? `USG | ${threadName}` : threadName,
          project_name: projectName ?? projectDetails?.project_name,
          is_done: taskDetails?.task_status_id === 3,
          is_deleted: false,
          messages: arrayUnion({
            message_id: cryptoRandomString({ length: 10 }),
            attachments: attachmentUrls,
            tagged_user_id: getTaggedUserId(parsedData),
            user_id: userDetails.user_id,
            content: modifyMessageString(data),
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            is_delete: false,
            is_edited: false,
            is_archive: false,
          }),
          is_usg_thread: false,
        };

        if (isUserStoryThread) {
          createMessageThreadPayload.epic = epicName;
          createMessageThreadPayload.is_usg_thread = true;
          createMessageThreadPayload.storyTitle = sectionName;
        }

        await setDoc(
          doc(db, messageThreadCollectionID, messageThreadId),
          createMessageThreadPayload,
        );

        let memberPayLoad: IAddTaggedMemberThread = {
          taggerMessageThreadId: messageThreadId,
          taggedMembersUserIds: parsedData,
          readMessageCount: 0,
          isUserStoryThread: isUserStoryThread,
        };
        if (parsedData.length > 0) {
          await messagingService.addTaggedMemberThread(memberPayLoad);
        }
      } else {
        let UpdateMessageThreadPayload: {
          member_id: ReturnType<typeof generateMemberDetails>;
          all_members_id: ReturnType<typeof generateMemberIds>;
          messages: ReturnType<typeof arrayUnion>;
          task_name: string;
          project_name?: string;
          organization_id?: string;
          is_done: boolean;
          updated_at: Timestamp;
          is_usg_thread: boolean;
          epic?: string;
          storyTitle?: string;
        } = {
          member_id: generateMemberDetails(),
          all_members_id: generateMemberIds(),
          messages: arrayUnion({
            message_id: cryptoRandomString({ length: 10 }),
            attachments: attachmentUrls,
            tagged_user_id: getTaggedUserId(parsedData),
            user_id: userDetails.user_id,
            content: modifyMessageString(data),
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            is_delete: false,
            is_edited: false,
            is_archive: false,
          }),
          task_name: isUserStoryThread ? `USG | ${threadName}` : threadName,
          project_name: projectName ?? projectDetails?.project_name,
          organization_id: currentOrganization?.organization_id,
          is_done: taskDetails?.task_status_id === 3,
          updated_at: Timestamp.now(),
          is_usg_thread: false,
        };

        if (isUserStoryThread) {
          UpdateMessageThreadPayload.epic = epicName;
          UpdateMessageThreadPayload.is_usg_thread = true;
          UpdateMessageThreadPayload.storyTitle = sectionName;
        }

        await updateDoc(
          doc(db, messageThreadCollectionID, messageThreadId),
          UpdateMessageThreadPayload,
        );

        let memberPayLoad: IAddTaggedMemberThread = {
          taggerMessageThreadId: messageThreadId,
          taggedMembersUserIds: parsedData,
          readMessageCount: 1,
          isUserStoryThread: isUserStoryThread,
        };
        if (parsedData.length > 0) {
          await messagingService.addTaggedMemberThread(memberPayLoad);
        }
      }
    } else {
      let document = await getDoc(
        doc(db, messageThreadCollectionID, messageThreadId),
      );
      let messages = document.data()!.messages;
      let props = messages.find((ele: any) => ele.message_id === messageId);
      let existingAttachments = [...props.attachments];

      props.content = modifyMessageString(data);

      props.updated_at = Timestamp.now();
      props.tagged_user_id = getTaggedUserId(parsedData);
      props.attachments = attachmentUrls;
      props.is_edited = true;

      await updateDoc(doc(db, messageThreadCollectionID, messageThreadId), {
        messages: messages,
        updated_at: Timestamp.now(),
        is_done: taskDetails?.task_status_id === 3,
      });

      existingAttachments
        .filter(
          (eleOuter: any) =>
            !attachmentUrls.map((ele) => ele.url).includes(eleOuter.url),
        )
        .forEach((ele) => {
          const desertRef = ref(storage, ele.url);
          deleteObject(desertRef);
        });

      cancelEditing();
      localStorage.removeItem('currentMessageId');
    }

    if (getTaggedUserId(parsedData)!?.length > 0) {
      sendNotification(getTaggedUserId(parsedData)!, data);
    }
  };
  const sendNotification = async (taggedUsers: number[], data: any) => {
    try {
      await projectService.sendMentionNotification({
        users: taggedUsers.filter((e) => e !== userDetails.user_id),
        senderName: userDetails.full_name ?? userDetails.email,
        projectName: projectName ?? projectDetails?.project_name,
        orgKey: org_key,
        teamName: getCurrentOrganization(org_key, userDetails.organization_info)
          ?.organization_name!,
        taskName: threadName,
        sectionName: sectionName,
        projectId: projectId ?? projectDetails?.project_id,
        organizationId: getCurrentOrganization(
          org_key,
          userDetails.organization_info,
        )?.organization_id!,
        messageContent: data,
        profilePicture: userDetails?.profile_picture,
        date: dayjs().format('MMM D, YYYY'),
        time: dayjs().format('h:mma'),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getMemberDetails = (userId: number) => {
    if (userId) {
      const memberData = teamMember ?? projectMembers;
      return memberData?.filter((ele) => ele.user_id === userId)[0];
    }
  };

  const generateMemberDetails = () => {
    const memberData = teamMember ?? projectMembers;
    return memberData?.map((ele) => {
      return {
        user_id: ele.user_id,
        user_name: ele.full_name,
      };
    });
  };

  const generateMemberIds = () => {
    const memberData = teamMember ?? projectMembers;
    return memberData?.map((ele) => ele.user_id);
  };

  const isAdminMessage = (user_id: number) => {
    return user_id === adminUser.user_id;
  };

  const getUser = (user_id: number) => {
    if (isAdminMessage(user_id)) {
      return adminUser;
    } else {
      return getMemberDetails(user_id);
    }
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === MessageThreadOption.EDIT_MESSAGE) {
      setMessageId(localStorage.getItem('currentMessageId')!);
      editMessage(localStorage.getItem('currentMessageId')!);
      setEditing(true);
    }
  };

  const editMessage = async (messageId: string) => {
    let data = await getDoc(
      doc(db, messageThreadCollectionID, messageThreadId),
    );
    let messages = data.data()!.messages;

    let text = messages.find(
      (ele: any) => ele.message_id === messageId,
    ).content;
    setMessageContent(text);
  };

  const deleteMessage = async (messageId: string) => {
    let data = await getDoc(
      doc(db, messageThreadCollectionID, messageThreadId),
    );
    let messages = data.data()!.messages;

    let props = messages.find((ele: any) => ele.message_id === messageId);

    props.updated_at = Timestamp.now();
    props.is_delete = true;

    await updateDoc(doc(db, messageThreadCollectionID, messageThreadId), {
      messages: messages,
      updated_at: Timestamp.now(),
    });
    setOpenPopover(openPopover ? { ...openPopover, [messageId]: false } : null);
  };

  const showUnreadIndicator = (messages: any) => {
    if (messages.length > 0) {
      let taggedUsers = messages.map((ele: any) => ele.tagged_user_id).flat();

      let uniqueTaggedUsers = [...new Set(taggedUsers)];

      if (
        sectionType === SectionTypeMessages.GRID_LIST_TASK ||
        sectionType === SectionTypeMessages.GRID_LIST_STAGE
      ) {
        return (
          taskDetails.created_by === userDetails.user_id ||
          uniqueTaggedUsers.includes(userDetails.user_id)
        );
      } else {
        return (
          taskDetails.created_by === userDetails.user_id ||
          taskDetails.assignee_id === userDetails.user_id ||
          uniqueTaggedUsers.includes(userDetails.user_id)
        );
      }
    }
    return false;
  };

  const getUserName = (userId: number, ele?: any) => {
    const userNameLength = isExpandedView ? 45 : 13;
    if (isAdminMessage(userId)) {
      return <span className="userNameElement">{adminUser.full_name}</span>;
    } else {
      let userName = getMemberDetails(userId)?.full_name
        ? getMemberDetails(userId)?.full_name
        : getUserThreadDetails(ele)?.name;
      if (userName) {
        if (userName?.length <= userNameLength) {
          return <span className="userNameElement">{userName}</span>;
        } else {
          return (
            <Tooltip
              title={userName}
              color={'#2E364C'}
              placement="right"
              className={uniqueId}
            >
              <span className="userNameElement">
                {`${userName.substring(0, userNameLength)}...`}
              </span>
            </Tooltip>
          );
        }
      }
    }
  };
  const removeMessageOverlay = () => {
    if (isExpandedView === true) {
      setIsExpandedView(false);
      setShowMessagesPopup(false);
      setShowEmojiPicker(false);
      cancelEditing();
      cancelDeleting();
      removeOverlay();
    }
  };
  const closeMessagePopup = () => {
    if (Boolean(isExpandedView) === false) {
      setShowMessagesPopup(false);
      setShowEmojiPicker(false);
      cancelEditing();
      cancelDeleting();
    }
  };

  const toggleMessagePopup = () => {
    if (showMessagesPopup) {
      closeMessagePopup();
    } else {
      if (teamMember && !isBlockedView) {
        browserStorage.setProjectMembers(JSON.stringify(teamMember));
      }
      setTimeout(() => {
        setShowMessagesPopup(true);
      }, 200);
    }
  };

  const getUserThreadDetails = (ele: any) => {
    if (
      messageThreadMembers &&
      messageThreadMembers.find((itm: any) => itm.user_id === ele.user_id) !==
        undefined
    ) {
      let user: any = messageThreadMembers.find(
        (itm: any) => itm.user_id === ele.user_id,
      );
      if (user) {
        return { name: user.user_name, removedStatus: user.removed };
      }
    }
  };

  // const closeMessagePopupClickingOutside = (e: MouseEvent) => {
  //   if (!messagePopupRef.current?.contains(e.target as Node)) {
  //       removeMessageOverlay()
  //       closeMessagePopup();
  //   }
  // };

  const cancelEditing = () => {
    setMessageId('');
    setMessageContent('');
    setEditing(false);
  };
  const cancelDeleting = () => {
    localStorage.removeItem('currentMessageId');

    if (openPopover) {
      const tempOpenPopover = { ...openPopover };

      for (let key in tempOpenPopover) {
        if (tempOpenPopover.hasOwnProperty(key)) {
          tempOpenPopover[key] = false;
        }
      }

      setOpenPopover(tempOpenPopover);
    } else {
      setOpenPopover(null);
    }
  };

  // Logic to handle modal close on outside click of modal
  // useEffect(() => {
  //   document.addEventListener("click", closeMessagePopupClickingOutside);
  //   return () =>
  //     document.removeEventListener("click", closeMessagePopupClickingOutside);
  // }, []);

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        removeMessageOverlay();
        closeMessagePopup();
      }
    };
    window.addEventListener('keydown', handleEscKeyPress);

    let messageString = isTemplate
      ? `message-thread-admin`
      : messageThreadCollectionID;

    const unSub = onSnapshot(doc(db, messageString, messageThreadId), (doc) => {
      if (doc.exists()) {
        setMessages(doc.data().messages);
        setMessageThreadMembers(doc.data().member_id);
      }
    });

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
      unSub();
      removeOverlay();
    };
  }, []);

  useEffect(() => {
    if (isExpandedView === false) {
      removeOverlay();
    }
    if (isExpandedView === true) {
      refDiv.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [isExpandedView]);

  useEffect(() => {
    if (messageThreadId && allThreadUnreadIndicator) {
      const tempThreadUnreadIndicator = allThreadUnreadIndicator
        ? allThreadUnreadIndicator[messageThreadId]
        : null;
      if (tempThreadUnreadIndicator) {
        setMessageInfo({ ...tempThreadUnreadIndicator });
      }
    }
  }, [messageThreadId, allThreadUnreadIndicator]);

  useEffect(() => {
    if (messages.length) {
      refDiv.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });

      let tempEl = {};
      messages.forEach((el: any) => {
        tempEl = { ...tempEl, [el.message_id]: false };
      });

      setOpenPopover(tempEl);
    } else {
      setOpenPopover(null);
    }
  }, [messages]);

  useEffect(() => {
    setUniqueId(
      `${componentId}_${taskDetails?.org_id}_${taskDetails?.task_id}_${taskDetails?.block_id}`,
    );
  }, [taskDetails, componentId]);

  // Logic to handle cases if thread has already been opened and a new message comes
  useEffect(() => {
    if (showMessagesPopup) {
      saveReadMessageCountByUser(messages.length, messageThreadId);

      if (allThreadsMessageDetails?.length) {
        const tempAllThreadsMessageDetails = allThreadsMessageDetails.map(
          (msg: any) => {
            if (msg.message_thread_id === messageThreadId) {
              return {
                ...msg,
                is_pinned: false,
                read_message_count: messages.length,
              };
            }
            return msg;
          },
        );

        setAllThreadMessageDetails(tempAllThreadsMessageDetails);
      } else {
        setAllThreadMessageDetails([
          {
            is_pinned: false,
            message_thread_id: messageThreadId,
            read_message_count: messages.length,
          },
        ]);
      }

      updateAllThreadUnreadIndicator(messageThreadId, {
        is_visible: false,
        count: messages.length,
      });
    }
  }, [messages, showMessagesPopup]);

  // Logic to get unread message state
  useEffect(() => {
    if (messageThreadId && messages?.length) {
      const detailsForSelectedThread = allThreadsMessageDetails.find(
        (thread: any) => thread.message_thread_id === messageThreadId,
      );

      // logic to show red dot
      if (!detailsForSelectedThread) {
        const totalDeletedMessages: any =
          messages.find((el: any) => el.is_delete) ?? [];

        if (totalDeletedMessages.length === messages.length) {
          updateAllThreadUnreadIndicator(messageThreadId, {
            is_visible: false,
            count: messages.length,
          });

          // if displayed thread is new, then update setAllThreadMessageDetails with thread id and count with length of messages
          setAllThreadMessageDetails([
            ...allThreadsMessageDetails,
            {
              is_pinned: false,
              message_thread_id: messageThreadId,
              read_message_count: 0,
            },
          ]);
          return;
        }

        // if displayed thread is new, then update setAllThreadMessageDetails with thread id and count as 0
        setAllThreadMessageDetails([
          ...allThreadsMessageDetails,
          {
            is_pinned: false,
            message_thread_id: messageThreadId,
            read_message_count: 0,
          },
        ]);

        const messageExceptUser: any = messages
          .map((elem: any, index: number) => {
            if (elem.user_id !== userDetails.user_id && !elem.is_delete) {
              return elem;
            }
          })
          ?.filter((el: any) => el !== undefined);

        if (messageExceptUser?.length) {
          updateAllThreadUnreadIndicator(messageThreadId, {
            is_visible: true,
            count: messages.length,
          });
        }
        return;
      }

      if (detailsForSelectedThread.read_message_count < messages.length) {
        const newMessageList: any = messages
          .map((elem: any, index: number) => {
            if (
              index >= detailsForSelectedThread.read_message_count &&
              elem.user_id !== userDetails.user_id &&
              !elem.is_delete
            ) {
              return elem;
            }
          })
          ?.filter((el: any) => el !== undefined);

        if (newMessageList?.length) {
          updateAllThreadUnreadIndicator(messageThreadId, {
            is_visible: true,
            count: messages.length,
          });
          return;
        }

        updateAllThreadUnreadIndicator(messageThreadId, {
          is_visible: false,
          count: messages.length,
        });
        return;
      }

      updateAllThreadUnreadIndicator(messageThreadId, {
        is_visible: false,
        count: messages.length,
      });
      return;
    }
  }, [messages]);

  useEffect(() => {
    if (
      messageThreadId &&
      messages?.length &&
      (isCompactView ||
        ([
          SectionTypeMessages.GRID_LIST_STAGE,
          SectionTypeMessages.GRID_LIST_BLOCK,
          SectionTypeMessages.GRID_LIST_TASK,
        ].includes(sectionType as SectionTypeMessages) &&
          componentId &&
          ['gridBlk', 'gridStage', 'gridTask'].includes(componentId)))
    ) {
      setAllThreadMessageDetails([...allThreadsMessageDetails]);
    }
  }, [messages]);

  // Update state and DB when opening the message box
  useEffect(() => {
    if (showMessagesPopup && messageInfo?.is_visible) {
      const detailsForSelectedThread = allThreadsMessageDetails.find(
        (thread: any) => thread.message_thread_id === messageThreadId,
      );

      if (
        allThreadUnreadIndicator[messageThreadId]?.is_user_thread &&
        userMessageList.find(
          (thread: any) => thread.threadId === messageThreadId,
        )
      ) {
        let updatedMessageCount =
          unReadMessageDetails.count -
          (messageInfo.count - detailsForSelectedThread.read_message_count);
        updatedMessageCount =
          updatedMessageCount >= 0 ? updatedMessageCount : 0;

        setUnReadMessageDetails({
          count: updatedMessageCount,
          shouldVisible: Boolean(updatedMessageCount),
        });
      }

      saveReadMessageCountByUser(messageInfo?.count ?? 0, messageThreadId);
      updateAllThreadUnreadIndicator(messageThreadId, {
        is_visible: false,
        count: messages.length,
      });
    }
  }, [showMessagesPopup]);

  // Scroll to the bottom of message body
  useEffect(() => {
    if (showMessagesPopup && messageBodyRef.current) {
      messageBodyRef.current.scrollTo(0, messageBodyRef.current.scrollHeight);
    }
    if (setCurrentSection && taskId) {
      if (showMessagesPopup === true) {
        setCurrentSection({
          sectionId: Number(taskId),
          popup: 'message',
        });
      }
    }
  }, [showMessagesPopup]);

  useEffect(() => {
    if (openPopover !== null && openPopover[messageId] === true) {
      setIfClickedOnTextFieldAfterSelectDelete(false);
      const elem: HTMLDivElement | null = document.querySelector(
        `#${uniqueId} .tiptap.ProseMirror`,
      );
      if (elem) {
        elem?.addEventListener('click', () =>
          setIfClickedOnTextFieldAfterSelectDelete(true),
        );
        if (editing) {
          elem.innerText = '';
          cancelEditing();
        }
      }
    }
  }, [openPopover]);

  useEffect(() => {
    if (ifClickedOnTextFieldAfterSelectDelete) {
      cancelDeleting();
    }
  }, [ifClickedOnTextFieldAfterSelectDelete]);

  const content = (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={messagePopupRef}
      className={`messageBox show`}
      id={uniqueId}
    >
      <div className={exClassName}>
        <div className={isExpandedView ? 'modalContent' : ''}>
          <div className={isExpandedView ? 'modal-header' : 'msgBoxTop d-flex'}>
            <div className="msgHeading">
              <Tooltip title={threadName} color={'#2E364C'} placement="right">
                {threadName}
              </Tooltip>
            </div>
            {!isCompactView ? (
              <>
                {Boolean(isExpandedView) === false && (
                  <span
                    className="openMessagePopup"
                    style={{
                      right: isCompactView ? 5 : 50,
                    }}
                    onClick={() => {
                      if (Boolean(isExpandedView) === false) {
                        setIsExpandedView(true);
                        addOverlay();
                      }
                    }}
                  ></span>
                )}
                <div
                  className="closeMsgBox"
                  onClick={() => {
                    removeMessageOverlay();
                    closeMessagePopup();
                  }}
                  onKeyDown={() => {}}
                >
                  <span
                    className="material-icons-outlined modal-close"
                    id="msgBoxClose"
                  >
                    close
                  </span>
                </div>
              </>
            ) : isExpandedView === true ? (
              <div
                className="closeMsgBox"
                onClick={() => {
                  removeMessageOverlay();
                  closeMessagePopup();
                }}
                onKeyDown={() => {}}
              >
                <span
                  className="material-icons-outlined modal-close"
                  id="msgBoxClose"
                >
                  close
                </span>
              </div>
            ) : null}
          </div>

          <div className={isExpandedView ? 'modal-body' : ''}>
            <div ref={messageBodyRef} className="msgBody">
              {messages.map((ele: any) => {
                let items: MenuProps['items'] = [];

                if (ele.user_id === userDetails.user_id) {
                  items =
                    messagingCmsData?.lbl_message_modification_option?.map(
                      (el: any) => {
                        if (el.key === MessageThreadOption.DELETE_MESSAGE) {
                          return {
                            key: el.key,
                            label: (
                              <Popconfirm
                                title={
                                  messagingCmsData
                                    ?.lbl_delete_message_popover_config.header
                                }
                                description={
                                  messagingCmsData
                                    ?.lbl_delete_message_popover_config.body
                                }
                                okText={
                                  messagingCmsData
                                    ?.lbl_delete_message_popover_config.confirm
                                }
                                cancelText={
                                  messagingCmsData
                                    ?.lbl_delete_message_popover_config.cancel
                                }
                                placement="leftTop"
                                icon={
                                  <QuestionCircleOutlined
                                    style={{ color: 'red' }}
                                  />
                                }
                                arrow={false}
                                open={Boolean(
                                  openPopover && openPopover[ele.message_id],
                                )}
                                onConfirm={() =>
                                  deleteMessage(
                                    localStorage.getItem('currentMessageId')!,
                                  )
                                }
                                onCancel={(e) => {
                                  e?.stopPropagation();
                                  cancelDeleting();
                                }}
                                overlayClassName="customAntDeletePopover"
                              >
                                <div
                                  className="optionWrap"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setOpenPopover({
                                      ...openPopover,
                                      [ele.message_id]: true,
                                    });
                                  }}
                                  onKeyDown={() => {}}
                                >
                                  <span className={el.className}></span>
                                  <span>{el.label}</span>
                                </div>
                              </Popconfirm>
                            ),
                          };
                        }

                        return {
                          key: el.key,
                          label: (
                            <div className="optionWrap">
                              <span className={el.className}></span>
                              <span>{el.label}</span>
                            </div>
                          ),
                        };
                      },
                    );
                }

                if (
                  ele.user_id !== userDetails.user_id &&
                  projectDetails?.associated_role_id === 3
                ) {
                  items = messagingCmsData?.lbl_message_modification_option
                    ?.map((el: any) => {
                      if (el.key === MessageThreadOption.DELETE_MESSAGE) {
                        return {
                          key: el.key,
                          label: (
                            <Popconfirm
                              title={
                                messagingCmsData
                                  ?.lbl_delete_message_popover_config.header
                              }
                              description={
                                messagingCmsData
                                  ?.lbl_delete_message_popover_config.body
                              }
                              okText={
                                messagingCmsData
                                  ?.lbl_delete_message_popover_config.confirm
                              }
                              cancelText={
                                messagingCmsData
                                  ?.lbl_delete_message_popover_config.cancel
                              }
                              placement="leftTop"
                              icon={
                                <QuestionCircleOutlined
                                  style={{ color: 'red' }}
                                />
                              }
                              arrow={false}
                              open={Boolean(
                                openPopover && openPopover[ele.message_id],
                              )}
                              onConfirm={() =>
                                deleteMessage(
                                  localStorage.getItem('currentMessageId')!,
                                )
                              }
                              onCancel={(e) => {
                                e?.stopPropagation();
                                cancelDeleting();
                              }}
                              overlayClassName="customAntDeletePopover"
                            >
                              <div
                                className="optionWrap"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpenPopover({
                                    ...openPopover,
                                    [ele.message_id]: true,
                                  });
                                }}
                                onKeyDown={() => {}}
                              >
                                <span className={el.className}></span>
                                <span>{el.label}</span>
                              </div>
                            </Popconfirm>
                          ),
                        };
                      }
                    })
                    .filter((ele: any) => ele);
                }
                return (
                  <div
                    className={
                      Boolean(isExpandedView) === false
                        ? 'masseuse'
                        : 'masseuse userBlkWrap'
                    }
                    key={ele.message_id}
                  >
                    {generateUserAvatar(
                      getUser(ele.user_id)! as unknown as IProjectMember,
                    ).key === 'string' ? (
                      getUserThreadDetails(ele)?.removedStatus === true ||
                      userDetails.value === '' ? (
                        <div className="userAvtr">
                          <span className=" removedMember">
                            <img src={removedUser} alt="" />
                          </span>
                        </div>
                      ) : (
                        <div className="userAvtr">
                          {
                            generateUserAvatar(
                              getUser(
                                ele.user_id,
                              )! as unknown as IProjectMember,
                            ).value
                          }
                        </div>
                      )
                    ) : (
                      <div className="userAvtr">
                        <img
                          src={
                            generateUserAvatar(
                              getUser(
                                ele.user_id,
                              )! as unknown as IProjectMember,
                            ).value
                          }
                        />
                      </div>
                    )}
                    <div className="msgArea">
                      <div className="userName">
                        {getUserName(ele.user_id, ele)}
                        {!isAdminMessage(ele.user_id) ? (
                          // <span className="msgDueDate">
                          //   {getDateFormat(
                          //     ele.created_at.toDate(),
                          //     messagingCmsData
                          //   )}
                          // </span>
                          <span className="msgDueDate">
                            {ele.is_edited
                              ? getDateFormat(
                                  ele.updated_at.toDate(),
                                  messagingCmsData,
                                )
                              : getDateFormat(
                                  ele.created_at.toDate(),
                                  messagingCmsData,
                                )}
                          </span>
                        ) : null}
                        {ele.is_edited ? (
                          <span className="editedMessageMarker">
                            {messagingCmsData?.lbl_edited}
                          </span>
                        ) : null}
                      </div>
                      {!ele?.is_delete ? (
                        <div
                          className="msgText"
                          dangerouslySetInnerHTML={{
                            __html: formatMessageItems(ele.content),
                          }}
                        ></div>
                      ) : (
                        <div className="msgTextDeleted">
                          <span className="deletedTxtFormatting">
                            {messagingCmsData?.lbl_deleted_message_text}
                          </span>
                        </div>
                      )}
                    </div>
                    {isEditorEnabled &&
                    !isAdminMessage(ele.user_id) &&
                    (projectDetails === null ||
                      projectDetails?.is_archived === false) ? (
                      <Fragment>
                        {ele.user_id === userDetails.user_id &&
                        !ele.is_delete ? (
                          <Dropdown
                            menu={{ items, onClick }}
                            placement="bottomRight"
                            trigger={['click']}
                          >
                            <span
                              className="msgTextMore cmnIcon more"
                              onClick={() => {
                                cancelDeleting();
                                setTimeout(() => {
                                  localStorage.setItem(
                                    'currentMessageId',
                                    ele.message_id,
                                  );
                                });
                              }}
                              onKeyDown={() => {}}
                            ></span>
                          </Dropdown>
                        ) : null}
                        {ele.user_id !== userDetails.user_id &&
                        projectDetails?.associated_role_id === 3 &&
                        !ele.is_delete ? (
                          <Dropdown
                            menu={{ items, onClick }}
                            placement="bottomRight"
                            trigger={['click']}
                          >
                            <span
                              className="msgTextMore cmnIcon more"
                              onClick={() => {
                                cancelDeleting();
                                setTimeout(() => {
                                  localStorage.setItem(
                                    'currentMessageId',
                                    ele.message_id,
                                  );
                                });
                              }}
                              onKeyDown={() => {}}
                            ></span>
                          </Dropdown>
                        ) : null}
                      </Fragment>
                    ) : null}
                  </div>
                );
              })}
              <div ref={refDiv} />
            </div>
            {isDashBoardOrPlanner === true ? (
              <div className={isExpandedView ? 'modal-footer' : ''}>
                <div className="replyMsg">
                  <Editor
                    sendMessage={sendMessage}
                    editorClassName="messageThread"
                    threadId={messageThreadId}
                    showEmojiPicker={showEmojiPicker}
                    setShowEmojiPicker={setShowEmojiPicker}
                    messageContent={messageContent}
                    setMessageId={setMessageId}
                    setMessageContent={setMessageContent}
                    setEditing={setEditing}
                    editing={editing}
                    className={isCompactView ? 'compactViewMessage' : ''}
                    info={{
                      orgId: taskDetails?.org_id,
                      taskId: taskDetails?.task_id,
                      blockId: taskDetails?.block_id,
                      projectId: taskDetails?.project_id,
                    }}
                    allowedExtensions={{
                      showBoldOutlined: true,
                      showItalicOutlined: true,
                      showUnorderedListOutlined: true,
                      showEmojiControl: true,
                      showUploadOutlined: true,
                      showLinkOutlined: true,
                      showSend: true,
                      showCheckList: true,
                    }}
                    isExpandedView={isExpandedView}
                  />
                </div>
              </div>
            ) : (
              isTemplate === false &&
              (projectDetails === null ||
                projectDetails?.is_archived === false) && (
                <div className={isExpandedView ? 'modal-footer' : ''}>
                  {isEditorEnabled ? (
                    <div className="replyMsg">
                      <Editor
                        sendMessage={sendMessage}
                        editorClassName="messageThread"
                        threadId={messageThreadId}
                        showEmojiPicker={showEmojiPicker}
                        setShowEmojiPicker={setShowEmojiPicker}
                        messageContent={messageContent}
                        setMessageId={setMessageId}
                        setMessageContent={setMessageContent}
                        setEditing={setEditing}
                        editing={editing}
                        className={isCompactView ? 'compactViewMessage' : ''}
                        info={{
                          orgId: taskDetails?.org_id,
                          taskId: taskDetails?.task_id,
                          blockId: taskDetails?.block_id,
                          projectId: taskDetails?.project_id,
                        }}
                        allowedExtensions={{
                          showBoldOutlined: true,
                          showItalicOutlined: true,
                          showUnorderedListOutlined: true,
                          showEmojiControl: true,
                          showUploadOutlined: true,
                          showLinkOutlined: true,
                          showSend: true,
                          showCheckList: true,
                        }}
                        isExpandedView={isExpandedView}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const openingLogic = () => {
    if (currentSection) {
      return (
        showMessagesPopup &&
        currentSection?.sectionId === Number(taskId) &&
        currentSection?.popup === 'message'
      );
    } else {
      return showMessagesPopup;
    }
  };

  const classNameForMessageThread = (count: number) =>
    count === 0 ? 'emptyMessageThread' : '';

  const onClickMessage = (e: any) => {
    e.stopPropagation();
    if (showMessagesPopup) {
      removeMessageOverlay();
      closeMessagePopup();
    } else {
      if (teamMember && !isBlockedView) {
        browserStorage.setProjectMembers(JSON.stringify(teamMember));
      }
      setTimeout(() => {
        setShowMessagesPopup(true);
      }, 200);
    }
  };

  if (
    [
      SectionTypeMessages.GRID_LIST_STAGE,
      SectionTypeMessages.GRID_LIST_BLOCK,
      SectionTypeMessages.GRID_LIST_TASK,
    ].includes(sectionType as SectionTypeMessages) &&
    componentId &&
    ['gridBlk', 'gridStage', 'gridTask'].includes(componentId)
  ) {
    if (compactView) {
      return (
        <SubMenu
          title={
            <div className="customDropDownItem">
              <span className="cmnIcon">
                <GetMessageIcon
                  isBlockedView={isBlockedView}
                  messages={messages}
                  messageInfo={messageInfo}
                  toggleMessagePopup={() => {}}
                  threadName={threadName}
                  showMessagesPopup={openingLogic()}
                  classNameForMessageThread={classNameForMessageThread}
                  isTemplate={isTemplate}
                  projectDetails={projectDetails}
                  isCompactView={compactView}
                  isDashBoardOrPlanner={isDashBoardOrPlanner}
                />
              </span>
              {/* @ts-ignore */}
              {'View Message'}
            </div>
          }
          key={taskId}
          popupClassName="customSubMenu twoLavelMsgPopup"
          onTitleClick={(e) => toggleMessagePopup()}
          //disabled={messages?.length === 0}
          disabled={projectDetails?.is_archived && messages?.length === 0}
        >
          <Menu.Item key={taskId}>{content}</Menu.Item>
        </SubMenu>
      );
    }

    return (
      <>
        {!openingLogic() ? (
          <>
            {messageInfo.is_visible ? (
              <div onClick={onClickMessage} className="blkMsg haveMsg">
                Message <em>unread</em>
              </div>
            ) : messages?.length ? (
              <div onClick={onClickMessage} className="blkMsg haveMsg">
                Message
              </div>
            ) : (
              <div onClick={onClickMessage} className="blkMsg">
                Message
              </div>
            )}
          </>
        ) : (
          <Popover
            placement="bottomRight"
            trigger={'click'}
            content={content}
            open={true}
            overlayClassName={'messageThreadClass commentContainer'}
            arrow={false}
            onOpenChange={(event) => {
              if (event === false) {
                removeMessageOverlay();
                closeMessagePopup();
              }
            }}
          >
            {messageInfo.is_visible ? (
              <div onClick={onClickMessage} className="blkMsg haveMsg">
                Message <em>unread</em>
              </div>
            ) : messages?.length ? (
              <div onClick={onClickMessage} className="blkMsg haveMsg">
                Message
              </div>
            ) : (
              <div onClick={onClickMessage} className="blkMsg">
                Message
              </div>
            )}
          </Popover>
        )}
      </>
    );
  }

  return (
    <Fragment>
      {!openingLogic() ? (
        <GetMessageIcon
          isBlockedView={isBlockedView}
          messages={messages}
          messageInfo={messageInfo}
          toggleMessagePopup={toggleMessagePopup}
          threadName={threadName}
          showMessagesPopup={openingLogic()}
          classNameForMessageThread={classNameForMessageThread}
          isTemplate={isTemplate}
          projectDetails={projectDetails}
          isCompactView={compactView}
          isDashBoardOrPlanner={isDashBoardOrPlanner}
          isEditorEnabled={isEditorEnabled}
          isUsgThreadExist={isMessageCountExist}
        />
      ) : (
        <Popover
          placement={placement}
          trigger="click"
          content={content}
          open={true}
          // overlayClassName="messageThreadClass"

          overlayClassName={`messageThreadClass commentContainer ${
            showMessagesPopup ? 'expandedMessagePopupState' : ''
          }`}
          arrow={false}
          onOpenChange={(event) => {
            if (event === false) {
              removeMessageOverlay();
              closeMessagePopup();
            }
          }}
        >
          <GetMessageIcon
            isBlockedView={isBlockedView}
            messages={messages}
            messageInfo={messageInfo}
            toggleMessagePopup={toggleMessagePopup}
            threadName={threadName}
            projectDetails={projectDetails}
            isCompactView={compactView}
            isDashBoardOrPlanner={isDashBoardOrPlanner}
          />
        </Popover>
      )}
    </Fragment>
  );
};

export default MessageThread;

const GetMessageIcon: React.FC<{
  isBlockedView: boolean;
  messages: any;
  messageInfo: IMessageInfo;
  toggleMessagePopup: Function;
  threadName: string;
  showMessagesPopup?: boolean;
  classNameForMessageThread?: Function;
  isTemplate?: boolean;
  projectDetails?: any;
  isCompactView?: boolean;
  isDashBoardOrPlanner?: boolean;
  isUsgThreadExist?: boolean;
  isEditorEnabled?: boolean;
}> = ({
  isBlockedView,
  messages,
  messageInfo,
  toggleMessagePopup,
  threadName,
  showMessagesPopup = true,
  classNameForMessageThread,
  isTemplate = false,
  projectDetails,
  isCompactView,
  isDashBoardOrPlanner = false,
  isUsgThreadExist = true,
  isEditorEnabled = true,
}) => {
  if (isDashBoardOrPlanner === true) {
    if (!showMessagesPopup && !isBlockedView && classNameForMessageThread) {
      return (
        <div className="messageWrap">
          {isTemplate === true ? (
            messages?.length > 0 && (
              <span
                className={`cmnIcon message ${classNameForMessageThread(
                  messages?.length,
                )}${messageInfo.is_visible ? ' unReadMessageIndicator' : ''}`}
                onClick={() => toggleMessagePopup()}
                onKeyDown={() => {}}
              ></span>
            )
          ) : (
            <span
              className={`cmnIcon message ${classNameForMessageThread(
                messages?.length,
              )}${messageInfo.is_visible ? ' unReadMessageIndicator' : ''}`}
              onClick={() => toggleMessagePopup()}
              onKeyDown={() => {}}
            ></span>
          )}
        </div>
      );
    }

    if (!isBlockedView) {
      return (
        <div className="messageWrap">
          <span
            className="cmnIcon message"
            onClick={() => toggleMessagePopup()}
            onKeyDown={() => {}}
          ></span>
        </div>
      );
    }

    if (messageInfo.is_visible) {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            toggleMessagePopup();
          }}
          className="blkMsg haveMsg"
        >
          Message <em>unread</em>
        </div>
      );
    }

    if (messages?.length) {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            toggleMessagePopup();
          }}
          className="blkMsg haveMsg"
        >
          Message
        </div>
      );
    }

    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleMessagePopup();
        }}
        className="blkMsg"
      >
        Message
      </div>
    );
  }
  if (!isCompactView) {
    if (projectDetails && projectDetails?.is_archived === true) {
      if (messages.length > 0) {
        if (!showMessagesPopup && !isBlockedView && classNameForMessageThread) {
          return (
            <div className="messageWrap">
              {isTemplate === true ? (
                messages?.length > 0 && (
                  <span
                    className={`cmnIcon message ${classNameForMessageThread(
                      messages?.length,
                    )}${
                      messageInfo.is_visible ? ' unReadMessageIndicator' : ''
                    }`}
                    onClick={() => toggleMessagePopup()}
                    onKeyDown={() => {}}
                  ></span>
                )
              ) : (
                <span
                  className={`cmnIcon message ${classNameForMessageThread(
                    messages?.length,
                  )}${messageInfo.is_visible ? ' unReadMessageIndicator' : ''}`}
                  onClick={() => toggleMessagePopup()}
                  onKeyDown={() => {}}
                ></span>
              )}
            </div>
          );
        }

        if (!isBlockedView) {
          return (
            <div className="messageWrap">
              <span
                className="cmnIcon message"
                onClick={() => toggleMessagePopup()}
                onKeyDown={() => {}}
              ></span>
            </div>
          );
        }

        if (messageInfo.is_visible) {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleMessagePopup();
              }}
              className="blkMsg haveMsg"
            >
              Message <em>unread</em>
            </div>
          );
        }

        if (messages?.length) {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleMessagePopup();
              }}
              className="blkMsg haveMsg"
            >
              Message
            </div>
          );
        }

        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleMessagePopup();
            }}
            className="blkMsg"
          >
            Message
          </div>
        );
      }
    } else {
      if (!showMessagesPopup && !isBlockedView && classNameForMessageThread) {
        return (
          <div className="messageWrap">
            {isTemplate === true ? (
              messages?.length > 0 && (
                <span
                  className={`cmnIcon message ${classNameForMessageThread(
                    messages?.length,
                  )}${messageInfo.is_visible ? ' unReadMessageIndicator' : ''}`}
                  onClick={() => toggleMessagePopup()}
                  onKeyDown={() => {}}
                ></span>
              )
            ) : isEditorEnabled ? (
              <span
                className={`cmnIcon message ${classNameForMessageThread(
                  messages?.length,
                )}${messageInfo.is_visible ? ' unReadMessageIndicator' : ''}`}
                onClick={() => toggleMessagePopup()}
                onKeyDown={() => {}}
              ></span>
            ) : isUsgThreadExist ? (
              <span
                className={`cmnIcon message ${classNameForMessageThread(
                  messages?.length,
                )}${messageInfo.is_visible ? ' unReadMessageIndicator' : ''}`}
                onClick={() => toggleMessagePopup()}
                onKeyDown={() => {}}
              ></span>
            ) : (
              <></>
            )}
          </div>
        );
      }

      if (!isBlockedView) {
        return (
          <div className="messageWrap">
            <span
              className="cmnIcon message"
              onClick={() => toggleMessagePopup()}
              onKeyDown={() => {}}
            ></span>
          </div>
        );
      }

      if (messageInfo.is_visible) {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleMessagePopup();
            }}
            className="blkMsg haveMsg"
          >
            Message <em>unread</em>
          </div>
        );
      }

      if (messages?.length) {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleMessagePopup();
            }}
            className="blkMsg haveMsg"
          >
            Message
          </div>
        );
      }

      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            toggleMessagePopup();
          }}
          className="blkMsg"
        >
          Message
        </div>
      );
    }
  }
  if (!showMessagesPopup && !isBlockedView && classNameForMessageThread) {
    return (
      <div className="messageWrap">
        {isTemplate === true ? (
          messages?.length > 0 && (
            <span
              className={`cmnIcon message ${classNameForMessageThread(
                messages?.length,
              )}${messageInfo.is_visible ? ' unReadMessageIndicator' : ''}`}
              onClick={() => toggleMessagePopup()}
              onKeyDown={() => {}}
            ></span>
          )
        ) : (
          <span
            className={`cmnIcon message ${classNameForMessageThread(
              messages?.length,
            )}${messageInfo.is_visible ? ' unReadMessageIndicator' : ''}`}
            onClick={() => toggleMessagePopup()}
            onKeyDown={() => {}}
          ></span>
        )}
      </div>
    );
  }

  if (!isBlockedView) {
    return (
      <div className="messageWrap">
        <span
          className="cmnIcon message"
          onClick={() => toggleMessagePopup()}
          onKeyDown={() => {}}
        ></span>
      </div>
    );
  }

  if (messageInfo.is_visible) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleMessagePopup();
        }}
        className="blkMsg haveMsg"
      >
        Message <em>unread</em>
      </div>
    );
  }

  if (messages?.length) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleMessagePopup();
        }}
        className="blkMsg haveMsg"
      >
        Message
      </div>
    );
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        toggleMessagePopup();
      }}
      className="blkMsg"
    >
      Message
    </div>
  );
};
