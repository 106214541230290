import { RoutesEnum } from "../../models/enums/apiRoutes";
import {
  IImageSaveResponse,
  IImageUrlParams,
  IOrganizationCleanupPayload,
  IProfilePayload,
  IProfileSetupResponse,
  ITeamSetupPayload,
  ITeamSetupResponse,
  IUserInvitationDto,
} from "../../models/interface";
import apiService from "../Api.service";

class UserOnboardingService {
  public setupTeam = async (teamSetupPayload: ITeamSetupPayload) => {
    return apiService.post<ITeamSetupResponse>(
      RoutesEnum.USER_ONBOARDING_SETUP_TEAM,
      teamSetupPayload
    );
  };

  public setupProfile = async (profileSetupPayload: IProfilePayload) => {
    return apiService.post<IProfileSetupResponse>(
      RoutesEnum.USER_ONBOARDING_SETUP_PROFILE,
      profileSetupPayload
    );
  };

  public cleanupOrganization = async (
    organizationCleanupPayload: IOrganizationCleanupPayload
  ) => {
    return apiService.post(
      RoutesEnum.USER_ONBOARDING_ORGANIZATION_CLEANUP,
      organizationCleanupPayload
    );
  };

  public saveOrganizationLogo = async (
    organizationLogoPayload: IImageUrlParams
  ) => {
    return apiService.post<IImageSaveResponse>(
      RoutesEnum.SAVE_IMAGE,
      organizationLogoPayload
    );
  };

  public saveProfileImage = async (profileImagePayload: IImageUrlParams) => {
    return apiService.post<IImageSaveResponse>(
      RoutesEnum.SAVE_IMAGE,
      profileImagePayload
    );
  };

  public postSendUserInvitationIndividual = async (
    payload: IUserInvitationDto
  ) => {
    return apiService.post<{ messageId: string; message: string; data: any }>(
      RoutesEnum.SEND_USER_INVITATION_INDIVIDUAL,
      payload
    );
  };
}
const userOnboardingService = new UserOnboardingService();
export default userOnboardingService;
