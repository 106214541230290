import { Fragment, useEffect, useRef, useState } from "react";
import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "utils/firebase";
import { Dropdown, MenuProps, Popconfirm, Popover, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import cryptoRandomString from "crypto-random-string";
import { useProjectDashboardStore, useUserStore } from "stores";
import { IProjectMember, IProjectState } from "models/interface";
import {
  formatMessageItems,
  generateUserAvatar,
  getCurrentOrganization,
  getDateFormat,
  modifyMessageString,
  removeOverlay,
} from "utils/commonFunctions";
import Editor from "components/sharedComponents/Editor/Editor";
import { deleteObject, ref } from "@firebase/storage";
import { messagingService, projectService } from "services";
import { SectionTypeMessages } from "models/enums/messages";
import BrowserStorageService from "../../../services/browserStorage.service";

enum MessageThreadOption {
  EDIT_MESSAGE = "edit_message",
  DELETE_MESSAGE = "delete_message",
}

const adminUser = {
  full_name: process.env.REACT_APP_CLEVER_SORT_USER_NAME,
  user_id: Number(process.env.REACT_APP_CLEVER_SORT_USER_ID) || -1,
};

const ExpandedMessagePopup: React.FC<{
  sectionType: string;
  sectionId: number | string;
  taskId: number | string;
  threadName: string;
  sectionName: string;
  taskDetails: any;
  projectId?: number;
  projectName?: string;
  orgId?: number;
  teamMember?: IProjectMember[];
  openMessageExpandedView?: boolean;
  setOpenMessageExpandedView?: React.Dispatch<React.SetStateAction<boolean>>;
  index?: number;
  expandedViewForMessageIndex?: number;
  templateDetails?: any;
  isTemplate: boolean;
  isEditorEnabled?: boolean;
}> = ({
  sectionType,
  sectionId,
  taskId,
  threadName,
  sectionName,
  taskDetails,
  projectId,
  projectName,
  orgId,
  teamMember,
  openMessageExpandedView,
  setOpenMessageExpandedView,
  index,
  expandedViewForMessageIndex,
  isTemplate,
  templateDetails,
  isEditorEnabled = true,
}) => {
  const refDiv = useRef<HTMLDivElement>(null);
  const [showMessagesPopup, setShowMessagesPopup] = useState(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedMessageThreadId, setSelectedMessageThreadId] =
    useState<string>("");
  const [messageId, setMessageId] = useState<string>("");
  const [messageContent, setMessageContent] = useState<string>("");
  const [messages, setMessages] = useState([]);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState({
    is_visible: false,
    count: 0,
  });

  const lbl_delete_message_popover_config = {
    body: "The message will not appear to anyone",
    cancel: "Cancel",
    header: "Delete the message",
    confirm: "Delete",
  };

  const lbl_message_modification_option = [
    {
      key: "edit_message",
      label: "Edit",
      className: "cmnIcon edit",
    },
    {
      key: "delete_message",
      label: "Delete",
      className: "actionIons cmnIcon deleteBin",
    },
  ];

  const { projectMembers, projectDetails, messagingCmsData } =
    useProjectDashboardStore((state: IProjectState) => state);

  const { userDetails } = useUserStore((state: any) => state);
  const location = useLocation();
  const browserStorage = BrowserStorageService.getInstance();
  const org_key = location.pathname.split("/")[2];
  const currentOrganization = getCurrentOrganization(
    org_key,
    userDetails?.organization_info
  );

  const messageThreadId =
    isTemplate === false
      ? `${sectionType}-${
          process.env.REACT_APP_STAGE === "local"
            ? "dev"
            : process.env.REACT_APP_STAGE
        }-${orgId ?? currentOrganization?.organization_id}-${
          projectId ?? projectDetails?.project_id
        }-${sectionId}-${taskId}`
      : `${sectionType}-${process.env.REACT_APP_STAGE}-CS-${templateDetails.template_id}-${sectionId}-${taskId}`;

  const getTaggedUserId = (data: any[]) => data;

  const sendMessage = async (
    data: string,
    parsedData: any,
    attachmentUrls: any[] | string[]
  ) => {
    if (isTemplate === false) {
      const docRef = doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        await messagingService.saveReadMessageCountByUser({
          count: docSnap.data().messages.length + 1,
          messageThreadId: messageThreadId,
        });
      } else {
        await messagingService.saveReadMessageCountByUser({
          count: 1,
          messageThreadId: messageThreadId,
        });
      }

      if (messageId === undefined || messageId === "") {
        if (!docSnap.exists()) {
          await setDoc(doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId), {
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            project_id: projectId ?? projectDetails?.project_id,
            section_id: sectionId,
            organization_id: currentOrganization?.organization_id,
            member_id: generateMemberDetails(),
            task_name: threadName,
            project_name: projectName ?? projectDetails?.project_name,
            is_done: false,
            is_deleted: false,
            messages: arrayUnion({
              message_id: cryptoRandomString({ length: 10 }),
              attachments: attachmentUrls,
              tagged_user_id: getTaggedUserId(parsedData),
              user_id: userDetails.user_id,
              // content: data.replaceAll(
              //   '<p class="tiptapExtensionParagraph"></p>',
              //   ""
              // ),
              content: modifyMessageString(data),
              created_at: Timestamp.now(),
              updated_at: Timestamp.now(),
              is_delete: false,
              is_edited: false,
              is_archive: false,
            }),
          });
        } else {
          await updateDoc(doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId), {
            member_id: generateMemberDetails(),
            messages: arrayUnion({
              message_id: cryptoRandomString({ length: 10 }),
              attachments: attachmentUrls,
              tagged_user_id: getTaggedUserId(parsedData),
              user_id: userDetails.user_id,
              // content: data.replaceAll(
              //   '<p class="tiptapExtensionParagraph"></p>',
              //   ""
              // ),
              content: modifyMessageString(data),
              created_at: Timestamp.now(),
              updated_at: Timestamp.now(),
              is_delete: false,
              is_edited: false,
              is_archive: false,
            }),
            is_done: false,
            updated_at: Timestamp.now(),
          });
        }
      } else {
        let document = await getDoc(doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId));
        let messages = document.data()!.messages;

        let props = messages.find((ele: any) => ele.message_id === messageId);

        let existingAttachments = [...props.attachments];

        // props.content = data.replaceAll(
        //   '<p class="tiptapExtensionParagraph"></p>',
        //   ""
        // );
        props.content = modifyMessageString(data);

        props.updated_at = Timestamp.now();
        props.tagged_user_id = getTaggedUserId(parsedData);
        props.attachments = attachmentUrls;
        props.is_edited = true;

        await updateDoc(doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId), {
          messages: messages,
          updated_at: Timestamp.now(),
          is_done: false,
        });

        existingAttachments
          .filter(
            (eleOuter: any) =>
              !attachmentUrls.map((ele) => ele.url).includes(eleOuter.url)
          )
          .forEach((ele) => {
            const desertRef = ref(storage, ele.url);
            deleteObject(desertRef);
          });

        setMessageId("");
        setMessageContent("");
        setEditing(false);
        localStorage.removeItem("currentMessageId");
      }

      if (getTaggedUserId(parsedData)!?.length > 0) {
        sendNotification(getTaggedUserId(parsedData)!);
      }
    } else {
      const docRef = doc(db, "message-thread-admin", messageThreadId);
      const docSnap = await getDoc(docRef);

      if (messageId === undefined || messageId === "") {
        if (!docSnap.exists()) {
          await setDoc(doc(db, "message-thread-admin", messageThreadId), {
            created_at: Timestamp.now(),
            updated_at: Timestamp.now(),
            template_id: templateDetails?.template_id,
            section_id: sectionId,
            organization_id: currentOrganization?.organization_id,
            task_name: threadName,
            is_done: false,
            is_deleted: false,
            messages: arrayUnion({
              message_id: cryptoRandomString({ length: 10 }),
              attachments: attachmentUrls,
              tagged_user_id: getTaggedUserId(parsedData),
              user_id: userDetails.user_id,
              // content: data.replaceAll(
              //   '<p class="tiptapExtensionParagraph"></p>',
              //   ""
              // ),
              content: modifyMessageString(data),
              created_at: Timestamp.now(),
              updated_at: Timestamp.now(),
              is_delete: false,
              is_edited: false,
              is_archive: false,
            }),
          });
        } else {
          await updateDoc(doc(db, "message-thread-admin", messageThreadId), {
            messages: arrayUnion({
              message_id: cryptoRandomString({ length: 10 }),
              attachments: attachmentUrls,
              tagged_user_id: getTaggedUserId(parsedData),
              user_id: userDetails.user_id,
              // content: data.replaceAll(
              //   '<p class="tiptapExtensionParagraph"></p>',
              //   ""
              // ),
              content: modifyMessageString(data),
              created_at: Timestamp.now(),
              updated_at: Timestamp.now(),
              is_delete: false,
              is_edited: false,
              is_archive: false,
            }),
            is_done: false,
            updated_at: Timestamp.now(),
          });
        }
      } else {
        let document = await getDoc(
          doc(db, "message-thread-admin", messageThreadId)
        );
        let messages = document.data()!.messages;

        let props = messages.find((ele: any) => ele.message_id === messageId);

        let existingAttachments = [...props.attachments];

        // props.content = data.replaceAll(
        //   '<p class="tiptapExtensionParagraph"></p>',
        //   ""
        // );
        props.content = modifyMessageString(data);

        props.updated_at = Timestamp.now();
        props.tagged_user_id = getTaggedUserId(parsedData);
        props.attachments = attachmentUrls;
        props.is_edited = true;

        await updateDoc(doc(db, "message-thread-admin", messageThreadId), {
          messages: messages,
          updated_at: Timestamp.now(),
          is_done: false,
        });

        existingAttachments
          .filter((ele: string) => !attachmentUrls.includes(ele))
          .forEach((ele) => {
            const desertRef = ref(storage, ele);
            deleteObject(desertRef);
          });

        setMessageId("");
        setMessageContent("");
        localStorage.removeItem("currentMessageId");
      }

      if (getTaggedUserId(parsedData)!?.length > 0) {
        sendNotification(getTaggedUserId(parsedData)!);
      }
    }
  };

  const sendNotification = async (taggedUsers: number[]) => {
    if (isTemplate === false) {
      try {
        await projectService.sendMentionNotification({
          users: taggedUsers.filter((e) => e !== userDetails.user_id),
          senderName: userDetails.full_name ?? userDetails.email,
          projectName: projectName ?? projectDetails?.project_name,
          orgKey: org_key,
          teamName: getCurrentOrganization(
            org_key,
            userDetails.organization_info
          )?.organization_name!,
          taskName: threadName,
          sectionName: sectionName,
          projectId: projectId ?? projectDetails?.project_id,
          organizationId: getCurrentOrganization(
            org_key,
            userDetails.organization_info
          )?.organization_id!,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getMemberDetails = (userId: number) => {
    if (userId) {
      const memberData = teamMember ?? projectMembers;
      return memberData?.filter((ele) => ele.user_id === userId)[0];
    }
  };

  const generateMemberDetails = () => {
    const memberData = teamMember ?? projectMembers;
    return memberData?.map((ele) => {
      return {
        user_id: ele.user_id,
        user_name: ele.full_name,
      };
    });
  };

  const isAdminMessage = (user_id: number) => {
    return user_id === adminUser.user_id;
  };

  const getUser = (user_id: number) => {
    if (isTemplate === false) {
      if (isAdminMessage(user_id)) {
        return adminUser;
      } else {
        return getMemberDetails(user_id);
      }
    } else {
      if (isAdminMessage(user_id)) {
        return adminUser;
      }
    }
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === MessageThreadOption.EDIT_MESSAGE) {
      let currentMessageId =
        localStorage.getItem("currentMessageId") !== undefined &&
        localStorage.getItem("currentMessageId") !== null
          ? localStorage.getItem("currentMessageId")
          : selectedMessageThreadId;
      let messageID = currentMessageId !== null ? currentMessageId : "";
      setMessageId(messageID);
      editMessage(messageID);
      if (isTemplate === false) {
        setEditing(true);
      }
    }
  };

  const editMessage = async (messageId: string | null) => {
    let data: any;
    if (isTemplate === false) {
      data = await getDoc(doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId));
    } else {
      data = await getDoc(doc(db, "message-thread-admin", messageThreadId));
    }

    let messages = data.data()!.messages;
    if (messageId !== null) {
      let text = messages.find(
        (ele: any) => ele.message_id === messageId
      ).content;
      setMessageContent(text);
    }
  };

  const deleteMessage = async (messageId: string | null) => {
    let data: any;
    if (isTemplate === false) {
      data = await getDoc(doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId));
    } else {
      data = await getDoc(doc(db, "message-thread-admin", messageThreadId));
    }
    let messages = data.data()!.messages;

    if (messageId !== null) {
      let props = messages.find((ele: any) => ele.message_id === messageId);

      props.updated_at = Timestamp.now();
      props.is_delete = true;

      if (isTemplate === false) {
        await updateDoc(doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId), {
          messages: messages,
          updated_at: Timestamp.now(),
        });
      } else {
        await updateDoc(doc(db, "message-thread-admin", messageThreadId), {
          messages: messages,
          updated_at: Timestamp.now(),
        });
      }
    }

    setOpenPopover(false);
  };

  const getUserName = (userId: number) => {
    if (isAdminMessage(userId)) {
      return <span className="userNameElement">{adminUser.full_name}</span>;
    } else {
      if (isTemplate === false) {
        let userName = getMemberDetails(userId)?.full_name;
        if (userName) {
          if (userName?.length <= 50) {
            return <span className="userNameElement">{userName}</span>;
          } else {
            return (
              <Tooltip title={userName} color={"#2E364C"} placement="right">
                <span className="userNameElement">
                  {`${userName.substring(0, 50)}...`}
                </span>
              </Tooltip>
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShowMessagesPopup(false);
        setShowEmojiPicker(false);
        setMessageId("");
        setMessageContent("");
        if (isTemplate === false) {
          setEditing(false);
        }
        localStorage.removeItem("currentMessageId");
        if (setOpenMessageExpandedView) {
          setOpenMessageExpandedView(false);
        }
      }
    };
    window.addEventListener("keydown", handleEscKeyPress);

    let unSub: any;
    if (isTemplate === false) {
      unSub = onSnapshot(doc(db, process.env.REACT_APP_MESSAGE_THREAD_ID!, messageThreadId), (doc) => {
        if (doc.exists()) {
          setMessages(doc.data().messages);
          // setMessageInfo({
          //   is_visible: showUnreadIndicator(doc.data().messages),
          //   count: doc.data().messages.length,
          // });
        }
      });
    } else {
      unSub = onSnapshot(
        doc(db, "message-thread-admin", messageThreadId),
        (doc) => {
          if (doc.exists()) {
            setMessages(doc.data().messages);
            // setMessageInfo({
            //   is_visible: showUnreadIndicator(doc.data().messages),
            //   count: doc.data().messages.length,
            // });
          }
        }
      );
    }

    return () => {
      //removeOverlay();
      window.removeEventListener("keydown", handleEscKeyPress);
      unSub();
    };
  }, []);

  useEffect(() => {
    if (messages.length) {
      refDiv.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages.length]);

  //   useEffect(() => {
  //     if (showMessagesPopup) {
  //       setMessageInfo({
  //         ...messageInfo,
  //         is_visible: false,
  //       });
  //     }
  //   }, [showMessagesPopup]);

  // const openDrawer = () => {
  //   setIsDrawerOpen(true);
  // };

  // const closeDrawer = () => {
  //   setIsDrawerOpen(false);
  // };

  const content = (
    <div
      onClick={(e) => {
        // This is for GridList view, to stop the event from bubbling up to the parent
        e.stopPropagation();
      }}
      className={`messageBox show`}
      id="msgBoxPopup"
    >
      <div
        className={`createNewProjectForm messageThreadExpandedDrawer ${
          openMessageExpandedView ? "addToggle" : ""
        }`}
        id="expandedMessageThred"
      >
        <div className={`messageBox show`} id="msgBoxExpanded">
          <div className="modalContent">
            <div className="modal-header">
              <div className="msgHeading">
                {isTemplate === false && (
                  <Tooltip
                    title={threadName}
                    color={"#2E364C"}
                    placement="right"
                  >
                    {threadName}
                  </Tooltip>
                )}
              </div>
              <span
                className="material-icons-outlined modal-close"
                id=""
                onClick={() => {
                  setShowMessagesPopup(false);
                  setShowEmojiPicker(false);
                  setMessageId("");
                  setMessageContent("");
                  if (isTemplate === false) {
                    setEditing(false);
                  }
                  localStorage.removeItem("currentMessageId");
                  if (setOpenMessageExpandedView) {
                    setOpenMessageExpandedView(false);
                  }
                  removeOverlay();
                }}
                onKeyDown={() => {}}
              >
                close
              </span>
            </div>
            <div className="modal-body">
              <div className="msgBody">
                {isTemplate === false
                  ? messages.map((ele: any) => {
                      let items: MenuProps["items"] = [];

                      if (ele.user_id === userDetails.user_id) {
                        items =
                          messagingCmsData?.lbl_message_modification_option?.map(
                            (ele: any) => {
                              return ele.key ===
                                MessageThreadOption.DELETE_MESSAGE
                                ? {
                                    key: ele.key,
                                    label: (
                                      <Popconfirm
                                        title={
                                          messagingCmsData
                                            ?.lbl_delete_message_popover_config
                                            .header
                                        }
                                        description={
                                          messagingCmsData
                                            ?.lbl_delete_message_popover_config
                                            .body
                                        }
                                        okText={
                                          messagingCmsData
                                            ?.lbl_delete_message_popover_config
                                            .confirm
                                        }
                                        cancelText={
                                          messagingCmsData
                                            ?.lbl_delete_message_popover_config
                                            .cancel
                                        }
                                        placement="leftTop"
                                        icon={
                                          <QuestionCircleOutlined
                                            style={{ color: "red" }}
                                          />
                                        }
                                        arrow={false}
                                        open={openPopover}
                                        onConfirm={() => {
                                          let currentMessageId =
                                            localStorage.getItem(
                                              "currentMessageId"
                                            ) !== undefined &&
                                            localStorage.getItem(
                                              "currentMessageId"
                                            ) !== null
                                              ? localStorage.getItem(
                                                  "currentMessageId"
                                                )
                                              : selectedMessageThreadId;
                                          deleteMessage(currentMessageId);
                                        }}
                                        onCancel={() => {
                                          localStorage.removeItem(
                                            "currentMessageId"
                                          );
                                          setOpenPopover(false);
                                        }}
                                        overlayClassName="customAntDeletePopover"
                                      >
                                        <div
                                          className="optionWrap"
                                          onClick={() => setOpenPopover(true)}
                                          onKeyDown={() => {}}
                                        >
                                          <span
                                            className={ele.className}
                                          ></span>
                                          <span>{ele.label}</span>
                                        </div>
                                      </Popconfirm>
                                    ),
                                  }
                                : {
                                    key: ele.key,
                                    label: (
                                      <div className="optionWrap">
                                        <span className={ele.className}></span>
                                        <span>{ele.label}</span>
                                      </div>
                                    ),
                                  };
                            }
                          );
                      }

                      if (
                        ele.user_id !== userDetails.user_id &&
                        projectDetails?.associated_role_id === 3
                      ) {
                        items =
                          messagingCmsData?.lbl_message_modification_option
                            ?.map((ele: any) => {
                              if (
                                ele.key === MessageThreadOption.DELETE_MESSAGE
                              ) {
                                return {
                                  key: ele.key,
                                  label: (
                                    <Popconfirm
                                      title={
                                        messagingCmsData
                                          ?.lbl_delete_message_popover_config
                                          .header
                                      }
                                      description={
                                        messagingCmsData
                                          ?.lbl_delete_message_popover_config
                                          .body
                                      }
                                      okText={
                                        messagingCmsData
                                          ?.lbl_delete_message_popover_config
                                          .confirm
                                      }
                                      cancelText={
                                        messagingCmsData
                                          ?.lbl_delete_message_popover_config
                                          .cancel
                                      }
                                      placement="leftTop"
                                      icon={
                                        <QuestionCircleOutlined
                                          style={{ color: "red" }}
                                        />
                                      }
                                      arrow={false}
                                      open={openPopover}
                                      onConfirm={() => {
                                        let currentMessageId =
                                          localStorage.getItem(
                                            "currentMessageId"
                                          ) !== undefined &&
                                          localStorage.getItem(
                                            "currentMessageId"
                                          ) !== null
                                            ? localStorage.getItem(
                                                "currentMessageId"
                                              )
                                            : selectedMessageThreadId;
                                        deleteMessage(currentMessageId);
                                      }}
                                      onCancel={() => {
                                        localStorage.removeItem(
                                          "currentMessageId"
                                        );
                                        setOpenPopover(false);
                                      }}
                                      overlayClassName="customAntDeletePopover"
                                    >
                                      <div
                                        className="optionWrap"
                                        onClick={() => setOpenPopover(true)}
                                        onKeyDown={() => {}}
                                      >
                                        <span className={ele.className}></span>
                                        <span>{ele.label}</span>
                                      </div>
                                    </Popconfirm>
                                  ),
                                };
                              }
                            })
                            .filter((ele: any) => ele);
                      }
                      return (
                        <div
                          className="masseuse userBlkWrap"
                          key={ele.message_id}
                        >
                          {generateUserAvatar(
                            getUser(ele.user_id)! as unknown as IProjectMember
                          ).key === "string" ? (
                            <div className="userAvtr">
                              {
                                generateUserAvatar(
                                  getUser(
                                    ele.user_id
                                  )! as unknown as IProjectMember
                                ).value
                              }
                            </div>
                          ) : (
                            <div className="userAvtr">
                              <img
                                src={
                                  generateUserAvatar(
                                    getUser(
                                      ele.user_id
                                    )! as unknown as IProjectMember
                                  ).value
                                }
                              />
                            </div>
                          )}
                          <div className="msgArea">
                            <div className="userName">
                              {getUserName(ele.user_id)}
                              {!isAdminMessage(ele.user_id) ? (
                                <span className="msgDueDate">
                                  {getDateFormat(
                                    ele.created_at.toDate(),
                                    messagingCmsData
                                  )}
                                </span>
                              ) : null}
                              {ele.is_edited ? (
                                <span className="editedMessageMarker">
                                  {messagingCmsData?.lbl_edited}
                                </span>
                              ) : null}
                            </div>
                            {!ele?.is_delete ? (
                              <div
                                className="msgText"
                                dangerouslySetInnerHTML={{
                                  __html: formatMessageItems(ele.content),
                                }}
                              ></div>
                            ) : (
                              <div className="msgTextDeleted">
                                <span className="deletedTxtFormatting">
                                  {messagingCmsData?.lbl_deleted_message_text}
                                </span>
                              </div>
                            )}
                          </div>
                          {!isAdminMessage(ele.user_id) ? (
                            <Fragment>
                              {ele.user_id === userDetails.user_id &&
                              !ele.is_delete ? (
                                <Dropdown
                                  menu={{ items, onClick }}
                                  placement="topRight"
                                  trigger={["click"]}
                                >
                                  <span
                                    className="msgTextMore cmnIcon more"
                                    onClick={() => {
                                      setSelectedMessageThreadId(
                                        ele.message_id
                                      );
                                      localStorage.setItem(
                                        "currentMessageId",
                                        ele.message_id
                                      );
                                    }}
                                    onKeyDown={() => {}}
                                  ></span>
                                </Dropdown>
                              ) : null}
                              {ele.user_id !== userDetails.user_id &&
                              projectDetails?.associated_role_id === 3 &&
                              !ele.is_delete ? (
                                <Dropdown
                                  menu={{ items, onClick }}
                                  placement="topRight"
                                  trigger={["click"]}
                                >
                                  <span
                                    className="msgTextMore cmnIcon more"
                                    onClick={() => {
                                      setSelectedMessageThreadId(
                                        ele.message_id
                                      );
                                      localStorage.setItem(
                                        "currentMessageId",
                                        ele.message_id
                                      );
                                    }}
                                    onKeyDown={() => {}}
                                  ></span>
                                </Dropdown>
                              ) : null}
                            </Fragment>
                          ) : null}
                        </div>
                      );
                    })
                  : messages
                      .filter((ele: any) => !ele.is_delete)
                      .map((ele: any) => {
                        let items: MenuProps["items"] = [];

                        if (ele.user_id === userDetails.user_id) {
                          items = lbl_message_modification_option?.map(
                            (ele: any) => {
                              return ele.key ===
                                MessageThreadOption.DELETE_MESSAGE
                                ? {
                                    key: ele.key,
                                    label: (
                                      <Popconfirm
                                        title={
                                          lbl_delete_message_popover_config.header
                                        }
                                        description={
                                          lbl_delete_message_popover_config.body
                                        }
                                        okText={
                                          lbl_delete_message_popover_config.confirm
                                        }
                                        cancelText={
                                          lbl_delete_message_popover_config.cancel
                                        }
                                        placement="leftTop"
                                        icon={
                                          <QuestionCircleOutlined
                                            style={{ color: "red" }}
                                          />
                                        }
                                        arrow={false}
                                        open={openPopover}
                                        onConfirm={() => {
                                          let currentMessageId =
                                            localStorage.getItem(
                                              "currentMessageId"
                                            ) !== undefined &&
                                            localStorage.getItem(
                                              "currentMessageId"
                                            ) !== null
                                              ? localStorage.getItem(
                                                  "currentMessageId"
                                                )
                                              : selectedMessageThreadId;
                                          deleteMessage(currentMessageId);
                                        }}
                                        onCancel={() => {
                                          localStorage.removeItem(
                                            "currentMessageId"
                                          );
                                          setOpenPopover(false);
                                        }}
                                        overlayClassName="customAntDeletePopover"
                                      >
                                        <div
                                          className="optionWrap"
                                          onClick={() => setOpenPopover(true)}
                                          onKeyDown={() => {}}
                                        >
                                          <span
                                            className={ele.className}
                                          ></span>
                                          <span>{ele.label}</span>
                                        </div>
                                      </Popconfirm>
                                    ),
                                  }
                                : {
                                    key: ele.key,
                                    label: (
                                      <div className="optionWrap">
                                        <span className={ele.className}></span>
                                        <span>{ele.label}</span>
                                      </div>
                                    ),
                                  };
                            }
                          );
                        }

                        return (
                          <div
                            className="masseuse userBlkWrap"
                            key={ele.message_id}
                          >
                            {generateUserAvatar(
                              getUser(ele.user_id)! as unknown as IProjectMember
                            ).key === "string" ? (
                              <div className="userAvtr">
                                {
                                  generateUserAvatar(
                                    getUser(
                                      ele.user_id
                                    )! as unknown as IProjectMember
                                  ).value
                                }
                              </div>
                            ) : (
                              <div className="userAvtr">
                                <img
                                  src={
                                    generateUserAvatar(
                                      getUser(
                                        ele.user_id
                                      )! as unknown as IProjectMember
                                    ).value
                                  }
                                />
                              </div>
                            )}
                            <div className="msgArea">
                              <div className="userName">
                                {getUserName(ele.user_id)}
                                {!isAdminMessage(ele.user_id) ? (
                                  <span className="msgDueDate">
                                    {getDateFormat(ele.created_at.toDate(), {})}
                                  </span>
                                ) : null}
                                {ele.is_edited &&
                                !isAdminMessage(ele.user_id) ? (
                                  <span className="editedMessageMarker">
                                    Edited
                                  </span>
                                ) : null}
                              </div>
                              {!ele?.is_delete ? (
                                <div
                                  className="msgText"
                                  dangerouslySetInnerHTML={{
                                    __html: formatMessageItems(ele.content),
                                  }}
                                ></div>
                              ) : (
                                <div className="msgTextDeleted">
                                  <span className="deletedTxtFormatting">
                                    This message was deleted
                                  </span>
                                </div>
                              )}
                            </div>
                            {!isAdminMessage(ele.user_id) ? (
                              <Fragment>
                                {isEditorEnabled &&
                                ele.user_id === userDetails.user_id &&
                                !ele.is_delete ? (
                                  <Dropdown
                                    menu={{ items, onClick }}
                                    placement="bottomLeft"
                                    trigger={["click"]}
                                  >
                                    <span
                                      className="msgTextMore cmnIcon more"
                                      onClick={() =>
                                        localStorage.setItem(
                                          "currentMessageId",
                                          ele.message_id
                                        )
                                      }
                                      onKeyDown={() => {}}
                                    ></span>
                                  </Dropdown>
                                ) : null}
                              </Fragment>
                            ) : null}
                          </div>
                        );
                      })}
                <div ref={refDiv} />
              </div>
              <div className="modal-footer">
                {isEditorEnabled && isTemplate === false && (
                  <div className="replyMsg">
                    <Editor
                      sendMessage={sendMessage}
                      editorClassName="messageThread"
                      threadId={messageThreadId}
                      showEmojiPicker={showEmojiPicker}
                      setShowEmojiPicker={setShowEmojiPicker}
                      messageContent={messageContent}
                      setMessageId={setMessageId}
                      setMessageContent={setMessageContent}
                      setEditing={setEditing}
                      editing={editing}
                      info={{
                        orgId: taskDetails?.org_id,
                        taskId: taskDetails?.task_id,
                        blockId: taskDetails?.block_id,
                        projectId: taskDetails?.project_id,
                      }}
                      allowedExtensions={{
                        showBoldOutlined: true,
                        showItalicOutlined: true,
                        showUnorderedListOutlined: true,
                        showEmojiControl: true,
                        showUploadOutlined: true,
                        showLinkOutlined: true,
                        showSend: true,
                        showCheckList: true,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const returnContent = () => {
    // This update for ActionDropdown.tsx
    if (
      sectionType === SectionTypeMessages.GRID_LIST_BLOCK &&
      openMessageExpandedView
    )
      return content;

    if (expandedViewForMessageIndex !== undefined && index !== undefined) {
      if (openMessageExpandedView === true) {
        if (expandedViewForMessageIndex === index) {
          return content;
        }
      }
    }
    // else {
    //   return content;
    // }
  };

  return <Fragment>{returnContent()}</Fragment>;
};

export default ExpandedMessagePopup;
