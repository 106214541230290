import { create } from 'zustand';

interface ISaveFbOrReply {
  replyId: number;
  reported_on: number;
  reported_by: number;
  htmlContent: string;
}

export interface IEnvFilterData {
  environment_id: number;
  environment_name: string;
  url: string;
}

export interface ITaskStatusFilter {
  name: string;
  task_status_id: number
}

export interface ITaskPriorityFilter {
  name: string;
  task_priority_id: number
}

export const useFeedbackStore = create((set, get: any) => ({
  //States
  fbEditContent: '',
  replyEditContent: '',
  envFilterData: [],
  envValue: 0,
  feedbackList: [],
  saveFbOrReply: [],
  organizationId: 5,
  projectId: 3,
  zoomLevelPercentage: 1,
  panOnDrag: true,
  savePayload: { feedbackId: 0, top: 0, left: 0, width: 0, height: 0 },
  rightDrawerPayload: { feedbackDetailsId: 0, feedbackId: 0 },
  filterKey: 0,
  fitviewFlag: 0,
  isRightDrawerOpen: false,
  taskStatusFilters: [],
  taskPriorityFilters: [],

  //Actions
  setFeedbackList: (feedbackList: any) => set({ feedbackList: feedbackList }),
  setFbEditContent: (fbEditContent: any) =>
    set({ fbEditContent: fbEditContent }),
  setReplyEditContent: (replyEditContent: any) =>
    set({ replyEditContent: replyEditContent }),
  setEnvFilterData: (envData: IEnvFilterData[]) =>
    set({ envFilterData: envData }),
  setEnvValue: (val: number) => set({ envValue: val }),
  setSaveFbOrReply: (saveFbOrReply: ISaveFbOrReply[]) =>
    set({ saveFbOrReply: saveFbOrReply }),
  handleSetPanOnDrag: (panOnDrag: boolean) => {
    set({ panOnDrag: panOnDrag });
  },
  setSavePayload: (savePayloadValue: any) => {
    set((state: any) => ({
      savePayload: {
        ...state.savePayload,
        ...savePayloadValue,
      },
    }));
  },
  updateFeedbackList: (
    feedbackIdValue: number,
    newFeedbackDetailsData: any,
  ) => {
    set((state: any) => {
      const updatedFeedbackList = state.feedbackList.map((feedback: any) => {
        feedback.page_data = feedback.page_data.map((feedbackId: any) => {
          if (feedbackId.feedback_id === feedbackIdValue) {
            feedbackId.feedback_details_data.push(newFeedbackDetailsData);
          }
          return feedbackId;
        });
        return feedback;
      });
      return { feedbackList: updatedFeedbackList };
    });
  },
  removeFeedback: (feedbackIdValue: number) => {
    set((state: any) => {
      const updatedFeedbackList = state.feedbackList.map((feedback: any) => {
        feedback.page_data = feedback.page_data.filter(
          (feedbackId: any) => feedbackId.feedback_id !== feedbackIdValue,
        );
        return feedback;
      });
      const filteredFeedbackList = updatedFeedbackList.filter(
        (feedback: any) => feedback.page_data.length > 0,
      );
      return { feedbackList: filteredFeedbackList };
    });
  },
  updateZoomLevel: (zoomLevelValue: number) => {
    set((state: any) => ({
      ...state,
      zoomLevelPercentage: zoomLevelValue,
    }));
  },
  updateFilterValue: (value: number) => {
    set({ filterKey: value });
  },
  updateFitviewFlag: (value: number) => {
    set({ fitviewFlag: value });
  },
  updateScreenTitle: (feedbackIdValue: number, screenNameValue: string) => {
    set((prevState: any) => {
      const updatedFeedbackList = prevState.feedbackList.map(
        (feedback: any) => {
          feedback.page_data = feedback.page_data.map((item: any) => {
            if (item.feedback_id == feedbackIdValue) {
              return { ...item, screen_name: screenNameValue };
            }
            return item;
          });
          return feedback;
        },
      );
      return { ...prevState, feedbackList: updatedFeedbackList };
    });
  },
  setIsRightDrawerOpen: (value: boolean) => {
    set({ isRightDrawerOpen: value });
  },
  setRightDrawerPayload: (rightDrawerPayloadValue: any) => {
    set((state: any) => ({
      rightDrawerPayload: {
        ...state.rightDrawerPayload,
        ...rightDrawerPayloadValue,
      },
    }));
  },
  deleteReplyData: (
    fbDetailsIdValue: number,
    feedbackIdValue: number,
    fbReplyIdValue: number,
  ) => {
    set((prevState: any) => {
      const updatedFeedbackList = prevState.feedbackList.map(
        (feedback: any) => {
          feedback.page_data = feedback.page_data.map((item: any) => {
            if (item.feedback_id === feedbackIdValue) {
              item.feedback_details_data = item.feedback_details_data.map(
                (detailsItem: any) => {
                  if (detailsItem.details_id === fbDetailsIdValue) {
                    detailsItem.feedback_reply_data =
                      detailsItem.feedback_reply_data.filter((reply: any) => {
                        return reply.reply_id !== fbReplyIdValue;
                      });
                  }
                  return detailsItem;
                },
              );
            }
            return item;
          });
          return feedback;
        },
      );
      return { ...prevState, feedbackList: updatedFeedbackList };
    });
  },
  addReplyData: (
    feedbackIdValue: number,
    fbDetailsIdValue: number,
    objValue: any,
  ) => {
    const newObj = {
      reply_id: objValue.reply_id,
      full_name: objValue.full_name,
      reported_by: objValue.reported_by,
      reported_on: objValue.reported_by,
    };
    set((prevState: any) => {
      const updatedFeedbackList = prevState.feedbackList.map(
        (feedback: any) => {
          feedback.page_data = feedback.page_data.map((item: any) => {
            if (item.feedback_id === feedbackIdValue) {
              item.feedback_details_data = item.feedback_details_data.map(
                (detailsItem: any) => {
                  if (detailsItem.details_id === fbDetailsIdValue) {
                    detailsItem.feedback_reply_data.push(newObj);
                  }
                  return detailsItem;
                },
              );
            }
            return item;
          });
          return feedback;
        },
      );
      return { ...prevState, feedbackList: updatedFeedbackList };
    });
  },
  updateFeedbackStatus: (
    statusIdValue: number,
    feedbackIdValue: number,
    fbDetailsIdValue: number,
  ) => {
    set((prevState: any) => {
      const updatedFeedbackList = prevState.feedbackList.map(
        (feedback: any) => {
          feedback.page_data = feedback.page_data.map((item: any) => {
            if (item.feedback_id === feedbackIdValue) {
              item.feedback_details_data = item.feedback_details_data.map(
                (detailsItem: any) => {
                  if (detailsItem.details_id === fbDetailsIdValue) {
                    return {
                      ...detailsItem,
                      feedback_status_id: statusIdValue,
                    };
                  }
                  return detailsItem;
                },
              );
            }
            return item;
          });
          return feedback;
        },
      );
      return { ...prevState, feedbackList: updatedFeedbackList };
    });
  },
  deleteFeedbackDetailsData: (
    feedbackIdValue: number,
    fbDetailsIdValue: number,
  ) => {
    set((prevState: any) => {
      const updatedFeedbackList = prevState.feedbackList.map(
        (feedback: any) => {
          feedback.page_data = feedback.page_data.map((item: any) => {
            if (item.feedback_id === feedbackIdValue) {
              item.feedback_details_data = item.feedback_details_data.filter(
                (i: any) => i.details_id !== fbDetailsIdValue,
              );
            }
            return item;
          });
          return feedback;
        },
      );
      return { ...prevState, feedbackList: updatedFeedbackList };
    });
  },
  setTaskStatusFilters: (data: ITaskStatusFilter) => set({ taskStatusFilters: data }),
  setTaskPriorityFilters: (data: ITaskPriorityFilter) => set({ taskPriorityFilters: data })
}));
