import React, { FC, Fragment, useEffect } from 'react';
import { Dropdown, MenuProps, message } from 'antd';
import { useProjectDashboardStore, useUserStore } from 'stores';
import {
  AllowedSection,
  IChild,
  IGridList,
  IGridListBlockDetail,
  IMeatBallMenu,
  IMemopad,
  IModalState,
  IProjectSections,
  IProjectState,
  IProjectUserMetaPayload,
  ITaskList,
  IUpdatedTasks,
  IUserState,
} from 'models/interface';
import { projectService } from 'services';
import {
  addOverlay,
  findCollapseStateFromStorage,
  getCurrentOrganization,
  groupGridBlock,
} from 'utils/commonFunctions';
import { useLocation } from 'react-router-dom';
import { TaskListView } from 'models/enums/tasklistView';
import { useRbac } from 'auth/rbac/rbac';
import { useModalStore } from 'stores/modal.store';
import { useAnalytics } from 'components/sharedComponents';
import {
  getMessageThreadBySection,
  markTaskDeleteInMessageThread,
  updateMessageThreadForPrivateSection,
  updateMessageThreadForPublicSection,
} from 'utils/firebaseCommonFunctions';
import { handleGridViewStore } from './GridListV3/GridList';
import { useFetchNewPermission } from 'components/sharedComponents/hooks';

interface IMeatBallMenuProps {
  sectionType: string;
  sectionList: IProjectSections[];
  sectionDetails: ITaskList | IGridList | IMemopad;
  setSectionDetails: React.Dispatch<
    React.SetStateAction<ITaskList | IGridList | IMemopad>
  >;
  setShowSidePanel?: React.Dispatch<React.SetStateAction<boolean>>;
  // socket: any;
  getSectionDetails: () => Promise<void>;
  changeTaskListView?: () => void;
  refDiv: React.RefObject<HTMLDivElement>;
  setCurrentId?: (val: string) => void;
  currentId?: string;
}

enum MeatBallMenuOptionKeys {
  CHANGE_SECTION_PRIVACY = 'change_section_privacy',
  CLONE_SECTION = 'clone_section',
  MOVE_SECTION = 'move_section',
  MOVE_SECTION_TOP = 'move_section_top',
  MOVE_SECTION_UP = 'move_section_up',
  MOVE_SECTION_DOWN = 'move_section_down',
  MOVE_SECTION_BOTTOM = 'move_section_bottom',
  ADD_SECTION = 'add_section',
  ADD_SECTION_TASK_LIST = 'add_section_task_list',
  ADD_SECTION_GRID_LIST = 'add_section_grid_list',
  ADD_SECTION_MEMO_PAD = 'add_section_memo_pad',
  SWITCH_VIEW = 'switch_view',
  CUSTOMIZE_STATUS_UPDATES = 'customize_status_update',
  DELETE_SECTION = 'delete_section',
  TOGGLE_LIST_VIEW = 'toggle_grid_list_view',
}

enum TaskStatusOptions {
  TODO = 1,
  OVERDUE,
  DONE,
  BLOCKED,
}

const MeatBallMenu: FC<IMeatBallMenuProps> = ({
  sectionType,
  sectionList,
  sectionDetails,
  setSectionDetails,
  setShowSidePanel,
  // socket,
  getSectionDetails,
  changeTaskListView,
  refDiv,
  setCurrentId,
}) => {
  const {
    taskListCmsData,
    projectDetails,
    setProjectDetails,
    setShowSectionDeleteConfirmation,
    showSectionDeleteConfirmation,
    gridListCmsData,
    setSectionDetail,
    sectionDetail: selectedSection,
    setMasterDataLoading,
  } = useProjectDashboardStore((state: IProjectState) => state);
  const { setModalState, modalState } = useModalStore(
    (state: IModalState) => state,
  );
  const modalClose = () => {
    setModalState({ ...modalState, shouldShow: false });
    setShowSectionDeleteConfirmation(false);
  };
  const gaContext = useAnalytics();
  const { userDetails } = useUserStore((state: IUserState) => state);

  const location = useLocation();
  const org_key = location.pathname.split('/')[2];
  const currentOrganization =
    userDetails?.organization_info &&
    getCurrentOrganization(org_key, userDetails?.organization_info);
  const { hasPermissions } = useRbac();

  const { fetchNewPermission } = useFetchNewPermission();

  const determineDisabled = (ele: IMeatBallMenu) => {
    if (
      projectDetails?.is_archived === true &&
      ele.key !== MeatBallMenuOptionKeys.SWITCH_VIEW &&
      ele.key !== MeatBallMenuOptionKeys.TOGGLE_LIST_VIEW
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getCurrentTaskListView = () => {
    let projectId =
      projectDetails && projectDetails.project_id
        ? projectDetails.project_id.toString()
        : '';
    let orgId = getCurrentOrganization(org_key, userDetails!.organization_info!)
      ?.organization_id!;

    let taskListState = findCollapseStateFromStorage(
      sectionDetails,
      userDetails?.user_id?.toString()!,
      projectId.toString(),
      orgId.toString(),
    );

    return taskListState?.view;
  };
  const getProjectMetadata = () => {
    let task_list_view: TaskListView;
    if (
      !sectionDetails.task_list_view ||
      sectionDetails.task_list_view === TaskListView.LIST
    ) {
      task_list_view = TaskListView.CARD;
    } else {
      task_list_view = TaskListView.LIST;
    }

    if (
      projectDetails?.project_metadata?.length &&
      projectDetails?.project_metadata?.length > 0
    ) {
      if (
        projectDetails.project_metadata
          .map((eachMeta) => eachMeta.section_id)
          .includes(sectionDetails.section_id)
      ) {
        return projectDetails?.project_metadata?.map((eachMeta) => {
          if (eachMeta.section_id === sectionDetails.section_id) {
            return {
              section_id: eachMeta.section_id,
              hide_completed_tasks:
                sectionDetails.hide_completed_tasks ?? false,
              show_own_tasks: sectionDetails.show_own_tasks ?? false,
              task_list_view: task_list_view,
            };
          }
          return eachMeta;
        });
      } else {
        return [
          ...projectDetails.project_metadata,
          {
            section_id: sectionDetails.section_id,
            hide_completed_tasks: sectionDetails.hide_completed_tasks ?? false,
            show_own_tasks: sectionDetails.show_own_tasks ?? false,
            task_list_view: task_list_view,
          },
        ];
      }
    } else {
      return [
        {
          section_id: sectionDetails.section_id,
          hide_completed_tasks: sectionDetails.hide_completed_tasks ?? false,
          show_own_tasks: sectionDetails.show_own_tasks ?? false,
          task_list_view: task_list_view,
        },
      ];
    }
  };

  const updateMetadata = async (
    updateProjectUserMetaPayload?: IProjectUserMetaPayload,
    isFromDelete?: boolean,
  ) => {
    if (updateProjectUserMetaPayload) {
      await projectService.updateProjectUserMetadata(
        updateProjectUserMetaPayload,
      );
      const _project_metadata =
        updateProjectUserMetaPayload?.project_metadata?.map((each) => ({
          ...each,
          section_id: parseInt(each.section_id as string),
        }));
      !isFromDelete &&
        setProjectDetails({
          ...projectDetails,
          project_metadata: _project_metadata,
        });
      getSectionDetails();
    }
  };

  // const notifyChannel = (userDetails: IUserDetails, eventType: string) => {
  //   notificationService.sendSectionUpdateNotification(socket, {
  //     eventType: eventType,
  //     eventValue: "",
  //     sectionType: sectionType,
  //     currentOrganizationId: currentOrganization?.organization_id,
  //     currentProjectId: projectDetails?.project_id,
  //     currentSectionId: "",
  //     currentUserId: userDetails.user_id,
  //     currentUserName: userDetails.full_name
  //       ? userDetails.full_name
  //       : userDetails.email,
  //   });
  // };

  const handleChangeSectionPrivacy = async () => {
    const _sectionDetails = sectionDetails;
    _sectionDetails.is_private = !_sectionDetails.is_private;
    try {
      if (
        currentOrganization &&
        projectDetails?.project_id &&
        userDetails &&
        typeof _sectionDetails.section_id === 'number'
      ) {
        await projectService.updateSection({
          organizationId: currentOrganization.organization_id,
          project_id: projectDetails.project_id,
          userId: userDetails.user_id,
          sections: [
            {
              section_id: _sectionDetails.section_id,
              section_name: _sectionDetails.section_name,
              is_private: _sectionDetails.is_private,
            },
          ],
        });

        if (sectionDetails.section_type_id === 1) {
          let _tasks: IUpdatedTasks[] | undefined =
            _sectionDetails?.task_list_details
              ?.filter((eachTask) => typeof eachTask.task_id !== 'string')
              ?.map((eachTask: any) => {
                if (
                  _sectionDetails.is_private === true &&
                  eachTask.task_status_id !== TaskStatusOptions.DONE &&
                  Number(eachTask.assignee_id) !== Number(userDetails?.user_id)
                ) {
                  eachTask.assignee_id = null;
                  eachTask.assignee_dp = undefined;
                  eachTask.assignee_email = undefined;
                  eachTask.assignee_name = undefined;
                }
                return {
                  ...eachTask,
                  task_id: parseInt(eachTask.task_id?.toString()),
                };
              });

          if (_tasks?.length) {
            await projectService.updateTask({
              organizationId: currentOrganization.organization_id,
              project_id: projectDetails.project_id,
              section_id: _sectionDetails.section_id,
              section_details: _tasks,
              updateAssignee: true,
            });
            setSectionDetails({
              ..._sectionDetails,
              task_list_details: _tasks,
            });
          }
        }

        if (sectionDetails.section_type_id === 2) {
          let gridListSection = _sectionDetails as IGridList;
          let _blocks:
            | IGridListBlockDetail[]
            | IGridListBlockDetail[][]
            | null
            | undefined = gridListSection?.grid_list_details?.block_details;

          if (_blocks) {
            _blocks = _blocks.flat()?.map((block) => {
              if (
                _sectionDetails.is_private === true &&
                block.block_status_id !== TaskStatusOptions.DONE &&
                block.assignee_id?.toString() != userDetails?.user_id
              ) {
                block.assignee_id = null;
                block.assignee_profile_picture = null;
                block.assignee_email = null;
                block.assignee_name = null;
              }
              return block;
            });
          }

          if (_sectionDetails.is_private === true) {
            await projectService.updateGridBlockForSectionPrivacy({
              organizationId: currentOrganization.organization_id,
              projectId: projectDetails.project_id,
              sectionId: _sectionDetails.section_id,
              userId: userDetails?.user_id,
            });
          }

          let updatedSection = {
            ...gridListSection,
            grid_list_details: {
              ...gridListSection.grid_list_details,
              block_details: groupGridBlock(
                _blocks!,
                gridListSection.grid_list_details!.task_details!,
              ) as Array<IGridListBlockDetail[]>,
            },
          };

          setSectionDetails(updatedSection);
        }

        if (sectionDetails.section_type_id === 3) {
          setSectionDetails({
            ...sectionDetails,
            is_private: _sectionDetails.is_private,
          });
        }

        // notifyChannel(
        //   userDetails,
        //   NotificationEventType.CHANGE_SECTION_PRIVACY
        // );

        const responseFireStore = await getMessageThreadBySection(
          +getCurrentOrganization(org_key, userDetails!.organization_info!)
            ?.organization_id!,
          projectDetails?.project_id!,
          _sectionDetails.section_id,
        );

        let messageThreadId: string[] = [];

        responseFireStore.forEach((doc: any) => {
          messageThreadId.push(doc.id);
        });

        for (let id of messageThreadId) {
          if (_sectionDetails.is_private) {
            await updateMessageThreadForPrivateSection(id, {
              user_id: +userDetails.user_id,
              user_name: userDetails?.full_name,
            });
          } else {
            await updateMessageThreadForPublicSection(id);
          }
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.messageId === -5) {
        fetchNewPermission(
          org_key,
          taskListCmsData?.lbl_error_message_permission_denied,
        );
      } else {
        message.error(
          taskListCmsData?.error_message_options.move_section_error,
        );
      }
    }
  };

  const handleViewSwitch = () => {
    const updateProjectUserMetaPayload = {
      project_id: projectDetails?.project_id,
      project_metadata: getProjectMetadata(),
      org_key: org_key,
    };
    updateMetadata(updateProjectUserMetaPayload);
  };

  const getMenuOptionClassName = (ele: IMeatBallMenu) => {
    if (
      (ele.key === MeatBallMenuOptionKeys.CHANGE_SECTION_PRIVACY &&
        sectionDetails.is_private === true) ||
      (ele.key === MeatBallMenuOptionKeys.SWITCH_VIEW &&
        sectionDetails.task_list_view === TaskListView.CARD) ||
      (ele.key === MeatBallMenuOptionKeys.TOGGLE_LIST_VIEW &&
        // @ts-ignore
        sectionDetails?.compactView)
    ) {
      return ele.className[1];
    }

    return ele.className[0];
  };

  const getMenuOptionLabel = (ele: IMeatBallMenu) => {
    if (
      (ele.key === MeatBallMenuOptionKeys.CHANGE_SECTION_PRIVACY &&
        sectionDetails.is_private === true) ||
      (ele.key === MeatBallMenuOptionKeys.SWITCH_VIEW &&
        getCurrentTaskListView() === TaskListView.CARD) ||
      (ele.key === MeatBallMenuOptionKeys.TOGGLE_LIST_VIEW &&
        // @ts-ignore
        sectionDetails?.compactView)
    ) {
      return ele.label[1];
    }

    return ele.label[0];
  };

  const isFirstSection = () => {
    return (
      projectDetails?.sections?.findIndex(
        (eachSection) => sectionDetails.section_id === eachSection.section_id,
      ) === 0
    );
  };

  const isLastSection = () => {
    let lastIndex = projectDetails?.sections?.length
      ? projectDetails?.sections?.length - 1
      : 0;
    return (
      projectDetails?.sections?.findIndex(
        (eachSection) => sectionDetails.section_id === eachSection.section_id,
      ) === lastIndex
    );
  };

  const moveToTop = async () => {
    setCurrentId?.('');
    let updatedSections = projectDetails?.sections;
    const currentSection = updatedSections?.splice(
      updatedSections.findIndex(
        (eachSection) => eachSection.section_id === sectionDetails.section_id,
      ),
      1,
    );
    if (currentSection) {
      updatedSections?.splice(0, 0, currentSection[0]);
      updatedSections = updatedSections?.map((eachSection, i) => ({
        ...eachSection,
        section_rank: i + 1,
      }));
      let updateSectionPayload = updatedSections?.map((eachSection) => ({
        section_id: eachSection.section_id,
        section_rank: eachSection.section_rank,
      }));
      try {
        if (
          currentOrganization &&
          projectDetails?.project_id &&
          userDetails &&
          updateSectionPayload
        ) {
          const updateSectionResponse = await projectService.updateSection({
            organizationId: currentOrganization.organization_id,
            project_id: projectDetails.project_id,
            userId: userDetails.user_id,
            sections: updateSectionPayload,
          });
          if (updateSectionResponse.messageId === 1) {
            setProjectDetails({
              ...projectDetails,
              sections: updatedSections,
            });

            // notifyChannel(userDetails, NotificationEventType.REORDER_SECTION);
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.messageId === -5) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
        } else {
          message.error(
            taskListCmsData?.error_message_options.move_section_error,
          );
        }
      }
    }
  };

  const moveToBottom = async () => {
    setCurrentId?.('');
    let updatedSections = projectDetails?.sections;
    const currentSection = updatedSections?.splice(
      updatedSections.findIndex(
        (eachSection) => eachSection.section_id === sectionDetails.section_id,
      ),
      1,
    );
    if (currentSection) {
      updatedSections?.splice(updatedSections?.length, 0, currentSection[0]);
      updatedSections = updatedSections?.map((eachSection, i) => ({
        ...eachSection,
        section_rank: i + 1,
      }));
      let updateSectionPayload = updatedSections?.map((eachSection) => ({
        section_id: eachSection.section_id,
        section_rank: eachSection.section_rank,
      }));
      try {
        if (
          currentOrganization &&
          projectDetails?.project_id &&
          userDetails &&
          updateSectionPayload
        ) {
          const updateSectionResponse = await projectService.updateSection({
            organizationId: currentOrganization.organization_id,
            project_id: projectDetails.project_id,
            userId: userDetails.user_id,
            sections: updateSectionPayload,
          });
          if (updateSectionResponse.messageId === 1) {
            setProjectDetails({
              ...projectDetails,
              sections: updatedSections,
            });

            // notifyChannel(userDetails, NotificationEventType.REORDER_SECTION);
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.messageId === -5) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
        } else {
          message.error(
            taskListCmsData?.error_message_options.move_section_error,
          );
        }
      }
    }
  };

  const moveUp = async () => {
    setCurrentId?.('');
    let updatedSections = projectDetails?.sections;
    const currentIndex = updatedSections?.findIndex(
      (eachSection) => eachSection.section_id === sectionDetails.section_id,
    );
    const currentSection = updatedSections?.splice(
      updatedSections.findIndex(
        (eachSection) => eachSection.section_id === sectionDetails.section_id,
      ),
      1,
    );
    if (currentSection && currentIndex !== undefined) {
      updatedSections?.splice(currentIndex - 1, 0, currentSection[0]);
      updatedSections = updatedSections?.map((eachSection, i) => ({
        ...eachSection,
        section_rank: i + 1,
      }));
      let updateSectionPayload = updatedSections?.map((eachSection) => ({
        section_id: eachSection.section_id,
        section_rank: eachSection.section_rank,
      }));
      try {
        if (
          currentOrganization &&
          projectDetails?.project_id &&
          userDetails &&
          updateSectionPayload
        ) {
          const updateSectionResponse = await projectService.updateSection({
            organizationId: currentOrganization.organization_id,
            project_id: projectDetails.project_id,
            userId: userDetails.user_id,
            sections: updateSectionPayload,
          });
          if (updateSectionResponse.messageId === 1) {
            setProjectDetails({
              ...projectDetails,
              sections: updatedSections,
            });

            // notifyChannel(userDetails, NotificationEventType.REORDER_SECTION);
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.messageId === -5) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
        } else {
          message.error(
            taskListCmsData?.error_message_options.move_section_error,
          );
        }
      }
    }
  };

  const moveDown = async () => {
    setCurrentId?.('');
    let updatedSections = projectDetails?.sections;
    const currentIndex = updatedSections?.findIndex(
      (eachSection) => eachSection.section_id === sectionDetails.section_id,
    );
    const currentSection = updatedSections?.splice(
      updatedSections.findIndex(
        (eachSection) => eachSection.section_id === sectionDetails.section_id,
      ),
      1,
    );
    if (currentSection && currentIndex !== undefined) {
      updatedSections?.splice(currentIndex + 1, 0, currentSection[0]);
      updatedSections = updatedSections?.map((eachSection, i) => ({
        ...eachSection,
        section_rank: i + 1,
      }));
      let updateSectionPayload = updatedSections?.map((eachSection) => ({
        section_id: eachSection.section_id,
        section_rank: eachSection.section_rank,
      }));
      try {
        if (
          currentOrganization &&
          projectDetails?.project_id &&
          userDetails &&
          updateSectionPayload
        ) {
          const updateSectionResponse = await projectService.updateSection({
            organizationId: currentOrganization.organization_id,
            project_id: projectDetails.project_id,
            userId: userDetails.user_id,
            sections: updateSectionPayload,
          });
          if (updateSectionResponse.messageId === 1) {
            setProjectDetails({
              ...projectDetails,
              sections: updatedSections,
            });

            // notifyChannel(userDetails, NotificationEventType.REORDER_SECTION);
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.messageId === -5) {
          fetchNewPermission(
            org_key,
            taskListCmsData?.lbl_error_message_permission_denied,
          );
        } else {
          message.error(
            taskListCmsData?.error_message_options.move_section_error,
          );
        }
      }
    }
  };

  let items: MenuProps['items'] = [];
  items = taskListCmsData?.meatball_menu_dropdown_options
    ?.filter((ele: IMeatBallMenu) => {
      //@ts-ignore
      if (sectionDetails.compactView && ele.key === 'customize_status_update') {
        return false;
      }
      return true;
    })
    ?.map((ele: IMeatBallMenu) => {
      if (
        ele.allowedSection.includes(sectionType as AllowedSection) &&
        (ele.permissions === null ||
          hasPermissions(ele.permissions, projectDetails?.associated_role_id))
      ) {
        let returnObj: any;

        returnObj = {
          key: ele.key,
          disabled: determineDisabled(ele),
          label: (
            <div
              className={`
               ${
                 ele.key === 'add_section'
                   ? 'customDropDownItem taskDueDateClearDisable'
                   : 'customDropDownItem'
               }
              
              `}
            >
              <span className={getMenuOptionClassName(ele)}></span>
              {getMenuOptionLabel(ele)}
            </div>
          ),
        };

        if (ele.hasChildren) {
          if (isFirstSection()) {
            returnObj.children = ele?.children?.filter(
              (eachChild) =>
                eachChild.key !== 'move_section_top' &&
                eachChild.key !== 'move_section_up',
            );
          }
          if (isLastSection()) {
            returnObj.children = ele?.children?.filter(
              (eachChild) =>
                eachChild.key !== 'move_section_down' &&
                eachChild.key !== 'move_section_bottom',
            );
          }
          if (!isFirstSection() && !isLastSection()) {
            returnObj.children = ele?.children;
          }
          returnObj.children = returnObj.children?.map((ele: IChild) => {
            return {
              key: ele.key,
              label: ele.hasClass ? (
                <div className="customDropDownItem">
                  <span className={ele.className![0]}></span>
                  {ele.label[0]}
                </div>
              ) : (
                ele.label[0]
              ),
            };
          });
        }

        return returnObj;
      }
    })
    .filter((ele: IMeatBallMenu) => {
      if (sectionDetails.created_by != userDetails?.user_id) {
        return ele?.key !== MeatBallMenuOptionKeys.CHANGE_SECTION_PRIVACY;
      }
      return ele;
    })
    .filter((ele: IMeatBallMenu) => {
      if (projectDetails?.sections?.length === 1) {
        return ele?.key !== MeatBallMenuOptionKeys.MOVE_SECTION;
      }
      return ele;
    });

  const deleteSection = async () => {
    try {
      const result = await projectService.updateSection({
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info!,
        )?.organization_id!,
        project_id: projectDetails?.project_id!,
        userId: userDetails?.user_id!,
        sections: [
          {
            section_id: +selectedSection?.section_id!,
            section_name: selectedSection?.section_name!,
            section_rank: selectedSection?.section_rank!,
            is_deleted: true,
          },
        ],
      });

      const updateProjectUserMetaPayload = {
        project_id: projectDetails?.project_id,
        project_metadata: getProjectMetadata().filter(
          (eachMeta) => eachMeta.section_id !== selectedSection?.section_id,
        ),
        org_key: org_key,
      };
      updateMetadata(updateProjectUserMetaPayload, true);

      if (result.messageId === 1) {
        setProjectDetails({
          ...projectDetails,
          project_metadata: updateProjectUserMetaPayload.project_metadata,
          sections: projectDetails?.sections?.filter(
            (e) => e.section_id !== selectedSection?.section_id,
          ),
        });

        gaContext.updateProps({
          username: userDetails!.full_name!,
          email: userDetails!.email,
          organization: getCurrentOrganization(
            org_key,
            userDetails!.organization_info!,
          )
            ? getCurrentOrganization(org_key, userDetails!.organization_info!)
                ?.organization_name
            : '',
          eventName: 'delete project section',
          eventCategory: 'Section',
          eventLabel: 'Delete section',
        });

        // notifyChannel(userDetails!, NotificationEventType.DELETE_SECTION);
      }
      modalClose();

      if (result.messageId === 1) {
        const responseFireStore = await getMessageThreadBySection(
          +getCurrentOrganization(org_key, userDetails!.organization_info!)
            ?.organization_id!,
          projectDetails?.project_id!,
          +selectedSection?.section_id!,
        );

        let messageThreadId: string[] = [];

        responseFireStore.forEach((doc: any) => {
          messageThreadId.push(doc.id);
        });

        for (let id of messageThreadId) {
          await markTaskDeleteInMessageThread(id);
        }
      }
    } catch (error: any) {
      modalClose();
      fetchNewPermission(
        org_key,
        taskListCmsData?.lbl_error_message_permission_denied,
      );
    }
  };

  useEffect(() => {
    if (showSectionDeleteConfirmation) {
      setModalState({
        modalHeader: (
          <Fragment>
            <h3> {gridListCmsData?.lbl_delete_section}</h3>
            <span
              className="material-icons-outlined modal-close"
              onClick={() => modalClose()}
              onKeyDown={() => {}}
            >
              close
            </span>
          </Fragment>
        ),
        modalBody: (
          <React.Fragment>
            <div className="icon"></div>
            <p>
              {gridListCmsData?.lbl_section_delete_warning_1}&nbsp;
              <b>{selectedSection?.section_name}</b>? <br />
              {gridListCmsData?.lbl_section_delete_warning_2}
            </p>
          </React.Fragment>
        ),
        modalFooter: (
          <Fragment>
            <button
              className="noStyle cancel_deleteFolder"
              onClick={() => modalClose()}
            >
              {gridListCmsData?.cta_delete_modal_cancel}
            </button>

            <button
              className="deleteBtnStyle"
              onClick={() =>
                (async () => {
                  await deleteSection();
                })()
              }
            >
              {gridListCmsData?.lbl_delete_section}
            </button>
          </Fragment>
        ),
        shouldShow: true,
      });
    }
  }, [showSectionDeleteConfirmation]);
  const cloneProjectSection = async () => {
    setMasterDataLoading(true);
    try {
      const response = await projectService.cloneSection({
        organizationId: getCurrentOrganization(
          org_key,
          userDetails?.organization_info!,
        )?.organization_id!,
        project_id: projectDetails?.project_id!, // NOSONAR
        user_id: +userDetails!.user_id!, // NOSONAR
        section_id: +sectionDetails!.section_id!, // NOSONAR
        section_rank: projectDetails!.sections!.length + 1, // NOSONAR,
        customMeta: sectionDetails?.custom_meta,
      });
      if (response.messageId === 1) {
        const projectDetailTemp = { ...projectDetails };
        const tempSection = response.data;

        let newSectionDetails = {
          section_id: +tempSection.section_id,
          section_name: tempSection.section_name as string,
          section_type_id: tempSection.section_type_id as number,
          section_rank: tempSection.section_rank as number,
          created_by: tempSection.created_by,
          is_edit_mode: true,
        };
        projectDetailTemp.sections?.unshift(newSectionDetails);
        setProjectDetails(projectDetailTemp);
        setCurrentId?.(tempSection.section_id as string);
        setTimeout(() => {
          // const mainContainer = document.querySelector(".ant-spin-container")!;
          // mainContainer.scrollIntoView({ block: "end", behavior: "smooth" });

          refDiv.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        }, 500);
        gaContext.updateProps({
          username: userDetails!.full_name!,
          email: userDetails!.email,
          organization: getCurrentOrganization(
            org_key,
            userDetails!.organization_info!,
          )
            ? getCurrentOrganization(org_key, userDetails!.organization_info!)
                ?.organization_name
            : '',
          eventName: 'clone project section',
          eventCategory: 'Section',
          eventLabel: 'Clone section',
        });

        // notifyChannel(userDetails!, NotificationEventType.CLONE_SECTION);
      }
    } catch (error: any) {
      fetchNewPermission(
        org_key,
        taskListCmsData?.lbl_error_message_permission_denied,
      );
    } finally {
      setMasterDataLoading(false);
    }
  };

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case MeatBallMenuOptionKeys.CHANGE_SECTION_PRIVACY:
        projectDetails?.is_archived === false && handleChangeSectionPrivacy();
        break;
      case MeatBallMenuOptionKeys.DELETE_SECTION:
        projectDetails?.is_archived === false &&
          setSectionDetail(sectionDetails);
        projectDetails?.is_archived === false &&
          setShowSectionDeleteConfirmation(true);
        break;
      case MeatBallMenuOptionKeys.CUSTOMIZE_STATUS_UPDATES:
        if (projectDetails?.is_archived === false) {
          if (setShowSidePanel) {
            setShowSidePanel(true);
          }
          addOverlay();
        }

        break;
      case MeatBallMenuOptionKeys.SWITCH_VIEW:
        changeTaskListView && changeTaskListView();

        break;
      case MeatBallMenuOptionKeys.MOVE_SECTION_TOP:
        projectDetails?.is_archived === false && moveToTop();
        break;
      case MeatBallMenuOptionKeys.MOVE_SECTION_UP:
        projectDetails?.is_archived === false && moveUp();
        break;
      case MeatBallMenuOptionKeys.MOVE_SECTION_DOWN:
        projectDetails?.is_archived === false && moveDown();
        break;
      case MeatBallMenuOptionKeys.MOVE_SECTION_BOTTOM:
        projectDetails?.is_archived === false && moveToBottom();
        break;
      case MeatBallMenuOptionKeys.CLONE_SECTION:
        projectDetails?.is_archived === false &&
          setSectionDetail(sectionDetails);
        projectDetails?.is_archived === false && cloneProjectSection();
        break;
      case MeatBallMenuOptionKeys.TOGGLE_LIST_VIEW:
        setSectionDetails({
          ...sectionDetails,
          // @ts-ignore
          compactView: !Boolean(sectionDetails.compactView),
        });
        // @ts-ignore
        handleGridViewStore(
          Number(sectionDetails.section_id ?? 0),
          !sectionDetails.compactView ? 1 : 0,
          'set',
          sectionDetails,
          userDetails,
          projectDetails,
          org_key,
          'compact_view',
        );
      default:
        break;
    }
  };

  if (items && items.length > 0) {
    return (
      <Dropdown
        menu={{ items, onClick }}
        placement="bottomLeft"
        trigger={['click']}
      >
        <span className="cmnIcon more" />
      </Dropdown>
    );
  } else {
    return <></>;
  }
};
export default MeatBallMenu;
