import { useAuth0 } from "@auth0/auth0-react";
import { ILoaderState, ILoginRedirectPageAttributes } from "models/interface";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cmsService, userService } from "services";
import BrowserStorageService from "services/browserStorage.service";
import { useLoaderStore, useLocalizationStore } from "stores";
import { getPageSpecificData } from "utils/commonFunctions";

const LoginRedirect: React.FC = () => {
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);
  const { logout } = useAuth0();
  const [pageCmsData, setPageCmsData] =
    useState<ILoginRedirectPageAttributes | null>(null);
  const { cmsData, setCmsData } = useLocalizationStore((state: any) => state);
  const [searchParams] = useSearchParams();

  const getCmsData = async () => {
    const cmsPageName = ["login-redirect-pages"];
    const pageSlug = ["login-redirect-page"];
    let tmpPageData: any;
    setLoaderState("active");
    let pageSpecificDataStore = cmsData.pageSpecificData;
    if (pageSpecificDataStore.length > 0) {
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      if (!tmpPageData) {
        await cmsService.fetchCmsData(
          cmsData,
          setCmsData,
          cmsPageName,
          pageSlug
        );
        tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
        tmpPageData && setPageCmsData(tmpPageData[0].attributes);
      } else {
        tmpPageData && setPageCmsData(tmpPageData[0].attributes);
      }
      setLoaderState("inactive");
    } else {
      await cmsService.fetchCmsData(cmsData, setCmsData, cmsPageName, pageSlug);
      tmpPageData = getPageSpecificData(cmsData, pageSlug[0]);
      tmpPageData && setPageCmsData(tmpPageData[0]?.attributes);
      setLoaderState("inactive");
    }
  };

  const handleClick = async() => {
    // await userService.accountActivationResendEmail({
    //   auth_id: searchParams.get("authId") ?? "",
    // });
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  const activateUser = async() => {
    await userService.accountActivationResendEmail({
      auth_id: searchParams.get("authId") ?? "",
    });
  };

  useEffect(() => {
    document.getElementById("root")!.className =
      "genericWrapper loginWrapperWhole";
    (async () => {
      await getCmsData();
    })();

    return () => {
      document.getElementById("root")!.className = "";
    };
  }, []);

  useEffect(() => {
    if(searchParams.get("authId")) {
      activateUser();        
    }
  },[searchParams]);

  return (
    <div className="loginRedirectPage registrationGenericContent">
      <div className="leftImg" />
      <div className="loginBlk">
        <h2>{pageCmsData?.lbl_account_link_success_title ?? ""}</h2>
        <p>{pageCmsData?.lbl_account_link_success_msg ?? ""}</p>
        <button className="btnStyle1 btnInline" onClick={handleClick}>
          {pageCmsData?.btn_login ?? "Login"}
        </button>
      </div>
    </div>
  );
};

export default LoginRedirect;
