import { create } from "zustand";

export const useMessagingStore = create((set) => ({
  //initial states
  unReadMessageDetails: {
    shouldVisible: false,
    count: 0,
  },

  //actions
  setUnReadMessageDetails: (value: { shouldVisible: boolean; count: number }) =>
    set({ unReadMessageDetails: value }),
}));

export const useAllThreadsMessageDetailsStore = create((set) => ({
  //initial states
  allThreadsMessageDetails: [],

  //actions
  setAllThreadMessageDetails: (allThreadsMessageDetails: any[]) =>
    set({ allThreadsMessageDetails }),
}));

export const useAllThreadUnreadIndicatorStore = create((set) => ({
  // initial states
  allThreadUnreadIndicator: null,
  userMessageList: [],

  // Create a new state
  setAllThreadUnreadIndicator: (allThreads: any) => 
    set((state: any) => {
      if (!state.allThreadUnreadIndicator) {
        let baseUnreadIndicatorsForThreads = {};

        allThreads?.forEach((thread: any) => {
          baseUnreadIndicatorsForThreads = {
            ...baseUnreadIndicatorsForThreads,
            [thread.message_thread_id]: {
              is_user_thread: thread.is_user_thread,
              is_visible: false,
              count: 0,
            }
          }
        })
        return { allThreadUnreadIndicator:
          Object.keys(baseUnreadIndicatorsForThreads)?.length ? baseUnreadIndicatorsForThreads : null
        };
      } else {
        let tempNewThreads = { ...state.allThreadUnreadIndicator };

        allThreads?.forEach((thread: any) => {
          if (tempNewThreads[thread.message_thread_id]) {
            tempNewThreads[thread.message_thread_id] = { ...tempNewThreads[thread.message_thread_id], is_user_thread: thread.is_user_thread};
          } else {
            tempNewThreads = { ...tempNewThreads, [thread.message_thread_id]: { is_user_thread: thread.is_user_thread, is_visible: false, count: 0 }};
          }
        });

        return { allThreadUnreadIndicator: tempNewThreads };
      }
    }),

    // Update state
    updateAllThreadUnreadIndicator: (threadId: string, unreadIndicator: { is_visible: boolean, count: number}) => 
      set((state: any) => {
        if (state.allThreadUnreadIndicator) {
          const newThread = state.allThreadUnreadIndicator[threadId];

          if (newThread) {
            return {allThreadUnreadIndicator: 
              {
                ...state.allThreadUnreadIndicator,
                [threadId]: { ...newThread, ...unreadIndicator }
              }
            } 
          }
          return { allThreadUnreadIndicator: 
            {
              ...state.allThreadUnreadIndicator,
              [threadId]: { ...unreadIndicator, is_user_thread: false },
            }
          };
        }
        return { allThreadUnreadIndicator: state.allThreadUnreadIndicator };
      }
    ),

    // Set user message list
    setUserMessageList: (list: any) => set({ userMessageList: list })
}));
