import { FC, useState } from "react";
import { Popover, Tooltip } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useProjectDashboardStore } from "stores";
import {
  IGridListBlockDetail,
  IGridList,
  IProjectState,
} from "models/interface";
import { getBlockStatus, groupGridBlock } from "utils/commonFunctions";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import CCalendar from "../../CDatePicker/CCalendar";

const BlockDueDate: FC<{
  blockId: number;
  dueDate: string | null;
  showCalender: number;
  setShowCalender: React.Dispatch<React.SetStateAction<number>>;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  updateBlock: (block: IGridListBlockDetail) => Promise<void>;
}> = ({
  showCalender,
  setShowCalender,
  blockId,
  dueDate,
  gridListDetails,
  setGridListDetails,
  updateBlock,
}) => {
  const { gridListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state
  );
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state
  );
  const { hasPermissions } = useRbac();

  // States
  const [selectingDate, setSelectingDate] = useState<Dayjs | null>(
    dayjs(dueDate || dayjs())
  );

  const setDueDate = (date: string | null) => {
    if (gridListDetails) {
      let blockDetails =
        gridListDetails.grid_list_details?.block_details!.flat()!;
      let target = blockDetails.find(
        (ele: IGridListBlockDetail) => ele.block_id === blockId
      )!;
      let targetIndex = blockDetails.findIndex(
        (ele: IGridListBlockDetail) => ele.block_id === blockId
      )!;

      if (date !== null) {
        target.due_date = date;
        const statusByDate = getBlockStatus(date, target.block_status_id);
        target.block_status_id = statusByDate;
      } else {
        target.due_date = null;
        target.block_status_id = 1;
      }

      blockDetails.splice(targetIndex, 1, target);

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          block_details: groupGridBlock(
            blockDetails,
            gridListDetails.grid_list_details!.task_details!
          ) as Array<IGridListBlockDetail[]>,
        },
      } as IGridList);

      setShowCalender(0);
      updateBlock(target);
    }
  };

  return (
    <Tooltip
      title={gridListCmsData?.lbl_set_due_date_tooltip}
      color={"#2E364C"}
      placement="left"
    >
      {showCalender !== blockId ? (
        <div
          onClick={() => {
            if (
              hasPermissions(
                [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_EDIT],
                projectDetails?.associated_role_id
              ) &&
              projectDetails?.is_archived === false
            )
              setShowCalender(blockId);
          }}
          onKeyDown={() => {}}
          className="blkDate blkNoDate"
        >
          {gridListDetails.compactView ? (
            <></>
          ) : (
            <>
              <div className="dateMonth"></div>
              <div className="dateDay"></div>
            </>
          )}
        </div>
      ) : (
        <Popover
          open
          content={
            <div>
              <CCalendar
                value={selectingDate}
                onChange={(date) => setSelectingDate(date)}
                onCancel={() => setShowCalender(0)}
                onOk={() =>
                  setDueDate(dayjs(selectingDate).format("YYYY/MM/DD"))
                }
                onClear={() => setDueDate(null)}
                open={true}
              />
            </div>
          }
          trigger="click"
          onOpenChange={(e) => {
            if (showCalender === blockId) setShowCalender(0);
          }}
        >
          {gridListDetails.compactView ? (
            <></>
          ) : (
            <div className="blkDate blkNoDate">
              <div className="dateMonth"></div>
              <div className="dateDay"></div>
            </div>
          )}
        </Popover>
      )}
    </Tooltip>
  );
};

export default BlockDueDate;
