import { FC, memo, useState } from "react";
import BlockStatus from "./BlockStatus";
import BlockAssignee from "./BlockAssignee";
import BlockDueDate from "./BlockDueDate";
import { SectionTypeMessages } from "models/enums/messages";
import { IGridList, IGridListBlockDetail } from "models/interface";
import ExpandedMessagePopup from "components/sharedComponents/ExpandedMessagePopup/ExpandedMessageDrawer";
import ActionDropdown from "../../ActionDropdown";
import SharedAssigneeDropDown from "components/sharedComponents/SharedAssigneeDropDown/SharedAssigneeDropDown";
import { ContextTypes } from "models/enums/constants";
import { getClassName } from "../BlockWithAssigneeOnly/BlockWithAssigneeOnlyWrapper";
import MessageThread from "../../../../MessageThread";

export const getThreadName = (
  gridListDetails: IGridList,
  blockDetails: IGridListBlockDetail
) => {
  if (gridListDetails) {
    let taskName =
      blockDetails.task_id > 0
        ? gridListDetails.grid_list_details!.task_details?.find(
            (ele) => ele.task_id === blockDetails.task_id
          )?.task_name
        : "";

    let stageName =
      blockDetails.stage_id > 0
        ? gridListDetails.grid_list_details!.stage_details?.find(
            (ele) => ele.stage_id === blockDetails.stage_id
          )?.stage_name
        : "";

    if (stageName && taskName) {
      return `${taskName} | ${stageName}`;
    } else {
      return `${taskName ?? ""}${stageName ?? ""}`;
    }
  }
  return "";
};

export const getBlockPosition = (
  gridListDetails: IGridList,
  blockDetails: IGridListBlockDetail
) => {
  if (gridListDetails) {
    const blockId = blockDetails.block_id;
    const sectionId = gridListDetails?.section_id;
    let taskId =
      blockDetails.task_id > 0
        ? gridListDetails.grid_list_details!.task_details?.find(
            (ele) => ele.task_id === blockDetails.task_id
          )?.task_id
        : "";

    let stageId =
      blockDetails.stage_id > 0
        ? gridListDetails.grid_list_details!.stage_details?.find(
            (ele) => ele.stage_id === blockDetails.stage_id
          )?.stage_id
        : "";

    return `${sectionId ?? ""}-${taskId ?? ""}-${stageId ?? ""}-${
      blockId ?? ""
    }`;
  }
  return "";
};

const BlockWithCompleteDetailsWrapper: FC<{
  blockDetails: IGridListBlockDetail;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  updateBlock: (block: IGridListBlockDetail) => Promise<void>;
  showDropDown: number;
  setShowDropDown: React.Dispatch<React.SetStateAction<number>>;
  showCalender: number;
  setShowCalender: React.Dispatch<React.SetStateAction<number>>;
  index?: number;
}> = ({
  gridListDetails,
  setGridListDetails,
  updateBlock,
  blockDetails,
  showDropDown,
  setShowDropDown,
  showCalender,
  setShowCalender,
  index,
}) => {
  const [openMessageExpandedView, setOpenMessageExpandedView] =
    useState<boolean>(false);
  const [expandedViewForMessageIndex, setExpandedViewForMessageIndex] =
    useState<number | undefined>();
  const className =
    (blockDetails.block_status_id ?? 0) === 1
      ? ""
      : getClassName(blockDetails.block_status_id)?.className;

  return (
    <div
      className={"gridBlk " + className}
      id={getBlockPosition(gridListDetails, blockDetails)}
    >
      {!gridListDetails?.compactView ? (
        <>
          <MessageThread
            componentId="blockWithCompleteDetailsWrapperMessaging"
            sectionId={gridListDetails!.section_id}
            taskId={blockDetails.block_id}
            sectionType={SectionTypeMessages.GRID_LIST_BLOCK}
            threadName={getThreadName(gridListDetails, blockDetails)}
            sectionName={gridListDetails!.section_name!}
            taskDetails={blockDetails}
            isBlockedView
          />
          <BlockDueDate
            blockDetails={blockDetails}
            showCalender={showCalender}
            setShowCalender={setShowCalender}
            blockId={blockDetails.block_id}
            dueDate={blockDetails.due_date}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            updateBlock={updateBlock}
          />
          {/* <BlockAssignee
            blockDetails={blockDetails}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            blockId={blockDetails.block_id}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            assigneeDetails={{
              assignee_id: blockDetails.assignee_id,
              assignee_name: blockDetails.assignee_name,
              assignee_email: blockDetails.assignee_email,
              assignee_profile_picture: blockDetails.assignee_profile_picture,
            }}
            updateBlock={updateBlock}
          /> */}
          <SharedAssigneeDropDown
            blockDetails={blockDetails}
            showDropDown={showDropDown}
            setShowDropDown={setShowDropDown}
            blockId={blockDetails.block_id}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            assigneeDetails={{
              assignee_id: blockDetails.assignee_id,
              assignee_name: blockDetails.assignee_name,
              assignee_email: blockDetails.assignee_email,
              assignee_profile_picture: blockDetails.assignee_profile_picture,
            }}
            updateBlock={updateBlock}
            contextType={ContextTypes.GRIDLIST}
          />
          <BlockStatus
            blockId={blockDetails.block_id}
            blockDetails={blockDetails}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            updateBlock={updateBlock}
          />
          <div className="blkReset">Reset</div>
        </>
      ) : (
        <>
          <BlockStatus
            blockId={blockDetails.block_id}
            blockDetails={blockDetails}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            updateBlock={updateBlock}
          />
          <ActionDropdown
            blockId={blockDetails.block_id}
            gridListDetails={gridListDetails}
            setGridListDetails={setGridListDetails}
            updateBlock={updateBlock}
            blockDetails={blockDetails}
            index={index ?? blockDetails.block_id}
          />
        </>
      )}

      {/* <ExpandedMessagePopup
        sectionId={gridListDetails!.section_id}
        taskId={blockDetails.block_id}
        sectionType={SectionTypeMessages.GRID_LIST_BLOCK}
        threadName={getThreadName(gridListDetails, blockDetails)}
        sectionName={gridListDetails!.section_name!}
        taskDetails={blockDetails}
        openMessageExpandedView={openMessageExpandedView}
        setOpenMessageExpandedView={setOpenMessageExpandedView}
        isTemplate={false}
        index={index}
        expandedViewForMessageIndex={expandedViewForMessageIndex}
      /> */}
    </div>
  );
};

export default memo(BlockWithCompleteDetailsWrapper);
