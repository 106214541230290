import React, { useState } from "react";
import { Dropdown, Checkbox, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { DEFAULT_COLUMN_DATA_INDEX } from "../../services/table.service";
import { useProjectDashboardStore } from "stores";
import { IProjectState } from "models/interface";

interface HideColumnsProps {
  columns: any[];
  onMenuClick: (data: any[]) => void;
}

const HideColumns: React.FC<HideColumnsProps> = ({ columns, onMenuClick }) => {
  const validList = columns.sort((a, b) => (a?.id ?? 0) - (b?.id ?? 0));
  const { taskListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state
  )

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const handleItemClick = (dataIndex: string) => {
    if (DEFAULT_COLUMN_DATA_INDEX.includes(dataIndex)) {
      const newCols = validList?.map((col) => {
        if (col?.dataIndex === dataIndex) {
          return { ...col, hidden: !col?.hidden };
        }
        return col;
      });

      return onMenuClick(newCols);
    }

    const newCols = validList?.map((col) => {
      if (col?.mapping_id === dataIndex) {
        return { ...col, hidden: !col?.hidden };
      }
      return col;
    });

    onMenuClick(newCols);
  };

  const menu = (
    <div className="dropdownContainer">
      <div className="dropdownHeading">{taskListCmsData?.lbl_display_columns}</div>
      <div className="allBlk">
        <Checkbox
          checked={validList.every((col) => !col?.hidden)}
          onChange={() => {
            const isAnyItemEnable = validList.some((col) => col?.hidden);
            const newCols = validList?.map((col) => ({
              ...col,
              hidden: isAnyItemEnable
                ? false
                : !validList.every((col) => col?.hidden),
            }));
            onMenuClick(newCols);
          }}
        >
          {taskListCmsData?.lbl_all_columns}
        </Checkbox>
        <span>{validList.filter((i) => !i?.hidden).length} {taskListCmsData?.lbl_selected}</span>
      </div>
      <ul className="checkboxList">
        {validList.map((col) => {
          return (
            <li key={col?.key}>
              <Checkbox
                checked={!col?.hidden}
                onChange={() =>
                  handleItemClick(col?.dataIndex || col?.mapping_id)
                }
              >
                {col?.name || col?.title}
              </Checkbox>
            </li>
          );
        })}
      </ul>
    </div>
  );

  if (!validList.length) return null;

  return (
    <Dropdown
      trigger={["click"]}
      overlay={menu}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button
        className="hide-button"
        icon={<EyeOutlined />}
        onClick={(e) => e.stopPropagation()}
      >
        {taskListCmsData?.lbl_hide}
      </Button>
    </Dropdown>
  );
};

export default HideColumns;
