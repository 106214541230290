import { useCallback } from 'react';
import { IProjectState } from 'models/interface';
import { userService } from 'services';
import { useProjectDashboardStore, useUserStore } from 'stores';
import { message } from 'antd';

export const useFetchNewPermission = () => {
  const { setRefreshDashboardFolders } = useProjectDashboardStore(
    (state: IProjectState) => state,
  );
  const { setPermissions, setProjectRolePermissionsList, setUserDetails } =
    useUserStore();

  const fetchNewPermission = useCallback(
    async (org_key: string, info: any) => {
      message.error(info, 3);
      const response = await userService.userDetails({
        org_key: org_key ?? null,
      });

      const userInfo = response?.data?.user_info[0];

      if (userInfo) {
        setUserDetails(userInfo);
        setPermissions(userInfo.permissions ?? []);
        setProjectRolePermissionsList(userInfo.project_permissions);
        setRefreshDashboardFolders(true);
      }
    },
    [
      setPermissions,
      setProjectRolePermissionsList,
      setUserDetails,
      setRefreshDashboardFolders,
    ],
  );

  return { fetchNewPermission };
};
