import { FC, Fragment, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  IGridList,
  IGridListBlockDetail,
  IProjectState,
} from "models/interface";
import { Popover, Tooltip } from "antd";
import { useProjectDashboardStore } from "stores";
import { getBlockStatus, groupGridBlock } from "utils/commonFunctions";
import { useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import CCalendar from "../../CDatePicker/CCalendar";

const BlockDueDate: FC<{
  blockId: number;
  dueDate: string | null;
  blockDetails: IGridListBlockDetail;
  showCalender: number;
  setShowCalender: React.Dispatch<React.SetStateAction<number>>;
  gridListDetails: IGridList;
  setGridListDetails: React.Dispatch<React.SetStateAction<IGridList>>;
  updateBlock: (block: IGridListBlockDetail) => Promise<void>;
}> = ({
  blockDetails,
  showCalender,
  setShowCalender,
  blockId,
  dueDate,
  gridListDetails,
  setGridListDetails,
  updateBlock,
}) => {
  const { gridListCmsData } = useProjectDashboardStore(
    (state: IProjectState) => state
  );
  const { projectDetails } = useProjectDashboardStore(
    (state: IProjectState) => state
  );
  const { hasPermissions } = useRbac();

  const [selectingDate, setSelectingDate] = useState<Dayjs | null>(
    dayjs(dueDate || dayjs())
  );

  const setDueDate = (date: string | null) => {
    if (gridListDetails) {
      let blockDetails =
        gridListDetails.grid_list_details?.block_details!.flat()!;
      let target = blockDetails.find(
        (ele: IGridListBlockDetail) => ele.block_id === blockId
      )!;
      let targetIndex = blockDetails.findIndex(
        (ele: IGridListBlockDetail) => ele.block_id === blockId
      )!;

      if (date !== null) {
        target.due_date = date;
        const statusByDate = getBlockStatus(date, target.block_status_id);
        target.block_status_id = statusByDate;
      } else {
        target.due_date = null;
        target.block_status_id = 1;
      }

      blockDetails.splice(targetIndex, 1, target);

      setGridListDetails({
        ...gridListDetails,
        grid_list_details: {
          ...gridListDetails.grid_list_details,
          block_details: groupGridBlock(
            blockDetails,
            gridListDetails.grid_list_details!.task_details!
          ) as Array<IGridListBlockDetail[]>,
        },
      } as IGridList);

      setShowCalender(0);
      updateBlock(target);
    }
  };

  const Calendar = (
    <CCalendar
      value={selectingDate}
      onChange={(date) => setSelectingDate(date)}
      onCancel={() => setShowCalender(0)}
      onOk={() => setDueDate(dayjs(selectingDate).format("YYYY/MM/DD"))}
      onClear={() => setDueDate(null)}
      open={true}
    />
  );

  if (gridListDetails.compactView) return <>{Calendar}</>;

  const getDueDateJsx = () => {
    return (
      <Fragment>
        {blockDetails.due_date ? (
          <>
            <div
              onClick={() => {
                if (
                  hasPermissions(
                    [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_EDIT],
                    projectDetails?.associated_role_id
                  ) &&
                  !projectDetails?.is_archived
                )
                  setShowCalender(blockId);
              }}
              onKeyDown={() => {}}
              className="blkDate"
            >
              <div className="dateMonth">
                {blockDetails.due_date
                  ? dayjs(blockDetails.due_date).format("MMM")
                  : ""}
              </div>
              <div className="dateDay">
                {blockDetails.due_date
                  ? dayjs(blockDetails.due_date).format("DD")
                  : ""}
              </div>
            </div>
          </>
        ) : (
          <Tooltip
            title={gridListCmsData?.lbl_set_due_date_tooltip}
            color={"#2E364C"}
            placement="right"
          >
            <div
              onClick={() => {
                if (
                  hasPermissions(
                    [ERbacPermissions.PROJECT_SECTION_GRIDLIST_BLOCK_EDIT],
                    projectDetails?.associated_role_id
                  ) &&
                  !projectDetails?.is_archived
                )
                  setShowCalender(blockId);
              }}
              onKeyDown={() => {}}
              className="blkDate blkNoDate"
            >
              <div className="dateMonth"></div>
              <div className="dateDay"></div>
            </div>
          </Tooltip>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      {showCalender !== blockId ? (
        getDueDateJsx()
      ) : (
        <Popover
          trigger="click"
          open={showCalender === blockId}
          onOpenChange={(e) => {
            if (showCalender === blockId) setShowCalender(0);
          }}
          content={<div>{Calendar}</div>}
        >
          {gridListDetails.compactView ? (
            <></>
          ) : (
            <div className="blkDate blkNoDate">
              <div className="dateMonth"></div>
              <div className="dateDay"></div>
            </div>
          )}
        </Popover>
      )}
    </Fragment>
  );
};

export default BlockDueDate;
