import { FC, Fragment, ReactNode, useEffect } from "react";
import { IGlobalState, ILoaderState } from "../../../models/interface";
import { geolocationService } from "../../../services";
import { useLoaderStore } from "../../../stores";
import { useGlobalStore } from "../../../stores/global.store";
import { setStyle } from "../../../utils/commonFunctions";
import BrowserStorageService from "services/browserStorage.service";
import { useSearchParams } from "react-router-dom";

export const MainWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const { setLoaderState } = useLoaderStore((state: ILoaderState) => state);

  const { language, setLanguage, country, setCountry, theme, setTheme } =
    useGlobalStore((state: IGlobalState) => state);
  const browserStorage = BrowserStorageService.getInstance();
  const [searchParam] = useSearchParams();

  const saveLocalizationData = async () => {
    const localizationData = browserStorage.getLocalization();
    let currentBrowserLang =
      localizationData.language === ""
        ? window.navigator.language?.split("-")[0]
        : localizationData.language;
    if (localizationData.country === "" || localizationData.language === "") {
      try {
        const userGeolocationInfo =
          await geolocationService.getUserGeolocationInfo();

        const country = userGeolocationInfo.countryCode.toUpperCase();
        browserStorage.setLocalization({
          language: currentBrowserLang,
          country,
        });
        setCountry(country);
      } catch (error) {
        setLoaderState("inactive");
        browserStorage.setLocalization({
          language: currentBrowserLang,
          country,
        });
        setCountry(country);
      }
      setLanguage(browserStorage.getLocalization().language ?? "en");
    } else {
      setCountry(localizationData.country);
      setLanguage(localizationData.language);
    }
  };

  useEffect(() => {
    if (
      searchParam.get("is_invitation") &&
      searchParam.get("is_invitation") === "true"
    ) {
      localStorage.setItem("invitation", searchParam.get("invitation_id")!);
    }
    (async () => {
      await saveLocalizationData();
    })();
    if (browserStorage.getTheme()) {
      setTheme(2);
      setStyle("custom-dark.css", "dark-style");
    }
  }, []);

  return <Fragment>{language && country && theme && children}</Fragment>;
};
